
import applicationReducer from './application'
import progressReducer from './progress'
import borrowerReducer from './borrower'
import notificationsReducer from './notifications'
import pipelineColumnsReducer from './pipelineColumns'

import { combineReducers } from 'redux';

let rootReducer =  combineReducers({
    application: applicationReducer,
    progress: progressReducer,
    borrower: borrowerReducer,
    notifications: notificationsReducer,
    pipelineConfiguration: pipelineColumnsReducer,
  });

export default rootReducer;