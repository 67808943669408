import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import "./RateQuote.css";
import { Col, Row, Card, Form, Button, Container } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";
import { commaize } from "../../Common";
import { RatesReportTable } from "./RateReport";
import Spinner from "react-bootstrap/Spinner";

export default function AllRatesModal(props) {
  const [showPointsInHandred, setShowPointsInHandred] = useState(true);

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "transparent",
    fontSize: "13px",
    // overflowWrap: "break-word",
    whiteSpace: "nowrap",
  };
  let mtypes = [];
  let lenderSet = new Set();
  for (let mtype in props.allRates) {
    mtypes.push(mtype);
    for (let d of props.allRates[mtype]) {
      lenderSet.add(d.Lender);
    }
  }

  if (mtypes.length === 0) {
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Please wait for the calculation to be finished.</Modal.Body>
      </Modal>
    );
  }

  const getTabs = () => {
    let tabs = [];

    const pointsFormatter = (cell, row) => {
      let prices = [];
      for (let p in cell[2]) {
        if (parseFloat(cell[2][p])) {
          if (p === "AdjustmentPoint") {
            let adjs = [];
            for (let adj in cell[3]) {
              adjs.push(
                <li>
                  {adj}: {parseFloat(cell[3][adj]).toFixed(3)}
                </li>
              );
            }
            prices.push(
              <li>
                AdjustmentPoint: {parseFloat(cell[2][p]).toFixed(3)}
                <ul>{adjs}</ul>
              </li>
            );
          } else {
            prices.push(
              <li>
                {p}: {parseFloat(cell[2][p]).toFixed(3)}
              </li>
            );
          }
        }
      }

      if (cell[1]) {
        return (
          <OverlayTrigger
            trigger="click"
            key="top"
            placement="top"
            overlay={
              <Popover id="popover-positioned-top">
                <Popover.Title as="h5">Price Details</Popover.Title>
                <Popover.Content>{prices}</Popover.Content>
              </Popover>
            }
          >
            <span>
              <strong style={{ color: "blue" }}>
                {showPointsInHandred
                  ? cell[0]
                  : (100 - parseFloat(cell[0])).toFixed(3)}
              </strong>
            </span>
          </OverlayTrigger>
        );
      }
      return (
        <OverlayTrigger
          trigger="click"
          key="top"
          placement="top"
          overlay={
            <Popover id="popover-positioned-top">
              <Popover.Title as="h5">Price Details</Popover.Title>
              <Popover.Content>{prices}</Popover.Content>
            </Popover>
          }
        >
          <span>
            {cell[0] === "-"
              ? "-"
              : showPointsInHandred
              ? cell[0]
              : (100 - parseFloat(cell[0])).toFixed(3)}
          </span>
        </OverlayTrigger>
      );
    };

    const generateTableColumns = () => {
      let columns = [
        {
          dataField: "rate",
          text: "Rate",
          sort: true,
          headerStyle: headerStyle,
          formatter: (cell, row) => {
            return (
              <span>
                <strong>{cell}</strong>
              </span>
            );
          },
        },
      ];
      for (let l of lenderSet) {
        columns.push({
          dataField: l,
          text: l,
          sort: true,
          headerStyle: headerStyle,
          formatter: pointsFormatter,
        });
      }
      return columns;
    };

    const generateTableData = (ratesData) => {
      let tmpData = {};
      let data = [];
      for (let d of ratesData) {
        if (tmpData[d.base_rate] == undefined) {
          tmpData[d.base_rate] = {};
        }
        tmpData[d.base_rate][d.Lender] = [
          d.points,
          false,
          d.prices,
          d.Adjustments,
        ];
      }
      for (let rate in tmpData) {
        let min = 9999;
        for (let p in tmpData[rate]) {
          if (tmpData[rate][p][0] < min) {
            min = tmpData[rate][p][0];
          }
        }
        for (let p in tmpData[rate]) {
          if (tmpData[rate][p][0] == min) {
            tmpData[rate][p][1] = true;
          }
        }
        let row = {};
        row.rate = rate;
        for (let l of lenderSet) {
          tmpData[rate][l] === undefined
            ? (row[l] = "-")
            : (row[l] = tmpData[rate][l]);
        }
        data.push(row);
      }
      return data;
    };

    const rowStyle = { fontSize: "14px", height: "20px" };
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(`clicked on row with index: ${rowIndex}`);
      },
      onMouseEnter: (e, row, rowIndex) => {
        console.log(e, e.currentTarget, e.target, row, rowIndex);
      },
    };
    for (let mtype in props.allRates) {
      tabs.push(
        <Tab eventKey={mtype} title={mtype}>
          <BootstrapTable
            className="allRatesModalTable"
            bootstrap4
            keyField="rate"
            data={generateTableData(props.allRates[mtype])}
            columns={generateTableColumns()}
            //   rowStyle={ rowStyle }
            rowEvents={rowEvents}
            bordered={false}
            defaultSorted={[
              {
                dataField: "rate",
              },
            ]}
            hover
            condensed
          />
        </Tab>
      );
    }
    return (
      <Tabs defaultActiveKey={mtypes[0]} id="allRatesTabs">
        {tabs}
      </Tabs>
    );
  };
  const handleSwitchPointsFormat = () => {
    if (showPointsInHandred) {
      setShowPointsInHandred(false);
    } else {
      setShowPointsInHandred(true);
    }
  };

  // console.log(props.allRates)
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">All Rates</Modal.Title>
      </Modal.Header>
      <Modal.Body className="allRatesModalBody">
        <div className="text-right">
          <Form.Check
            type="switch"
            className="pointsSwitch"
            id="pointsSwitch"
            label="Switch Points Format"
            onChange={handleSwitchPointsFormat}
          />
        </div>
        {getTabs()}
      </Modal.Body>
    </Modal>
  );
}

export function AllRatesTable(props) {
  const [showPointsInHandred, setShowPointsInHandred] = useState(false);
  const [showSendRatesModal, setShowSendRatesModal] = useState(false);
  const [ratesToBeSend, setRatesToBeSend] = useState([]);
  const [removeRatesToBeSendIndex, setRemoveRatesToBeSendIndex] = useState(-1);

  useEffect(() => {
    if (removeRatesToBeSendIndex >= 0) {
      let newData = [...ratesToBeSend];
      newData.splice(removeRatesToBeSendIndex, 1);
      setRatesToBeSend(newData);
      setRemoveRatesToBeSendIndex(-1);
    }
  }, [removeRatesToBeSendIndex]);

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "transparent",
    fontSize: "14px",
    // overflowWrap: "break-word",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontWeight: "bold",
  };
  let mtypes = [];
  let lenderSet = new Set();
  for (let mtype in props.allRates) {
    mtypes.push(mtype);
    for (let d of props.allRates[mtype]) {
      lenderSet.add(d.Lender);
    }
  }

  if (mtypes.length === 0) {
    return <p>Please wait for the calculation to be finished.</p>;
  }

  const getTabs = () => {
    let tabs = [];

    const pointsFormatter = (cell, row) => {
      let prices = [];
      for (let p in cell.prices) {
        if (parseFloat(cell.prices[p])) {
          if (p === "AdjustmentPoint") {
            let adjs = [];
            for (let adj in cell.Adjustments) {
              adjs.push(
                <li>
                  {adj}: {parseFloat(cell.Adjustments[adj]).toFixed(3)}
                </li>
              );
            }
            prices.push(
              <li>
                AdjustmentPoint: {parseFloat(cell.prices[p]).toFixed(3)}
                <ul>{adjs}</ul>
              </li>
            );
          } else {
            prices.push(
              <li>
                {p}: {parseFloat(cell.prices[p]).toFixed(3)}
              </li>
            );
          }
        }
      }

      if (cell.isMin) {
        return (
          <OverlayTrigger
            // trigger="hover"
            key="top"
            placement="top"
            overlay={
              <Popover id="popover-positioned-top">
                <Popover.Content>
                  <div>Product Name: {cell.Name}</div>
                  <div>APR: {cell.APR}</div>
                  <div>Monthly Payment: ${commaize(cell.monthly_payment)}</div>
                  <div>Lock day: {cell.lockDay}</div>
                  <div>AUS: {cell.Aus}</div>
                  <div>
                    Estimated Closing Costs: ${commaize(cell.closing_cost)}
                    {props.borrowerPaidCompensationAmount > 0 &&
                      " including $" +
                        commaize(props.borrowerPaidCompensationAmount) +
                        " lender paid comp."}
                  </div>
                  <hr />
                  <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                    Points Calculation
                  </div>
                  <div>{prices}</div>
                </Popover.Content>
              </Popover>
            }
          >
            <span
              onClick={(e) => {
                if (cell.base_rate !== undefined) {
                  let toSend = [...ratesToBeSend];
                  toSend.push(cell);
                  setRatesToBeSend(toSend);
                }
              }}
            >
              <strong style={{ color: "blue" }}>
                {showPointsInHandred
                  ? cell.points
                  : (100 - parseFloat(cell.points)).toFixed(3)}
              </strong>
            </span>
          </OverlayTrigger>
        );
      }

      if (Object.keys(cell).length !== 0) {
        return (
          <OverlayTrigger
            // trigger="hover"
            key="top"
            placement="top"
            overlay={
              <Popover id="popover-positioned-top">
                {/* <Popover.Title as="h5">Price Details</Popover.Title> */}
                <Popover.Content>
                  {/* <div style={{fontWeight: "bold"}}>Product Info</div> */}
                  <div>Product Name: {cell.Name}</div>
                  <div>APR: {cell.APR}</div>
                  <div>Monthly Payment: ${commaize(cell.monthly_payment)}</div>
                  <div>Lock day: {cell.lockDay}</div>
                  <div>AUS: {cell.Aus}</div>
                  <div>
                    Estimated Closing Costs: ${commaize(cell.closing_cost)}
                    {props.borrowerPaidCompensationAmount > 0 &&
                      " including $" +
                        commaize(props.borrowerPaidCompensationAmount) +
                        " lender paid comp."}
                  </div>
                  <hr />
                  <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                    Points Calculation
                  </div>
                  <div>{prices}</div>
                </Popover.Content>
              </Popover>
            }
          >
            <span
              onClick={(e) => {
                if (cell.base_rate !== undefined) {
                  let toSend = [...ratesToBeSend];
                  toSend.push(cell);
                  setRatesToBeSend(toSend);
                }
              }}
            >
              {Object.keys(cell).length === 0
                ? "-"
                : showPointsInHandred
                ? cell.points
                : (100 - parseFloat(cell.points)).toFixed(3)}
            </span>
          </OverlayTrigger>
        );
      }
      return <span>-</span>;
    };

    const generateTableColumns = () => {
      let columns = [
        {
          dataField: "rate",
          text: "Rate",
          sort: true,
          headerStyle: headerStyle,
          formatter: (cell, row) => {
            return (
              <span>
                <strong>{cell}</strong>
              </span>
            );
          },
        },
      ];
      for (let l of lenderSet) {
        columns.push({
          dataField: l,
          text: l,
          sort: true,
          headerStyle: headerStyle,
          formatter: pointsFormatter,
        });
      }
      return columns;
    };

    const generateTableData = (ratesData) => {
      let tmpData = {};
      let data = [];
      for (let d of ratesData) {
        if (tmpData[d.base_rate] == undefined) {
          tmpData[d.base_rate] = {};
        }
        tmpData[d.base_rate][d.Lender] = { ...d };
        tmpData[d.base_rate][d.Lender]["isMin"] = false;
      }
      for (let rate in tmpData) {
        let min = 9999;
        for (let p in tmpData[rate]) {
          if (tmpData[rate][p].points < min) {
            min = tmpData[rate][p].points;
          }
        }
        for (let p in tmpData[rate]) {
          if (tmpData[rate][p].points == min) {
            tmpData[rate][p].isMin = true;
          }
        }
        let row = {};
        row.rate = rate;
        for (let l of lenderSet) {
          tmpData[rate][l] === undefined
            ? (row[l] = {})
            : (row[l] = tmpData[rate][l]);
        }
        data.push(row);
      }
      return data;
    };

    const rowStyle = { fontSize: "14px", height: "20px", textAlign: "center" };
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(`clicked on row with index: ${rowIndex}`);
      },
      onMouseEnter: (e, row, rowIndex) => {
        console.log(e, e.currentTarget, e.target, row, rowIndex);
      },
    };
    for (let mtype in props.allRates) {
      tabs.push(
        <Tab eventKey={mtype} title={mtype}>
          <div className="allRatesTable">
            <BootstrapTable
              bootstrap4
              keyField="rate"
              data={generateTableData(props.allRates[mtype])}
              columns={generateTableColumns()}
              rowStyle={rowStyle}
              rowEvents={rowEvents}
              bordered={false}
              defaultSorted={[
                {
                  dataField: "rate",
                },
              ]}
              hover
              condensed
            />
          </div>
        </Tab>
      );
    }
    return (
      <Tabs defaultActiveKey={mtypes[0]} id="allRatesTabs">
        {tabs}
      </Tabs>
    );
  };
  const handleSwitchPointsFormat = () => {
    if (showPointsInHandred) {
      setShowPointsInHandred(false);
    } else {
      setShowPointsInHandred(true);
    }
  };

  let onRemoveRatesToBeSend = (i) => {
    // let newData = [...ratesToBeSend]
    // newData.splice(i, 1)
    // setRatesToBeSend(newData)
    // console.log(i, ratesToBeSend)
    setRemoveRatesToBeSendIndex(i);
  };

  return (
    <div className="text-left">
      <div style={{ marginTop: "15px" }}>
        <p>
          <a className="clickPointsTip">
            <img src="/images/tooltipBulb.png"  style={{maxWidth:"10px", marginRight:"10px"}}/>
            Click on points to send a rates report to a borrower
          </a>{" "}
          {ratesToBeSend.length > 0 && (
            <Button
              variant="sendRatesButton"
              size="sm"
              onClick={() => setShowSendRatesModal(true)}
            >
              Send Rates Email to a Borrower
            </Button>
          )}
        </p>
      </div>
      {ratesToBeSend.length > 0 && (
        <RatesReportTable
          rows={ratesToBeSend}
          onRemoveRow={onRemoveRatesToBeSend}
          hideRemoveButton={false}
        />
      )}
      <div>
        <Form.Check
          type="switch"
          className="pointsSwitch"
          id="pointsSwitch"
          label="Switch Points Format"
          onChange={handleSwitchPointsFormat}
        />
      </div>
      {getTabs()}
      {showSendRatesModal && (
        <SendRatesModal
          show={showSendRatesModal}
          onHide={() => setShowSendRatesModal(false)}
          ratesToBeSend={ratesToBeSend}
          loanInfo={props.loanInfo}
          onRemoveRatesToBeSend={onRemoveRatesToBeSend}
        />
      )}
    </div>
  );
}

function SendRatesModal(props) {
  const [emailAddress, setEmailAddress] = useState("");
  const [loProfile, setLOProfile] = useState({});
  const [showSendReportSpinner, setShowSendReportSpinner] = useState(false);

  let token = sessionStorage.getItem("ZeitroA");
  const getLOProfile = () => {
    fetch("/agent/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error: " + js.Error);
          } else {
            let lo = js.Profile;
            setLOProfile({
              firstName: lo.FirstName,
              lastName: lo.LastName,
              email: lo.Email,
              phone: lo.Phone,
              avatarsrc: lo.Image,
              nmls: lo.Nmls,
            });
          }
        });
      })
      .catch(function (err) {
        alert("Fetch Error :-S", err);
      });
  };
  useEffect(() => {
    getLOProfile();
  }, []);

  let sendEmail = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let html = document.getElementById("ratesreport").outerHTML;
    let token = sessionStorage.getItem("ZeitroA");

    if (loProfile.firstName !== undefined) {
      html += `
      <div class="col"><p><b>${loProfile.firstName} ${loProfile.lastName}</b> | Loan Officer<br>
      ${loProfile.email} | ${loProfile.phone}<br>
      NMLS: ${loProfile.nmls}</p></div>
      `;
    }
    setShowSendReportSpinner(true);
    fetch("/agent/sendratesreport", {
      method: "POST",
      body: JSON.stringify({
        Email: emailAddress,
        Subject: "Your Personalized Mortgage Rate",
        Body: "<html><body>" + html + "</body></html>",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Something went srong when sending the email, please report this issue to Zeitro."
          );
        } else {
          alert("Rates report has been sent successfully.");
          setShowSendReportSpinner(false);
        }
      })
      .catch(function (err) {
        console.log("Fetch Error :", err);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Preview the email to be sent
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="sendEmailModalBody">
        <Container id="ratesreport">
          <Row>
            <h2>Your Personalized Mortgage Rate Today</h2>
          </Row>
          <br />
          <Row>
            <h4>Loan Summary:</h4>
          </Row>
          <Row>
            <ul>
              <li>
                <b>Loan Purpose:</b> {props.loanInfo.purpose.toLowerCase()}
              </li>
              <li>
                <b>Loan Amount:</b> {commaize(props.loanInfo.loanAmount)}
              </li>
              <li>
                <b>FICO: </b>
                {props.loanInfo.fico}
              </li>
              <li>
                <b>Occupancy: </b>
                {props.loanInfo.occupancy.toLowerCase()}
              </li>
              <li>
                <b>Property Type:</b>
                {props.loanInfo.propertyType
                  .replaceAll("_", " ")
                  .toLowerCase()}{" "}
              </li>
            </ul>
          </Row>
          <Row>
            <RatesReportTable
              rows={props.ratesToBeSend}
              onRemoveRow={props.onRemoveRatesToBeSend}
              hideRemoveButton={true}
            />
          </Row>
          <br />
          <br />
          <Row>
            <a style={{ color: "gray", fontSize: "80%" }}>
              Disclaimer: Mortgage interest rate and cost is changing all the
              time. Your actual rate, payment, and costs could be different. Get
              an official Loan Estimate before choosing a loan.
            </a>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Form onSubmit={sendEmail}>
          <Row>
            <Col>
              <Form.Label>
                <b>Email:</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                style={{ minWidth: "250px" }}
                size="sm"
                as="input"
                onChange={(e) => setEmailAddress(e.target.value)}
                value={emailAddress}
                required
              />
            </Col>
            <Col>
              <Button type="submit" variant="sendEmail">
                {showSendReportSpinner ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="emailSpinner"
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Footer>
    </Modal>
  );
}
