import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import LoanOfficers from "./LoanOfficers";
import Borrowers from "./Borrowers";
import Pipeline from "./Pipeline";
import Integration from "./Integration";
import Customization from "./Customization/Customization";
import Lenders from "./Lenders";
import CommunicationSetup from "./CommunicationSetup/CommunicationSetup";
import CreditReportSettings from "./CreditReportSettings";
import SampleRatesConfig from "./SampleRatesConfig/SampleRatesConfig";
import ThirdPartyResources from "./ThirdPartyResources/ThirdPartyResources";
import Properties from "./Properties/Properties";

import { checkAccess } from "../Auth";
import { UR_LoanOfficer, UR_Owner, UR_RealEstateAgent } from "../State";

import "./Fixes.css";

const Management = () => {
  let content = (
    <Tabs mountOnEnter={true}>
      <Tab eventKey="loanOfficers" title="Loan Officers">
        <LoanOfficers />
      </Tab>
      <Tab eventKey="loans" title="Loans">
        <Borrowers />
      </Tab>
      <Tab eventKey="dashboards" title="Dashboards">
        <Pipeline />
      </Tab>
    </Tabs>
  );

  if (checkAccess([UR_RealEstateAgent])) {
    content = (
      <Tabs mountOnEnter={true} unmountOnExit="true">
        <Tab eventKey="" title="Resources">
          <ThirdPartyResources />
        </Tab>
        <Tab eventKey="properties" title="Properties">
          <Properties />
        </Tab>
        {/* <Tab eventKey="lenders" title="Lenders">
          <Lenders />
        </Tab> */}
        {/* <Tab eventKey="todaysbest" title="Sample Rates Config">
          <SampleRatesConfig />
        </Tab> */}
      </Tabs>
    );
  } else if (checkAccess([UR_LoanOfficer])) {
    content = (
      <Tabs mountOnEnter={true} unmountOnExit="true">
        <Tab eventKey="lenders" title="Lenders">
          <Lenders />
        </Tab>
        {/* <Tab eventKey="todaysbest" title="Sample Rates Config">
          <SampleRatesConfig />
        </Tab> */}
        <Tab eventKey="dashboards" title="Dashboards">
        <Pipeline />
      </Tab>
      </Tabs>
    );
  }  else if (checkAccess([UR_Owner])) {
    content = (
      <Tabs mountOnEnter={true} unmountOnExit="true">
        <Tab eventKey="loans" title="Loans">
          <Borrowers />
        </Tab>
        <Tab eventKey="lenders" title="Lenders">
          <Lenders />
        </Tab>
        {/* <Tab eventKey="todaysbest" title="Sample Rates Config">
          <SampleRatesConfig />
        </Tab> */}
        <Tab eventKey="loanOfficers" title="Loan Officers">
        <LoanOfficers />
      </Tab>
        <Tab eventKey="dashboards" title="Dashboards">
        <Pipeline />
      </Tab>
        <Tab eventKey="customization" title="Customization">
        <Customization />
      </Tab>
        <Tab eventKey="integration" title="Public Site Integration">
        <Integration />
      </Tab>
        <Tab eventKey="communicationsetup" title="Communication Setup">
        <CommunicationSetup />
      </Tab>
      {/* <Tab eventKey="credit" title="Credit Report Settings">
        <CreditReportSettings />
      </Tab> */}
      </Tabs>
    );
  }

  return content;
};

export default Management;
