import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

import useInput, { helper } from '../hooks/use-input';
import { SMSSP_AmazonSNS10DLC } from '../../State'
import classes from './General.module.css';

const validateSMSProvider = input => input === SMSSP_AmazonSNS10DLC;
const validatetenDLCNumber = number => number.startsWith('+') && number.length === 12 && !isNaN(number.substring(1));

const Note = ({ content, variant }) => {
  let headerClass;
  let mainClass;
  let header;
  if (variant === 'info') {
    mainClass = classes.info;
    headerClass = classes.infoHeader;
    header = <><i className="fas fa-info"></i> <span>Note</span></>;
  } else if (variant === 'warning') {
    mainClass = classes.warning;
    headerClass = classes.warningHeader;
    header = <><i className="fas fa-exclamation"></i> <span>Important</span></>;
  }
  return (
    <div className={`${mainClass} ${classes.noteGeneral}`}>
      <div className={`${headerClass} ${classes.noteHeaderGeneral}`}>{header}</div>
      <div>
        {content}
      </div>
    </div>
  );
}

const SMSSetup = () => {

  const [submitted, setSubmitted] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const {
    value: SMSProvider,
    valueIsValid: SMSProviderIsValid,
    valueInputChangeHandler: SMSProviderChangeHandler,
    valueInputBlurHandler: SMSProviderBlurHandler,
    cssClasses: SMSProviderClasses,
  } = useInput(validateSMSProvider, submitted);

  const {
    value: tenDLCNumber,
    valueIsValid: tenDLCNumberIsValid,
    valueInputChangeHandler: tenDLCNumberChangeHandler,
    valueInputBlurHandler: tenDLCNumberBlurHandler,
    validationCondition: validationConditionForTenDLCNumber,
    cssClasses: tenDLCNumberClasses,
  } = useInput(validatetenDLCNumber, submitted);

  const amazonSNSContent = useMemo(() => ([
    <>
      <h1>To make this work your company need to obtain 10DLC number.</h1>
      <p>Information below is taken from Amazon. <a href='https://docs.aws.amazon.com/sns/latest/dg/channels-sms-originating-identities-10dlc.html' rel="noopener noreferrer" target='_blank'>Original Amazon guide.</a></p>
      <h3>Register your company</h3>
      <p>
        Before you can request a 10DLC, your company must be registered with The Campaign Registry;
        for more information, see <a href='https://docs.aws.amazon.com/sns/latest/dg/sns-settings-register-company.html' rel="noopener noreferrer" target='_blank'>Registering a company</a>.
        Registration is typically instantaneous unless The Campaign
        Registry requires more information. There is a one-time registration fee to register your company,
        displayed on the registration page. This one-time fee is paid separately from your monthly charges
        for the campaign and 10DLC.
      </p>
      <ol>
        <li>Sign in to the AWS Management Console and open the Amazon Pinpoint console <a href='https://console.aws.amazon.com/pinpoint/' rel="noopener noreferrer" target='_blank'>here</a>.</li>
        <li>In the navigation pane, under <b>SMS and voice</b>, choose <b>Phone numbers</b>.</li>
        <li>
          On the <b>10DLC campaigns</b> tab, choose <b>Register company</b>.
          <Note
            content={<>The <b>Register your company</b> page displays the <b>Registration fee</b>. This is a one-time fee associated with registering your company.
              This cost is separate from any other monthly costs or fees. It is charged to you when you register your company, or when you modify the
              details of an existing company registration.</>}
            variant='info'
          />
        </li>
        <li>
          In the Company info section, do the following:
          <ul>
            <li>
              For <b>Legal company name</b>, enter the
              name that the company is registered under. The name that you enter must
              be an exact match for the company name that's associated with the tax ID
              that you provide.
            </li>
          </ul>
          <Note
            content={<>Make sure to use your company's exact legal name. Once
              submitted you can't change this information. Incorrect or incomplete information might
              result in your registration being delayed or denied.</>}
            variant='warning'
          />
          <ul>
            <li>
              For <b>What type of legal form is this
                organization</b>, choose the option that best describes your
              company.
            </li>
            <li>
              If you chose <b>Public for profit</b> in the
              previous step, enter the company's stock symbol and the stock exchange
              that it's listed on.
            </li>
            <li >
              For <b>Country of registration</b>, choose
              the country where the company is registered.
            </li>
            <li>
              For <b>Doing Business As (DBA) or brand
                name</b>, enter any other names that your company does
              business as.
            </li>
            <li>
              For <b>Tax ID</b>, enter your company's tax
              ID. The ID that you enter depends on the country that your company is
              registered in.
              <ul>
                <li>
                  If you're registering a US or non-US entity that has an IRS Employer
                  Identification Number (EIN), enter your nine-digit EIN. The legal
                  company name, EIN, and physical address that you enter must all match
                  the company information that is registered with the IRS.
                </li>
                <li>If you're registering a Canadian entity, enter your federal or provincial Corporation number.
                  Don't enter the Business Number (BN) provided by the CRA. The legal company name, Corporation number,
                  and physical address that you enter must all match the company information that is registered with Corporations Canada.</li>
                <li>
                  If you're registering an entity that is based in another country, enter the primary tax ID for your country. In many countries,
                  this is the numeric portion of your VAT ID number.
                </li>
              </ul>
            </li>
            <li>
              For <b>Vertical</b>, choose the category
              that best describes the company you're registering.
            </li>
          </ul>
        </li>
        <li>
          In the <b>Contact info</b> section, do the
          following:
          <ul type="disc">
            <li>
              For <b>Address/Street</b>, enter the
              physical street address associated with your company.
            </li>
            <li>
              For <b>City</b>, enter the city where the
              physical address is located.
            </li>
            <li>
              For <b>State or region</b>, enter the state
              or region where the address is located.
            </li>
            <li>
              For <b>Zip Code/Postal Code</b>, enter the
              ZIP or postal code for the address.
            </li>
            <li>
              For <b>Company website</b>, enter the full
              URL of your company's website. Include "http://" or "https://" at the
              beginning of the address.
            </li>
            <li>
              For <b>Support email</b>, enter an email
              address.
            </li>
            <li>
              For <b>Support phone number</b>, enter a
              phone number with a country code.
            </li>
          </ul>
          <Note
            content={<>
              The Campaign Registry requires a contact email address and phone number in case they need
              to verify the registration information with a representative of your company.
            </>}
            variant='info'
          />
        </li>
        <li>
          When you finish, choose <b>Create</b>. Your company
          registration is submitted to the Campaign Registry. In most cases, your
          registration is accepted immediately, and a status is provided.
        </li>
      </ol>
    </>,
    <>
      <h3>(Optional, but recommended) Apply for vetting</h3>
      <p>
        If your company registration is successful, you can begin creating
        low-volume, mixed-use 10DLC campaigns. These campaigns can send 75 messages
        per minute to recipients who use AT&amp;T, and your registered company can
        send 2,000 messages per day to recipients who use T-Mobile. If your use case
        requires a throughput rate that exceeds these values, you can apply for
        vetting of your company registration. Vetting your company registration can
        increase the throughput rates for your companies and campaigns, but it isn't
        guaranteed to do so. For more information about vetting, see <a href="https://docs.aws.amazon.com/sns/latest/dg/sns-settings-register-company.html#sns-10dlc-vetting" rel="noopener noreferrer" target='_blank'>Vetting your Amazon SNS 10DLC registration</a>.
      </p>
      <p>
        When you vet your registration, a third-party organization analyzes the company details that you provided and returns a vetting score. A high vetting score can lead to
        higher throughput rates for your 10DLC company and the campaigns associated with it. However, vetting isn't guaranteed to increase your throughput.
      </p>
      <p>
        Vetting scores aren't applied retroactively. In other words, if you've already created a 10DLC campaign, and you later vet your company registration,
        your vetting score isn't automatically applied to your existing campaign. For this reason, you should vet your company or brand before you create any of your 10DLC campaigns.
      </p>
      <ol>
        <li>
          Sign in to the AWS Management Console and open the Amazon Pinpoint console <a href="https://console.aws.amazon.com/pinpoint/" rel="noopener noreferrer" target="_blank">here</a>.
        </li>
        <li>
          In the navigation pane, under <b>SMS and voice</b>, choose <b>Phone numbers</b>.
        </li>
        <li>
          On the <b>10DLC campaigns</b> tab, choose the <b>10DLC company</b> that you want to vet.
        </li>
        <li>
          On the company details page, toward the bottom of the page, choose <b>Apply for vetting</b>.
        </li>
        <li>
          On the <b>Apply for additional vetting</b> window,
          choose <b>Submit</b>.
          For US-based companies, the vetting process typically takes around a minute to complete. For non-US-based companies, the vetting process might take significantly more time,
          depending on how readily available data is for that country.
        </li>
      </ol>
      <p>
        After you submit a vetting request, you return to the company details page. The
        <b>Company vetting results </b>section displays the status
        and results of your vetting request. When the vetting process completes, this table
        shows a vetting score in the <b>Score</b> column. Your vetting
        score determines your 10DLC throughput capabilities. Your throughput varies based on the
        type of campaign that you create. If you create mixed-use or marketing-related 10DLC
        campaigns, you need to have a higher vetting score than you'd need for other campaign
        types in order to achieve high throughput rates.
      </p>
    </>,
    <>
      <h3>Register your campaign</h3>
      <p>
        After your company is registered, create a 10DLC campaign and associate it with one of
        your registered companies. This campaign is submitted to The Campaign Registry for approval. In
        most cases, 10DLC campaign approval is instantaneous unless The Campaign Registry requires more
        information. For more information, see <a href="https://docs.aws.amazon.com/sns/latest/dg/sns-settings-register-campaign-10dlc.html" target='_blank' rel="noopener noreferrer" >Registering a 10DLC campaign</a>.
      </p>
      <Note
        content={
          <>
            <p>After you register your company, Amazon Pinpoint shows one of two statuses for the
              registration: <b>Verified</b> or <b>Unverified</b>. You can only complete the 10DLC campaign registration
              process if the status of your company registration is <b>Verified</b>. You will be able to create low-volume mixed use
              campaigns.</p><p>If the status is <b>Unverified</b>, it usually means that
                some of the data that you provided when you registered your company was incorrect. You
                won't be able to create any 10DLC campaigns while your company has this status. You can
                modify your company registration to attempt to fix the issues with your company
                registration. For more information about modifying 10DLC company registrations,
                see <a href="https://docs.aws.amazon.com/sns/latest/dg/sns-settings-10dlc-modify-company.html" target='_blank' rel="noopener noreferrer" >Editing or deleting a registered company</a>.
            </p>
          </>
        }
        variant='info'
      />
      <p>
        On this page, you first provide the details about the company you're creating the 10DLC
        campaign for and then provide the use case details of the campaign itself. The information on
        this page is then provided to The Campaign Registry for approval.
      </p>
      <h4>To register a 10DLC campaign:</h4>
      <ol>
        <li>
          Sign in to the AWS Management Console and open the <a href="https://console.aws.amazon.com/pinpoint/" rel="noopener noreferrer" target="_blank">Amazon Pinpoint console</a>.
        </li>
        <li>
          Under <b>SMS and voice</b>, choose <b>Phone numbers</b>.
        </li>
        <li>
          On the <b>10DLC campaigns</b> tab, choose <b>Create a 10DLC campaign</b>.
        </li>
        <li>
          On the <b>Create a 10DLC campaign</b> page, in the
          <b>Campaign info</b> section, do the following:
          <ol type="a">
            <li>
              For <b>Company name</b>, choose the company that
              you're creating this campaign for.
            </li>
            <li>
              For <b>10DLC campaign name</b>, enter a name for
              the campaign.
            </li>
            <li>
              For <b>Vertical</b>, choose option that best
              represents your company.
            </li>
            <li>
              For <b>Help message</b>, enter the message that
              your customers receive if they send the keyword "HELP" to your 10DLC phone
              number.
            </li>
            <li>
              For <b>Stop message</b>, enter the message that
              your customers receive if they send the keyword "STOP" to your 10DLC phone
              number.
            </li>
          </ol>
          <Note
            content={
              <div>
                <p>Your customers can reply to your messages with the word "HELP" to
                  learn more about the messages that they're receiving from you. They can
                  also reply "STOP" to opt-out of receiving messages from you. The US
                  mobile carriers require you to provide responses to both of these
                  keywords.
                </p>
                <p>
                  The following is an example of a HELP response that complies with the
                  requirements of the US mobile carriers:
                </p>
                <p>
                  <code>
                    ExampleCorp Account Alerts: For help call 1-888-555-0142 or go to
                    example.com. Msg&amp;data rates may apply. Text STOP to cancel.
                  </code>
                </p>
                <p>
                  The following is an example of a compliant STOP response:
                </p>
                <p>
                  <code>
                    You are unsubscribed from ExampleCorp Account Alerts. No more messages
                    will be sent. Reply HELP for help or call 1-888-555-0142.
                  </code>
                </p>
                <p>
                  Your responses to these keywords must contain 160 characters or
                  fewer.
                </p>
              </div>
            }
            variant='info'
          />
        </li>
        <li>
          In the <b>Campaign use case</b> section, do the
          following:
          <ol type='a'>
            <li>
              For <b>Use case type</b>, if you have a
              charity-related use case, choose <b>Special</b>.
              Otherwise, choose <b>Standard</b>.
            </li>
            <li>
              For <b>Use case</b>, choose a use case that most
              closely resembles your campaign from the preset list of use cases. The
              monthly fee for each use case appears next to the use case name.
            </li>
          </ol>
          <Note
            content={
              <p>
                The monthly charge for registering the 10DLC campaign is shown next to each use case type.
                Most 10DLC campaign types have the same monthly charge. The charge for registering Low-Volume
                Mixed use cases is lower than for other use case types. However, Low-Volume Mixed campaigns
                support lower throughput rates than other campaign types.
              </p>
            }
            variant='info'
          />
          <ol type='a' start='3'>
            <li>
              Enter at least one <b>Sample SMS message</b>.
              This is the sample message you plan to send to your customers. If you plan
              to use multiple message templates for this 10DLC campaign, include them as
              well.
            </li>
          </ol>
          <Note
            content={
              <>
                <p>
                  Don't use placeholder text for your sample messages. The example
                  messages that you provide should reflect the actual messages
                  that you plan to send as accurately as possible.
                </p>
                <div>
                  <p>
                    Examples of what Zeitro is sending right now:
                  </p>
                  <p>
                    1. FirstName LastName requested to lock the rate for his selected loan product. &#60;Your company name&#62;.
                  </p>
                  <p>
                    2. You have a request for something and more from &#60;Your company name&#62;: https://....
                  </p>
                </div>
              </>
            }
            variant='warning'
          />
        </li>
        <li>
          The <b>Campaign and content attributes</b> section
          contains a series of <b>Yes</b> or <b>No</b> questions related to the particular features of the campaign.
          Some attributes are mandatory, so you can't change the default value.
          Make sure that the attributes you choose are accurate for your campaign.
          Indicate whether each of the following applies to the campaign that you're
          registering:
          <ul type="disc">
            <li>
              <b>Subscriber opt-in</b> – Subscribers can opt in to
              receive messages about this campaign. - <span className={classes.warningText}>Yes</span>
            </li>
            <li>
              <b>Subscriber opt-out</b> – Subscribers can opt out of
              receiving messages about this campaign. - <span className={classes.warningText}>Yes</span>
            </li>
            <li>
              <b>Subscriber help</b> – Subscribers can contact the
              message sender after sending the HELP keyword. - <span className={classes.warningText}>Yes</span>
            </li>
            <li>
              <b>Number pooling</b> – This 10DLC campaign uses more
              than 50 phone numbers. - <span className={classes.warningText}>Yes</span>
            </li>
            <li>
              <b>Direct lending or loan arrangement </b> – The
              campaign includes information about direct lending or other loan
              arrangements. - <span className={classes.warningText}>Yes</span>
            </li>
            <li>
              <b>Embedded link </b> – The 10DLC campaign includes an
              embedded link. Links from common URL shorteners, such as TinyUrl or Bit.ly,
              are not allowed. However, you can use URL shorteners that offer custom
              domains. - <span className={classes.warningText}>Yes</span>
            </li>
            <li>
              <b>Embedded phone number</b> – The campaign includes an
              embedded phone number that isn't a customer support number. - <span className={classes.warningText}>Yes</span>
            </li>
            <li>
              <b>Affiliate marketing</b> – The 10DLC campaign includes
              information from affiliate marketing. - <span className={classes.warningText}>No</span>
            </li>
            <li>
              <b>Age-gated content</b> – The 10DLC campaign includes
              age-gated content as defined by carrier and Cellular Telecommunications
              and Internet Association (CTIA) guidelines. - <span className={classes.warningText}>No</span>
            </li>
          </ul>
        </li>
        <li>
          Choose <b>Create</b>.
          After you submit the registration details for your campaign, the SMS and voice
          page opens. A message appears indicating that your campaign was submitted and is
          under review. You can see the status of your request on the <b>10DLC campaigns</b> tab. You can check the status of your registration
          on the <b>10DLC</b> tab, which will be one of the
          following:
          <ul type="disc">
            <li>
              <b>Active</b> – Your 10DLC campaign was approved.  You
              can request a 10DLC phone number and associate that number with your
              campaign. For more information, see <a target='_blank' rel="noopener noreferrer" href="https://docs.aws.amazon.com/sns/latest/dg/channels-sms-awssupport-long-code.html">Requesting 10DLC numbers, toll-free
                numbers, and P2P long codes for SMS messaging with Amazon SNS</a>.
            </li>
            <li>
              <b>Pending</b> – Your 10DLC campaign hasn't been approved
              yet. In some cases, approval might take one week or more. If the status
              changes, the Amazon Pinpoint console reflects that change. We don't notify
              you of status changes.
            </li>
            <li>
              <b>Rejected</b> – Your 10DLC campaign was rejected. To
              get more information, submit a support request that includes the campaign ID
              of the rejected campaign.
            </li>
            <li>
              <b>Suspended</b> – One or more carriers suspended your
              10DLC campaign. To get more information, submit a support request that
              includes the campaign ID of the suspended campaign. Amazon Pinpoint doesn't
              include suspension reasons on the console, and we don't notify you if your
              campaign is suspended.
            </li>
          </ul>

        </li>
        <li>
          If your 10DLC is approved, you can request a 10DLC number to associate with
          that campaign. For information about requesting a 10DLC number, see <a target='_blank' rel="noopener noreferrer" href="https://docs.aws.amazon.com/sns/latest/dg/channels-sms-awssupport-long-code.html">Requesting 10DLC numbers, toll-free
            numbers, and P2P long codes for SMS messaging with Amazon SNS</a>.
        </li>
      </ol>
    </>,
    <>
      <h3>Request your 10DLC number</h3>
      <p>
        After your 10DLC campaign is approved, you can request a 10DLC and associate that number with the approved campaign.
        Your 10DLC campaign can only use a number approved for it. See <a target='_blank' rel="noopener noreferrer" href='https://docs.aws.amazon.com/sns/latest/dg/channels-sms-awssupport-long-code.html'>Requesting 10DLC numbers, toll-free numbers, and P2P long codes for SMS messaging with Amazon SNS</a>.
      </p>
      <p>On the SMS and voice settings page in the Amazon Pinpoint console you can request a long code, toll-free number, or 10DLC phone number.</p>
      <ol>
        <li>
          Sign in to the AWS Management Console and open the <a href="https://console.aws.amazon.com/pinpoint/" rel="noopener noreferrer" target="_blank">Amazon Pinpoint console</a>.
        </li>
        <li>In the navigation pane, under <b>Settings</b>, choose <b>SMS and voice</b>.</li>
        <li>On the <b>Phone numbers</b> tab, choose <b>Request phone number</b>.</li>
        <li>
          On the <b>Define your phone numbers</b> page, for <b>Country</b>, choose the country in which you want to
          purchase a phone number. You can choose only one country at a time, but you can
          choose additional countries later if necessary.
        </li>
        <li>Choose the phone number type - 10DLC</li>
      </ol>
      <Note
        content={
          <>
            <p>
              <b>10DLC</b> – (US only) A 10-digit number that
              requires a registration process before it can be used. These numbers can
              support both SMS and voice messages. Associating a phone number with a
              10DLC campaign can take 1–2 weeks to complete.
            </p>
            <p>To request a 10DLC phone number, you must first register your company
              and campaign. After you complete these registrations, you can request a
              phone number and associate it with a 10DLC campaign. For more
              information about 10DLC, see <a rel="noopener noreferrer" target="_blank" href="https://docs.aws.amazon.com/pinpoint/latest/userguide/settings-sms-10dlc.html">10DLC</a>.
            </p>
            <p>
              If you choose <b>10DLC</b>, you're prompted to assign
              the number to a 10DLC campaign. This number can only be used for the
              associated campaign. A single phone number can't be associated with
              multiple 10DLC campaigns. If you request a 10DLC number before you have
              an active 10DLC campaign, Amazon Pinpoint returns an error message. If you don't
              see a campaign listed, verify the campaign status on the
              <b>10DLC</b> tab on <b>SMS and voice</b>
              settings page.
            </p>
          </>
        }
        variant='info'
      />
      <ol start='6'>
        <li>
          Choose a message type for the phone number. You can choose one of the following options:
          <ul type="disc">
            <li>
              <b>Promotional</b> – Choose this option for
              sending marketing messages or messages promoting your business or
              service.
            </li>
            <li>
              <b>Transactional</b> – Choose this option for
              sending time-sensitive messages, such as password resets or transaction
              alerts. <span className={classes.warningText}>&#8592; select this one</span>
            </li>
          </ul>
        </li>
        <li>
          The <b>Summary</b> section displays information about the
          number. The <b>Price per month</b> shows the cost for a single
          number. For <b>Quantity</b>, choose 1.
        </li>
        <li>
          On the <b>Review and request</b> page, confirm the phone number
          details.If you're ready to complete the purchase, choose <b>Request</b>.
          Otherwise, choose <b>Previous</b> to go to back and make any
          changes.
        </li>
      </ol>
    </>
  ]), []);

  useEffect(() => SMSProviderChangeHandler(helper(SMSSP_AmazonSNS10DLC)), [SMSProviderChangeHandler]);

  useEffect(() => {
    const token = sessionStorage.getItem("ZeitroA");
    fetch('/los/getsmsconfiguration', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      }
    }).then(response => {
      if (!response.ok) {
        alert(`contact engineering team please, error: ${response.statusText}`);
        return;
      }
      response.json().then(json => {
        if (json.Provider !== '') SMSProviderChangeHandler(helper(json.Provider));
        if (json.Phone !== '') tenDLCNumberChangeHandler(helper(json.Phone));
        if (json.Provider === SMSSP_AmazonSNS10DLC) {
          setCurrentPage(amazonSNSContent.length - 1);
        }
      })
    });
  }, [])

  const pageIsNotFist = value => value !== 0;
  const pageIsNotLast = value => value !== amazonSNSContent.length - 1;

  const next = () => {
    setCurrentPage(prev => {
      if (pageIsNotLast(prev)) {
        return prev + 1;
      }
      return prev;
    });
  }

  const prev = () => {
    setCurrentPage(prev => {
      if (pageIsNotFist(prev)) {
        return prev - 1;
      }
      return prev;
    });
  }

  const updateSMSConfiguration = () => {
    if (SMSProviderIsValid && tenDLCNumberIsValid) {
      const token = sessionStorage.getItem("ZeitroA");
      fetch('/los/updatesmsconfiguration', {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body: JSON.stringify({
          SMSProvider: SMSProvider,
          PhoneNumber: tenDLCNumber
        })
      }).then(response => {
        if (!response.ok) {
          alert(`contact engineering team please, error: ${response.statusText}`);
          return;
        }
        response.json().then(json => {
          alert(json.Text)
        })
      });
    } else {
      alert('SMS provider is not selected or phone number format is not valid.')
    }
  }

  return <>
    <Row className='mt-3'>
      <Form.Group as={Col} controlId="SMSProvider" className={SMSProviderClasses}>
        <Form.Label>SMS Provider</Form.Label>
        <Form.Control as='select' onChange={SMSProviderChangeHandler} onBlur={SMSProviderBlurHandler} value={SMSProvider}>
          <option value={SMSSP_AmazonSNS10DLC}>Amazon SNS - 10DLC</option>
        </Form.Control>
        <Form.Text className="text-muted">
          SMS Provider
        </Form.Text>
      </Form.Group>
    </Row>
    {SMSProvider === SMSSP_AmazonSNS10DLC &&
      <>
        <div className={`${classes.textAlignLeft} m-3`}>
          {amazonSNSContent[currentPage]}
          {
            currentPage === 3 &&
            <div className={classes['flex-row']}>
              <Form.Group style={{ minWidth: 471 }} controlId="phoneNumber" className={tenDLCNumberClasses}>
                <Form.Label>10DLC number</Form.Label>
                <Form.Control
                  value={tenDLCNumber}
                  onChange={tenDLCNumberChangeHandler}
                  onBlur={tenDLCNumberBlurHandler}
                  placeholder="Enter 10DLC phone number"
                />
                <Form.Text className="text-muted">{!validationConditionForTenDLCNumber ? '10DLC number' : 'Phone number must start with "+" sign and be 12 characters long.'}</Form.Text>
              </Form.Group>
              <Button onClick={updateSMSConfiguration}>Submit</Button>
            </div>
          }
        </div>
        <div className={classes.pagination}>
          {pageIsNotFist(currentPage) && <Button onClick={prev}>&#8592;</Button>}
          <span>{currentPage + 1}</span>
          {pageIsNotLast(currentPage) && <Button onClick={next}>&#8594;</Button>}
        </div>
      </>
    }
  </>
}

export default SMSSetup;