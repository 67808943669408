import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import {Button as But} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as com from '../Common.js'
import { cloneDeep } from 'lodash'
import  { Type } from 'react-bootstrap-table2-editor';
import { Spinner } from "react-bootstrap";
import { SearchOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table, Tag, Modal, ConfigProvider } from 'antd';
import "./Tasks.css"


const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
});
class Tasks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            url: "",
            tasktype: "upload",
            download: "",
            redirect: "",
            date: new Date(),
            validated: false,
            hackvalid: false,
            hackinvalid: "",
            taskname: "",
            taskcomment: "",
            taskid: "other",
            forwhom: "borrower",
            tasks: [],
            generatedDocs: {},
            open: true,
            loading: false,
            loadingdelete: false,
            searchText: '',
            searchedColumn: '',
            showCreateNewModal: false,
            selectedGeneratedTasks: [],
            succeed: false,
        }
        this.hack = React.createRef();
        this.form = React.createRef();
        this.desc = React.createRef();
        this.state.date.setDate(this.state.date.getDate() + 5)
        this.aref = React.createRef();
        this.searchInput = React.createRef();

        this.columns = [
            {
                dataField: 'task',
                text: 'Task',
                sort: true,
                editable: false
            },
            {
                dataField: 'forwhom',
                text: 'For Whom',
                sort: true,
                editable: false
            }, {
                dataField: 'deadline',
                text: 'Deadline',
                sort: true,
                editable: false
            }, {
                dataField: 'status',
                text: 'Status',
                sort: true,
                editable: false
            }, {
                dataField: 'change_status',
                text: 'Change Status',
                editor: {
                    type: Type.SELECT,
                    options: [{
                      value: 'open',
                      label: 'open'
                    }, {
                      value: 'closed',
                      label: 'closed'
                    }, {
                      value: 'verified by loan officer',
                      label: 'verified by loan officer'
                    }]
                  },
                  editCellStyle: (cell, row, rowIndex, colIndex) => {
                    return { width: '200px' };
                  },
                  classes: 'divlink',
            }, {
                dataField: 'delete',
                text: 'Delete',
                classes: 'divlink',
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.deleteTask(row.id, row.index)
                    },
                },
                editable: false
            }, {
                dataField: "id",
                text: "id",
                hidden: true,
            }, {
                dataField: "index",
                text: "index",
                hidden: true,
            }
        ];
    }
    componentWillReceiveProps = (newProps) => {

        this.reload()
    }

    componentDidMount() {
        this.reload()
    }
    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/agent/tasks/' + this.props.borrower.id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        let docs = js.Tasks.map(s => {

                            let dt = new Date(com.timeGoToDateJS(s.Created))
                            let dd = new Date(com.timeGoToDateJS(s.Deadline))

                            return { rand: Math.random(), id: s.ID, tasktype: s.TaskType, name: s.TaskName, created: dt.toLocaleDateString("en-US"), deadline: dd, status: s.Status, forwhom: s.ForWhom }
                        })
                        this.setState({ documents: docs })

                    }
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }
    showTask = (url, index) => {
        this.setState({ redirect: <Redirect to={url} /> })
    }
    changeTaskStatus = (id, oldValue, newValue) => {
        if((newValue !== '') && (oldValue !== newValue)){
            let token = sessionStorage.getItem("ZeitroA")
            let loan_id = this.props.borrower.loan_id
            let body = {
                TaskID: id,
                LoanID: loan_id,
                NewValue: newValue
            }

            fetch('/agent/changetaskstatus/', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(
                response => {
                    if (response.status !== 200) {
                        alert('Looks like there was a problem. Status Code: ' +
                            response.status);
                        return;
                    }
                    // Examine the text in the response
                    response.json().then(js => {

                        if (js.Status !== "OK") {
                            alert("Error: " + js.Text)
                        } else {
                            let tmp = this.state.documents.map(d => {
                                if (d.id === id) {
                                    d.status = newValue
                                }
                                return d
                            })
                            this.setState({documents: cloneDeep(tmp)})
                        }
                    });
                }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
        }
    }
    sendTask = () => {
        let token = sessionStorage.getItem("ZeitroA")

        let body = {
            BorrowerID: this.props.borrower.id,
            Tasks: this.state.tasks        }
        this.setState({loading: true})
        fetch('/agent/createtask/' + this.props.borrower.loan_id, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    this.setState({loading: false})
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        this.setState({loading: false})
                        alert("Error: " + js.Text)
                    } else {
                        this.setState({loading: false, succeed: true})
                        this.setState({tasks: [], taskname: ""})
                        this.reload()
                    }
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }
    addTask = () => {
        let now = new Date()
 
        let tasks = this.state.tasks
        let task = {
            TaskName: this.state.taskname,
            TaskType: this.state.tasktype,
            Created: now.toUTCString(), //('en-US'),
            Duedate: this.state.date.toUTCString(), //toLocaleDateString(),
            TaskID: this.state.taskid,
            ForWhom: this.state.forwhom,
            Key: Math.random(),
        }
        tasks.unshift(task)
 
        this.setState({tasks:tasks})
    }
    handleSubmit = (event) => {
        const node = this.form.current
        if (!node)
            return false
        if (node.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();

        this.addTask()
        return true
    }
    updateType = e => {
        if (e.target.value === "actionitem") {
            this.setState({ taskname: "", taskcomment: "", taskid: "other" })
        } else if (e.target.value === "declarations") {
            this.setState({ taskname:"Complete declarations in application interview", taskcomment: "", taskid: "other"})
        }
        this.setState({ tasktype: e.target.value })

    }
    descriptionChange = e => {
        this.setState({ taskname: e.target.value })
    }
    deleteTask = (id, index) => {

        if (window.confirm("Are you sure you want to delete the task '" + this.state.documents[index].name + "'")) {
            let token = sessionStorage.getItem("ZeitroA")
            this.setState({loadingdelete: true})
            fetch('/agent/deletetask/' + id, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(
                response => {
                    if (response.status !== 200) {
                        this.setState({loadingdelete: false})
                        alert('Looks like there was a problem. Status Code: ' +
                            response.status);
                        return;
                    }
                    // Examine the text in the response
                    response.json().then(js => {

                        if (js.Status !== "OK") {
                            this.setState({loadingdelete: false})
                            alert("Error: " + js.Text)
                        } else {
                            let tmp = this.state.documents.filter(d => d.id !== id)
                            this.setState({documents: cloneDeep(tmp)})
                            this.setState({loadingdelete: false})
                        }
                    });
                }
            )
                .catch(function (err) {
                    this.setState({loadingdelete: false})
                    console.log('Fetch Error :-S', err);
                });
        }
    }
    onDateChange = e => {
        this.setState({ date: e })
        let selected = new Date(e);
        let today = new Date()
        selected.setHours(0, 0, 0, 0)
        today.setHours(0, 0, 0, 0)
        if (selected <= today) {
            //this.hack.current.setCustomValidity("Select the date in the future")
            this.hack.current.value = ""
        } else {
            //this.hack.current.setCustomValidity("")      
            this.hack.current.value = "q"
        }
    }
    taskSelected = (id) => {
        let [taskname, taskcomment] = com.documents[id]
        if (id === "other")
            taskname = ""
        this.setState({ taskid: id, taskname: taskname, taskcomment: taskcomment })

    }
    selected2Sign = (id) => {
        let [taskname, taskcomment] = com.documents2sign[id]
        this.setState({ taskid: id, taskname: taskname, taskcomment: taskcomment })

    }
    taskComment = (tasktype) => {
        if (tasktype === "upload") {
            return (
                <Col md={4} key={this.state.tasktype} className="text-left">
                    <Form.Group controlId={"docdesc_" + tasktype}   >
                        <Form.Label className="text-left" >Document Description:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                required
  
                                name="taskname"
                                size="sm"
                                autoFocus
                                autoComplete="off"
                                type="text"
                                value={this.state.taskname}
                                readOnly={this.state.taskid !== "other"}
                                onChange={this.descriptionChange}
                                pattern="^.+$"

                            />
                            <DropdownButton
                                as={InputGroup.Append}
                                variant="task-desc"
                                title=""
                                id="input-group-dropdown-2"
                                style={{height:"44px"}}
                            >   {Object.keys(com.documents).map(key => {
                                return (<Dropdown.Item onSelect={this.taskSelected} selected={key === "other"} key={key} eventKey={key} href="#">{com.documents[key][0]}</Dropdown.Item>)

                            })}


                            </DropdownButton>
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please enter task description!
                </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            )
        } else if (tasktype === "sign") {
            return (
                <Col md={4} key={tasktype} className="text-left">
                    <Form.Group controlId={"signdesc_" + tasktype} >
                        <Form.Label className="text-left" >Document Description:</Form.Label>
                        <InputGroup >
                            <Form.Control
                                required
                                name="taskname"
                                size="sm"
                                autoFocus
                                autoComplete="off"
                                type="text"
                                value={this.state.taskname}
                                readOnly={this.state.taskid !== "other"}
                                onChange={this.descriptionChange}
                                pattern="^.+$"

                            />
                            <DropdownButton
                                as={InputGroup.Append}
                                variant="success"
                                title=""
                                id="input-group-dropdown-2"
                                size="sm"
                            >   {Object.keys(com.documents2sign).map(key => {

                                return (<Dropdown.Item onSelect={this.selected2Sign} selected={key === "other"} key={key} eventKey={key} href="#">{com.documents2sign[key][0]}</Dropdown.Item>)

                            })}


                            </DropdownButton>
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please enter task description!
                </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            )
        } else {
            return (
                <Col md={4} key={tasktype} className="text-left">
                    <Form.Group controlId={"task_" + tasktype}  >
                        <Form.Label className="text-left" >Task Description:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                required
                                name="taskname"
                                size="sm"
                                autoFocus
                                autoComplete="off"
                                type="text"
                                value={this.state.taskname}
                                readOnly={this.state.taskid !== "other"}
                                onChange={this.descriptionChange}
                                pattern="^.+$"

                            />

                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please enter task description!
                </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            )
        }
    }
    forWhomDecoded = (_forwhom) => {
        let forwhom = ""

        if(_forwhom === 'joint' && this.props.application.coborrower.firstname === '' &&
            this.props.application.coborrower.lastname === '') {
            _forwhom ='borrower'
        }
        try {
            switch (_forwhom) {
                case 'borrower':
                    forwhom = this.props.application.borrower.firstname + " " + 
                    this.props.application.borrower.lastname 
                    break
                case 'coborrower':
                    forwhom = this.props.application.coborrower.firstname + " " + 
                    this.props.application.coborrower.lastname
                    break;
                case 'joint':
                    forwhom = this.props.application.borrower.firstname + " " + 
                    this.props.application.borrower.lastname + " and " + this.props.application.coborrower.firstname + " " + 
                    this.props.application.coborrower.lastname              
                    break;
                default:
                    forwhom = _forwhom
                    break;
            }
        } catch (x) {
            forwhom = _forwhom
        }        
        return forwhom
    }    
    documentsJSON = (document, index) => {
        let showDeadline = (document, deadline) => {
            deadline.setHours(23)
            deadline.setMinutes(59)
            deadline.setSeconds(59)
            let cls = document.status === "closed" ? "" : "bigred"

            let datenow = new Date()
            if (datenow > deadline) {
                return (<div className={cls}> {deadline.toLocaleDateString("en-US")}  </div>)
            } else {
                return deadline.toLocaleDateString("en-US")
            }
        }
        let buttonLink = (document) => {

            return (
                document.status
            )
        }
        return {
            task: document.name,
            forwhom: this.forWhomDecoded(document.forwhom),
            deadline: showDeadline(document, document.deadline),
            change_status: "Change Status ▼",
            delete: "Delete",
            id: document.id,
            status: buttonLink(document),
            index: index
        }
    }
    showCoborrower = () => {
        if (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married)
            return true
        return false
    }
    onGenaratedDocDateChange = i => (e) => {
        let d = this.state.generatedDocs
        if (d.docs!=undefined) {
            d.docs[i].date = e
            this.setState({ generatedDocs: d })
            let selected = new Date(e);
            let today = new Date()
            selected.setHours(0, 0, 0, 0)
            today.setHours(0, 0, 0, 0)
            if (selected <= today) {
                //this.hack.current.setCustomValidity("Select the date in the future")
                this.hack.current.value = ""
            } else {
                //this.hack.current.setCustomValidity("")      
                this.hack.current.value = "q"
            }
        }
    }
    genDocs = () => {
        let token = sessionStorage.getItem("ZeitroA")

        let body = {
            BorrowerID: this.props.borrower.id,
            Tasks: this.state.tasks
        }

        fetch('/agent/gendocs/' + this.props.borrower.loan_id, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    console.log(response)
                    return;
                }
                response.json().then(js => {
                    if (js.docs!=undefined) {
                        this.setState({generatedDocs:{}})
                        let btasks = []
                        js.docs.forEach((d)=>{
                            if (d.from == "borrower" || d.from == "coborrower" || d.from=="joint") {
                                btasks.push(d)
                            }
                        })
                        let ctasks = []
                        btasks.forEach((d)=>{
                            d.date = this.state.date
                            d.docname = d.docname.split(/(?=[A-Z])/).join(" ")
                            if (d.months != undefined) {
                                d.docname = d.docname + " - Last "+ d.months + " Months"
                            }
                            if (d.from == "borrower") {
                                d.forwhom = this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname
                            } else if (d.from == "coborrower") {
                                d.forwhom = this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname
                            } else if (d.from == "joint") {
                                d.forwhom = this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname
                                let c = {
                                    date: d.date,
                                    docname: d.docname,
                                    forwhom: this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname,
                                    reasoning: d.reasoning,
                                    months: d.months,
                                }
                                ctasks.push(c)
                            }
                        })
                        // ctasks.forEach((d)=>{
                        //     d.forwhom = this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname
                        // })
                        js.docs = [...btasks, ...ctasks]
                        js.docs.sort((a,b) => (a.docname > b.docname) ? 1 : ((b.docname > a.docname) ? -1 : 0))

                        this.setState({generatedDocs:js})
                    } else {
                        alert('Looks like there was a problem: can not generate required documents');
                        console.log(response)
                        return
                    }
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });    
    }
    renderDocReasoningTooltip = (doc) => (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {doc.reasoning}
        </Tooltip>
    );
    getDocType = (doc) => {
        return (
          <Col md={4} key="upload" className="text-left">
            <Form.Group controlId="docdesc_upload">
              <Form.Label className="text-left">
                Document Description:
              </Form.Label>
              {doc.reasoning === undefined ? (
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder={doc.docname}
                  aria-label="docname"
                  disabled
                  readOnly
                />
              ) : (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderDocReasoningTooltip(doc)}
                >
                <InputGroup>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder={doc.docname}
                    aria-label="docname"
                    disabled
                    readOnly
                  />
                  <Button variant="outline-dark" class="rounded-pill" size="sm">?</Button>
                </InputGroup>
                </OverlayTrigger>
              )}
            </Form.Group>
          </Col>
        );
    }
    submitGeneratedDocs = () => {
        let docs = [...this.state.generatedDocs.docs]
        let indicesToRemove = []
        let tasks = [...this.state.tasks]
        for (let i=0; i<docs.length; i++) {
            let doc = docs[i]
            if (!this.state.selectedGeneratedTasks.includes(doc.docname+doc.forwhom)) {
                continue
            }
            let now = new Date()
            let taskid="other"
            for (let key in com.documents) {
                for (let name of com.documents[key]) {
                    if (doc.docname.includes(name)) {
                        taskid = key
                        continue
                    }
                }
            }

            let task = {
                TaskName: doc.docname,
                TaskType: "upload",
                Created: now.toUTCString(), //('en-US'),
                Duedate: doc.date.toUTCString(), //toLocaleDateString(),
                TaskID: taskid,
                ForWhom: doc.forwhom,
                Key: Math.random(),
            }
            tasks.push(task)
            indicesToRemove.push(i)
        }
        this.setState({tasks:tasks})
        let gd = this.state.generatedDocs
        this.setState({generatedDocs: {docs: docs.filter((_, index) => !indicesToRemove.includes(index)), alternatives: gd.alternatives}}) 
        this.setState({selectedGeneratedTasks:[]})
    }

    render() {
        let updateForWho = e => {

            this.setState({ forwhom: e.target.value })
        }
        const showSelectedTasks = () => {
            
            let decodeDate = s => {
                let dt = new Date(s)
                return dt.toLocaleDateString("en-US")
            }
            const onDelete = (index) => {
                let tasks = this.state.tasks
                tasks.splice(index, 1)
                this.setState({tasks})
            }
            const data = this.state.tasks.map(item => {
                return Object.keys(item).reduce((acc, key) => {
                  acc[key.toLowerCase()] = item[key];
                  return acc;
                }, {});
              });
            const columns = [
                {
                    title: 'TASK TYPE',
                    dataIndex: 'tasktype',
                    key: 'tasktype',
                    render: (text) => <a>{com.capitalize(text)}</a>,
                },
                {
                  title: 'TASK NAME',
                  dataIndex: 'taskname',
                  key: 'taskname',
                },
                {
                  title: 'FOR WHOM',
                  dataIndex: 'forwhom',
                  key: 'forwhom',
                  render: (text) => <a>{this.forWhomDecoded(text)}</a>,
                },
                {
                  title: 'DUE DATE',
                  dataIndex: 'duedate',
                  key: 'duedate',
                  render: (text) => <a>{decodeDate(text)}</a>,
                },
                {
                  title: '',
                  key: 'action',
                  fixed: 'right',
                  width: 60,
                  render: (_, record, index) => (
                    <Space size="middle">
                        <DeleteOutlined onClick={()=>onDelete(index)}/>
                    </Space>
                  ),
                },
              ];

            const customizeRenderEmpty = () => (
                <div style={{textAlign: 'center',}}>
                    <p>No created tasks yet</p>
                </div>
            );

            return <div><ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table columns={columns} dataSource={data}
                    pagination={false}
                    scroll={{
                        y: 210,
                      }}
                />
                </ConfigProvider></div>
        }

        const showGeneratedDocs = () => {
            if (typeof this.state.generatedDocs.docs === "undefined") {
                return ""
            }

            if (this.state.generatedDocs.docs.length === 0) {
                return ""
            }

            const columns = [
                {
                    title: 'TYPE',
                    dataIndex: '',
                    render: (text) => <a>Document upload</a>,
                },
                {
                    title: 'DOC NAME',
                    dataIndex: 'docname',
                    render: (text) => <a>{text}</a>,
                },
                {
                  title: 'FOR WHOM',
                  dataIndex: 'forwhom',
                  render: (text) => <a>{text}</a>,
                },
                {
                    title: '',
                    dataIndex: '',
                    render: (_, record, index) => <a style={{color:"#325CEB", textDecoration:"underline"}} onClick={()=>{
                        let key = record.docname+record.forwhom
                        if (!this.state.selectedGeneratedTasks.includes(key)) {
                            let newSelectedRowKeys = [...this.state.selectedGeneratedTasks]
                            newSelectedRowKeys.push(key)
                            this.setState({selectedGeneratedTasks: newSelectedRowKeys})
                        }
                    }}>Add</a>,
                },
              ];
              const onSelectChange = (newSelectedRowKeys) => {
                this.setState({selectedGeneratedTasks: newSelectedRowKeys});

              };
              const rowSelection = {
                selectedRowKeys: this.state.selectedGeneratedTasks,
                onChange: onSelectChange,
                selections: [
                    Table.SELECTION_ALL,
                    Table.SELECTION_INVERT,
                    Table.SELECTION_NONE,
                  ],
              };

            let data = this.state.generatedDocs.docs.map((obj, index) => {
                return {
                    ...obj,
                    key: obj.docname+obj.forwhom,
                };
            });
            return <div>
                <div className="d-flex justify-content-between my-3">
                    <div className="title-4 ">Auto generated tasks ({this.state.selectedGeneratedTasks.length} selected)</div>
                    {this.state.selectedGeneratedTasks.length>0 && <div><Button type="primary" onClick={()=>this.submitGeneratedDocs()}>Add selected tasks</Button></div>}
                </div>
                <div style={{borderRadius:4, border:"1px solid #CFD4D9", padding:"10px 30px"}}>
                    <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    scroll={{
                        y: 210,
                      }}
                />
                </div>
            </div>
        }

        const showCurrentTasks  = () => {

            const handleSearch = (selectedKeys, confirm, dataIndex) => {
                confirm();
                this.setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex
                });
              };
            
            const handleReset = (clearFilters) => {
                clearFilters();
                this.setState({ searchText: '' });
                };
            
            const getColumnSearchProps = (dataIndex) => ({
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div
                    style={{
                        padding: 8,
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                    >
                    <Input
                        ref={this.searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                        marginBottom: 8,
                        display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        >
                        Search
                        </Button>
                        <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        >
                        Reset
                        </Button>
                        <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                            closeDropdown: false,
                            });
                            this.setState({searchText: selectedKeys[0]});
                            this.setState({searchedColumn: dataIndex});
                        }}
                        >
                        Filter
                        </Button>
                        <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                        >
                        close
                        </Button>
                    </Space>
                    </div>
                ),
                filterIcon: (filtered) => (
                    <SearchOutlined
                    style={{
                        color: filtered ? '#1677ff' : undefined,
                    }}
                    />
                ),
                onFilter: (value, record) =>
                    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange: (visible) => {
                    if (visible) {
                    setTimeout(() => this.searchInput.current?.select(), 100);
                    }
                },
                render: (text) =>
                    this.state.searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                        }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                    ) : (
                    text
                    ),
                });
                
            const columns = [
                {
                    title: "NAME",
                    dataIndex: 'name',
                    key: 'name',
                    ...getColumnSearchProps('name'),
                },
                {
                    title: 'TASK TYPE',
                    dataIndex: 'tasktype',
                    key: 'tasktype',
                    render: (text) => <a>{com.capitalize(text)}</a>,
                    // width: '30%',
                    ...getColumnSearchProps('tasktype'),
                },
                {
                    title: 'CREATED AT',
                    dataIndex: 'created',
                    key: 'created',
                    sorter: (a, b) => a.created - b.created,
                },
                {
                    title: 'STATUS',
                    dataIndex: 'status',
                    key: 'status',
                    render: (_, { status }) => (
                        <>
                            <Tag color={status==="open" ? "#E2FFE3" : "#FFF2C5"} key={status} style={{color:"black"}}>
                                {status}
                            </Tag>

                        </>
                      ),
                },
                {
                    title: 'FOR WHOM',
                    dataIndex: 'forwhom',
                    key: 'forwhom',
                    render: (_, { forwhom }) => (
                        <> 
                            {this.forWhomDecoded(forwhom)}
                        </>
                    ),
                },
                {
                    title: ' ',
                    dataIndex: 'action',
                    fixed: 'right',
                    width: 60,
                    key: 'action',
                    render: (_, record, index) => (
                        <a
                          onClick={() => this.deleteTask(record.id, index)}
                        >
                          <DeleteOutlined />
                        </a>
                      ),
                  },
            ]

            return <div className="py-3 px-5 currentTasksWrap">
                <div className="text-right mb-2">
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={()=>this.setState({showCreateNewModal: true})}>New task</Button>
                </div>
                <Table columns={columns} dataSource={this.state.documents}
                scroll={{
                    x: true,
                }}
                pagination={{
                // pageSize: 4,
                    hideOnSinglePage: true
                }}
                />
            </div>
        }

        const showCreateNewTasks = () => {
            return  <div className="text-left mt-3 mx-2">
            {this.state.redirect}
            <a hidden ref={this.aref} href={this.state.url} download={this.state.download}> </a>
            <div className="title-3">Create new set of action requests for {this.props.borrower.fullname}'s loan</div>

            <div className="text-center mb-3">
                <div className="mt-3 mb-2"><Button type="primary" size="large" onClick={this.genDocs} ghost> Auto generate tasks </Button>{' '}</div>
                <div>or add new task manually</div>
            </div>

            {showSelectedTasks()}

            {showGeneratedDocs()}
            <Form ref={this.form} className="text-center" onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>

                <Form.Row key={"AAA" + this.state.tasktype} className=" w-100 mt-0 pt-4 mb-3 text-nowrap text-center">
                    <Col md={2} className="text-left">
                        <Form.Group controlId="username"  >
                            <Form.Label className="text-left" >Type:</Form.Label>
                            <Form.Control as="select" size="sm"
                                value={this.state.tasktype}
                                type="text"
                                onChange={this.updateType}

                            >
                                <option value="upload">Document upload</option>
                                {/* {!isPrequal && <option value="declarations">Fill declarations</option>} */}
                                <option value="sign">Document sign</option>
                                <option value="actionitem">Action item</option>
                            </Form.Control>
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide the answer.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={2} className="text-left">
                        <Form.Group controlId="username"  >
                            <Form.Label className="text-left" >For Whom:</Form.Label>
                            <Form.Control as="select" size="sm"
                                defaultValue="upload"
                                type="text"
                                onChange={updateForWho}

                            >

                                <option value="borrower">{this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname}</option>
                                {(this.showCoborrower()) ?
                                    <option value="coborrower">Coborrower</option>
                                    : ""
                                }
                                {(this.showCoborrower()) ?
                                    <option value="joint">Joint</option>
                                    : ""
                                }

                            </Form.Control>
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide the answer.
                            </Form.Control.Feedback>

                        </Form.Group>
                    </Col>
                    {this.taskComment(this.state.tasktype)}
                    <Col md={1} className="text-left mr-3">
                        <Form.Group ref={this.picker} controlId="date" className="text-left m-0 p-0" >
                            <Form.Label className="text-left" >Due Date:</Form.Label>
                            <br />
                            <DatePicker
                                showPopperArrow={true}
                                selected={this.state.date}
                                onChange={this.onDateChange}

                            />
                            <Form.Control isValid={false} style={{height:"44px"}}
                                hidden
                                required
                                name="hack"
                                type="text"
                                ref={this.hack}
                                pattern="^[\w]$"
                                defaultValue="q"
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please provide a date in the future.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="" style={{paddingTop:25}}>
                        <But className="mt-2 ml-2" type="submit" variant="link" style={{color:"#325CEB"}}><PlusCircleOutlined style={{color:"#325CEB", marginRight:7, fontSize: 14}}/>Add Task</But>
                    </Col>
                </Form.Row>
            </Form>

            </div>
        }

        const showSucceed = () => {
            return <div className="text-center mt-5">
                <div><img src="/images/checkEmail.png" style={{height: 71, width: 64}}/></div>
                <div className="title-2 mt-3">Email sent successfully!</div>
            </div>
        }

        const handleCancel = () => {
            this.setState({showCreateNewModal: false})
          };
        let footer = [
            <Button key="submit" type="primary"  onClick={handleCancel} ghost> 
                Cancel
            </Button>
            ]
        if (this.state.tasks.length > 0) {
            footer.push(<Button type="primary" onClick={this.sendTask}>{this.state.loading? <Spinner animation="border" size="sm"/>:" Save tasks and send notifications"} </Button>)
        }
        if (this.state.succeed) {
            footer = [
                <Button key="submit" type="primary"  onClick={()=>this.setState({succeed: false})} ghost> 
                    Add more tasks
                </Button>,
                <Button type="primary"  onClick={()=>this.setState({succeed: false, showCreateNewModal: false})} > 
                    Done
                </Button>
            ]
        }

        return (
            <div>
                {showCurrentTasks()}
                <Modal
                    width={"85vw"}
                    bodyStyle={{height:"85vh", overflowY:"scroll"}}
                    centered={true}
                    open={this.state.showCreateNewModal}
                    title=""
                    onCancel={handleCancel}
                    footer={footer}
                >
                    {this.state.succeed ? showSucceed() : showCreateNewTasks()}
                   
      </Modal>                
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
