
import HomePage from './HomePage'
import Auth from "./Auth";
import smartlookClient from 'smartlook-client'
import WelcomePage from './WelcomePage';
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Error from "./Error.js"
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {Helmet} from "react-helmet";
import { getLoId, getLoInfo } from "./Common";




export default class AuthenticatedApp extends Component {

    componentDidMount() {
        const loInfo = getLoInfo()
        // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
// This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
// Call this function in your authentication promise handler or callback when your visitor and account id values are available
// Please use Strings, Numbers, or Bools for value types.
    window.pendo.initialize({
        visitor: {
            id:              loInfo.loanofficerid,
            role:            loInfo.roles[0],
            // email:        // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
        },

        account: {
            id:              loInfo.loanofficerid,
            role:            loInfo.roles[0],
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
        }
    });

    if (!window.location.hostname.includes("localhost")) {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/agent/getprofile', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Please contact Zeitro for help.');
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Error)
                    } else {
                        let lo = js.Profile
                        smartlookClient.init('ea0bef70cdcdc8f7469f9df8b5e2371a9da1811d')
                        smartlookClient.record({ 
                            emails: true,
                            forms: true,
                            numbers: true,
                            ips: true,
                        })
                        smartlookClient.identify(loInfo.loanofficerid, {
                            name: lo.FirstName + " " + lo.LastName,
                            email: lo.Email,
                        })
                    }
                });
            }
        ).catch(function (err) {
            console.log(err)
        });
    }
    }
    render() {
        return (

            <div className="mx-0 px-0">
                <Auth />
                <Router>
                    <Switch>
                        {/* <Route path="/app/welcome">
                            <WelcomePage />
                        </Route> */}
                        <Route path="/app/">
                            <HomePage />
                        </Route>

                        <Route path="/error/401">
                            <Error code={401} />
                        </Route>
                        <Route >
                            <Error code={404} />
                        </Route>

                    </Switch>
                </Router>
            </div>
        )
    }
}