import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Spinner from './CommonUIComponents/Spinner'

import "react-datepicker/dist/react-datepicker.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import { connect } from 'react-redux'
import * as com from "./Common"
import { Empty , Alert} from 'antd';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
});
class FormPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            preview: [],
            renderer: <Spinner addSpinnerClass={true} style={{top: '30vh'}} />
        }
        this.prefix = "data:image/png;base64,"
        this.downloadref = React.createRef()
    }

    componentDidMount() {
        this.reload()
    }

    download = () => {
        this.downloadref.current.click()
    }

    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let { id } = this.props.borrower

        let url
        let headers = {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            'X-Loan': this.props.borrower.loan_id
        }
        if (this.props.form === '1003') {
            url = `/agent/get${this.props.form}/b64/${id}`
        } else if (this.props.form === 'undefined') {
            this.setState({
                renderer:
                    (<div>
                        <p style={{fontSize: '25px'}}>This information is not available yet.</p>
                    </div>)
            })
            return
        } else if (this.props.form === 'mismo34') {
            fetch(`/agent/getmismothreefour/${this.props.borrower.loan_id}`, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(response => {
                if (response.status !== 200) {
                    this.setState({renderer: null})
                     alert('Looks like there was a problem. Please ask Zeitro team for help.')
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(json => {
                    try {
                        let obj = JSON.parse(atob(json.Document))
                        if (obj.Errors || obj.Error) {
                            if (obj.Error && obj.Error === "Data.Text.Internal.Fusion.Common.index: Index too large") {
                                obj.Error = "Credit info has not been provided yet."
                            } else if (obj.Error && obj.Error.includes("invalid address line")) {
                                obj.Error = "Property address has not been provided yet."
                            }
                            console.log(obj)
                            this.setState({
                                renderer:
                                    (
                                        <div className="m-5"><Empty description={
                                            <div>
                                              Document is not available yet
                                            </div>
                                        }/>
                                        {obj.Error  && <Alert message={obj.Error} type="warning" showIcon className="mt-3"/>}
                                        {obj.Errors  && obj.Errors.map(e => <Alert message={e} type="warning" showIcon className="mt-3"/>)}
                                        </div>
                                    )
                            })
                        }
                    }
                    catch (e) {
                        let current = com.getNumericalStatus(this.props.application.status)
                        let loansubmitted = com.getNumericalStatus("loansubmitted")
                        this.setState({
                            renderer:
                            // ( current < loansubmitted ? 
                            //         <div className='forms-preview text-center'>
                            //             <h4 className="mt-3 mb-5">Customer application is not submitted yet</h4>
                            //             <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                            //                 The interview must reach the last page, and application must be submitted. Even if the borrower or yourself went back, and made an edit, one has to step through all pages to the end to ensure consistency of the application.
                            //             </div>
                            //         </div>
                            //         :
                                    <div className='forms-preview'>
                                        <h4 className="mt-3 mb-5">Please click below to download FNM 3.4 file</h4>
                                        <a ref={this.downloadref} hidden href={`data:application/xml;base64,${json.Document}`} download={this.props.borrower.fullname + " " + this.props.form}> </a>
                                        <div onClick={this.download} className='documentDownload'><i style={{ fontSize: '70px', top: '15px', left: '47%' }} className="fas fa-file-download"></i></div>
                                    </div>
                                // )
                        })
                    }
                })
            })
        } else {
            url = `/agent/getform/b64/${id}`
            headers['X-form'] = this.props.form
        }

        fetch(url, {
            method: 'GET',
            headers
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(json => {
                    let doc = json.Document
                    if (this.props.borrower.id !== id) {
                        this.reload()
                    } else {
                        this.setState({ preview: doc.pngPagesBase64 })
                        this.setState({
                            renderer:
                                (
                                    <div className='forms-preview'>
                                        <a ref={this.downloadref} hidden href={`data:application/pdf;base64,${doc.documentBase64}`} download={this.props.borrower.fullname + " " + this.props.form}> </a>
                                        <div onClick={this.download} className='forms-download documentDownload'><i style={{ fontSize: '40px' }} className="fas fa-file-download"></i></div>
                                        {this.state.preview.map((page, index) =>
                                            <img key={index} alt="view" src={this.prefix + page} />
                                        )}
                                    </div>
                                )
                        })
                    }

                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    render() {

        return (
            <div className="text-center mt-3 bluish">
                {this.state.renderer}
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormPreview)
