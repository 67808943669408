import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Spinner from 'react-bootstrap/Spinner'
import * as com from './Common'
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";

class Validate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:  <Spinner className="text-center" 
            animation="border" role="status" />

        }
        this.loid = this.props.match.params["loid"] 
        this.borrowerid = this.props.match.params["borrowerid"] 
        this.validate()
    }


    validate = () => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/agent/validated/' + this.loid + "/" + this.borrowerid, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Please contact support@zeitro.com for help.');
                        this.setState({redirect: <Redirect to={this.props.url + "/services/signin"}/>})    
                    return;
                    
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        alert('Looks like there was a problem. Please contact support@zeitro.com for help.');
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {
                        let url = js.Text
                        window.location.href = url + "/services/signin";
                    }

            
            });
            }
        ).catch( (err) => {
            alert('Fetch Error :', err);
        });
    }

    onSigned = () => {
        this.markClosed()
    }
    render() {
        return (
        <div className="mt-5 mb-5 text-center">
            {this.state.redirect}
        </div>
        )
    }
}

export default withRouter(Validate)

export class ValidateAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:  <Spinner className="text-center" 
            animation="border" role="status" />

        }
        this.email = this.props.email
        this.validate()
    }


    validate = () => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/agent/validatedagent/' + this.email, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Please contact support@zeitro.com for help.');
                        this.setState({redirect: <Redirect to={window.location.host}/>})    
                    return;
                    
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        alert('Looks like there was a problem. Please contact support@zeitro.com for help.');
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {
                        window.location.href = "/signupsuccess";
                    }

            
            });
            }
        ).catch( (err) => {
            alert('Fetch Error :', err);
        });
    }

    onSigned = () => {
        this.markClosed()
    }
    render() {
        return (
        <div className="mt-5 mb-5 text-center">
            {this.state.redirect}
        </div>
        )
    }
}
