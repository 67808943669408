import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Tooltip from 'react-bootstrap/Tooltip'
import { Popover as Pop } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";

const catchall = text =>
    (props) => {
        let p = { ...props, 'show': 'true' }
        return (
            <Popover id="years-tooltip"  {...p} >
                <Popover.Content> {text}
                </Popover.Content>
            </Popover>
        )
    }
export const tooltip = (title, text, placement, cls, nocomma) => {
    if("undefined" === typeof placement)
        placement="bottom"
    let cla = "pb-2"
   
    if("undefined" !== typeof cls) {
        cla = cls
    }
    let last = ""
    if (typeof title === "object" ) {
    } else {
        let split = title.split(' ')
        if(split.length > 1) {
            last = split[split.length - 1]
            title = split.slice(0, split.length -1).join(' ')
        } 
    }
    return (
        <div className={cla}>
            <OverlayTrigger
                trigger={['click' , 'hover' ]} rootClose
                placement={placement} overlay={catchall(text)} >
                <div className="divlink text-wrap">{title} <span className="text-nowrap">{last}<i className="ml-1 fas fa-question-circle text-newinfo"></i>{nocomma? '' : ':'}</span></div>
            </OverlayTrigger>
        </div>)
}
export const tooltipWhite = (title, text, placement, cls, nocomma, size) => {
    if("undefined" === typeof placement)
        placement="bottom"
    let cla = "pb-2"
   
    if("undefined" !== typeof cls) {
        cla = cls
    }
    let last = ""
    if (typeof title === "object" ) {
    } else {
        let split = title.split(' ')
        if(split.length > 1) {
            last = split[split.length - 1]
            title = split.slice(0, split.length -1).join(' ')
        } 
    }
    return (
        <div className={cla}>
            <OverlayTrigger
                trigger={['click' , 'hover' ]} rootClose
                placement={placement} overlay={catchall(text)} >
                <div className="divlink text-wrap">{title} <span className="text-nowrap">{last}{
                    size ? <img src="/images/questionMark.png" style={{maxWidth: size, paddingLeft:"2px"}} /> : <img style={{maxWidth: "14px"}} src="/images/questionMark.png" />
                }{nocomma? '' : ':'}</span></div>
            </OverlayTrigger>
        </div>)
}
export const tooltipFlat = (title, text, placement, cls, nocomma, noquestion) => {
    if("undefined" === typeof placement)
        placement="bottom"
    let cla = "pb-2"
    let question = <i className="ml-1 fas fa-question-circle text-newinfo"></i>
    if(noquestion === false)
        question = ""
    if("undefined" !== typeof cls) {
        cla = cls
    }
    let last = ""
    if (typeof title === "object" ) {
    } else {
        let split = title.split(' ')
        if(split.length > 1) {
            last = split[split.length - 1]
            title = split.slice(0, split.length -1).join(' ')
        } 
    }
    return (
        <span className={cla}>
            <OverlayTrigger
                trigger={['click' , 'hover' ]} rootClose
                placement={placement} overlay={catchall(text)} >
                <span className="divlink text-wrap">{title} <span className="text-nowrap">{last}{question}{nocomma? '' : ':'}</span></span>
            </OverlayTrigger>
        </span>)
}
export const MonhtlyChange = (old, nw, code) => {

    if( Math.round(old - nw) === 0 ) {
        let text=<span>No improvement</span>      
        return (
            <div >
                <OverlayTrigger
                    trigger={['click' , 'hover' ]} rootClose
                    placement="auto" overlay={catchall(text)} >
                    <span className="divlink">{code} </span>
                </OverlayTrigger>
            </div>)
    }

    if(old < nw) {
        let text=<span>Monthly payment higher by <b>{"$" + (nw - old).toFixed(2)} </b></span>      
        return (
            <div >
                <OverlayTrigger
                    trigger={['click' , 'hover' ]} rootClose
                    placement="auto" overlay={catchall(text)} >
                    <span className="divlink">{code} </span>
                </OverlayTrigger>
            </div>)
    }
      
    let text=<span>Monthly payment lower by <b>{"$" + (old - nw).toFixed(2)} !</b></span>      

    return (
        <div >
            <OverlayTrigger
                trigger={['click' , 'hover' ]} rootClose
                placement="auto" overlay={catchall(text)} >
                <span className="divlink">{code} </span>
            </OverlayTrigger>
        </div>)
}

export const MonhtlyChangeBP = (old, nw, breakEven, code) => {

    if( Math.round(old - nw) === 0 ) {
        let text=<span>No improvement</span>      
        return (
            <div >
                <OverlayTrigger
                    trigger={['click' , 'hover' ]} rootClose
                    placement="auto" overlay={catchall(text)} >
                    <span className="divlink">{code} </span>
                </OverlayTrigger>
            </div>)
    }
    let be = ""
    if(breakEven !== -1) {
        be = <div>Break even in <b>{breakEven} months </b></div>
    }
    if(old < nw) {
        let text=<span>Monthly payment higher by <b>{"$" + (nw - old).toFixed(2)} </b></span>      
        return (
            <div >
                <OverlayTrigger
                    trigger={['click' , 'hover' ]} rootClose
                    placement="auto" overlay={catchall(text)} >
                    <span className="divlink">{code} </span>
                </OverlayTrigger>
            </div>)
    }
      
    let text=<span>Monthly payment lower by <b>{"$" + (old - nw).toFixed(2)} ! </b>{be}</span>      

    return (
        <div >
            <OverlayTrigger
                trigger={['click' , 'hover' ]} rootClose
                placement="auto" overlay={catchall(text)} >
                <span className="divlink">{code} </span>
            </OverlayTrigger>
        </div>)
}


export const Tips = ({content, title, placement}) => {

    return (
        <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip id="tooltip" className="text-left" style={{backgroundColor:"EAEFFD"}}>
            <div className="p-3" style={{backgroundColor:"EAEFFD"}}>
                <div style={{backgroundColor:"EAEFFD"}}>
                    <img src="/images/tooltipBulb.png" style={{width:"17px", marginTop:"-5px"}} />
                    <a style={{fontSize:"16px", fontWeight:"700", color: "#1F3988", marginLeft:"10px",backgroundColor:"EAEFFD"}}>INSIGHTS</a>
                </div>
                {title !== "" ? <div className="pt-3" style={{fontSize:"16px", fontWeight:"600",backgroundColor:"EAEFFD"}}>
                    {title}
                </div> : ""}
                <div className="pt-3" style={{backgroundColor:"EAEFFD"}}>
                    {content}
                </div>
            </div>
          </Tooltip>
        }
      >
        <img src="/images/questionMark.png" style={{maxWidth:"15px", maxHeight:"15px", marginLeft:"5px"}}/>
      </OverlayTrigger>

    )
}

export const SimpleTip = ({content, title}) => {
    return <Pop className="pl-2" content={content} title={title} trigger="hover"><QuestionCircleOutlined /></Pop>
}