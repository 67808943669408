import React from "react";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Content from "./components/Content";

import { logo as logoDb } from '../../../../Common'

import classes from './CustomizationPreview.module.css';

const CustomizationPreview = props => {

  const { colors, logo } = props;

  let logoDisplayed = logo || logoDb;

  return (
    <div className={classes.border}>
      <Header colors={colors} logo={logoDisplayed} />
      <div className={classes.layout}>
        <Sidebar colors={colors} />
        <Content colors={colors} logo={logoDisplayed} />
      </div>
    </div>
  );
}

export default CustomizationPreview;