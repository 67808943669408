import React, { useState, useEffect, useRef } from "react";
import "./ReferredRealtors";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as com from "../../Common";

const AddReferredRealtors = (props) => {
  const [validated, setValidated] = useState(false);
  const [resourceFirstName, setResourceFirstName] = useState("");
  const [resourceLastName, setResourceLastName] = useState("");
  const [resourceEmail, setResourceEmail] = useState("");
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);

  const handleSubmit = (event) => {
    // event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    let lst = [{
      FirstName: resourceFirstName,
      LastName: resourceLastName,
      Email: resourceEmail,
    }];

    let token = sessionStorage.getItem("ZeitroA");
    setLoading(true)
    fetch("/re/savereferredrealtors", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(lst),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          alert("Looks like there was a problem, please contact Zeitro for help.");
          return;
        }

        response.json().then((js) => {
          if (js.Status !== "OK") {
            setLoading(false)
            alert("Looks like there was a problem, please contact Zeitro for help.");
          } else {
            setLoading(false)
            alert("Your contact has been saved! If the real estate agent is not in the system yet, she/he will get an invitation email.");
            // window.location.reload();
          }
        });
      })
      .catch(function (err) {
        setLoading(false)
        console.log("Fetch Error:/re/savereferredrealtors :-S", err);
      });
  };

  return (
    <div>
      <h5 className="mt-4 pl-5"><b>Invite a Real Estate Agent</b></h5>
      <div className="pl-5 pr-5">
        Connecting with real estate agents to provide your borrowers with streamlined house purchase and loan application experience.
      </div>
      <div className="addNewResource">
        <Form ref={formRef} validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col className="text-left">
              <Form.Group controlId="name">
                <Form.Label className="text-left">First Name:</Form.Label>
                <Form.Control
                  required
                  name="name"
                  size="sm"
                  autoFocus
                  autoComplete="name"
                  type="name"
                  defaultValue={resourceFirstName}
                  onChange={(e) => {
                    setResourceFirstName(e.target.value);
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="name">
                <Form.Label className="text-left">Last Name:</Form.Label>
                <Form.Control
                  required
                  name="name"
                  size="sm"
                  autoFocus
                  autoComplete="name"
                  type="name"
                  defaultValue={resourceLastName}
                  onChange={(e) => {
                    setResourceLastName(e.target.value);
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="email">
                <Form.Label className="text-left">Email:</Form.Label>
                <Form.Control
                  required
                  name="resourceEmail"
                  size="sm"
                  autoFocus
                  autoComplete="resourceEmail"
                  type="resourceEmail"
                  defaultValue={resourceEmail}
                  onChange={(e) => {
                    setResourceEmail(e.target.value);
                  }}
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="submit"
                style={{ marginTop: "26px", height: "32px" }}
              >
                {loading ? <Spinner animation="border" variant="primary" size="sm" /> : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AddReferredRealtors;
