import React, { useState, useEffect, useRef } from "react";
import "./Properties";
import Spinner from "react-bootstrap/Spinner";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { commaize } from "../../Common";
import NumericalInput, { CommaizedInput } from "../../NumericalInput";
import ImageUploader from "./ImageUploader";

const { SearchBar, ClearSearchButton } = Search;

const CurrentProperties = (props) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const [toRemoveProperty, setToRemoveProperty] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const [toEditProperty, setToEditProperty] = useState({});

  const actionsFormatter = (cell, row) => (
    <div>
      <Button
        variant="remove"
        style={{backgroundColor:"#01183c"}}
        onClick={() => {
          setToEditProperty(row);
          setShowEditModal(true);
        }}
      >
        Edit
      </Button>{" "}
      <Button
        variant="remove"
        onClick={() => {
          setToRemoveProperty(row);
          setShowRemoveModal(true);
        }}
      >
        Remove
      </Button>
    </div>
  );
  const imageFormatter = (cell, row, i) => (
    <div>
      {row.ImageSRCs.map((x, y) => (
        <img src={x} style={{ maxWidth: "30px", maxHeight: "30px" }} />
      ))}
    </div>
  );
  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "solid 2px",
    fontSize: "120%",
  };
  let columns = [
    // {
    //   dataField: "Status",
    //   text: "Status",
    //   sort: true,
    //   headerStyle: headerStyle,
    // },
    {
      dataField: "Address",
      text: "Address",
      headerStyle: { ...headerStyle, minWidth: "100px" },
      style: {overflowWrap: "break-word"},
    },
    {
      dataField: "ListingPrice",
      text: "Listing Price",
      headerStyle: headerStyle,
      formatter: (cell, row, i) => "$" + commaize(cell),
    },
    {
      dataField: "NumOfBeds",
      text: "Number of Beds",
      headerStyle: headerStyle,
    },
    {
      dataField: "NumOfBaths",
      text: "Number of Baths",
      headerStyle: headerStyle,
    },
    {
      dataField: "Sqft",
      text: "Sqft.",
      headerStyle: headerStyle,
      formatter: (cell, row, i) => commaize(cell),
    },
    {
      dataField: "ImageSRCs",
      text: "Photos",
      formatter: imageFormatter,
      headerStyle: headerStyle,
      style: {overflowWrap: "break-word"}
    },
    {
      dataField: "Remove",
      text: "",
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: { ...headerStyle, minWidth: "150px" },
    },
  ];

  const getProperties = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getproperties", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-LO": props.id,
      },
    }).then((response) => {
      if (response.status !== 200) {
        setLoading(false);
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((r) => {
        if (r) {
          setProperties(r);
          console.log(properties);
        }
        setLoading(false);
      });
    });
  };

  const removeProperty = () => {
    setLoading(true);
    console.log("removing", toRemoveProperty);

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/removeproperty", {
      method: "POST",
      body: JSON.stringify(toRemoveProperty),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        setLoading(false);
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      setProperties([]);
      getProperties();
    });
    setLoading(false);
    setShowRemoveModal(false);
  };

  const getRemoveModal = () => {
    return (
      <Modal show={showRemoveModal} onHide={handleCloseRemoveModal}>
        <Modal.Header closeButton>
          <Modal.Title>Removing Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {toRemoveProperty.Address}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseRemoveModal}>
            Close
          </Button>
          <Button onClick={removeProperty}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const changeEditProperty = (e, k, isInt) => {
    let tmp = toEditProperty;
    if (isInt) {
      tmp[k] = parseInt(e.target.value);
    } else {
      tmp[k] = e.target.value;
    }
    setToEditProperty(tmp);
  };

  const getEditModal = () => {
    return (
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Editing Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editProperty}>
            <Row>
              <Col className="text-left" xs={3}>
                <Form.Group>
                  <Form.Label>Status:</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    defaultValue={toEditProperty.Status}
                    onChange={(e) => changeEditProperty(e, "Status")}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="text-left" xs={9}>
                <Form.Group>
                  <Form.Label>Address:</Form.Label>
                  <Form.Control
                    required
                    size="sm"
                    defaultValue={toEditProperty.Address}
                    onChange={(e) => changeEditProperty(e, "Address")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="text-left">
                <Form.Group>
                  <Form.Label>Property Type:</Form.Label>
                  <Form.Control
                    name="propertytype"
                    as="select"
                    size="sm"
                    type="text"
                    required
                    defaultValue={toEditProperty.PropertyType}
                    onChange={(e) => changeEditProperty(e, "PropertyType")}
                  >
                    <option value="singlefamilyattached">
                      Single Family Attached
                    </option>
                    <option value="singlefamilydetached">
                      Single Family Detached
                    </option>
                    <option value="condominium">Condominium Attached</option>
                    <option value="condominiumdetached">
                      Condominium Detached
                    </option>
                    <option value="pudattached">PUD attached</option>
                    <option value="puddetached">PUD detached</option>
                    <option value="twotofourfamily">
                      2 to 4 Unit Multifamily
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="text-left">
                <Form.Group controlId="numOfBeds">
                  <Form.Label className="text-left">Number of Beds</Form.Label>
                  <Form.Control
                    required
                    name="numOfBeds"
                    size="sm"
                    as="select"
                    defaultValue={toEditProperty.NumOfBeds}
                    onChange={(e) => changeEditProperty(e, "NumOfBeds", true)}
                  >
                    {Array.from({ length: 10 }, (_, i) => i + 1).map((x, y) => (
                      <option value={x}>{x}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="text-left">
                <Form.Group controlId="numOfBaths">
                  <Form.Label className="text-left">Number of Baths</Form.Label>
                  <Form.Control
                    required
                    name="numOfBaths"
                    size="sm"
                    as="select"
                    defaultValue={toEditProperty.NumOfBaths}
                    onChange={(e) => changeEditProperty(e, "NumOfBaths", true)}
                  >
                    {Array.from({ length: 10 }, (_, i) => i + 1).map((x, y) => (
                      <option value={x}>{x}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="text-left">
                <Form.Label className="text-left">Sqrt:</Form.Label>
                <CommaizedInput
                  step={100}
                  size="sm"
                  defaultValue={toEditProperty.Sqft}
                  required
                  onChange={(e) => changeEditProperty(e, "Sqft")}
                  min={0}
                />
              </Col>
              <Col className="text-left">
                <Form.Group controlId="minPrice">
                  <Form.Label className="text-left">Listing Price:</Form.Label>
                  <NumericalInput
                    step={10000}
                    size="sm"
                    defaultValue={toEditProperty.ListingPrice}
                    required
                    onChange={(e) => changeEditProperty(e, "ListingPrice")}
                    min={0}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <ImageUploader
                  onChangeImageSRCs={(newImgs) => {
                    let tmp = toEditProperty;
                    tmp.ImageSRCs = newImgs;
                    setToEditProperty(tmp);
                  }}
                />
              </Col>
              <Col>
                {toEditProperty.ImageSRCs.map((x, y) => (
                  <img
                    src={x}
                    style={{ maxWidth: "30px", maxHeight: "30px" }}
                  />
                ))}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button onClick={editProperty}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const editProperty = () => {

    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    let data = {...toEditProperty}
    data.ImageSRCs = data.ImageSRCs.map((v) => v.base64 !== undefined ? v.base64 : v)
    console.log(data)
    fetch("/re/editproperty", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {

      if (response.status !== 200) {
        setLoading(false)
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      setProperties([])
      getProperties();
    });
    setLoading(false)
    setShowEditModal(false);
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <div className="mt-4">
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : properties.length === 0 ? (
        <h5 className="mt-5">You haven't added any properties yet.</h5>
      ) : (
        <ToolkitProvider
          bootstrap4
          keyField="email"
          data={properties}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <Row className="ml-3">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
              </Row>

              <BootstrapTable
                id="properties"
                setProperties
                bordered={false}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                })}
                {...props.baseProps}
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      )}
      {showRemoveModal && getRemoveModal()}
      {showEditModal && getEditModal()}
    </div>
  );
};
export default CurrentProperties;
