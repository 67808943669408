import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../Photo/Dropzone.css';
import './LoanOfficers.css';
import { Row, Col, Form, Button, Modal, Tabs, Tab, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Dropzone from '../Photo/Dropzone'
import * as com from '../Common'
import { UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner } from "../State";
import { RBAC, checkAccess } from "../Auth";
const { SearchBar, ClearSearchButton } = Search;

class CreateLO extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.imageref = React.createRef();
    this.password = React.createRef();
    this.password1 = React.createRef();
    this.state = {
      validated: false,
      lastName: "",
      firstName: "",
      phone: "",
      email: "",
      nmls: "",
      password: "",
      password1: "",
      roles: [UR_LoanOfficer],
      checkboxValidationColors: null,
      required: true,
      delete: false,
      avatarsrc: "/avatar.png"
    }
  }
  sendAccount = () => {
    let tosend = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      password: this.state.password,
      phone: this.state.phone,
      nmls: this.state.nmls,
      image: this.state.avatarsrc,
      roles: this.state.roles
    }
    let token = sessionStorage.getItem("ZeitroA")
    console.log(JSON.stringify(tosend))
    console.log("token: " + token)

    fetch('/los/createloaccount', {
      method: 'POST',
      body: JSON.stringify(tosend),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      },
    }).then(
      response => {
        if (response.status !== 200) {
          alert('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {
          if (js.Status !== "OK") {
            alert("Account was not created. Error: " + js.Text)
          } else {
            alert("Account for " + this.state.email + " has been created!")
          }
        });
      }
    ).catch(function (err) {
      console.log('Fetch Error :-S', err);
    });
  }
  componentDidMount() {

  }
  isValidated() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated: true })
      return false
    };
    return true;
  }
  isValidated1() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated1: true })
      return false
    };
    return true;
  }
  handleSubmit = (event) => {
    this.checkPasswordValidity()

    if (this.state.roles.length !== 0) {
      this.setState({ checkboxValidationColors: '#28a745' })
    } else {
      this.setState({ checkboxValidationColors: '#dc3545' })
    }

    if (this.form.current.reportValidity() === false || this.state.roles.length === 0) {

      event.preventDefault();
      this.setState({ validated: true })
      return false
    }
    event.preventDefault();
    this.setState({ validated: false })
    event.stopPropagation();

    this.sendAccount()
    return false
  }

  checkPasswordValidity = () => {
    console.log("%s, %s", this.state.password, this.state.password1)
    if ((this.state.password === undefined && this.state.password1 !== undefined) || (this.state.password !== undefined && this.state.password1 === undefined)) {
      this.password1.current.setCustomValidity("")
      return
    }
    if (this.state.password !== this.state.password1) {
      this.password1.current.setCustomValidity("Passwords need to match")
    } else {
      this.password1.current.setCustomValidity("")
    }
  }
  onpassword = e => {
    console.log(e.target.value)
    this.setState({ password: e.target.value })
    //this.state.password = e.target.value
    this.checkPasswordValidity()
  }
  onpassword1 = e => {
    this.setState({ password1: e.target.value })
    //this.state.password1 = e.target.value
    this.checkPasswordValidity()
  }
  changeFirstName = (e) => {
    this.setState({ firstName: e.target.value })
  }
  changeLastName = (e) => {
    console.log(e.target.value)
    this.setState({ lastName: e.target.value })
  }
  changeNMLS = e => {
    this.setState({ nmls: e.target.value })
  }
  changeUsername = (e) => {
    console.log(e.target.value)
    this.setState({ email: e.target.value })
  }
  changeRoles = e => {
    const value = e.target.id;
    this.setState(prev => {
      return {
        ...prev,
        roles: prev.roles.includes(value) ? prev.roles.filter(el => el !== value) : [...prev.roles, value]
      }
    });
  }
  onFilesAdded = (files) => {
    let file = files[0]
    let reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (e) => {
      // Render thumbnail
      this.setState({ avatarsrc: e.target.result })
      this.imageref.current.src = e.target.result
    }

    // Read in the image file as a data URL.
    reader.readAsDataURL(file);
  }
  changePhone = (e) => {
    let phone = com.fixPhoneInput(e.target.value)
    this.setState({ phone: phone })
  }
  render() {
    return (
      <div style={{ height: '100%' }} className="text-center mt-0 px-5  text-body">
        <Form ref={this.form} id="personalform" autoComplete="off" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <Row >
            <Col md="auto" style={{display: 'flex', justifyContent: 'center'}}><Dropzone
              disabled={false}
              onFilesAdded={this.onFilesAdded}

              imageref={this.imageref}
            />
            </Col>
            <Col className="text-left pt-1 upload-profile-btn">
              <h4 style={{marginBottom: 24}}>Create New Loan Officer Account</h4>
              <Dropzone
                  disabled={false}
                  type="button"
                  btnText="Add your photo"
                  onFilesAdded={this.onFilesAdded}
                  imageref={this.imageref}
              />
            </Col>
          </Row>

          <Row className="mt-2 pt-2 border-top border-dark">


            <Col className="text-left">
              <Form.Group controlId="qeqwe" className="text-left" >
                <Form.Label className="text-left" >Email:</Form.Label>
                <Form.Control isValid={false} size="sm"
                  required
                  name="username"
                  type="text"

                  onChange={this.changeUsername}
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"

                />
                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" >
                  Please provide a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="firstname" className="text-left" >
                <Form.Label className="text-left" >First Name:</Form.Label>
                <Form.Control isValid={false} size="sm"
                  required
                  name="firstname"
                  type="text"
                  defaultValue={this.state.firstName}
                  onChange={this.changeFirstName}
                  pattern="^[\w\s-@.,\/#&]+$"
                />
                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" >
                  Please provide your first name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="lastname" className="text-left" >
                <Form.Label className="text-left" >Last Name:</Form.Label>
                <Form.Control isValid={false} size="sm"
                  required
                  name="lastname"
                  type="text"
                  defaultValue={this.state.lastName}
                  onChange={this.changeLastName}
                  pattern="^[\w\s-@.,\/#&]+$"
                />
                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" >
                  Please provide your last name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <RBAC allowedRoles={[UR_Owner]}>
              <Col className="text-left">
                <Form.Group controlId="roles" className="text-left">
                  {[UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner].map((el,i) =>
                    <div key={i}>
                      <Form.Check
                        style={{ display: 'inline', marginRight: '5px' }}
                        type='checkbox'
                        id={el}
                        checked={this.state.roles.includes(el)}
                        onChange={this.changeRoles}
                      />
                      <Form.Check.Label style={{ color: `${this.state.checkboxValidationColors}` }} htmlFor={el}>{com.unCamelCase(el)}</Form.Check.Label>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </RBAC>
          </Row>


          <Row className="text-left mt-3 mb-1">
            <Col className="text-left">
              <Form.Group controlId="contactphone" className="text-left" >
                <Form.Label className="text-left" >Contact Phone:</Form.Label>
                <Form.Control isValid={false} size="sm"
                  required
                  name="contactphone"
                  type="text"
                  value={this.state.phone}
                  placeholder="(###)-###-####"
                  onChange={this.changePhone}
                  pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                />
                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" >
                  Please provide your contact phone.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col className="text-left">
              <Form.Group controlId="newpassword" className="text-left" >
                <Form.Label className="text-left" >Password:</Form.Label>
                <Form.Control isValid={false} size="sm"
                  required
                  ref={this.password}
                  name="dasd"
                  type="password"
                  autoComplete="new-password"
                  value={this.state.password}
                  onChange={this.onpassword}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                />
                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" >
                  Password should be 8-20 characters long with a number, mix of small and large letters.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="newpassword1" className="text-left" >
                <Form.Label className="text-left" >Retype Password:</Form.Label>
                <Form.Control isValid={false} size="sm"
                  required
                  ref={this.password1}
                  name="newpassword1"
                  type="password"
                  value={this.state.password1}
                  autoComplete="new-password"
                  onChange={this.onpassword1}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                />
                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" >
                  Passwords should be identical.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="text-left">
              <Form.Group controlId="username" className="text-left" >
                <Form.Label className="text-left" >NMLS License:</Form.Label>
                <Form.Control isValid={false} size="sm"
                  required
                  name="username"
                  type="text"
                  placeholder="#######"
                  defaultValue={this.state.nmls}
                  onChange={this.changeNMLS}
                  pattern="^[0-9]{6,7}$"

                />
                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" >
                  Please provide a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
            </Col>
            <Col>
            </Col>
          </Row>
          <Row className="text-center pt-3 pb-3  ">

            <Col className="text-center" md-auto="true">
              <Button variant="primary" type="submit" className="text-center ml-3"  >&nbsp;&nbsp;Create Account&nbsp;&nbsp;</Button>
            </Col>

          </Row>



        </Form>

      </div>
    )
  }
}
class UpdateLO extends Component {
  constructor(props) {
    super(props)

    this.form = React.createRef();
    this.password = React.createRef();
    this.password1 = React.createRef();
    this.state = {
      validated: false,
      firstname: props.firstname,
      lastname: props.lastname,
      nmls: props.nmls,
      roles: props.roles || [],
    }
  }
  /*
  static getDerivedStateFromProps(nextProps, prevState) {
      if(prevState.id !== nextProps.id) {
          console.log(JSON.stringify(nextProps))
          return {
              firstname: nextProps.firstname,
              lastname: nextProps.lastname,
          }
      }
      else return {}
     }
  */
  updateTheProfile = a => {
    let token = sessionStorage.getItem("ZeitroA")
    let body = {
      id: this.props.id.toString(),
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      password: this.state.password,
      nmls: this.state.nmls,
      roles: this.state.roles
    }
    fetch('/los/updatelo', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(
      response => {
        if (response.status !== 200) {
          alert('Looks like there was a problem. Status Code: ' +
            response.status);
          this.props.onHide()
          return;
        }
        // Examine the text in the response
        response.json().then(js => {
          if (js.Status !== "OK") {
            alert("Error: " + js.Text)
            this.props.onHide()

          } else {
            this.props.onHide()

          }
        });
      }
    ).catch(function (err) {
      alert('Fetch Error :-S', err);
    });
  }

  handleSubmit = e => {

    this.checkPasswordValidity()
    if (this.form.current.reportValidity() === false) {

      e.preventDefault();
      e.stopPropagation();
      this.setState({ validated: true })
      return false
    }


    this.updateTheProfile()
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
  checkPasswordValidity = () => {
    console.log("%s, %s", this.state.password, this.state.password1)
    if ((this.state.password === undefined && this.state.password1 !== undefined) || (this.state.password !== undefined && this.state.password1 === undefined)) {
      this.password1.current.setCustomValidity("")
      return
    }
    if (this.state.password !== this.state.password1) {
      this.password1.current.setCustomValidity("Passwords need to match")
    } else {
      this.password1.current.setCustomValidity("")
    }
  }
  changeFirstName = e => {
    this.setState({ firstname: e.target.value })
  }
  changeLastName = e => {
    this.setState({ lastname: e.target.value })
  }
  changeNMLS = e => {
    this.setState({ nmls: e.target.value })
  }
  changeRoles = e => {
    const value = e.target.id;
    this.setState(prev => {
      return {
        ...prev,
        roles: prev.roles.includes(value) ? prev.roles.filter(el => el !== value) : [...prev.roles, value]
      }
    });
  }
  onPassword = e => {
    this.setState({ password: e.target.value })
  }
  onPassword1 = e => {
    this.setState({ password1: e.target.value })
  }
  render = () => {

    return (
      <Modal {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Modify the account for {this.props.firstname}  {this.props.lastname}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-5 text-center">
          <Form autocomplete="off" ref={this.form} id="updateform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>


            <Row className="mt-5 pt-2 border-top border-dark">

              <Col className="text-left">
                <Form.Group controlId="firstname" className="text-left" >
                  <Form.Label className="text-left" >First Name</Form.Label>
                  <Form.Control isValid={false} size="sm"
                    required
                    name="firstupdate"
                    type="text"
                    value={this.state.firstname}
                    onChange={this.changeFirstName}
                    pattern="^[\w\s-@.,\/#&]+$"
                  />
                  <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" >
                    Please provide your first name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="text-left">
                <Form.Group controlId="lastname" className="text-left" >
                  <Form.Label className="text-left" >Last Name</Form.Label>
                  <Form.Control isValid={false} size="sm"
                    required
                    name="lastupdate"
                    type="text"
                    autocomplete="new-password"
                    value={this.state.lastname}
                    onChange={this.changeLastName}
                    pattern="^[\w\s-@.,\/#&]+$"
                  />
                  <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" >
                    Please provide your last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <RBAC allowedRoles={[UR_Owner]}>
                <Col className="text-left">
                  <Form.Group controlId="roles" className="text-left">
                    {[UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner].map((el, i) =>
                      <div key={i}>
                        <Form.Check
                          style={{ display: 'inline', marginRight: '5px' }}
                          type='checkbox'
                          id={el}
                          checked={this.state.roles.includes(el)}
                          onChange={this.changeRoles}
                        />
                        <Form.Check.Label style={{ color: `${this.state.checkboxValidationColors}` }} htmlFor={el}>{el}</Form.Check.Label>
                      </div>
                    )}
                  </Form.Group>
                </Col>

              </RBAC>
            </Row>


            <Row className="text-left mt-3 mb-1">
              <Col className="text-left">
                <Form.Group controlId="newpassword" className="text-left" >
                  <Form.Label className="text-left" >New Password</Form.Label>
                  <Form.Control isValid={false} size="sm"
                    ref={this.password}
                    name="newpassword"
                    autocomplete="new-password"
                    type="password"
                    value={this.state.password}
                    onChange={this.onPassword}
                    pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                  />
                  <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" >
                    Password should be 8-20 characters long with a number, mix of small and large letters.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="text-left">
                <Form.Group controlId="newpassword1" className="text-left" >
                  <Form.Label className="text-left" >Retype New Password</Form.Label>
                  <Form.Control isValid={false} size="sm"
                    ref={this.password1}
                    autocomplete="off"
                    name="newpassword1"
                    type="password"
                    value={this.state.password1}

                    onChange={this.onPassword1}
                    pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                  />
                  <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" >
                    Passwords should be identical.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="text-left">
                <Form.Group controlId="username" className="text-left" >
                  <Form.Label className="text-left" >NMLS License:</Form.Label>
                  <Form.Control isValid={false} size="sm"
                    required
                    name="username"
                    type="text"
                    defaultValue={this.state.nmls}
                    onChange={this.changeNMLS}
                    pattern="^[0-9]{6,7}$"

                  />
                  <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" >
                    Please provide a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-center pt-3 pb-3  ">

              <Col className="text-center" md-auto="true">
                <Button variant="primary" type="submit" className="text-center ml-3"  >&nbsp;&nbsp;Update Account&nbsp;&nbsp;</Button>
              </Col>

            </Row>

          </Form>

        </Modal.Body>
      </Modal>
    )
  }
}
function DeleteLO(props) {
  const nukeTheProfile = a => {
    let token = sessionStorage.getItem("ZeitroA")
    let body = { id: props.id }
    fetch('/los/deleteloanofficer', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(
      response => {
        if (response.status !== 200) {
          alert('Looks like there was a problem. Status Code: ' +
            response.status);
          props.onHide()
          return;
        }
        // Examine the text in the response
        response.json().then(js => {
          if (js.Status !== "OK") {
            alert("Error: " + js.Text)
            props.onHide()

          } else {
            props.onHide()

          }
        });
      }
    ).catch(function (err) {
      alert('Fetch Error :-S', err);
    });
  }

  return (
    <Modal {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Delete the loan officer account for {props.username} </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-5 text-center">
        <p>
          This operation is irreversible. Are you sure you want to do this?
        </p>
        <Row className="mt-5">
          <Col className="text-center">
            <Button variant="success" onClick={props.handleClose}>No, keep {props.username}</Button>
          </Col>
          <Col className="text-center">
            <Button variant="danger" onClick={nukeTheProfile}>Yes, delete {props.username}</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
class LOManager extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loanofficers: [],
      delete: false,
      selectedname: "",
      selectedid: -1,
      loading: true,
    }
    this.aref = React.createRef();

    this.columns = [
      {
        dataField: 'image',
        text: 'Picture',
        classes: "avatartd",
      },
      {
        dataField: 'firstname',
        text: 'First Name',
        sort: true
      }, {
        dataField: 'lastname',
        text: 'Last Name',
        sort: true
      }, {
        dataField: 'email',
        text: 'Email',
        sort: true,
        classes: 'email-column'
      }, {
        dataField: 'phone',
        text: 'Phone'
      }, {
        dataField: 'nmls',
        text: 'Nmls license',
      },
      ...(checkAccess([UR_Owner]) ?
        [{
          dataField: 'roles',
          text: 'Roles',
          formatter: (cell, row) => {
            let tmp = ''
            cell.forEach((el, i) => {
              if (i !== cell.length - 1) {
                tmp += com.unCamelCase(el) + ', '
              } else {
                tmp += com.unCamelCase(el)
              }
            })
            return <span>{tmp}</span>
          }
        }] : []), {
        dataField: 'update',
        text: 'Update',
        classes: 'divlink',
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.showUpdate(row.id, row.firstname, row.lastname, row.nmls, row.roles)
          },
        }
      }, {
        dataField: 'delete',
        text: 'Delete'
      }, {
        dataField: 'id',
        text: 'id',
        hidden: true,
      }
    ];
  }

  losJSON = (document, index) => {
    let showDelete = (id, firstname, lastname) => {
      return e => this.showDelete(id, firstname + " " + lastname)
    }
    return {
      image: <img alt="" className="avatar" style={{ width: '32px' }} src={document.image} ></img>,
      firstname: document.firstname,
      lastname: document.lastname,
      email: document.email,
      phone: document.phone,
      nmls: document.nmls,
      roles: document.roles,
      update: "Update",
      delete: <Button className="mybutton" onClick={showDelete(document.id, document.firstname, document.lastname)} variant="Link">Delete</Button>,
      id: document.id,
    }
  }
  componentDidMount() {
    this.reload()
  }
  reload = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/owner/loanofficerlist', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          this.setState({ loading: false })
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            console.log("run map")
            let docs = js.LoanOfficers.map(s => {
              return {
                rand: Math.random(), id: s.ID, email: s.Email,
                firstname: s.FirstName, lastname: s.LastName, phone: s.Phone, nmls: s.Nmls, roles: s.Roles, image: s.Image === "" ? "/avatar.png" : s.Image
              }
            })
            this.setState({ loanofficers: docs })
          }
          this.setState({ loading: false })
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
        this.setState({ loading: false })
      });
  }
  showDelete = (id, name) => {
    console.log("show delete %s, %s", id, name)
    this.setState({ selectedid: id, selectedname: name })
    this.setState({ delete: true })
  }
  hideDelete = () => {
    this.setState({ delete: false })
    this.reload()
  }
  showUpdate(id, firstname, lastname, nmls, roles) {
    this.setState({ selectedid: id, selectedfirstname: firstname, selectedlastname: lastname, selectednmls: nmls, selectedroles: roles })
    this.setState({ update: true })
  }
  hideUpdate = () => {
    this.setState({ update: false })
    this.reload()
  }
  showSpinner = () => {
    if (this.state.loading)
      return (
        <div className="my-5 py-5 w-100 text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )
    else return (
      ""
    )
  }
  render() {
    return (
      <div className="text-left mt-3 mx-3">
        <ToolkitProvider
          bootstrap4
          keyField='email'
          data={this.state.loanofficers.map(this.losJSON)}
          columns={this.columns}
          search >
          {
            props => (
              <div>
                <h3>Loan Officers:</h3>
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
                <BootstrapTable id="loans"
                  striped bootstrap4 bordered={false}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
        {this.showSpinner()}
        <DeleteLO
          key={this.state.selectedid + "_DeleteLO"}
          show={this.state.delete}
          id={this.state.selectedid}
          username={this.state.selectedname}
          onHide={this.hideDelete}
          handleClose={() => { this.setState({ delete: false }) }}
        />
        <UpdateLO
          key={this.state.selectedid + "_UpdateLO"}
          show={this.state.update}
          id={this.state.selectedid}
          firstname={this.state.selectedfirstname}
          lastname={this.state.selectedlastname}
          nmls={this.state.selectednmls}
          roles={this.state.selectedroles}
          onHide={this.hideUpdate}
          handleClose={() => { this.setState({ update: false }) }}
        />
      </div>
    )
  }
}
class LoanOfficers extends Component {

  componentDidMount() {
  }
  render() {
    return (
      <Tabs defaultActiveKey="upload" unmountOnExit="true" transition={false} id="documenttabs">
        <Tab style={{ height: '100%' }} eventKey="upload" title="Create New Loan Officer">
          <CreateLO />
        </Tab>
        <Tab style={{ height: '100%' }} eventKey="review" title="Manage Loan Officers">
          <LOManager />
        </Tab>
      </Tabs>
    )
  }
}
export default LoanOfficers
