import React, { useState, useEffect, useRef } from "react";
import "./ReferBorrowers.js";
import Spinner from "react-bootstrap/Spinner";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { commaize } from "../Common";
import NumericalInput, { CommaizedInput } from "../NumericalInput";

const { SearchBar, ClearSearchButton } = Search;

const CurrentBorrowers = (props) => {
  const [borrowers, setBorrowers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const [toRemoveBorrower, setToRemoveBorrower] = useState({});


  const removeFormatter = (cell, row) => (
    <div>
      <Button
        variant="remove"
        onClick={() => {
          setToRemoveBorrower(row);
          setShowRemoveModal(true);
        }}
      >
        Remove
      </Button>
    </div>
  );

  const statusFormatter = (cell, row) => (
    <div>
      {row.LoName === "" ? <>
      <div>Unregistered</div>
      <Button
        variant="link"
        onClick={() => {
          resend(row)
        }}
      >
        {loadingResend ? <Spinner animation="border" size="sm" />: "Resend Invidation Email"}
      </Button>
      </> : <>Registered with {row.LoName} ({row.LoEmail})</>}
    </div>
  );

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "solid 2px",
    fontSize: "120%",
  };
  let columns = [
    {
      dataField: "Name",
      text: "Name",
      headerStyle: headerStyle,
    },
    {
      dataField: "Email",
      text: "Email",
      headerStyle: headerStyle,
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: statusFormatter,
      headerStyle: headerStyle,
    },
    {
      dataField: "Remove",
      text: "",
      isDummyField: true,
      formatter: removeFormatter,
      headerStyle: headerStyle,
    },
  ];

  const getBorrowers = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getreferborrowers", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "Cache-Control": "no-cache, no-store, must-revalidate",
      },
    }).then((response) => {
      if (response.status !== 200) {
        setLoading(false);
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((r) => {
        if (r) {
          setBorrowers(r);
          console.log(borrowers);
        }
        setLoading(false);
      });
    });
  };

  const removeBorrower = () => {
    setLoading(true);
    console.log("removing", toRemoveBorrower);

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/removereferborrower", {
      method: "POST",
      body: JSON.stringify(toRemoveBorrower),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        setLoading(false);
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      setBorrowers([]);
      getBorrowers();
    });
    setLoading(false);
    setShowRemoveModal(false);
  };

  const getRemoveModal = () => {
    return (
      <Modal show={showRemoveModal} onHide={handleCloseRemoveModal}>
        <Modal.Header closeButton>
          <Modal.Title>Removing Referred Borrower</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {toRemoveBorrower.Name}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseRemoveModal}>
            Close
          </Button>
          <Button onClick={removeBorrower}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const resend = (row) => {
    setLoadingResend(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/resendreferborrower", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "Cache-Control": "no-cache, no-store, must-revalidate",
      },
      body: JSON.stringify(
        {
          email: row.Email,
          name: row.Name,
        }
      ),
    }).then((response) => {
      if (response.status !== 200) {
        setLoadingResend(false);
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((js) => {
        setLoadingResend(false)
        if (js.Status !== "OK") {
          alert("Looks like there was a problem. Please contact Zeitro for help.");
        } else {
          alert("We've resent the invitation email to " + row.Email + ".");
        }
      });
    });
  };


  useEffect(() => {
    getBorrowers();
  }, []);

  return (
    <div className="mt-4">
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : borrowers.length === 0 ? (
        <h5 className="mt-5">You haven't refered any borrower yet.</h5>
      ) : (
        <ToolkitProvider
          bootstrap4
          keyField="email"
          data={borrowers}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <Row className="ml-3">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
              </Row>

              <BootstrapTable
                id="borrowers"
                bordered={false}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                })}
                {...props.baseProps}
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      )}
      {showRemoveModal && getRemoveModal()}
    </div>
  );
};
export default CurrentBorrowers;
