import React, { Component, useState } from "react";
import "./Borrowers.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Row, Col, Form, Button as But } from 'react-bootstrap'
import { Button, Input, Space, Table, Skeleton, Switch, Tag, Modal, Select, Spin, Dropdown } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as com from '../Common'
import { UR_LoanOfficer, UR_Owner } from "../State";
import { capitalize } from "lodash";
import { checkAccess } from "../Auth";
import {CaretDownOutlined} from '@ant-design/icons';

function AssignLoanOfficer(props) {

  const updateLoanOfficer = (id, sid) => {
    let token = sessionStorage.getItem("ZeitroA")
    let body = { loanid: id, losid: sid }
    fetch('/agent/assignlostoborrower', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(
      response => {
        if (response.status !== 200) {
          alert('Looks like there was a problem. Status Code: ' +
            response.status);
          props.onHide()
          return;
        }
        // Examine the text in the response
        response.json().then(js => {
          if (js.Status !== "OK") {
            alert("Error: " + js.Text)
            props.onHide()

          } else {
            props.onHide()

          }
        });
      }
    ).catch(function (err) {
      alert('Fetch Error :-S', err);
    });
  }
  const [sid, setSid] = useState("");
  const handleSubmit = event => {
    updateLoanOfficer(props.loan_id, sid)
  }
  const showOptions = () => {
    const selected = e => {
      setSid(e.target.value)
    }

    return (
      <Row className="mt-1">
        <Col className="px-0">
          <Form.Group controlId="propertytype" className="text-left" >
            <Form.Control name="propertytype" as="select"
              defaultValue={props.los_id}
              type="text"
              required
              onChange={selected}
              style={{fontSize:14, borderRadius:6, border:"1px solid #ECECEC"}}
            >
              <option value="" >Select loan officer</option>

              {props.loanofficers.map(t => {
                return (
                  <option value={t.id}>{t.firstname} {t.lastname}</option>
                )
              }
              )
              }
            </Form.Control>
            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" >
              Please select loan officer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    )

  }
  const showNotes = () => {
    if (sid === "") return <></>
    const newLOName = props.loanofficers.find(t => t.id === sid).firstname + " " + props.loanofficers.find(t => t.id === sid).lastname
    if (props.loName === newLOName) return <></>
    return (
      <Row style={{marginTop:-10}}>
        <Col className="px-0">
          <div style={titleStyle}>*{newLOName} will replace {props.loName} as the borrower's loan officer</div>
        </Col>
      </Row>
    )
  }
  const titleStyle = {fontSize:12, color:"#6E6E70", fontWeight:500}
  return (
    <Modal
      {...props}
      title="Assign loan officer to the borrower"
      open={props.show}
      onCancel={props.handleClose}
      onOk={handleSubmit}
      okText="Submit"
      okButtonProps={{
        disabled: sid==="",
      }}
      >
        <div className="pt-3 pb-3">

          <Row style={{borderRadius:6, backgroundColor:"#F5F5F7", padding:"15px 23px"}}>
            <Col>
              <div style={titleStyle}>Borrower</div>
              <div>{props.name}</div>
            </Col>
            <Col>
              <div style={titleStyle}>Assigned Loan Officer</div>
              <div>{props.loName}</div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="px-0">
            <div style={titleStyle}>Loan Officer</div>
            <div>
              {showOptions()}
            </div>
            </Col>
          </Row>
          {showNotes()}


        </div>
    </Modal>
  )
}

class Borrower extends Component {

  constructor(props) {
    super(props);
    this.state = {
      borrowers: [],
      allborrowers: [],
      loanofficers: {},
      name: "", // borrower name for assigning loan officer
      loName: "", // loan officer name for assigning loan officer
      id: "",
      loanofficers_full: [],
      loading: true,
      showassign: false,
      searchText: '',
      searchedColumn: '',
      loading: false,
      showErrorModal: false,
      editingCell: { loanID: null, columnKey: null },
      loadingStatus: false,
    }
    this.searchInput = React.createRef();

  }
  toggleEdit = (loanID, columnKey) => {
    this.setState(prevState => ({
      editingCell: {
        loanID: prevState.editingCell.loanID === loanID && prevState.editingCell.columnKey === columnKey ? null : loanID,
        columnKey: prevState.editingCell.columnKey === columnKey && prevState.editingCell.loanID === loanID ? null : columnKey
      }
    }));
  }
  

  handleStatusChange = (loanID, newStatus) => {
    this.setState({ loadingStatus: true })
    let token = sessionStorage.getItem("ZeitroA")
    let body = { LoanID: loanID, Key: "status", Value: newStatus, IsAdmin: checkAccess([UR_Owner]) ? true : false }
    fetch('/agent/updatepipeline', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        this.setState({ showErrorModal: true, loadingStatus: false })
        return;
      }
      this.toggleEdit(null, null);
      const updatedBorrowers = this.state.borrowers.map(borrower => {
        if (borrower.loan_id === loanID) {
          return { ...borrower, status: newStatus };
        }
        return borrower;
      });
      this.setState({ borrowers: updatedBorrowers, loadingStatus: false });
    })
    .catch(function (err) {
      console.log('Fetch Error :-S', err);
    })
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  componentDidMount() {
    this.reload()
  }
  getLoanofficers = () => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/owner/loanofficerlist', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          this.setState({ loading: false })
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            console.log("run map")
            let docs = js.LoanOfficers.filter(el => el.Roles.includes(UR_LoanOfficer));
            docs = docs.map(s => {
              let lo = this.state.loanofficers
              lo[s.ID] = s.FirstName + " " + s.LastName
              this.setState({ loanofficers: lo })

              return {
                rand: Math.random(), id: s.ID, email: s.Email,
                firstname: s.FirstName, lastname: s.LastName, phone: s.Phone, image: s.Image === "" ? "/avatar.png" : s.Image
              }
            })
            this.setState({ loanofficers_full: docs })
          }
          this.setState({ loading: false })
          let br = this.state.borrowers.map(s => {
            let lo = "Unassigned"
            if (this.state.loanofficers[s.los_id])
              lo = this.state.loanofficers[s.los_id]

            return { ...s, loanofficer: lo, rand: Math.random() }
          }


          )
          this.setState({ borrowers: br, allborrowers: br })
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
        this.setState({ loading: false })

      });
  }
  reload = () => {
    this.setState({ loading: true })
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/agent/loansformanagement', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          this.setState({ loading: false })
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            if (js.Borrowers) {
              let docs = js.Borrowers.map(s => {
                return {
                  rand: Math.random(), id: s.ID, loan_id: s.LoanID, firstname: s.FirstName,
                  lastname: s.LastName,
                  email: s.Email, status: s.Status, started: s.Started, phone: s.ContactPhone, 
                  los_id: s.LoanOfficerID,
                  purpose: s.LoanPurpose,
                  hascontract: s.HasContract,
                  unknownpurpose: s.UnknownPurpose,
                  address: s.PropertyAddressStreet,
                  county: s.PropertyAddressCounty,
                  state: s.PropertyAddressState,
                }
              })
              this.setState({ borrowers: docs, allborrowers: docs, loading: false })
            }
            this.getLoanofficers(0)
          }
        });
      }
    )
      .catch(function (err) {
        this.setState({ loading: false })
        console.log('Fetch Error :-S', err);
      });
  }
  showAssign = (id, loan_id, name, loanofficer) => {
    this.setState({ id: id, loan_id: loan_id, name: name,  loName: loanofficer })
    this.setState({ showassign: true })
  }
  hideAssign = () => {
    this.setState({ showassign: false })
    this.reload()
  }
  getPurpose = (purpose, hascontract, unknownpurpose) => {
    if (purpose === "refinance") return "Rate/Term Refinance"
    if (purpose === "cashoutrefinance") return "Cash-out Refinance"
    if (purpose === "purchase") {
      if (unknownpurpose) return "TBD"
      if (!hascontract) return "Pre-qualification"
      return "Purchase"
    }
    return "TBD"
  }
  handlePreQualificationToggle = (e) => {
    if (!e) {
      this.setState(
        {borrowers: this.state.borrowers.filter(s => 
          !(s.purpose==="purchase" && !s.unknownpurpose && !s.hascontract) 
        )}
      )
    } else {
      this.setState({borrowers: this.state.allborrowers})
    }

  }
  borrowersJSON = (document, index) => {
    let dt = new Date(com.timeGoToJS(document.started))
    return {
      address: document.address,
      countystate: document.county+", "+ com.convertStateToAbbr(document.state),
      name: document.firstname + " " + document.lastname,
      email: document.email,
      loanofficer: document.loanofficer,
      phone: document.phone,
      status: document.status,
      started: dt.toLocaleDateString(),
      id: document.id,
      loanid: document.loan_id, 
      purpose: this.getPurpose(document.purpose, document.hascontract, document.unknownpurpose),
    }
  }

  processAddress = (address) => {
    const regex = /^(.*?),\s*([A-Za-z\s]+,\s*[A-Z]{2}\s*\d{5})$/;
    const match = address.match(regex);

    if (match && match.length === 3) {
        return <>
          <div>{match[1].trim()}</div>
          <div style={{color:"#6E6E70", fontSize:12}}>{match[2].trim()}</div>
        </>
    } else {
        return address
    }

  }

  render() {
    let columns = [
      {
        title: 'Loan Address',
        dataIndex: 'address',
        key: 'address',
        ...this.getColumnSearchProps('address'),
        render: (text, record) => <div style={{ paddingLeft: '10px'}}>
          {record.address === "" ? "-" : this.processAddress(record.address)}
        </div>,
      },
      {
        title: 'Applicant',
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
        sorter: (a, b) => {
          if (a.email < b.email) {
            return -1;
          }
          if (a.email > b.email) {
            return 1;
          }
          return 0;
        }
      },
      {
        title: 'Loan Purpose',
        dataIndex: 'purpose',
        key: 'purpose',
        sorter: (a, b) => {
          if (a.purpose < b.purpose) {
            return -1;
          }
          if (a.purpose > b.purpose) {
            return 1;
          }
          return 0;
        },
        filters:  ["Purchase","Pre-qualification", "Rate/Term Refinance", "Cash-out Refinance", "TBD"].map(s => { return { text: capitalize(s), value: s } }),
        onFilter: (value, record) => record.purpose.startsWith(value),
      },
      {
        title: 'Start Date',
        dataIndex: 'started',
        key: 'started',
        render: (text) => <>{text}</>,
        sorter: (a, b) => com.dateFromDate(a.started) - com.dateFromDate(b.started),
        defaultSortOrder: "descend",
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        },
        filters:  com.loanStatus.map(s => { return { text: s, value: s } }),
        onFilter: (value, record) => record.status.startsWith(value),
        filterSearch: true,
        render: (text, record) => {
          // const isEditing = this.state.editingCell.loanID === record.loanid && this.state.editingCell.columnKey === 'status';
          // return isEditing ? (
          //   this.state.loadingStatus ? <div className="text-center"><Spin /></div> :
          //   <Select 
          //     bordered={false}
          //     defaultValue={text}
          //     onChange={(e) => this.handleStatusChange(record.loanid, e)}
          //     options={Object.entries(com.loanStatusMap).map(([key, val]) => (
          //       {label: val, value: key}
          //     ))}
          //   />
          // ) : (
          //   <div onClick={() => this.toggleEdit(record.loanid, 'status')} className="loansStatus">
          //     <Tag
          //       bordered={false}
          //       color={com.mapStatusTagColor(record.status)} >
          //       {com.splitStatusByWords(record.status)}
          //     </Tag>
          //   </div>
          // );

          const items = Object.entries(com.loanStatusMap).map(([key, val]) => (
            {
              key: key,
              label: (
                <a onClick={() => this.handleStatusChange(record.loanid, key)}>
                  {val}
                </a>
              ),
            }
          ))

          return <div className="loansStatus">
            <Tag
              bordered={false}
              color={com.mapStatusTagColor(record.status)}
              className="loansStatusTag" >
              {com.splitStatusByWords(record.status)}
              
            </Tag>
            <Dropdown
                menu={{items,}}
                placement="bottomLeft"
                arrow
              >
                <Button className="loanStatusSelect" onClick={() => this.toggleEdit(record.loanid, 'status')} >
                  <CaretDownOutlined style={{fontSize:11}}/>
                </Button>
            </Dropdown>
          </div>
        },
        sortDirections: ["descend", "ascend", "descend"],
      },
    ];
    if (checkAccess([UR_Owner])) {
      columns.push(
        {
          title: "Loan Officer",
          dataIndex: "loanofficer",
          key: "loanofficer",
          sorter: (a, b) => {
            if (a.loanofficer < b.loanofficer) {
              return -1;
            }
            if (a.loanofficer > b.loanofficer) {
              return 1;
            }
            return 0;
          },
          // ...this.getColumnSearchProps('loanofficer'),
          filters: Array.from(new Set(Object.values(this.state.loanofficers))).map(s => { return { text: s, value: s } }),
          onFilter: (value, record) => record.loanofficer.startsWith(value),
          filterSearch: true,
          render: (_, row) => (
            <div>
              {row.loanofficer === "Unassigned" ? 
              <Button 
                onClick={() => this.showAssign(row.id, row.loanid, row.name, row.loanofficer)}
                className="loansAddLOButton">Add LO</Button>
              :            
                <a
                onClick={() => this.showAssign(row.id, row.loanid, row.name, row.loanofficer)}
                style={{ textDecoration: "underline",marginRight:40,fontWeight:500 }}
                >
                {row.loanofficer}
              </a>}
  
            </div>
          ),
        }
      )
    }
    return (
      <div className="loansTable text-left mt-3 mx-0 w-100 ">
        <div className=" mb-3 ml-4 mr-5 d-flex justify-content-between align-items-center">
          <div className="title-2">Loans</div>
          <div style={{fontWeight:500}}>
            Show Pre-Qualification
            <Switch
              defaultChecked
              onChange={this.handlePreQualificationToggle}
              style={{ marginLeft: '10px' }} 
            />
          </div>
        </div>
        {this.state.loading ? <div><Skeleton active/></div> : 
          <div className="loansTableWrap">
            <Table  className="allLoansTable" columns={columns} dataSource={this.state.borrowers.map(this.borrowersJSON)} showSorterTooltip={false}/>
          </div>
          }
        <AssignLoanOfficer
          show={this.state.showassign}
          name={this.state.name}
          loName = {this.state.loName}
          onHide={this.hideAssign}
          id={this.state.id}
          loan_id={this.state.loan_id}
          los_id={this.state.los_id}
          loanofficers={this.state.loanofficers_full.map(t => {
            return { id: t.id, firstname: t.firstname, lastname: t.lastname }
          })}
          handleClose={() => { this.setState({ showassign: false }) }}
        />
        <Modal title="An internal error has occurred" 
          open={this.state.showErrorModal} 
          onOk={()=>{
            this.setState({showErrorModal: false})
            this.toggleEdit(null, null);
            this.reload()
          }} 
          onCancel={()=>this.setState({showErrorModal: false})}>
          <p>Please try later or contact contact@zeitro.com for help.</p>
        </Modal>
      </div>
    )
  }
}

export default Borrower
