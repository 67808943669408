import React,  { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { 
    Progress,
    Tabs,
    Tag,
    DatePicker,
    Button,
    Checkbox,
    Tooltip,
    Select,
    Switch,
    Modal,
    Divider,
    Form, 
    Input,
    InputNumber,
    Slider,
    Col, 
    Row,
    Upload
  } from 'antd';
import "./BasicInfoSetup.css";
import { isEmpty } from 'lodash';
const BasicInfoSetup = forwardRef(({ info, finish }, ref) => {
    const [form] = Form.useForm();
    const [expand, setExpand] = useState(true);
    const [percent, setPercent] = useState(0);
    const [required, setRequired] = useState(50);

    const [tags, setTags] = useState([]);
    const [popularTags, setPopularTags] = useState([])
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    const [profilePhoto, setProfilePhoto] = useState('');
    useEffect(() => {
        if (inputVisible && inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputVisible]);
    useEffect(() => {
        if (editInputRef.current) {
            editInputRef.current.focus()
        }
    }, [editInputValue]);
    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag)
        setTags(newTags)
    }
    const handleClick = (tag) => {
        if (!tags.includes(tag) && tags.length < 5) {
            setTags([...tags, tag])
        }
    }
    useEffect(() => {
        const formValues = form.getFieldValue()
        const all = {...formValues, ...{tagsList: tags }}
        setProgressValue(all)
    }, [tags, percent])
    useEffect(() => {
        const { tagsList, popularTags, ...formValue } = info
        form.setFieldsValue(formValue)
        setTags(tagsList)
        setPopularTags(popularTags)
    }, [info])
    const showInput = () => {
        setInputVisible(true)
    }
    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }
        setInputVisible(false)
        setInputValue('')
    }
    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value)
    }
    const handleEditInputConfirm = () => {
        const newTags = [...popularTags]
        newTags[editInputIndex] = editInputValue
        setPopularTags(newTags)
        setEditInputIndex(-1)
        setEditInputValue('')
    }
    const tagInputStyle = {
        width: 64,
        height: 24,
        marginInlineEnd: 8,
        verticalAlign: 'top',
    }
    const states = {
        AL: "Alabama",
        AK: "Alaska",
        AS: "American Samoa",
        AZ: "Arizona",
        AR: "Arkansas",
        CA: "California",
        CO: "Colorado",
        CT: "Connecticut",
        DE: "Delaware",
        DC: "District Of Columbia",
        FM: "Federated States Of Micronesia",
        FL: "Florida",
        GA: "Georgia",
        GU: "Guam",
        HI: "Hawaii",
        ID: "Idaho",
        IL: "Illinois",
        IN: "Indiana",
        IA: "Iowa",
        KS: "Kansas",
        KY: "Kentucky",
        LA: "Louisiana",
        ME: "Maine",
        MH: "Marshall Islands",
        MD: "Maryland",
        MA: "Massachusetts",
        MI: "Michigan",
        MN: "Minnesota",
        MS: "Mississippi",
        MO: "Missouri",
        MT: "Montana",
        NE: "Nebraska",
        NV: "Nevada",
        NH: "New Hampshire",
        NJ: "New Jersey",
        NM: "New Mexico",
        NY: "New York",
        NC: "North Carolina",
        ND: "North Dakota",
        MP: "Northern Mariana Islands",
        OH: "Ohio",
        OK: "Oklahoma",
        OR: "Oregon",
        PW: "Palau",
        PA: "Pennsylvania",
        PR: "Puerto Rico",
        RI: "Rhode Island",
        SC: "South Carolina",
        SD: "South Dakota",
        TN: "Tennessee",
        TX: "Texas",
        UT: "Utah",
        VT: "Vermont",
        VI: "Virgin Islands",
        VA: "Virginia",
        WA: "Washington",
        WV: "West Virginia",
        WI: "Wisconsin",
        WY: "Wyoming",
    }
    const getOptions = () => {
        let options = []
        for (let s in states) {
          options.push({ value: s, label: states[s] })
        }
        return options
    }
    const progressStatus = () => {
        if (percent < required) {
            return 'undone'
        } else if (percent >= required && percent < 100) {
            return 'normal'
        }
    }
    const validateMessages = {
        required: '${label} is required!',
    }
    const onFinish = (val) => {

    }
    useImperativeHandle(ref, () => ({
        validateForm() {
            form.validateFields().then((values) => {
                const info = {
                    ...values,
                    tagsList: tags,
                    popularTags: popularTags
                }
                finish(info)
            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo);
            });
        },
        resetForm() {
            form.resetFields()
        }
    }))
    const handleValuesChange = (changedValues, allValues) => {
        const form = {...allValues, ...{tagsList: tags }}
        setProgressValue(form)
    }
    const handleUploadChange = async ({ file, fileList }) => {
        if (file.status === 'uploading') {
            const base64Data = await readFileAsDataURL(file.originFileObj)
          
            const newFileList = fileList.map((f) => {
            if (f.uid === file.uid) {
                    return { ...f, url: base64Data }
                }
                return f
            })
            form.setFieldsValue({
                file: newFileList,
            })
        } else if (file.status === 'error') {
          
        }
    }
    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (e) => resolve(e.target.result)
          reader.onerror = (error) => reject(error)
        })
    }
    const setProgressValue = (form) => {
        const fillNum = Object.keys(form).filter((key) => {
            const value = form[key];
            if (percent < required || (percent >= required && (isEmpty(form.file) || isEmpty(form.firstname) || isEmpty(form.lastname)))) {
                return !isEmpty(value) && ['file', 'firstname', 'lastname'].includes(key)
            } else {
                return !isEmpty(value)
            }
        }).length
        const progressValue = Math.round((fillNum / 6) * 100)
        setPercent(progressValue)
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
    }
    const handleCustomRequest = (options) => {
        const { onSuccess } = options
        onSuccess()
    }
    const uploadBtn = () => {
        let file = form.getFieldValue('file')
        return file && file.length > 0 ? null : <div className='upload-btn'><img src='/images/upload-img.svg' /></div>
    }
    const itemRender = (originNode, file, fileList, actions) => (
        <div style={{marginTop: '6px', display: 'flex'}}>
            <Upload
                maxCount={1}
                customRequest={handleCustomRequest}
                onChange={handleUploadChange}
            >
                <img className='profile-photo' src={profilePhoto || file.url}></img>

            </Upload>
            <img className='remove-photo' src='/images/remove.svg' onClick={() => {actions.remove(file)}} />
        </div>
    )
    return (
        <div className="basic-info-setup">
            <div className='progress-box'>
                <div className='left-progress'>
                    <Progress className={`module-progress ${progressStatus()}`} percent={percent} showInfo={false} trailColor="#d9d9d9" />
                    <div style={{width: `${required}%`}} className='require-tag'><span>Required</span></div>
                </div>
                <div className='percent'>
                    {percent}%
                </div>
            </div>
            <div className='form-wrap'>
                <Form
                    className='basic-info-form'
                    layout='vertical'
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={handleValuesChange}
                    validateMessages={validateMessages}
                    size="large"
                >
                    <Row>
                        <Form.Item
                            name="file"
                            label="Profile photo"
                            rules={[
                                {
                                required: true,
                                },
                            ]}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                maxCount={1}
                                customRequest={handleCustomRequest}
                                itemRender={itemRender}
                                onChange={handleUploadChange}
                            >
                                {uploadBtn()}

                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row gutter={60}>
                        <Col span={24} sm={12}>
                            <Form.Item 
                                name="firstname" 
                                label="First name"
                                rules={[
                                    {
                                    required: true,
                                    },
                                ]}
                            >
                                <Input placeholder=""  />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12}>
                            <Form.Item
                                name="lastname" 
                                label="Last name"
                                rules={[
                                    {
                                    required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='optional'>
                        <span>Optional field</span> <img className={expand ? 'down' : 'up'} src='/images/chevron-down-large.svg' onClick={() => {setExpand(!expand)}} />
                    </Row>
                    {
                        expand && (
                            <>
                                <Row gutter={60}>
                                    <Col span={24} sm={12}>
                                        <Form.Item 
                                            name="nmls" 
                                            label="NMLS License"
                                        >
                                            <Input placeholder=""  />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} sm={12}>
                                        <Form.Item
                                            name="states" 
                                            label="License States"
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                options={getOptions()}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='theme-title'>Personalized tag</div>
                                <div className='theme-subtitle'>You can add up to 5 tags</div>
                                
                                <div className='tag-list' style={{marginBottom: 0}}>
                                    {
                                        tags.map((tag, index) => {
                                            const isLongTag = tag.length > 20;
                                            const tagElem = (
                                                <Tag
                                                key={tag}
                                                closable
                                                style={{
                                                    userSelect: 'none',
                                                    marginBottom: '8px'
                                                }}
                                                onClose={() => handleClose(tag)}
                                                >
                                                <span>
                                                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                </span>
                                                </Tag>
                                            );
                                            return isLongTag ? (
                                                <Tooltip title={tag} key={tag}>
                                                {tagElem}
                                                </Tooltip>
                                            ) : (
                                                tagElem
                                            );
                                        })
                                    }
                                </div>
                                {
                                    tags.length < 5 && (
                                        inputVisible ? (
                                            <Input
                                                ref={inputRef}
                                                type="text"
                                                size="small"
                                                style={tagInputStyle}
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                onBlur={handleInputConfirm}
                                                onPressEnter={handleInputConfirm}
                                            />
                                            ) : (
                                            <Tag onClick={showInput} bordered={false} color='default'>
                                            + Add yours
                                            </Tag>
                                        )
                                    )
                                }
                                <div className='theme-subtitle' style={{marginTop: 16}}>Popular tags</div>
                                <div className='popular-tags'>
                                    {popularTags.map((tag, index) => {
                                        if (editInputIndex === index) {
                                            return (
                                            <Input
                                                ref={editInputRef}
                                                key={tag}
                                                size="small"
                                                style={tagInputStyle}
                                                value={editInputValue}
                                                onChange={handleEditInputChange}
                                                onBlur={handleEditInputConfirm}
                                                onPressEnter={handleEditInputConfirm}
                                            />
                                            );
                                        }
                                        const isLongTag = tag.length > 20;
                                        const tagElem = (
                                            <Tag
                                            key={tag}
                                            style={{
                                                userSelect: 'none',
                                                marginBottom: '8px'
                                            }}
                                            color="geekblue"
                                            bordered={false}
                                            onClick={() => handleClick(tag)}
                                            >
                                            <span
                                                onDoubleClick={(e) => {
                                                    setEditInputIndex(index)
                                                    setEditInputValue(tag)
                                                    e.preventDefault()
                                                }}
                                            >
                                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                            </span>
                                            </Tag>
                                        );
                                        return isLongTag ? (
                                            <Tooltip title={tag} key={tag}>
                                            {tagElem}
                                            </Tooltip>
                                        ) : (
                                            tagElem
                                        );
                                        })}
                                </div>
                            </>
                        )
                    }
                </Form>
            </div>
        </div>
    );
})
export default BasicInfoSetup;