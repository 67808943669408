// import React, { useState, useEffect, useRef } from "react";
// import Menu from './Menu'
// import InnerHTML from "dangerously-set-html-content";
// import { Col, Row, Card, Container } from "react-bootstrap";
// import { Link } from 'react-router-dom'

// const BorrowerHandoffPage = (props) => {


//   let html = `<script id="zeitrotag" src=https://app.zeitro.us/api/zeitrotag.js?customerid=individualcustomers&loid=&pageid=handoff> </script>`;
//   let host = window.location.host

//   if (host == "agent.zeitro.com") {
//     html = `<script id="zeitrotag" src=https://app.zeitro.com/api/zeitrotag.js?customerid=individualcustomers&loid=&pageid=handoff> </script>`
//   }

//   if (host.includes("localhost")) {
//     html = `<script id="zeitrotag" src=http://localhost:3000/api/zeitrotag.js?customerid=individualcustomers&loid=&pageid=handoff> </script>`
//   }
  

//   return (
//     <div>
//       <Menu />
//       <div className="mt-5">
//         <Container>
//           <Row className="text-center mb-1">
//             <Col>
//               <h1>Please setup your password</h1>
//             </Col>
//           </Row>
//         <InnerHTML html={html} />

//         </Container>
//       </div>
//     </div>
//   );
// };

// export default BorrowerHandoffPage;

import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as com from "./Common.js"
import zxcvbn from "zxcvbn"
import Recaptcha from './Recaptcha'
import InputGroup from 'react-bootstrap/InputGroup'
import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'
import Menu from './Menu'

export const loadPasswordCheck = (callback) => {
  const existingScript = document.getElementById('zxcvbn');

  if (!existingScript) {
      const script = document.createElement('script');
      script.src = '/js/zxcvbn.js';
      script.id = 'zxcvbn';
      document.body.appendChild(script);

      script.onload = () => {
          if (callback) callback();
      };
  }

  if (existingScript && callback) callback();
};

export default class BorrowerHandoffPage extends Component {
    constructor(props) {

        super(props);
        this.form = React.createRef();
        this.password = React.createRef();
        this.password1 = React.createRef();
        this.captcha = React.createRef();
        this.timer = null;
        this.buttonpressed = false;
        this.state = {
            password: "",
            password1: "",
            modaltitle: "",
            show: false,
            message: "",
            validated: false,
            captchasolved: true,
            text: "",
          

        };
        this.pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        this.myObserver = new ResizeObserver(entries => {
            if (entries.length > 0) {
                let bod = entries[0]

                let _width = bod.contentRect.width
                let _height = bod.contentRect.height

                let height = Math.max(_height, document.body.clientHeight, 550)
                let width = Math.max(_width, document.body.clientWidth)

                window.parent.postMessage({ source: "tools", payload: [width, height] }, '*')

            }
        });
        this.ref = React.createRef()
    }
    validateForm() {

        return this.state.password.length > 8
            && this.state.password === this.state.password1;
    }

    onPassword = e => {
        this.setState({ password: e.target.value })

        if (this.state.passwordCheckReady) {

            let ret = zxcvbn(e.target.value)

            switch (ret.score) {
                case 0:
                    this.setState({ text: "Insecure", bgcolor: "rgb(255,64,64)" })
                    break;
                case 1:
                    this.setState({ text: "Too Weak", bgcolor: "rgb(255,128,64)" })
                    break;
                case 2:
                    this.setState({ text: "Breakable", bgcolor: "rgb(160,160,64)" })
                    break;
                case 3:
                    this.setState({ text: "Good enough", bgcolor: "rgb(128,180,64)" })
                    break;
                case 4:
                    this.setState({ text: "Strong!!!", bgcolor: "rgb(64,180,64)" })
                    break;
                default:
                    break;
            }
            if (this.state.password1 && this.state.password1 !== "") {
                if (e.target.value !== this.state.password1) {
                    this.password1.current.setCustomValidity("Passwords should match!")
                    this.setState({ text2: "Mismatch", bgcolor2: "rgb(255,64,64)" })
                } else {
                    this.setState({ text2: "Identical!", bgcolor2: "rgb(64,180,64)" })
                }
            }
            if (ret.score < 3) {
                this.password.current.setCustomValidity("Password is too weak. Try longer mix of letters, numbers and special characters")
            } else {
                this.password.current.setCustomValidity("")
            }
        }
    }
    onPassword1 = e => {
        this.setState({ password1: e.target.value })
        if (e.target.value !== this.state.password) {
            this.setState({ validated: true })
            this.password1.current.setCustomValidity("Passwords should match!")
            this.setState({ text2: "Mismatch", bgcolor2: "rgb(255,64,64)" })
        } else {
            this.password1.current.setCustomValidity("")
            this.setState({ text2: "Identical!", bgcolor2: "rgb(64,180,64)" })
        }
    }
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleClose = () => { this.setState({ show: false }) }
    checkCaptcha = (event) => {
        if (!window.grecaptcha || !this.buttonpressed)
            return false;

        let captcha = window.grecaptcha.getResponse()
        if (!captcha || captcha === "") {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false

            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.captcha.current.required = true
            return false
        } else {

            this.setState({ captchasolved: true })
            this["state"].captchasolved = true
            this.captcha.current.setCustomValidity("")
            this.captcha.current.required = false
        }
        return true;
    }
    componentDidMount() {
        loadPasswordCheck(() => {
            // Work to do after the library loads.
            this.setState({ passwordCheckReady: true });
        });        
        if (this.props.view === "bare") {
            this.myObserver.observe(this.ref.current);
            if (window.drift) {
                window.drift.hide()
                window.drift = null;
            }
        }
        this.timer = window.setInterval(this.checkCaptcha, 1000)
    }
    componentWillUnmount() {
        window.clearInterval(this.timer)

    }
    reset = (event, data) => {
        let captcha = window.grecaptcha.getResponse()
        if (!captcha || "" === captcha) {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false

            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            return
        }
        let params = new URLSearchParams(window.location.search);
        let hash = params.get("hash")
        let email = com.hex2ascii(params.get("email"))
        let customerid = params.get("customerid")
        let landingUrl = params.get("landingurl")
        const js = {
            password: data.get("password"), email,
            hash, captcha: captcha, customerid
        }
        let tosend =
            JSON.stringify(js)

        fetch('/handofftoborrower', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, show: true
                    })
                    window.grecaptcha.reset()
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status === "OK") {
                      window.location.href = this.props.url + "/services/signin"
                        if (customerid==="individualcustomers") {
                            window.parent.postMessage({ source: "plainredirect", payload: landingUrl + "/services/signin" }, '*')
                        } else {
                            window.parent.postMessage({ source: "plainredirect", payload: "/login" }, '*')
                        }
                        //if(this.props.view === "bare") {
                        //    window.location.href =  com.redirectValidation() 
                        //} else
                        //    window.location.href = "/services/signin"
                    } else {
                        this.setState({ message: js.Text, show: true })
                        window.grecaptcha.reset()
                    }

                }).catch(function (err) {
                    console.log('JSON processing Error, %s', err);
                    window.grecaptcha.reset()
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
            window.grecaptcha.reset()

        });
    }
    handleSubmit = event => {
        this.buttonpressed = true;

        if (!this.checkCaptcha(event)) {
            console.log("invalid captcha, skip")
            return false
        }

        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();

        const data = new FormData(event.target);

        this.reset(event, data)
        return false
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    header = () => {

        return (
            <div className=" ml-3  nav-link text-center">
                <h5>Please Reset Your Password</h5>
                Please enter your new password. It should contain small and large letters, and at leas one number, and be at least 8 characters long.
            </div>
        )

    }
    render() {
        let dummy = () => {

        }
        return (
            <div ref={this.ref} className="Login m-0 p-0" >
                <Menu/>
                {this.props.view !== "bare" ?
                    <Helmet>
                        <title>Create a New Loan Account</title>
                    </Helmet> : ""}

                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Password recovery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <div className="text-center mt-5">
                    <div id="landing1" className="loginbox mt-4" >
                        <div className="row">
                            <div className="col-sm   px-5 py-3 text-center">
                                {this.props.view !== "bare" ? this.header() : ""}
                                <Form style={{ fontSize: '0.8em' }} ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                                    <Row className="mt-3 ">
                                        <Col className="text-left">&nbsp;</Col>
                                    </Row>
                                    <Row >
                                        <Col className="  text-left">
                                            <Form.Group controlId="password"  >
                                                <Form.Label className="text-left" >New&nbsp;Password:</Form.Label>
                                                <InputGroup size="sm" >
                                                    <Form.Control
                                                        size="sm"
                                                        name="password"
                                                        ref={this.password}
                                                        required
                                                        autoComplete="off"
                                                        value={this.state.password}
                                                        onChange={this.onPassword}
                                                        type="password"
                                                        pattern="^.+$"
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="pl-4" style={{ "whiteSpace": "nowrap", "textAlign": "right", "backgroundColor": this.state.bgcolor, "color": "white", "minWidth": "9em" }} size="sm">{this.state.text}</InputGroup.Text>
                                                    </InputGroup.Append>

                                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid" >
                                                        Password must contain large, small letters, and numbers!
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className=" ">
                                        <Col className="text-left" >
                                            <Form.Group controlId="password1" >
                                                <Form.Label className="text-left" >Validate:</Form.Label>
                                                <InputGroup size="sm" >
                                                    <Form.Control
                                                        ref={this.password1}
                                                        size="sm"
                                                        required
                                                        autoComplete="off"
                                                        value={this.state.password1}
                                                        onChange={this.onPassword1}
                                                        pattern="^.+$"
                                                        type="password"
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="pl-4" style={{ "whiteSpace": "nowrap", "textAlign": "right", "backgroundColor": this.state.bgcolor2, "color": "white", "minWidth": "9em" }} size="sm">{this.state.text2}</InputGroup.Text>
                                                    </InputGroup.Append>
                                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid" >
                                                        Make sure validate and password fields are the same!
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="text-center mt-2 pl-0 ml-0">
                                        <Col className="text-left">
                                            <Recaptcha />
                                            <Form.Group controlId="hackid" >
                                                <Form.Control
                                                    size="sm"
                                                    ref={this.captcha}
                                                    required={!this.state.captchasolved}
                                                    value=""
                                                    name="hack"
                                                    hidden={true}
                                                    type="text"
                                                    onChange={dummy}

                                                />
                                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" >
                                                    Please solve the Captcha!
                                                </Form.Control.Feedback>

                                            </Form.Group>

                                        </Col>
                                    </Row>

                                    <Row className="pt-3  mb-5">
                                        <Col className="text-center">
                                            <Button
                                                type="submit" variant="success"
                                            > Click to reset password!</Button>
                                        </Col>


                                    </Row>
                                    <Row className="mt-3 ">

                                    </Row>
                                </Form>
                            </div>

                        </div>
                    </div>
                </div>
                {/* {this.props.view !== "bare" ?
                    <LandingCopyright /> : ""} */}
            </div>
        );
    }
}

