import React, {useState, useEffect} from 'react'
import { CommentSection} from 'react-comments-section'
import { Spinner } from 'react-bootstrap';
import 'react-comments-section/dist/index.css'
import * as com from "./Common";

const LoanComments = () => {


  const [data, setData] = useState([])
  const [loProfile, setLOProfile] = useState({})
  const [loading, setLoading] = useState(false)


  const getComments = () => {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA")
    const paths = window.location.pathname.split('/');
    fetch('/agent/getloancomments', {
      body: JSON.stringify(
        {LoanID: paths[paths.length - 1]}
      ),
      method: 'POST',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          setLoading(false)
          return;
        }
        response.json().then(js => {
          let d = JSON.parse(js)
          if (d["pipeline"] !== undefined && d["pipeline"]["comments"] !== undefined) {
              setData(d["pipeline"]["comments"])
          }
          setLoading(false)
        });
      }
    ).catch(function (err) {
        console.log('Fetch Error :-S', err);
        setLoading(false)
      });
  }

  const getLoProfile = () => {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          setLoading(false)
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Error);
          } else {
            let lo = js.Profile;
            setLOProfile({
              id: lo.ID,
              firstName: lo.FirstName,
              lastName: lo.LastName,
              email: lo.Email,
              phone: lo.Phone,
              avatarsrc: lo.Image,
              nmls: lo.Nmls,
            });
          }
          setLoading(false)
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
        setLoading(false)
      });
  }

  const updateComments = (comments) => {
    let token = sessionStorage.getItem("ZeitroA")
    const paths = window.location.pathname.split('/');
    console.log(comments)
    fetch('/agent/updateloancomments', {
      body: JSON.stringify(
        {
            LoanID: paths[paths.length - 1],
            Data: JSON.stringify(comments),
        }
      ),
      method: 'POST',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            setLoading(false)
          return;
        }
      }
    ).catch(function (err) {
        console.log('Fetch Error :-S', err);
        setLoading(false)
      });
  }
  
  useEffect(()=> {
    getLoProfile()
    getComments()
  }, [])

//   useEffect(()=> {
//     if (data.length > 0) {
//         updateComments()
//     }
//   }, data)

  return (loProfile.firstName && !loading) ?  
  <div className='text-left p-5'>
  <CommentSection
    currentUser={{
      currentUserId: loProfile.id,
      currentUserImg: loProfile.avatarsrc,
      currentUserProfile: '',
      currentUserFullName: loProfile.firstName + " " + loProfile.lastName,
    }}
    logIn={{
      loginLink: 'http://localhost:3001/',
      signupLink: 'http://localhost:3001/'
    }}
    commentData={data}
    currentData={(data) => {
    //   console.log('curent data', data)
        if (data.length>0) {
            updateComments(data)
        }
    }}
    onReplyAction={(data) => console.log('check reply, ', data)}
    customNoComment={() => <div className='no-com'>No comments</div>}
  /></div> : ""
}

export default LoanComments