import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { PennyInput } from "../NumericalInput";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Multiselect from "multiselect-react-dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../Utils.css";
import "./Management.css";
import * as com from "../Common";
import Spinner from "react-bootstrap/Spinner";
import { lender, UR_LoanOfficer, UR_Owner } from "../State";
import { toInteger } from "lodash";
import { tooltip } from "../Common/Tooltip";
import { checkAccess } from "../Auth";
import { message, Popconfirm, Collapse } from 'antd';
import { InfoCircleTwoTone, DeleteTwoTone } from "@ant-design/icons";

const deepcopy = require("deepcopy");
const emptyrule = {
  selectedRule: "",
  value: "",
  maxrule: "",
  minrule: "",
  minoperator: "less",
  maxoperator: "less",
  action: "",
  selectedEnum: [],
  operator: null,
  ref: null,
};
const emptypromotion = {
  selectedPromotion: "",
  value: "",
  maxrule: "",
  minrule: "",
  minoperator: "less",
  maxoperator: "less",
  message: "",
  selectedEnum: [],
  operator: null,
  ref: null,
};
class Lenders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allenders: [],
      customerlenders: [],
      selectedid: null,
      validated: false,
      minimumClosingCostPercentage: "",
      maximumClosingCostPercentage: "",
      show: false,
      showcopy: false,
      showpromotion: false,
      mode: "add",
      showmodalvalidated: false,
      rules: [deepcopy(emptyrule)],
      promotions: [deepcopy(emptypromotion)],
      selectedEnum: [],
      modalcopyvalidated: false,
      modalpromotionvalidated: false,
      lendercache: {},
      showSpinner: false,
      isLOAgent: false,
      showLendersNotInListModal: false,
    };
    this.state.rules[0].ref = React.createRef();
    this.state.promotions[0].ref = React.createRef();
    this.lendersid = {};
    this.form = React.createRef();
    this.modal = React.createRef();
    this.promotionmodal = React.createRef();
    this.modalcopy = React.createRef();
    this.isMobile = window.innerWidth<576
  }

  getCustomerLenders = () => {
    let token = sessionStorage.getItem("ZeitroA");
    let url = "/owner/getcustomerlenders"
    if (this.state.isLOAgent) {
      url = "/agent/getcustomerlenders"
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Please contact Zeitro for help."
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Looks like there was a problem. Please contact Zeitro.");
          } else if (!js.Lenders) {
            return
          } else {
            this.setState({
              minimumClosingCostPercentage: js.Config.MinClosingCostPercent,
              maximumClosingCostPercentage: js.Config.MaxClosingCostPercent,
            });
            js.Lenders.forEach((x) => {
              x.Config = JSON.parse(x.Config);
            });
            this.resetLenderIDs(js.Lenders);
            console.log(this.state);
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };
  resetLenderIDs = (lenders) => {
    this.lendersid = [];
    lenders.forEach((x) => {
      if (x.Active) {
        this.lendersid[x.id] = true;
      }
    });
    lenders = lenders.sort((x, y) => {
      return x.name[0] > y.name[0] ? 1 : -1;
    });
    this.setState({ customerlenders: lenders });
    // if (lenders.length > 0 ) {
    //     this.setState({ selectedid: lenders[0].id })
    // }
  };
  getLenders = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/getalllenders", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Please contact Zeitro."
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
            let lenders = js.Lenders.sort((x, y) => {
              return x.name[0] > y.name[0] ? 1 : -1;
            });
            this.setState({ allenders: lenders });
            this.getCustomerLenders();
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };
  componentDidMount() {
    this.getLenders();
    if (checkAccess([UR_LoanOfficer])) {
      this.setState({isLOAgent: true})
    } else {
      this.setState({isLOAgent: false})
    }
  }
  sendLenders() {
    this.setState({showSpinner: true})
    let token = sessionStorage.getItem("ZeitroA");
    let b = [];
    let lendersCopy = deepcopy(this.state.customerlenders)
    for (let l of lendersCopy) {
      if (l.Active) {
        b.push(l);
      }
    }
    // reset config to string
    b.forEach((x) => {
      x.Config = JSON.stringify(x.Config);
    });
    let body = {
      Status: "OK",
      Lenders: b,
      Config: {
        MinClosingCostPercent: this.state.minimumClosingCostPercentage,
        MaxClosingCostPercent: this.state.maximumClosingCostPercentage,
      },
    };
    let url = "/owner/postlenders"
    if (this.state.isLOAgent) {url = "/agent/postlenders"}
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          this.setState({showSpinner: false})
          alert("Looks like there was a problem, please contact Zeitro.");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          this.setState({showSpinner: false})
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
            alert("Lenders updated successfully!");
          }
        });
      })
      .catch(function (err) {
        this.setState({showSpinner: false})
        console.log("Fetch Error :-S", err);
      });
  }
  deleteLender(id) {
    let token = sessionStorage.getItem("ZeitroA");
    let body = { ID: id };
    let url = "/owner/deletelender"
    if (this.state.isLOAgent) {url = "/agent/deletelender"}
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Please contact Zeitro."
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  }
  handleSubmit = (event) => {
    if (this.form.current.reportValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });

      return false;
    }
    this.sendLenders();
    // event.preventDefault();
    // event.stopPropagation();
    return false;
  };

  render() {
    let addLenders = () => {
      let out = [];

      let lenders = [];
      this.state.allenders.forEach((x) => {
        if (undefined === this.lendersid[x.id]) {
          lenders.push(x);
        }
      });
      lenders.forEach((x) => {
        out.push(
          <option key={x.id} value={x.id}>
            {x.name}
          </option>
        );
      });
      return out;
    };
    let setSelectedLender = (e) => {
      this.setState({ selectedid: parseInt(e.target.value) });
    };

    let showLenders = (e) => {
      if (this.state.customerlenders.length===0) {
        return <h5 className="text-center mt-5 mb-5">You haven't added any lenders yet.</h5>
      }
      let items = [];

      for (let x of this.state.customerlenders) {
        if (x.Active === true) {
        //   console.log(x.Config);
          let displayRelationship = (key, i) => {
            let rel = com.LenderRelationshhip[key];
            return (
              <option
                value={key}
                selected={key === x.Config.correspondentLender}
                key={"relationship+" + i}
              >
                {rel.name}
              </option>
            );
          };

          let removeLender = () => {
            if (
              window.confirm(
                "Are you sure you want to delete " +
                  x.name +
                  "? The operation is irreversible."
              )
            ) {
              let lenders = [];
              this.state.customerlenders.forEach((y) => {
                if (y.id === x.id) {
                  return;
                }
                lenders.push(y);
              });
              let cac = deepcopy(this.state.lendercache);
              cac[x.id] = x;
              this.setState({ lendercache: cac });
              this.deleteLender(x.id);
              this.resetLenderIDs(lenders);
            }
          };
          let onCompensation = (e) => {
            x.Config.compensation = e.target.value;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onCompensationFlatFee = (e) => {
            x.Config.compensationFlatFee = parseFloat(e.target.value);
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onCloseover = (e) => {
            x.Config.closeover = e.target.value;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onMincost = (e) => {
            x.Config.minimumClosingCostPercentage = e.target.value;
            if (x.Config.minimumClosingCostPercentage === "")
              x.Config.minimumClosingCostPercentage = null;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onMaxcost = (e) => {
            x.Config.maximumClosingCostPercentage = e.target.value;
            if (x.Config.maximumClosingCostPercentage === "")
              x.Config.maximumClosingCostPercentage = null;
            this.setState({ customerlenders: this.state.customerlenders });
          };
          let onCorrespondent = (e) => {
            x.Config.correspondentLender = e.target.value;
            this.setState({ customerlenders: this.state.customerlenders });
          };

          let showLenderFees = (y, i) => {
            let removeSlice = () => {
              x.Config.lenderfees.splice(i, 1);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let addSlice = () => {
              x.Config.lenderfees.push({
                fee: 0,
                states: [],
                mortgageTypes: [],
              });
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let onFee = (e) => {
              y.fee = parseFloat(e.target.value);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let onStates = (e) => {
              y.states = e.target.value.split(",").map((x) => {
                return x.trim().toUpperCase();
              });
              this.setState({ customerlenders: this.state.customerlenders });
            };

            return (
              <>
                <Row
                  key={"card" + x.lender + i}
                  className=" m-2 p-0"
                  style={{ border: "1px solid black" }}
                >
                  <Col xs="auto" className="p-2">
                    <Form.Group controlId={"idfee" + x.lender + i}>
                      <Form.Label className="text-left">
                        Lender Origination Fee:
                      </Form.Label>

                      <InputGroup size="sm">
                        <PennyInput
                          size="sm"
                          name={"idfee" + x.lender + i}
                          required
                          defaultValue={parseFloat(y.fee)}
                          onChange={onFee}
                          type="text"
                          style={{ width: "5em" }}
                          pattern="^.+$"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col className="p-2">
                    <Form.Group controlId={"states" + i} className="text-left">
                      <Form.Label className="text-left">States:</Form.Label>
                      <Form.Control
                        isValid={false}
                        size="sm"
                        required
                        name={"states" + i}
                        type="text"
                        value={y.states.join(",")}
                        onChange={onStates}
                        pattern="^[\w,]*$"
                        placeholder="Comma-separated list of states 2 upper letter codes, such as 'CA' or 'CA,TX'"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please provide states.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col
                    xs="auto"
                    as="div"
                    className="text-right aligh-top px-0 py-0"
                  >
                    <i
                      className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
                      onClick={addSlice}
                    ></i>
                    {x.Config.lenderfees.length > 1 ? (
                      <i
                        className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                        onClick={removeSlice}
                      ></i>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </>
            );
          };
          let displaySubRule = (rule) => {
            let displayValue = (key) => {
              switch (com.RuleSet[key].typ) {
                case "minmax":
                  return (
                    <span className="text-bold">
                      {com.operatorInout[rule[key].operator]} [{" "}
                      {rule[key].value[0]}{" "}
                      {com.operatorMap[rule[key].minoperator]}{" "}
                      {com.RuleSet[key].name}{" "}
                      {com.operatorMap[rule[key].maxoperator]}{" "}
                      {rule[key].value[1]} ]{" "}
                    </span>
                  );
                case "bool":
                  return (
                    <span className="text-bold">
                      {com.operatorBool[rule[key].operator]}{" "}
                    </span>
                  );

                case "value":
                  switch (com.RuleSet[key].values) {
                    case "bool":
                      return (
                        <span className="text-bold">{rule[key].operator} </span>
                      );
                    case "dollar":
                      return (
                        <span className="text-bold">
                          {" "}
                          {com.operatorMap[rule[key].operator]} $
                          {com.commaizeFloat(rule[key].value)}
                        </span>
                      );
                    default:
                      return (
                        <span className="text-bold">
                          {com.operatorMap[rule[key].operator]}{" "}
                          {rule[key].value}
                        </span>
                      );
                  }

                case "enum":
                  return (
                    <span>
                      {com.operatorEnum[rule[key].operator]}{" "}
                      <span className="text-bold">
                        {" "}
                        [
                        {rule[key].value
                          .map((x) => com.RuleSet[key].values[x])
                          .join(", ")}
                        ]{" "}
                      </span>
                    </span>
                  );
                case "boolean":
                  return "";
              }
              return rule[key];
            };
            let prepSubRule = (key) => {
              return (
                <Row key={"prepSubRule" + key}>
                  <Col xs="auto" className="text-nowrap">
                    <span className="text-bold">{com.RuleSet[key].name}</span>,
                    if {displayValue(key)}
                  </Col>
                </Row>
              );
            };
            let out = Object.keys(rule).map(prepSubRule);
            return out;
          };
          let displayEstablishedRule = (rules, j) => {
            let removeeme = () => {
              x.Config.rules.splice(j, 1);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let edit = () => {
              let staterules = [];

              if (x.Config.rules[j].adjustment != null) {
                this.setState({
                  action: "adjustment",
                  adjustment: x.Config.rules[j].adjustment,
                });
              }

              if (x.Config.rules[j].fail != null) {
                this.setState({ action: "fail", fail: x.Config.rules[j].fail });
              }

              x.Config.rules[j].rules.forEach((y) => {
                Object.keys(y).forEach((z) => {
                  let r = {
                    selectedRule: z,
                  };

                  switch (com.RuleSet[z].typ) {
                    case "minmax":
                      {
                        r.minrule = y[z].value[0];
                        r.maxrule = y[z].value[1];
                        r.maxoperator = y[z].maxoperator;
                        r.minoperator = y[z].minoperator;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "enum":
                      {
                        r.selectedEnum = y[z].value.map((x) => {
                          return { id: x, name: com.RuleSet[z].values[x] };
                        });
                        r.operator = y[z].operator;
                      }
                      break;
                    case "value":
                      {
                        r.value = y[z].value;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "bool":
                      {
                        r.value = true;
                        r.operator = y[z].operator;
                      }
                      break;
                  }
                  staterules.push(r);
                });
              });

              this.setState({ rules: staterules });
              this.setState({
                show: true,
                modalvalidated: false,
                mode: "edit",
                config: x.Config,
                ruleindex: j,
                conditionindex: x,
              });
            };
            return (
              <Col key={"esrule" + j}>
                <div className="rulecard  mb-2">
                  <Row style={{ backgroundColor: "#999999" }}>
                    <Col className="text-right pr-1">
                      <i
                        onClick={edit}
                        className="fas fahover fa-edit aligh-top"
                      ></i>
                      <i
                        onClick={removeeme}
                        className="far fahover fa-minus-square aligh-top"
                      ></i>
                    </Col>
                  </Row>
                  {rules.rules.map(displaySubRule)}

                  <Row>
                    <Col xs="auto">
                      {rules.adjustment != null ? (
                        <span className="text-bold">
                          Adjustment: {rules.adjustment}%
                        </span>
                      ) : (
                        ""
                      )}
                      {rules.fail != null ? (
                        <span
                          className="text-bold"
                          style={{ color: "#993333" }}
                        >
                          Fail qualification!
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          };
          let displayEstablishedRules = () => {
            let rules = x.Config.rules;

            let rows = [];
            for (let i = 0; i < rules.length; i += 4) {
              let cols = [];
              let j = i;
              for (; j < i + 4 && j < rules.length; j++) {
                cols.push(displayEstablishedRule(rules[j], j));
              }
              if ((i + 1) % 4 !== 0) cols.push(<Col key={"cc" + j}></Col>);
              rows.push(<Row key={"displayEstablishedRules" + i}>{cols}</Row>);
            }
            return rows;
          };
          let displayEstablishedPromotion = (promotions, j) => {
            let removeeme = () => {
              x.Config.promotions.splice(j, 1);
              this.setState({ customerlenders: this.state.customerlenders });
            };
            let edit = () => {
              let statepromotions = [];

              if (x.Config.promotions[j].message != null) {
                this.setState({
                  message: x.Config.promotions[j].message,
                });
              }

              x.Config.promotions[j].conditions.forEach((y) => {
                Object.keys(y).forEach((z) => {
                  let r = {
                    selectedRule: z,
                  };

                  switch (com.RuleSet[z].typ) {
                    case "minmax":
                      {
                        r.minrule = y[z].value[0];
                        r.maxrule = y[z].value[1];
                        r.maxoperator = y[z].maxoperator;
                        r.minoperator = y[z].minoperator;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "enum":
                      {
                        r.selectedEnum = y[z].value.map((x) => {
                          return { id: x, name: com.RuleSet[z].values[x] };
                        });
                        r.operator = y[z].operator;
                      }
                      break;
                    case "value":
                      {
                        r.value = y[z].value;
                        r.operator = y[z].operator;
                      }
                      break;
                    case "bool":
                      {
                        r.value = true;
                        r.operator = y[z].operator;
                      }
                      break;
                  }
                  statepromotions.push(r);
                });
              });

              this.setState({ promotions: statepromotions });
              this.setState({
                showpromotion: true,
                modalpromotionvalidated: false,
                mode: "edit",
                config: x.Config,
                promotionindex: j,
                conditionindex: x,
              });
            };
            return (
              <Col key={"esrule" + j}>
                <div className="rulecard  mb-2">
                  <Row style={{ backgroundColor: "#999999" }}>
                    <Col className="text-right pr-1">
                      <i
                        onClick={edit}
                        className="fas fahover fa-edit aligh-top"
                      ></i>
                      <i
                        onClick={removeeme}
                        className="far fahover fa-minus-square aligh-top"
                      ></i>
                    </Col>
                  </Row>
                  {promotions.conditions.map(displaySubRule)}

                  <Row>
                    <Col xs="auto">
                      <b>Promotion message: </b>{promotions.message}
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          };
          let displayEstablishedPromotions = () => {
            let promotions = x.Config.promotions;

            let rows = [];
            for (let i = 0; i < promotions.length; i += 4) {
              let cols = [];
              let j = i;
              for (; j < i + 4 && j < promotions.length; j++) {
                cols.push(displayEstablishedPromotion(promotions[j], j));
              }
              if ((i + 1) % 4 !== 0) cols.push(<Col key={"cc" + j}></Col>);
              rows.push(<Row key={"displayEstablishedPromotions" + i}>{cols}</Row>);
            }
            return rows;
          };
          items.push(
            {
              key: "card_" + x.lender,
              label:
                <div className="d-flex justify-content-between">
                  <div className="managementLendersName">{x.name}</div>
                  <DeleteTwoTone style={{fontSize:16}} twoToneColor={"#325CEB"} onClick={removeLender}/>
                </div>, 
              children: 
                <div>
                <Row>
                  <Col xs="auto">
                    <Form.Group controlId={"idcompensation" + x.lender}>
                      <Form.Label className="text-left">
                        Compensation Percentage:
                      </Form.Label>

                      <InputGroup size="sm">
                        <Form.Control
                          size="sm"
                          name={"compensation" + x.lender}
                          required
                          value={x.Config.compensation}
                          onChange={onCompensation}
                          type="text"
                          style={{ width: "3em" }}
                          pattern="^.+$"
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            className="pl-3"
                            style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                            size="sm"
                          >
                            %
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter compensation
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId={"idcompensationflatfee" + x.lender}>
                      <Form.Label className="text-left">
                        Compensation Flat Fee:
                      </Form.Label>
                      <InputGroup size="sm">
                        <PennyInput
                          size="sm"
                          name={"compensationflatfee" + x.lender}
                          required
                          defaultValue={x.Config.compensationFlatFee}
                          onChange={onCompensationFlatFee}
                          type="text"
                          style={{ width: "5em" }}
                          pattern="^.+$"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId="password">
                      <Form.Label className="text-left">
                        Min Closing Cost Points:
                      </Form.Label>

                      <InputGroup size="sm">
                        <Form.Control
                          size="sm"
                          name={"mincost" + x.lender}
                          //required
                          value={
                            x.Config.minimumClosingCostPercentage == null
                              ? ""
                              : x.Config.minimumClosingCostPercentage
                          }
                          onChange={onMincost}
                          type="text"
                          style={{ width: "3em" }}
                          pattern="^[0...9-.]*$"
                          placeholder="(-)XXX or empty"
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            className="pl-3"
                            style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                            size="sm"
                          >
                            %
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter Min Closing Cost Points
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group controlId="password">
                      <Form.Label className="text-left">
                        Max Closing Cost Points:
                      </Form.Label>

                      <InputGroup size="sm">
                        <Form.Control
                          size="sm"
                          name={"mincost" + x.lender}
                          //required

                          value={
                            x.Config.maximumClosingCostPercentage == null
                              ? ""
                              : x.Config.maximumClosingCostPercentage
                          }
                          onChange={onMaxcost}
                          type="text"
                          style={{ width: "3em" }}
                          pattern="^[0...9-.]*$"
                          placeholder="XXX or empty"
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            className="pl-3"
                            style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                            size="sm"
                          >
                            %
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter Max Closing Cost Points
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                {x.Config.lenderfees?.map(showLenderFees)}
                <Row>
                  <Col xs="auto">
                    <Form.Group className="inputSection">
                      <Form.Label style={{ marginBottom: "10px" }}>
                        Relationship with the lender:
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        onChange={onCorrespondent}
                        required
                      >
                        {" "}
                        {Object.keys(com.LenderRelationshhip).map(
                          displayRelationship
                        )}
                      </Form.Control>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {(x.Config.correspondentLender ===
                    "NondedicatedCorrespondentLender" ||
                    x.Config.correspondentLender ===
                      "DedicatedCorrespondentLender" ||
                    x.Config.correspondentLender === "DirecttLender") && (
                    <Col xs="auto">
                      <Form.Group controlId={"idcloseover" + x.lender}>
                        <Form.Label className="text-left">
                          {tooltip(
                            "Close Over Percentage",
                            <div>
                              Applied to lenders who want to add extra points to
                              ratesheets, leave empty when not applied.
                            </div>,
                            "auto",
                            ""
                          )}
                        </Form.Label>
                        <InputGroup size="sm">
                          <Form.Control
                            size="sm"
                            name={"closeover" + x.lender}
                            value={x.Config.closeover}
                            onChange={onCloseover}
                            type="text"
                            style={{ width: "3em" }}
                            pattern="^.+$"
                          />

                          <InputGroup.Append>
                            <InputGroup.Text
                              className="pl-3"
                              style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                              size="sm"
                            >
                              %
                            </InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please enter compensation
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  )}

                  <Col></Col>
                  {this.state.customerlenders.length > 1 &&
                  x.Config.rules != null ? (
                    <Col className="text-right" xs="auto">
                      <Button
                        onClick={(e) => {
                          this.setState({
                            config: x.Config,
                            showcopy: true,
                            lenderid: x.id,
                            lendername: x.name,
                          });
                        }}
                        className="mt-3"
                      >
                        Copy Config to Other Lenders
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}

                </Row>
                <Row className="mb-2">
                  <Col className="text-right" xs="auto">
                    <Button className="mt-3 mr-2" 
                      onClick={(e) => {
                        let promotions = [deepcopy(emptypromotion)];
                        this.state.promotions[0].ref = React.createRef();
                        this.setState({
                          config: x.Config,
                          promotions: promotions,
                          modalpromotionvalidated: false,
                          showpromotion: true,
                          mode: "add",
                        });
                      }}
                    >
                        Add Promotion
                    </Button>
                    <Button
                      onClick={(e) => {
                        let rules = [deepcopy(emptyrule)];
                        this.state.rules[0].ref = React.createRef();
                        this.setState({
                          config: x.Config,
                          rules: rules,
                          modalvalidated: false,
                          show: true,
                          mode: "add",
                        });
                      }}
                      className="mt-3"
                    >
                      Add Adjustments
                    </Button>
                    {this.state.customerlenders.length > 1 &&
                    x.Config.rules != null ? (
                      <Button
                        onClick={(e) => {
                          this.setState({
                            config: x.Config,
                            showcopy: true,
                            lenderid: x.id,
                            lendername: x.name,
                          });
                        }}
                        className="mt-3"
                        variant="link"
                      >
                        Copy Config to Other Lenders
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                {x.Config.promotions != null ? (
                  <div>
                    <div
                      style={{ fontWeight: "bold", fontSize: "1.3em" }}
                      className="ml-3"
                    >
                      Promotions:
                    </div>
                    {displayEstablishedPromotions()}
                  </div>
                ) : (
                  ""
                )}
                {x.Config.rules != null ? (
                  <div>
                    <div
                      style={{ fontWeight: "bold", fontSize: "1.3em" }}
                      className="ml-3"
                    >
                      Adjustments:
                    </div>
                    {displayEstablishedRules()}
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
          );
        }
      }

      // return out;
      return <div className="managementLendersWrap">
          <Collapse collapsible="icon" items={items} />
        </div>
    };
    let add = (e) => {
      // let out = [];

      let lenders = [];
      this.state.allenders.forEach((x) => {
        if (undefined === this.lendersid[x.id]) {
          lenders.push(x);
        }
      });

      let lender = null;
      lenders.forEach((x) => {
        if (x.id === this.state.selectedid) lender = x;
      });

      if (lender === null) {
        lender = lenders[0];
        console.log(this.state.selectedid);
        console.log(lenders);
      }

      // use historical config
      let lendersCopy = deepcopy(this.state.customerlenders);
      for (let x of lendersCopy) {
        if (x.id === lender.id && !x.Active) {
          x.Active = true;
          this.resetLenderIDs(lendersCopy);
          this.setState({ customerlenders: lendersCopy });
          return;
        }
      }

      lender.Config = {
        compensation: "1",
        compensationFlatFee: 0,
        closeover: null,
        lenderfees: [
          {
            fee: 0,
            states: [],
            mortgageTypes: [],
          },
        ],
        maximumClosingCostPercentage: null,
        minimumClosingCostPercentage: null,
      };

      if (this.state.lendercache[lender.id] !== undefined) {
        lender.Config = this.state.lendercache[lender.id]["Config"];
      }

      let slenders = this.state.customerlenders;
      let l = {
        id: lender.id,
        name: lender.name,
        lender: lender.lender,
        Config: lender.Config,
        Active: true,
      };
      slenders.push(l);
      this.resetLenderIDs(slenders);
      this.setState({ customerlenders: slenders });
      console.log(this.state);
    };
    let showRules = (key) => {
      return (
        <option value={key} key={key}>
          {com.RuleSet[key].name}
        </option>
      );
    };
    let displayRule = () => {
      let out = [];
      out.push(displayRuleBody());
      out.push(displayAction());
      return out;
    };

    let displayAction = () => {
      return (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label style={{ marginBottom: "0" }}>Action:</Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  required
                  type="text"
                  value={this.state.action}
                  onChange={(e) => {
                    this.setState({ action: e.target.value });
                  }}
                >
                  <option value="">...</option>
                  <option value="adjustment">Set adjustment points</option>
                  <option value="fail">Fail qualification</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {this.state.action === "adjustment" ? (
            <Row>
              <Col>
                <Form.Group controlId="action">
                  <InputGroup size="sm">
                    <Form.Control
                      size="sm"
                      required
                      value={this.state.adjustment}
                      onChange={(e) =>
                        this.setState({ adjustment: e.target.value })
                      }
                      type="text"
                      style={{ width: "3em" }}
                      pattern="^[0...9-.]*$"
                      placeholder="XX.X"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text
                        className="pl-3"
                        style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                        size="sm"
                      >
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter adjustment points
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      );
    };

    let displayRuleBody = (index) => {
      let selectedRule = this.state.rules[index].selectedRule;
      let maxrule = this.state.rules[index].maxrule;
      let minrule = this.state.rules[index].minrule;
      let value = this.state.rules[index].value;
      let values = this.state.rules[index].values;
      let typ = this.state.rules[index].typ;

      let enumValues = () => {
        let out = [];
        Object.keys(com.RuleSet[selectedRule].values).forEach((key) => {
          out.push(
            <option value={key}>{com.RuleSet[selectedRule].values[key]}</option>
          );
        });
        return out;
      };

      if (selectedRule === "" || undefined === selectedRule) return "";

      let displayFloat = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0...9-.]+$"
                placeholder="XXX.XX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };

      let displayInt = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0-9]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };

      let displayString = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let displayDollars = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text style={{ fontSize: "0.9em" }} size="sm">
                  $
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "5em" }}
                pattern="^[0-9,.]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let setRuleValue = (token) => {
        return (e) => {
          this.state.rules[index][token] = e.target.value;
          this.setState({ rules: this.state.rules });
        };
      };
      let displayOperator = () => {
        if (undefined === selectedRule || "" === selectedRule) return "";

        let showOption = (op) => {
          switch (com.RuleSet[selectedRule].typ) {
            case "minmax":
              return <option value={op}>{com.operatorInout[op]}</option>;
            case "enum":
              return <option value={op}>{com.operatorEnum[op]}</option>;
            case "bool":
              return <option value={op}>{com.operatorBool[op]}</option>;
            case "value":
            default:
              return <option value={op}>{com.operatorMap[op]}</option>;
          }
        };
        return (
          <Col
            xs="auto"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                required
                type="text"
                value={this.state.rules[index]["operator"]}
                onChange={(e) => {
                  this.state.rules[index]["operator"] = e.target.value;
                  this.setState({ rules: this.state.rules });
                }}
              >
                <option value="">...</option>
                {com.RuleSet[selectedRule].operators != null
                  ? com.RuleSet[selectedRule].operators.map(showOption)
                  : ""}
              </Form.Control>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter comparison
              </Form.Control.Feedback>{" "}
            </Form.Group>
          </Col>
        );
      };
      let getOptions = () => {
        let r = Object.keys(com.RuleSet[selectedRule].values).map((x) => {
          return { name: com.RuleSet[selectedRule].values[x], id: x };
        });

        return r;
      };
      let onSelect = (e) => {
        this.state.rules[index]["selectedEnum"] = e;
        this.setState({ rules: this.state.rules });
      };
      let selectLeftOperator = (e) => {
        this.state.rules[index]["minoperator"] = e.target.value;
        this.setState({ rules: this.state.rules });
      };
      let selecRightOperator = (e) => {
        this.state.rules[index]["maxoperator"] = e.target.value;
        this.setState({ rules: this.state.rules });
      };

      let displayRightCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.rules[index]["maxoperator"]}
                onChange={selecRightOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        );
      };
      let displayLeftCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.rules[index]["minoperator"]}
                onChange={selectLeftOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>{" "}
          </Col>
        );
      };

      switch (com.RuleSet[selectedRule].typ) {
        case "minmax":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )
                  : com.RuleSet[selectedRule].values === "int"
                  ? displayInt(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )

                  : com.RuleSet[selectedRule].values === "dollar"
                  ? displayDollars(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )
                  : ""}
              </Col>
              {displayLeftCondition()}
              <Col className="text-center">
                <span className="mx-2 text-nowrap font-weight-bold">
                  {com.RuleSet[selectedRule].short !== undefined
                    ? com.RuleSet[selectedRule].short
                    : com.RuleSet[selectedRule].name}
                </span>
              </Col>
              {displayRightCondition()}
              <Col>
                {" "}
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                  : com.RuleSet[selectedRule].values === "int"
                  ? displayInt(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                  : com.RuleSet[selectedRule].values === "dollar"
                  ? displayDollars(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                  : ""}
              </Col>
            </Row>
          );
        case "enum":
          return (
            <Row>
              {displayOperator()}
              <Col>
                <Multiselect
                  ref={this.state.rules[index].ref}
                  options={getOptions()}
                  selectedValues={this.state.rules[index]["selectedEnum"]}
                  displayValue="name"
                  onSelect={onSelect}
                  onRemove={onSelect}
                />
              </Col>
            </Row>
          );
          break;
        case "value":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : com.RuleSet[selectedRule].values === "int"
                  ? displayInt(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : com.RuleSet[selectedRule].values === "string"
                  ? displayString(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : com.RuleSet[selectedRule].values === "dollar"
                  ? displayDollars(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : ""}
              </Col>
            </Row>
          );
        case "bool":
          return (
            <Row>
              {displayOperator()}
              <Col></Col>
              <Col></Col>
            </Row>
          );
        default:
          return selectedRule;
      }
    };
    let displayPromotionBody = (index) => {
      let selectedRule = this.state.promotions[index].selectedPromotion;
      let maxrule = this.state.promotions[index].maxrule;
      let minrule = this.state.promotions[index].minrule;
      let value = this.state.promotions[index].value;
      let values = this.state.promotions[index].values;
      let typ = this.state.promotions[index].typ;

      let enumValues = () => {
        let out = [];
        Object.keys(com.RuleSet[selectedRule].values).forEach((key) => {
          out.push(
            <option value={key}>{com.RuleSet[selectedRule].values[key]}</option>
          );
        });
        return out;
      };

      if (selectedRule === "" || undefined === selectedRule) return "";

      let displayFloat = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0...9-.]+$"
                placeholder="XXX.XX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };

      let displayInt = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
                pattern="^[0-9]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let displayString = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "3em" }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let displayDollars = (id, value, onChange, feedback) => {
        return (
          <Form.Group controlId={id}>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text style={{ fontSize: "0.9em" }} size="sm">
                  $
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size="sm"
                name={id}
                required
                value={value}
                onChange={onChange}
                type="text"
                style={{ width: "5em" }}
                pattern="^[0-9,.]+$"
                placeholder="XXX"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {feedback}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      };
      let setRuleValue = (token) => {
        return (e) => {
          this.state.promotions[index][token] = e.target.value;
          this.setState({ promotions: this.state.promotions });
        };
      };
      let displayOperator = () => {
        if (undefined === selectedRule || "" === selectedRule) return "";

        let showOption = (op) => {
          switch (com.RuleSet[selectedRule].typ) {
            case "minmax":
              return <option value={op}>{com.operatorInout[op]}</option>;
            case "enum":
              return <option value={op}>{com.operatorEnum[op]}</option>;
            case "bool":
              return <option value={op}>{com.operatorBool[op]}</option>;
            case "value":
            default:
              return <option value={op}>{com.operatorMap[op]}</option>;
          }
        };
        return (
          <Col
            xs="auto"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                required
                type="text"
                value={this.state.promotions[index]["operator"]}
                onChange={(e) => {
                  this.state.promotions[index]["operator"] = e.target.value;
                  this.setState({ promotions: this.state.promotions });
                }}
              >
                <option value="">...</option>
                {com.RuleSet[selectedRule].operators != null
                  ? com.RuleSet[selectedRule].operators.map(showOption)
                  : ""}
              </Form.Control>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter comparison
              </Form.Control.Feedback>{" "}
            </Form.Group>
          </Col>
        );
      };
      let getOptions = () => {
        let r = Object.keys(com.RuleSet[selectedRule].values).map((x) => {
          return { name: com.RuleSet[selectedRule].values[x], id: x };
        });

        return r;
      };
      let onSelect = (e) => {
        this.state.promotions[index]["selectedEnum"] = e;
        this.setState({ promotions: this.state.promotions });
      };
      let selectLeftOperator = (e) => {
        this.state.promotions[index]["minoperator"] = e.target.value;
        this.setState({ promotions: this.state.promotions });
      };
      let selecRightOperator = (e) => {
        this.state.promotions[index]["maxoperator"] = e.target.value;
        this.setState({ promotions: this.state.promotions });
      };

      let displayRightCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.promotions[index]["maxoperator"]}
                onChange={selecRightOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        );
      };
      let displayLeftCondition = () => {
        return (
          <Col xs="auto">
            <Form.Group>
              <Form.Control
                as="select"
                size="sm"
                //defaultValue={this.props.firsttime}
                type="select"
                style={{ width: "5em" }}
                required
                value={this.state.promotions[index]["minoperator"]}
                onChange={selectLeftOperator}
              >
                <option key="defrule" value="">
                  ...
                </option>
                <option key="less" value="less">
                  &lt;
                </option>
                <option key="lessorequal" value="lessorequal">
                  &lt;=
                </option>
              </Form.Control>
            </Form.Group>{" "}
          </Col>
        );
      };

      switch (com.RuleSet[selectedRule].typ) {
        case "minmax":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )
                  : com.RuleSet[selectedRule].values === "int"
                  ? displayInt(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )
                  : com.RuleSet[selectedRule].values === "dollar"
                  ? displayDollars(
                      "minimumrange",
                      minrule,
                      setRuleValue("minrule"),
                      "Enter Min"
                    )
                  : ""}
              </Col>
              {displayLeftCondition()}
              <Col className="text-center">
                <span className="mx-2 text-nowrap font-weight-bold">
                  {com.RuleSet[selectedRule].short !== undefined
                    ? com.RuleSet[selectedRule].short
                    : com.RuleSet[selectedRule].name}
                </span>
              </Col>
              {displayRightCondition()}
              <Col>
                {" "}
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                  : com.RuleSet[selectedRule].values === "int"
                  ? displayInt(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                  : com.RuleSet[selectedRule].values === "dollar"
                  ? displayDollars(
                      "maximumrange",
                      maxrule,
                      setRuleValue("maxrule"),
                      "Enter Max"
                    )
                  : ""}
              </Col>
            </Row>
          );
        case "enum":
          return (
            <Row>
              {displayOperator()}
              <Col>
                <Multiselect
                  ref={this.state.promotions[index].ref}
                  options={getOptions()}
                  selectedValues={this.state.promotions[index]["selectedEnum"]}
                  displayValue="name"
                  onSelect={onSelect}
                  onRemove={onSelect}
                />
              </Col>
            </Row>
          );
          break;
        case "value":
          return (
            <Row>
              {displayOperator()}
              <Col>
                {com.RuleSet[selectedRule].values === "float"
                  ? displayFloat(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : com.RuleSet[selectedRule].values === "int"
                  ? displayInt(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : com.RuleSet[selectedRule].values === "string"
                  ? displayString(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : com.RuleSet[selectedRule].values === "dollar"
                  ? displayDollars(
                      "value",
                      value,
                      setRuleValue("value"),
                      "Please enter value"
                    )
                  : ""}
              </Col>
            </Row>
          );
        case "bool":
          return (
            <Row>
              {displayOperator()}
              <Col></Col>
              <Col></Col>
            </Row>
          );
        default:
          return selectedRule;
      }
    };
    let modalSubmit = (event) => {
      if (this.modal.current.reportValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ modalvalidated: true });

        return false;
      }
      event.preventDefault();
      event.stopPropagation();

      let tst = {};
      switch (this.state.action) {
        case "adjustment": {
          tst.adjustment = this.state.adjustment;
          break;
        }
        case "fail": {
          tst.fail = true;
          break;
        }
        default: {
          alert("should not see this!");
        }
      }
      tst.rules = [];

      this.state.rules.forEach((x) => {
        // set the config
        //create rule

        let rule = {};
        switch (com.RuleSet[x.selectedRule].typ) {
          case "minmax":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = [x.minrule, x.maxrule];
            rule[x.selectedRule].minoperator = x.minoperator;
            rule[x.selectedRule].maxoperator = x.maxoperator;
            rule[x.selectedRule].operator = x.operator;
            break;
          case "enum":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = x.selectedEnum.map((x) => x.id);
            rule[x.selectedRule].operator = x.operator;
            break;
          case "value":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = x.value;
            rule[x.selectedRule].operator = x.operator;
            break;
          case "bool":
            rule[x.selectedRule] = {};
            rule[x.selectedRule].value = true;
            rule[x.selectedRule].operator = x.operator;
            break;
        }

        tst.rules.push(rule);
      });

      if (this.state.mode === "edit") {
        this.state.config.rules[this.state.ruleindex] = tst;
        this.state.rule = tst;
      } else {
        if (this.state.config.rules == null) this.state.config.rules = [];

        this.state.config.rules.push(tst);
      }

      this.setState({
        show: false,
        customerlenders: this.state.customerlenders,
      });
      return false;
    };
    let displayPromotionBox = (rule, i) => {
      let selectPromotion = (e) => {
        this.state.promotions[i].selectedPromotion = e.target.value;
        this.setState({ promotions: this.state.promotions });
        if (this.state.promotions[i].ref == null) {
          this.state.promotions[i].ref = React.createRef();
        }

        if (null != this.state.promotions[i].ref.current) {
          setTimeout((e) => {
            if (this.state.promotions[i].ref.current != null)
              this.state.promotions[i].ref.current.resetSelectedValues();
            console.log("reset");
          }, 200);
        }
      };
      let remove = () => {
        this.state.promotions.splice(i, 1);
        this.setState({ promotions: this.state.promotions });
      };

      return (
        <div className="rulecard mb-2" key={"displayRuleBox" + i}>
          {this.state.promotions.length > 1 ? (
            <Row>
              <Col className="text-right pt-0 pr-1">
                <i
                  className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                  onClick={remove}
                ></i>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label style={{ marginBottom: "0" }}>
                  Condition:
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  //defaultValue={this.props.firsttime}
                  type="select"
                  required
                  value={rule.selectedPromotion}
                  onChange={selectPromotion}
                >
                  <option key="defrule" value="">
                    ...
                  </option>
                  {Object.keys(com.RuleSet).map(showRules)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {this.state.promotions[i].selectedPromotion !== "" ? displayPromotionBody(i) : ""}
        </div>
      );
    };
    let addMorePromotion = () => {
      let newpromotion = deepcopy(emptypromotion);
      newpromotion.ref = React.createRef();
      this.state.promotions.push(newpromotion);
      this.setState({ promotions: this.state.promotions });
    };
    let displayRuleBox = (rule, i) => {
      let selectRule = (e) => {
        this.state.rules[i].selectedRule = e.target.value;
        this.setState({ rules: this.state.rules });
        if (this.state.rules[i].ref == null) {
          this.state.rules[i].ref = React.createRef();
        }

        if (null != this.state.rules[i].ref.current) {
          setTimeout((e) => {
            if (this.state.rules[i].ref.current != null)
              this.state.rules[i].ref.current.resetSelectedValues();
            console.log("reset");
          }, 200);
        }
      };
      let remove = () => {
        this.state.rules.splice(i, 1);
        this.setState({ rules: this.state.rules });
      };

      return (
        <div className="rulecard mb-2" key={"displayRuleBox" + i}>
          {this.state.rules.length > 1 ? (
            <Row>
              <Col className="text-right pt-0 pr-1">
                <i
                  className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                  onClick={remove}
                ></i>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label style={{ marginBottom: "0" }}>
                  Condition:
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  //defaultValue={this.props.firsttime}
                  type="select"
                  required
                  value={rule.selectedRule}
                  onChange={selectRule}
                >
                  <option key="defrule" value="">
                    ...
                  </option>
                  {Object.keys(com.RuleSet).map(showRules)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {this.state.rules[i].selectedRule !== "" ? displayRuleBody(i) : ""}
        </div>
      );
    };
    let addMore = () => {
      let newrule = deepcopy(emptyrule);
      newrule.ref = React.createRef();
      this.state.rules.push(newrule);
      this.setState({ rules: this.state.rules });
    };
    let copyOptions = () => {
      let out = [];
      this.state.customerlenders.forEach((x) => {
        if (x.id === this.state.lenderid) return;
        out.push(<option value={x.id}> {x.name} </option>);
        // console.log(x)
      });
      return out;
    };
    let modalCopySubmit = (event) => {
      if (this.modalcopy.current.reportValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ modalcopyvalidated: true });

        return false;
      }
      event.preventDefault();
      event.stopPropagation();

      this.state.customerlenders.forEach((x) => {
        if (x.id.toString() === this.state.targetcopy) {
          //alert(this.state.targetcopy)
          x.Config.rules = deepcopy(this.state.config.rules);
        }
      });

      this.setState({
        showcopy: false,
        customerlenders: this.state.customerlenders,
      });
      return false;
    };
    let modalPromotionSubmit = (event) => {
      if (this.promotionmodal.current.reportValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ modalpromotionvalidated: true });

        return false;
      }
      event.preventDefault();
      event.stopPropagation();

      let tst = {};
      tst.conditions = [];
      tst.message = this.state.message;

      this.state.promotions.forEach((x) => {
        // set the config
        //create rule

        let rule = {};
        switch (com.RuleSet[x.selectedPromotion].typ) {
          case "minmax":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = [x.minrule, x.maxrule];
            rule[x.selectedPromotion].minoperator = x.minoperator;
            rule[x.selectedPromotion].maxoperator = x.maxoperator;
            rule[x.selectedPromotion].operator = x.operator;
            break;
          case "enum":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = x.selectedEnum.map((x) => x.id);
            rule[x.selectedPromotion].operator = x.operator;
            break;
          case "value":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = x.value;
            rule[x.selectedPromotion].operator = x.operator;
            break;
          case "bool":
            rule[x.selectedPromotion] = {};
            rule[x.selectedPromotion].value = true;
            rule[x.selectedPromotion].operator = x.operator;
            break;
        }

        tst.conditions.push(rule);
      });

      if (this.state.mode === "edit") {
        this.state.config.promotions[this.state.promotionindex] = tst;

      } else {
        if (this.state.config.promotions == null) this.state.config.promotions = [];

        this.state.config.promotions.push(tst);
      }
      console.log(this.state.config)
      this.setState({
        showpromotion: false,
        customerlenders: this.state.customerlenders,
      });
      return false;
    };

    const sendMsgToSlack = () => {

      let token = sessionStorage.getItem("ZeitroA");
  
      fetch("/agent/addlenderrequest", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
      }).then((response) => {
        if (response.status !== 200) {
          console.log(
            "/agent/addlenderrequest: Looks like there was a problem. Status Code: " +
              response.status
          );
          return;
        }
      });
    };

    return (
      <div className="text-left">
        <h5 className="ml-4 mt-3">Wholesale Lenders:</h5>
        <Modal
          dialogClassName="modal-50w"
          show={this.state.show}
          onHide={(e) => {
            this.setState({ show: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.mode === "edit" ? (
                <>Edit an adjustment rule</>
              ) : (
                <>Add an adjustment rule</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Form
            ref={this.modal}
            onSubmit={modalSubmit}
            noValidate
            validated={this.state.modalvalidated}
          >
            <Modal.Body>
              {this.state.rules.map(displayRuleBox)}
              <Row>
                <Col className="text-right">
                  <Button onClick={addMore} variant="link">
                    More...
                  </Button>
                </Col>
              </Row>
              {this.state.selectedRule !== "" ? displayAction() : ""}
            </Modal.Body>

            <Modal.Footer className="text-center d-block">
              <Row className="text-center">
                <Col>
                  <Button
                    onClick={(e) => {
                      this.setState({ show: false });
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button variant="success" type="submit">
                    {this.state.mode === "edit" ? "Update Rule" : "Add Rule"}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName="modal-50w"
          show={this.state.showcopy}
          onHide={(e) => {
            this.setState({ showcopy: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Copy adjustment rules from {this.state.lendername}
            </Modal.Title>
          </Modal.Header>
          <Form
            ref={this.modalcopy}
            onSubmit={modalCopySubmit}
            noValidate
            validated={this.state.modalcopyvalidated}
          >
            <Modal.Body>
              <Form.Group>
                <Form.Label style={{ marginBottom: "0" }}>To:</Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  required
                  type="text"
                  //value={this.state.action}

                  onChange={(e) => {
                    this.setState({ targetcopy: e.target.value });
                  }}
                >
                  <option value="">...</option>
                  {copyOptions()}
                </Form.Control>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer className="text-center d-block">
              <Row className="text-center">
                <Col>
                  <Button
                    onClick={(e) => {
                      this.setState({ showcopy: false });
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button variant="success" type="submit">
                    Copy
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName="modal-50w"
          show={this.state.showpromotion}
          onHide={(e) => {
            this.setState({ showpromotion: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.mode === "edit" ? (
                <>Edit promotion</>
              ) : (
                <>Add promotion</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Form
            ref={this.promotionmodal}
            onSubmit={modalPromotionSubmit}
            noValidate
            validated={this.state.modalpromotionvalidated}
          >
            <Modal.Body>
              {this.state.promotions.map(displayPromotionBox)}
              <Row>
                <Col className="text-right">
                  <Button onClick={addMorePromotion} variant="link">
                    More...
                  </Button>
                </Col>
              </Row>
              {this.state.selectedRule !== "" ? <Row>
                <Col>
                  <Form.Group>
                    <Form.Label style={{ marginBottom: "0" }}>Promotion message:</Form.Label>
                    <Form.Control
                      size="sm"
                      required
                      type="text"
                      value={this.state.message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                    >
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row> : ""}
            </Modal.Body>

            <Modal.Footer className="text-center d-block">
              <Row className="text-center">
                <Col>
                  <Button
                    onClick={(e) => {
                      this.setState({ showpromotion: false });
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button variant="success" type="submit">
                    {this.state.mode === "edit" ? "Update Promotion" : "Add Promotion"}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        <Form
          ref={this.form}
          onSubmit={this.handleSubmit}
          noValidate
          validated={this.state.validated}
        >
          {addLenders() !== [] ? (
            <Row>
              <Col xs={12} md style={{ paddingTop: "1.33em" }} className={this.isMobile?"ml-0":"ml-3"}>
                <Form.Control
                  name="lenders"
                  as="select"
                  value={this.state.selectedid}
                  onChange={setSelectedLender}
                >
                  {addLenders()}
                </Form.Control>
              </Col>
              <Col xs={12} md style={{ paddingTop: "1.35em" }} className="d-flex">
                <Button variant="zeitro-primary" onClick={add}>Add</Button>
                <Popconfirm
                  title="Would you like to send a request to add lenders to the Zeitro team?"
                  description="After submitting your request, Zeitro will reach out to you with instructions on how to add additional lenders to the system. We may require your assistance in obtaining the necessary information for these lenders."
                  onConfirm={()=>{
                    sendMsgToSlack()
                    message.success("Your request has been sent, and the Zeitro team will contact you soon.")
                  }}
                  onCancel={()=>{}}
                  okText="Send request"
                  placement="bottom"
                  overlayStyle={{width:400}}
                  cancelText="Cancel"
                  icon={<InfoCircleTwoTone />}
                ><Button className="ml-2" variant="link">Lenders not in the list? </Button></Popconfirm>
                
              </Col>
              {/* <Col xs="auto">
                <Form.Group controlId="password">
                  <Form.Label className="text-left">
                    Global Min Closing Cost:
                  </Form.Label>

                  <InputGroup size="sm">
                    <Form.Control
                      size="sm"
                      name={"mincost"}
                      //required
                      value={this.state.minimumClosingCostPercentage}
                      onChange={(e) => {
                        this.setState({
                          minimumClosingCostPercentage: e.target.value,
                        });
                      }}
                      type="text"
                      style={{ width: "8em" }}
                      pattern="^[0...9-.]*$"
                      placeholder="(-)XXX or empty"
                    />

                    <InputGroup.Append>
                      <InputGroup.Text
                        className="pl-3"
                        style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                        size="sm"
                      >
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter Min Closing Cost Points
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Form.Group controlId="password">
                  <Form.Label className="text-left">
                    Global Max Closing Cost:
                  </Form.Label>

                  <InputGroup size="sm">
                    <Form.Control
                      size="sm"
                      name={"mincost"}
                      //required
                      value={this.state.maximumClosingCostPercentage}
                      onChange={(e) => {
                        this.setState({
                          maximumClosingCostPercentage: e.target.value,
                        });
                      }}
                      type="text"
                      style={{ width: "8em" }}
                      pattern="^[0...9-.]*$"
                      placeholder="XXX or empty"
                    />

                    <InputGroup.Append>
                      <InputGroup.Text
                        className="pl-3"
                        style={{ fontSize: "0.9em", lineHeight: "1.3" }}
                        size="sm"
                      >
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter Min Closing Cost Points
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col> */}
            </Row>
          ) : (
            ""
          )}
          {showLenders()}
          <Row className="mb-5">
            <Col className="text-center">
              <Button type="submit">{this.state.showSpinner? <Spinner size="sm" animation="border" variant="primary" />: "Update"}</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Lenders;
