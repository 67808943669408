import React, { useState, useEffect, useRef } from "react";
import "./Properties.css";
import { Form, Col, Button, Row, Container } from "react-bootstrap";

const ImageUploader = (props) => {
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
//   const [imageSRCS, setImageSRCs] = useState([]);

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setImageURLs(newImageUrls);
  }, [images]);

  const onImageChange = async (e) => {
    setImages([...e.target.files]);

    const fileToDataUri = (image) => {
        return new Promise((res) => {
          const reader = new FileReader();
          const {type, name, size} = image;
          reader.addEventListener('load', () => {
              res({
                  base64: reader.result,
                  name: name,
                  type,
                  size: size,
              })
          });
          reader.readAsDataURL(image);
        })
      }

      const newImagesPromises = []
      for (let i = 0; i < e.target.files.length; i++) {
          newImagesPromises.push(fileToDataUri(e.target.files[i]))
      }
      const newImages = await Promise.all(newImagesPromises)
      props.onChangeImageSRCs(newImages)
    
  };

  return (
    <div>
      <input type="file" multiple accept="image/*" onChange={onImageChange} defaultValue={props.defaultValue}/>
      <Row>Please select all images</Row>
      <Row>
        {imageURLS.map((imageSrc) => (
          <img
            src={imageSrc}
            alt="not found"
            style={{ maxWidth: "200px", maxHeight: "200px" }}
          />
        ))}
      </Row>
    </div>
  );
};
export default ImageUploader;
