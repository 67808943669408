import React, { useState, useEffect, useRef } from "react";
import "./ReferredRealtors.css";
import ListGroup from "react-bootstrap/ListGroup";
import { Row, Col, Form, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AddReferredRealtors from "./AddReferredRealtors";
import CurrentReferredRealtors from "./CurrentReferredRealtors";

const { SearchBar, ClearSearchButton } = Search;

const ReferredRealtors = (props) => {
  return (
    <Tabs defaultActiveKey="current" unmountOnExit="true" transition={false} id="realtorstabs">
      <Tab style={{ height: '100%' }} eventKey="current" title="Current Contacts">
        <CurrentReferredRealtors />
      </Tab>
      <Tab style={{ height: '100%' }} eventKey="createre" title="Add New Realtors">
        <AddReferredRealtors />
      </Tab>
    </Tabs>
  )
};
export default ReferredRealtors;
