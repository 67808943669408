import React from 'react';
import * as com from '../Common';
import styles from './Spinner.module.css';

const Spinner = props => (
  <div id="spinner" style={{...props.style}} className={`${props.addSpinnerClass && styles.spinner} ${props.className}`}>
    <img alt="spinner" className={`${styles.loader}`} src="/images/zeitrologo.png"></img>
  </div>
)


export default Spinner