import React, { useReducer } from 'react';

import classes from '../CustomizationPreview.module.css';

const hoverReducer = (state, action) => {
  switch (action.type) {
    case 'application':
      return { ...state, application: action.payload };
    case 'start':
      return { ...state, start: action.payload };
    case 'personal':
      return { ...state, personal: action.payload };
    case 'choice':
      return { ...state, choice: action.payload };
    case 'document':
      return { ...state, document: action.payload };
    case 'status':
      return { ...state, status: action.payload };
    case 'contact':
      return { ...state, contact: action.payload };
    default:
      return { ...state };
  }
}

const Sidebar = props => {
  const [state, dispatch] = useReducer(hoverReducer, {
    application: false,
    start: false,
    personal: false,
    choice: false,
    document: false,
    status: false,
    contact: false,
  });

  const { colors } = props;

  const styleOnHover = (condition) => ({
    backgroundColor: condition ? colors.paleColor : colors.primaryColor2,
    color: condition ? colors.paleColorText : colors.primaryColor2Text,
    borderBottom: `0.5px solid ${colors.primaryColor3}`
  });

  return (
    <div className={classes.sidebar} style={{ backgroundColor: colors.primaryColor, color: colors.primaryColorText }}>
      <div className={classes.sidesection}>Dashboard</div>
      <button
        onMouseEnter={() => dispatch({ type: 'application', payload: true })}
        onMouseLeave={() => dispatch({ type: 'application', payload: false })}
        style={styleOnHover(state.application)}
        className={`${classes.application} ${classes.hover}`}
      >
        My Application
      </button>
      <div
        onMouseEnter={() => dispatch({ type: 'start', payload: true })}
        onMouseLeave={() => dispatch({ type: 'start', payload: false })}
        style={styleOnHover(state.start)}
        className={`${classes.interviewStep} ${classes.hover}`}
      >
        <div className={classes.filler} />
        <div></div>
        Start
      </div>
      <div
        onMouseEnter={() => dispatch({ type: 'personal', payload: true })}
        onMouseLeave={() => dispatch({ type: 'personal', payload: false })}
        style={styleOnHover(state.personal)}
        className={`${classes.interviewStep} ${classes.hover}`}>
        <div className={classes.filler} />
        <div></div>
        Personal
      </div>
      <div
        onMouseEnter={() => dispatch({ type: 'choice', payload: true })}
        onMouseLeave={() => dispatch({ type: 'choice', payload: false })}
        style={styleOnHover(state.choice)}
        className={`${classes.interviewStep} ${classes.hover}`}
      >
        <div className={classes.filler} style={{ backgroundColor: colors.paleColor }} />
        <div></div>
        Loan Choice
      </div>
      <div
        style={{
          backgroundColor: colors.primaryColor2,
          color: colors.primaryColor2Text,
          borderBottom: `0.5px solid ${colors.primaryColor3}`
        }}
        className={`${classes.interviewStep} ${classes.disabled}`}
      >
        <div className={classes.filler} />
        <div></div>
        Declaration
      </div>
      <div
        onMouseEnter={() => dispatch({ type: 'document', payload: true })}
        onMouseLeave={() => dispatch({ type: 'document', payload: false })}
        className={`${classes.other} ${classes.hover}`}
        style={state.document ? styleOnHover(state.document) : {
          borderBottom: `0.5px solid ${colors.primaryColor3}`,
        }} >
        <i className="fa fa-file mr-1"></i>
        <span className="sidebartext">Documents</span>
      </div>
      <div
        onMouseEnter={() => dispatch({ type: 'status', payload: true })}
        onMouseLeave={() => dispatch({ type: 'status', payload: false })}
        className={`${classes.other} ${classes.hover}`}
        style={state.status ? styleOnHover(state.status) : {
          borderBottom: `0.5px solid ${colors.primaryColor3}`
        }} >
        <i className="fas  fa-stopwatch mr-1"></i>
        <span className="sidebartext">Loan Status</span>
      </div>
      <div
        onMouseEnter={() => dispatch({ type: 'contact', payload: true })}
        onMouseLeave={() => dispatch({ type: 'contact', payload: false })}
        className={`${classes.other} ${classes.hover}`}
        style={state.contact ? styleOnHover(state.contact) : {
          borderBottom: `0.5px solid ${colors.primaryColor3}`
        }} >
        <i className="fa  fa-envelope mr-1"></i>
        <span className="sidebartext">Contact Us</span>
      </div>
    </div >
  );
}

export default Sidebar;