import React, { Component } from "react";
import "./Dropzone.css";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if(files.length === 0) {
      this.setState({ hightlight: false });
      return
    }
    let file = files[0]
    let ext = file.name.split('.').pop();
    
    let allowed = ['jpg', 'jpeg', 'png', 'gif','JPG', 'JPEG', 'PNG', 'GIF']
    if(!allowed.includes(ext)) {
      this.setState({ hightlight: false });
      return
    }
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    return (
      <div
      className={`Dropzone ${this.props.type === "button" ? "add-btn" : ""} ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        {this.props.btnText}
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
    
          onChange={this.onFilesAdded}
          accept=".gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG"
        />
        {
          this.props.type !== "button" && <img
            alt="upload"
            className="Icon"
            src={this.props.src}
            ref={this.props.imageref}
          />
        }
      </div>
    );
  }
}

export default Dropzone;
