import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import useInput from '../../hooks/use-input';

import classes from '../../hooks/hook.module.css';

const validateEmail = email => String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const urlValidityCheckerOrEmpty = value => (value.trim() !== '' && ((value.startsWith('http://') && value.replace('http://', '').length !== 0) || (value.startsWith('https://') && value.replace('https://', '').length !== 0))) || value.trim() === '';
const allwaysTrue = value => true;

const Other = () => {
  const [submitted, setSubmitted] = useState(false);

  const {
    value: noReplyEmail,
    valueIsValid: noReplyEmailIsValid,
    valueInputChangeHandler: noReplyEmailInputChangeHandler,
    valueInputBlurHandler: noReplyEmailInputBlurHandler,
    validationCondition: validationConditionForNoReplyEmail,
    cssClasses: noReplyEmailClasses,
  } = useInput(validateEmail, submitted);

  const {
    value: supportEmail,
    valueIsValid: supportEmailIsValid,
    valueInputChangeHandler: supportEmailInputChangeHandler,
    valueInputBlurHandler: supportEmailInputBlurHandler,
    validationCondition: validationConditionForSupportEmail,
    cssClasses: supportEmailClasses,
  } = useInput(validateEmail, submitted);

  const {
    value: twitter,
    valueIsValid: twitterIsValid,
    valueInputChangeHandler: twitterInputChangeHandler,
    valueInputBlurHandler: twitterInputBlurHandler,
    cssClasses: twitterClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);
  const {
    value: twitterIconURL,
    valueIsValid: twitterIconURLIsValid,
    valueInputChangeHandler: twitterIconURLInputChangeHandler,
    valueInputBlurHandler: twitterIconURLInputBlurHandler,
    cssClasses: twitterIconURLClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: instagram,
    valueIsValid: instagramIsValid,
    valueInputChangeHandler: instagramInputChangeHandler,
    valueInputBlurHandler: instagramInputBlurHandler,
    cssClasses: instagramClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: instagramIconURL,
    valueIsValid: instagramIconURLIsValid,
    valueInputChangeHandler: instagramIconURLInputChangeHandler,
    valueInputBlurHandler: instagramIconURLInputBlurHandler,
    cssClasses: instagramIconURLClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: linkedin,
    valueIsValid: linkedinIsValid,
    valueInputChangeHandler: linkedinInputChangeHandler,
    valueInputBlurHandler: linkedinInputBlurHandler,
    cssClasses: linkedinClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: linkedinIconURL,
    valueIsValid: linkedinIconURLIsValid,
    valueInputChangeHandler: linkedinIconURLInputChangeHandler,
    valueInputBlurHandler: linkedinIconURLInputBlurHandler,
    cssClasses: linkedinIconURLClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: facebook,
    valueIsValid: facebookIsValid,
    valueInputChangeHandler: facebookInputChangeHandler,
    valueInputBlurHandler: facebookInputBlurHandler,
    cssClasses: facebookClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: facebookIconURL,
    valueIsValid: facebookIconURLIsValid,
    valueInputChangeHandler: facebookIconURLInputChangeHandler,
    valueInputBlurHandler: facebookIconURLInputBlurHandler,
    cssClasses: facebookIconURLClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: logoURL,
    valueIsValid: logoURLIsValid,
    valueInputChangeHandler: logoURLInputChangeHandler,
    valueInputBlurHandler: logoURLInputBlurHandler,
    cssClasses: logoURLClasses,
  } = useInput(urlValidityCheckerOrEmpty, submitted);

  const {
    value: phone,
    valueIsValid: phoneIsValid,
    valueInputChangeHandler: phoneInputChangeHandler,
    valueInputBlurHandler: phoneInputBlurHandler,
    validationCondition: validationConditionForPhone,
    cssClasses: phoneClasses,
  } = useInput(allwaysTrue, submitted);

  const {
    value: address,
    valueIsValid: addressIsValid,
    valueInputChangeHandler: addressInputChangeHandler,
    valueInputBlurHandler: addressInputBlurHandler,
    validationCondition: validationConditionForAddress,
    cssClasses: addressClasses,
  } = useInput(allwaysTrue, submitted);

  useEffect(() => {
    const token = sessionStorage.getItem("ZeitroA");
    fetch('/los/customersocials', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }
      response.json().then(js => {
        let contacts = JSON.parse(js.Contacts);
        let icons = JSON.parse(js.Icons);
        let helper = (value) => ({ target: { value } })
        noReplyEmailInputChangeHandler(helper(contacts.EmailAddresses.NoReplyEmail));
        supportEmailInputChangeHandler(helper(contacts.EmailAddresses.SupportEmail));
        twitterInputChangeHandler(helper(contacts.SocialMedias.Twitter));
        instagramInputChangeHandler(helper(contacts.SocialMedias.Instagram));
        linkedinInputChangeHandler(helper(contacts.SocialMedias.Linkedin));
        facebookInputChangeHandler(helper(contacts.SocialMedias.Facebook));
        phoneInputChangeHandler(helper(contacts.Phone));
        addressInputChangeHandler(helper(contacts.Address));
        twitterIconURLInputChangeHandler(helper(icons.TwitterIconURL));
        linkedinIconURLInputChangeHandler(helper(icons.LinkedinIconURL));
        instagramIconURLInputChangeHandler(helper(icons.InstagramIconURL));
        facebookIconURLInputChangeHandler(helper(icons.FacebookIconURL));
        logoURLInputChangeHandler(helper(icons.LogoURL));
      });
    })
  }, [noReplyEmailInputChangeHandler, supportEmailInputChangeHandler, twitterInputChangeHandler, instagramInputChangeHandler, linkedinInputChangeHandler, facebookInputChangeHandler, phoneInputChangeHandler, addressInputChangeHandler, twitterIconURLInputChangeHandler, linkedinIconURLInputChangeHandler, instagramIconURLInputChangeHandler, facebookIconURLInputChangeHandler, logoURLInputChangeHandler])


  const formIsValid = noReplyEmailIsValid && supportEmailIsValid && twitterIsValid && instagramIsValid &&
    linkedinIsValid && facebookIsValid && phoneIsValid & addressIsValid && twitterIconURLIsValid &&
    instagramIconURLIsValid && linkedinIconURLIsValid && facebookIconURLIsValid && logoURLIsValid;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (formIsValid) {

      const token = sessionStorage.getItem("ZeitroA");

      const body = JSON.stringify({
        NoReplyEmail: noReplyEmail,
        SupportEmail: supportEmail,
        Twitter: twitter,
        TwitterIconURL: twitterIconURL,
        Instagram: instagram,
        InstagramIconURL: instagramIconURL,
        Linkedin: linkedin,
        LinkedinIconURL: linkedinIconURL,
        Facebook: facebook,
        FacebookIconURL: facebookIconURL,
        LogoURL: logoURL,
        Phone: phone,
        Address: address,
      });

      fetch('/los/updatecustomersocials', {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body,
      }).then(response => {
        if (!response.ok) {
          alert(`contact engineering team please, error: ${response.statusText}`);
          return;
        }

        alert("Contact information is updated successfully")

      }).finally(() => {
        setSubmitted(false);
      })
    } else {
      alert('Some fields are empty');
    }
  };

  const contactInputs = [
    [
      {
        className: twitterClasses,
        controlId: 'twitter',
        label: 'Twitter link',
        placeholder: "Enter twitter link",
        value: twitter,
        onChange: twitterInputChangeHandler,
        onBlur: twitterInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
      {
        className: instagramClasses,
        controlId: 'instagram',
        label: 'Instagram link',
        placeholder: "Enter instagram link",
        value: instagram,
        onChange: instagramInputChangeHandler,
        onBlur: instagramInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
      {
        className: linkedinClasses,
        controlId: 'linkedin',
        label: 'Linkedin link',
        placeholder: "Enter linkedin link",
        value: linkedin,
        onChange: linkedinInputChangeHandler,
        onBlur: linkedinInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
      {
        className: facebookClasses,
        controlId: 'facebook',
        label: 'Facebook link',
        placeholder: "Enter facebook link",
        value: facebook,
        onChange: facebookInputChangeHandler,
        onBlur: facebookInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
    ],
    [
      {
        className: twitterIconURLClasses,
        controlId: 'twitterIcon',
        label: 'Twitter icon url',
        placeholder: "Enter twitter icon url",
        value: twitterIconURL,
        onChange: twitterIconURLInputChangeHandler,
        onBlur: twitterIconURLInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
      {
        className: instagramIconURLClasses,
        controlId: 'instagramIconURL',
        label: 'Instagram icon url',
        placeholder: "Enter instagram icon url",
        value: instagramIconURL,
        onChange: instagramIconURLInputChangeHandler,
        onBlur: instagramIconURLInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
      {
        className: linkedinIconURLClasses,
        controlId: 'linkedinIconURL',
        label: 'Linkedin icon url',
        placeholder: "Enter linkedin icon url",
        value: linkedinIconURL,
        onChange: linkedinIconURLInputChangeHandler,
        onBlur: linkedinIconURLInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
      {
        className: facebookIconURLClasses,
        controlId: 'facebookIconURL',
        label: 'Facebook icon url',
        placeholder: "Enter facebook icon url",
        value: facebookIconURL,
        onChange: facebookIconURLInputChangeHandler,
        onBlur: facebookIconURLInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
      {
        className: logoURLClasses,
        controlId: 'logoURL',
        label: 'Logo url',
        placeholder: "Enter logo url",
        value: logoURL,
        onChange: logoURLInputChangeHandler,
        onBlur: logoURLInputBlurHandler,
        formText: 'Should be empty or should start with "http://" or "https://"',
        disabled: false
      },
    ],
    [
      {
        className: phoneClasses,
        controlId: 'phone',
        label: 'Phone number',
        placeholder: validationConditionForPhone ? 'Invalid' : 'Enter phone number',
        value: phone,
        onChange: phoneInputChangeHandler,
        onBlur: phoneInputBlurHandler,
        formText: !validationConditionForPhone ? 'Phone number' : '',
        disabled: false
      },
      {
        className: addressClasses,
        controlId: 'Address',
        label: 'Address',
        placeholder: validationConditionForAddress ? 'Invalid' : 'Enter Address',
        value: address,
        onChange: addressInputChangeHandler,
        onBlur: addressInputBlurHandler,
        formText: !validationConditionForAddress ? 'Address' : '',
        disabled: false
      },
      {
        className: noReplyEmailClasses,
        controlId: 'noReplyEmail',
        label: 'No Reply Email',
        placeholder: validationConditionForNoReplyEmail ? 'Invalid' : 'Enter no reply email',
        value: noReplyEmail,
        onChange: noReplyEmailInputChangeHandler,
        onBlur: noReplyEmailInputBlurHandler,
        formText: !validationConditionForNoReplyEmail ? 'No reply email address' : 'Email should follow standard email format.',
        disabled: false
      },
      {
        className: supportEmailClasses,
        controlId: 'supportEmail',
        label: 'Support Email',
        placeholder: validationConditionForSupportEmail ? 'Invalid' : 'Enter support team email',
        value: supportEmail,
        onChange: supportEmailInputChangeHandler,
        onBlur: supportEmailInputBlurHandler,
        formText: !validationConditionForSupportEmail ? 'Support address' : 'Email should follow standard email format.',
        disabled: false
      }
    ]
  ]

  return (
    <Form className={classes['m-1']}>
      <h2>Contacts</h2>
      <p>Fields can be left blank, except email addresses</p>
      {contactInputs.map((el, i) => <Row key={i}>
        {el.map((el2, j) => {
          if (el2.$$typeof) {
            return el2
          } else {
            return <Form.Group key={`${i}${j}`} as={Col} className={el2.className} controlId={el2.controlId}>
              <Form.Label>{el2.label}</Form.Label>
              <Form.Control type="text" placeholder={el2.placeholder} value={el2.value} onChange={el2.onChange} onBlur={el2.onBlur} disabled={el2.disabled} />
              <Form.Text className="text-muted mb-3">{el2.formText}</Form.Text>
            </Form.Group>
          }
        }
        )}
      </Row>
      )}
      <Row className='m-3'>
        <Col>
          <Button variant="primary" onClick={formSubmitHandler}>
            Submit
          </Button>
        </Col>
      </Row>

    </Form>
  );
}

export default Other;