import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../CommonUIComponents/Spinner';
import classes from './ImagePreview.module.css';

export default function DocumentPreview({ fileName, magnifier, boxConfig, scaleFactor, changeMagnifierAccessibility }) {
  //component's state
  const [preview, setPreview] = useState([]);
  const [imagesStyles, setImagesStyles] = useState({});
  const [bboxStyle, setBboxStyle] = useState({});

  //redux state
  const borrower = useSelector(state => state.borrower);

  const imagesRefs = useRef({});
  const mostOutterElement = useRef(null);
  // found imperically, tied to .bbox border sizes in css file
  const offsetForArrowLeft = 31;
  const offsetForArrowTop = 25;


  const prefix = "data:image/png;base64,"

  useEffect(() => {
    /* using closure, we can prevent unnecessary updates
     * shouldUpdate is set in the closure, update is happening, only if shouldUpdate is true
     * function we return, where shouldUpdate is set to false, is executed, when props.fileName is changed
     * meaning that state update for old value of props.fileName will not happen
     * Ex. if we change props.fileName twice in 2 seconds, first request will be sent, but the response
     * (let's assume) will not be received yet, but shouldUpdate will already be false for that response
    */
    setPreview([])
    setImagesStyles({})
    setBboxStyle({})
    changeMagnifierAccessibility(false);
    let token = sessionStorage.getItem("ZeitroA")
    let shouldUpdate = true
    if (fileName !== '') {
      fetch(`/agent/documentpreview/${fileName.replace('.pdf', '')}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-Borrower": borrower.id
        }
      }).then(
        async response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
              response.status);
            return;
          }
          await response.json().then(json => {
            if (shouldUpdate) {
              setPreview(json.Document.pngPagesBase64)
              for (let i = 0; i < json.Document.pngPagesBase64.length; i++) {
                setImagesStyles(prev => ({ ...prev, [i]: {} }));
              }
              changeMagnifierAccessibility(true);
            }
            return;
          });
        }
      ).catch((err) => {
        console.log('Fetch Error :', err);
      });
      return () => {
        shouldUpdate = false
      }
    }
  }, [fileName, borrower.id, changeMagnifierAccessibility]);

  const getZeroLeftOrTopWhenMagnified = useCallback((imageHeightOrWidth) => {
    // values won't be actual zeros, because of how css scale works
    // formula to calculate the values was found empirically
    return (imageHeightOrWidth / 2) * (scaleFactor - 1)
  }, [scaleFactor])

  const getValueBetweenBoundaries = useCallback((value, imageDimension) => {
    //so that we can't set or drag beyond the edges of the image
    let leftOrTop = getZeroLeftOrTopWhenMagnified(imageDimension);
    let rightOrBottom = -leftOrTop;
    // equivalences are backwards, because when it's zero zero (top left)
    // its positive, positive , but we can move to the right and bottom
    // towards negatives
    if (value < leftOrTop && value > rightOrBottom) {
      return value;
    } else if (value > leftOrTop && value > rightOrBottom) {
      return leftOrTop;
    } else if (value < leftOrTop && value < rightOrBottom) {
      return rightOrBottom;
    }
    return leftOrTop;
  }, [getZeroLeftOrTopWhenMagnified])

  useEffect(() => {
    if (magnifier) {
      setImagesStyles(prev => {
        let newState = {};
        for (let i = 0; i < Object.keys(prev).length; i++) {
          newState[i] = {
            ...prev[i],
            transform: `scale(${scaleFactor})`,
            top: `${getZeroLeftOrTopWhenMagnified(imagesRefs.current[i].children[0].offsetHeight)}px`,
            left: `${getZeroLeftOrTopWhenMagnified(imagesRefs.current[i].children[0].offsetWidth)}px`
          };
        }
        return newState;
      })
    } else {
      setImagesStyles(prev => {
        let newState = {};
        for (let i = 0; i < Object.keys(prev).length; i++) {
          newState[i] = { ...prev[i], transform: 'scale(1)', top: 0, left: 0 };
        }
        return newState;
      })
    }
  }, [magnifier, scaleFactor, getZeroLeftOrTopWhenMagnified])

  useEffect(() => {
    if (imagesRefs.current[boxConfig.page - 1]) {
      //calculate box styles when toggling mignifier or selecting other box
      let multiplier = magnifier ? scaleFactor : 1;
      let imgWidth = imagesRefs.current[boxConfig.page - 1].children[0].offsetWidth;
      let imgHeight = imagesRefs.current[boxConfig.page - 1].children[0].offsetHeight;
      let left = imgWidth * boxConfig.bbox.Left * multiplier;
      let top = (imgHeight * boxConfig.bbox.Top + (boxConfig.page - 1) * imgHeight) * multiplier;
      let width = imgWidth * boxConfig.bbox.Width * multiplier;
      let height = imgHeight * boxConfig.bbox.Height * multiplier;

      let offsetLeft = offsetForArrowLeft;
      let offsetTop = offsetForArrowTop;

      for (let i = 0; i < boxConfig.page - 1; i++) {
        let imageHeight = imagesRefs.current[i].children[0].offsetHeight * multiplier;
        offsetTop += imageHeight;
      }

      //scroll of all images, to the needed page
      const imagesScroll = mostOutterElement.current.parentNode;
      imagesScroll.scrollTop = (offsetTop / multiplier) - offsetForArrowTop;


      if (magnifier) {
        //"scroll" of image with the box vertically(left) and horizontally(top)

        let leftForImage = getValueBetweenBoundaries(
          getZeroLeftOrTopWhenMagnified(imagesRefs.current[boxConfig.page - 1].children[0].offsetWidth) - left + offsetForArrowLeft,
          imagesRefs.current[boxConfig.page - 1].children[0].offsetWidth
        )

        let topForImage = getValueBetweenBoundaries(
          getZeroLeftOrTopWhenMagnified(imagesRefs.current[boxConfig.page - 1].children[0].offsetHeight) - top + offsetTop + offsetForArrowTop + offsetForArrowTop,
          imagesRefs.current[boxConfig.page - 1].children[0].offsetHeight
        )

        setImagesStyles(prev => (
          {
            ...prev,
            [boxConfig.page - 1]: {
              ...prev[boxConfig.page - 1],
              left: leftForImage + 'px',
              top: topForImage + 'px',
            }
          }))

        offsetLeft += getZeroLeftOrTopWhenMagnified(imagesRefs.current[boxConfig.page - 1].children[0].offsetWidth) - leftForImage;
        offsetTop += getZeroLeftOrTopWhenMagnified(imagesRefs.current[boxConfig.page - 1].children[0].offsetHeight) - topForImage;
      }

      setBboxStyle(prev => ({ ...prev, display: 'block', left: `${left - offsetLeft}px`, top: `${top - offsetTop}px`, width: `${width}px`, height: `${height}px` }));

    }
  }, [magnifier, boxConfig, scaleFactor, getZeroLeftOrTopWhenMagnified, getValueBetweenBoundaries])

  const dragHandle = e => {
    if (magnifier) {
      if (e.preventDefault) e.preventDefault();

      const imageIndex = e.target.getAttribute("custom-key")

      let wrapperDiv = imagesRefs.current[imageIndex];

      if (!wrapperDiv.children[0].classList.contains(classes.image)) {
        return
      };

      // calculate event X, Y coordinates
      const offsetX = e.clientX;
      const offsetY = e.clientY;


      // calculate integer values for top and left properties
      const coordX = parseInt(imagesStyles[imageIndex].left);
      const coordY = parseInt(imagesStyles[imageIndex].top);

      let imgWidth = wrapperDiv.children[0].offsetWidth;
      let imgHeight = wrapperDiv.children[0].offsetHeight;
      let constLeft = 0;
      let constTop = 0;
      if (boxConfig.bbox) {
        constLeft = imgWidth * boxConfig.bbox.Left;
        constTop = imgHeight * boxConfig.bbox.Top + (boxConfig.page - 1) * imgHeight;
      }

      if (magnifier) {
        constLeft *= scaleFactor;
        constTop *= scaleFactor;
      }

      function dragDiv(e) {

        let leftValue = getValueBetweenBoundaries(coordX - offsetX + e.clientX, wrapperDiv.children[0].offsetWidth);
        let topValue = getValueBetweenBoundaries(coordY - offsetY + e.clientY, wrapperDiv.children[0].offsetHeight);

        setImagesStyles(prev => ({
          ...prev,
          [imageIndex]:
          {
            ...prev[imageIndex],
            left: leftValue + 'px',
            top: topValue + 'px',
          },
        }))

        if (boxConfig.bbox) {
          // difference between initial value and current position
          let differenceLeft = leftValue - getZeroLeftOrTopWhenMagnified(wrapperDiv.children[0].offsetWidth);
          let differenceTop = topValue - getZeroLeftOrTopWhenMagnified(wrapperDiv.children[0].offsetHeight);

          let subtrahendPageHeights = 0;
          for (let i = 0; i < boxConfig.page - 1; i++) {
            let imageHeight = imagesRefs.current[boxConfig.page - 1].children[0].offsetHeight;
            if (magnifier) {
              imageHeight *= scaleFactor;
            }
            subtrahendPageHeights -= imageHeight;
          }

          setBboxStyle(prev => ({
            ...prev,
            left: constLeft + differenceLeft - offsetForArrowLeft + 'px',
            // i don't know where that 7 comes from, but it is needed to keep the arrow position consistent
            top: constTop + differenceTop + subtrahendPageHeights - offsetForArrowLeft + 7 + 'px',
          }))
        }

        return false;
      }

      // move div element
      wrapperDiv.onmousemove = dragDiv;
      return false;
    }
  };

  const handleDragEnd = () => {
    if (magnifier) {
      for (let key in imagesRefs.current) {
        if (imagesRefs.current[key].onmousemove) {
          imagesRefs.current[key].onmousemove = null
          break;
        }
      }
    }
  }

  return (
    <div ref={mostOutterElement}>
      <div className={classes.documentHeader}>
        <p className={classes.documentTitle}>{fileName}</p>
      </div>
      {preview.length !== 0 ?
        preview.map((page, index) =>
          <div className={classes.imageContainer} key={index} ref={element => imagesRefs.current[index] = element}>
            <img
              alt='document'
              style={imagesStyles[index]}
              className={`${classes.image} ${magnifier ? classes.cursorMove : ''}`}
              src={prefix + page}
              onMouseDown={dragHandle}
              onMouseUp={handleDragEnd}
              custom-key={index}
            />
            {(boxConfig.page - 1) === index && <div className={classes.bbox} style={bboxStyle} />}
            {magnifier && <hr className={classes.hr} />}
          </div>
        ) :
        fileName ? <Spinner addSpinnerClass={true} className={classes.customSpinnerStyles} /> : null
      }
    </div>
  );

}