import React, { useState, useEffect, useRef } from "react";
import Menu from './Menu'
import InnerHTML from "dangerously-set-html-content";
import { Col, Row, Card, Container } from "react-bootstrap";
import { Link } from 'react-router-dom'

const BorrowerResetPasswordPage = (props) => {


  let html = `<script id="zeitrotag" src=https://app.zeitro.us/api/zeitrotag.js?customerid=individualcustomers&loid=&pageid=resetpassword> </script>`;
  let host = window.location.host

  if (host == "agent.zeitro.com") {
    html = `<script id="zeitrotag" src=https://app.zeitro.com/api/zeitrotag.js?customerid=individualcustomers&loid=&pageid=resetpassword> </script>`
  }

  if (host.includes("localhost")) {
    html = `<script id="zeitrotag" src=http://localhost:3000/api/zeitrotag.js?customerid=individualcustomers&loid=&pageid=resetpassword> </script>`
  }
  

  return (
    <div>
      <Menu />
      <div className="mt-5">
        <Container>
          <Row className="text-center mb-1">
            <Col>
              <h1>Reset Password</h1>
            </Col>
          </Row>
        <InnerHTML html={html} />
        </Container>
      </div>
    </div>
  );
};

export default BorrowerResetPasswordPage;
