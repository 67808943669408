import React, { useState, useEffect, useRef } from "react";
import "./Properties";
import "./Properties.css";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ImageUploader from "./ImageUploader";
import NumericalInput, {CommaizedInput} from "../../NumericalInput";
import AddressAutocomplete from "../../Common/AddressAutocomplete";

import * as com from "../../Common.js";

const AddNewProperties = (props) => {
  const [validated, setValidated] = useState(false);
  const [showAddImagesModal, setShowAddImagesModal] = useState(false);
  const [address, setAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [listingPrice, setListingPrice] = useState("");
  const [sqft, setSqft] = useState("");
  const [numOfBeds, setNumOfBeds] = useState(1);
  const [numOfBaths, setNumOfBaths] = useState(1);
  const [propertyType, setPropertyType] = useState("");
  const [imageSRCs, setImageSRCs] = useState([]);
  const [toBeAdded, setToBeAdded] = useState([]);
  const [toRemoveIndex, setToRemoveIndex] = useState(-1);
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);

  const handleReset = () => {
    formRef.current.reset();
    setListingPrice("");
    setAddress("");
    setNumOfBaths(1);
    setNumOfBeds(1);
    setPropertyType("");
    setSqft("");
    setImageSRCs([]);
    setValidated(false);
  };

  const onChangeImageSRCs = (newImgs) => {
    console.log(newImgs)
    setImageSRCs(newImgs);
  };


  useEffect(() => {
    if (toRemoveIndex >= 0) {
      let tmp = [...toBeAdded];
      tmp.splice(toRemoveIndex, 1);
      setToBeAdded(tmp);
      setToRemoveIndex(-1);
    }
  }, [toRemoveIndex]);

  const getAddImagesModal = () => {
    return (
      <Modal
        show={showAddImagesModal}
        onHide={() => setShowAddImagesModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {address === ""
              ? "Add Photos for the Property"
              : "Add Photos for " + address}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUploader onChangeImageSRCs={onChangeImageSRCs} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowAddImagesModal(false)}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    let zip = com.getSubaddress(address, "postal_code", "long_name");

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    // let street = com.getSubaddress(address, "route", "long_name");
    let street = com.getSubaddress(address, "route", "short_name");
    let stradd = num + " " + street;
    // console.log(num, city, state, zip, county, longstate);
    // console.log(stradd);
    setAddress(stradd);
    setPropertyState(state);
    setCity(city);
    setCounty(county);
    setZipcode(zip);
  };

  const addProperty = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    let lst = [];
    lst.push({
      address: address,
      listingPrice: listingPrice,
      propertyType: propertyType,
      numOfBeds: numOfBeds,
      numOfBaths: numOfBaths,
      imageSRCs: imageSRCs.map((v) => v.base64),
      county: county,
      propertyState: propertyState,
      city: city,
      zipcode: zipcode,
      sqft: sqft,
    });

    handleReset();

    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/saveproperties", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(lst),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          alert("Looks like there was a problem. Please contact Zeitro for help.");
          return;
        }
        response.json().then((js) => {
          setLoading(false)
          if (js.Status !== "OK") {
            alert("Looks like there was a problem. Please contact Zeitro for help.");
          } else {
            alert("Your propeties have been saved!");
            window.location.reload();
          }
        });
      })
      .catch(function (err) {
        setLoading(false)
        console.log("Fetch Error /re/saveproperties:-S", err);
      });

  };

  const createToBeAddedTable = () => {
    const actionsFormatter = (cell, row, i) => (
      <div>
        <Button
          variant="remove"
          onClick={() => {
            setToRemoveIndex(i);
          }}
        >
          Remove
        </Button>
      </div>
    );
    const imageFormatter = (cell, row, i) => (
      <div>
        {row.imageSRCs.map((x, y) => (
          <img src={x} style={{ maxWidth: "30px", maxHeight: "30px" }} />
        ))}
      </div>
    );
    let headerStyle = {
      backgroundColor: "white",
      borderBottom: "solid 2px",
      borderTop: "solid 2px",
      fontSize: "120%",
    };
    let columns = [
      {
        dataField: "address",
        text: "Address",
        headerStyle: { ...headerStyle, width: "230px" },
      },
      {
        dataField: "propertyType",
        text: "propertyType",
        headerStyle: headerStyle,
      },
      {
        dataField: "sqft",
        text: "Sqft.",
        headerStyle: headerStyle,
      },
      {
        dataField: "numOfBeds",
        text: "Number of Beds",
        headerStyle: headerStyle,
      },
      {
        dataField: "numOfBaths",
        text: "Number of Baths",
        headerStyle: headerStyle,
      },
      {
        dataField: "listingPrice",
        text: "Listing Price",
        headerStyle: headerStyle,
      },
      {
        dataField: "imageSRCs",
        text: "Photos",
        formatter: imageFormatter,
        headerStyle: { ...headerStyle, width: "250px" },
      },
      {
        dataField: "Remove",
        text: "",
        isDummyField: true,
        formatter: actionsFormatter,
        headerStyle: headerStyle,
      },
    ];
    const saveProperties = () => {
      let token = sessionStorage.getItem("ZeitroA");

      fetch("/re/saveproperties", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body: JSON.stringify(toBeAdded),
      })
        .then((response) => {
          if (response.status !== 200) {
            alert("Looks like there was a problem. Please contact Zeitro for help.");
            return;
          }
          // Examine the text in the response
          response.json().then((js) => {
            if (js.Status !== "OK") {
              alert("Looks like there was a problem. Please contact Zeitro for help.");
            } else {
              setToBeAdded([]);
              alert("Your propeties have been saved!");
              // window.location.reload();
            }
          });
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
        });
    };
    return (
      <div className="mt-4">
        <ToolkitProvider
          bootstrap4
          keyField="email"
          data={toBeAdded}
          columns={columns}
          search
        >
          {(props) => (
            <div className="toBeAdded">
              <Row className="text-left mb-2">
                <h5 className="pt-2">Properties To Be Added</h5>
                <Button
                  className="ml-2 saveResourceBtn"
                  onClick={() => saveProperties()}
                >
                  Save
                </Button>
              </Row>
              <BootstrapTable
                id="resources"
                bootstrap4
                bordered={false}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                })}
                {...props.baseProps}
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  };

  return (
    <div>
      <h5 className="mt-4 mb-4 pl-5">Add Properties To Your Listing</h5>
      <Container>
        {/* <Row>
          Search resource's name, company, email: ## fuzzy search here
          <input className="ml-3"></input>
        </Row> */}
        <Form ref={formRef} validated={validated} onSubmit={addProperty}>
          <Row>
            <Col className="text-left" xs={4}>
              <AddressAutocomplete
                id="address"
                label="Address:"
                required={true}
                name="address"
                defaultValue={address}
                onChange={(e) => setAddress(e.target.value)}
                onCompletion={onStreetAddressCompletion}
                pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                placeholder="Street Address, No P.O.Box accepted"
                goodfeedback="Looks good!"
                badfeedback="Please provide address."
              />
            </Col>
            <Col className="text-left">
              <Form.Group>
                <Form.Label>Property Type:</Form.Label>
                <Form.Control
                  name="propertytype"
                  as="select"
                  size="sm"
                  type="text"
                  required
                  defaultValue={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                >
                  <option value="">...</option>
                  <option value="townhouse">
                    Townhouse
                  </option>
                  <option value="singlefamilyattached">
                    Single Family
                  </option>
                  <option value="condo">Condominium</option>
                  <option value="twotofourfamily">
                    2 to 4 Unit Multifamily
                  </option>
                  {/*} <option value="manufacturedhome">Manufactured Home</option> */}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="numOfBeds">
                <Form.Label className="text-left">Number of Beds</Form.Label>
                <Form.Control
                  required
                  name="numOfBeds"
                  size="sm"
                  as="select"
                  defaultValue={numOfBeds}
                  onChange={(e) => setNumOfBeds(parseInt(e.target.value))}
                >
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((x, y) => (
                    <option value={x}>{x}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid number of beds!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="numOfBaths">
                <Form.Label className="text-left">Number of Baths</Form.Label>
                <Form.Control
                  required
                  name="numOfBaths"
                  size="sm"
                  as="select"
                  defaultValue={numOfBaths}
                  onChange={(e) => setNumOfBaths(parseInt(e.target.value))}
                >
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((x, y) => (
                    <option value={x}>{x}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid number of baths!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="text-left">
              <Form.Label className="text-left">Sqrt:</Form.Label>
              <CommaizedInput
                  step={100}
                  size="sm"
                  value={sqft}
                  required
                  onChange={(e) => setSqft(e.target.value)}
                  min={0}
                />
            </Col>
            <Col className="text-left">
              <Form.Group controlId="minPrice">
                <Form.Label className="text-left">Listing Price:</Form.Label>
                <NumericalInput
                  step={10000}
                  size="sm"
                  value={listingPrice}
                  required
                  onChange={(e) => setListingPrice(e.target.value)}
                  min={0}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Button
                variant="outline-dark"
                className="addPropertiesImageButton"
                onClick={() => setShowAddImagesModal(true)}
              >
                Add images
              </Button>{" "}
              {imageSRCs.map((x, y) => (
                  <img
                    src={x.base64}
                    style={{ maxWidth: "30px", maxHeight: "30px", marginRight:"5px", marginTop:"25px" }}
                  />
                ))}
            </Col>
          </Row>
          <Row className="text-center">
          <Col className="text-center">
              <Button className="addPropertyButton" type="submit">
                {loading? <Spinner animation="border" size="sm"/> : "Confirm"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      {showAddImagesModal && getAddImagesModal()}
      {toBeAdded.length > 0 && createToBeAddedTable()}
    </div>
  );
};

export default AddNewProperties;
