import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Col, Row, Divider, Carousel, Drawer, Spin, Modal, Alert, Checkbox } from "antd";
import jwt_decode from "jwt-decode";
import AppleLogin from 'react-apple-login'
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './msalConfig';
import throttle from 'lodash.throttle';
import EmailVerification from "./Application/EmailVerification";
import "./LoginPage.css";
import cookie from 'react-cookies'
const contentStyle = {
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
export default function Login(props) {
  const [form] = Form.useForm()
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [loadingResend, setLoadingResend] = useState(false)
  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const [googleReady, setGoogleReady] = useState(false);
  const [appleEmail, setAppleEmail] = useState("")
  const [applePrivateEmail, setApplePrivateEmail] = useState("")
  const [showAddMoreInfo, setShowAddMoreInfo] = useState(false)
  const [remember, setRemember] = useState(false)
  const [status, setStatus] = useState(1) // 1 Continue 2 Sign in 3 Reset your password 4 success
  const formInitial = {}
  const onFinish = (val) => {
    if (status === 1) {
      checkLoginMethod()
    } else if (status === 2) {
      signin(val)
    } else if (status === 4) {
      setStatus(2)
    }
    
  };

  const handleClose = () => {
    setShow(false)
  };
  const appleLogin = (res) => {
    if (res && !res.error) {
      setLoading(true)
      fetch("/agent/applesignin", {
        method: "POST",
        body: JSON.stringify({
          code: res.authorization.code, 
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            alert(
              "Looks like there was a problem. Please contact contact@zeitro.com for help."
            );
            setLoading(false)
            return;
          }
          response.json().then((js) => {
            if (js.Status !== "OK") {
              console.log("Error: " + js.Text)
              if (js.Text.includes("invalid email address")) {
                setMsg('Sorry, but we were unable to find a valid email address associated with this Apple ID. Please try using a different Apple ID or alternative methods.')
              } else {
                setMsg('Sorry, but we are unable to sign you in using your Apple account at this time. Please consider using alternative sign-in methods.')
              }
              setShow(true)
              sessionStorage.removeItem("ZeitroA")
              sessionStorage.removeItem("state")
              setLoading(false)
            } else {
              if (js.Text === "Account authenticated" || 
              (!js.AppleStatus.IsPrivateEmail && js.AppleStatus.IsNameIncluded)) {

                sessionStorage.setItem("ZeitroA", js.Token)
                sessionStorage.setItem("state", js.State)
                sessionStorage.setItem("originalstate", js.State)
                sessionStorage.setItem(
                  "roles",
                  JSON.parse(atob(js.Token.split(".")[1])).roles
                  );
                  
                  window.location.href = "/app/welcome"
                  setLoading(false)
              } else {
                setApplePrivateEmail(js.AppleStatus.Email)
                if (!js.AppleStatus.IsPrivateEmail) {
                  setAppleEmail(js.AppleStatus.Email)
                }
                setShowAddMoreInfo(true)
                setLoading(false)
              }
            }
          })
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err)
        })
    }
  }
  const msLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
  
      // Check if the login response is valid and contains the account
      if (loginResponse && loginResponse.account) {
        // Proceed with any necessary actions after successful sign-in
        console.log('Successfully signed in:', loginResponse.account, loginResponse.account.name, loginResponse.account.username);

        setLoading(true)
        fetch("/agent/microsoftsignin", {
          method: "POST",
          body: JSON.stringify({
            name: loginResponse.account.name,
            email:loginResponse.account.username,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              alert(
                "Looks like there was a problem. Please contact contact@zeitro.com for help."
              );
              setLoading(false)
              return;
            }
            response.json().then((js) => {
              if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
                setMsg('Sorry, but we are unable to sign you in using your Microsoft account at this time. Please consider using alternative sign-in methods.')
                setShow(true)
                sessionStorage.removeItem("ZeitroA")
                sessionStorage.removeItem("state")
                setLoading(false)
              } else {
                sessionStorage.setItem("ZeitroA", js.Token)
                sessionStorage.setItem("state", js.State)
                sessionStorage.setItem("originalstate", js.State)
                sessionStorage.setItem(
                  "roles",
                  JSON.parse(atob(js.Token.split(".")[1])).roles
                );
    
                window.location.href = "/app/welcome"
                setLoading(false)
              }
            })
          })
          .catch(function (err) {
            console.log("Fetch Error :-S", err)
          })
        

      }
    } catch (error) {
      console.error('Sign-in error:', error);
    }
  };
  
  const handleSwitchToCode = () => {
    setShowEmailVerification(true)
  }

  const checkLoginMethod = () => {
    let customerid = "individualcustomers";
    let tosend = JSON.stringify({
      username: form.getFieldValue('email').toLowerCase(),
      customerid,
    });
    setLoading(true)
    fetch("/agent/checkloginmethod", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status !== 200) {
            alert("Looks like there was a problem. Please contact contact@zeitro.com for help.")
            setLoading(false)
            return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            setMsg(js.Text)
            setShow(true)
            setLoading(false)
          } else {
            if (js.Text === "email") {
              setShowEmailVerification(true)
            } else {
              setShowEmailVerification(false)
              setStatus(2)
            }
            setLoading(false)
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };
  const signin = (val) => {
    let customerid = "individualcustomers";
    let tosend = JSON.stringify({
      username: val.email.toLowerCase(),
      password: val.password,
      captcha:"",
      customerid,
    });
    setLoading(true)
    fetch("/agent/signin", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status !== 200) {
            alert("Looks like there was a problem. Please contact contact@zeitro.com for help.")
            setLoading(false)
            return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            setMsg(js.Text)
            setShow(true)
            sessionStorage.removeItem("ZeitroA");
            sessionStorage.removeItem("state");
            setLoading(false)
          } else {
            if (remember) {
              let inFifteenMinutes = new Date(new Date().getTime() + 72 * 3600 * 1000)
              cookie.save("remember", {username: val.email.toLowerCase(), password: val.password}, { expires: inFifteenMinutes })
            } else {
              cookie.remove("remember");
            }
            sessionStorage.setItem("ZeitroA", js.Token);
            // now seed the local state!
            //console.log(" state: " + js.State)

            sessionStorage.setItem("state", js.State);
            sessionStorage.setItem("originalstate", js.State);
            sessionStorage.setItem("roles", JSON.parse(atob(js.Token.split('.')[1])).roles)

            window.location.href = "/app/welcome";
            setLoading(false)
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };
  const renderGoogleBtn = () => {
    try {
      if (google && google.accounts && google.accounts.id) {
        google.accounts.id.renderButton(
          document.getElementById("googleSignin"),
          {
            type: "icon",
            theme: "outline", 
            size:"large",
            shape: "circle",
            width: document.getElementById("googleSigninWrap")?.offsetWidth,
            text: "signup_with",
          }
        )
        setGoogleReady(true)
      }      
    } catch (error) {
      
    }

  }
  useEffect(() => {
    if (cookie.load("remember")) {
      let cache = cookie.load("remember")
      setRemember(true)
      form.setFieldsValue({
          email: cache.username,
          password: cache.password
      })
    }
    /* global google */
    window.onload =  () => {
      google.accounts.id.initialize({
        client_id:"265683149831-g5e9goi4rtabm4p6rv11igkvnan4rfns.apps.googleusercontent.com",
        callback: handleGoogleCallback,
      })
      renderGoogleBtn()
    }
    const handleResize = throttle((event) => {
      renderGoogleBtn()
    }, 200);
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    if (!showEmailVerification) {
      renderGoogleBtn()
    }
    if (status === 1) {
      renderGoogleBtn()
    }
  }, [showEmailVerification, status])
  const handleGoogleCallback = (response) => {
    let userObject = jwt_decode(response.credential);

    setLoading(true)
    fetch("/agent/googlesignin", {
      method: "POST",
      body: JSON.stringify(userObject),
      headers: {
        "Content-Type": "application/json",
        "X-GOOGLETOKEN": response.credential,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Please contact contact@zeitro.com for help."
          );
          setLoading(false)
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
            setMsg('Sorry, but we are unable to sign you in using your Google account at this time. Please consider using alternative sign-in methods.')
            setShow(true)
            sessionStorage.removeItem("ZeitroA")
            sessionStorage.removeItem("state")
            setLoading(false)
          } else {
            sessionStorage.setItem("ZeitroA", js.Token)
            sessionStorage.setItem("state", js.State)
            sessionStorage.setItem("originalstate", js.State)
            sessionStorage.setItem(
              "roles",
              JSON.parse(atob(js.Token.split(".")[1])).roles
            );

            window.location.href = "/app/welcome"
            setLoading(false)
          }
        })
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err)
      })
  }
  const resendValidationEmail = () => {
    setLoadingResend(true)
    fetch("/agent/sendvalidationemail/" + form.getFieldValue('email'), {
      method: "GET",
      headers: { Cache: "no-cache"},
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoadingResend(false)
          alert(
            "Looks like there was a problem. Please contact contact@zeitro.com for help."
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            setLoadingResend(false)
            alert(
              "Looks like there was a problem. Please contact Zeitro for help."
            );
            return;
          } else {
            setLoadingResend(false)
            setShow(false)
            alert("The validation email has been sent to "  + this.state.username + ", please check your email inbox.")
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  }

  const getErrorMessage = () => {
    if (msg.includes("no rows")) {
      return "The email address has not been registered yet. Please proceed with signing up."
    }  
    if (msg.includes("Invalid credentials")) {
      return "Incorrect password."
    }
    return msg
  }

  const back = (type) => {
    setShowEmailVerification(false)
    setStatus(type)
  }

  const sendMoreInfo = (val) => {
    setLoading(true)
    let tosend = JSON.stringify({
      Email: val.email.toLowerCase(),
      PrivateEmail: applePrivateEmail,
      FirstName: val.firstname,
      LastName: val.lastname,
    });
    fetch("/agent/signupwithmoreinfo", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact contact@zeitro.com for help."
        );
        setLoading(false)
        return;
      }
      response.json().then((js) => {
        if (js.Status !== "OK") {
          console.log("Error: " + js.Text)
          setMsg('Sorry, but we are unable to sign you in using your Apple account at this time. Please consider using alternative sign-in methods.')
          setShow(true)
          sessionStorage.removeItem("ZeitroA")
          sessionStorage.removeItem("state")
          setLoading(false)
        } else {
          sessionStorage.setItem("ZeitroA", js.Token)
          sessionStorage.setItem("state", js.State)
          sessionStorage.setItem("originalstate", js.State)
          sessionStorage.setItem(
            "roles",
            JSON.parse(atob(js.Token.split(".")[1])).roles
          );

          window.location.href = "/app/welcome"
          setLoading(false)
          setShowAddMoreInfo(false)
        }
      })
    })
    .catch(function (err) {
      console.log("Fetch Error :-S", err)
    })
  };
  const pageHeader = () => {
    if (status === 1) {
      return (
        <>
          <div className="login-title">Welcome back</div>
            <div className="login-subtitle mt-2 title-5">Log in to continue</div>
            {!googleReady && <Row className="waitingGoogleSignin"  style={{justifyContent: 'center', gap: 34}}><Spin tip="Loading" size="small">
                  <div />
                </Spin></Row>}
              <Spin spinning={loading}>
                <Row id="googleSigninWrap" style={{justifyContent: 'center', gap: '34px'}}>
                    
                      <div id="googleSignin"></div>
                      {googleReady && <>
                        <Button
                      size="large"
                      shape="circle"
                      className="other-login sign-outlook"
                      onClick={msLogin}
                      icon={
                        <img
                          src="/images/outlook.svg"
                          style={{ width: "18px", height: "18px" }}
                        />
                      }
                    >
                    </Button>
                    <AppleLogin
                      clientId={"com.zeitro.signinservice"} 
                      redirectURI={"https://agent.zeitro.com"}   
                      usePopup={true}
                      callback={(res) => appleLogin(res)}
                      scope="email name"
                      responseMode="query"
                      render={(props) => (
                          <Button
                            size="large"
                            shape="circle"
                            className="other-login sign-apple"
                            onClick={props.onClick}
                            icon={
                              <img
                                src="/images/apple.svg"
                                style={{ width: "18px", height: "18px" }}
                              />
                            }
                          >
                          </Button>
                      )}
                    />
                      </>}
                </Row>
              </Spin> 
              <Divider plain>or login with</Divider>        
        </>
      )
    } else {
      return (
        <div style={{marginBottom: 24}}>
          <img src="/images/password-check.png" style={{ width: 69, height: 69, marginBottom: 11 }} />
          <div className="login-title">{status === 2 ? 'Enter your password' : status === 3 ? 'Reset your password' : 'You are all set!'}</div>
          {status === 4 && <div className="login-title-tips">Your password has been successfully reset,click the following to continue your access.</div>}
        </div>
      )
    }
  }
  const onChangeRemember= (e) => {
    setRemember(e.target.checked)
  }
  return (
    <>
      <div className="sign-up-box">
        <div className="create-account">
          <div className="sign-up-logo">
            <img src="/images/zeitrologo.png" style={{width:70, height: 'auto'}}/>
          </div>
          <div className="mobile-nav">
              <img className="mobile-logo" src="/images/mobile-logo.svg" />
          </div>
          {props.fromsignup && <Alert className="login-alert" message="Your account has been validated!" type="info" />}
          {showEmailVerification ? <div className="create-centerBox">
            <EmailVerification 
              showBack 
              fromSignIn
              email={form.getFieldValue('email')} 
              back={(type) => back(type)}/>
              </div> :  <div className="create-centerBox">
              {pageHeader()}
              <Form
                className="create-account-form"
                layout="vertical"
                form={form}
                initialValues={formInitial}
                onFinish={onFinish}
                
                size="large"
                >
                {
                  [1,2,3].includes(status) && (
                    <Row>
                        <Col span={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            style={{marginBottom: status !== 1 ? 0 : 20}}
                            rules={[
                            {
                                required: true,
                                type: "email",
                                message: 'Please enter a valid email address.',
                            },
                            ]}
                        >
                            
                            {status === 1 && <Input placeholder="Your email" />}
                            {(status === 2 || status === 3) && <div className="enterPwd-email"><span>{form.getFieldValue('email')}</span>{status === 2 && <span onClick={() => setStatus(1)}>Edit</span>}</div>}
                        </Form.Item>
                        </Col>
                    </Row>
                  )
                }
                {status === 2 && <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Password"
                        name="password"
                        style={{marginBottom: 6}}
                        rules={[
                          {
                            required: true,
                            message: 'Enter your password!',
                          },
                        ]}
                      >
                        <Input.Password placeholder="Your password" />
                      </Form.Item>
                    </Col>
                </Row>}
                {
                  status === 2 && (
                    <Row className="login-operate">
                      <Checkbox checked={remember} onChange={onChangeRemember}><span className="remember-pwd">Remember me</span></Checkbox>
                      <span className="forget-pwd" onClick={()=>window.location.href = "/forgot-password"}>Forgot password?</span>
                    </Row>
                  )
                }
                <Spin spinning={loading}>
                  <Button
                      htmlType="submit"
                      className="create-btn sign-zeitro"
                      block
                  >
                      {status === 1 ? "Continue" : "Sign in"}
                  </Button>
                </Spin>
            </Form>
          
            <div className="bottom-link">
              {status === 1 && <div>Don’t have an account?<span onClick={()=>window.location.href = "/re/signup"}>Sign up</span></div>}
              {status === 2 && <div>or<span onClick={handleSwitchToCode}>Sign in with one-time code</span></div>}
            </div>
          </div>}

              <div className="create-copyright">© {new Date().getFullYear()} Zeitro. All rights reserved.</div>

        </div>
      </div>
      <Modal
        open={show}
        title="Authentication Failed"
        cancelText="Close"
        onCancel={handleClose}
        footer={(_, { CancelBtn }) => (
          <>
            {msg.includes("validated") && <Button loading={loadingResend} type="primary" onClick={()=>{resendValidationEmail()}}>Resend Validation Email</Button>}
            <CancelBtn />
          </>
        )}
      >
        <div>{getErrorMessage()}</div>
        
      </Modal>
      <Modal
        open={showAddMoreInfo}
        title="You're one step away from completing your registration!"
        footer={null}
        closeIcon={false}
      >
        <Form
          name="basic"
          layout="vertical"
          initialValues={{
            email: appleEmail,
          }}
          onFinish={(e)=>sendMoreInfo(e)}
          autoComplete="off"
          className="mt-4"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="First Name"
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Please input your first name.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              {
                required: true,
                message: 'Please input your last name.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
}
