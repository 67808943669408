import React, { useEffect, useState, useReducer, useRef } from "react";

import "./RateQuote.css";

import {commaizeFloat } from "../../Common";

import { Table,Tabs, Switch, Popover, Button, Modal, Tooltip, Input, Select} from 'antd';
import { DownOutlined, UpOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Lenders from "../../Management/Lenders";

const {TabPane}=Tabs;

const typemap = {
  fixed30: "Fixed 30",
  fixed25: "Fixed 25",
  fixed20: "Fixed 20",
  fixed15: "Fixed 15",
  fixed10: "Fixed 10",
  arm56: "ARM 5/6",
  arm76: "ARM 7/6",
  arm106: "ARM 10/6",
}
const keymap ={
  fixed30: 1,
  fixed25: 2,
  fixed20: 3,
  fixed15: 4,
  fixed10: 5,
  arm56: 6,
  arm76: 7,
  arm106: 8,
}

const skipKeywordsMap={
  "Agency": ["conforming","highbalance", "high-balance","high_balance","super_conforming","superconforming","super-conforming","agency"],
  "Non-agency": ["fha", "va", "usda", "jumbo","nonconforming","non-conforming","non_conforming","nonqm","non-qm","non_qm","non-agency","non_agency","nonagency"],
  "FHA": "fha",
  "VA": "va",
  "USDA": "usda",
  "Non-QM": ["nonqm", "non-qm","non_qm"],
}

export function AllRatesTable(props) {
  const [results,setResults] = useState(0);
  const [emptyTargetRate,setEmptyTargetRate] = useState(false)
  const [emptyTargetPrice, setEmptyTargetPrice] = useState(false)
  const [allresults,setAllResults] = useState(0);
  const [connectedresults,setConnectedResults] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState("fixed30");
  const [currentTarget,setCurrentTarget] = useState('price');
  const [targetprice,setTargetPrice] = useState(3000);
  const [targetrate,setTargetRate] = useState(6.5);
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);
  const [showConnectedLendersOnly, setShowConnectedLendersOnly] = useState(false);
  const [displayALLForLO, setDisplayALLForLO] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showShareRateModal, setShowShareRatesModal] = useState(false);
  
  const { TextArea } = Input;
  let AllresultsCount={}
  let ConnectedresultsCount={}
  useEffect(() => {
    setAllResults(AllresultsCount[activeTabKey]);
    setConnectedResults(ConnectedresultsCount[activeTabKey])
  }, [activeTabKey,props.stdproducts])

  const handleTabChange=(key)=>{
    setActiveTabKey(key);
  };
 const maincolumns = [
    {
      title: 'Program',
      dataIndex: 'program',
      key: 'program',
      width:"200px"
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width:"80px",
      render:(_,record)=>(
        <strong style={{color:currentTarget==='rate'?"#1677FF":''}}>{record.rate}%</strong>
      ),
      sorter:(a, b) => a.rate - b.rate,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: 'Lender price',
      dataIndex: 'originalprice',
      key: 'originalprice',
      width:"100px",
      render:(_,record)=>(
        <div style={{display:"flex",alignItems:"center"}}>
          <strong style={{color:currentTarget==="price" && record.isConnected ?"#1677FF":''}}>${commaizeFloat(record.originalprice)}</strong>
          <Popover content={record.popoverContent} title='Details'>
            <img src="/images/questionMark.png" style={{marginLeft:"5px",width:"12px",height:"12px"}}/>
          </Popover>
        </div>
        ),
      defaultSortOrder: 'ascend',
      sorter:(a, b) => a.originalprice - b.originalprice,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: 'Comp.',
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"80px",
      render:(_,record)=>(
        <strong style={{marginLeft:"5px"}}>${record.isConnected?commaizeFloat(record.compensationprice):"----"}</strong>
      ),
      sorter:(a, b) => a.compensationprice - b.compensationprice,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: 'Monthly pay',
      dataIndex: 'monthlypay',
      key: 'monthlypay',
      width:"100px",
      render:(_,record)=>(
        <strong>${commaizeFloat(record.monthlypay)}</strong>
      ),
      sorter:(a, b) => a.monthlypay - b.monthlypay,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: 'Final DTI',
      dataIndex: 'dti',
      key: 'dti',
      width:"80px",
      render:(_,record)=>(
        <strong>{record.dti}%</strong>
      ),
      sorter:(a, b) => a.dti - b.dti,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: '',
      key: 'share',
      align: "center",
      width:"30px",
      render: (_, record) => (
        !record.isConnected ? <div><img src="/images/priceengine/addLendersIcon.png" onClick={()=>setShowAddLendersModal(true)} style={{cursor: "pointer", width:32}} /></div> : ""
      ),
    },
    Table.EXPAND_COLUMN,
  ];
  
  const innerColumns=[
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width:"80px",
      render:(_,record)=>(
        <strong style={{color:record.isMin===true?'#1677FF':''}}>{record.rate}%</strong>
      ),
    },
    {
      title: 'Lender price',
      dataIndex: 'originalprice',
      key: 'originalprice',
      width:"100px",
      render:(_,record)=>(
        <div style={{display:"flex",alignItems:"center"}}>
          <strong style={{color:record.isMin===true?'#1677FF':''}}>${commaizeFloat(record.originalprice)}</strong>
          <Popover content={record.popoverContent} title='Details'>
          <img src="/images/questionMark.png" style={{marginLeft:"5px",width:"12px",height:"12px"}}/>
          </Popover>
        </div>
      )
    },
    {
      title: 'Comp.',
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"80px",
      render:(_,record)=>(
        <strong style={{color:record.isMin===true?'#1677FF':''}}>${commaizeFloat(record.compensationprice)}</strong>
      ),
    },
    {
      title: 'MonthlyPay',
      dataIndex: 'monthlypay',
      key: 'monthlypay',
      width:"100px",
      render:(_,record)=>(
        <strong style={{color:record.isMin===true?'#1677FF':''}}>${record.monthlypay}</strong>
      ),
    },
    {
      title: 'Final DTI',
      dataIndex: 'dti',
      key: 'dti',
      width:"80px",
      render:(_,record)=>(
        <strong style={{color:record.isMin===true?'#1677FF':''}}>{record.dti}%</strong>
      ),
    },
  ]

  let mtypes = [];
  let lenderSet = {}
  let lenderRates={}
  let lenderPrices={}
  
  for (let mtype in props.allRates) {
    for (let d of props.allRates[mtype]){
      let tmpname= d.Lender+' - '+d.Name
      if (typeof(lenderRates[tmpname]) === "undefined"){
        lenderRates[tmpname]=[]
        lenderPrices[tmpname]=[]
     
      }
      lenderRates[tmpname].push(d.base_rate);
      lenderPrices[tmpname].push(d.lender_credit-(d.prices.CompensationPoint * d.loanAmount/100));
    }
    mtypes.push(mtype)
    if (typeof lenderSet[mtype] === "undefined") {
      lenderSet[mtype] = new Set()
    }
    
    for (let d of props.allRates[mtype]) {
      lenderSet[mtype].add(d.Lender + "-" + d.Name);
    }
  }

  for (let m in lenderSet) {
    lenderSet[m] = Array.from(lenderSet[m]).sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
  })
  }

  // if (mtypes.length === 0) {
  //   return <p>Please wait for the calculation to be finished.</p>;
  // }

  const checkMin=(pname,target)=>{
    let minval=Math.min(...lenderRates[pname].rate)
    return target === minval;
  }

  const compareTarget=(target,v,targettype,pname)=>{
    if(targettype === "rate"){
      let differences=lenderRates[pname].map(element=>Math.abs(element-target));
      let minval=Math.min(...differences);
      let minidx = differences.indexOf(minval);
      return v === lenderRates[pname][minidx]
    }
    if(targettype === "price"){
      let differences=lenderPrices[pname].map(element=>Math.abs(element-target));
      let minval=Math.min(...differences);
      let minidx = differences.indexOf(minval);
      return v === lenderPrices[pname][minidx];
    }
  }

  const generateTableData = (ratesData, mtype,targettype) => {
    let tmpOuttableonRate = [];
    let tmpOuttableonPrice = [];
    let tmpIntable={}
    let idx=1;
    for (let d of ratesData){
      let mindiff=false
      let tmpname= d.Lender+' - '+d.Name
      let isConnected = (props.connectedLendersMap[d.Lender] && props.connectedLendersMap[d.Lender] === true )? true : false
      if(skipkeywords.includes(d.Mtype.toLowerCase())) {
        continue
      }
      if (typeof(tmpIntable[tmpname]) === "undefined"){
        tmpIntable[tmpname]=[]
      }
      let adjs=[]
      for (let adj in d.Adjustments){
        adjs.push(
          <li>{adj}: {parseFloat(d.Adjustments[adj]).toFixed(3)}</li>
        )
      }
      let Content=(
        <div>
          <ul>
            <li>
              Loan Amount: {''} ${commaizeFloat(d.loanAmount)}
            </li>
            <li>
              APR: {''} {parseFloat(d.APR).toFixed(3)}
            </li>
            <li>
              AUS: {''} {d.Aus}
            </li>
            <li>
              LockDay:{''} {d.lockDay}
            </li>
            <li>
              Estimated Closing Costs: {''} ${commaizeFloat(d.closing_cost)}
            </li>
          </ul>
          <hr/>
          <strong>Points Calculation</strong>
          <ul>
            <li>
              Base_rate:{' '} {parseFloat(d.prices.BaseRate).toFixed(3)}
            </li>
            <li>
              LockDayPoint:{' '} {parseFloat(d.prices.LockDayPoint).toFixed(3)}
            </li>
            <li>
              AdjustmentPoint:{' '} {parseFloat(d.prices.AdjustmentPoint).toFixed(3)}
            </li>
            <ul>
              {adjs}
            </ul>
            <li>
              CompensationPoint:{' '} {parseFloat(d.prices.CompensationPoint).toFixed(3)}
            </li>
            <li>
              CloseOverPoint:{' '} {parseFloat(d.prices.CloseOverPoint).toFixed(3)}
            </li>
          </ul>
        </div>
      )
      let originalprice = d.lender_credit-(d.prices.CompensationPoint * d.loanAmount/100)
      if(targettype==="rate" && compareTarget(targetrate,d.base_rate,targettype,tmpname)){
        tmpOuttableonRate.push({
          key:idx, 
          isConnected: isConnected,
          program:tmpname,
          rate: d.base_rate,
          price: parseFloat(d.lender_credit).toFixed(0),
          originalprice: parseFloat(originalprice).toFixed(0),
          compensationprice: parseFloat(d.prices.CompensationPoint * d.loanAmount/100).toFixed(0),
          monthlypay: d.monthly_payment,
          dti: d.DTI,
          popoverContent:Content
        })
        idx+=1;
        mindiff=true;
      }
      if(targettype==="price" && compareTarget(targetprice,originalprice,targettype,tmpname)){
        tmpOuttableonPrice.push({
          key:idx, 
          isConnected: isConnected,
          program:tmpname,
          rate: d.base_rate,
          price: parseFloat(d.lender_credit).toFixed(0),
          originalprice: parseFloat(originalprice).toFixed(0),
          compensationprice: parseFloat(d.prices.CompensationPoint * d.loanAmount/100).toFixed(0),
          monthlypay: d.monthly_payment,
          dti: d.DTI,
          popoverContent:Content
        })
        idx+=1;
        mindiff=true;
      }
      tmpIntable[tmpname].push({  
        rate: d.base_rate,
        price: parseFloat(d.lender_credit).toFixed(0),
        originalprice: parseFloat(originalprice).toFixed(0),
        compensationprice: parseFloat(d.prices.CompensationPoint * d.loanAmount/100).toFixed(0),
        monthlypay:parseFloat(d.monthly_payment).toFixed(0),
        dti: d.DTI,
        isMin:mindiff,
        popoverContent:Content
      })
      
    }
    if (targettype==="rate"){
      return [tmpOuttableonRate,tmpIntable];
    }
    if (targettype==="price"){
      return [tmpOuttableonPrice,tmpIntable];
    }
  }
  const handleTargetSwitch=(checked)=>{
    if(checked===true){
      setCurrentTarget("rate")
    }
    if(checked===false){
      setCurrentTarget("price")
    }
  }

  const updateTargetPrice = (e) => {
    let val = e.target.value
    if (val==="") {
      setTargetPrice(0)
      setEmptyTargetPrice(true)
    } else {
      setTargetPrice(parseInt(val))
      setEmptyTargetPrice(false)
    }
  }

  const updateTargetRate = (e) => {
    let val = e.target.value
    if (val==="") {
      setTargetRate(0);
      setEmptyTargetRate(true)
    } else {
      setTargetRate(parseFloat(val));
      setEmptyTargetRate(false)
    }
  }
 
  let all={}
  let outdata={}
  const tabdata=[];
  let idx=1;
  const uncheckedProducts=[];
  for(let p in props.stdproducts){
    if(props.stdproducts[p].status=="unchecked"){
      uncheckedProducts.push(props.stdproducts[p].name)
    }
  }
  let skipkeywords=[]
  uncheckedProducts.map((key)=>{
    skipkeywords= skipkeywords.concat(skipKeywordsMap[key])
  })
  for(let mtype in props.allRates){
    [outdata[mtype],all[mtype]]=generateTableData(props.allRates[mtype],mtype,currentTarget);
    AllresultsCount[mtype]=outdata[mtype].length
    ConnectedresultsCount[mtype]=outdata[mtype].filter(d => d.isConnected).length

    tabdata.push({
      tabTitle:typemap[mtype],
      intabledata: all[mtype],
      outtabledata:outdata[mtype],
      tabkey:mtype
    })
 
    idx+=1
  }

  
  return (
    <div className="text-left w-100" style={{height:props.viewportH-140}}>
      <div className={props.isMobile?"text-left":""} style={{display:"flex", flexDirection:props.isMobile?"column":"row", justifyContent:"space-between",height:props.isMobile?"":"50px",fontSize:"20px",fontWeight:"400",lineHeight:"20px",color:"#6E6E70",marginLeft:props.isMobile?"":"10px",alignItems:props.isMobile?"left":"center"}}> 
      <div style={{paddingTop:"10px", marginBottom:props.isMobile?"10px":""}}>{showConnectedLendersOnly?connectedresults:allresults} Results </div>
        <div className="rateQuoterSwitch" style={{display:"flex",alignItems:"center"}}>
        <Select
            defaultValue="price"
            style={{
              width: 120,
              marginRight: 4
            }}
            onChange={(e)=>setCurrentTarget(e)}
            options={[
              {
                value: 'price',
                label: 'Target Price',
              },
              {
                value: 'rate',
                label: 'Target Rate',
              },
            ]}
          />
          <div>
                {currentTarget == "rate"?(
                  <div  style={{backgroundColor:"white",display:"flex",height:"32px",width:"120px",border:"1px solid #ECECEC",borderRadius:"0 6px 6px 0",justifyContent:"space-between",paddingLeft:'5px',paddingRight:"5px"}}>
                    <input  style={{width:"80px",border:"none", marginLeft:"5px"}} type="number" value={emptyTargetRate?"":targetrate} onChange={updateTargetRate}/>
                    <div style={{marginTop:"4px",marginRight:"5px", fontSize:14}}>%</div>
                  </div>
              ):(
                  <div style={{backgroundColor:"white",height:"32px",width:"120px",display:"flex",border:"1px solid #ECECEC", alignItems:"center",borderRadius:"0 6px 6px 0",justifyContent:"space-between",paddingRight:'5px'}} >
                    <div style={{borderRight:"1px solid #ECECEC", padding:"5px", fontSize:14}}>$</div>
                    <input  style={{width:"80px",border:"none",fontSize:"14px",marginLeft:"5px"}} type="number" value={emptyTargetPrice?"":targetprice} onChange={updateTargetPrice}/>
                    {/* <CommaizedInput className="targetpriceInput" style={{border:"none",width:"60px" }} value={targetprice} onChange={updateTargetPrice} /> */}
                  </div>
                )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end  align-items-center my-2" >
        {selectedRows.length >0 && <Button type="primary" className="mr-2" onClick={()=>setShowShareRatesModal(true)}>Share</Button>}
        <div className="d-flex justify-content-end  align-items-center" >Display my lenders only<Switch className="ml-2 mr-4" onChange={(e)=>setShowConnectedLendersOnly(e)}/></div>
        {/* {checkAccess([UR_Owner]) && <div className="d-flex justify-content-end  align-items-center" >Only show added lenders to my LO<Switch className="ml-2" onChange={(e)=>setDisplayALLForLO(e)}/></div>} */}
      </div>
      {/* <Tabs  activeKey={activeTabKey} onChange={handleTabChange} items={tabsdata} /> */}
      <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        {tabdata.map((tab) => (
          <TabPane tab={tab.tabTitle} key={tab.tabkey} >
            <Table
                  style={{width:"1200px"}}
                  className="outertable"
                  pagination={false}
                  columns={maincolumns}
                  scroll={{
                    y: props.viewportH-390
                  }}
                  rowClassName={(record,_)=> record.isConnected ? "connectedLenderRow":"unconnectedLenderRow"}
                  showSorterTooltip={false}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div>
                        {!record.isConnected && <div className="notAddedLenderLabel"><Tooltip className="d-flex align-items-center" title="This wholesale lender is not currently added to your list of lenders. To connect with this lender, click the + button."> Not added yet <QuestionCircleOutlined style={{color:"#fffff", marginLeft:5}} />  </Tooltip></div>}
                        <Table 
                          className="innertable"
                          pagination={false}
                          showHeader={false}
                          columns={innerColumns}
                          dataSource={tab.intabledata[record.program]}
                        />
                      </div>
                    ),
                    expandIcon:({expanded,onExpand, record})=>{
                      return(
                        expanded? (
                            <img
                              src="/images/priceengine/lendersUpIcon.png"
                              style={{cursor: "pointer", width:32}} 
                              onClick={(e)=>{
                                onExpand(record,e)
                              }}
                            />
                        ):(
                          <img
                          src="/images/priceengine/lendersDownIcon.png"
                            style={{cursor: "pointer", width:32}} 
                              onClick={(e)=>{
                                onExpand(record,e)
                              }}
                            />
                        )
                      )
                    }
                  }}
                  dataSource={showConnectedLendersOnly ? tab.outtabledata.filter(d => d.isConnected) : tab.outtabledata}
          />
          </TabPane>
        ))}
    </Tabs>

    <Modal
      open={showAddLenderModal}
      onCancel={()=>setShowAddLendersModal(false)}
      centered
      footer={[
        <Button key="back" onClick={()=>setShowAddLendersModal(false)}>
          Cancel
        </Button>,
      ]}
      width={1000}
    >
      <Lenders />
    </Modal>
    <Modal
      title="Share Rates"
      open={showShareRateModal}
      onCancel={()=>setShowShareRatesModal(false)}
      centered
      footer={[
        <Button type="primary" onClick={()=>setShowShareRatesModal(false)}>
          Share
        </Button>,
      ]}
    >
      <div className="p-3">
        <div className="title-5">Borrower Email</div>
        <Input placeholder="email@example.com"/>

        <div className="title-5">Selected rates</div>
        {/* {displaySelectedRates(selectedRows)} */}
        
        <div className="title-5">Additonal information (optional)</div>
        <TextArea rows={4} placeholder="Enter your infomation..." maxLength={6} />


      </div>
    
    </Modal>
     
    </div>
  );
}



