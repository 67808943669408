import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Utils.css';
import './CreditReport.css'
import {FilterOutlined, DownOutlined, SlidersOutlined} from '@ant-design/icons';
import { Row, Col, Modal, Button, Form, InputGroup } from 'react-bootstrap'
import { Alert, Spin } from 'antd';
import Dropdown from 'react-bootstrap/Dropdown'
import NumericalInput from './NumericalInput'
import AddressAutocomplete from './Common/AddressAutocomplete'
import 'bootstrap/dist/css/bootstrap.min.css';
import * as com from "./Common"
import * as act from "./Store/actions"
import { getCurrentState } from './Store'
import Spinner from './CommonUIComponents/Spinner'
import { tooltip } from "../src/Common/Tooltip";
import * as st from "./State"
import { connect } from 'react-redux'
import _ from 'lodash'
import { Empty, Tag, Card, Switch } from 'antd';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        borrower: state.borrower,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    copyBorrowerLoan: (p, who) => {
        dispatch(act.CopyBorrowerLoan(p, who))
    },
    changeBorrowerLoanCorrection: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanCorrection(event.target.value, i, who))
    },
    changeBorrowerLoanSatisfiedUpon: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanSatisfiedUpon(event.target.value, i, who))
    },
    changeBorrowerLoanExplanation: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanExplanation(event.target.value, i, who))
    },
    changeBorrowerLoanForSubject: (val, i, who) => {
        dispatch(act.ChangeBorrowerLoanForSubject(val, i, who))
    },
    changeBorrowerLoanMonthly: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanMonthly(event.target.value, i, who))
    },
    updateFico: (score) => {
        dispatch(act.UpdateFico(score))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    updateCRErrors: (ar) => {
        dispatch(act.UpdateCRErrors(ar))
    },
    changeBorrowerInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    updateApplicationAttribute: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.value, verb))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },
    changeBorrowerLoanRate: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanRate(event.target.value, i, who))
    },
});
class CreditReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            renderer: <Spinner addSpinnerClass={true} style={{ top: '30vh' }} />,
            dtiRenderer: null,
            loadingcr: false,
            loadingpullcredit: false,
            show: {},
            expand: [],
            loans: [],
            categories: {},
            personswithscoresandresidencies: [],
            dtiSpreadsheetLoading: false,
            isFetchUSCButtonDisabled: true,
            DTISpreadsheet: null,
            creditLoanTypes: [],
            status: [],
            selectedTags: [],
            exludeZeroMonthlyPay: false,
            isMobile: window.innerWidth < 576
        }
        this.inteval = undefined
        this.updateMain = this.props.application.assetsandliabilities.loans != null && this.props.application.assetsandliabilities.loans.length === 0
        this.downloadDTIspreadsheetRef = React.createRef();
    }


    componentDidMount() {
        if (this.props.application.assetsandliabilities.loans == null) {
            this.setState({ loadingcr: true, renderer: <div className="mt-5"><Empty description={
                <div>
                  Credit has not been pull yet
                </div>
              }/></div> })
            return
        }
        this.reload()
        this.inteval = setInterval(this.saveCRAdditionsLiabilities, 30 * 1000)
    }

    componentWillUnmount = () => {
        this.saveCRAdditionsLiabilities()
        clearInterval(this.inteval)
    }

    saveCRAdditionsLiabilities = () => {
        let token = sessionStorage.getItem("ZeitroA")
        if (this.state.loans && this.state.loans.length === 0)
            return;
        let Loan_id = this.props.borrower.loan_id
        let Id = this.props.borrower.id
        let Loans = this.state.loans
        let body = {
            Id,
            Loan_id,
            Loans: JSON.stringify(Loans)
        }

        fetch('/agent/updatecradditions/', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            if (!response.ok) {
                console.log("Auth fetch error")
                sessionStorage.removeItem("ZeitroA")
            } else {
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("State Update Error: " + js.Status)
                    } else {
                        //console.log("State Update Success: " + js.Status)
                    }
                })
            }
        }).catch(error => {
            console.log("error")
        })
    }

    fillPerson = who => {
        let a = this.props.application[who]

        return {
            firstname: a.firstname, middlename: a.middlename, lastname: a.lastname,
            suffix: a.suffix, email: a.email,
            socialsecurity: a.socialsecurity,
            dateofbirth: a.dateofbirth
        }
    }
    fillAddress = who => {
        let a = this.props.application[who]

        return {
            street: a.presentaddressstreet, city: a.presentaddresscity, state: a.presentaddressstate,
            zip: a.presentaddresszip, years: a.presentaddressyears,
            months: a.presentaddressmonths, ownership: a.presentaddressownership
        }
    }
    fillPreviouswAddress = (index, who) => {
        let a = this.props.application[who].previousaddresses[index]

        return {
            street: a.street, city: a.city, state: a.state,
            zip: a.zip, years: a.years,
            months: a.months, ownership: a.ownership
        }
    }
    fillApplicant = who => {
        let addresses = [this.fillAddress(who)]
        let a = this.props.application[who].previousaddresses

        for (let i = 0; i < a.length; i++) {
            addresses.push(this.fillPreviouswAddress(i, who))
        }
        return { person: this.fillPerson(who), addresses: addresses }
    }
    fillRequestBody = (force) => {
        let borrowers = [this.fillApplicant("borrower")]
        // Sergei, p[ease check this
        if (this.props.application.hascoborrower === "withcoborrower" ||
            (this.props.application.hascoborrower === "multiple" && this.props.application.married)
        ) {
            borrowers.push(this.fillApplicant("coborrower"))
        }
        return {
            force_ucs: force,
            output: "json",
            app_data: borrowers,
            request: "credit_report"
        }
    }

    reconcileSSN = (js, borrowers) => {
        let dashize = ssn => {
            return ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, 9)
        }
        // for (let i = 0; i < borrowers.length; i++) {
        //     let outperson = js.personswithresidencies[i].person
        //     let inperson = borrowers[i].person
        //     let inssn = inperson.socialsecurity.replace(/-/g, '')

        //     let outssn = dashize(outperson.socialsecurity)

        //     if (outperson.socialsecurity !== inssn) {

        //         if (window.confirm("Attention!\nCredit report returned a different SSN for " + outperson.firstname + " " + outperson.lastname + ": " + outssn +
        //             "\nYou entered: " + borrowers[i].person.socialsecurity + "\n\nWould you like to use the value from credit report (recommended)? ")) {

        //             this.props.changeBorrowerInfo(outssn, (i === 0) ? com.borrower : com.coborrower, "socialsecurity")
        //         }
        //     }
        // }
    }

    updateApplicationAttribute = (value) => {
        this.props.updateApplicationAttribute({ target: { value } }, "loanbalance")
    }

    fetchCRJson = (forse) => {
        this.setState({
            loadingcr: true,
            renderer: <Spinner addSpinnerClass={true} style={{ top: '30vh' }} />,
            personswithscoresandresidencies: [],
            categories: {},
            loans: [],
            creditLoanTypes: [],
            status: [],
            selectedTags: [],
        }, () => {
            let token = sessionStorage.getItem("ZeitroA")
            let { id } = this.props.borrower
            let body = this.fillRequestBody(forse)
            let borrowers = body.app_data

            fetch("/agent/getcreditreport/json/" + id, {
                cache: 'no-cache',
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-ForceUCS": '' + forse
                },
            }).then(response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then(respJSON => {
                    let json = respJSON.Additions
                    if (json.personswithscoresandresidencies) {
                        this.setState({
                            personswithscoresandresidencies: json.personswithscoresandresidencies,
                            renderer: (
                                json.personswithscoresandresidencies.map(person =>
                                    <div key={person.person.socialsecurity} className='credit-report-lo'>
                                        <h2>{person.person.firstname} {person.person.middlename} {person.person.lastname}</h2>
                                        <Row>
                                            {person.scores.equifax &&
                                                <Col>
                                                    <h3>Equifax</h3>
                                                    <div className='credit-report-lo-scores'>
                                                        <p style={{ fontSize: '17px' }}><b>Score: {person.scores.equifax.score}</b></p>
                                                        {/* <p>Max: {person.scores.equifax.max}</p>
                                                        <p>Min: {person.scores.equifax.min}</p> */}
                                                    </div>
                                                    <ul>
                                                        {person.scores.equifax.factors.length !== 0 && person.scores.equifax.factors.map((factor, index) =>
                                                            <li key={index}>{factor}</li>
                                                        )}
                                                    </ul>
                                                </Col>
                                            }
                                            {person.scores.experian &&
                                                <Col>
                                                    <h3>Experian</h3>
                                                    <div className='credit-report-lo-scores'>
                                                        <p style={{ fontSize: '17px' }}><b>Score: {person.scores.experian.score}</b></p>
                                                        {/* <p>Max: {person.scores.experian.max}</p>
                                                        <p>Min: {person.scores.experian.min}</p> */}
                                                    </div>
                                                    <ul>
                                                        {person.scores.experian.factors.length !== 0 && person.scores.experian.factors.map((factor, index) =>
                                                            <li key={index}>{factor}</li>
                                                        )}
                                                    </ul>
                                                </Col>
                                            }
                                            {person.scores.transunion &&
                                                <Col>
                                                    <h3>Transunion</h3>
                                                    <div className='credit-report-lo-scores'>
                                                        <p style={{ fontSize: '17px' }}><b>Score: {person.scores.transunion.score}</b></p>
                                                        {/* <p>Max: {person.scores.transunion.max}</p>
                                                        <p>Min: {person.scores.transunion.min}</p> */}
                                                    </div>
                                                    {person.scores.transunion.factors.length !== 0 && <div className="text-left">
                                                            <div style={{fontWeight: 500}}>Factors Influencing Credit Score:</div>
                                                            {person.scores.transunion.factors.map((factor, index) =>
                                                            <li key={index}>{com.capitalize(factor)}</li>
                                                        )}
                                                        </div>}
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                )),
                            loadingcr: false
                        })
                    } else {
                        this.setState({ renderer: null })
                    }
                    try {
                        let js = json
                        const creditLoanTypes = js.liabilities.loans.map(loan => loan.creditloantype ).filter(t=>t!==null);
                        const status = js.liabilities.loans.map(loan => loan.creditliabilityaccountstatustype);
                        this.setState({ categories: js.liabilities.categories, 
                                loans: js.liabilities.loans,
                                creditLoanTypes: [...new Set(creditLoanTypes)],
                                status: [...new Set(status)],
                                selectedTags: [...new Set([...creditLoanTypes, ...status])]})

                    } catch (xx) {
                        console.log(json)
                    }
                    if (this.updateMain) {
                        try {
                            let js = respJSON.Main
                            this.props.clearBorrowerLoans(null, this.props.who)
                            this.props.updateFico(js.creditscore)
                            this.reconcileSSN(js, borrowers)
                            let remap = o => {
                                let l = { ...o }
                                let islease = l.creditliabilityaccounttype === "AutoLease" || l.creditliabilityaccounttype === "Lease"
                                let isinstallment = l.creditliabilityaccounttype === "Installment"

                                if (l.creditloantype.toLowerCase().indexOf("education") >= 0) {
                                    if (l.monthlypayment === "")
                                        l.monthlypayment = 0
                                } else {
                                    if ((islease || isinstallment) && (l.monthlypayment === 0 || l.monthlypayment === "")) {
                                        l.monthlydefinedbycustomer = true
                                    }
                                }
                                return l
                            }

                            for (let i = 0; i < js.liabilities.length; i++) {
                                this.props.copyBorrowerLoan(remap(js.liabilities[i]), this.props.who)
                            }
                            this.props.addBorrowerAdditionalPropertiesFromCredit(null)
                            getCurrentState()
                        } catch (xx) {
                            console.log(json)
                        }
                    }
                    this.setState({ loadingcr: false })
                })
            }).catch(function (err) {
                console.log('Fetch Error :-S', err);
                this.setState({ loadingcr: false })
            });
        })
    }

    generateDTISpreadsheet = () => {
        this.setState({ dtiSpreadsheetLoading: true }, () => {
            let token = sessionStorage.getItem("ZeitroA")
            fetch("/agent/generatedtispreadsheet", {
                cache: 'no-cache',
                method: 'POST',
                body: JSON.stringify({ CustomerID: this.props.borrower.id }),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                },
            }).then(response => {
                if (response.status !== 200) {
                    this.setState({ dtiSpreadsheetLoading: false })

                    try {

                        response.text().then(js => {
                            console.log(js)
                        })
                        return;
                    } catch {
                        alert('Something went wrong, please contact engineers')
                        return;
                    }
                }
                response.json().then((js) => {
                    if (js.RedirectURL !== "Something is wrong") {
                        this.setState({ DTISpreadsheet: js.SpreadsheetB64 }, () => this.downloadDTIspreadsheetRef.current.click());
                    } else {
                        alert("Something is wrong, please reach engineers for help")
                    }
                    this.setState({ dtiSpreadsheetLoading: false })
                })
            })
        })
    }

    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        this["state"].loadingcr = true
        this.setState({ loadingcr: true })
        let { id, loan_id } = this.props.borrower

        this.fetchCRJson(false)

    }

    pullcredit = () => {
        this.props.changeReviewedLoans(0)
        this.setState({ loadingpullcredit: true })
        let token = window.sessionStorage.getItem("ZeitroA");
        let { id, loan_id } = this.props.borrower
        let body = this.fillRequestBody()
        let borrowers = body.app_data
        fetch(window.location.origin + "/borrower/creditreport/" + id, {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {

            response.text().then(text => {
                try {

                    let js = JSON.parse(text)
                    this.props.clearBorrowerLoans(null, this.props.who)
                    this.props.updateFico(js.creditscore)

                    if (js.creditscore === "")
                        console.log(js)

                    // this.reconcileSSN(js, borrowers)
                    // this.reconcileDOBs(js, borrowers)
                    let remap = o => {
                        let l = { ...o }

                        //l.correction = "none"
                        //l.attribution = attr

                        let isstudent = l.creditliabilityaccounttype === "Educational"
                        let islease = l.creditliabilityaccounttype === "AutoLease" || l.creditliabilityaccounttype === "Lease"
                        let isinstallment = l.creditliabilityaccounttype === "Installment"

                        if (l.creditloantype.toLowerCase().indexOf("education") >= 0) {
                            if (l.monthlypayment === "")
                                l.monthlypayment = 0
                        } else {
                            if ((islease || isinstallment) && (l.monthlypayment === 0 || l.monthlypayment === "")) {
                                l.monthlydefinedbycustomer = true
                            }
                        }
                        return l
                    }
                    let opened = js.liabilities
                    let dupresolved = null
                    let expand = []
                    let steps = -1
                    let substeps = 0
                    let stepsandsubsteps = []
                    for (let i = 0; i < opened.length; i++) {
                        let remapped = remap(opened[i])
                        if (opened[i].remainingbalance === "" || parseInt(opened[i].remainingbalance) === 0)
                           continue
                        this.props.copyBorrowerLoan(remapped, this.props.who)

                        expand.push(false)
                        let st
                        if (remapped.correction === "duplicate") {
                            substeps += 1
                            st = { steps, substeps, dupresolved }
                        } else {
                            steps += 1
                            substeps = 0
                            st = { steps, substeps, dupresolved }
                        }
                        stepsandsubsteps.push(st)
                    }
                    this.props.updateCRErrors(null)
                    this.props.addBorrowerAdditionalPropertiesFromCredit(null)
                    getCurrentState()

                    this.setState({ loadingpullcredit: false, expand, stepsandsubsteps })
                    if (typeof js.errorMessages !== "undefined") {
                        this.props.updateCRErrors(js.errorMessages)
                    }

                } catch (xx) {
                    console.log(text)
                    console.log(xx)
                    this.setState({ loadingpullcredit: false })
                }
            })

        }).catch(error => {

            this.setState({ loadingpullcredit: false })

        })

    }


    renderByCategories = (category) => {
        let loans
        if (this.state.categories[category]) {
            loans = this.state.categories[category].map(id => {
                let result = this.props.application.assetsandliabilities.loans.findIndex(l => l.key === id)
                if (result !== -1) {
                    return { loan: this.props.application.assetsandliabilities.loans[result], index: result, main: true }
                }
                result = this.state.loans.findIndex(l => l.key === id)
                if (result !== -1) {
                    return { loan: this.state.loans[result], index: result, main: false }
                }
                return null
            })
            loans = loans.filter(l => l != null && l.creditloantype !== null)

            if (loans.length !== 0)
                return (<>
                    <div className="title-3">{category}</div >
                    {loans.map((v, i) => this.process(v.loan, v.index, v.main))}
                </>)
        }
        return null
    }

    handleLoanTypeTagChange = (tag, checked) => {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked
          ? [...selectedTags, tag]
          : selectedTags.filter((t) => t !== tag);
        this.setState({ selectedTags: nextSelectedTags });
      };
      
    handleStatusTagChange = (tag, checked) => {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t !== tag);
    this.setState({ selectedTags: nextSelectedTags });
    };

    filterLoans = () => {
    const { loans, selectedTags, exludeZeroMonthlyPay } = this.state;
    return loans.filter((loan) => {
        if (exludeZeroMonthlyPay) {
            if (loan.monthlypayment === "" || loan.monthlypayment === "0") {
                return false
            }
        }
        if (selectedTags.length === 0) return false;
        return selectedTags.includes(loan.creditliabilityaccountstatustype) && selectedTags.includes(loan.creditloantype);
    });
    };

    renderLoans = () => {
        const filteredLoans = this.filterLoans();
        const { selectedTags } = this.state;
        const renderLoan = (loan, index) => {
            let items = [
                {
                    label: "Name",
                    children: loan.name,
                },
                {
                    label: "Remaining Balance",
                    children: "$"+com.commaize(loan.remainingbalance),
                },
                {
                    label: "Monthly Payment",
                    children: "$"+com.commaize(loan.monthlypayment),
                },
                {
                    label: "Months left to pay",
                    children: loan.monthslefttopay === "" ? 0 : loan.monthslefttopay,
                },
                {
                    label: "Opened date",
                    children: loan.openeddate,
                },
            ]
            return <div ><Card key={index} size="sm" className="creditReportCard"
            title={com.splitByCapitalLetter(loan.creditloantype) + " | " + loan.creditliabilityaccountstatustype}
            style={{width: "100%", marginBottom: 15 }}>
            <div className={this.state.isMobile?"d-block":"d-flex justify-content-between"}>
                {items.map(i => (
                    <div>
                        <div>{i.label}</div>
                        <div style={{fontWeight: 500}}>{i.children}</div>
                    </div>
                ))}
            </div>
            </Card>
            </div>
            
        }
        const customToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
              href=""
              ref={ref}
              onClick={(e) => {
                e.preventDefault();
                onClick(e);
              }}
              className="ratesDropdown "
            >
                <div style={{color:"black", alignItems:"center", display:"flex"}} ><SlidersOutlined style={{fontSize: 18, marginRight:7}}/>{children}</div>
                <DownOutlined style={{color:"black", fontSize: 20}}  />
            </a>
          ));
        return (
            <div>
                <Dropdown onSelect={this.selectsort} size="sm" className="dropdownsort mb-3">
                    <Dropdown.Toggle as={customToggle} id="dropdownsort" size="sm" >
                        Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu size="sm" className='filterDropdown'>
                        <div className="p-3">
                            {this.state.creditLoanTypes.length > 0 && <div>
                                <div style={{fontWeight:500, fontSize: 14}}>Credit loan type</div>
                                {this.state.creditLoanTypes.map((tag) => (
                                        <Tag.CheckableTag
                                            className="creditRportTag"
                                            key={tag}
                                            checked={selectedTags.indexOf(tag) > -1}
                                            onChange={(checked) => this.handleLoanTypeTagChange(tag, checked)}
                                        >
                                            {com.splitByCapitalLetter(tag)}
                                        </Tag.CheckableTag>
                                    ))}
                                </div>}
                                {this.state.status.length > 0 && <div className="mt-3">
                                <div style={{fontWeight:500, fontSize: 14}}>Account status</div>
                                {this.state.status.map((tag) => (
                                        <Tag.CheckableTag
                                            className="creditRportTag"
                                            key={tag}
                                            checked={selectedTags.indexOf(tag) > -1}
                                            onChange={(checked) => this.handleStatusTagChange(tag, checked)}
                                        >
                                            {com.splitByCapitalLetter(tag)}
                                        </Tag.CheckableTag>
                                    ))}
                                </div>}
                                <div className="mt-3 monthlyPaymentSwitch">
                                    <div style={{fontWeight:500, fontSize: 14}}>Exclude 0 monthly payment records</div>
                                    <Switch checked={this.state.exludeZeroMonthlyPay} onChange={()=>this.setState({exludeZeroMonthlyPay: !this.state.exludeZeroMonthlyPay})} />
                                </div>
                        </div>
                    
                    </Dropdown.Menu>
                </Dropdown>
              <div>
                {filteredLoans.map((loan, index) => renderLoan(loan))}
              </div>
            </div>
          );
    }

    process = (val, index, isMain) => {
        if (null === val || val.creditloantype===null)
            return ""
        let name = val.name;
        let address = val.address;
        let accountnumber = val.accountnumber;
        let monthlypayment = val.monthlypayment;
        let remainingbalance = val.remainingbalance;
        let satisfiedupon = val.satisfiedupon;
        let monthslefttopay = val.monthslefttopay;
        let key = val.key
        
        return this.renderLoan(index, name, address, accountnumber,
            monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, true, isMain)
    }

    getFlag = (correction) => {
        if (correction === "paidbybusiness")
            return <i className="fas fa-user-tie ml-2 darkgreen"></i>

        if (correction === "alreadyclosed")
            return <i className="fa fa-times ml-2 darkgreen"></i>
        if (correction === "wrongperson")
            return <i className="far fa-hand-paper ml-2 darkred"></i>
        if (correction === "other")
            return <i className="far fa-flag ml-2 darkred"></i>
        if (correction === "duplicate")
            return <i className="far fa-clone ml-2 darkred"></i>
        return ""
    }


    renderLoan = (index, name, address, accountnumber, monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, margin, isMain) => {
        index = parseInt(index)

        let placeInStore = isMain ? this.props.application.assetsandliabilities.loans[index] : this.state.loans[index]


        let getBalancePayment = () => {
            let totalmortgage = 0
            let mortgagebalance = 0
            if (null !== this.props.application.assetsandliabilities.loans) {
                let len = this.props.application.assetsandliabilities.loans.length
                for (let index = 0; index < len; index++) {
                    if (placeInStore.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
                        placeInStore.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                        placeInStore.creditloantype.toLowerCase().indexOf("realestate") >= 0
                    ) {
                        if (placeInStore.forsubjectproperty
                            && placeInStore.satisfiedupon === "withproceeds") {
                            totalmortgage += parseInt(placeInStore.monthlypayment)
                            mortgagebalance += parseInt(placeInStore.remainingbalance)
                        }

                        if (placeInStore.forprimaryresidence) {
                            totalmortgage += parseInt(placeInStore.monthlypayment)
                            mortgagebalance += parseInt(placeInStore.remainingbalance)
                        }
                    }
                }
            }

            if (mortgagebalance !== parseInt(this.props.application.loanbalance))
                this.updateApplicationAttribute(mortgagebalance.toString())
            return [totalmortgage, mortgagebalance]
        }

        let isHELOC = () => {
            let loan = placeInStore
            return loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0
        }
        let handleClose = e => {
            let newshow = { ...this.state.show }
            newshow[index] = false
            this.setState({ show: newshow })
        }
        let showCorrect = e => {
            let newshow = { ...this.state.show }
            newshow[index] = true
            this.setState({ show: newshow })
        }
        let changeBorrowerLoanSatisfiedUpon = (event) => {
            com.touch()
            let val = event.target.value
            if (event.persist)
                event.persist()
            if (isMain) {
                this.props.changeBorrowerLoanSatisfiedUpon(event, index, this.props.who)
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.satisfiedupon = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let changeExplanation = e => {
            com.touch()
            let val = e.target.value
            if (e.persist)
                e.persist()
            if (isMain) {
                this.props.changeBorrowerLoanExplanation(e, index, this.props.who)
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.explanation = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let correctionChecked = e => {
            com.touch()
            let val = e.target.value
            if (e.persist)
                e.persist()
            if (isMain) {
                this.props.changeBorrowerLoanCorrection(e, index, this.props.who)
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.correction = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let changeBorrowerLoanForSubject = e => {
            com.touch()
            if (e.persist)
                e.persist()
            let val = e.target.value === "true"
            if (isMain) {
                this.props.changeBorrowerLoanForSubject(val, index, this.props.who)
                getBalancePayment()
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.forsubjectproperty = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let changeBorrowerLoanForPrimary = e => {
            com.touch()
            if (e.persist)
                e.persist()
            let val = e.target.value === "true"
            if (isMain) {
                this.props.changeBorrowerLoan(val, index, "forprimaryresidence")
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.forprimaryresidence = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let displayOwnership = () => {
            switch (placeInStore.attribution) {
                case 1:
                    return `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`
                case 2:
                    return `${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}`
                case 3:
                    return "Joint"
                default:
                    return "Unknown " + placeInStore.attribution
            }
        }
        let onMonthlyChange = (e) => {
            com.touch()
            let val = e.target.value
            if (e.persist)
                e.persist()
            if (isMain) {
                this.props.changeBorrowerLoanMonthly(e, index, this.props.who)
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.monthlypayment = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let displayMonthly = () => {

            let liability = placeInStore
            let userinput = liability.monthlydefinedbycustomer

            if (userinput) {
                return (
                    <Form.Group className="text-left pr-3">
                        <NumericalInput
                            isValid={false}
                            size="sm"
                            id={"loan" + key}
                            required
                            type="number"
                            name={"loan" + key}
                            defaultValue={liability.monthlypayment}
                            onChange={onMonthlyChange}

                            min={1}
                        />
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Enter the monthly payment
                        </Form.Control.Feedback>
                    </Form.Group>
                )
            }
            return "$" + com.commaize(placeInStore.monthlypayment)
        }
        let askHowToPay = () => {
            if (parseInt(placeInStore.remainingbalance) > 0) {
                if (placeInStore.name === "AMEX") {
                    if ('not' === placeInStore.satisfiedupon) {

                        changeBorrowerLoanSatisfiedUpon({ target: { value: 'withownfunds' } })
                    }
                    return (
                        <div>
                            <div className="sectionquestion"><i className="fas fa-angle-right"></i>Will you pay the balance before this transaction?
                            </div>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mb-0">
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withproceeds" checked={placeInStore.satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + key} name={"satisfied" + key}></input>
                                            <label id={"satisfiedlabel" + key} className="zeitro-radio divlink" htmlFor={"satisfied" + key}>No, I won't</label>
                                        </div>
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={placeInStore.satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + key} name={"satisfied" + key}></input>
                                            <label id={"satisfieddownlabel" + key} className="zeitro-radio divlink" htmlFor={"satisfiedown" + key}>Yes, I will</label>
                                        </div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please make your choice.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    )
                }
                let canPayoffWithLoanProceeds = () => {
                    if (this.props.application.property.purpose === st.POL_Cashoutrefinance)
                        return true
                    if (this.props.application.property.purpose === st.POL_Purchase)
                        return false
                    // refinance left
                    if (!isHELOC() && placeInStore.forsubjectproperty === true)
                        return true
                    if (isHELOC() && placeInStore.partoforiginalpurchase)
                        return true
                    return false
                }
                let paidOff = () => {

                    return tooltip(<i className="text-nowrap" style={{ fontWeight: 'normal', fontStyle: 'normal' }}><i className="fas fa-angle-right"></i>Will this balance be paid off at or before this transaction</i>,
                        <div>
                            This question should be answered having several things in mind:
                            <ul>
                                <li>
                                    Are you refinancing this loan? Answer "Yes, I'm refinancing this loan"
                                </li>
                                <li>
                                    Are you paying the loan back? For example, you're selling your previous residence. Answer: "Yes, I'm it paying it off myself
                                    ".
                                </li><li>
                                    Closing some credit card balances can also be helpful in lowering your debt payments, and improving your odds of getting qualified for a new loan.
                                </li>
                                <li>
                                    Answer No, if you're keeping the loan as is
                                </li>
                            </ul>
                        </div>, "auto", "", "")
                }
                if (!showBalancePaidOff())
                    return ""
                return (
                    <div>
                        <div className="sectionquestion d-inline-block">{paidOff()}</div>
                        <Form.Group>
                            <fieldset>
                                <div className="d-flex mb-0">
                                    {canPayoffWithLoanProceeds() ?
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withproceeds" checked={placeInStore.satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + key} name={"satisfied" + key}></input>
                                            <label id={"satisfiedlabel" + key} className="zeitro-radio divlink" htmlFor={"satisfied" + key}>Yes, I'm refinancing this loan</label>
                                        </div> : ""
                                    }
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={placeInStore.satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + key} name={"satisfied" + key}></input>
                                        <label id={"satisfiedownlabel" + key} className="zeitro-radio zeitro-radio divlink" htmlFor={"satisfiedown" + key}>Yes, I'm paying it off myself</label>
                                    </div>
                                    <div>
                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={placeInStore.satisfiedupon === "not"} type="radio" id={"not" + key} name={"satisfied" + key}></input>
                                        <label id={"notlabel" + key} className="zeitro-radio ivlink" htmlFor={"not" + key}>No, it won't</label>
                                    </div>
                                </div>
                            </fieldset>
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please make your choice.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                )
            }
            return ""
        }

        let changePropertyAddress = event => {
            com.touch()
            let val = event.target.value
            if (isMain) {
                this.props.changeBorrowerLoan(val, index, "propertyaddress")
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.propertyaddress = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }

        let changeBorrowerLoanForMI = e => {
            let val = e.target.value === "true"
            if (isMain) {
                this.props.changeBorrowerLoan(val, index, "includesmi")
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.includesmi = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let isMortgage = () => {
            return placeInStore.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
                placeInStore.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                placeInStore.creditloantype.toLowerCase().indexOf("realestate") >= 0
        }
        let correction = placeInStore.correction
        let crossed = ""

        if (["wrongperson", "alreadyclosed", "other", "duplicate"].includes(correction))
            crossed = " crisscrossed"
        if (correction === "paidbybusiness" || correction === "alreadyclosed")
            crossed = " greencrisscrossed"
        let mt5 = " mt-4"
        if (index === 0)
            mt5 = ""
        let onRateChange = (e) => {
            com.touch()
            if (e.persist)
                e.persist()
            let val = e.target.value
            if (isMain) {
                this.props.changeBorrowerLoanRate(e, index, this.props.who)
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.rate = val
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        if (placeInStore.forsubjectproperty === true) {
            if ("" === placeInStore.rate) {

                let rate = com.getYearlyRate(
                    parseInt(placeInStore.remainingbalance),
                    parseInt(placeInStore.monthslefttopay),
                    parseInt(placeInStore.monthlypayment))
                onRateChange({ target: { value: rate.toFixed(2) } })
            }
        }
        let changePartOfOriginal = e => {
            let r = e.target.value === "true"
            if (e.persist)
                e.persist()
            if (isMain) {
                this.props.changeBorrowerLoan(r, index, "partoforiginalpurchase")
            } else {
                this.setState(prevState => {
                    let tmp = prevState.loans.map((l, i) => {
                        if (index === i)
                            l.partoforiginalpurchase = r
                        return _.cloneDeep(l)
                    })
                    return { loans: tmp }
                })
            }
        }
        let showAddress = () => {
            if (!isMortgage())
                return false

            if (this.props.application.property.purpose !== st.POL_Purchase && false === placeInStore.forsubjectproperty)
                return true

            if (this.props.application.property.purpose === st.POL_Purchase && false === placeInStore.forprimaryresidence)
                return true

            return false
        }
        let showMortgageInsurance = () => {
            if (!isMortgage())
                return false
            if (placeInStore.forsubjectproperty == null &&
                placeInStore.forprimaryresidence == null)
                return false
            return true
        }
        let showBalancePaidOff = () => {
            if (isMortgage()) {
                if (placeInStore.forsubjectproperty == null &&
                    placeInStore.forprimaryresidence == null)
                    return false
                if (placeInStore.includesmi == null)
                    return false
                return true
            }
            return true
        }
        let style = {}
        if (placeInStore.forsubjectproperty === true && crossed === "") {
            style = {
                backgroundImage: 'url("/housemoney.png")',
                position: 'relative',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom 0px right 50px',
                backgroundSize: '14em',

            }

        }
        let theRest = () => {
            let ital = "ml-3 font-italic font-weight-light"
            switch (placeInStore.correction) {
                case "duplicate":
                    return <div className={ital}>
                        This entry is a duplicate, and won't be counted against your debt
                    </div>
                case "wrongperson":
                    return <div className={ital}>
                        This entry does not belong to you, and won't be counted against your debt
                    </div>
                case "alreadyclosed":
                    return <div className={ital}>
                        This loan is already closed, and won't be counted against your debt
                    </div>
                case "paidbybusiness":
                    return <div className={ital}>
                        This loan is paid by business, and won't be counted against your debt
                    </div>
                case "other":
                    return <div className={ital}>
                        This loan won't be counted against your debt
                    </div>
                default:
                    break
            }


            if (crossed !== "")
                return ""


            return (
                <div>
                    {(this.props.application.property.purpose !== st.POL_Purchase && isMortgage())
                        ?
                        <Row className="">
                            <Col>
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Is this a loan for the property you're refinancing?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanForSubject} value="true" checked={true === placeInStore.forsubjectproperty} type="radio" id={"forsubject" + key} name={"forsubject" + key}></input>
                                        <label id={"forsubjectlabel" + key} className="zeitro-radio divlink" htmlFor={"forsubject" + key}>Yes, it is</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanForSubject} value="false" checked={false === placeInStore.forsubjectproperty} type="radio" id={"forsubjectnot" + key} name={"forsubject" + key}></input>
                                        <label id={"forsubjectnotlabel" + key} className="zeitro-radio divlink" htmlFor={"forsubjectnot" + key}>No, it isn't</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        : ""}
                    {(this.props.application.property.purpose === st.POL_Purchase && isMortgage())
                        ? <div>
                            <Row className="">
                                <Col>
                                    <div className="sectionquestion"><i className="fas fa-angle-right"></i>Is this a loan for the primary residence?</div>
                                    <div className="d-flex mb-0">

                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanForPrimary} value="true" checked={true === placeInStore.forprimaryresidence} type="radio" id={"forprimary" + key} name={"forsforprimaryubject" + key}></input>
                                            <label id={"forprimarylabel" + key} className="zeitro-radio divlink" htmlFor={"forprimary" + key}>Yes, it is</label>
                                        </div>
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanForPrimary} value="false" checked={false === placeInStore.forprimaryresidence} type="radio" id={"forprimarynot" + key} name={"forsforprimaryubject" + key}></input>
                                            <label id={"forprimarynotlabel" + key} className="zeitro-radio divlink" htmlFor={"forprimarynot" + key}>No, it isn't</label>
                                        </div>

                                    </div>

                                </Col>
                            </Row>

                        </div>
                        : ""}
                    {placeInStore.forsubjectproperty === true &&
                        !isHELOC()
                        ?

                        <Form.Group controlId={"liability" + key} className="text-left d-flex text-nowrap  m-0  p-0 pb-2 " >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '1.1em' }} className="text-left pt-1 pl-3" > &gt;Your current rate? :</Form.Label>
                            <fieldset>
                                <InputGroup style={{ width: '8em' }} >
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        className="ml-1"
                                        required
                                        name={"liability" + key}
                                        type="text"
                                        defaultValue={placeInStore.rate}
                                        onChange={onRateChange}
                                        style={{ width: '4em' }}
                                        pattern="^[0-9]+\.[0-9]+$"
                                    />
                                    <InputGroup.Append  >
                                        <InputGroup.Text style={{ fontSize: '1.1em' }} >%
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <div>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide description.
                                    </Form.Control.Feedback>
                                </div>
                            </fieldset>
                        </Form.Group>

                        : <div className="">{showAddress() ?
                            <Form.Group className="text-left  text-nowrap  m-0  px-3  " >
                                <AddressAutocomplete isValid={false} size="sm"
                                    required
                                    label="Provide Property Address:"
                                    id={"propertyaddress" + key}
                                    type="text"
                                    defaultValue={placeInStore.propertyaddress}
                                    onChange={changePropertyAddress}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    goodfeedback="Looks good!"
                                    badfeedback="Please provide property raddress."
                                />

                            </Form.Group> : " "}</div>

                    }
                    {placeInStore.forsubjectproperty === true && isHELOC() &&
                        this.props.application.property.purpose === st.POL_Refinance ?
                        <Row className="">
                            <Col >
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Was this HELOC a part of the original purchase?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="true" checked={true === placeInStore.partoforiginalpurchase} type="radio" id={"partoforiginalpurchase" + key} name={"partoforiginalpurchase" + key}></input>
                                        <label id={"partoforiginalpurchaselabel" + key} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchase" + key}>Yes, it was</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="false" checked={false === placeInStore.partoforiginalpurchase} type="radio" id={"partoforiginalpurchasenot" + key} name={"partoforiginalpurchase" + key}></input>
                                        <label id={"partoforiginalpurchasenotlabel" + key} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchasenot" + key}>No, it wasn't</label>
                                    </div>

                                </div>

                            </Col>

                        </Row>
                        : ""}

                    {showMortgageInsurance() ?
                        <Row className="">
                            <Col>
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Does the payment include mortgage insurance?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3">
                                        <input required onChange={changeBorrowerLoanForMI} value="true" checked={true === placeInStore.includesmi} type="radio" id={"includesmi" + key} name={"includesmi" + key}></input>
                                        <label id={"includesmi" + key} className="zeitro-radio divlink" htmlFor={"includesmi" + key}>Yes, it does</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanForMI} value="false" checked={false === placeInStore.includesmi} type="radio" id={"includesminot" + key} name={"includesmi" + key}></input>
                                        <label id={"includesminotlabel" + key} className="zeitro-radio divlink" htmlFor={"includesminot" + key}>No, it doesn't</label>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                        : ""}

                    <Row className="">
                        <Col >

                            {askHowToPay()}
                        </Col>

                    </Row>
                </div>
            )
        }
        let m3 = "m-3 "
        if (!margin) {
            mt5 = ""
            m3 = "mb-3 "
        }
        let toggleShow = () => {
            let expand = this.state.expand
            expand[index] = !expand[index]
            this.setState({ expand })

            var content = document.getElementById("card" + key);
            if (!expand[index]) {
                content.style.maxHeight = "0px";
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        }
        let askAboutDuplicate = !margin &&
            placeInStore.correction === "duplicate" &&
            this.state.stepsandsubsteps[index].dupresolved == null

        let askDupe = () => {
            let onDupe = e => {
                let val = e.target.value === "true"
                let stepsandsubsteps = this.state.stepsandsubsteps
                stepsandsubsteps[index].dupresolved = val
                this.setState({ stepsandsubsteps })

                if (!val) {
                    com.touch()
                    if (isMain) {
                        this.props.changeBorrowerLoanCorrection({ target: { value: "none" } }, index, this.props.who)
                    } else {
                        this.setState(prevState => {
                            let tmp = prevState.loans.map((l, i) => {
                                if (index === i)
                                    l.correction = "none"
                                return _.cloneDeep(l)
                            })
                            return { loans: tmp }
                        })
                    }
                    setTimeout(() => {
                        let stepsandsubsteps = this.recalcStepper();
                        this.setState({ stepsandsubsteps })
                    }, 1000)
                }
            }
            return <div className="ml-2">
                <div className="_sectionquestion">This entry looks like a duplicate of the previous one: {this.props.application.assetsandliabilities.loans[index - 1].name}, acc. {this.props.application.assetsandliabilities.loans[index - 1].accountnumber},
                    balance: ${com.commaizeFloat(this.props.application.assetsandliabilities.loans[index - 1].remainingbalance)}
                    <br />Could you please confirm?</div>
                <Form.Group>
                    <fieldset>
                        <div className="d-flex  mt-2">
                            <div className="mr-3">
                                <input required onChange={onDupe} value={true} checked={true === this.state.stepsandsubsteps[index].dupresolved} type="radio" id="dupe" name="dupe"></input>
                                <label className="zeitro-radio divlink" htmlFor="dupe">Yes, this is a duplicate</label></div>
                            <div><input required onChange={onDupe} value={false} checked={false === this.state.stepsandsubsteps[index].dupresolved} type="radio" id="dupenot" name="dupe"></input>
                                <label className="zeitro-radio divlink" htmlFor="dupenot">No, it is not</label></div>
                        </div>
                    </fieldset>
                </Form.Group>
            </div>
        }
        return (
            <div key={key} className={placeInStore.mannerofpaymentcode !== "C" ?
                m3 + " red-shadow  text-wrap" + crossed + mt5
                :
                m3 + "  drop-shadow  text-wrap" + crossed + mt5
            } style={style}>
                <Modal show={this.state.show[index]} onHide={handleClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4>Does this liability need a correction?</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-3">
                        <form name={"corr" + key}>
                            <div className="mt-2 ">
                                <Form.Control name="purpose" as="select"
                                    value={correction}
                                    onChange={correctionChecked} >

                                    <option value="none">No correction needed</option>
                                    <option value="wrongperson">This is not my account!</option>
                                    <option value="alreadyclosed">This account has been paid in full and closed</option>
                                    <option value="paidbybusiness">Debt paid by business</option>
                                    <option value="duplicate">Duplicate entry</option>

                                    <option value="other">Other</option>
                                </Form.Control>

                            </div>
                            {correction !== "none" && correction !== "duplicate" ?
                                <div className="mt-3">
                                    <div>Please enter an explanation:</div>
                                    <textarea rows="5" onChange={changeExplanation} className="w-100" value={placeInStore.explanation}>
                                    </textarea>
                                    <div style={{ fontStyle: 'italic', fontSize: '0.8em' }}>Documentation is required to support this explanation</div>

                                </div>
                                : ""
                            }
                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Button variant="success" onClick={handleClose}>Close</Button>
                                </Col>
                            </Row>
                        </form>

                    </Modal.Body>
                </Modal>
                <Row className="mt-0 pt-2 bluishbackground">
                    <Col xs>

                        <div style={{ fontSize: "1.2em" }} className="sectionquestion">
                            {com.unCamelCase(placeInStore.creditloantype)}{this.getFlag(correction)} - <i>{placeInStore.creditliabilityaccountstatustype}</i></div>
                    </Col>
                    {margin ?
                        <Col xs="auto">
                            <i className={this.state.expand[index] ? "fa fa-arrow-circle-up downarrow" : "fa fa-arrow-circle-down downarrow"} onClick={toggleShow} aria-hidden="true" ></i>
                        </Col> : ""}
                </Row>
                <div className="">
                    <Row className="bluishbackground" >
                        <Col xs="auto">
                            <div>Lender:</div>
                            <div >{placeInStore.name}</div>
                        </Col>
                        <Col xs="auto">
                            <div>Account number:</div>
                            <div>{placeInStore.accountnumber}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Balance:</div>
                            <div>${com.commaize(placeInStore.remainingbalance)}</div>
                        </Col>
                        <Col xs="auto" >
                            <div>Mo. Payment:</div>
                            <div>{displayMonthly()}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Mo. left:</div>
                            <div>{
                                Number.isInteger(parseInt(placeInStore.monthslefttopay)) ?
                                    placeInStore.monthslefttopay : "N/A"
                            }</div>
                        </Col>

                        <Col xs="auto">
                            Ownership<br />
                            {displayOwnership()}
                        </Col>

                        {placeInStore.reportedby && <Col xs="auto">
                            Reported by:<br />
                            {placeInStore.reportedby.join(', ')}
                        </Col>}

                        <Col xs="auto">
                            Start date:<br />
                            {placeInStore.openeddate}
                        </Col>

                        {placeInStore.currentratingtype !== "AsAgreed" ?
                            <Col xs="auto" className="text-wrap">
                                <div style={{ fontWeight: '700', color: '#660000' }} className="text-wrap">{com.unCamelCase(placeInStore.currentratingtype)}</div>
                            </Col>
                            : ""}

                    </Row>
                </div>
                <div className={!margin ? "" : "cardbody"} id={"card" + key}>
                    {askAboutDuplicate ? askDupe() :
                        <div className="">
                            <Row className="">
                                <Col sm="auto" className="d-flex pl-2 py-0 text-nowrap" >
                                    <Button onClick={showCorrect} style={{ color: '#600' }} className="text-wrap mt-0 p-1" variant="link" ><i className="fas fa-angle-right"></i>Click if this entry needs correction</Button><span className="mt-1">{tooltip("",
                                        <div>Click on this link if
                                            <ul>
                                                <li>
                                                    This loan does not belong to you
                                                </li>
                                                <li>
                                                    It has been paid in full
                                                </li>
                                                <li>
                                                    The debt has been paid by business
                                                </li>
                                                <li>
                                                    This is a duplicate entry (happens quite often!)
                                                </li>                                </ul>
                                            In this case the liability won't be counted, but you will have to supply supporting documents.

                                        </div>
                                        , "auto", "", true)}</span>
                                </Col>

                            </Row>
                            {askAboutDuplicate ? askDupe() : theRest()}
                        </div>}
                </div>

            </div>
        )
    }


    render() {

        let showWarnings = () => {
            if (this.props.application.assetsandliabilities.warnings != null && this.props.application.scre===0) {
                if (this.props.application.assetsandliabilities.warnings != null) {
                    return <div className="mx-5 text-left">
                        <div className="title-3 mb-3">The soft credit pull was unsuccessful for the following reasons:</div>
                        <div>
                            {this.props.application.assetsandliabilities.warnings.map(x => <Alert message={x} type="warning"/>)}
                        </div>
                        <div className="my-3" ><Button onClick={this.pullcredit}>{this.state.loadingpullcredit ? <Spin /> : "Redo soft credit pull"}</Button></div>
                    </div>
                } else {
                    return <div className="mx-5 ">
                        <Alert message="Sorry, we're unble to do soft credit check, please contact Zeitro for help." type="warning"/>
                    </div>
                }
            }
            return ""
        }

        return (
            <div className="text-center mt-3 ">
                <div>
                    {this.state.renderer}
                    {this.state.dtiRenderer}
                    {showWarnings()}
                </div>
                {this.state.loadingcr ?
                    null
                    :
                    <div className=" p-4 text-left">
                        {this.renderLoans()}
                    </div>
                }

            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditReport)
