import React, { useEffect, useRef, useState } from "react";
import { Button as B, Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Space, Table, Button, Skeleton, Popconfirm } from "antd";
import Highlighter from "react-highlight-words";
import { dateToStringMMDDYYYY, sortDates } from "./Common";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import TruncatedText from "./Common/TruncatedText";
import "./Borrowers.css"

const Borrowers = (props) => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [loans, setLoans] = useState([]);
  const [borrowerData, setBorrowerData] = useState([]);
  const [selectedBorrower, setSelectedBorrower] = useState("");
  const [isPast, setIsPast] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      // width: "30%",
      ...getColumnSearchProps("name"),
      render: (_, record) => (
        <a
          onClick={() => handleSelected(record.borrowerId)}
          style={{ fontWeight: 500 }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "ACTIVITY DATE",
      dataIndex: "lastTouch",
      key: "lastTouch",
      render: (text) => <>{dateToStringMMDDYYYY(text)}</>,
      sorter: (a, b) => a.lastTouch - b.lastTouch,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <div className="d-flex align-items-center">
        <Button
          onClick={() => handleSelected(record.borrowerId)}
          style={{ backgroundColor: "#325CEB", color:"white" }}
        >
          View
        </Button>
        <Popconfirm
        title="Are you sure to delete this borrower?"
        description="All the related loan records and documents will be deleleted."
        onConfirm={()=>deleteBorrower(record.borrowerId)}
        okText="Yes"
        cancelText="No"
      ><a
      style={{ color: "#EB0000", textDecoration: "underline", marginLeft: 13 }}
    >
      {deleteLoading ? <Spinner animation="border" variant="danger" size="sm" /> : "Delete"}
    </a></Popconfirm>
    </div>
      ),
    },
  ];
  const transform = (borrower) => {
    const getPurpose = () => {
      if (borrower.LoanPurpose === "") {
        return "";
      }
      if (borrower.LoanPurpose === "purchase") {
        if (borrower.HasContract) {
          return "Purchase";
        } else {
          return "Pre-qualification";
        }
      } else if (borrower.LoanPurpose === "refinance") {
        return "Rate/Term refinance";
      } else {
        return "Cash out refinance";
      }
    };
    return {
      name: borrower.FirstName + " " + borrower.LastName,
      email: borrower.Email,
      address: borrower.Address,
      phone: borrower.Phone === "" ? "-" : borrower.Phone,
      lastTouch: new Date(borrower.LastTouch),
      purpose: getPurpose(),
      borrowerId: borrower.ID,
      loanId: borrower.LoanID,
      isCurrentLoan: borrower.IsCurrentLoan,
    };
  };
  const processLoans = (loans) => {
    let data = [];
    let borrowerMap = {};
    for (let loan of loans) {
      if (loan.email in borrowerMap) {
        if (loan.lastTouch > borrowerMap[loan.email].lastTouch) {
          borrowerMap[loan.email] = loan;
        }
      } else {
        borrowerMap[loan.email] = loan;
      }
    }
    for (let bo in borrowerMap) {
      data.push(borrowerMap[bo]);
    }
    return data;
  };

  const getLoans = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/loanlist", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Past": isPast,
      },
    })
      .then((response) => {
        setSelectedBorrower("")
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text);
          } else {
            let docs = js.Loans.map((s) => transform(s));
            setLoans(docs);
            let data = processLoans(docs);
            setBorrowerData(data);
            if (typeof props.match.params["borrowerid"] !== "undefined") {
              setSelectedBorrower(props.match.params["borrowerid"])
            } 
            setLoading(false);
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLoans();
  }, []);



  const handleSelected = (v) => {
    setSelectedBorrower(v);
  };

  const deleteBorrower = (borrowerID) => {
    setDeleteLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/deleteborrower/"+borrowerID, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          setDeleteLoading(false)
          alert(
            "Looks like there was a problem. Please try later."
          );
          return;
        } else {
          setDeleteLoading(false)
          alert(
            "The borrower has been deleted."
          );
          window.location.href = "/app/borrowers"
        }
      })
      .catch(function (err) {
        setDeleteLoading(false)
        console.log("Fetch Error :-S", err);
      })
  }

  return selectedBorrower !== "" ? (
    <BorrowerDetail
      borrowerId={selectedBorrower}
      borrowers={borrowerData}
      loans={loans}
      handleSelected={handleSelected}
    />
  ) : (
    <div className="borrowers-wrap text-right">
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="mb-2 d-flex justify-content-between operate-bar">
            <div className="title-4 ml-3 borrower-num">
              {borrowerData.length + " borrowers"}
            </div>
            <Link className="applicant-link" to="/app/customerregistration">
              <B
                variant="zeitro-primary"
                style={{ width: "144px" }}
                className="mr-5 applicant-btn"
              >
                New applicant
              </B>
            </Link>
          </div>
          <Table
            className="borrowers-data-table"
            columns={columns}
            dataSource={borrowerData}
            showSorterTooltip={false}
            scroll={{
              x: true,
            }}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </>
      )}
    </div>
  );
};

const BorrowerDetail = ({ borrowerId, borrowers, loans, handleSelected }) => {
  const [borrowerSearch, setBorrowerSearch] = useState("");
  const [currentBorrowers, setCurrentBorrowers] = useState(borrowers);
  const [currentBorrower, setCurrentBorrower] = useState({});

  const borrowerSearchHandler = (e) => {
    let tmp = borrowers.filter(
      (c) =>
        c.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        c.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setBorrowerSearch(e.target.value);
    setCurrentBorrowers(cloneDeep(tmp));
  };

  useEffect(() => {
    for (let b of borrowers) {
      if (b.borrowerId === borrowerId) {
        setCurrentBorrower(b);
      }
    }
  }, [borrowerId]);

  const switchBorrower = (where) => {
    sessionStorage.setItem("edit", "false")
    sessionStorage.setItem("state", "{}");
    sessionStorage.setItem("originalstate", "{}");
    sessionStorage.setItem("borrowermenu", "loanSummary");
    window.location.href = "/app/borrower/" + where;
  };

  const getBorrowerLoans = () => {
    let bLoans = loans.filter(
      (l) => l.borrowerId === currentBorrower.borrowerId
    );
    bLoans.sort((a, b) => {
      if (a.isCurrentLoan && !b.isCurrentLoan) {
        return -1; 
      } else if (!a.isCurrentLoan && b.isCurrentLoan) {
        return 1; 
      }
      return 0; 
    });
    return bLoans.map((l) => (
      <div
        className="pb-1 text-left mr-4 mb-4 d-flex flex-column"
        style={{
          width: 308,
          height: 177,
          borderRadius: 4,
          border: "1px solid #ECECEC",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        }}
      >
      {l.isCurrentLoan && <div className="applicationLabel">Current application</div>}
      <div style={{padding:  l.isCurrentLoan ? "5px 20px 15px 20px" : "26px 20px 15px 20px"}}>
        <div className="text-label mb-3">{l.purpose}</div>
        <div className="mb-2">{l.address === "" ? "-" : <TruncatedText maxLines={1} text={l.address} maxWidth={40}/>}</div>
        <div style={{ color: "#6E6E70", fontSize: 14 }}>
          Last edit at {dateToStringMMDDYYYY(l.lastTouch)}
        </div>
        </div>
        <div className="border-top text-right mt-auto">
          <B variant="link" onClick={() => switchBorrower(l.loanId)}>
            View loan
          </B>
        </div>
      </div>
    ));
  };

  return (
    <Row className="d-flex vh-100">
      <Col sm={4} lg={2} className="borrowerNames">
        <div className="py-4 px-3">
          <div className="title-4 text-left mb-3">
            {borrowers.length + " borrowers"}
          </div>
          <Input
            placeholder="Search applicants"
            prefix={<SearchOutlined />}
            value={borrowerSearch}
            onChange={borrowerSearchHandler}
          />
        </div>
        {currentBorrowers.map((t) => {
          return (
            <B
              variant={
                borrowerId === t.borrowerId ? "selectedBorrower" : "borrower"
              }
              key={t.borrowerId}
              onClick={() => handleSelected(t.borrowerId)}
            >
              <div style={{ fontWeight: 500 }}>{t.name}</div>
              <div style={{ fontSize: 14 }}>
                Active on {dateToStringMMDDYYYY(t.lastTouch)}
              </div>
            </B>
          );
        })}
      </Col>
      <Col sm={8} lg={10} className="borrowerDetails">
        <div className="text-left">
          <CloseOutlined
            onClick={() => handleSelected("")}
            style={{ fontSize: 20, color: "#6E6E70" }}
            className="mb-3"
          />
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="title-2">{currentBorrower.name}</div>
          {/* <B variant="zeitro-primary">Contact</B> */}
        </div>
        <div className="title-4 text-left mb-3">Basic Information</div>
        <div className="text-left mb-3">
          <Row className="pl-0 mb-3">
            <Col className="pl-0">
              <div className="text-label">Name</div>
              <div>{currentBorrower.name}</div>
            </Col>
            <Col>
              <div className="text-label">Email</div>
              <div>{currentBorrower.email}</div>
            </Col>
          </Row>
          <Row className="pl-0">
            <Col className="pl-0">
              <div className="text-label">Phone</div>
              <div>{currentBorrower.phone}</div>
            </Col>
            <Col>
              <div className="text-label">Address</div>
              <div>
                {currentBorrower.address === "" ? "-" : currentBorrower.address}
              </div>
            </Col>
          </Row>
        </div>
        <div className="border-top">
          <div className="title-4 text-left py-3">Loans</div>
          <div className="d-flex flex-wrap">{getBorrowerLoans()}</div>
        </div>
      </Col>
    </Row>
  );
};

export default Borrowers;
