import React from 'react';
import { Button, Col, Row } from 'antd';
import "./AboutMe.css";
export default function AboutMe({ basicInfo, aboutInfo }) {
    const dynamicExternalLink = (href) => {
        if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`
        }
        return href
    }
    return (
        <div className="about-me">
           <div className='about-title'>About {`${basicInfo.firstname} ${basicInfo.lastname}`}</div>
           <div className='introduction'>{ aboutInfo.introduction }</div>
           <div className='contact'>
                <Row>
                    <Col className='contact-item' lg={aboutInfo.companyWebsite ? 9 : 12} span={24}>
                        <div className='label'>Email</div>
                        <div className='value'>{ aboutInfo.email }</div>
                    </Col>
                    <Col className='contact-item' lg={aboutInfo.companyWebsite ? 6 : 12} span={24}>
                        <div className='label'>Cell</div>
                        <div className='value'>{ aboutInfo.phone }</div>
                    </Col>
                    {
                        aboutInfo.companyWebsite && (
                            <Col className='contact-item' lg={9} span={24}>
                                <div className='label'>Company</div>
                                <div className='value'>{ aboutInfo.companyWebsite }</div>
                            </Col>
                        )
                    }
                </Row>
           </div>
           <div className='app-list'>
            { aboutInfo.google && <img src='/images/google.svg' alt='google' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.google)} /> }
            { aboutInfo.linkedin && <img src='/images/linkedln.svg' alt='linkedln' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.linkedin)} /> }
            { aboutInfo.twitter && <img src='/images/twitter.svg' alt='twitter' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.twitter)} /> }
            { aboutInfo.yelp && <img src='/images/yelp.svg' alt='yelp' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.yelp)} /> }
           </div>
           <img className='bg-circle' src='/images/bg-circle.svg' />
        </div>
    );
}