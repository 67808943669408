export const DEFAULT_LOAN_TERM = 30; //year
export const PURCHASE = "Purchase";
export const REFINANCE = "RateTermRefi";
export const CALIFORNIA = "CALIFORNIA";
export const TEXAS = "TEXAS";
export const termOptions = [
  { label: "30 Year", value: "fixed30" },
  { label: "25 Year", value: "fixed25" },
  { label: "20 Year", value: "fixed20" },
  { label: "15 Year", value: "fixed15" },
  { label: "10 Year", value: "fixed10" },
  { label: "ARM 5/6", value: "arm56" },
  { label: "ARM 7/6", value: "arm76" },
  { label: "ARM 10/6", value: "arm106" },
];
export const ausOptions = [
  { name: "DU", key: "DU" },
  { name: "LP", key: "LP" },
  { name: "Manual", key: "Manual" },
];
export const transformScenario = (scenario) => {
  let tmp = {
    ...scenario,
    parameters: {
      County: scenario.parameters.County,
      CreditScore: scenario.parameters.CreditScore,
      DownPayment: scenario.parameters.DownPayment,
      Occupancy: scenario.parameters.Occupancy,
      PropertyType: scenario.parameters.PropertyType,
      PropertyValue: scenario.parameters.PropertyValue,
      Purpose: scenario.parameters.Purpose,
      State: scenario.parameters.State,
    }
  }
  if(tmp.parameters.State === TEXAS) delete tmp.parameters.County;
  if (tmp.parameters.PropertyType === 'twotofourfamily') tmp.parameters.Units = scenario.parameters.Units
  return tmp
}