import { createStore } from "redux";
import { throttle } from "lodash";
import rootReducer from "../Reducers/index";
import * as com from "../Common.js";
import { LoState, State } from "../State.js";

import { updateApplication } from "./actions";

var stringify = require("fast-json-stable-stringify");

let inCurrentState = false;
let stampCurrentState = 0;
export const initialState = JSON.parse(
  (() => {
    let a = new LoState();
    return a.toJson();
  })()
);

var md5 = require("md5");
const merge = require("deepmerge");
const cloneDeep = require("lodash/cloneDeep");

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log("Error in loadState:", err)
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (e) {
    // write errors
    console.log("Error in saveState:", e)
  }

};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

let persistedState = loadState();

if (typeof persistedState !== "undefined") {
  persistedState = merge(initialState, persistedState, {
    arrayMerge: overwriteMerge,
  });
} else {
  persistedState = initialState;
}
saveState(persistedState);

const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
);

let lastSentHash = "";

store.subscribe(
  throttle(() => {
    let current = store.getState();
    // console.log("in state save throttler 1000")
    saveState(current);
  }, 1000)
);

store.subscribe(
  throttle(() => {
    let current = store.getState();
    // console.log("in state save throttler 5000")
    let currentHash = md5(stringify(current));
    if (currentHash !== lastSentHash) {
      let token = window.sessionStorage.getItem("ZeitroA");
      if (null != token) {
        if (!inCurrentState) {
          getCurrentState();
          lastSentHash = currentHash;
        } else {
          //console.log("skip getCurrentState")
        }
      }
    }
  }, 5000)
);

export function mergeState(
  id,
  origin,
  masterState = cloneDeep(store.getState()),
  ps = sessionStorage.getItem("originalstate")
) {
  let originState;
  let broken = false;

  try {
    originState = JSON.parse(origin);
  } catch (e) {
    console.log("Error: Invalid json from the server in merge");
    broken = true;
  }
  if (originState.progress == null) {
    //debugger
  }
  let previousState;
  if (broken) {
    console.log("state broken");
    originState = new State();
    previousState = new State();
  } else {
    try {
      previousState =
        ps === "{}" || ps === null
          ? cloneDeep(store.getState())
          : JSON.parse(ps);
    } catch (x) {
      originState = new State();
      previousState = new State();
    }
  }
  try {
    if (masterState.borrower.id !== id) {
      console.log(
        "State collision detected. local: ",
        masterState.borrower.id,
        " remote call: ",
        id
      );
      //debugger
      return masterState;
    }
  } catch (c) {
    return masterState;
  }
  let appAtOrigin = originState.application;
  let appCurrent = masterState.application;
  let appPrevious = previousState.application;
  let inp = stringify(appCurrent);
  let pr = stringify(appPrevious);
  let or = stringify(appAtOrigin);

  // eslint-disable-next-line eqeqeq
  if (pr != inp) {
    console.log("new local changes detected");
    /*
    let str = ""
    
    let d = diff(inp, pr)

    d.forEach(x => {
      let [s, val] = x
      switch(s) {
        case -1: 
        str += val
        console.log(str.substr(str.length - 20, 20))
        break
        case 1: 
        break
        default: 
        str += val
        break
      }
     })
     */
  } else {
    // eslint-disable-next-line eqeqeq
    if (inp != or) console.log("new remote changes detected");
  }
  let gettype = (o) => {
    let whattype = typeof o;
    if ("object" === whattype) {
      if (Array.isArray(o)) {
        whattype = "array";
      }
    }
    return whattype;
  };
  let dumpArray = (obj, prev, origin, level) => {
    for (let i = 0; i < obj.length; i++) {
      let whattype = gettype(obj[i]);
      if (whattype === "object") {
        dumpObject(obj[i], prev[i], origin[i], level + 1);
      } else if (whattype === "array") {
        dumpArray(obj[i], prev[i], origin[i], level + 1);
      } else {
        // number, boolean, string
        if (obj[i] === prev[i] && obj[i] !== origin[i]) {
          obj[i] = origin[i];
          continue;
        }
      }
    }
  };
  let dumpObject = (obj, prev, origin, level) => {
    for (var propertyName in obj) {
      let whattype = gettype(obj[propertyName]);
      if (obj[propertyName] == null && origin[propertyName] != null)
        whattype = gettype(origin[propertyName]);

      if (whattype === "object") {
        if (prev == null)
          // new wins
          continue;
        if (origin == null)
          // local wins
          continue;
        dumpObject(
          obj[propertyName],
          prev[propertyName],
          origin[propertyName],
          level + 1
        );
      } else if (whattype === "array") {
        if (prev == null || prev[propertyName] == null)
          // local change wins
          continue;

        if (origin == null || origin[propertyName] == null)
          // nothing to merge with
          continue;

        if (
          obj[propertyName].length === prev[propertyName].length &&
          prev[propertyName].length === origin[propertyName].length
        ) {
          // just iterate
          dumpArray(
            obj[propertyName],
            prev[propertyName],
            origin[propertyName],
            level + 1
          );
        } else {
          if (obj[propertyName].length === prev[propertyName].length) {
            // use reliable serializer
            let pa = stringify(prev[propertyName]);
            let oa = stringify(obj[propertyName]);
            if (pa === oa) {
              // take remote, SUBPAR, todo
              obj[propertyName] = origin[propertyName];
              prev[propertyName] = origin[propertyName];
            }
          }
          // keep local
        }
      } else {
        // number, bool, string
        if (prev == null || origin == null) continue;
        if (undefined === typeof origin[propertyName]) continue;

        if (
          obj[propertyName] === prev[propertyName] &&
          obj[propertyName] !== origin[propertyName]
        ) {
          console.log(
            "set ",
            propertyName,
            " to remote value: ",
            origin[propertyName],
            " local value: ",
            obj[propertyName],
            ", previous: ",
            prev[propertyName]
          );
          obj[propertyName] = origin[propertyName];
          continue;
        }
      }
    }
  };

  try {
    dumpObject(appCurrent, appPrevious, appAtOrigin, 0);
  } catch (x) {
    console.log("exception in dumpObject");
  }
  let out = stringify(appCurrent);

  masterState.application = appCurrent;
  try {
    let d1 = new Date(masterState.progress.lasttouch)
    let d2 = new Date(originState.progress.lasttouch)
    masterState.progress.lasttouch =
      d2 > d1
        ? originState.progress.lasttouch
        : masterState.progress.lasttouch;
    masterState.progress.interview.max = Math.max(
      masterState.progress.interview.max,
      originState.progress.interview.max
    );
    masterState.progress.interview.step = Math.max(
      masterState.progress.interview.max,
      originState.progress.interview.step
    );
  } catch (x) {
    console.log(x)
    debugger
  }
  // eslint-disable-next-line eqeqeq
  if (or != out) {
    // application part mediated

    sessionStorage.setItem("originalstate", JSON.stringify(masterState));

    const unsubscribe = store.subscribe(handleChange);
    function handleChange() {
      unsubscribe();
      if (inp !== or) {
        console.log("Update the remote state");
        updateState(masterState);
      } else {
        console.log(
          "don't update the remote state, it is the same, updated local"
        );
      }
    }
    store.dispatch(updateApplication(appCurrent));
  } else {
    inCurrentState = false;
    sessionStorage.setItem("originalstate", JSON.stringify(masterState));
    //console.log("no change from origin, no local changes")
  }
  return masterState;
}

export function getCurrentState(
  masterState = cloneDeep(store.getState()),
  previousState = sessionStorage.getItem("originalstate")
) {
  if (inCurrentState) {
    let now = Date.now();
    if (now - stampCurrentState < 10000) {
      return; // should be more sophisticated?
    }
    console.log(
      "Error: 10 sec state management timeout! Elapsed ",
      now - stampCurrentState
    );
  }
  stampCurrentState = Date.now();
  inCurrentState = true;

  let token = window.sessionStorage.getItem("ZeitroA");
  saveState(masterState);

  let id = masterState.borrower.id;
  let loanid = masterState.borrower.loan_id;
  if (id === "") return;

  console.log("get state for borrower:", id, ", loanid:", loanid);

  fetch(window.location.origin + "/agent/borrowerstate/" + id + "/" + loanid, {
    cache: "no-cache",
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      Cache: "no-cache",
    },
  })
    .then((response) => {
      if (!response.ok) {
        console.log("Auth fetch error:", response);
        sessionStorage.removeItem("ZeitroA");
        window.location.href = "/";
      } else {
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("State Update Error: " + js.Status);
            window.document.dispatchEvent(new Event("reauthenticate"), "");
          } else {
            if (js.Text !== "{}") {
              masterState = store.getState();
              previousState = sessionStorage.getItem("originalstate");
              if (
                typeof masterState === "undefined" &&
                typeof previousState === "undefined"
              ) {
                mergeState(id, js.Text);
              } else {
                mergeState(id, js.Text, masterState, previousState);
              }
            } else {
              saveState(masterState);
              sessionStorage.setItem(
                "originalstate",
                JSON.stringify(store.getState())
              );
              updateState(masterState);
            }
          }
        });
      }
    })
    .catch((error) => {
      inCurrentState = false;
      console.log("getCurrentState: error");
    });
}

export function updateState(s) {
  let sstate = JSON.stringify({
    application: s.application,
    progress: s.progress,
  });
  sessionStorage.setItem("originalstate", sstate);

  let token = window.sessionStorage.getItem("ZeitroA");
  let borrowerId = s.borrower.id;
  let tosend = com.ascii2hex(sstate);
  let body = { id: borrowerId, state: tosend, loanid: s.borrower.loan_id};

  if (token === null) {
    console.log("updateState: not authenticated");
    window.location.href = "/#home";
    return;
  }
  if (sessionStorage.getItem("edit") === "false") {
    console.log("Editing is not allowed.")
    return
  };
  inCurrentState = true;
  console.log("update state for ", borrowerId);

  fetch(window.location.origin + "/agent/updatestate", {
    cache: "no-cache",
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + token,
      Cache: "no-cache",
    },
  })
    .then((response) => {
      if (!response.ok) {
        console.log("Auth fetch error");
        sessionStorage.removeItem("ZeitroA");
        this.setState({ show: true });
      } else {
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("State Update Error: " + js.Status);
            if (js.Status.indexOf("token is expired")) {
              sessionStorage.removeItem("ZeitroA");
            }
            window.document.dispatchEvent(new Event("reauthenticate"), "");
          } else {
          }
          inCurrentState = false;
        });
      }
    })
    .catch((error) => {
      sessionStorage.removeItem("ZeitroA");
      console.log("updateState: error");
      inCurrentState = false;
    });
}

window.setInterval(() => {
  let token = window.sessionStorage.getItem("ZeitroA");
  if (null != token) getCurrentState();
}, 5000);

export default store;
