import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Col from "react-bootstrap/Col";
import { Row } from 'react-bootstrap';
import './App.css';
import './Menu.css';
import * as com from './Common';
import { Link } from "react-router-dom";
import { Dropdown, Space } from 'antd';
import { Popup, Collapse } from 'antd-mobile'
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { checkAccess, RBAC } from "./Auth";
import {
    UR_LoanOfficer,
    UR_LoanOfficerManager,
    UR_Owner,
    UR_LoanProcessor,
    UR_RealEstateAgent,
  } from "./State";
export class AnthenticatedMenu extends Component {
    constructor(props) {
        super(props);
        let sess = window.sessionStorage.getItem("ZeitroA")
        this.state = {
            noactivesession: (null == sess || sess.length === 0),
            avatar: "/avatar.png",
            gmailKeysApsent: false,
            email: "",
            popupVisible: false
        }
        this.timerId = null
        window.sessionStorage.setItem("step", 0)
        let token = window.sessionStorage.getItem("ZeitroA");

        fetch("/agent/getprofile", {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {
            let d = new Date()
            if (!response.ok) {
                console.log("Auth fetch error, at: " + d.toLocaleString())
                sessionStorage.removeItem("ZeitroA")
                this.setState({ show: true })
            } else {

                response.json().then(js => {
                    if (js.Status !== "OK") {

                    } else {
                        
                        let image = js.Profile.Image
                        let emailaddress = js.Profile.Email
                        this.setState({ avatar: image })
                        this.setState({ email: emailaddress})
                    }
                })
            }
        }).catch(error => {
            let d = new Date()
            console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
        })

    }

    ontimer = () => {
        let sess = window.sessionStorage.getItem("ZeitroA")
        let notalive = (null == sess || sess.length === 0)
        if (this.state.noactivesession !== notalive) {
            this.setState({ noactivesession: notalive })
        }
        this.timerId = setTimeout(this.ontimer, 1000)
    }
    componentDidMount() {
        this.timerId = setTimeout(this.ontimer, 1000)
    }

    componentWillUnmount() {
        if (this.timerId)
            clearTimeout(this.timerId)
        this.timerId = null
    }
    toggleMenu = () => {
        this.setState({ popupVisible: !this.state.popupVisible })
    }
    closeMenu = () => {
        this.setState({ popupVisible: false })
    }
    renderNav = () => {
        const items = [
            {
              label: (
                <Link to= "/app/borrowers">
                    <a  rel="noopener noreferrer" >
                    Borrowers
                    </a>
                </Link>
              ),
              key: '0',
            },
            {
                label: (
                  <Link  to=  "/app/purchasecalculator">
                    <a  rel="noopener noreferrer" >
                        Rate pricing
                    </a>
                  </Link>
                ),
                key: '1',
            },
            {
                label: (
                    <Link  to=  "/app/affordability">
                        <a  rel="noopener noreferrer">
                            Affordability
                        </a>
                  </Link>
                ),
                key: '2',
            },
            {
            type: 'divider',
            },
            {
              label: (
                <a rel="noopener noreferrer" href="/app/logout">
                  Log out
                </a>
              ),
              key: '3',
            },
          ];

        if (this.state.noactivesession)
            return ""
        else
            return (
                <Dropdown
                menu={{
                    items,
                }}
            >
                <a onClick={(e) => e.preventDefault()} className="d-flex" style={{color:"#1F3988"}}>
                        {this.state.email}
                    <DownOutlined style={{marginLeft: 5, marginTop: 4}} />

                </a>
            </Dropdown>
            )

    }
    render = () => {
        let siderbarItem = (link, imgName, text, putEnd) => {

            let selected = false
            if (window.location.href.includes(link)) {
              selected = true
            }
      
            let st = putEnd? {marginTop:"auto"}: {}
            return <Link to={link} style={st} onClick={this.closeMenu}>
                    <Col className='d-flex align-items-center mobile-menu'>
                        <div className={selected ? "mainSiderbarItemSelected" : "mainSiderbarItem"}>
                          <img src={"/images/dashboard-" + imgName +".svg"} 
                          className= "mainSiderbarImg"/>
                         </div>
                          <span className="mainSidebarText">{putEnd? "": text}</span>
      
                    </Col>
                  </Link>
        }
        let siderbarMoreItem = () => {
            const items = [
              {
                key: '1',
                label: (
                  <Link to="/app/affordability" className="d-flex" onClick={this.closeMenu}>
                    <img src="/images/dashboard-affordability.svg" className= "mainSiderbarDropdownImg"/>
                    <div>Affordability check</div>
                  </Link>
                ),
              },
              // {
              //   key: '2',
              //   label: (
              //     <Link to="/app/landingpage" className="d-flex">
              //       <img src="/images/dashboard-landing.svg" className= "mainSiderbarDropdownImg"/>
              //       <div>My Landing page</div>
              //     </Link>
              //   ),
              // },
              // {
              //   key: '3',
              //   label: (
              //     <Link to="/app/distribution" className="d-flex">
              //       <img src="/images/dashboard-leads.svg" className= "mainSiderbarDropdownImg"/>
              //       <div>Leads</div>
              //     </Link>
              //   ),
              // },
              {
                key: '4',
                label: (
                  <Link to="/app/profile" className="d-flex" onClick={this.closeMenu}>
                    <img src="/images/dashboard-settings.svg" className= "mainSiderbarDropdownImg"/>
                    <div>Settings</div>
                  </Link>
                ),
              },
              {
                key: '5',
                label: (
                  <Link to="/app/logout" onClick={this.closeMenu}>
                    Log out
                  </Link>
                ),
              },
            ];
            const panelTitle = () => {
                return (
                    <div className='d-flex align-items-center'>
                        <div className={ "mainSiderbarItem"}>
                            <img src="/images/dashboard-more.svg" className= "mainSiderbarImg"/>
                        </div>
                        <span style={{marginLeft: '12px'}} className="mainSidebarText">More</span>
                    </div>
                )
            }
            return (
                <Collapse className='menu-collapse'>
                    <Collapse.Panel key='1' title={panelTitle()}>
                        {
                            items.map((item, index) => (
                                <div key={index} className='menu-collapse-item'>{item.label}</div>
                            ))
                        }
                    </Collapse.Panel>
                </Collapse>
            )
          }
        let popouContent = () => {
            if (checkAccess(UR_LoanProcessor)) {
                return (
                  <div>
                      {siderbarItem("/app/borrowersforlp", "borrowers", "Loans") }
                      {siderbarItem("/app/purchasecalculator", "rates", "Rates")}
                      {siderbarItem("/app/mortgagegpt", "mortgagegpt", "GuidelineGPT")}
                      {siderbarMoreItem()}
                  </div>
                );
              }
          
              return (
                <div>
                    {siderbarItem("/app/welcome", "home", "Home")}
                    {siderbarItem("/app/borrowers", "borrowers", "Borrowers")}
                    {siderbarItem("/app/purchasecalculator", "rates", "Rates")}
                    {siderbarItem("/app/mortgagegpt", "mortgagegpt", "GuidelineGPT")}
                    {siderbarItem("/app/landingpage", "landing", "My site")}
                    {checkAccess([UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer]) && siderbarItem("/app/management", "management", "Management")}
                    
                    {siderbarMoreItem()}
                </div>
            )
        }
        return (
            <>
                <div className='nav-block'></div>
                <Navbar fixed="top" style={{zIndex:999}} id="navb" className="p-0 m-0 d-flex justify-content-between align-items-center top-menu" >

                    <Navbar.Brand style={{marginRight: '0px'}} className="text-left ml-0" >
                        <Nav.Link style={{display: 'flex', alignItems: 'center'}}>
                            {/* <img className={this.props.app ? "logoprivate" : "logo"} src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"} alt="zeitro" /> */}
                            <img className='menu-icon' src='/images/menu.svg' onClick={this.toggleMenu} />
                            <img className={this.props.app ? "logoprivate nav-logo" : "logo nav-logo"} src="/images/zeitrologo.png" alt="zeitro" />

                        </Nav.Link >
                    </Navbar.Brand>

                    {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav"> */}

                    <div className='d-flex justify-content-between align-items-center mr-3'>
                        <Link to="/app/profile"><img alt="avatar" className="avatar mr-2" src={this.state.avatar}/></Link>

                        {this.renderNav()}
                    </div>

                    {/* </Navbar.Collapse> */}
                </Navbar>
                <Popup
                    className='menu-top-popup'
                    visible={this.state.popupVisible}
                    onMaskClick={this.closeMenu}
                    onClose={this.closeMenu}
                    position='top'
                    bodyStyle={{ height: 'auto' }}
                >
                    {popouContent()}
                </Popup>
            </>
        )
    }
};

export default class Menu extends Component {
    constructor(props) {
        super(props);
        let sess = window.sessionStorage.getItem("ZeitroA")
        this.state = {
            noactivesession: (null == sess || sess.length === 0),
            companyName: com.companyName,
            logo: `data:image/png;base64, ${com.logo}`,
        }
        this.timerId = null
    }

    ontimer = () => {
        let sess = window.sessionStorage.getItem("ZeitroA")
        let notalive = (null == sess || sess.length === 0)
        if (this.state.noactivesession !== notalive) {
            this.setState({ noactivesession: notalive })
        }
        this.timerId = setTimeout(this.ontimer, 1000)
    }
    componentDidMount() {
        const url = new URL(window.location.href);
        let customerID = url.pathname.split('/');
        customerID = customerID[customerID.length - 1] || 'zeitro';
        this.timerId = setTimeout(this.ontimer, 1000)
        fetch('/data/companycustomization', {
            headers: {
                'X-CustomerId': "individualcustomers",
            }
        })
            .then(async response => {
                if (!response.ok) {
                    return;
                }
                response.json().then(json => {
                    this.setState({ companyName: json.CompanyName });
                    let customization = JSON.parse(json.Customization)
                    if (Object.keys(customization).includes('primaryColor')) {
                        this.setState({ logo: `data:image/png;base64, ${customization.icon}` });
                    }
                    com.insertCssColors(customerID);
                })
            })
    }

    componentWillUnmount() {
        if (this.timerId)
            clearTimeout(this.timerId)
        this.timerId = null
    }

    renderNav = () => {
        if (this.state.noactivesession)
            return (
                <Nav className="pr-3">
                </Nav>
            )
        else
            return (
                <Nav className="pr-3">
                    <Nav.Link className="acct" href="/services/logout">Log out</Nav.Link>
                </Nav>
            )
    }
    render = () => {

        return (
            <Navbar id={window.location.pathname === '/' ? "navbb" : this.props.isLogin ? "losLoginNav" : "navb"}
                className="p-0 m-0" collapseOnSelect expand="lg"
                variant="light"  >

                <Navbar.Brand className="text-left" >
                    {
                        this.props.zeitro === true ? 
                        <Nav.Link href="/">
                            <img src="/images/zeitrologo.png" width="100" height="30" alt='logo'></img>
                        </Nav.Link > : <div className='navAgentName'>{this.props.name}</div>
                    }


                </Navbar.Brand>

                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}

                {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
                    <Nav className="mr-auto " >

                    </Nav>
                    {this.renderNav()}
                {/* </Navbar.Collapse> */}
            </Navbar>
        )
    }
};
