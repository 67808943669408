import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Borrower from "./Borrower";
import Spinner from "react-bootstrap/Spinner";

const { SearchBar, ClearSearchButton } = Search;

const ReferralsForLO = (props) => {
  const [referrals, setReferrals] = useState([]);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const handleCloseAcceptModal = () => setShowAcceptModal(false);
  const [acceptedLoanID, setAcceptedLoanID] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [declineLoanID, setDeclineLoanID] = useState("")
  const [declineReason, setDeclineReason] = useState("")

  const getAcceptModal = () => {
    return (
      <Modal show={showAcceptModal} onHide={handleCloseAcceptModal}>
        <Modal.Header closeButton>
          <Modal.Title>Loan Referral Accepted</Modal.Title>
        </Modal.Header>
        <Modal.Body>Click on "OK" below to start work on the loan.</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/app/borrower/" + acceptedLoanID;
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const actionsFormatter = (cell, row) => (
    <div>
      {row.Status === "pending" ? (
        <Spinner animation="border" variant="primary" size="sm" />
      ) : row.Status === "sent" ? (
        <div>
          <Button variant="remove" onClick={() => onAcceptReferral(row)}>
            Accept
          </Button>{" "}
          {/* <Button variant="remove" onClick={() => onDeclineReferral(row)}> */}
          <Button variant="remove" onClick={() => {
            setShowDeclineModal(true)
            setDeclineLoanID(row.LoanID)
          }}>
            Decline
          </Button>
        </div>
      ) : (
        row.Status
      )}
    </div>
  );

  const onAcceptReferral = (row) => {
    setShowSpinner(true);
    row.Status = "pending";

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/acceptreferral/" + row.LoanID, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        setShowSpinner(false);
        alert(
          "Something wrong with the system, please contact Zeitro for help."
        );
        return;
      }
      response.json().then((js) => {
        if (js.Status !== "OK") {
          // meaning the loan has been successfully handed off but email was not sent to RE agent
          setShowSpinner(false);
          setAcceptedLoanID(row.LoanID);
          setShowAcceptModal(true);
        } else {
          setShowSpinner(false);
          setAcceptedLoanID(row.LoanID);
          setShowAcceptModal(true);
        }
      });
    });
  };

  const onDeclineReferral = () => {
    setShowSpinner(true);
    let token = sessionStorage.getItem("ZeitroA");
    console.log(declineReason)
    fetch("/re/declinereferral/" + declineLoanID, {
      method: "POST",
      body: JSON.stringify({reason: declineReason}),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        setShowSpinner(false);
        alert(
          "Something wrong with the system, please contact Zeitro for help."
        );
        return;
      }
      response.json().then((js) => {
        setShowSpinner(false);
        setShowDeclineModal(false)
        setDeclineReason("")
        setDeclineLoanID("")
        getReferrals();
      });
    });
  };

  const seeLoanFormatter = (cell, row) => (
    <div>
      <Button
        variant="remove"
        style={{ backgroundColor: "#96b2ff" }}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "/app/referredborrower/" + row.LoanID;
        }}
      >
        See Loan Details
      </Button>
    </div>
  );

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "solid 2px",
    fontSize: "120%",
  };
  let columns = [
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "ReferredBy",
      text: "Referred By",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "BorrowerName",
      text: "Borrower Name",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "SeeLoan",
      text: "",
      isDummyField: true,
      formatter: seeLoanFormatter,
      headerStyle: headerStyle,
    },
    {
      dataField: "Status",
      text: "",
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: headerStyle,
    },
    {
      dataField: "LoanID",
      text: "Loan ID",
      hidden: true,
    },
  ];

  const getReferrals = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getreferralsforlo", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((js) => {
        let refs = [];
        if (js) {
          for (let r of js) {
            if (r.Status !== "declined") {
              refs.push({
                Date: r.Time.slice(0, 10),
                ReferredBy:
                  r.LoFirstName + " " + r.LoLastName + " (" + r.LoEmail + ")",
                BorrowerName: r.LoanName,
                LoanID: r.LoanID,
                Status: r.Status,
              });
            }
          }
        }
        setReferrals(refs);
      });
    });
  };

  const getDeclineModal = () => {
    return (
      <Modal
        show={showDeclineModal}
        onHide={() => setShowDeclineModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to decline the loan?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3" controlId="declineReason">
        <Form.Label>Please tell us why you want to decline this loan:</Form.Label>
        <Form.Control as="textarea" rows={3} onChange={(e)=>setDeclineReason(e.target.value)} />
      </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowDeclineModal(false)}>Back</Button>
          <Button onClick={() => onDeclineReferral()}>{showSpinner ? <Spinner animation="border" variant="primary" size="sm" /> : "Confirm"}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    getReferrals();
  }, []);

  return (
    <div>
      {referrals.length === 0 ? (
        <h5 className="mt-5">You do not have any referral yet.</h5>
      ) : (
        <ToolkitProvider
          bootstrap4
          keyField="BorrowerName"
          data={referrals}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <Row>
                <Col>
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton {...props.searchProps} />
                </Col>
              </Row>

              <BootstrapTable
                id="referrals"
                bootstrap4
                bordered={false}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                })}
                {...props.baseProps}
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      )}
      {showAcceptModal ? getAcceptModal() : ""}
      {showDeclineModal && getDeclineModal()}
    </div>
  );
};

export default ReferralsForLO;

export const ReferralsForRE = (props) => {
  const [referrals, setReferrals] = useState([]);

  const seeLoanFormatter = (cell, row) => (
    <div>
      {row.Status === "declined" ? (
        <Button
          variant="remove"
          style={{ backgroundColor: "#96b2ff" }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/app/borrower/" + row.LoanID;
          }}
        >
          See Loan Details
        </Button>
      ) : (
        <Button
          variant="remove"
          style={{ backgroundColor: "#96b2ff" }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/app/referredborrower/" + row.LoanID;
          }}
        >
          See Loan Details
        </Button>
      )}
    </div>
  );

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "solid 2px",
    fontSize: "120%",
  };
  let columns = [
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "ReferTo",
      text: "Refer To",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "BorrowerName",
      text: "Borrower Name",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: "SeeLoan",
      text: "",
      isDummyField: true,
      formatter: seeLoanFormatter,
      headerStyle: headerStyle,
    },
    {
      dataField: "LoanID",
      text: "Loan ID",
      hidden: true,
    },
  ];

  const getReferrals = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getreferralsforre", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      response.json().then((js) => {
        console.log(js);
        let refs = [];
        if (js) {
          for (let r of js) {
            refs.push({
              Date: r.Time.slice(0, 10),
              ReferTo:
                r.LoFirstName + " " + r.LoLastName + " (" + r.LoEmail + ")",
              BorrowerName: r.LoanName,
              LoanID: r.LoanID,
              Status: r.Status,
            });
          }
        }
        setReferrals(refs);
      });
    });
  };

  useEffect(() => {
    getReferrals();
  }, []);

  return (
    <div>
      {referrals.length === 0 ? (
        <h5 className="mt-5">You haven't make any referral yet.</h5>
      ) : (
        <ToolkitProvider
          bootstrap4
          keyField="BorrowerName"
          data={referrals}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <Row>
                <Col>
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton {...props.searchProps} />
                </Col>
              </Row>

              <BootstrapTable
                id="referrals"
                bootstrap4
                bordered={false}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                })}
                {...props.baseProps}
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
};
