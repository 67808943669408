import React from 'react';
import { Tooltip } from 'antd';

const TruncatedText = ({ text, maxLines, maxWidth }) => {
  const divRef = React.useRef(null);
  
  const divStyle = {
    maxHeight: maxLines * 1.5 + 'em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': maxLines,
    '-webkit-box-orient': 'vertical',
  };
  const maxLength = (maxLines * maxWidth) - 3; 

  const truncatedText = React.useMemo(() => {
    // Approximate calculation based on line height
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  }, [text, maxLines]);

  if (text.length <= maxLength) {
    return <div>{text}</div>
  }

  return (
    <Tooltip
      title={text}
      placement="top"
    >
      <div ref={divRef} style={divStyle}>
        {truncatedText}
      </div>
    </Tooltip>
  );
};

export default TruncatedText;
