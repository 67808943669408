import React, { useReducer } from "react";

import classes from '../CustomizationPreview.module.css';

const reducer = (state, action) => {
  switch (action.type) {
    case 'button':
      return { ...state, button: action.payload };
    case 'input':
      return { ...state, input: action.payload };
    case 'outline':
      return { ...state, outline: action.payload };
    case 'outline2':
      return { ...state, outline2: action.payload };
    default:
      return { ...state };
  }
}

const Content = props => {

  const [state, dispatch] = useReducer(reducer, { button: false, input: false });

  const { colors, logo } = props;

  const btnOutline = <a
    className={classes.btnOutline}
    onMouseEnter={() => dispatch({ type: 'outline', payload: true })}
    onMouseLeave={() => dispatch({ type: 'outline', payload: false })}
    style={state.outline ? {
      backgroundColor: state.outline ? colors.primaryColor : 'transparent',
      borderColor: state.outline ? colors.primaryColor2 : colors.primaryColor,
      color: colors.primaryColorText,
      textDecoration: 'none',
    } : { color: colors.primaryColor, borderColor: colors.primaryColor, }}
  >
    Edit
  </a>;

  const btnOutline2 = <a
    className={classes.btnOutline}
    onMouseEnter={() => dispatch({ type: 'outline2', payload: true })}
    onMouseLeave={() => dispatch({ type: 'outline2', payload: false })}
    style={state.outline2 ? {
      backgroundColor: state.outline2 ? colors.primaryColor : 'transparent',
      borderColor: state.outline2 ? colors.primaryColor2 : colors.primaryColor,
      color: colors.primaryColorText,
      textDecoration: 'none',
    } : { color: colors.primaryColor, borderColor: colors.primaryColor, }}
  >
    Edit
  </a>;

  return <div className={classes.content}>

    <div className={classes.card} style={{ boxShadow: `${colors.primaryColor3} 0px 0px 10px 0px` }}>
      <div className={classes.cardHeader} style={{ backgroundColor: colors.paleColor, color: colors.paleColorText }}>
        <input type='checkbox' />
        <p>Jumbo ?,  Fixed rate, 30 years</p>
        <span style={{ color: colors.paleColorText }}><i className="fas fa-trash-alt"></i> Remove choice</span>
      </div>
      <div className={classes.cardContent}>
        <div className={classes.cart}>
          <p>Rate / APR</p>
          <p>3.625% / 3.64%</p>
        </div>
        <div className={classes.cart}>
          <p>Mo. Payment</p>
          <p>$5,141.00</p>
        </div>
        <div className={classes.cart}>
          <p>Closing Cost</p>
          <p>$2,236.59</p>
        </div>
        <div className={classes.cart}>
          <p>Cash to close</p>
          <p>$394,903.59</p>
        </div>
        <div className={classes.cart}>
          <p>Remaining credits</p>
          <p>$0.00</p>
        </div>
      </div>
      <div className={classes.cardFooter}>
        <p>Reserves required: 12 months</p>
        <p>Lender credits ? :-0.382%</p>
        <p className={classes.justifyEnd} style={{ color: colors.primaryColor }}><i className="fas fa-search-plus mr-1"></i> Cost Breakdown</p>
      </div>
    </div>


    <div className={classes.flexRow}>
      <a
        onMouseEnter={() => dispatch({ type: 'button', payload: true })}
        onMouseLeave={() => dispatch({ type: 'button', payload: false })}
        className={classes.btn}
        style={{
          backgroundColor: state.button ? colors.primaryColor2 : colors.primaryColor,
          color: state.button ? colors.primaryColor2Text : colors.primaryColorText,
          borderColor: colors.primaryColor2
        }}
      >
        Example
      </a>
      <div className={classes.loanTerm} style={{ marginLeft: '1rem' }}>
        <div className={classes.loanTermHeader} style={{ backgroundColor: colors.paleColor, color: colors.paleColorText }}>Loan Terms</div>
        <ul>
          <li><input type='radio' name='example' id='30' /> <label htmlFor="30">30 years</label></li>
          <li><input type='radio' name='example' id='25' /> <label htmlFor="25">25 years</label></li>
          <li><input type='radio' name='example' id='20' /> <label htmlFor="20">20 years</label></li>
          <li><input type='radio' name='example' id='15' /> <label htmlFor="15">15 years</label></li>
          <li><input type='radio' name='example' id='10' /> <label htmlFor="10">10 years</label></li>
        </ul>
      </div>

      <div className={classes.spinner}>
        <img alt="spinner" className={classes.loader} src={`data:image/png;base64, ${logo}`}></img>
      </div>

      <input
        onFocus={() => dispatch({ type: 'input', payload: true })}
        onBlur={() => dispatch({ type: 'input', payload: false })}
        className={classes.inputExample}
        style={state.input ? {
          outline: 'none',
          borderColor: colors.primaryColor,
          boxShadow: `0 0 5px ${colors.primaryColor3}`,
        } : {}}
        type="text"
        value=''
        onChange={() => { }}
      />

    </div>


    <div className="text-left mt-2 mb-4 pt-2 ">
      <p style={{ color: `${colors.primaryColor2}`, fontSize: '26px', fontWeight: 700 }}>Please Review Your Income and Assets </p>
      <div className="viewport">
        <div className="text-left sectionquestion">Income:</div>
        <div>
          <div className="mt-4">
            <span> Marisol  Testcase</span>
            <span>  is currently employed at <b>Cisco</b>,3200 Senter Road, San Jose, CA 95111-1332 as <b>Financial Manager</b> {btnOutline}</span>
            <div>Employment gross <b>monthly</b> income: <b>$24,222.00</b> (<b>yearly</b> income of <b>$290,664.00)</b>{btnOutline2}
            </div>
          </div>
        </div>
      </div>
    </div>


  </div >
}

export default Content;