import React, { useState, useEffect, useRef } from "react";
import "./Properties.css";
import ListGroup from "react-bootstrap/ListGroup";
import { Row, Col, Form, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AddNewResources from "./AddNewProperties";
import CurrentResources from "./CurrentProperties";

const { SearchBar, ClearSearchButton } = Search;

const Properties = (props) => {
  return (
    <Tabs defaultActiveKey="current" unmountOnExit="true" transition={false} id="resourcestabs">
      <Tab style={{ height: '100%' }} eventKey="current" title="Manage Properties">
        <CurrentResources />
      </Tab>
      <Tab style={{ height: '100%' }} eventKey="createnew" title="Add New Properties">
        <AddNewResources />
      </Tab>
    </Tabs>
  )
};
export default Properties;
