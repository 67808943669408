import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../App.css";
import "./RateQuote.css";
import * as com from "../../Common.js";
import NumericalInput, {CommaizedInput}  from "../../NumericalInput";
import { withRouter } from "react-router-dom";
import IneligibleProductsModal from "./IneligibleProducts";
import AllRatesModal, { AllRatesTable } from "./NewAllRates";
import ReportIssuesModal from "./ReportIssues";
import { YellowWarning } from "../../Common/Warnings";
import { checkAccess } from "../../Auth";
import { UR_LoanOfficer, UR_RealEstateAgent } from "../../State";
import {Radio, Skeleton} from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";
import Lenders from "../../Management/Lenders";

const DEFAULT_LOAN_TERM = 30; //year

let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};

const RateQuoteLOVersion = (props) => {
  let customerid = com.getCustomerId();

  const [emptydti,setEmptyDTI] = useState(false);
  const [emptymonthlyIncome,setEmptymonthlyIncome] = useState(false);
  const [formContainerHeight, setFormContainerHeight] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [extended, setExtended] = useState(true);
  const [emptymonthlyDebt,setEmptymonthlyDebt] = useState(false);
  const [resultContainerWidth,setResultContainerWidth] = useState("40%");
  const [formContainerWidth,setFormContainerWidth] = useState("60%");
  const [currentTargetbutton,setCurrentTargetbutton] = useState('rate');
  const [targetprice,setTargetPrice] = useState(3000);
  const [targetrate,setTargetRate] = useState(6.5);
  const [radiovalue,setRadioValue] = useState("purchase");
  const [stdproducts,setStdProducts] = useState([
    {name:"Agency",status: "checked"},
    {name:"Non-agency",status: "checked"},
    {name:"FHA",status: "checked"},
    {name:"VA",status: "unchecked"},
    {name:"USDA",status: "unchecked"},
    {name:"Non-QM",status: "unchecked"},
  ]);
  // const [stdproducts,setStdproducts] = useState(["agency","non-agency","fha"]);
  const [assets, setAssets] = useState("fullassets");
  const [incomeCondition, setIncomeCondition] = useState("fullincome");
  const [employment,setEmployment] = useState("Employed");
  const [emptyLtv, setEmptyLtv] = useState(false) 
  const [emptyDownpay,setEmptyDownpay] = useState(false) 
  const [emptyPropertyVal,setEmptyPropertyVal] = useState(false) 
  const [notmodified,setNotmodified] = useState(true);
  const [loanLimits, setLoanLimits] = useState({});
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");
  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("ALAMEDA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [dti, setDTI] = useState(30);
  const [monthlyIncome, setMonthlyIncome] = useState(50000);
  const [monthlyDebt, setMonthlyDebt] = useState(1000);
  const [estOtherCosts, setEstOtherCosts] = useState(3195);
  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [lockDay, setLockDay] = useState(30);
  const [citizenship, setCitizenship] = useState("US_CITIZENS");
  const [visaType, setVisaType] = useState("H_1B");
  const [numberOfOwnedProperties, setNumberOfOwnedProperties] = useState(1);
  const [currentJobInMonths, setCurrentJobInMonths] = useState(24);
  const [secondMortageAmount, setSecondMortgageAmount] = useState(0);
  const [escrowWaiver, setEscrowWaiver] = useState(false)
  const [selfEmployed, setSelfEmployed] = useState(false)
  const [rentalIncomeUsed, setRentalIncomeUsed] = useState(false)
  const [firstTimeHomeBuyer, setfirstTimeHomeBuyer] = useState("Yes")
  const [appraisalWaiver, setAppraisalWaiver] = useState(false)
  const [rsuAsIncome, setRsuAsIncome] = useState(false)
  const [giftFundUsed, setGiftFundUsed] = useState(false)
  const [workForSpecialBusiness, setWorkForSpecialBusiness] = useState(false)
  const [nonOccupancyCoBorrower, setNonOccupancyCoBorrower] = useState(false)
  const [borrowerPaidCompensation, setBorrowerPaidCompensation] = useState(false)
  const [borrowerPaidCompensationAmount, setBorrowerPaidCompensationAmount] = useState(0)
  
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(6);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [ineligibleProducts, setIneligibleProducts] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [showReportIssues, setShowReportIssues] = useState(false);
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [loProfile, setLOProfile] = useState({});

  const [message, setMessage] = useState("");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [showFileModal, setShowFileModel] = useState(false);
  const handleCloseFileModal = () => setShowFileModel(false);
  const handleShowFileModal = () => setShowFileModel(true);
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);

  const [showEditCostDetails, setShowEditCostDetails] = useState(false);
  const handleEditCostDetailsModal = () => setShowEditCostDetails(false);
  const [cannotshop, setCannotshop] = useState([
    { name: "Appraisal Fee", value: 750 },
    { name: "Credit Report", value: 65 },
    { name: "Flood Certification", value: 10 },
    { name: "HOA Certification Fee", value: 350 },
    { name: "Lender Doc Prep", value: 75 },
    { name: "Tax Service Fee", value: 80 },
  ]);
  const [canshop, setCanshop] = useState([
    { name: "Pest Inspection", value: 175 },
    { name: "Title-Closing/Escrow Fee", value: 475 },
    { name: "Title-Courier Fee", value: 35 },
    { name: "Title-Endorsement Fee", value: 25 },
    { name: "Title-Lender's Title Insurance", value: 645 },
    { name: "Title-Loan Tie in Fee", value: 280 },
    { name: "Title-Notary Fee", value: 200 },
    { name: "Title-Processing Fees to Escrow", value: 15 },
    { name: "Title-Wire Fee to Title", value: 15 },
  ]);
  const [connectedLendersMap, setConnectedLendersMap] = useState({});

  let getHeight = () => {
    return viewportHeight-140
  }

  const handlePurposechange = (val) =>{
    setPurpose(val);
    console.log(val);
  };

  let preselectedTermOptions = [
    { name: "Fixed 30 Yr", key: "fixed30" },
    { name: "Fixed 15 Yr", key: "fixed15" },
    { name: "ARM 5/6", key: "arm56" },
  ];

  let fileSuffixMap = {
    Fairway: "pdf",
    HomePoint: "xlsx",
    PRMG: "xlsx",
    AmeriHome: "xlsx",
    HomePointDelegated: "xlsx",
    FifthThirdBank: "xls",
    RocketPro: "xlsx",
    USBank: "pdf",
    PRMG: "xls",
    Caliber: "pdf",
    LoanDepot: "pdf",
    Amwest: "pdf",
    DivineMortgage: "pdf",
    LoanStore: "pdf",
    NewWaveLending: "pdf",
    WholesaleMortgageLender: "pdf",
    ResiCentral: "pdf",
    Provident: "csv",
    GMCCCelebrities : "xlsx",
    FlagStar : "xlsx",
    PlanetHome : "xlsx",
  };

  // useEffect(() => {
  //   console.log(showIneligible, message, ineligibleProducts);
  // });

  useEffect(() => {
    measureFormContainerHeight();

    window.addEventListener("resize", measureFormContainerHeight);

    return () => {
      window.removeEventListener("resize", measureFormContainerHeight);
    }
  },[]);

  useEffect(() => {
    // Function to update viewport height when the window is resized
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth)
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [selectedTerms, setSelectedTerms] = useState(preselectedTermOptions);
  const underwritingOptions = [
    { label: "DU", value: "DU" },
    { label: "LP", value: "LP" },
    { label: "Manual", value: "Manual" },
  ];
  const [selectedAUS, setSelectedAUS] = useState(underwritingOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.round((getLoanAmount() / parseInt(propertyValue)) * 100);
    let cltv =  Math.round(((getLoanAmount() + parseInt(secondMortageAmount)) / parseInt(propertyValue)) * 100);
    let hcltv =  Math.round(((getLoanAmount() + parseInt(secondMortageAmount))/ parseInt(propertyValue)) * 100);
    // let cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
    // let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
    return [ltv, cltv, hcltv];
  };

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };

  let downloadRatesheet = (loan) => {
    let s2ab = (s) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    let blob = new Blob([s2ab(atob(loan.FileB))], { type: "" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    if (fileSuffixMap[loan.Lender] == undefined) {
      handleShowFileModal();
      return;
    }

    link.download = loan.Lender + "_Ratesheet." + fileSuffixMap[loan.Lender];
    link.click();
  };


  let handleEditCostDetails = () => {
    if (showEditCostDetails) {
      setShowEditCostDetails(false);
    } else {
      setShowEditCostDetails(true);
    }
  };

  let getProducts = () => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;

    setMessage("");

    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();

    if (state != "TEXAS") _county = county;

    let aus = []
    for (let a of selectedAUS) {
      aus.push(a.label)
    }

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: parseFloat(estOtherCosts),
      pmi: pmi,
      getFiles: false,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
      firsttimehomebuyer: firstTimeHomeBuyer==="Yes" ? true : false,
      selfemployed: selfEmployed,
      citizenship: citizenship,
      visaType: visaType,
      numberOfOwnedProperties: numberOfOwnedProperties,
      escrowWaiver: escrowWaiver,
      appraisalWaiver: appraisalWaiver,
      currentJobInMonths: currentJobInMonths,
      rsuAsIncome: rsuAsIncome ? 10 : -1,
      giftFundUsed: giftFundUsed,
      familyRelatedOrSmallBusiness: workForSpecialBusiness,
      borrowerPaidCompensationAmount: borrowerPaidCompensationAmount,
      dti:dti,
    };
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    let url = "/data/lopriceengine/" + customerid
    if (checkAccess([UR_LoanOfficer])) {
      url = "/agent/priceengine"
      body["isre"]= false
    }
    fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          setMessage("An internal error has occured");
          return;
        }
        response.json().then((pr) => {
          setConnectedLendersMap(pr.connected_lender_map)
          let products = pr.result;
          if (pr.ineligible_reasons != undefined) {
            setIneligibleProducts(pr.ineligible_reasons);
          } else {
            setIneligibleProducts({});
          }
          if (pr.message != undefined) {
            setMessage(pr.message);
          } else {
            setMessage("");
          }
          if (products === undefined) {
            setNoProducts(true)
            setShowIneligible(true);
            if(pr.message ==="can not get lender fee data"){
              setShowIneligible(false);
              setShowAddLendersModal(true);
            }
            setLoading(false);
            return;
          } else {
            setNoProducts(false)
          }
          products.forEach((product) => {
            // console.log("prd", product);
            let lender = product.Lender;
            let fb = product.FileB;
            product.ProductRatesCosts.forEach((pr) => {
              let commonproduct = pr.product;
              let adjustments = pr.appliedAdjustments;
              // if (commonproduct.Term !== loanTerm) return;
              let ausengine = pr.AUSEngine;
              pr.rateCosts.forEach((rate) => {
                let pdkt = {
                  Lender: lender,
                  ...commonproduct,
                  Aus: ausengine,
                  ...rate,
                  Adjustments: adjustments,
                  FileB: fb,
                };
                
                if (rate.lockDay !== lockDay) {
                  return;
                }
                let commonAUS = selectedAUS.filter(val => pdkt.Aus.includes(val.label));
                if (commonAUS.length === 0) {
                  return
                }
                const filterPdkt = () => {
                  if (customerid==="siliconcitymortgage" && pdkt.Mtype.includes("FHA")) {
                    return false
                  }
                  return true
                }

                /// SOMRTHING ELSE HERE
                pdkt.loanAmount = getLoanAmount();
                if (filterPdkt()) {
                  if (pdkt.Arm != null) {
                    if (pdkt.Arm.fixedperiod == 5) {
                      arm56.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 7) {
                      arm76.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 10) {
                      arm106.push(pdkt);
                    }
                  } else {
                    if (pdkt.Term == 30) {
                      fixed30.push(pdkt);
                    } else if (pdkt.Term == 25) {
                      fixed25.push(pdkt);
                    } else if (pdkt.Term == 20) {
                      fixed20.push(pdkt);
                    } else if (pdkt.Term == 15) {
                      fixed15.push(pdkt);
                    } else if (pdkt.Term == 10) {
                      fixed10.push(pdkt);
                    }
                  }
                }
              });
            });
          });
          
          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            const filteredPrds = products.filter((p) => {
              return p.lender_credit > 0;
            });

            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              // console.log(filteredPrds[i]);
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0;
            let bestPoints = 1000000;
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 1500) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 1500);
                bestIndex = i;
              }
            }
            return bestIndex;
          };

          allRates = {};
          allRates.fixed30 = fixed30;
          allRates.fixed25 = fixed25;
          allRates.fixed20 = fixed20;
          allRates.fixed15 = fixed15;
          allRates.fixed10 = fixed10;
          allRates.arm56 = arm56;
          allRates.arm76 = arm76;
          allRates.arm106 = arm106;
          // console.log("allRates---")
          // console.log(allRates)
          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);

          // console.log("allRates=====")
          // console.log(allRates)

          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setLoading(false);
          // console.log(allRates);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          // console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    };


  const ref = useRef();
  const formContainerRef = useRef(null);

  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });

  const measureFormContainerHeight = () => {
    if (formContainerRef.current) {
      setFormContainerHeight(formContainerRef.current.offsetHeight);
    }
  };


  useEffect(() => {
    getProducts();
    getLoanLimits();
  }, []);

  useEffect(() => {
    if (!showSubscribe) setShowRatePreview(false);
  }, [showSubscribe, showRatePreview]);

  useEffect(() => {
    setMonthlyDebt(parseInt(monthlyIncome * dti / 100));
  }, [monthlyIncome, dti]);

  let showCounties = () => {
    let options = [<option value="">Select your county</option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);
      
      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x)}</option>);
      });
    }
    return options;
  };

  let showStates = () => {
    let options = [<option value="">Select your state</option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  }

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
    if (parseInt(downpayment) === 0) {
      setValidated(true);
      alert("Downpayment can't be zero");
      return false;
    }
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      alert("Downpayment can't be bigger than purchase price");
      return false;
    }
    if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
      setValidated(true);
      alert("FICO score should be in the range of 300 - 1000");
      return false;
    }
    setNotmodified(false);
    getProducts();
    setExtended(false)
    return false;
  };

  let changeBalancefixed30 = (v) => {
    setIndexFixed30(v);
  };
  let changeBalancefixed25 = (v) => {
    setIndexFixed25(v);
  };
  let changeBalancefixed20 = (v) => {
    setIndexFixed20(v);
  };
  let changeBalancefixed15 = (v) => {
    setIndexFixed15(v);
  };
  let changeBalancefixed10 = (v) => {
    setIndexFixed10(v);
  };
  let changeBalancearm56 = (v) => {
    setIndexArm56(v);
  };
  let changeBalancearm76 = (v) => {
    setIndexArm76(v);
  };
  let changeBalancearm106 = (v) => {
    setIndexArm106(v);
  };

  let onSelectTerms = (selectedList, selectedItem) => {
    setSelectedTerms(selectedList);
  };

  let onRemoveTerms = (selectedList, removedItem) => {
    setSelectedTerms(selectedList);
  };

  let onSelectAUS = (selectedList, selectedItem) => {
    setSelectedAUS(selectedList);
  };

  let onRemoveAUS = (selectedList, removedItem) => {
    setSelectedAUS(selectedList);
  };

  let handleChangeUnderwritingOptions = (e) => {
    setSelectedAUS(e)
  }

  let onMoreOptions = (e) => {
    if (showMoreOptions) {
      setShowMoreOptions(false);
    } else {
      setShowMoreOptions(true);
    }
  };

  let handleCheckProduct = (name) =>{
    let i=parseInt(name)
    let newproducts=[...stdproducts];
    if(newproducts[i].status == "checked"){
      newproducts[i].status = "unchecked"
    }else{
      newproducts[i].status = "checked"
    }
    setStdProducts(newproducts);
    console.log(stdproducts);
  };

  let handleClearProducts = () => {
    let newstdproducts=[...stdproducts];
    newstdproducts.forEach(newstdproduct=>newstdproduct.status="unchecked");
    setStdProducts(newstdproducts);
  };

  let getEditCostsModal = () => {
    let updateTotal = (f1, f2) => {
      let t = 0;
      let all = [...f1, ...f2];
      for (let fe of all) {
        t += parseFloat(fe.value);
      }
      setEstOtherCosts(t);
    };

   
    let handleCannotshop = (i, e) => {
      let newFormValues = [...cannotshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };

    let addCannotshop = () => {
      setCannotshop([...cannotshop, { name: "", value: "" }]);
    };

    let removeCannotshop = (i) => {
      let newFormValues = [...cannotshop];
      newFormValues.splice(i, 1);
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };
    let handleCanshop = (i, e) => {
      let newFormValues = [...canshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    let addCanshop = () => {
      setCanshop([...canshop, { name: "", value: "" }]);
    };

    let removeCanshop = (i) => {
      let newFormValues = [...canshop];
      newFormValues.splice(i, 1);
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    return (
      <Modal
        show={showEditCostDetails}
        onHide={handleEditCostDetailsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h3>
              <b>Services you cannot shop for</b>
            </h3>
            {cannotshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCannotshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCannotshop()}>
              Add More Fees
            </Button>
            <h3>
              <b>Services you can shop for</b>
            </h3>
            {canshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCanshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCanshop()}>
              Add More Fees
            </Button>
          </Form>
          <h3>
            <b>Total estimated fees: ${com.commaize(estOtherCosts)}</b>
          </h3>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={handleEditCostDetailsModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditCostDetailsModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const updateProperValue = (e) => {
    let val = e.target.value
    setEmptyLtv(false)
    if (val==="") {
      setPropertyValue(100000)
      setEmptyPropertyVal(true)
    } else {
      setPropertyValue(parseInt(val))
      setEmptyPropertyVal(false)
    }
  }
  const updateMonthlyIncome = (e) => {
    let val = e.target.value
    if (val==="") {
      setEmptymonthlyIncome(true)
      setMonthlyIncome(0)
    } else {
      setEmptymonthlyIncome(false)
      setMonthlyIncome(parseInt(val))
    }
  }

  const updateMonthlyDebt = (e) => {
    let val = e.target.value
    if (val==="") {
      setEmptymonthlyDebt(true)
      setMonthlyDebt(0)
    } else {
      setEmptymonthlyDebt(false)
      setMonthlyDebt(parseInt(val))
    }
  } 

  const updateDownpayment = (e) => {
    let val = e.target.value
    setEmptyLtv(false)
    if (val==="") {
      setDownpayment(Math.round(propertyValue * 0.03))
      setEmptyDownpay(true)
    } else {
      setDownpayment(parseInt(val))
      setEmptyDownpay(false)
    }
  }
  const updateLTV = (e) => {
    let val = e.target.value
    setEmptyDownpay(false)
    if (val==="") {
      setDownpayment(Math.round(propertyValue))
      setEmptyLtv(true)
    } else {
      let ltv = parseInt(val)
      setEmptyLtv(false)
      if (ltv <= 0) { // TBD
        setDownpayment(Math.round(propertyValue))
      } else if (ltv > 97) {
        setDownpayment(Math.round(propertyValue * 0.03))
      } else {
        if(purpose==="Purchase"){
          setDownpayment(Math.round(propertyValue * (1 - 0.01 * ltv)))
        }
        else{
          setDownpayment(Math.round(propertyValue * (0.01 * ltv))) 
        }
      }
    }
  }

  const updateDTI = (e) => {
    let val = e.target.value
    setEmptyDTI(false)
    if (val==="") {
      setDTI(0)
      setEmptyDTI(true)
    } else {
      setEmptyDTI(false)
      setDTI(parseInt(val))
      // setMonthlyDebt(parseInt(val) * 0.01 * parseInt(monthlyIncome))
    }
  }
  const handleChangeEmployment = (e) => {
    setEmployment(e.target.value)
    if (e.target.value==="Selfemployed") {
      setSelfEmployed(true)
    }
    // if (e.target.value==="Unemployed") {
    //   setIncomeCondition("noincome")
    //   setMonthlyIncome(0)
    // }
    // if (e.target.value==="Employed") {
    //   setIncomeCondition("fullincome")
    //   setMonthlyIncome(10000)
    // }
  }
  const getDownpaymentDomain = () => {
    if (purpose === "Purchase") {
      return [propertyValue*0.03, propertyValue*0.6]
    } else {
      return [0, propertyValue*0.97]
    }
  }

  const radioOptions=[
    {
      label:"Purchase",
      value:"Purchase"
    },
    {
      label:"Refinance",
      value:"RateTermRefi"
    },
    {
      label:"Cashout",
      value:"CashOutRefi"
    }
  ]


  const onRadioChange=({target:{value}}) =>{
    setRadioValue(value);
    setPurpose(value);
  }
  const handleExpandButtonClick = () => {
    setExtended(!extended);
  }

  const handleManagementDirect = () => {
    if(window.location.hostname.includes("localhost")){
      window.location.href = "http://localhost:9262/app/management"
    }else{
      window.location.href = "https://agent.zeitro.com/app/management"
    }
  }

  return (
    <div
      id="contain"
      ref={ref}
      style={{height:props.isMobile?"":getHeight(), backgroundColor:"#F6F6F6",color: "black", display:"flex",flexDirection: props.isMobile?"column":"row", flexWrap:"nowarp", alignItems:"flex-start"}}
      className="w-100 text-center"
    >
      <Container ref={formContainerRef} className={props.isMobile?"formContainer-mobile":extended===true?"formContainer-extended p-1":"formContainer p-1"}  style={{borderRight:"1px solid #ECECEC",backgroundColor:"#F4F4F487",height: props.isMobile?"":getHeight()}}>
        {!notmodified &&<div style={{display:"flex",alignItems:"center",justifyContent:"space-between",paddingLeft:"20px",paddingRight:"10px", marginTop:"15px"}}>
          <div style={{fontSize:"16px",color:"#6E6E70",display:"flex",alignItems:"center"}}>
            <img src="/images/allfiltersIcon.png" style={{width:"20px",height:"20px"}}/>
            <div>All Filters</div>
          </div>
          {extended && <img src="/images/closeExpand.png" className="minimizeFormIcon" onClick={handleExpandButtonClick} />}
          {!extended && <CaretRightOutlined className="expandFormIcon" onClick={handleExpandButtonClick} />}
        </div>}
        {extended===false?(<div style={{marginLeft:"10px", marginRight:"10px",paddingTop:'10px'}}>
          <Form
            style={{maxWidth:"1050px",display:"flex",flexDirection:"column",justifyContent:"space-between",height:formContainerHeight-60}}
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
          <div style={{height:formContainerHeight-120,overflowY:"auto"}}> 
            <Row >
            <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="propertytype" className="text-left ">
                  <Form.Label style={{ marginBottom: "10px", fontWeight:"600", fontSize:"14px",lineHeight:"20px" }}>
                    Purpose
                  </Form.Label>
                  <Form.Control
                    name="purpose"
                    as="select"
                    size="sm"
                    type="text"
                    required
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                  >
                    {radioOptions.map((option) => {
                      return <option value={option.value}>{option.label}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="propertytype" className="text-left ">
                  <Form.Label style={{ marginBottom: "10px", fontWeight:"600", fontSize:"14px",lineHeight:"20px" }}>
                    Property Type
                  </Form.Label>
                  <Form.Control
                    name="propertytype"
                    as="select"
                    size="sm"
                    type="text"
                    required
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                  >
                    <option value="">Property type</option>
                    <option value="singlefamilydetached">
                      Single Family Home, Detached
                    </option>
                    <option value="singlefamilyattached">
                      Single Family Home, Attached
                    </option>
                    <option value="condominiumdetached">
                      Condominium, Detached
                    </option>
                    <option value="condominium">Condominium, Attached</option>
                    <option value="pudattached">
                      Planned Unit Development, attached
                    </option>
                    <option value="puddetached">
                      Planned Unit Development, detached
                    </option>
                    <option value="twotofourfamily">
                      2 to 4 Unit Multifamily
                    </option>
                    <option value="manufacturedhome">Manufactured Home</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {propertyType === "twotofourfamily" ? (
                <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                  <Form.Group controlId="propertytype" className="text-left ">
                    <Form.Label 
                      className="text-left "
                      style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                    >
                      Number of Units
                    </Form.Label>
                    <Form.Control
                      name="propertytype"
                      as="select"
                      size="sm"
                      type="text"
                      required
                      value={units}
                      onChange={(e) => setUnits(e.target.value)}
                    >
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md={12}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="occupancy" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Occupancy
                  </Form.Label>
                  <Form.Control
                    name="occupancy"
                    as="select"
                    size="sm"
                    defaultValue={occupancy}
                    onChange={(e) => {
                      onOccupancy(e.target.value);
                    }}
                  >
                    <option value="principal">Primary Residence</option>
                    <option value="secondhome">Second Home</option>
                    <option value="investment">Investment</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group controlId="firstTimeHomeBuyer" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    First time home buyer
                  </Form.Label>
                  <Form.Control
                    name="firstTimeHomeBuyer"
                    as="select"
                    size="sm"
                    defaultValue={firstTimeHomeBuyer}
                    onChange={(e) => {
                      setfirstTimeHomeBuyer(e.target.value);
                    }}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            
            
              <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px",  fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>State</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                    required
                    value={state}
                  >
                    {showStates()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {state !== "TEXAS" ? (
                <Col md={12} style={{marginBottom:"0px", paddingRight:"10px"}} >
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                      County
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      id="country"
                      name="country"
                      onChange={(e) => setCounty(e.target.value)}
                      value={county}
                      required
                    >
                      {showCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                    Credit Score
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="input"
                    onChange={(e) => e.target.value===""? setFico(0) : setFico(parseInt(e.target.value))}
                    value={fico}
                    placeholder="Enter credit score"
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
            
            
              <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                    {purpose === "Purchase"
                      ? "Property Value"
                      : "Home Value"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div style={{borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingRight:"8px"}}>$</div>
                      <CommaizedInput style={{border:"none"}} value={emptyPropertyVal?"":propertyValue} onChange={updateProperValue} />

                    {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div> */}
                  </div>
                </Form.Group>

              </Col>

              <Col md={12} style={{marginBottom:"0px", paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    {purpose === "Purchase"
                      ? "Down Payment / LTV"
                      : "Mortgage Balance / LTV"}
                  </Form.Label>
                  {/* <div className="rateQuoterFormSliderOutline"> */}
                  <Row>
                    <Col md={9} style={{paddingLeft:"0",paddingRight:"3px"}}>
                    <div style={{backgroundColor:"#ffffff",display:"flex", alignItems:"center",border:"1px solid #CFD4D9",borderRadius:"4px 2px 2px 4px"}}>
                    <div style={{marginLeft:"10px",borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingBottom:"13px",paddingRight:"8px"}}>$</div>
                    <CommaizedInput style={{border:"none"}} value={emptyDownpay?"":downpayment} onChange={updateDownpayment} />
                    </div>
                    </Col>
                    <Col md={3} style={{paddingLeft:"0",paddingRight:"0",display:"flex", alignItems:"center",backgroundColor:"#ffffff",border:"1px solid #CFD4D9",borderRadius:"2px 4px 4px 2px"}}>
                      <input className="ltv" type="number" style={{minWidth:"15px",border:"none",paddingLeft:"4px"}} value={emptyLtv?"":getLtv()[0]} onChange={updateLTV}/>
                      <div className="ltvSuffix"style={{marginRight:"2px"}}>%</div>
                    </Col>
                    </Row>
                </Form.Group>
              </Col>
              <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Current DTI
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline" style={{justifyContent:"space-between"}}>
                    <input className="dti" style={{height:"43px"}} type="number" value={emptydti?"":dti} onChange={updateDTI}/>
                    <div className="ltvSuffix">%</div>
                    {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[0, 100]} defaultValues={[dti]} onChange={(e)=>{setDTI(e[0])}} onUpdate={setDTI} step={1}/></div> */}
                  </div>
                </Form.Group>
              </Col>

              {!(incomeCondition ==="noincome") && <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                      Monthly Income
                    </Form.Label>
                    <div className="rateQuoterFormSliderOutline">
                      <div style={{borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingRight:"8px"}}>$</div>
                        <CommaizedInput style={{border:"none"}} value={emptymonthlyIncome?"":monthlyIncome} onChange={updateMonthlyIncome} />

                      {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div> */}
                    </div>
                  </Form.Group>
              </Col>}

            <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                      Lock Day
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) => setLockDay(parseInt(e.target.value))}
                      value={lockDay}
                      required
                    >
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                      <option value="60">60</option>
                      <option value="75">75</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
           

            {showMoreOptions && (
              <>
                 <Col md={12}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="employment" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Employment
                  </Form.Label>
                  <Form.Control
                    name="employment"
                    as="select"
                    size="sm"
                    defaultValue={employment}
                    onChange={handleChangeEmployment}
                  >
                    <option value="Employed">Employed</option>
                    <option value="Selfemployed">Self-Employed</option>
                    {/* <option value="Unemployed">Unemployed</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>
              {!(employment === "Unemployed") &&  <Col md={12}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="incomeCondition" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Income
                  </Form.Label>
                  <Form.Control
                    name="incomeCondition"
                    as="select"
                    size="sm"
                    defaultValue={incomeCondition}
                    onChange={(e) => {
                      setIncomeCondition(e.target.value);
                    }}
                  >
                    <option value="fullincome">Full income</option>
                    <option value="partialincome">Partial income</option>
                    {/* <option value="noincome">No income</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>}
             
              {/* <Col md={12}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="assets" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Assets
                  </Form.Label>
                  <Form.Control
                    name="assets"
                    as="select"
                    size="sm"
                    defaultValue={assets}
                    onChange={(e) => {
                      setAssets(e.target.value);
                    }}
                  >
                    <option value="fullassets">Full Assets</option>
                    <option value="noassets">No assets</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              <Col md={12}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="assets" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Escrow
                  </Form.Label>
                  <Form.Control
                    name="escrow"
                    as="select"
                    size="sm"
                    defaultValue={escrowWaiver}
                    onChange={(e) => {
                      setEscrowWaiver(e.target.value);
                    }}
                  >
                    <option value={false}>Yes</option>
                    <option value={true}>No</option>
                  </Form.Control>
                </Form.Group>
              </Col>
               <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                    Second Loan Amount
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    value={secondMortageAmount}
                    required
                    onChange={(e) => setSecondMortgageAmount(e.target.value)}
                    min={0}
                    step={10000}
                    noarrow={true}
                  />
                </Form.Group>
              </Col>
              <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0px",fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                      Citizenship
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) => setCitizenship(e.target.value)}
                      value={citizenship}
                      required
                    >
                      <option value="US_CITIZENS">US Citizen</option>
                      <option value="PERMANENT_RESIDENT_ALIEN">
                        Permanet Resident Alien
                      </option>
                      <option value="NON_RESIDENT_ALIEN">
                        Non Resident Alien
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {citizenship !== "US_CITIZENS" && (
                  <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                    <Form.Group className="text-left ">
                      <Form.Label style={{ marginBottom: "0px",fontWeight:"600", fontSize:"14px",lineHeight:"20px"   }}>
                        Visa Type
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        onChange={(e) => setVisaType(e.target.value)}
                        value={visaType}
                        required
                      >
                        <option value="A_1"> A-1</option>
                        <option value="A_2"> A-2</option>
                        <option value="A_3"> A-3</option>
                        <option value="B_1"> B-1</option>
                        <option value="B_2"> B-2</option>
                        <option value="B_3"> B-3</option>
                        <option value="C_1"> C-1</option>
                        <option value="C_2"> C-2</option>
                        <option value="C_3"> C-3</option>
                        <option value="C_4"> C-4</option>
                        <option value="C_1D"> C-1D</option>
                        <option value="CP"> CP</option>
                        <option value="D_1"> D-1</option>
                        <option value="D_2"> D-2</option>
                        <option value="E_1"> E-1</option>
                        <option value="E_2"> E-2</option>
                        <option value="E_3"> E-3</option>
                        <option value="F_1"> F-1</option>
                        <option value="F_2"> F-2</option>
                        <option value="F_3"> F-3</option>
                        <option value="G_1"> G-1</option>
                        <option value="G_2"> G-2</option>
                        <option value="G_3"> G-3</option>
                        <option value="G_4"> G-4</option>
                        <option value="G_5"> G-5</option>
                        <option value="H_1A"> H-1A</option>
                        <option value="H_1B"> H-1B</option>
                        <option value="H_1B1"> H-1B1</option>
                        <option value="H_1C"> H-1C</option>
                        <option value="H_2"> H-2</option>
                        <option value="H_2A"> H-2A</option>
                        <option value="H_2B"> H-2B</option>
                        <option value="H_3"> H-3</option>
                        <option value="H_4"> H-4</option>
                        <option value="I"> I </option>
                        <option value="OR_1"> OR-1</option>
                        <option value="J_1"> J-1</option>
                        <option value="J_2"> J-2</option>
                        <option value="K_1"> K-1</option>
                        <option value="K_2"> K-2</option>
                        <option value="K_3"> K-3</option>
                        <option value="K_4"> K-4</option>
                        <option value="L_1"> L-1</option>
                        <option value="L_2"> L-2</option>
                        <option value="M_1"> M-1</option>
                        <option value="M_2"> M-2</option>
                        <option value="M_3"> M-3</option>
                        <option value="N_8"> N-8</option>
                        <option value="N_9"> N-9</option>
                        <option value="NACARA"> NACARA</option>
                        <option value="NATO_1_6"> NATO-1-6</option>
                        <option value="NATO_7"> NATO-7</option>
                        <option value="O_1"> O-1</option>
                        <option value="O_2"> O-2</option>
                        <option value="O_3"> O-3</option>
                        <option value="P_1"> P-1</option>
                        <option value="P_2"> P-2</option>
                        <option value="P_3"> P-3</option>
                        <option value="P_4"> P-4</option>
                        <option value="Q_1"> Q-1</option>
                        <option value="Q_2"> Q-2</option>
                        <option value="Q_3"> Q-3</option>
                        <option value="R_1"> R-1</option>
                        <option value="R_2"> R-2</option>
                        <option value="S_5"> S-5</option>
                        <option value="S_6"> S-6</option>
                        <option value="S_7"> S-7</option>
                        <option value="T_1"> T-1</option>
                        <option value="T_2"> T-2</option>
                        <option value="T_3"> T-3</option>
                        <option value="T_4"> T-4</option>
                        <option value="TD"> TD</option>
                        <option value="TN_1"> TN-1</option>
                        <option value="TN_2"> TN-2</option>
                        <option value="U_1"> U-1</option>
                        <option value="U_2"> U-2</option>
                        <option value="U_3"> U-3</option>
                        <option value="U_4"> U-4</option>
                        <option value="V_1"> V-1</option>
                        <option value="V_2"> V-2</option>
                        <option value="V_3"> V-3</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}
                 <Col md={12} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px",display:"flex"  }}>
                    Est. Fee 
                    <a
                      onClick={handleEditCostDetails}
                      style={{
                        marginLeft:"10px",
                        color: "blue",
                        textDecorationLine: "underline",
                      }}
                    >
                      <b>EDIT</b>
                    </a>
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    value={estOtherCosts}
                    required
                    onChange={(e) => setEstOtherCosts(e.target.value)}
                    min={0}
                    noarrow={true}
                  />
                </Form.Group>
              </Col>
              </>
              
            )}
           </Row>
           {showMoreOptions && (
              <div style={{marginBottom:"20px",paddingLeft:"15px"}}>
                
                  <Row style={{ marginBottom: "10px", fontWeight:"600", fontSize:"0.9em",lineHeight:"20px",textAlign:"left",alignItems:"center"}}>
                    <div>Standard Products</div>
                    <a
                     style={{marginRight:"30px",marginLeft:"10px", color: "blue",fontSize:"12px",textDecorationLine: "underline",lineHeight:"2px",fontWeight:"bolder"}}
                     onClick={handleClearProducts}
                     
                    >
                      Clear
                    </a>
                  </Row>
                  <Row>
                  {stdproducts.map((stdproduct,idx)=>(
                  <Button
                    className={stdproduct.status=="checked"?"stdProductsButtonChecked":"stdProductsButtonUnchecked"}
                    variant="outline" 
                    name={`${idx}`} 
                    onClick={(e)=>handleCheckProduct(e.target.name)}
                  >
                    {stdproduct.name}
                  </Button>
                  ))}
                  </Row>
              </div>
            )}

            <Row className="text-left" style={{paddingLeft:'5px'}}>
                <Button style={{paddingLeft:"0", color:"#325CEB",marginBottom: showMoreOptions?"40px":"40px"}}
                  variant="rateQuoterAdvancedOptions"
                  onClick={onMoreOptions}
                  size="sm"
                >
                  {showMoreOptions ? <>Less Options<img src="/images/blueMinusInCircleMark.png" style={{maxWidth:"20px", paddingLeft:"5px"}}/></> : <>Advanced Options<img src="/images/bluePlusInCircleMark.png" style={{maxWidth:"20px", paddingLeft:"5px"}}/></>}
                  
                </Button>{" "}
            </Row>
            </div>
            <div style={{borderTop:"#ECECEC 1px solid"}}>
              <Form.Row style={{fontSize:"16px", marginTop:"10px",paddingLeft:"0px", display:"flex", flexDirection:"column",justifyContent:"center"}}>
                <Col md={12} className="text-left d-flex">
                  <Button type="submit" size="sm" className="checkrates" style={{}}
                  >
                    Check rates
                  </Button>
                </Col>
              {/* </Form.Row> */}
              {Object.keys(ineligibleProducts).length > 0 && (
                // <Form.Row style={{fontSize:"16px", marginTop:"0px",paddingLeft:"0px"}}>
                    <Col md={12} className="text-left ">
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => setShowIneligible(true)}
                      style={{whiteSpace:"nowrap",width:"100%"}}
                    >
                      Check Ineligible Products
                    </Button>
                    </Col>
                    // </Form.Row>
                  )}
              </Form.Row>
            </div>
          </Form>
        </div>
        ):(
        <div style={{marginLeft:"10px", marginRight:"10px",paddingTop:"10px"}}>
          <Form
            style={{maxWidth:"1050px",display:"flex",flexDirection:"column",justifyContent:"space-between",height:notmodified?formContainerHeight-30:formContainerHeight-60}}
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
          <div style={{height:formContainerHeight-120,overflowY:"auto"}}>
          <div style={{textAlign:'left',marginBottom:"20px",marginTop:"10px",paddingLeft:"15px"}}>
                <Radio.Group
                  className="purposeRadiobutton"
                  options={radioOptions}
                  onChange={onRadioChange}
                  value={purpose}
                  optionType="button"
                  buttonStyle="solid"
                />
          </div>   
            <Row>
            <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="propertytype" className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px" }}>
                    Property Type
                  </Form.Label>
                  <Form.Control
                    name="propertytype"
                    as="select"
                    size="sm"
                    type="text"
                    required
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                  >
                    <option value="">Property type</option>
                    <option value="singlefamilydetached">
                      Single Family Home, Detached
                    </option>
                    <option value="singlefamilyattached">
                      Single Family Home, Attached
                    </option>
                    <option value="condominiumdetached">
                      Condominium, Detached
                    </option>
                    <option value="condominium">Condominium, Attached</option>
                    <option value="pudattached">
                      Planned Unit Development, attached
                    </option>
                    <option value="puddetached">
                      Planned Unit Development, detached
                    </option>
                    <option value="twotofourfamily">
                      2 to 4 Unit Multifamily
                    </option>
                    <option value="manufacturedhome">Manufactured Home</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {propertyType === "twotofourfamily" ? (
                <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                  <Form.Group controlId="propertytype" className="text-left ">
                    <Form.Label 
                      className="text-left "
                      style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                    >
                      Number of Units
                    </Form.Label>
                    <Form.Control
                      name="propertytype"
                      as="select"
                      size="sm"
                      type="text"
                      required
                      value={units}
                      onChange={(e) => setUnits(e.target.value)}
                    >
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="occupancy" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Occupancy
                  </Form.Label>
                  <Form.Control
                    name="occupancy"
                    as="select"
                    size="sm"
                    defaultValue={occupancy}
                    onChange={(e) => {
                      onOccupancy(e.target.value);
                    }}
                  >
                    <option value="principal">Primary Residence</option>
                    <option value="secondhome">Second Home</option>
                    <option value="investment">Investment</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group controlId="firstTimeHomeBuyer" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    First time home buyer
                  </Form.Label>
                  <Form.Control
                    name="firstTimeHomeBuyer"
                    as="select"
                    size="sm"
                    defaultValue={firstTimeHomeBuyer}
                    onChange={(e) => {
                      setfirstTimeHomeBuyer(e.target.value);
                    }}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            
          
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px",  fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>State</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                    required
                    value={state}
                  >
                    {showStates()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {state !== "TEXAS" ? (
                <Col md={4} style={{marginBottom:"0px", paddingRight:"10px"}} >
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                      County
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      id="country"
                      name="country"
                      onChange={(e) => setCounty(e.target.value)}
                      value={county}
                      required
                    >
                      {showCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                    Credit Score
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="input"
                    onChange={(e) => e.target.value===""? setFico(0) : setFico(parseInt(e.target.value))}
                    value={fico}
                    placeholder="Enter credit score"
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
            
            
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                    {purpose === "Purchase"
                      ? "Property Value"
                      : "Home Value"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div style={{borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingRight:"8px"}}>$</div>
                      <CommaizedInput style={{border:"none"}} value={emptyPropertyVal?"":propertyValue} onChange={updateProperValue} />

                    {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div> */}
                  </div>
                </Form.Group>

              </Col>

              <Col md={4} style={{marginBottom:"0px", paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    {purpose === "Purchase"
                      ? "Down Payment / LTV"
                      : "Mortgage Balance / LTV"}
                  </Form.Label>
                  {/* <div className="rateQuoterFormSliderOutline"> */}
                  <Row>
                      <Col md={8} xs={8} style={{paddingLeft:"0",paddingRight:"3px"}}>
                      <div style={{backgroundColor:"#ffffff",display:"flex", alignItems:"center",border:"1px solid #CFD4D9",borderRadius:"4px 2px 2px 4px"}}>
                      <div style={{marginLeft:"10px",borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingBottom:"13px",paddingRight:"8px"}}>$</div>
                      <CommaizedInput style={{border:"none"}} value={emptyDownpay?"":downpayment} onChange={updateDownpayment} />
                      </div>
                      </Col>
                      <Col md={4} xs={4} style={{paddingLeft:"0",paddingRight:"0",display:"flex", alignItems:"center",backgroundColor:"#ffffff",border:"1px solid #CFD4D9",borderRadius:"2px 4px 4px 2px"}}>
                        <input className="ltv" type="number" style={{minWidth:"15px",border:"none",paddingLeft:"4px"}} value={emptyLtv?"":getLtv()[0]} onChange={updateLTV}/>
                        <div className="ltvSuffix"style={{marginRight:"15px"}}>%</div>
                      </Col>
                    </Row>
                </Form.Group>
              </Col>
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Current DTI
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline" style={{justifyContent:"space-between"}}>
                    <input className="dti" style={{height:"43px"}} type="number" value={emptydti?"":dti} onChange={updateDTI}/>
                    <div className="ltvSuffix">%</div>
                    {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[0, 100]} defaultValues={[dti]} onChange={(e)=>{setDTI(e[0])}} onUpdate={setDTI} step={1}/></div> */}
                  </div>
                </Form.Group>
              </Col>

              {!(incomeCondition ==="noincome") && <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                    Monthly Income
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div style={{borderRight:"1px solid #CFD4D9",paddingTop:"13px",paddingRight:"8px"}}>$</div>
                      <CommaizedInput style={{border:"none"}} value={emptymonthlyIncome?"":monthlyIncome} onChange={updateMonthlyIncome} />

                    {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div> */}
                  </div>
                </Form.Group>
              </Col>}
            
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                      Lock Day
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) => setLockDay(parseInt(e.target.value))}
                      value={lockDay}
                      required
                    >
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                      <option value="60">60</option>
                      <option value="75">75</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
            </Row>

            {showMoreOptions && (
              <Row>
                <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="employment" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Employment
                  </Form.Label>
                  <Form.Control
                    name="employment"
                    as="select"
                    size="sm"
                    defaultValue={employment}
                    onChange={handleChangeEmployment}
                  >
                    <option value="Employed">Employed</option>
                    <option value="Selfemployed">Self-Employed</option>
                    {/* <option value="Unemployed">Unemployed</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>
              {!(employment === "Unemployed") && <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="incomeCondition" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Income
                  </Form.Label>
                  <Form.Control
                    name="incomeCondition"
                    as="select"
                    size="sm"
                    defaultValue={incomeCondition}
                    onChange={(e) => {
                      setIncomeCondition(e.target.value);
                    }}
                  >
                    <option value="fullincome">Full income</option>
                    <option value="partialincome">Partial income</option>
                    {/* <option value="noincome">No income</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>}
             
                {/* <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="assets" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Assets
                  </Form.Label>
                  <Form.Control
                    name="assets"
                    as="select"
                    size="sm"
                    defaultValue={assets}
                    onChange={(e) => {
                      setAssets(e.target.value);
                    }}
                  >
                    <option value="fullassets">Full Assets</option>
                    <option value="noassets">No assets</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              <Col md={4}  style={{marginBottom:"0px",paddingRight:"10px"}} >
                <Form.Group controlId="assets" className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}
                  >
                    Escrow
                  </Form.Label>
                  <Form.Control
                    name="escrow"
                    as="select"
                    size="sm"
                    defaultValue={escrowWaiver}
                    onChange={(e) => {
                      setEscrowWaiver(e.target.value);
                    }}
                  >
                    <option value="false">Yes</option>
                    <option value="true">No</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                    Second Loan Amount
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    value={secondMortageAmount}
                    required
                    onChange={(e) => setSecondMortgageAmount(e.target.value)}
                    min={0}
                    step={10000}
                    noarrow={true}
                  />
                </Form.Group>
              </Col>
              <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0px",fontWeight:"600", fontSize:"14px",lineHeight:"20px"  }}>
                      Citizenship
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) => setCitizenship(e.target.value)}
                      value={citizenship}
                      required
                    >
                      <option value="US_CITIZENS">US Citizen</option>
                      <option value="PERMANENT_RESIDENT_ALIEN">
                        Permanet Resident Alien
                      </option>
                      <option value="NON_RESIDENT_ALIEN">
                        Non Resident Alien
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {citizenship !== "US_CITIZENS" && (
                  <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                    <Form.Group className="text-left ">
                      <Form.Label style={{ marginBottom: "0px",fontWeight:"600", fontSize:"14px",lineHeight:"20px"   }}>
                        Visa Type
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        as="select"
                        onChange={(e) => setVisaType(e.target.value)}
                        value={visaType}
                        required
                      >
                        <option value="A_1"> A-1</option>
                        <option value="A_2"> A-2</option>
                        <option value="A_3"> A-3</option>
                        <option value="B_1"> B-1</option>
                        <option value="B_2"> B-2</option>
                        <option value="B_3"> B-3</option>
                        <option value="C_1"> C-1</option>
                        <option value="C_2"> C-2</option>
                        <option value="C_3"> C-3</option>
                        <option value="C_4"> C-4</option>
                        <option value="C_1D"> C-1D</option>
                        <option value="CP"> CP</option>
                        <option value="D_1"> D-1</option>
                        <option value="D_2"> D-2</option>
                        <option value="E_1"> E-1</option>
                        <option value="E_2"> E-2</option>
                        <option value="E_3"> E-3</option>
                        <option value="F_1"> F-1</option>
                        <option value="F_2"> F-2</option>
                        <option value="F_3"> F-3</option>
                        <option value="G_1"> G-1</option>
                        <option value="G_2"> G-2</option>
                        <option value="G_3"> G-3</option>
                        <option value="G_4"> G-4</option>
                        <option value="G_5"> G-5</option>
                        <option value="H_1A"> H-1A</option>
                        <option value="H_1B"> H-1B</option>
                        <option value="H_1B1"> H-1B1</option>
                        <option value="H_1C"> H-1C</option>
                        <option value="H_2"> H-2</option>
                        <option value="H_2A"> H-2A</option>
                        <option value="H_2B"> H-2B</option>
                        <option value="H_3"> H-3</option>
                        <option value="H_4"> H-4</option>
                        <option value="I"> I </option>
                        <option value="OR_1"> OR-1</option>
                        <option value="J_1"> J-1</option>
                        <option value="J_2"> J-2</option>
                        <option value="K_1"> K-1</option>
                        <option value="K_2"> K-2</option>
                        <option value="K_3"> K-3</option>
                        <option value="K_4"> K-4</option>
                        <option value="L_1"> L-1</option>
                        <option value="L_2"> L-2</option>
                        <option value="M_1"> M-1</option>
                        <option value="M_2"> M-2</option>
                        <option value="M_3"> M-3</option>
                        <option value="N_8"> N-8</option>
                        <option value="N_9"> N-9</option>
                        <option value="NACARA"> NACARA</option>
                        <option value="NATO_1_6"> NATO-1-6</option>
                        <option value="NATO_7"> NATO-7</option>
                        <option value="O_1"> O-1</option>
                        <option value="O_2"> O-2</option>
                        <option value="O_3"> O-3</option>
                        <option value="P_1"> P-1</option>
                        <option value="P_2"> P-2</option>
                        <option value="P_3"> P-3</option>
                        <option value="P_4"> P-4</option>
                        <option value="Q_1"> Q-1</option>
                        <option value="Q_2"> Q-2</option>
                        <option value="Q_3"> Q-3</option>
                        <option value="R_1"> R-1</option>
                        <option value="R_2"> R-2</option>
                        <option value="S_5"> S-5</option>
                        <option value="S_6"> S-6</option>
                        <option value="S_7"> S-7</option>
                        <option value="T_1"> T-1</option>
                        <option value="T_2"> T-2</option>
                        <option value="T_3"> T-3</option>
                        <option value="T_4"> T-4</option>
                        <option value="TD"> TD</option>
                        <option value="TN_1"> TN-1</option>
                        <option value="TN_2"> TN-2</option>
                        <option value="U_1"> U-1</option>
                        <option value="U_2"> U-2</option>
                        <option value="U_3"> U-3</option>
                        <option value="U_4"> U-4</option>
                        <option value="V_1"> V-1</option>
                        <option value="V_2"> V-2</option>
                        <option value="V_3"> V-3</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}

                <Col md={4} style={{marginBottom:"0px",paddingRight:"10px"}}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0px", fontWeight:"600", fontSize:"14px",lineHeight:"20px",display:"flex"  }}>
                    Est. Fee 
                    <a
                      onClick={handleEditCostDetails}
                      style={{
                        marginLeft:"10px",
                        color: "blue",
                        textDecorationLine: "underline",
                      }}
                    >
                      <b>EDIT</b>
                    </a>
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    value={estOtherCosts}
                    required
                    onChange={(e) => setEstOtherCosts(e.target.value)}
                    min={0}
                    noarrow={true}
                  />
                </Form.Group>
              </Col>
              </Row>
              
            )}
          
          {showMoreOptions && (
              <div style={{marginBottom:"0px",paddingLeft:"15px"}}>
                
                  <Row style={{ marginBottom: "0px", fontWeight:"600", fontSize:"0.9em",lineHeight:"20px",textAlign:"left",alignItems:"center"}}>
                    <div>Standard Products</div>
                    <a
                    style={{marginRight:"30px",marginLeft:"10px", color: "blue",fontSize:"12px",textDecorationLine: "underline",lineHeight:"2px",fontWeight:"bolder"}}
                    onClick={handleClearProducts}
                    >
                      Clear
                    </a>
                  </Row>
                  <Row>
                  {stdproducts.map((stdproduct,idx)=>(
                  <Button
                    className={stdproduct.status=="checked"?"stdProductsButtonChecked":"stdProductsButtonUnchecked"}
                    variant="outline" 
                    name={`${idx}`} 
                    onClick={(e)=>handleCheckProduct(e.target.name)}
                  >
                    {stdproduct.name}
                  </Button>
                  ))}
                  </Row>
              </div>
            )}

            <Row className="text-left" style={{paddingLeft:'5px'}}>
                <Button style={{paddingLeft:"0", color:"#325CEB",marginBottom: props.isMobile?"20px":""}}
                  variant="rateQuoterAdvancedOptions"
                  onClick={onMoreOptions}
                  size="sm"
                >
                  {showMoreOptions ? <>Less Options<img src="/images/blueMinusInCircleMark.png" style={{maxWidth:"20px", paddingLeft:"5px"}}/></> : <>Advanced Options<img src="/images/bluePlusInCircleMark.png" style={{maxWidth:"20px", paddingLeft:"5px"}}/></>}
                  
                </Button>{" "}
            </Row>
            {/* <hr className="advancedOptionsDivider" /> */}
            </div>
            <div className="checkratesWraper">
              <Form.Row style={{fontSize:"16px", marginTop:"10px",justifyContent:"space-between",alignItems:"center", paddingRight:"10px"}}>
                {Object.keys(ineligibleProducts).length > 0 && (
                  // <Form.Row style={{fontSize:"16px", marginTop:"0px",paddingLeft:"0px"}}>
                  <Col md={4} className="text-left ">
                    <Button
                        variant="link"
                        size="sm"
                        onClick={() => setShowIneligible(true)}
                        style={{whiteSpace:"nowrap",marginLeft:"5px",fontWeight:"600"}}
                    >
                        Check Ineligible Products
                    </Button>
                  </Col>
                )}
                {!showIneligible &&<div></div>}
                <Col md={4} className="text-left">
                  <Button type="submit" size="sm" className="checkrates" style={{width:props.isMobile?"100%":"", marginBottom:props.isMobile?"10px":""}} 
                  >
                    Check rates
                  </Button>
                </Col>
              </Form.Row>
            </div>
          </Form>
        </div>
        )}
      </Container>
      {notmodified? (<Container md={2} className={props.isMobile?"messageContainer-mobile":"messageContainer"}>
          <div style={{justifyContent:"center", marginTop:props.isMobile?"20px":"300px"}}>
            <img style={{marginLeft:"20px"}} src="/images/explorerates.png"></img>
            <div style={{textAlign:"center", fontSize:"16px", fontWeight:"500",padding:"0px 60px 0px 60px"}}>Modify borrower's information to explore rates & pricing </div>
          </div>
        </Container>
      ):           
      loading ? (
        <Container id="spinnerContainer" className="pt-5">
          <Row >
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
          </Row>
        </Container>
      ) : (
        (!notmodified)&&noProducts ? <Container className="warningContainer"><YellowWarning text="No qualified products."/></Container> :
        <>
        
        <Container className={props.isMobile?"resultContainer-mobile":"resultContainer"} style={{height:getHeight()}}>
          <AllRatesTable
          connectedLendersMap={connectedLendersMap}
          allRates={allRates}
          formExtended={extended}
          stdproducts={stdproducts}
          viewportH = {viewportHeight}
          borrowerPaidCompensationAmount={borrowerPaidCompensationAmount}
          loanInfo={{loanAmount: getLoanAmount(), occupancy: occupancy, purpose: purpose, propertyType: propertyType, fico:fico}}
          isMobile={isMobile}
        />
        </Container>
        </>
      )}

      <Modal
        show={showAddLenderModal}
        onHide={()=>setShowAddLendersModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div>Important! You haven't add lender setting into the system yet.</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex" style={{flexDirection:"column", fontSize:"16px"}}>
              A Complete lenders setting is the prerequisite for this feature. Please go to the lenders section in management page to update your lender settings first!
              <span style={{color:"blue", marginTop:"20px"}}onClick={handleManagementDirect}>Go to Management</span>
            </div>
        </Modal.Body>

        <Modal.Footer>
          <Button key="back" onClick={()=>setShowAddLendersModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>


      {showReportIssues && (
        <ReportIssuesModal
          show={true}
          onHide={() => setShowReportIssues(false)}
        />
      )}
      {showIneligible && (
        <IneligibleProductsModal
          show={true}
          onHide={() => setShowIneligible(false)}
          ineligibleProducts={ineligibleProducts}
          message={message}
        />
      )}
      {showEditCostDetails ? getEditCostsModal() : ""}
    </div>
  );
};
export default withRouter(RateQuoteLOVersion);