
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import DocumentManager from './DocumentManager'
import Upload from './Upload'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as brwr from "../Store/borrower"
import * as prgr from "../Store/progress"

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    changeBorrowerId: (id) => {
        dispatch(brwr.ChangeBorrowerId(id))
    },
    changeBorrowerName: (name) => {
        dispatch(brwr.ChangeBorrowerName(name))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },

});
class Documents extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

    }
    componentDidMount = () => {
    }
    componentWillReceiveProps = (newProps) => {
    }
    render = () => {
        return (
            <Tabs onSelect={this.onselect} className="tabfont documents-tabs mt-1 pt-2" variant="tabs" defaultActiveKey="borrowersDocuments" unmountOnExit={true} id="documenttabs">
                <Tab eventKey="borrowersDocuments" title="Borrower's documents">
                    <DocumentManager key={this.state.id} whosedocuments = 'customer' />
                </Tab>
                <Tab eventKey="lendersDocuments" title="Lender's documents">
                    <DocumentManager />
                </Tab>
                <Tab eventKey="upload" title="Upload Documents">
                    <Upload />
                </Tab>
            </Tabs>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
