import React from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import "./BasicInfo.css";
export default function BasicInfo({ customerid,loid,basicInfo, contact }) {

    const getPreQualificationLink = () => {
        if (window.location.host.includes("zeitro.us")) {
          return "https://app.zeitro.us/services/preapp?customerid=" + customerid + "&loid=" + loid
        } 
        if (window.location.host.includes("localhost")) {
          return "http://localhost:3000/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        return "https://app.zeitro.com/services/preapp?customerid=" + customerid + "&loid=" + loid
      }

    const contactMe = () => {
        contact()
    }
    let avatarSrc = "/images/avatar.png"
    return (
        <div className="basic-info">
            <div>
                <div className='avatar-box'>
                <img className="basic-info-avatar" src={typeof basicInfo.file !== "undefined" ? basicInfo.file[0].url : avatarSrc}></img>
                    <img className='verified-img' src='/images/verified02.png'></img>
                </div>
                <Row className='name-box'>
                    <Col className='name'>{`${basicInfo.firstname} ${basicInfo.lastname}`}</Col>
                    <Col>
                        <div className='verified-tag'>
                            <img style={{width: '17px', height: '17px', marginRight: '2px'}} src='/images/verified02.png'></img>Verified Loan Officer
                        </div>
                    </Col>
                </Row>
                <div className='certificate'>
                    <span className='NMSL'>NMLS: {basicInfo.nmls ? basicInfo.nmls : '-'}</span>
                    <span className='license'>License State: {basicInfo.states ? basicInfo.states.join(', ') : '-'}</span>
                </div>
                {
                    basicInfo.tagsList.length > 0 && (
                        <div className='tag-list'>
                            {
                                basicInfo.tagsList.map((tag, index) => 
                                    <div className='tag' key={index}>{tag}</div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <div style={{display:"flex",flexDirection:"row"}}>
            <div><Tooltip placement="top" title="Your customer will be able to leave their phone number for you to call them back."><Button disabled className='contact-btn' size='large' onClick={contactMe}>Contact me today</Button></Tooltip></div>
                {/* <div><Button className='contact-btn basicinfo-apply-now-btn' onClick={() => window.location.href=getPreQualificationLink()}>Apply Now</Button></div> */}
            </div>
        </div>
    );
}