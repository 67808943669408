import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'
import Menu from "./Menu";
import FloatingLabelInput from "./Common/components/FloatingLabelInput";
import Copyright from "./Copyright";
import { Spinner } from "react-bootstrap";

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();

        this.state = {
            email: "",
            modaltitle: "",
            show: false,
            message: "",
            validated: false,
            showSpinner: false,
        };
        this.pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        if (this.state.signin)
            this.pattern = "^.+$"        
        this.ref = React.createRef()
    }

    componentDidMount() {
    }
    validateForm() {
        if (this.state.signin)
            return this.state.email.length > 0 && this.state.password.length > 8;
        else
            return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0 && this.state.password.length > 8
                && this.state.password === this.state.password1;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleClose = () => { this.setState({ show: false }) }


    reset = (event, data) => {
        this.setState({showSpinner: true})
        let tosend =
            JSON.stringify({ email: data.get("email").toLowerCase() })

        fetch('/agent/resetpassword', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({showSpinner: false})
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, show: true
                    })
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                this.setState({showSpinner: false})
                this.setState({ message: "We have sent a password reset email to your email address. Please check your inbox for further instructions.", show: true })

            }
        ).catch((err) => {
            this.setState({showSpinner: false})
            console.log('Fetch Error :', err);
        });
    }
    handleSubmit = event => {
        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();
        const data = new FormData(event.target);
        this.reset(event, data)
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    header = () => {

        return (

            <div className="pt-3 nav-link text-center">
                <div className="recover-title">Recover Password</div>
            </div>
        )

    }
    render() {
        return (
            <div ref={this.ref} className="forgotPasswordWrap" >
                <Menu zeitro/>
                <Helmet>
                    <title>Zeitro Forgot Password</title>
                </Helmet>
                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Password recovery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <div style={{flex:1}} className={this.props.view !== 'bare' ? "text-center mb-5 mt-5" : "text-center" } >
                    <div className="loginbox">
                    <div id="landing1" className=" container text-center my-4 pb-5" >
                        <div className="row">
                            <div className="col-sm mt-3  text-center">
                                { this.props.view !== 'bare' ? this.header() : ""}
                                <Form ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                                    <Row className=" mt-4 ">
                                        <Col className="text-center">
                                            <FloatingLabelInput 
                                                name="email" 
                                                label="Enter your email" 
                                                value={this.state.email} 
                                                onChange={e=>this.setState({email: e.target.value})}
                                                className="boarder"
                                                style={{border: "1px solid #ECECEC", borderRadius: 4}}/>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4 ">
                                        <Col className="text-center">
                                            <Button 
                                                type="submit" variant="zeitro-primary" className="w-100"
                                            > {this.state.showSpinner ? <Spinner size="sm" variant="primary" animation="border"/> : "Request password reset"}</Button>
                                        </Col>


                                    </Row>
                                    <Row className="mt-3 ">
                                    </Row>
                                </Form>
                            </div>

                        </div>
                    </div>
                    </div>
                </div>  
                <Copyright className="mt-5 pt-5" />
            </div>
        );
    }
}

