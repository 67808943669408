import React, { useState, useEffect, useRef } from "react";
import "./ThirdPartyResources.css";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as com from "../../Common";
import StateSelector from "../../StateSelector";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const { SearchBar, ClearSearchButton } = Search;

const CurrentResources = (props) => {
  const [resources, setResouces] = useState([]);
  const [referredLoanOfficers, setReferredLoanOfficers] = useState([]);
  const [toRemoveResource, setToRemoveResource] = useState({});
  const [toRemoveReferredLoanOfficer, setToRemoveReferredLoanOfficer] =
    useState({});
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const [showRemoveLOModal, setShowRemoveLOModal] = useState(false);
  const handleCloseRemoveLOModal = () => setShowRemoveLOModal(false);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const [toEditResource, setToEditResource] = useState({});

  const onRemove = (row) => {
    setToRemoveResource(row);
    setShowRemoveModal(true);
  };
  const onRemoveLO = (row) => {
    setToRemoveReferredLoanOfficer(row);
    setShowRemoveLOModal(true);
  };
  const removeResource = () => {
    console.log("removing", toRemoveResource);

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/removeresource", {
      method: "POST",
      body: JSON.stringify(toRemoveResource),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      getResouces();
    });
    setShowRemoveModal(false);
  };

  const removeReferredLoanOfficer = () => {
    console.log("removing", toRemoveReferredLoanOfficer);

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/removereferredloanofficer", {
      method: "POST",
      body: JSON.stringify(toRemoveReferredLoanOfficer),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      getReferredLoanOfficers();
    });
    setShowRemoveLOModal(false);
  };
  const getRemoveModal = () => {
    return (
      <Modal show={showRemoveModal} onHide={handleCloseRemoveModal}>
        <Modal.Header closeButton>
          <Modal.Title>Removing Resouce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {toRemoveResource.Name},{" "}
          {toRemoveResource.Email}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseRemoveModal}>
            Close
          </Button>
          <Button onClick={removeResource}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const getRemoveLOModal = () => {
    return (
      <Modal show={showRemoveLOModal} onHide={handleCloseRemoveLOModal}>
        <Modal.Header closeButton>
          <Modal.Title>Removing Loan Officers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {toRemoveReferredLoanOfficer.Name},{" "}
          {toRemoveReferredLoanOfficer.Email}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseRemoveLOModal}>
            Close
          </Button>
          <Button onClick={removeReferredLoanOfficer}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const resendInvitation = (row)=> {
    setLoadingResend(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/reinvitereferredloanofficer", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(row),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoadingResend(false)
          alert("Looks like there was a problem, please contact Zeitro for help.");
          return;
        }
        response.json().then((js) => {
          setLoadingResend(false)
          if (js.Status !== "OK") {
            alert("Looks like there was a problem, please contact Zeitro for help.");
          } else {
            alert("The invitation email has been resent to " + row.Email + " successfully!");
          }
        });
      })
      .catch(function (err) {
        setLoadingResend(false)
        console.log("Fetch Error:/re/reinvitereferredloanofficer :-S", err);
      });
  
  }

  const actionsFormatter = (cell, row) => (
    <div>
      <Button
        variant="editresource"
        onClick={() => {
          setToEditResource(row);
          setShowEditModal(true);
        }}
      >
        Edit
      </Button>{" "}
      <Button variant="remove" onClick={() => onRemove(row)}>
        Remove
      </Button>
    </div>
  );

  const loStatusFormatter = (_, row) => (
    <div>{row.LoID === "" ? <div>Unregistered<Button variant="link" size="sm" onClick={()=>resendInvitation(row)}>{loadingResend? <Spinner size="sm" animation="border"/>: "Resend Invitation"}</Button></div> : "Registerred"}</div>
  );

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "solid 2px",
    fontSize: "min(1.3vw, 18px)",
    // wordBreak: "break-word",
  };
  let columnStyle = { wordBreak: "break-word", fontSize: "min(1.3vw, 16px)", };
  let columns = [
    {
      dataField: "Type",
      text: "Type",
      sort: true,
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "Name",
      text: "Name",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "Company",
      text: "Company",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "Email",
      text: "Email",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "Phone",
      text: "Phone",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    // {
    //   dataField: "NMLS",
    //   text: "NMLS",
    //   headerStyle: headerStyle,
    // },
    // {
    //   dataField: "LicenseState",
    //   text: "License State",
    //   headerStyle: headerStyle,
    // },
    // {
    //   dataField: "Website",
    //   text: "Website",
    //   headerStyle: headerStyle,
    // },
    {
      dataField: "Remove",
      text: "",
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: headerStyle,
    },
  ];

  let loColumns = [
    {
      dataField: "Name",
      text: "Name",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "Company",
      text: "Company",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "Email",
      text: "Email",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "Phone",
      text: "Phone",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    // {
    //   dataField: "NMLS",
    //   text: "NMLS",
    //   headerStyle: headerStyle,
    // },
    // {
    //   dataField: "LicenseState",
    //   text: "License State",
    //   headerStyle: headerStyle,
    // },
    // {
    //   dataField: "Website",
    //   text: "Website",
    //   headerStyle: headerStyle,
    // },
    {
      dataField: "LoID",
      text: "Status",
      isDummyField: true,
      formatter: loStatusFormatter,
      headerStyle: headerStyle,
      sort: true,
      style: {fontSize: "min(1.3vw, 16px)", }
    },
    {
      dataField: "Remove",
      text: "",
      isDummyField: true,
      formatter: (cell, row) => (
        <div>
          <Button
            variant="editresource"
            onClick={() => {
              setToEditResource(row);
              setShowEditModal(true);
            }}
          >
            Edit
          </Button>{" "}
          <Button variant="remove" onClick={() => onRemoveLO(row)}>
            Remove
          </Button>
        </div>
      ),
      headerStyle: headerStyle,
    },
  ];

  const getResouces = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getresources", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      response.json().then((r) => {
        setResouces(r);
      });
    });
  };

  const getReferredLoanOfficers = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getreferredloanofficers", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((r) => {
        console.log(r);
        setReferredLoanOfficers(r);
      });
    });
  };

  useEffect(() => {
    getResouces();
    getReferredLoanOfficers();
  }, []);

  const changeEditResource = (e, k, isDropdown) => {
    let tmp = toEditResource;
    if (isDropdown) {
      tmp[k] = e;
    } else {
      tmp[k] = e.target.value;
    }
    setToEditResource(tmp);
  };

  const getEditModal = () => {
    return (
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Editing Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editResource.Type !== "" ? (
            <Form onSubmit={editResource}>
              <Row>
              <Col className="text-left">
                  <Form.Group controlId="email">
                    <Form.Label className="text-left">Email:</Form.Label>
                    <Form.Control
                      disabled
                      name="resourceEmail"
                      size="sm"
                      autoFocus
                      autoComplete="resourceEmail"
                      type="resourceEmail"
                      defaultValue={toEditResource.Email}
                      onChange={(e) => changeEditResource(e, "Email")}
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                    />
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="type">
                    <Form.Label className="text-left">Type:</Form.Label>
                    <Form.Control
                        disabled
                        name="resourceType"
                        type="text"
                        size="sm"
                        defaultValue={toEditResource.Type}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <Form.Group controlId="name">
                    <Form.Label className="text-left">Name:</Form.Label>
                    <Form.Control
                      required
                      name="name"
                      size="sm"
                      autoFocus
                      autoComplete="name"
                      type="name"
                      defaultValue={toEditResource.Name}
                      onChange={(e) => changeEditResource(e, "Name")}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="resourceCompany">
                    <Form.Label className="text-left">
                      Company/Broker:
                    </Form.Label>
                    <Form.Control
                      required
                      name="resourceCompany"
                      size="sm"
                      autoFocus
                      autoComplete="resourceCompany"
                      type="resourceCompany"
                      defaultValue={toEditResource.Company}
                      onChange={(e) => changeEditResource(e, "Company")}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid company/broker.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col className="text-left">
                  <Form.Group controlId="phone" className="text-left">
                    <Form.Label className="text-left">
                      Contact Phone:
                    </Form.Label>
                    <Form.Control
                      isValid={false}
                      size="sm"
                      required
                      name="phone"
                      type="text"
                      defaultValue={toEditResource.Phone}
                      placeholder="xxx-xxx-xxxx"
                      onChange={(e) => changeEditResource(e, "Phone")}
                      pattern={com.phoneRegex}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please provide contact phone.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="text-left">
                  <Form.Group controlId="licenseState" className="text-left">
                    <Form.Label className="text-left">
                      License State(optional):
                    </Form.Label>
                    <StateSelector
                      isValid={false}
                      size="sm"
                      name="resourceLicenseState"
                      autoComplete="resourceLicenseState"
                      type="text"
                      placeholder="##"
                      value={toEditResource.LicenseState}
                      onChange={(e) => changeEditResource(e, "LicenseState")}
                      pattern="^[A-Z]{2,2}$"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please provide valid state, for example, CA.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="resouceLicenseNumber">
                    <Form.Label className="text-left">
                      License No. (optional):
                    </Form.Label>
                    <Form.Control
                      name="resouceLicenseNumber"
                      size="sm"
                      autoFocus
                      autoComplete="resouceLicenseNumber"
                      type="resouceLicenseNumber"
                      defaultValue={toEditResource.NMLS}
                      onChange={(e) => changeEditResource(e, "NMLS")}
                    />
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="resourceWebsite">
                    <Form.Label className="text-left">
                      URL (optional):
                    </Form.Label>
                    <Form.Control
                      name="resourceWebsite"
                      size="sm"
                      autoFocus
                      autoComplete="resourceWebsite"
                      type="resourceWebsite"
                      defaultValue={toEditResource.Website}
                      onChange={(e) => changeEditResource(e, "Website")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          ) : (
            <Form onSubmit={editResource}>
              <Row>
                <Col className="text-left">
                  <Form.Group controlId="email">
                    <Form.Label className="text-left">Email:</Form.Label>
                    <Form.Control
                      disabled
                      required
                      name="resourceEmail"
                      size="sm"
                      autoFocus
                      autoComplete="resourceEmail"
                      type="resourceEmail"
                      defaultValue={toEditResource.Email}
                      onChange={(e) => changeEditResource(e, "Email")}
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address!
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="name">
                    <Form.Label className="text-left">LO Name:</Form.Label>
                    <Form.Control
                      name="name"
                      size="sm"
                      autoFocus
                      autoComplete="name"
                      type="name"
                      defaultValue={toEditResource.Name}
                      onChange={(e) => changeEditResource(e, "Name")}
                    />
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="resourceCompany">
                    <Form.Label className="text-left">
                      Company/Broker:
                    </Form.Label>
                    <Form.Control
                      name="resourceCompany"
                      size="sm"
                      autoFocus
                      autoComplete="resourceCompany"
                      type="resourceCompany"
                      defaultValue={toEditResource.Company}
                      onChange={(e) => changeEditResource(e, "Company")}
                    />
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="phone" className="text-left">
                    <Form.Label className="text-left">
                      Contact Phone:
                    </Form.Label>
                    <Form.Control
                      isValid={false}
                      size="sm"
                      required
                      name="phone"
                      type="text"
                      value={toEditResource.Phone}
                      placeholder="(###)-###-####"
                      onChange={(e) => changeEditResource(e, "Phone")}
                      pattern={com.phoneRegex}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please provide contact phone.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="text-left" xs={6}>
                  <Form.Group controlId="licenseState" className="text-left">
                    <Form.Label className="text-left">
                      License State(optional):
                    </Form.Label>
                    <StateSelector
                      isValid={false}
                      size="sm"
                      name="resourceLicenseState"
                      autoComplete="resourceLicenseState"
                      type="text"
                      placeholder="##"
                      value={toEditResource.LicenseState}
                      defaultValue={toEditResource.LicenseState}
                      onChange={(e) => changeEditResource(e, "LicenseState")}
                      pattern="^[A-Z]{2,2}$"
                    />
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="resouceLicenseNumber">
                    <Form.Label className="text-left">
                      NMLS(optional):
                    </Form.Label>
                    <Form.Control
                      name="resouceLicenseNumber"
                      size="sm"
                      autoFocus
                      autoComplete="resouceLicenseNumber"
                      type="resouceLicenseNumber"
                      defaultValue={toEditResource.NMLS}
                      onChange={(e) => changeEditResource(e, "NMLS")}
                    />
                  </Form.Group>
                </Col>
                <Col className="text-left">
                  <Form.Group controlId="resourceWebsite">
                    <Form.Label className="text-left">
                      URL (optional):
                    </Form.Label>
                    <Form.Control
                      name="resourceWebsite"
                      size="sm"
                      autoFocus
                      autoComplete="resourceWebsite"
                      type="resourceWebsite"
                      defaultValue={toEditResource.Website}
                      onChange={(e) => changeEditResource(e, "Website")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button onClick={editResource}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const editResource = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    let data = { ...toEditResource };

    if (data.Type !== "") {
      fetch("/re/editresource", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            setLoading(false);
            alert(
              "Looks like there was a problem. Please contact Zeitro for help."
            );
            return;
          }
          setResouces([]);
          getResouces();
        })
        .catch(function (err) {
          setLoading(false);
          alert(
            "Looks like there was a problem. Please contact Zeitro for help."
          );
          console.log("Fetch Error:/re/editconnectedloanofficer", err);
        });
    } else {
      fetch("/re/editreferredloanofficer", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            setLoading(false);
            alert(
              "Looks like there was a problem. Please contact Zeitro for help."
            );
            return;
          }
          setReferredLoanOfficers([]);
          getReferredLoanOfficers();
        })
        .catch(function (err) {
          setLoading(false);
          alert(
            "Looks like there was a problem. Please contact Zeitro for help."
          );
          console.log("Fetch Error:/re/editconnectedloanofficer", err);
        });
    }

    setLoading(false);
    setShowEditModal(false);
  };

  return (
    <div>
      <div className="loanofficers">
        <h5 className="mt-4">
          <b>Loan Officers</b>
        </h5>
        {!referredLoanOfficers ? (
          <h5 className="mt-5">You haven't added any loan officer yet.</h5>
        ) : (
          <ToolkitProvider
            bootstrap4
            keyField="email"
            data={referredLoanOfficers}
            columns={loColumns}
            search
          >
            {(props) => (
              <div className="mt-4">
                <Row className="ml-4">
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton {...props.searchProps} />
                </Row>

                <BootstrapTable
                  id="resources"
                  bootstrap4
                  bordered={false}
                  // pagination={paginationFactory({
                  //   hidePageListOnlyOnePage: true,
                  // })}
                  {...props.baseProps}
                  filter={filterFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
      <div className="resources">
        <h5 className="mt-5">
          <b>Third Party Resources</b>
        </h5>
        {!resources ? (
          <h5 className="mt-5">You haven't added any resource yet.</h5>
        ) : (
          <ToolkitProvider
            bootstrap4
            keyField="email"
            data={resources}
            columns={columns}
            search
          >
            {(props) => (
              <div className="mt-4">
                <Row className="ml-4">
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton {...props.searchProps} />
                </Row>

                <BootstrapTable
                  id="resources"
                  bootstrap4
                  bordered={false}
                  // pagination={paginationFactory({
                  //   hidePageListOnlyOnePage: true,
                  // })}
                  {...props.baseProps}
                  filter={filterFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
      {showRemoveModal ? getRemoveModal() : ""}
      {showRemoveLOModal ? getRemoveLOModal() : ""}
      {showEditModal && getEditModal()}
    </div>
  );
};
export default CurrentResources;
