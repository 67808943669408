import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux'
import { Dropdown, Form, Button } from 'react-bootstrap';

import Id from './Forms/Id';
import W2 from './Forms/W2';
import Greencard from './Forms/Greencard';
import Bankstatement from './Forms/Bankstatement';
import Paystub from './Forms/Paystub';
import Form1040 from './Forms/Form1040';
import Form1120 from './Forms/Form1120';
import Form1120s from './Forms/Form1120s';
import Form1065 from './Forms/Form1065';
import Spinner from '../CommonUIComponents/Spinner';

import ImagePreview from './ImagePreview'
import { documents } from '../Common';
import classes from './DocumentVerification.module.css'
import '../Utils.css';

export default function DocumentsVerification(props) {
  const [docs, setDocs] = useState({});
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState('');
  const [magnifier, setMagnifier] = useState(1);
  const [magnifierAvailable, setMagnifierAvailable] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [boxConfig, setBoxConfig] = useState({});
  const isMobile = window.innerWidth < 576
  const borrower = useSelector(state => state.borrower);

  useEffect(() => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/agent/getparseddocs', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": borrower.id
      }
    }).then(response => {
      response.json().then(json => {
        let parsedDocuments = JSON.parse(json.Documents)
        let options = []
        let documents = {}
        for (let [key, value] of Object.entries(parsedDocuments)) {
          let isVerified = typeof value.verified === 'object' && value.verified !== null
          options.push({ title: key, verified: isVerified });
          documents[key] = isVerified ? value.verified : value.parseddata;
        }
        setDocs(documents)
        setOptions(options)
      })
    })
  }, [borrower.id])

  const selectDocument = documentTitle => {
    setSelected(documentTitle)
  }

  const changeTextInputHandler = (filename, value, path) => {
    let docsCopy = JSON.parse(JSON.stringify(docs))
    let fieldToUpdate = docsCopy[filename]
    for (let i = 0; i < path.length - 1; i++) {
      fieldToUpdate = fieldToUpdate[path[i]];
    }
    fieldToUpdate[path[path.length - 1]] = value
    setDocs(docsCopy)
  }

  const onFieldFocus = (bbox, page) => {
    setBoxConfig({ bbox, page });
  }

  const changeMagnifierAccessibility = useCallback((value) => {
    setMagnifierAvailable(value);
    if (!value) setMagnifier(1);
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()
    setVerificationLoading(true);
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/agent/verifydocument', {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-CustomerID": borrower.id,
        "X-Document": selected,
      },
      body: JSON.stringify(docs[selected]),
    }).then(response => {
      if (response.status !== 200) {
        alert('Something went wrong');
        setVerificationLoading(false);
        return;
      }
      setOptions(prev => prev.map(el => el.title === selected ? { ...el, verified: true } : { ...el }))
      setVerificationLoading(false);
    })
  }

  const formRenderer = (doc, fn) => {
    let m = {
      '': null,
      'id.pdf': <Id doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'w2.pdf': <W2 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'greencard.pdf': <Greencard doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'bankstatement.pdf': <Bankstatement doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'paystub.pdf': <Paystub doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1040.pdf': <Form1040 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1120.pdf': <Form1120 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1120s.pdf': <Form1120s doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />,
      'form1065.pdf': <Form1065 doc={docs[fn]} onFocus={onFieldFocus} onChangeTextInput={changeTextInputHandler} />
    }
    return m[doc]
  };

  const mapFilenameToForm = (selectedfilename) => {
    if (selectedfilename==="") return ""
    let m = {
      'id.pdf': documents["driverlicense"].concat(["I D"]),
      "w2.pdf": documents["w2"].concat(["W2"]),
      "greencard.pdf": documents["greencard"].concat(["Green Card"]),
      "bankstatement.pdf": documents["bankstatements"].concat(["Bank Statement"]),
      "paystub.pdf": documents["paystubs"].concat(["Paystub"]),
      "form1040.pdf": documents["form1040"],
      "form1120s.pdf": documents["form1120s"],
      "form1120.pdf": documents["form1120"],
      "form1065.pdf": documents["form1065"],
    }

    for (let doc in m) {
      for (let name of m[doc]) {
        console.log(selectedfilename, name)
        if (selectedfilename.includes(name)) {
          return formRenderer(doc, selectedfilename)
        }
      }
    }
    return <div>Document verification is not available for this type of document</div>
  }

  return (
    <div className={isMobile?"":classes.grid}>
      {!isMobile && <div className={classes['grid-element']}>
        <Form onSubmit={submitHandler}>
          <Form.Group className={`mb-3 ${classes['m-top-1']}`} controlId="Magnifier">
            <div className={`${classes.flexbox} ${!magnifierAvailable ? classes.disabled : ''}`}>
              <span>Zoom in images up to 4 times</span>
              <input disabled={!magnifierAvailable} type="range" min='1' max='4' step='1' value={magnifier} onChange={e => setMagnifier(parseInt(e.target.value))} />
              x{magnifier}
            </div>
          </Form.Group>
          <Dropdown id='dropdown-autoclose-true'>
            <Dropdown.Toggle variant="Secondary">
              Select Document
            </Dropdown.Toggle>
            <Dropdown.Menu className={classes.dropdown}>
              {options.sort((a, b) => (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1)).map((o, i) => (
                <Dropdown.Item
                  className={`${o.verified ? classes.green : classes.red}`}
                  onClick={() => selectDocument(o.title)}
                  key={i}>
                  {o.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {selected.length === 0 && <p className={`${classes.bigger} ${classes.red}`}>If document has been verified, form will be populated with verified data, not with originally parsed data.</p>}
          {mapFilenameToForm(selected)}
          {options.some(el => el.title === selected && el.verified) &&
            <p style={{ fontSize: '1.5rem' }} className={classes.green}>Document is verified</p>}
          {selected && !verificationLoading && <Button className='button-custom' type='submit'>Verify</Button>}
          {selected && verificationLoading && <Spinner />}
        </Form>
      </div>}
      {!isMobile && <div className={`${classes['grid-element']}`}>
        <ImagePreview boxConfig={boxConfig} magnifier={magnifier !== 1} scaleFactor={magnifier} changeMagnifierAccessibility={changeMagnifierAccessibility} fileName={selected} />
      </div>}
      {isMobile && <div>Sorry, this feature is not supported by mobile devices. Please switch to a desktop devices to enable this feature.</div>}
    </div>
  );
}