import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "./LandingPage.css";

const ReProfile = (props) => {
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [license, setLicense] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [company, setCompany] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [showContactModal, setShowContactModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoN, setPhotoN] = useState(0)

  const getProfile = () => {
    fetch("/individualcustomers/getprofilebyid/" + props.id, {
      method: "GET",
      headers: {
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          console.log(js);
          setImage(js.Image);
          setFirstName(js.FirstName);
          setLastName(js.LastName);
          props.setName(js.FirstName + " " + js.LastName);
          setEmail(js.Email);
          props.setEmail(js.Email);
          setPhone(js.Phone);
          setLicense(js.LicenseNumber);
          setIntroduction(js.Introduction);
          setCompany(js.Company);
        });
      })
      .catch(function (err) {
        alert("Fetch Error /individualcustomers/getprofilebyid/:-S", err);
        this.setState({ loading: false });
      });
  };

  useEffect(() => {
    setPhotoN(Math.floor(Math.random() * 5))
    getProfile();
  }, []);

  const handleClickContact = () => {
    if (contactName !== "" && (contactEmail !== "" || contactPhone !== "")) {
      setLoading(true);
      fetch("/agent/sendcontactmeemail", {
        method: "POST",
        body: JSON.stringify({
          Name: contactName,
          Email: contactEmail,
          Phone: contactPhone,
          AgentName: firstName + " " + lastName,
          AgentEmail: email,
        }),
        headers: {
          Cache: "no-cache",
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            setLoading(true);
            alert(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          }
          setLoading(false);
          setShowContactModal(true);
        })
        .catch(function (err) {
          setLoading(false);
          alert("Fetch Error :/agent/sendcontactmeemail", err);
        });
    }
  };

  const getContactModal = () => {
    return (
      <Modal show={showContactModal} onHide={() => setShowContactModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>The agent will contact you soon.</Modal.Body>
      </Modal>
    );
  };

  const getLandingBackPhoto = () => {
    return {background: "url('/images/reLandingBackground" + photoN  + ".png')"}
  }

  return (
    <div>
      <div className="landingBackground" style={getLandingBackPhoto()}>
        <Container className="personalinfo ">
          <Row>
            <div>
              <div className="profileTitle">PREMIER AGENT</div>
              <div className="profileImageRow ">
              <img src={image} className="profileImageMobile" />
              <div className="profileNameRe">
                <a className="profileFirstName" style={{ color: "#6E6E70" }}>
                  {firstName}
                </a>{" "}
                <b>{lastName}</b>
              </div>
              </div>
              <Row className="profileOtherInfo">
                <Col
                  className="profileOtherInfoCol"
                  style={{ color: "#6E6E70" }}
                >
                  License#
                </Col>
                <Col className="profileOtherInfoCol2">{license}</Col>
              </Row>
              {company !== "" && (
                <Row className="profileOtherInfo">
                  <Col
                    className="profileOtherInfoCol"
                    style={{ color: "#6E6E70" }}
                  >
                    Company
                  </Col>
                  <Col className="profileOtherInfoCol2">{company}</Col>
                </Row>
              )}
              <Row className="profileOtherInfo">
                <Col
                  className="profileOtherInfoCol"
                  style={{ color: "#6E6E70" }}
                >
                  Cell Phone
                </Col>
                <Col className="profileOtherInfoCol2">{phone}</Col>
              </Row>
              <Row className="profileOtherInfo">
                <Col
                  className="profileOtherInfoCol"
                  style={{ color: "#6E6E70" }}
                >
                  Email
                </Col>
                <Col className="profileOtherInfoCol2">{email}</Col>
              </Row>
            </div>
          </Row>
          <Row className="profileContact">
            <Col>
              <Form.Group>
                <Form.Label className="profileFormLabel">Name</Form.Label>
                <Form.Control
                  className="profileFormInput"
                  placeholder="Your Name"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="profileFormLabel">Email</Form.Label>
                <Form.Control
                  className="profileFormInput"
                  type="email"
                  placeholder="example@email.com"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="profileFormLabel">Phone</Form.Label>
                <Form.Control
                  className="profileFormInput"
                  placeholder="XXX-XXX-XXXX"
                  pattern="^[1-9][0-9]{2,2}-[0-9]{3,3}-[0-9]{4,4}$"
                  value={contactPhone}
                  onChange={(e) => setContactPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col style={{ maxWidth: "180px" }}>
              <Button
                variant="contactButton"
                onClick={() => handleClickContact()}
              >
                {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "Contact"
                )}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      {introduction.length > 0 && (
        <div className="profileProfessionalInfo">
          <Container>
            <Row>
              <Col sm={3} className="profileProfessional">
                <b>About</b> <a style={{ color: "#6E6E70" }}>me</a>
              </Col>
              <Col sm={7} md={6}>
                <div className="profileIntroduction"> {introduction}</div>
              </Col>
              <Col sm={2}>
                <img src={image} className="profileImage" />
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showContactModal && getContactModal()}
    </div>
  );
};

export default ReProfile;

export const LoProfile = (props) => {
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [license, setLicense] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [company, setCompany] = useState("");
  const [photoN, setPhotoN] = useState(0)

  const getProfile = () => {
    fetch("/individualcustomers/getprofilebyid/" + props.id, {
      method: "GET",
      headers: {
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          console.log(js);
          setImage(js.Image);
          setFirstName(js.FirstName);
          setLastName(js.LastName);
          props.setName(js.FirstName + " " + js.LastName);
          setEmail(js.Email);
          props.setEmail(js.Email);
          setPhone(js.Phone);
          setLicense(js.Nmls);
          setIntroduction(js.Introduction);
          setCompany(js.Company);
        });
      })
      .catch(function (err) {
        alert("Fetch Error :-S", err);
        this.setState({ loading: false });
      });
  };

  useEffect(() => {
    setPhotoN(Math.floor(Math.random() * 5))
    getProfile();
  }, []);

  const getLandingBackPhoto = () => {
    return {background: "url('/images/loLandingBackground" + photoN  + ".png')"}
  }

  return (
    <div>
      <div className="landingBackgroundLo" style={getLandingBackPhoto()}>
        <Container className="personalinfo">
          <Row>
            <div>
              <div className="profileImageRow">
              <img src={image} className="profileImageMobile" />
              <div className="profileNameLo mt-5">
                <a className="profileFirstName" style={{ color: "#6E6E70" }}>
                  {firstName}
                </a>{" "}
                <b>{lastName}</b>
              </div>
              </div>
              <Row className="profileOtherInfo">
                <Col
                  className="profileOtherInfoCol"
                  style={{ color: "#6E6E70" }}
                >
                  NMLS
                </Col>
                <Col className="profileOtherInfoCol2">{license}</Col>
              </Row>
              {company !== "" && (
                <Row className="profileOtherInfo">
                  <Col
                    className="profileOtherInfoCol"
                    style={{ color: "#6E6E70" }}
                  >
                    Company
                  </Col>
                  <Col className="profileOtherInfoCol2">{company}</Col>
                </Row>
              )}
              <Row className="profileOtherInfo">
                <Col
                  className="profileOtherInfoCol"
                  style={{ color: "#6E6E70" }}
                >
                  Cell Phone
                </Col>
                <Col className="profileOtherInfoCol2">{phone}</Col>
              </Row>
              <Row className="profileOtherInfo">
                <Col
                  className="profileOtherInfoCol"
                  style={{ color: "#6E6E70" }}
                >
                  Email
                </Col>
                <Col className="profileOtherInfoCol2">{email}</Col>
              </Row>
              <div className="profileTitle" style={{ marginTop: "60px" }}>
                I can help you
              </div>
              <div className="profileICanHelpYouRow">
                <div className="profileICanHelpYouCol" onClick={props.handleClickRefRateQuoter}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="/images/checkRates.png"
                      style={{ maxWidth: "38px" }}
                    />
                  </div>
                  <div className="profileICanHelpYouText">Check Rates</div>
                </div>
                <div className="profileICanHelpYouCol" onClick={props.handleClickRefAffordability}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="/images/checkAffordability.png"
                      style={{ maxWidth: "38px" }}
                    />
                  </div>
                  <div className="profileICanHelpYouText">
                    Check Affordability
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      {introduction.length > 0 && (
        <div className="profileProfessionalInfo mt-0">
          <Container>
            <Row>
              <Col sm={3} className="profileProfessional">
                <b>About</b> <a style={{ color: "#6E6E70" }}>me</a>
              </Col>
              <Col sm={7} md={6}>
                <div className="profileIntroduction"> {introduction}</div>
              </Col>
              <Col sm={2}>
                <img src={image} className="profileImage" />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};
