import React, { Component } from "react";
import "./LoanSummary.css";
import "../App.css";

import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import * as com from "../Common";
import * as act from "../Store/actions";
import {calculateEmploymentIncome, calculateDividendIncome, calculateOtherIncome} from '../Interview/Income'

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateApplicationAttribute: (t, verb) => {
    dispatch(act.UpdateApplicationAttribute(t, verb));
  },
  updateSelectedLoanAttribute: (t, verb) => {
    dispatch(act.UpdateSelectedLoanAttribute(t, verb));
  },
});

export default class LoanOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  displayApplicant = (who) => {
    if (this.props.application[who].firstname==="") {
      return (
        <div>{who==="coborrower"?"Coborrower":"Borrower"} information has not been provided yet.</div>
      )
    }

    return (
      <div className="mt-4">
        <b>
          {this.props.application[who].firstname}{" "}
          {this.props.application[who].lastname}
        </b>
        {com.getPrimaryAddress(who, this.props.application).length >5 && <div>Residing at {com.getPrimaryAddress(who, this.props.application)}</div>}
        
        <div></div>
      </div>
    );
  };
  showButton = (s) => {
    if (this.props.borrowerid != null) return "";
    return s;
  };
  displayApplicants = () => {
    let out = [];
    out.push(this.displayApplicant("borrower"));

    if (
      this.props.application.hascoborrower === "withcoborrower" ||
      this.props.application.hascoborrower === "multiple"
    ) {
      out.push(this.displayApplicant("coborrower"));
    }
    return out;
  };
  displayLoan = () => {
    if (this.props.application.property.appraisal==="" && this.props.application.property.salesprice==="") {
      return <div>Loan information has not been provided yet.</div>
    }

    let isPreapproval = () => {
      return (
        false === this.props.application.property.hascontract &&
        this.props.application.property.purpose === "purchase"
      );
    };
    let getAddress = () => {
      let st = this.props.application.property.address;
      if (isPreapproval()) return "TBD";
      return st;
    };

    let purpose;
    switch (this.props.application.property.purpose) {
      case "refinance": {
        purpose = "refinance loan";
        break;
      }
      case "cashoutrefinance": {
        purpose = "cash out refinance loan";
        break;
      }
      default:
      case "purchase": {
        purpose = "purchase loan";
        break;
      }
    }
    let getPropertyPage = () => {
      if (
        this.props.application.property.purpose !== "purchase" &&
        true === this.props.application.financingsameproperty
      ) {
        return "/app/interview/main/3";
      }
      return "/app/interview/main/7";
    };
    if (isPreapproval()) {
      purpose = "preapproval application";
    }
    let propertyType = () => {
      switch (this.props.application.property.propertytype) {
        case "singlefamilyattached":
          return "single family attached";
        case "singlefamilydetached":
          return "single family detached";
        case "condominium":
          return "condominium attached";
        case "condominiumdetached":
          return "condominium detached";
        case "pudattached":
          return "PUD attached";
        case "puddetached":
          return "PUD detached";
        case "twotofourfamily":
          return "2 to 4 Unit Multifamily";
        default:
          return "You should not see this";
      }
    };
    let occupancy = () => {
      switch (this.props.application.property.occupancy) {
        case "principal":
          return "a primary residence";
        case "secondhome":
          return "a second home";
        case "investment":
          return "an investment property";
        default:
          return "Should not see this";
      }
    };
    let getEditOccupancy = () => {
      if (
        this.props.application.property.purpose !== "purchase" &&
        this.props.application.financingsameproperty
      )
        return "/app/interview/main/1";
      return "/app/interview/main/property";
    };

    return (
      <div>
        <div className="mt-4">Doing a {purpose}</div>
        <div>
          for the property located at {getAddress()}{" "}
        </div>
        <div>The property is {propertyType()}</div>

        <div>It will be used as {occupancy()}</div>

        {false === this.props.application.property.hascontract &&
        this.props.application.property.purpose === "purchase" ? (
          <div>
            Apply for a preapproval for up to $
            {com.commaizeFloat(this.props.application.property.salesprice)}
          </div>
        ) : (
          <div>
            Estmated home value $
            {com.commaizeFloat(this.props.application.property.appraisal)}
            {this.props.application.property.purpose !== "purchase" ? (
              ""
            ) : (
              <span>
                , purchase price $
                {com.commaizeFloat(this.props.application.property.salesprice)}
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  getEmploymentStatus = (who) => {
    let tmp=[]
    let btn = <Button className="ml-4 my-1 py-0"
    href={"/app/interview/main/income"} size="sm" variant="outline-primary"> Edit</Button>

    if (this.props.application[who].occupation.hasoccupation === null) {
      return <div>{who==="coborrower"?"Coborrower":"Borrower"} information has not been provided yet.</div>
    }

    switch(this.props.application[who].occupation.hasoccupation) {

      case  "unemployed": default:
        tmp.push(<span> is unemployed </span>)
        break
      case  "retired":
        tmp.push(<span>  is retired </span> )
        break

      case  "employed": 
        tmp.push(  <span>  is currently employed at <b>{this.props.application[who].occupation.employername}</b>, 
              {this.props.application[who].occupation.employeraddress} as <b>{this.props.application[who].occupation.positiontitle}</b> </span> )
              
        if(this.props.application[who].otheroccupations!==null  && this.props.application[who].otheroccupations.length > 0) {
          tmp.push(<div>Also employed at:</div>)
          for(let i = 0; i < this.props.application[who].otheroccupations.length; i++) {
            let o = this.props.application[who].otheroccupations[i]
            tmp.push(<div><b>{o.employername}</b>, at {o.employeraddress} as <b>{o.positiontitle}</b> </div>)
          }
        }
        if(calculateEmploymentIncome(this.props.application, who) > 0) 
          tmp.push(<div>Employment gross <b>monthly</b> income: <b>${com.commaizeFloat(calculateEmploymentIncome(this.props.application, who).toFixed(2))}</b> (<b>yearly</b> income of <b>${com.commaizeFloat( (12*calculateEmploymentIncome(this.props.application, who)).toFixed(2) )})</b> </div>)
       

        break

    }
    if(calculateDividendIncome(this.props.application, who) > 0) {
      tmp.push(<div>Gross <b>monthly</b> dividends: <b>${com.commaizeFloat(calculateDividendIncome(this.props.application, who).toFixed(2))}</b> (yearly dividends of  <b>${com.commaizeFloat( (12*calculateDividendIncome(this.props.application, who)).toFixed(2))}</b>)</div>)
    }              
    if(calculateOtherIncome(this.props.application, who) > 0) {
      tmp.push(<div>Gross <b>monthly</b> other income: <b>${com.commaizeFloat(calculateOtherIncome(this.props.application, who).toFixed(2))}</b> (yearly amount of  <b>${com.commaizeFloat( (12*calculateOtherIncome(this.props.application, who)).toFixed(2))}</b>)</div>)
    }              
    return (
        <div className="mt-4"><span> {this.props.application[who].firstname}  {this.props.application[who].lastname}</span>
        {tmp} </div>
         
    )
  }
  displayIncome = () => {
    if (this.props.application.borrower.occupation.hasoccupation === null ) {
      return <div>Employment information has not been provided yet.</div>
    }

    let statuses = []
    statuses.push(this.getEmploymentStatus("borrower"))
    if (this.props.application.hascoborrower === "withcoborrower" ||
    this.props.application.hascoborrower === "multiple") {
      statuses.push(this.getEmploymentStatus("coborrower"))
    }      
    return statuses
  }

  displayAssets = () => {
    let btn = <Button className="ml-4 my-1 py-0"
    href={"/app/interview/main/assets"} size="sm" variant="outline-primary"> Edit</Button>      
    let out = []
    let totalbanks = [0, 0, 0]
    let totalbrokoerages = [0, 0, 0]
    let getname = (b) => {
      return this.props.application[b].firstname + " " + this.props.application[b].lastname
    } 
    

    this.props.application.assetsandliabilities.accounts && 
    this.props.application.assetsandliabilities.accounts.forEach(x => {
      let attribution = x.attribution == null ? 0 : x.attribution - 1
        if(x.balance !== "") {
          totalbanks[attribution] += parseFloat( x.balance)
        }
    })
    if(totalbanks[0] + totalbanks[1] + totalbanks[2] > 0) {
      out.push(<div className="mt-4 font-weight-bold">Bank balances:</div>)
      if(totalbanks[0] > 0) {
        out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalbanks[0].toFixed(2) )}</b> </div>)
      }
      if(totalbanks[1] > 0) {
        out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalbanks[1].toFixed(2) )}</b></div>)
      }
      if(totalbanks[2] > 0) {
        out.push(<div>Joint accounts: <b>${com.commaizeFloat( totalbanks[2].toFixed(2) )}</b></div>)
      }
    }
    this.props.application.assetsandliabilities.brokerages && 
    this.props.application.assetsandliabilities.brokerages.forEach(x => {
        let attribution = x.attribution == null ? 0 : x.attribution - 1
        if(x.balance !== "") {
          totalbrokoerages[attribution] += parseFloat( x.balance)
        }
    })

    if(totalbrokoerages[0] + totalbrokoerages[1] + totalbrokoerages[2] > 0) {
      out.push(<div className="mt-4 font-weight-bold">Brokerage accounts balances:</div>)
      if(totalbrokoerages[0] > 0) {
        out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalbrokoerages[0].toFixed(2) )}</b></div>)
      }
      if(totalbrokoerages[1] > 0) {
        out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalbrokoerages[1].toFixed(2) )}</b></div>)
      }
      if(totalbrokoerages[2] > 0) {
        out.push(<div>Joint accounts: <b>${com.commaizeFloat( totalbrokoerages[2].toFixed(2) )}</b></div>)
      }
    }

    btn = <Button className="ml-4 my-1 py-0"
    href={"/app/interview/main/22"} size="sm" variant="outline-primary"> Edit</Button>         
    if(this.props.application.assetsandliabilities.gifts != null && this.props.application.assetsandliabilities.gifts.length > 0) {
      
      out.push(<div className="mt-4 font-weight-bold">Gifts:</div>)   
      let gifts = this.props.application.assetsandliabilities.gifts
      let totalgifts = [0, 0, 0]
      for(let i = 0; i < gifts.length; i++) {
        let attribution = gifts[i].attribution == null ? 0 : gifts[i].attribution - 1
        totalgifts[attribution] += parseFloat(gifts[i].value)
      }     
      if(totalgifts[0] > 0) {
        out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalgifts[0].toFixed(2) )}</b></div>)
      }
      if(totalgifts[1] > 0) {
        out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalgifts[1].toFixed(2) )}</b></div>)
      }
      if(totalgifts[2] > 0) {
        out.push(<div>Joint ownership: <b>${com.commaizeFloat( totalgifts[2].toFixed(2) )}</b></div>)
      }        
    }
    if(this.props.application.assetsandliabilities.otherassets != null && this.props.application.assetsandliabilities.otherassets.length !== 0){
      out.push(<div className="mt-4 font-weight-bold">Other assets:</div>)   
      let other = this.props.application.assetsandliabilities.otherassets
      let totalother = [0, 0, 0]
      for(let i = 0; i < other.length; i++) {
        let attribution = other[i].attribution == null ? 0 : other[i].attribution - 1
        totalother[attribution] += parseFloat(other[i].value)
      }     
      if(totalother[0] > 0) {
        out.push(<div>{getname("borrower")}: <b>${com.commaizeFloat( totalother[0].toFixed(2) )}</b></div>)
      }
      if(totalother[1] > 0) {
        out.push(<div>{getname("coborrower")}: <b>${com.commaizeFloat( totalother[1].toFixed(2) )}</b></div>)
      }
      if(totalother[2] > 0) {
        out.push(<div>Joint ownership: <b>${com.commaizeFloat( totalother[2].toFixed(2) )}</b></div>)
      }        
    }
    if (out.length === 0 ) {
      out.push(<div>No assets was added.</div>)
    }

    return out
  }

  render() {
    let total = null
    let monthlypayment = 0
    let rate = null
    let remainingbalance = 0
    let totalloans = 0
    let totalhelocs = 0
    let totalhelocbalance = 0
    let totalhelocpayment = 0

    let calculateMortgage = () => {
      if (null == total) {
        total = 0
        let loans = this.props.application.assetsandliabilities.loans
        if (loans === null) {
          return
        }
        for (let i = 0; i < loans.length; i++) {
          let loan = loans[i]
          if (loan.correction !== "none") continue
          if (loan.creditloantype !== null) {
            if (!(loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
              loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
              loan.creditloantype.toLowerCase().indexOf("realestate") >= 0)) continue
          if (loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0) {
            totalhelocs += 1
            totalhelocbalance += parseFloat(loan.remainingbalance)
            totalhelocpayment += parseFloat(loan.monthlypayment)
            continue
          }
          }
          if (rate == null)
            rate = loan.rate
          remainingbalance += parseFloat(loan.remainingbalance)
          totalloans += 1
          monthlypayment += parseFloat(loan.monthlypayment)
        }
        // now go over manual loans
        loans = this.props.application.assetsandliabilities.properties
        for (let i = 0; loans != null && i < loans.length; i++) {
          let loan = loans[i]
          if (loan.ownedfreeandclear === true) continue


          remainingbalance += parseFloat(loan.liens)
          totalloans += 1
          monthlypayment += parseFloat(loan.payment)
        }
        loans = this.props.application.otherliens
        for (let i = 0; loans != null && i < loans.length; i++) {
          let loan = loans[i]
          if (loan.balance === "")
            continue

          remainingbalance += parseFloat(loan.balance)
          totalloans += 1
          monthlypayment += parseFloat(loan.monthly)
        }
      }
    }
    calculateMortgage()
    let totalMortgagePayments = () => {
      return monthlypayment
    }
    let countMortgages = () => {
      return totalloans
    }
    let mortgageBalance = () => {

      if (remainingbalance === "")
        remainingbalance = 0
      return remainingbalance
    }


    return (
      <div className="mb-5  text-left">
        <div className="text-left mt-2 mb-4 pt-2 ">
          <div className="viewport text-wrap">
            <div className="text-left sectionquestion">
              {this.props.application.hascoborrower === "withcoborrower" ||
              this.props.application.hascoborrower === "multiple"
                ? "Applicants"
                : "Applicant"}
              :
            </div>
            {this.displayApplicants()}
          </div>
        </div>

        <div className="viewport text-wrap">
          <div className="text-left sectionquestion">Loan:</div>
          {this.displayLoan()}
        </div>
        <div className="viewport text-wrap">
          <div className="text-left sectionquestion">Income:</div>
          {this.displayIncome()}
        </div>
        <div className="viewport text-wrap">
          <div className="text-left sectionquestion">Assets:</div>
          {this.displayAssets()}
        </div>
        <div className="viewport text-wrap">
          <div className="text-left sectionquestion">Expenses:</div>
            {(mortgageBalance() === 0 || totalMortgagePayments() === 0) ? <div>No existing mortgages.</div> :
              <div>
                <b>Monthly mortgage payments</b> are <b>${com.commaizeFloat(totalMortgagePayments())}</b> for {countMortgages()} mortgage loan{countMortgages() > 1 ? "s" : ""} with a <b>total
                  balance of ${com.commaizeFloat(mortgageBalance())}</b> 
              </div>
            }
        </div>
      </div>
    );
  }
}
LoanOverview = connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(LoanOverview);
