import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./HomePage.css"
import LogoutPage from './LogoutPage'
import { AnthenticatedMenu } from "./Menu.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import Sidebar from "./Sidebar/Sidebar";
import Profile from "./Profile";
import Borrowers from "./Borrowers";
import Borrower, {ReferredBorrower} from "./Borrower";
import ReferBorrowers from "./ReferBorrowers/ReferBorrowers";
import ReferralsForLO, {ReferralsForRE} from "./Referrals";
import Tasks from "./Tasks/Tasks";
import LOInput from "./LOInput/LOInput";
import Notifications from "./Notifications";
import ContactCustomer from "./Tools/ContactCustomer/ContactCustomer";
import Affordability from "./Tools/Afordability/Affordability";
import Login from "./Tools/CustomerRegistration/Login";
import Management from "./Management/Management";
import ThirdPartyResources from "./Management/ThirdPartyResources/ThirdPartyResources";
import Properties from "./Management/Properties/Properties";
import RateQuoter from "./Tools/MortgageCalculators/RateQuote";
import SubscribersList from "./Tools/Subscription/SubscribersList";
import SubscriptionDashboard from "./Tools/Subscription/SubscriptionDashboard";
import { ReportIssuesForm } from "./Tools/MortgageCalculators/ReportIssues";
import ReferredRealtors from "./Management/ReferredRealtors/ReferredRealtors";
import LandingPageSetup from "./InitialSetup/LandingPageSetup";
import NewLandingPageSetup from "./InitialSetup/NewLandingPageSetup";
import WelcomePage from './WelcomePage';
import Pipeline from './Management/Borrowers';

import { RouteWrapper } from "./Auth";
import {
  UR_LoanOfficer,
  UR_LoanOfficerManager,
  UR_Owner,
  UR_RealEstateAgent,
  UR_LoanProcessor, 
  UR_PriceEngineUser
} from "./State";
import * as com from "./Common";
import { Switch, Route } from "react-router-dom";
import Interview1003View from './Interview1003View/Interview1003View';
import MortgageGPT from './MortgageGPT/MortgageGPT';
import { Menu, Layout, Modal, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import eventBus from './Bus';
const { Header, Content, Footer, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { confirm } = Modal;

class HomePage extends React.Component {
  state = {
    shrunk: false,
    collapsed: false,
    avatar: "/avatar.png",
    email: "",
    firstName: "",
    lastName: "",
    current: "dashboard"
  }
  shrink = () => {
    this.setState({ shrunk: !this.state.shrunk });
  };
  listenResizeEvent = (e) => {
    //console.log("listenResizeEvent")
    if (window.innerWidth < 800) {
      if (!this.state.hidden) {
        this.setState({ hidden: true, shrunk: true });
        //console.log("small resize")
      }
    } else {
      if (this.state.hidden) {
        this.setState({ hidden: false, shrunk: false });
        //console.log("big resize")
      }
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setMenuSelect()
    }
  }
  componentDidMount() {
    this.setMenuSelect()
    com.getReturnURLs()
    eventBus.addListener('updateProfile',this.getprofile)
    window.addEventListener("resize", this.listenResizeEvent)
    window.addEventListener("popstate", this.setMenuSelect.bind(this))
    this.listenResizeEvent()
    this.getprofile()
  }
  componentWillUnmount() {
    eventBus.removeListener('updateProfile',this.getprofile)
    window.addEventListener('resize', this.listenResizeEvent)
    window.removeEventListener("popstate", this.setMenuSelect.bind(this))
  }
  setMenuSelect = () => {
    const urlObj = {
      'dashboard': 'welcome',
      'loans' : 'loans',
      'loans' : 'app/borrower/',
      // 'leads' : '',
      'borrowers' : 'borrowers',
      'rates' : 'purchasecalculator',
      'gpt' : 'mortgagegpt',
      'mySite' : 'landingpage',
      'management' : 'management',
      // 'search' : '',
      'settings' : 'profile'
    }
    const currentKey = Object.keys(urlObj).find(key => window.location.pathname.includes(urlObj[key]))
    if (currentKey) {
      this.setState({ current: currentKey, collapsed: currentKey !== 'dashboard'})
    }
  }
  getprofile = () => {
    let token = window.sessionStorage.getItem("ZeitroA")
    fetch("/agent/getprofile", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache"
      },
    }).then(response => {
        let d = new Date()
        //console.log(JSON.stringify(response))
        if (!response.ok) {
            console.log("Auth fetch error, at: " + d.toLocaleString())
            sessionStorage.removeItem("ZeitroA")
        } else {

            response.json().then(js => {
                //console.log(JSON.stringify(js))
                if (js.Status !== "OK") {

                } else {
                    let profile = js.Profile
                    this.setState({ avatar: profile.Image, email: profile.Email, firstName: profile.FirstName, lastName: profile.LastName })
                }
            })
        }
    }).catch(error => {
        let d = new Date()
        console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
    })
  }
  
  getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    }
  }
  callSidebar = () => {
    const sidebaricon = (key) => {
      return this.state.current === key ? <img className='sidebar-icon' src={`/sidebaricons/${key}-active.svg`}/> : <img className='sidebar-icon' src={`/sidebaricons/${key}.svg`}/>
    }
    const urlObj = {
      'dashboard': 'welcome',
      'loans' : 'loans',
      // 'leads' : '',
      'borrowers' : 'borrowers',
      'rates' : 'purchasecalculator',
      'gpt' : 'mortgagegpt',
      'mySite' : 'landingpage',
      'management' : 'management',
      // 'search' : '',
      'settings' : 'profile'
    }
    const items = [
      this.getItem('Dashboard', 'dashboard', sidebaricon('dashboard')),
      this.getItem('Loans', 'loans', sidebaricon('loans')),
      // this.getItem('Leads', 'leads', sidebaricon('leads')),
      this.getItem('Borrowers', 'borrowers', sidebaricon('borrowers')),
      this.getItem('Rates', 'rates', sidebaricon('rates')),
      this.getItem('Guideline GPT', 'gpt', sidebaricon('gpt')),
      this.getItem('My site', 'mySite', sidebaricon('mySite')),
      this.getItem('Management', 'management', sidebaricon('management')),
      // this.getItem('Search', 'search', sidebaricon('search')),
      this.getItem('Settings', 'settings', sidebaricon('settings')),
    ]
    const onSidebarClick = (e) => {
      let url = `/app/${urlObj[e.key]}`
      this.setState({ collapsed: e.key !== 'dashboard', current: e.key })
      this.props.history.push(url)
    }
    const logout = (props) => {
      confirm({
        title: 'Are you sure you want to log out?',
        onOk() {
          props.history.push('/app/logout')
        },
        onCancel() {

        },
      })
    }
    const handleCollapse = () => {
      this.setState({ collapsed: !this.state.collapsed })
    }
    const toDashboard = () => {
      this.props.history.push('/app/welcome')
    }
    // return <div className="call-sidebar"><Col className="px-2 m-0 " style={{position:"sticky", top:"55px", height:"calc(100vh - 56px)"}}><Sidebar /></Col></div>
    return (
      <div className='call-sidebar'>
        <div className='collapse-btn' onClick={handleCollapse}>{this.state.collapsed ? <RightOutlined /> : <LeftOutlined />}</div>
        <Sider className='main-sider' collapsible collapsed={this.state.collapsed} trigger={null} >
        <div className={this.state.collapsed ? 'sidebar-logo' : 'sidebar-logo-expand'} ><div className='logo-box' onClick={toDashboard}>{!this.state.collapsed ? <img style={{width: 122}} src='/images/zeitrologo.png'/> : <img style={{width: 25}} src='/apple-touch-icon.png'/>}</div></div>
          {/* <Menu onClick={onSidebarClick} defaultSelectedKeys={[this.state.current]} mode="inline" items={items} /> */}
          <Menu onClick={onSidebarClick} selectedKeys={[this.state.current]} mode="inline">
            <MenuItem key="dashboard" icon={sidebaricon('dashboard')}>Dashboard</MenuItem>
            <MenuItem key="loans" icon={sidebaricon('loans')}>Loans</MenuItem>
            {/* <MenuItem key="leads" icon={sidebaricon('leads')}>Leads</MenuItem> */}
            <MenuItem key="borrowers" icon={sidebaricon('borrowers')}>Borrowers</MenuItem>
            <MenuItem key="rates" icon={sidebaricon('rates')}>Rates</MenuItem>
            <MenuItem key="gpt" icon={sidebaricon('gpt')}>Guideline GPT</MenuItem>
            <MenuItem key="mySite" icon={sidebaricon('mySite')}>My site</MenuItem>
            <MenuItem key="management" icon={sidebaricon('management')}>Management</MenuItem>
            <div className='menu-line'></div>
            {/* <MenuItem key="search" icon={sidebaricon('search')}>Search</MenuItem> */}
            <MenuItem key="settings" icon={sidebaricon('settings')}>Settings</MenuItem>
          </Menu>
          <div className={`sidebar-botttom ${this.state.collapsed ? 'sidebar-collapsed-style' : ''}`} >
              <img alt="avatar" className="header-img" src={this.state.avatar}/>
              {this.state.collapsed && <div style={{marginTop: 2}} className='logout' onClick={() => logout(this.props)}>logout</div>}
              {
                !this.state.collapsed && (
                  <div style={{flex: 1, marginLeft: 10}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '3px'}}><div className='load-name'><Tooltip title={`${this.state.firstName} ${this.state.lastName}`}>{`${this.state.firstName} ${this.state.lastName}`}</Tooltip></div><div className='logout' onClick={() => logout(this.props)}>logout</div></div>
                    <div className='load-email'><Tooltip title={this.state.email}>{this.state.email}</Tooltip></div>
                  </div>
                )
              }
            </div>
        </Sider>
      </div> 
    )
  }
  render = () => {
    return (
      <div id="myapp" className="py-0 mb-0">
        <Helmet>
          <title>Zeitro Mortgage Loan </title>
        </Helmet>
        {/* <AnthenticatedMenu /> */}
        <Row id="pane" className="w-100 mx-0 px-0 pt-0 align-top d-flex backimage0">
          {this.callSidebar()}
          <Col
            id="home"
            className="my-0 text-center text-wrap align-top home p-0 m-0"
          >
            <Switch>
              <Route exact path="/app/home">
                <h1>Welcome back!</h1>
              </Route>
              <RouteWrapper
                Component={WelcomePage}
                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor, UR_RealEstateAgent]}
                exact
                path="/app/welcome"
              />
              <RouteWrapper
                Component={MortgageGPT}
                roles={[UR_LoanOfficerManager, UR_Owner, UR_LoanOfficer, UR_LoanProcessor]}
                exact
                path="/app/mortgagegpt"
              />
              <RouteWrapper
                Component={Tasks}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/tasks"
              />
              <RouteWrapper
                Component={Pipeline}
                roles={[UR_LoanOfficer, UR_LoanProcessor, UR_Owner,UR_LoanOfficer]}
                exact
                path="/app/loans"
              />
              <RouteWrapper
                Component={Management}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/management"
              />
              <RouteWrapper
                Component={ReferredRealtors}
                roles={[UR_LoanOfficer, UR_Owner]}
                exact
                path="/app/referredrealtors"
              />
              <RouteWrapper
                Component={Properties}
                roles={[UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/properties"
              />
              <RouteWrapper
                Component={ThirdPartyResources}
                roles={[UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/resources"
              />
              <RouteWrapper
                Component={ReferBorrowers}
                roles={[UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/referborrowers"
              />

              <RouteWrapper
                Component={Borrowers}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/borrowers/:borrowerid?"
              />

              <RouteWrapper
                Component={LOInput}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/loinput"
              />

              <RouteWrapper
                Component={Borrower}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                path="/app/borrower/:id"
              />

              <RouteWrapper
                Component={ReferredBorrower}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                path="/app/referredborrower/:id"
              />

              <RouteWrapper
                Component={ReferralsForLO}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                path="/app/referralsforlo"
              />
              <RouteWrapper
                Component={ReferralsForRE}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                path="/app/referralsforre"
              />

              <RouteWrapper
                Component={ContactCustomer}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/emailcustomer"
              />

              <RouteWrapper
                Component={Login}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/customerregistration"
              />

              <RouteWrapper
                Component={Affordability}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/affordability"
              />

              <RouteWrapper
                Component={Profile}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/profile"
              />

              <RouteWrapper
                Component={RateQuoter}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/purchasecalculator"
              />
              <RouteWrapper
                Component={SubscriptionDashboard}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/distribution"
              />
              <RouteWrapper
                Component={ReportIssuesForm}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/feedback"
              />
              {/* <RouteWrapper
                Component={LandingPageSetup}
                roles={[UR_LoanOfficer, UR_RealEstateAgent, UR_Owner]}
                exact
                path="/app/landingpage"
              /> */}
              <RouteWrapper
                Component={NewLandingPageSetup}
                roles={[UR_LoanOfficer, UR_Owner]}
                exact
                path="/app/landingpage"
              />
              <RouteWrapper
                Component={LogoutPage}
                roles={[UR_LoanOfficer, UR_LoanOfficerManager, UR_Owner, UR_LoanProcessor, UR_PriceEngineUser]}
                exact
                path="/app/logout"
              />
              <Route>
                <h1>Error 404! Can't render this URL!</h1>
              </Route>
            </Switch>
          </Col>
        </Row>
        <Notifications />
      </div>
    );
  };
}
export default withRouter(HomePage)