import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import CustomizationPreview from './UICustomizationPreview/CustomizationPreview';
import { getCustomerId } from '../../../Common';

import classes from './UICustomization.module.css';

const black = '#000000';
const white = '#ffffff';

const UICustomization = () => {

  const [palletAuto, setPalletAuto] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [iconB64, setIconB64] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('');
  const [primaryColorText, setPrimaryColorText] = useState('');
  const [primaryColor2, setPrimaryColor2] = useState('');
  const [primaryColor2Text, setPrimaryColor2Text] = useState('');
  const [primaryColor3, setPrimaryColor3] = useState('');
  const [primaryColor3Text, setPrimaryColor3Text] = useState('');
  const [paleColor, setPaleColor] = useState('');
  const [paleColorText, setPaleColorText] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [colorProps, setColorProps] = useState(null);

  const fileRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColorProps({ primaryColor, primaryColorText, primaryColor2, primaryColor2Text, primaryColor3, primaryColor3Text, paleColor, paleColorText });
    }, 0.5 * 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [primaryColor, primaryColorText, primaryColor2, primaryColor2Text, primaryColor3, primaryColor3Text, paleColor, paleColorText])


  useEffect(() => {
    fetch('/data/companycustomization', {
      headers: {
        'X-CustomerId': getCustomerId() || 'zeitro',
      }
    })
      .then(async response => {
        if (!response.ok) {
          return;
        }
        response.json().then(json => {
          if (Object.keys(JSON.parse(json.Customization)).includes('primaryColor')) {
            let { primaryColor, primaryColorText, primaryColor2, primaryColor2Text, primaryColor3,
              primaryColor3Text, paleColor, paleColorText, secondaryColor, icon } = JSON.parse(json.Customization);
            console.log(primaryColor);
            setPrimaryColor(primaryColor);
            setPrimaryColorText(primaryColorText);
            setPrimaryColor2(primaryColor2);
            setPrimaryColor2Text(primaryColor2Text);
            setPrimaryColor3(primaryColor3);
            setPrimaryColor3Text(primaryColor3Text);
            setPaleColor(paleColor);
            setPaleColorText(paleColorText);
            setSecondaryColor(secondaryColor);
            setIconB64(icon);
          }
        })
      });
  }, [])

  const formIsValid = paleColorText && secondaryColor && primaryColor && primaryColorText && primaryColor2 &&
    primaryColor2Text && primaryColor3 && primaryColor3Text && paleColor;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (formIsValid) {

      const token = sessionStorage.getItem("ZeitroA")

      const body = JSON.stringify({
        PrimaryColor: primaryColor,
        PrimaryColorText: primaryColorText,
        PrimaryColor2: primaryColor2,
        PrimaryColor2Text: primaryColor2Text,
        PrimaryColor3: primaryColor3,
        PrimaryColor3Text: primaryColor3Text,
        PaleColor: paleColor,
        PaleColorText: paleColorText,
        SecondaryColor: secondaryColor,
        File: iconB64 || '',
      });

      fetch('/los/updatecustomization', {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body,
      }).then(response => {
        if (!response.ok) {
          alert(`contact engineering team please, error: ${response.statusText}`);
          return;
        }
        alert('Styles are updated successfully, your page will be reloaded');
        window.location.reload()
      }).finally(() => {
        setIconB64(null);
        fileRef.current.value = '';
        setPrimaryColor('');
        setPrimaryColorText('');
        setPrimaryColor2('');
        setPrimaryColor2Text('');
        setPrimaryColor3('');
        setPrimaryColor3Text('');
        setPaleColor('');
        setPaleColorText('');
        setSecondaryColor('');
        setColorProps(null);
        setSubmitted(false);
      })
    } else {
      alert('Colors are not chosen');
    }
  };

  const iconUploadHandler = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      let base64String;

      reader.onload = function () {
        base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        setIconB64(base64String);
      }
      reader.readAsDataURL(file);
    }
  }


  const getHSPValue = (r, g, b) => {
    return r * 0.299 + g * 0.587 + b * 0.114
  }


  const hexToRgb = (hex) => {
    const rgb = hex.substring(1);
    let r = parseInt(rgb.substring(0, 2), 16);
    let g = parseInt(rgb.substring(2, 4), 16);
    let b = parseInt(rgb.substring(4, 6), 16);

    return [r, g, b];
  }

  const isColorBright = (hex) => {
    // based on theory, if more then color is bright, if <= then dark
    const HSPthreshold = 150;
    let [r, g, b] = hexToRgb(hex);
    //color HSP value
    let hsp = getHSPValue(r, g, b);
    return hsp > HSPthreshold;
  }

  const colorsGeneration = (hex) => {
    // https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
    const RGB_Linear_Shade = (p, c) => {
      var i = parseInt, r = Math.round, [a, b, c] = c.split(","), P = p < 0, t = P ? 0 : 255 * p, P = P ? 1 + p : 1 - p;
      return [Math.round(r(i(a[3] === "a" ? a.slice(5) : a.slice(4)) * P + t)), Math.round(r(i(b) * P + t)), Math.round((i(c) * P + t))];
    }



    function hslToRgb(h, s, l) {
      var r, g, b;

      if (s === 0) {
        r = g = b = l; // achromatic
      } else {
        var hue2rgb = function hue2rgb(p, q, t) {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    function rgbToHsl(r, g, b) {
      let R = r / 255, G = g / 255, B = b / 255;
      var max = Math.max(R, G, B), min = Math.min(R, G, B);
      var h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case R: h = (G - B) / d + (G < B ? 6 : 0); break;
          case G: h = (B - R) / d + 2; break;
          case B: h = (R - G) / d + 4; break;
          default: ;
        }
        h /= 6;
      }

      return [Math.floor(h * 360), Math.floor(s * 100), Math.floor(l * 100)];
    }

    function rgbToHex(r, g, b) {
      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    const primaryColor = hex;

    let [r, g, b] = hexToRgb(primaryColor);

    let primaryColorText;
    let primaryColor2;
    let primaryColor2Text;
    let primaryColor3;
    let primaryColor3Text;
    let paleColor;
    let paleColorText;

    if (isColorBright(primaryColor)) {
      let primaryRGB = `rgb(${r},${g},${b})`
      primaryColor2 = rgbToHex(...RGB_Linear_Shade(-0.20, primaryRGB));
      primaryColor3 = rgbToHex(...RGB_Linear_Shade(-0.40, primaryRGB));
      primaryColorText = black;

      let [h, s, l] = rgbToHsl(r, g, b);

      if (s < 25 || s > 95) {
        s = 25 + 70 * Math.random();
      }

      if (l < 85 || l > 95) {
        l = 85 + 10 * Math.random()
      }

      let [paleR, paleG, paleB] = hslToRgb(h / 360, s / 100, l / 100);

      paleColor = rgbToHex(paleR, paleG, paleB);

    } else {
      let primaryRGB = `rgb(${r},${g},${b})`
      primaryColor2 = rgbToHex(...RGB_Linear_Shade(0.20, primaryRGB));
      const [primaryColor3R, primaryColor3G, primaryColor3B] = RGB_Linear_Shade(0.40, primaryRGB);
      primaryColor3 = rgbToHex(primaryColor3R, primaryColor3G, primaryColor3B);
      primaryColorText = white;

      let [h, s, l] = rgbToHsl(primaryColor3R, primaryColor3G, primaryColor3B);

      if (s < 25 || s > 95) {
        s = 25 + 70 * Math.random();
      }

      if (l < 85 || l > 95) {
        l = 85 + 10 * Math.random()
      }

      let [paleR, paleG, paleB] = hslToRgb(h / 360, s / 100, l / 100);

      paleColor = rgbToHex(paleR, paleG, paleB);
    }

    primaryColor2Text = isColorBright(primaryColor2) ? black : white;
    primaryColor3Text = isColorBright(primaryColor3) ? black : white;
    paleColorText = isColorBright(paleColor) ? black : white;

    return [primaryColor, primaryColorText, primaryColor2, primaryColor2Text, primaryColor3, primaryColor3Text, paleColor, paleColorText];
  }

  const primaryColorSelectHandler = (event) => {
    if (palletAuto){
      let [primaryColor, primaryColorText, primaryColor2, primaryColor2Text, primaryColor3, primaryColor3Text, paleColor, paleColorText] = colorsGeneration(event.target.value);
  
      setPrimaryColor(primaryColor);
      setPrimaryColorText(primaryColorText);
      setPrimaryColor2(primaryColor2);
      setPrimaryColor2Text(primaryColor2Text);
      setPrimaryColor3(primaryColor3);
      setPrimaryColor3Text(primaryColor3Text);
      setPaleColor(paleColor);
      setPaleColorText(paleColorText);
    } else {
      setPrimaryColor(event.target.value);
    }
  };

  const primaryColorTextSelectHandler = event => {
    setPrimaryColorText(event.target.value);
  }

  const primaryColor2SelectHandler = event => {
    const color = event.target.value;
    setPrimaryColor2(color);
    const textColor = isColorBright(color) ? black : white;
    setPrimaryColor2Text(textColor);
  }

  const primaryColor2TextSelectHandler = event => {
    setPrimaryColor2Text(event.target.value);
  }

  const primaryColor3SelectHandler = event => {
    const color = event.target.value;
    setPrimaryColor3(color);
    const textColor = isColorBright(color) ? black : white;
    setPrimaryColor3Text(textColor);
  }

  const primaryColor3TextSelectHandler = event => {
    setPrimaryColor3Text(event.target.value);
  }

  const paleColorSelectHandler = event => {
    const color = event.target.value;
    setPaleColor(color);
    const textColor = isColorBright(color) ? black : white;
    setPaleColorText(textColor);
  }

  const paleColorTextSelectHandler = event => {
    setPaleColorText(event.target.value);
  }

  const secondaryColorSelectHandler = (event) => {
    setSecondaryColor(event.target.value);
  };

  const palletChangeHandler = e => {
    setPalletAuto(e.target.checked);
  }

  return (
    <Form onSubmit={formSubmitHandler} className={classes['m-1']}>
      <Form.Group className="mb-3" controlId="pallet">
        <Form.Check type="checkbox" label="Auto adjust color palette based on primary color" checked={palletAuto} onChange={palletChangeHandler} />
      </Form.Group>
      <div>
        <Row>
          <Form.Group key='5432' as={Col} controlId="icon" className={`mb-3 ${submitted && !iconB64 ? classes['invalid-input'] : ''}`}>
            <Form.Label>Upload Icon File</Form.Label>
            <Form.Control type="file" onChange={iconUploadHandler} ref={fileRef} />
          </Form.Group >
          <Col>
            <Form.Group className={submitted && !primaryColor ? classes['invalid-input'] : ''} controlId="primaryColor">
              <Form.Label>Primary color</Form.Label>
              <Form.Control
                type="color"
                title="Choose your color"
                className={classes['color-picker']}
                onChange={primaryColorSelectHandler}
                value={primaryColor}
              />
            </Form.Group>
            {primaryColor &&
              <>
                <Form.Group className={submitted && !primaryColorText ? classes['invalid-input'] : ''} controlId="primaryColorText">
                  <Form.Label>Primary color - text</Form.Label>
                  <Form.Control
                    type="color"
                    title="Choose your color"
                    className={classes['color-picker']}
                    onChange={primaryColorTextSelectHandler}
                    value={primaryColorText}
                  />
                </Form.Group>
                <div className={classes.example} style={{ backgroundColor: primaryColor, color: primaryColorText }}>
                  example
                </div>
              </>
            }
          </Col>
          {primaryColor &&
            <>
              <Col>
                <Form.Group className={submitted && !primaryColor2 ? classes['invalid-input'] : ''} controlId="primaryColor2">
                  <Form.Label>Primary color - 2</Form.Label>
                  <Form.Control
                    type="color"
                    title="Choose your color"
                    className={classes['color-picker']}
                    onChange={primaryColor2SelectHandler}
                    value={primaryColor2}
                  />
                </Form.Group>
                <Form.Group className={submitted && !primaryColor2Text ? classes['invalid-input'] : ''} controlId="primaryColor2Text">
                  <Form.Label>Primary color - 2 text</Form.Label>
                  <Form.Control
                    type="color"
                    title="Choose your color"
                    className={classes['color-picker']}
                    onChange={primaryColor2TextSelectHandler}
                    value={primaryColor2Text}
                  />
                </Form.Group>
                <div className={classes.example} style={{ backgroundColor: primaryColor2, color: primaryColor2Text }}>
                  example
                </div>
              </Col>
              <Col>
                <Form.Group className={submitted && !primaryColor3 ? classes['invalid-input'] : ''} controlId='primaryColor3'>
                  <Form.Label>Primary color - 3</Form.Label>
                  <Form.Control
                    type="color"
                    title="Choose your color"
                    className={classes['color-picker']}
                    onChange={primaryColor3SelectHandler}
                    value={primaryColor3}
                  />
                </Form.Group>
                <Form.Group className={submitted && !primaryColor3Text ? classes['invalid-input'] : ''} controlId='primaryColor3Text'>
                  <Form.Label>Primary color - 3 text</Form.Label>
                  <Form.Control
                    type="color"
                    title="Choose your color"
                    className={classes['color-picker']}
                    onChange={primaryColor3TextSelectHandler}
                    value={primaryColor3Text}
                  />
                </Form.Group>
                <div className={classes.example} style={{ backgroundColor: primaryColor3, color: primaryColor3Text }}>
                  example
                </div>
              </Col>
              <Col>
                <Form.Group className={submitted && !paleColor ? classes['invalid-input'] : ''} controlId='paleColor'>
                  <Form.Label>Pale color</Form.Label>
                  <Form.Control
                    type="color"
                    title="Choose your color"
                    className={classes['color-picker']}
                    onChange={paleColorSelectHandler}
                    value={paleColor}
                  />
                </Form.Group>
                <Form.Group className={submitted && !paleColorText ? classes['invalid-input'] : ''} controlId='paleColorText'>
                  <Form.Label>Primary color - 3 text</Form.Label>
                  <Form.Control
                    type="color"
                    title="Choose your color"
                    className={classes['color-picker']}
                    onChange={paleColorTextSelectHandler}
                    value={paleColorText}
                  />
                </Form.Group>
                <div className={classes.example} style={{ backgroundColor: paleColor, color: paleColorText }}>
                  example
                </div>
              </Col>
            </>
          }
          <Form.Group as={Col} className={submitted && !secondaryColor ? classes['invalid-input'] : ''} controlId='paleColorText'>
            <Form.Label>Secondary color</Form.Label>
            <Form.Control
              type="color"
              title="Choose your color"
              className={classes['color-picker']}
              onChange={secondaryColorSelectHandler}
              value={secondaryColor}
            />

          </Form.Group>
        </Row>
      </div>
      {colorProps && colorProps.primaryColor2Text && <CustomizationPreview colors={colorProps} logo={iconB64} />}
      <Row className='m-3'>
        <Col>
          <Button variant="primary" type='submit'>
            Submit
          </Button>
        </Col>
      </Row>

    </Form>
  );
}

export default UICustomization;