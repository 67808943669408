import React, { useState, useEffect, useRef } from "react";
import Menu from './Menu'
import InnerHTML from "dangerously-set-html-content";
import { Col, Row, Card, Container } from "react-bootstrap";
import { Link } from 'react-router-dom'

const BorrowerLoginPage = (props) => {

  let agentID = props["agentID"] === undefined ? "" :props["agentID"]
  let html = `<script id="zeitrotag" src=https://app.zeitro.us/api/zeitrotag.js?customerid=individualcustomers&loid=`+agentID+`&pageid=login> </script>`;
  let host = window.location.host

  if (host == "agent.zeitro.com") {
    html = `<script id="zeitrotag" src=https://app.zeitro.com/api/zeitrotag.js?customerid=individualcustomers&loid=`+agentID+`&pageid=login> </script>`
  }

  if (host.includes("localhost") || host.includes("192.")) {
    // html = `<script id="zeitrotag" src=http://localhost:3000/api/zeitrotag.js?customerid=individualcustomers&loid=5e170df0-bf21-468c-bbb0-97280ff64fa0&pageid=agentborrowersignup> </script>`
    // html = `<script id="zeitrotag" src=http://localhost:3000/api/zeitrotag.js?customerid=individualcustomers&loid=5e170df0-bf21-468c-bbb0-97280ff64fa0&pageid=purchase> </script>`
    html = `<script id="zeitrotag" src=http://localhost:3000/api/zeitrotag.js?customerid=individualcustomers&loid=`+agentID+`&pageid=login> </script>`

  }
  
  return (
    <div>
      <Menu {...props}/>
      <div className="mt-5">
        <Container>
          <Row className="text-center mb-1">
          </Row>
        <InnerHTML html={html} />
        <Row className="text-center">
          <Col>
          <Link to= "/services/forgotpassword">Forgot your password?</Link>
          </Col>
          </Row>
        <Row className="text-center">
          <Col>
          <Link to= {props.url + "/services/signup"}>Sign up for a new account</Link>
          </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BorrowerLoginPage;
