import React, { useState, useEffect, useRef } from "react";
import "./ThirdPartyResources.css";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as com from "../../Common";
import StateSelector from "../../StateSelector";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const AddNewResources = (props) => {
  const [validated, setValidated] = useState(false);
  const [resourceType, setResourceType] = useState("");
  const [resourceName, setResourceName] = useState("");
  const [resourceEmail, setResourceEmail] = useState("");
  const [resourceCompany, setResourceCompany] = useState("");
  const [resourcePhone, setResourcePhone] = useState("");
  const [resourceLicenseState, setResourceLicenseState] = useState("");
  const [resourceLicenseNumber, setResourceLicenseNumber] = useState("");
  const [resourceWebsite, setResourceWebsite] = useState("");
  const [toBeAdded, setToBeAdded] = useState([]);
  const [toRemoveIndex, setToRemoveIndex] = useState(-1);
  const formRef = useRef(null);
  const handleReset = () => {
    formRef.current.reset();
    setResourceType("");
    setResourceName("");
    setResourceEmail("");
    setResourceCompany("");
    setResourcePhone("");
    setResourceLicenseNumber("");
    setResourceLicenseState("");
    setResourceWebsite("");
    setValidated(false);
  };
  useEffect(() => {
    if (toRemoveIndex >= 0) {
      let tmp = [...toBeAdded];
      tmp.splice(toRemoveIndex, 1);
      setToBeAdded(tmp);
      setToRemoveIndex(-1);
    }
  }, [toRemoveIndex]);

  const changePhone = (e) => {
    let phone = com.fixPhoneInput(e.target.value);
    setResourcePhone(phone);
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    let lst = [];
    lst.push({
      Type: resourceType,
      Company: resourceCompany,
      Name: resourceName,
      Email: resourceEmail,
      Phone: resourcePhone,
      NMLS: resourceLicenseNumber,
      Website: resourceWebsite,
      LicenseState: resourceLicenseState,
    });

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/saveresources", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(lst),
    })
      .then((response) => {
        if (response.status !== 200) {
          alert("Looks like there was a problem, please contact Zeitro for help.");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error!");
          } else {
            alert("Your third party resources have been saved!");
            // window.location.reload();
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error:/re/saveresources:-S", err);
      });

  };

  const createToBeAddedTable = () => {
    const actionsFormatter = (cell, row, i) => (
      <div>
        <Button
          variant="remove"
          onClick={() => {
            console.log(i);
            setToRemoveIndex(i);
          }}
        >
          Remove
        </Button>
      </div>
    );
    let headerStyle = {
      backgroundColor: "white",
      borderBottom: "solid 2px",
      borderTop: "solid 2px",
      fontSize: "120%",
    };
    let columns = [
      {
        dataField: "Type",
        text: "Type",
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: "Name",
        text: "Name",
        headerStyle: headerStyle,
      },
      {
        dataField: "Company",
        text: "Company/Broker",
        headerStyle: headerStyle,
      },
      {
        dataField: "Email",
        text: "Email",
        headerStyle: headerStyle,
      },
      {
        dataField: "Phone",
        text: "Phone",
        headerStyle: headerStyle,
      },
      {
        dataField: "NMLS",
        text: "NMLS",
        headerStyle: headerStyle,
      },
      {
        dataField: "LicenseState",
        text: "License State",
        headerStyle: headerStyle,
      },
      {
        dataField: "Website",
        text: "Website",
        headerStyle: headerStyle,
      },
      {
        dataField: "Remove",
        text: "",
        isDummyField: true,
        formatter: actionsFormatter,
        headerStyle: headerStyle,
      },
    ];
    const saveResouces = () => {
      let token = sessionStorage.getItem("ZeitroA");

      fetch("/re/saveresources", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body: JSON.stringify(toBeAdded),
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log("Looks like there was a problem: ", response);
            return;
          }
          // Examine the text in the response
          response.json().then((js) => {
            if (js.Status !== "OK") {
              alert("Error!");
            } else {
              setToBeAdded([]);
              alert("Your third party resources have been saved!");
              window.location.reload();
            }
          });
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
        });
    };
    return (
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="email"
          data={toBeAdded}
          columns={columns}
          search
        >
          {(props) => (
            <div className="toBeAdded">
              <Row className="text-left mb-">
                <h5 className="pt-2">Resources To Be Added</h5>
                <Button
                  className="ml-2 saveResourceBtn"
                  onClick={() => saveResouces()}
                >
                  Save
                </Button>
              </Row>
              <BootstrapTable
                id="resources"
                bootstrap4
                bordered={false}
                {...props.baseProps}
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  };

  return (
    <div>
      <h5 className="mt-4 pl-5">
        <b>Add New Third Party Resources</b>
      </h5>
      <div className="pl-5 pr-5">
        Added resouorces will be displayed on your personal landing page.
      </div>
      <div className="addNewResource">
        {/* <Row>
          Search resource's name, company, email: ## fuzzy search here
          <input className="ml-3"></input>
        </Row> */}
        <Form ref={formRef} validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col className="text-left">
              <Form.Group controlId="email">
                <Form.Label className="text-left">Type:</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    name="resourceType"
                    type="text"
                    size="sm"
                    defaultValue={resourceType}
                    value={resourceType}
                    onChange={(e) => {
                      setResourceType(e.target.value);
                    }}
                  />
                  <DropdownButton
                    as={InputGroup.Append}
                    className="state-dropdown-menu"
                    variant="dropdown"
                    title=""
                    size="sm"
                    autoComplete="state"
                    onSelect={(e) => setResourceType(e)}
                  >
                    <Dropdown.Item
                      key="General Inspectors"
                      eventKey="General Inspectors"
                      href="#"
                    >
                      General Inspectors
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="Foundation Inspector"
                      eventKey="Foundation Inspector"
                      href="#"
                    >
                      Foundation Inspector
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="Insurance Agent"
                      eventKey="Insurance Agent"
                      href="#"
                    >
                      Insurance Agent
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="Make Ready Contractor"
                      eventKey="Make Ready Contractor"
                      href="#"
                    >
                      Make Ready Contractor
                    </Dropdown.Item>
                    <Dropdown.Item key="Roofer" eventKey="Roofer" href="#">
                      Roofer
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="HVAC Tech"
                      eventKey="HVAC Tech"
                      href="#"
                    >
                      HVAC Tech
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="Pool Contractor"
                      eventKey="Pool Contractor"
                      href="#"
                    >
                      Pool Contractor
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="Electrician"
                      eventKey="Electrician"
                      href="#"
                    >
                      Electrician
                    </Dropdown.Item>
                    <Dropdown.Item key="Plumber" eventKey="Plumber" href="#">
                      Plumber
                    </Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="name">
                <Form.Label className="text-left">Name:</Form.Label>
                <Form.Control
                  required
                  name="name"
                  size="sm"
                  autoFocus
                  autoComplete="name"
                  type="name"
                  defaultValue={resourceName}
                  onChange={(e) => {
                    setResourceName(e.target.value);
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="resourceCompany">
                <Form.Label className="text-left">Company/Broker:</Form.Label>
                <Form.Control
                  required
                  name="resourceCompany"
                  size="sm"
                  autoFocus
                  autoComplete="resourceCompany"
                  type="resourceCompany"
                  defaultValue={resourceCompany}
                  onChange={(e) => {
                    setResourceCompany(e.target.value);
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid company/broker.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="email">
                <Form.Label className="text-left">Email:</Form.Label>
                <Form.Control
                  required
                  name="resourceEmail"
                  size="sm"
                  autoFocus
                  autoComplete="resourceEmail"
                  type="resourceEmail"
                  defaultValue={resourceEmail}
                  onChange={(e) => {
                    setResourceEmail(e.target.value);
                  }}
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="phone" className="text-left">
                <Form.Label className="text-left">Phone:</Form.Label>
                <Form.Control
                  isValid={false}
                  size="sm"
                  required
                  name="phone"
                  type="text"
                  value={resourcePhone}
                  onChange={changePhone}
                  pattern={com.phoneRegex}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please provide contact phone.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* <Row>
            <Col className="text-left">
              <Form.Group controlId="licenseState" className="text-left">
                <Form.Label className="text-left">License State(optional):</Form.Label>
                <StateSelector
                  isValid={false}
                  size="sm"
                  name="resourceLicenseState"
                  autoComplete="resourceLicenseState"
                  type="text"
                  placeholder="##"
                  value={resourceLicenseState}
                  onChange={(e) => setResourceLicenseState(e.target.value)}
                  pattern="^[A-Z]{2,2}$"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please provide valid state, for example, CA.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="resouceLicenseNumber">
                <Form.Label className="text-left">License No. (optional):</Form.Label>
                <Form.Control
                  name="resouceLicenseNumber"
                  size="sm"
                  autoFocus
                  autoComplete="resouceLicenseNumber"
                  type="resouceLicenseNumber"
                  defaultValue={resourceLicenseNumber}
                  onChange={(e) => setResourceLicenseNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="resourceWebsite">
                <Form.Label className="text-left">URL (optional):</Form.Label>
                <Form.Control
                  name="resourceWebsite"
                  size="sm"
                  autoFocus
                  autoComplete="resourceWebsite"
                  type="resourceWebsite"
                  defaultValue={resourceWebsite}
                  onChange={(e) => setResourceWebsite(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Button
                type="submit"
                style={{ marginTop: "26px", height: "32px" }}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {toBeAdded.length > 0 && createToBeAddedTable()}
    </div>
  );
};

export default AddNewResources;
