import { TOGGLE_COLUMN, INITIALIZE_LAYOUT } from '../Defs/pipelinecolumnsdefs'

// doesn't make sense to create a yaml definition

const defaultState = { // keys match dataFields from Borrowers.js
  errors: true,
  comments: true,
  accountCreated: true,
  lender: true,
  interviewSubmission: true,
  loanRegisteredWithLender: true,
  LERequested: true,
  threeDeathDays: true,
  initialDisclosresIntentProceedBack: true,
  loanRunThroughDO: true,
  openedEscrow: true,
  escrowFeesReceived: true,
  prelimReceived: true,
  documentsRequested: true,
  documentsReceived: true,
  loanProcessed: true,
  rateLocked: true,
  lockExpires: true,
  confirmedLockWithBorrower: true,
  loanPackageSubmitted: true,
  loanDecisioned: true,
  piwAce: true,
  appraisalOrdered: true,
  ptdsSignedOff: true,
  CDOrdered: true,
  CDOut: true,
  docsOrdered: true,
  docsOut: true,
  docsScheduled: true,
  docsSigned: true,
  docsBack: true,
  ptfsSubmitted: true,
  ptfsSignedOff: true,
  loanFunded: true,
  doLpa: true,
  loanAmount: true,
  totalBrokerCompensation: true,
  appraisalReceived: true,
  ptdsSubmitted: true,
  estimatedClosingDate: true,
  submittedToUW: true,
  lockRequested: true,
  lenderCaseNumber: true,
  clearToClose: true,
  CDAcknowledged: true,
  appraisalBack: true,
  conditionsRequested: true,
  conditionsSubmitted: true,
  disbursementDate: true,
  scheduledDisbursementDate: true,
  status: true,
  substatus: true
}

export default function pipelineColumnsReducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_COLUMN: {
      state[action.column] = action.payload
      break;
    }
    case INITIALIZE_LAYOUT: {
      state = action.configuration
      break;
    }
    default:
      break;
  }


  return JSON.parse(JSON.stringify(state))
}