import React from 'react';

import classes from '../CustomizationPreview.module.css';

import { companyName } from '../../../../../Common';

const Header = props => {

  const { colors, logo } = props;

  return <header className={classes.header}>
    <img src={`data:image/png;base64, ${logo}`} alt='logo' />
    <span style={{ color: colors.primaryColor }}>{companyName || "Zeitro"}</span>
  </header>;
}

export default Header;