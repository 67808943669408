import React, { useState, useEffect } from "react";
import "./LandingPageSetup.css"
import "./LandingPage.css"
import { Button } from "react-bootstrap";

const LandingPageSetup = (props) => {
    return (
        <div className="landingPageSetupRoot">
            <div className="landingPageSetupHeader">
                <div className="landingPageSetupTitle">Editing my page</div>
                <div className="landingPageSetupButtons">
                    <Button variant="landingPageSetupButton">Save</Button>
                    <Button variant="landingPageSetupButton">Cancel</Button>
                </div>
            </div>
            <div className="setupHeader">
                <div className="landingPageSetupSubTitle">NAVIGATION</div>
            </div>
        </div>
    )
}

export default LandingPageSetup;