import React, { useState, useEffect } from "react";
import "./App.css";
import "./Admin.css";
import Error from "./Error";
import Loading from "./Loading";
import AuthenticatedApp from "./AuthenticatedApp";
import LandingPage from "./LandingPage/LandingPage";
import LandingPageSetup from "./LandingPage/LandingPageSetup";
import NewLandingPage from './LandingPage/NewLandingPage';
import LandingPreview from './LandingPage/LandingPreview';
// import LoginPage from "./LoginPage";
import LoginPage from "./LoginPage-new";
// import SignupPage from "./SignupPage";
import SignupPage from "./SignUpPage-new";
import LogoutPage from "./LogoutPage";
import Customer from "./Customer";
import ForgotPassword from "./ForgotPassword";
import ResetAgentPassword from "./ResetAgentPassword";
import BorrowerLoginPage from "./BorrowerLoginPage";
import BorrowerSignUpPage from "./BorrowerSignUpPage";
import BorrowerForgotPasswordPage from "./BorrowerForgotPasswordPage";
import BorrowerResetPasswordPage from "./BorrowerResetPasswordPage";
import BorrowerHandoffPage from "./BorrowerHandoffPage";
import Validation from "./Validation";
import Validate, { ValidateAgent } from "./Validate";
import "./fonts/gilroy-regular-3-webfont.woff2";
import "./fonts/gilroy-medium-2-webfont.woff2";
import "./fonts/gilroy-bold-4-webfont.woff2";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { insertCssColors } from "./Common";

let stylesNotApplied = true;

if (stylesNotApplied) {
  const customerID = localStorage.getItem("customerid");
  insertCssColors(customerID);
  stylesNotApplied = false;
}

function App() {
  const [loaded, setLoaded] = useState(false);
  const [landingPages, setLandingPages] = useState([]);

  const generateRealEstateLanding = () => {
    setLoaded(false);
    fetch("/re/getlandingurls", {
      method: "GET",
      headers: {
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          setLoaded(true);
          return;
        }
        response.json().then((js) => {
          let tmp = [];
          for (let id in js) {
            let url = js[id]["url"];
            let name = js[id]["name"];
            let siteversion = js[id]["siteversion"];

            tmp.push(
              <Route exact path={url}>
                {siteversion=== "2" ? <LandingPage customerid="individualcustomers" id={id} url={url} />: <NewLandingPage customerid="individualcustomers" loid={id} url={url}  />}
              </Route>
            );
            tmp.push(
              <Route exact path={url + "/services/signin"}>
                <BorrowerLoginPage url={url} agentID={id} name={name}/>
              </Route>
            );
            tmp.push(
              <Route exact path={url + "/services/signup/"}>
                <BorrowerSignUpPage view="signup" agentID={id} url={url} name={name}/>
              </Route>
            );
            tmp.push(
              <Route exact path="/handoff">
                <BorrowerHandoffPage url={url} name={name}/>
              </Route>
            );
            tmp.push(
              <Route exact path="/services/new-landing-page">
                <NewLandingPage id={id} url={url} />
              </Route>
            );
            tmp.push(
              <Route exact path="/services/landing-review">
                <LandingPreview id={id} url={url} />
              </Route>
            );
          }
          tmp.push(
            <Route  exact path="/validated/:loid/:borrowerid">
              <Validate />
            </Route>
          );
          tmp.push(
            <Route exact path="/services/forgotpassword">
              <BorrowerForgotPasswordPage />
            </Route>
          );
          tmp.push(
            <Route  exact path="/services/resetpassword">
              <BorrowerResetPasswordPage />
            </Route>
          );
          setLandingPages(tmp);
          setLoaded(true);
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :", err);
        setLoaded(true);
      });
  };

  useEffect(() => {
    generateRealEstateLanding();
  }, []);

  return (
    <Router>
      <div className="App py-0 my-0">
        <Helmet>
          <title>Zeitro</title>
        </Helmet>

        <Switch>
        <Route exact path="/">
            <LoginPage />
          </Route>
        {/* {!window.location.host.includes("awpickelhomeloans.com") ? 
          <Route exact path="/">
            <LoginPage />
          </Route> :
            <Route exact path="/">
                <NewLandingPage customerid="individualcustomers" loid="38fb0348-c3fe-4d4a-805d-8d63d7b36d03" url=""  />
              </Route>} */}

          <Route path="/app">
            <AuthenticatedApp />
          </Route>
          <Route exact path="/services/logout">
            <LogoutPage />
          </Route>

          <Route exact path="/re/signup">
            <SignupPage />
          </Route>

          <Route exact path="/admin/signup">
            <SignupPage fromAdmin={true}/>
          </Route>

          <Route
            exact
            path="/re/signup/fromre/:loemail"
            render={(props) => <SignupPage {...props.match.params} />}
          />
          <Route
            exact
            path="/re/signup/fromlo/:reemail"
            render={(props) => <SignupPage {...props.match.params} />}
          />
          {/* <Route exact path="/re/signup/fromre/:loemail"> */}
          {/* <SignupPage />
          </Route> */}
          <Route exact path="/signupsuccess">
            <LoginPage fromsignup={true} />
          </Route>
          <Route
            exact
            path="/validatedagent/:email"
            render={(props) => <ValidateAgent {...props.match.params} />}
          />
          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route
            exact
            path="/resetagentpassword/:email"
            render={(props) => <ResetAgentPassword {...props.match.params} />}
          />
          <Route exact path="/services/validate">
            <Validation />
          </Route>

          <Route exact path="/landingpagesetup">
            <LandingPageSetup />
          </Route>
          
          {loaded ? (
            landingPages
          ) : (
            <Route>
              <Loading />
            </Route>
          )}


          <Route>
            <Error code={404} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
