import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import * as com from "../Common.js"
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import { Link } from 'react-router-dom'
import InputGroup from 'react-bootstrap/InputGroup'
import { tooltip } from "../Common/Tooltip";
import Steps from 'awesome-steps'
import { countSubjectMortgages } from './AlimonySolar'
import 'awesome-steps/dist/style.css'
import { mockUCS } from "./mock";
import { getCurrentState } from '../Store'

var parser = require('parse-address');

const mapStateToProps = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    copyBorrowerLoan: (p, who) => {
        dispatch(act.CopyBorrowerLoan(p, who))
    },
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },
    changeBorrowerLoanMonthly: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanMonthly(event.target.value, i, who))
    },
    changeBorrowerLoanSatisfiedUpon: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanSatisfiedUpon(event.target.value, i, who))
    },
    changeBorrowerLoanRate: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanRate(event.target.value, i, who))
    },
    changeBorrowerLoanForSubject: (val, i, who) => {
        dispatch(act.ChangeBorrowerLoanForSubject(val, i, who))
    },
    changeBorrowerLoanCorrection: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanCorrection(event.target.value, i, who))
    },
    changeBorrowerLoanExplanation: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanExplanation(event.target.value, i, who))
    },
    updateFinances: (t, who, verb) => {
        dispatch(act.UpdateFinances(t, who, verb))
    },
    addBorrowerAdditionaCredits: (event, who) => {
        dispatch(act.AddBorrowerAdditionaCredits(event, who))
    },
    clearBorrowerAdditionalCredits: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalCredits(event, who))
    },
    updateJointly: (event) => {
        dispatch(act.UpdateJointly(event.target.checked))
    },
    updateApplicationAttribute: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.value, verb))
    },
    updateFico: (score) => {
        dispatch(act.UpdateFico(score))
    },
    updateCRErrors: (ar) => {
        dispatch(act.UpdateCRErrors(ar))
    },
    changeBorrowerInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeBorrowerFreeAndClear: (payload, who) => {
        dispatch(act.ChangeBorrowerFreeAndClear(payload, who))
    },
});

export function calculateBalancesAndPayments() {
    let firstmortgage = 0
    let firstmortgagebalance = 0
    let othermortgage = 0
    let otherbalance = 0
    let cashout = 0
    let loanmount = 0
    let subjectmortgages = countSubjectMortgages(this.props.application.property.purpose,
        this.props.application.assetsandliabilities.loans,
        this.props.application.otherliens)
    let loans = this.props.application.assetsandliabilities.loans
    let len = loans.length
    let otherliens = this.props.application.otherliens

    if (subjectmortgages === 1 && this.props.application.property.purpose !== st.POL_Purchase) {
        // mark the only mortgage as primary
        for (let index = 0; index < len; index++) {
            let loan = loans[index]
            if (loan.forsubjectproperty === true) {
                loan.isprimary = true
            }
        }
        if (otherliens != null) {
            for (let index = 0; index < otherliens.length; index++) {
                let loan = otherliens[index]
                loan.isprimary = true
                loan.satisfiedupon = "withproceeds"
            }
        }
    }
    if (null !== loans) {

        for (let index = 0; index < len; index++) {
            let loan = loans[index]
            if (com.isHomeloan(loan)) {
                if (loan.correction !== "none"
                    && loan.correction !== "")
                    continue
                let mopayment = parseInt(loan.monthlypayment)
                let rembalance = parseInt(loan.remainingbalance)
                if (this.props.application.property.purpose !== st.POL_Purchase && loan.forsubjectproperty
                ) {
                    if (loan.satisfiedupon === "withproceeds") {
                        if (loan.isprimary) {
                            firstmortgage += mopayment
                            firstmortgagebalance += rembalance
                        } else {
                            if (this.props.application.property.purpose === st.POL_Refinance &&
                                loan.partoforiginalpurchase === true) {
                                othermortgage += mopayment
                                otherbalance += rembalance
                            } else {
                                othermortgage += mopayment
                                otherbalance += rembalance
                                cashout += rembalance
                            }
                        }
                    }
                }

                if (this.props.application.property.purpose === st.POL_Purchase &&
                    loan.forprimaryresidence) {
                    if (firstmortgagebalance === 0) {
                        firstmortgage += mopayment
                        firstmortgagebalance += rembalance
                    } else {
                        othermortgage += mopayment
                        otherbalance += rembalance
                        cashout += rembalance
                    }
                }
            } else {
                if (loan.correction !== "none" && loan.correction !== "")
                    continue
                // add for cashout
                if (this.props.application.property.purpose === st.POL_Cashoutrefinance &&
                    loan.satisfiedupon === "withproceeds")
                    cashout += parseInt(loan.remainingbalance)
            }
        }
    }

    if (otherliens != null) {
        for (let i = 0; i < otherliens.length; i++) {
            let lien = otherliens[i]
            if ("" !== lien.balance) {

                if (lien.isHELOC) {
                    if (lien.satisfiedupon !== "withproceeds")
                        continue
                }
                firstmortgagebalance += parseInt(lien.balance)
                firstmortgage += parseInt(lien.monthly)

            }
        }
    }
    switch (this.props.application.property.purpose) {
        case st.POL_Refinance:
            loanmount = firstmortgagebalance + otherbalance
            break
        case st.POL_Cashoutrefinance:
            loanmount = firstmortgagebalance + cashout
            break

        default:
            break
    }

    return [firstmortgage, firstmortgagebalance, othermortgage, otherbalance, cashout, loanmount]
}

export class Liabilities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingcr: false,
            show: {},
            usestepper: true,
            status: "process",
            expand: [],
            stepsandsubsteps: [],
            observedIndex: 0,
            showWarning: false,
            message: ""
        }
        if (null === this.props.application.assetsandliabilities.loansreviewed) {
            this.props.application.assetsandliabilities.loansreviewed = 0
            this.props.changeReviewedLoans(0)
        }

        if ((this.props.who === com.borrower && this.props.application.assetsandliabilities.loans === null)) {
            this.load()
        } else {
            this.state.loadingcr = false
        }
        props.updateApplicationAttribute({ target: { value: "0" } }, "loanbalance")

        if (null != this.props.application.assetsandliabilities.loans) {

            this.state.stepsandsubsteps = this.recalcStepper()
        }
    }
    recalcStepper = () => {
        let loans = this.getFilteredLoans()


        let steps = -1
        let substeps = 0
        let dupresolved = null
        let stepsandsubsteps = []
        for (let i = 0; i < loans.length; i++) {
            let remapped = loans[i]
            let st
            if (remapped.correction === "duplicate") {
                substeps += 1
                st = { steps, substeps, dupresolved }
            } else {
                steps += 1
                substeps = 0
                st = { steps, substeps, dupresolved }
            }
            stepsandsubsteps.push(st)
        }
        return stepsandsubsteps
    }
    reconcileSSN = (js, borrowers) => {
        let dashize = ssn => {
            return ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, 9)
        }
        // for (let i = 0; i < borrowers.length; i++) {
        //     let outperson = js.personswithresidencies[i].person
        //     let inperson = borrowers[i].person
        //     let inssn = inperson.socialsecurity.replace(/-/g, '')

        //     let outssn = dashize(outperson.socialsecurity)

        //     if (outperson.socialsecurity !== inssn) {

        //         if (window.confirm("Attention!\nCredit report returned a different SSN for " + outperson.firstname + " " + outperson.lastname + ": " + outssn +
        //             "\nYou entered: " + borrowers[i].person.socialsecurity + "\n\nWould you like to use the value from credit report (recommended)? ")) {

        //             this.props.changeBorrowerInfo(outssn, (i === 0) ? com.borrower : com.coborrower, "socialsecurity")
        //         }
        //     }
        // }
    }
    // reconcileDOBs = (js, borrowers) => {

    //     for (let i = 0; i < borrowers.length; i++) {
    //         let outperson = js.personswithresidencies[i].person
    //         let inperson = borrowers[i].person
    //         let indateofbirth = inperson.dateofbirth.replace(/-/g, '')

    //         let outdateofbirth = outperson.dateofbirth

    //         if (outperson.dateofbirth !== indateofbirth && outdateofbirth !== "") {

    //             if (window.confirm("Attention!\nCredit report returned a different date of birth for " + outperson.firstname + " " + outperson.lastname + ": " + outdateofbirth +
    //                 "\nYou entered: " + borrowers[i].person.dateofbirth + "\n\nWould you like to use the value from credit report (recommended)? ")) {

    //                 this.props.changeBorrowerInfo(outdateofbirth, (i === 0) ? com.borrower : com.coborrower, "dateofbirth")
    //             }
    //         }
    //     }

    // }
    reconcileDOBs = (js, borrowers) => {

        for (let i = 0; i < borrowers.length; i++) {
            let outperson = js.personswithresidencies[i].person
            let inperson = borrowers[i].person
            let indateofbirth = inperson.dateofbirth.replace(/-/g, '')

            let outdateofbirth = outperson.dateofbirth

            if (outdateofbirth !== "") {
                this.props.changeBorrowerInfo(outdateofbirth, (i === 0) ? com.borrower : com.coborrower, "dateofbirth")
            }
        }

    }
    fillPerson = who => {
        let a = this.props.application[who]
        return {
            firstname: a.firstname, middlename: a.middlename, lastname: a.lastname,
            suffix: a.suffix, email: a.email,
            socialsecurity: a.socialsecurity,
            dateofbirth: a.dateofbirth
        }
    }
    fillAddress = who => {
        let a = this.props.application[who]

        return {
            street: a.presentaddressstreet, city: a.presentaddresscity, state: a.presentaddressstate,
            zip: a.presentaddresszip, years: a.presentaddressyears,
            months: a.presentaddressmonths, ownership: a.presentaddressownership
        }
    }
    fillPreviouswAddress = (index, who) => {
        let a = this.props.application[who].previousaddresses[index]

        return {
            street: a.street, city: a.city, state: a.state,
            zip: a.zip, years: a.years,
            months: a.months, ownership: a.ownership
        }
    }
    fillApplicant = who => {
        let addresses = [this.fillAddress(who)]
        let a = this.props.application[who].previousaddresses

        for (let i = 0; i < a.length; i++) {
            addresses.push(this.fillPreviouswAddress(i, who))
        }

        if (addresses.length>0 && addresses[0].street==="") {
            addresses = [this.fillAddress("borrower")]
        }
        console.log(addresses)
        return { person: this.fillPerson(who), addresses: addresses }
    }
    fillRequestBody = () => {
        let borrowers = [this.fillApplicant("borrower")]
        if ((this.props.application.hascoborrower === "withcoborrower" ||
            this.props.application.hascoborrower === "multiple"
            || (this.props.application.hascoborrower === "alone" && this.props.application.aloneMarriedWantsFHA)
        ) && this.props.application.married
        ) {
            borrowers.push(this.fillApplicant("coborrower"))
        }
        return {
            // true for LO, false for borrower
            force_ucs: false,
            debug: "just_request",
            app_data: borrowers,
            request: "credit_report"
        }
    }
    setCRError = () => {
        this.setState({ message: <div className="text-center my-3" style={{ color: "#993333", fontStyle: 'bold', fontSize: '1.3em' }}>We were unable to pull your credit report. <br /><Button href="/app/contact" variant="link">Please contact your loan officer for assistance.</Button> </div> })

    }
    load = () => {
        this.props.changeReviewedLoans(0)
        if (false && "undefined" === typeof this.props.borrowerid && null !== this.props.application.assetsandliabilities.loans) {
            return
        }
        this["state"].loadingcr = true
        this.setState({ loadingcr: true })
        let token = window.sessionStorage.getItem("ZeitroA");
        let id = com.getUserId()

        if ("undefined" === typeof id) {
            id = this.props.borrowerid
        }
        let body = this.fillRequestBody()
        let borrowers = body.app_data

        let processData = (js) => {
            this.props.clearBorrowerLoans(null, this.props.who)
            this.props.updateFico(js.creditscore)
            if (js.creditscore=="0") {
                this.props.updateFico("750")
            }
            if (js.creditscore === "")
                this.setCRError()

            // this.reconcileSSN(js, borrowers)
            // this.reconcileDOBs(js, borrowers)
            let remap = o => {
                let l = { ...o }

                //l.correction = "none"
                //l.attribution = attr

                // let isstudent = liability.creditliabilityaccounttype === "Educational"
                let islease = l.creditliabilityaccounttype === "AutoLease" || l.creditliabilityaccounttype === "Lease"
                let isinstallment = l.creditliabilityaccounttype === "Installment"

                if (l.creditloantype.toLowerCase().indexOf("education") >= 0) {
                    if (l.monthlypayment === "")
                        l.monthlypayment = 0
                } else {
                    if ((islease || isinstallment) && (l.monthlypayment === 0 || l.monthlypayment === "")) {
                        l.monthlydefinedbycustomer = true
                    }
                }
                return l
            }
            let opened = js.liabilities
            let dupresolved = null
            let expand = []
            let steps = -1
            let substeps = 0
            let stepsandsubsteps = []
            for (let i = 0; i < opened.length; i++) {
                let remapped = remap(opened[i])
                if (opened[i].remainingbalance === "" || parseInt(opened[i].remainingbalance) === 0)
                   continue
                this.props.copyBorrowerLoan(remapped, this.props.who)

                expand.push(false)
                let st
                if (remapped.correction === "duplicate") {
                    substeps += 1
                    st = { steps, substeps, dupresolved }
                } else {
                    steps += 1
                    substeps = 0
                    st = { steps, substeps, dupresolved }
                }
                stepsandsubsteps.push(st)
            }

            this.props.addBorrowerAdditionalPropertiesFromCredit(null)
            getCurrentState()

            this.setState({ loadingcr: false, expand, stepsandsubsteps })
            if (typeof js.errorMessages !== "undefined") {
                this.props.updateCRErrors(js.errorMessages)
            }
        }

        // if (window.location.hostname === "localhost") {
        //     processData(mockUCS)
        //     return
        // }

        fetch(window.location.origin + "/borrower/creditreport/" + id, {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(response => {

            response.text().then(text => {
                try {

                    let js = JSON.parse(text)
                    processData(js)
                } catch (xx) {
                    console.log(text)
                    console.log(xx)
                    this.setState({ loadingcr: false })
                }
            })

        }).catch(error => {

            this.setState({ loadingcr: false })

        })

    }
    componentDidMount() { }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    updateJointly = (e) => {
        com.touch()
        let f = e.target.value === "true"
        this.props.updateJointly({ target: { checked: f } })
    }

    processOtherCredits = (val, index) => {
        if (null === val)
            return ""
        let { altname, creditor, account, key } = val

        return this.renderRow(index, altname, creditor, account, key)
    }

    addOtherCredits = (e) => {
        com.touch()
        let val = e.target.value === "true"
        if (!val) {
            this.props.clearBorrowerAdditionalCredits(0, this.props.who)
        } else {
            this.props.addBorrowerAdditionaCredits(0, this.props.who)
        }
    }

    updateFinancesCheck = (verb) => {
        return e => {
            let val = e.target.value === "true"
            this.props.updateFinances(val, this.props.who, verb)
        }
    }
    updateFinances = (verb) => {
        return e => this.props.updateFinances(e.target.value, this.props.who, verb)
    }
    freeAndClear = (e) => {
        com.touch()
        let t = e.target.value === "true"
        this.props.changeBorrowerFreeAndClear(t)
    }
    filterMortgages = (loan) => {
        return loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
            loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
            loan.creditloantype.toLowerCase().indexOf("realestate") >= 0
    }
    filterNonDuplicates = (loan) => {
        return loan.correction !== "duplicate"
    }
    getFilteredLoans = () => {
        return this.props.application.assetsandliabilities.loans == null ? null :
            this.props.application.assetsandliabilities.loans.filter(this.filterNonDuplicates).filter(this.filterMortgages)
    }
    getUnfilteredIndex = (index) => {
        let loans = this.props.application.assetsandliabilities.loans

        let acc = 0
        for (let i = 0; i < loans.length; i++) {
            let loan = loans[i]
            if (this.filterMortgages(loan) && this.filterNonDuplicates(loan)) {
                if (acc === index)
                    return i
                acc += 1
            }
        }
        alert("should not be here")
        return -1
    }

    processSteps = (val, index) => {
        if (null === val)
            return ""
        let name = val.name;
        let address = val.address;
        let accountnumber = val.accountnumber;
        let monthlypayment = val.monthlypayment;
        let remainingbalance = val.remainingbalance;
        let satisfiedupon = val.satisfiedupon;
        let monthslefttopay = val.monthslefttopay;
        let key = val.key

        return this.renderLoanStep(index, name, address, accountnumber,
            monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key)
    }

    process = (val, index) => {
        if (null === val)
            return ""
        let name = val.name;
        let address = val.address;
        let accountnumber = val.accountnumber;
        let monthlypayment = val.monthlypayment;
        let remainingbalance = val.remainingbalance;
        let satisfiedupon = val.satisfiedupon;
        let monthslefttopay = val.monthslefttopay;
        let key = val.key

        return this.renderLoan(index, name, address, accountnumber,
            monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, true)
    }
    updateApplicationAttribute = (value) => {
        this.props.updateApplicationAttribute({ target: { value } }, "loanbalance")
    }

    getBalancePayment = calculateBalancesAndPayments.bind(this)


    presentFreeAndClear = () => {
        let loans = this.getFilteredLoans()

        if (loans == null) return ""

        if (this.props.application.assetsandliabilities.loansreviewed < loans.length)
            return ""

        if (this.props.application.property.purpose === st.POL_Purchase
            && this.props.application[this.props.who].presentaddressownership !== st.O_Own) {
            return ""
        }
        let iwe = (this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
            `we` :
            `I`

        //let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()

        let hasMortgages = false
        let hassubject = false
        let hasprimary = false
        //let hasprimary = false
        if (null !== loans) {
            let len = loans.length
            for (let index = 0; index < len; index++) {
                if (loans[index].creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
                    loans[index].creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                    loans[index].creditloantype.toLowerCase().indexOf("realestate") >= 0
                ) {
                    hasMortgages = true
                    if (loans[index].forsubjectproperty) {
                        if ("" !== loans[index].remainingbalance)
                            hassubject = true
                    }
                    if (loans[index].forprimaryresidence) {
                        if ("" !== loans[index].remainingbalance)
                            hasprimary = true
                    }
                }
            }
        }
        let needsToAsk = (this.props.application.property.purpose === st.POL_Purchase && !hasprimary) ||
            (this.props.application.property.purpose !== st.POL_Purchase && !hassubject)
        if (needsToAsk) {
            return (
                <div className="viewport mt-3" >
                    <div className="mx-3  text-wrap">
                        <div className="sectionquestion">{
                            (this.props.application.property.purpose === st.POL_Purchase) ? "Do you own your primary residence free and clear?" :
                                "Do you own the property being refinanced free and clear?"}</div>
                        <Form.Group>
                            <fieldset>
                                <div className="d-flex  mt-2">
                                    <div className="mr-3">
                                        <input required onChange={this.freeAndClear} value={true} checked={true === this.props.application.assetsandliabilities.propertyownedfreeandclear} type="radio" id="freeandclear" name="freeandclear"></input>
                                        <label className="zeitro-radio divlink" htmlFor="freeandclear">Yes, {iwe} do</label></div>
                                    <div><input required onChange={this.freeAndClear} value={false} checked={false === this.props.application.assetsandliabilities.propertyownedfreeandclear} type="radio" id="freeandclearnot" name="freeandclear"></input>
                                        <label className="zeitro-radio divlink" htmlFor="freeandclearnot">No, {iwe} don't</label></div>
                                </div>
                            </fieldset>
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please specify if you own the property free and clear.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {
                            this.props.application.property.purpose === st.POL_Purchase ?
                                (
                                    hasMortgages && !hasprimary && !this.props.application.assetsandliabilities.propertyownedfreeandclear ?
                                        <div style={{ color: '#600' }} >Important! Your credit report contains home loans, but none of them are checked to be for your current residence.
                                            Double check the loans above. If your mortgages for current residence are missing in the credit report, specify them on the next page.
                                        </div>

                                        : ""
                                ) : (
                                    hasMortgages && !hassubject && !this.props.application.assetsandliabilities.propertyownedfreeandclear ?
                                        <div style={{ color: '#600' }} >Important! Your credit report contains home loans, but none of them are checked to be for the property being refinanced.
                                            Double check the loans above. If your mortgages for subject property are missing in the credit report, specify them on the next page.
                                        </div>

                                        : <div style={{ color: '#600' }} >Important! You can't refinance a property that is owned free and clear.    </div>
                                )
                        }

                        {this.props.application.property.purpose === st.POL_Refinance &&

                            true === this.props.application.assetsandliabilities.propertyownedfreeandclear
                            ? <div>
                                <div style={{ fontSize: '1.4em', color: '#600' }} >You can't do a rate/term refinance of the property that you own free and clear.
                                    <div className="mt-2">However, you may qualify to a cash out refinance.
                                        <br /><br /><Link to="/app/interview/main/1">Click here to change your choice of loan type</Link>
                                    </div>
                                </div>
                            </div>
                            : ""
                        }
                    </div>
                </div>
            )
        }

    }

    handleSubmit = (event) => {
        if (this.props.application.scre === 0) {
            this.setCRError()
            return false
        }
        /* eslint-disable-next-line no-unused-vars */
        let [totalmortgage, mortgagebalance, othermortgage, otherbalance, cashout, lanmount] = this.getBalancePayment()
        if (this.props.application.property.purpose === st.POL_Cashoutrefinance) {
            this.props.updateSelectField(cashout, "mincashout")
        }
        if (this.props.application.property.purpose === st.POL_Refinance &&
            0 === totalmortgage && true === this.props.application.assetsandliabilities.propertyownedfreeandclear) {
            alert("You can't refinance the property that you own free and clear!")
            return false
        }
        let loans = this.getFilteredLoans()
        if (this.props.application.assetsandliabilities.loansreviewed == null ||
            this.props.application.assetsandliabilities.loansreviewed < loans.length) {
            if (this.props.application.assetsandliabilities.loansreviewed != null) {
                this.props.changeReviewedLoans(this.props.application.assetsandliabilities.loansreviewed + 1)
            }
            return false
        }
        this.props.updateApplicationAttribute({ target: { value: mortgagebalance.toString() } }, "loanbalance")
        return true
    }
    getIcon = (index) => {
        let loans = this.getFilteredLoans()


        let a = loans[index]
        let mapit = a => (a.creditloantype.toLowerCase().indexOf("mortgage") >= 0) ? "1" :
            ((a.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                a.creditloantype.toLowerCase().indexOf("realestate") >= 0
            ) ? "2" :
                ((a.creditloantype.toLowerCase().indexOf("education") >= 0 ? "3" :
                    (a.creditloantype.toLowerCase().indexOf("auto") >= 0 ? "4" :

                        (a.creditloantype.toLowerCase().indexOf("card") >= 0 || a.creditloantype.toLowerCase().indexOf("charge") >= 0 ? "5" :
                            (a.creditloantype.toLowerCase().indexOf("medical") >= 0 ? "6" :

                                (a.creditloantype.toLowerCase().indexOf("collection") >= 0 ? "7" : "8")
                            ))))))
        switch (mapit(a)) {
            case "1":
                return <i className="fas fa-home mr-1 blue"></i>
            case "2":
                return <i className="fas fa-house-damage mr-1 blue"></i>
            case "3":
                return <i className="fas fa-graduation-cap mr-1 blue"></i>
            case "4":
                return <i className="fas fa-car-alt mr-1 blue"></i>
            case "5":
                return <i className="fas fa-credit-card mr-1 blue"></i>
            case "6":
                return <i className="fas fa-first-aid mr-1 blue"></i>
            case "7":
                return <i className="fas fa-hand-holding-usd mr-1 blue"></i>
            default:
                return ""
        }

    }
    getSubjAddress = () => {
        if (this.props.application.property.purpose !== st.POL_Purchase)
            return this.props.application.property.address
        let addr = com.formatAddress(this.props.application.borrower)

        return addr
    }
    getFlag = (correction) => {
        if (correction === "paidbybusiness")
            return <i className="fas fa-user-tie ml-2 darkgreen"></i>

        if (correction === "alreadyclosed")
            return <i className="fa fa-times ml-2 darkgreen"></i>
        if (correction === "wrongperson")
            return <i className="far fa-hand-paper ml-2 darkred"></i>
        if (correction === "other")
            return <i className="far fa-flag ml-2 darkred"></i>
        if (correction === "duplicate")
            return <i className="far fa-clone ml-2 darkred"></i>
        return ""
    }
    renderLoan = (index, name, address, accountnumber, monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, margin) => {
        index = parseInt(index)
        let loans = this.getFilteredLoans()

        let isHELOC = () => {

            let loan = loans[index]
            return com.isHeloc(loan)
        }
        let handleClose = e => {
            let newshow = { ...this.state.show }
            newshow[index] = false
            this.setState({ show: newshow })
        }
        let showCorrect = e => {
            let newshow = { ...this.state.show }
            newshow[index] = true
            this.setState({ show: newshow })
        }
        let isPrimary = (trr) => {

            if (this.props.application.property.purpose !== st.POL_Purchase &&
                (loans[index].forsubjectproperty === true ||
                    (trr && loans[index].forsubjectproperty == null)
                )
            ) {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < index; i++) {
                    let loan = loans[i]

                    if (loan.forsubjectproperty &&
                        ((loan.satisfiedupon === "withproceeds") || (trr && loan.satisfiedupon == null))) {
                        alreadyRefinanced = true
                        break
                    }
                }
                if (alreadyRefinanced) {
                    return false
                }
                return true
            }
            return false
        }
        let changeBorrowerLoanSatisfiedUpon = (event) => {
            com.touch()

            if (isMortgage() && this.props.application.property.purpose === st.POL_Refinance && event.target.value === "withproceeds") {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < index; i++) {
                    let loan = loans[i]
                    if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                        alreadyRefinanced = true
                        break
                    }
                }
                if (alreadyRefinanced) {
                    this.setState({ observedIndex: index, showWarning: true })
                    return
                }
            }
            this.props.changeBorrowerLoanSatisfiedUpon(event, this.getUnfilteredIndex(index), this.props.who)
        }
        let changeExplanation = e => {
            com.touch()
            this.props.changeBorrowerLoanExplanation(e, this.getUnfilteredIndex(index), this.props.who)
        }
        let correctionChecked = e => {
            com.touch()
            this.props.changeBorrowerLoanCorrection(e, this.getUnfilteredIndex(index), this.props.who)
        }
        let changeBorrowerLoanForSubject = e => {
            com.touch()
            let val = e.target.value === "true"

            this.props.changeBorrowerLoanForSubject(val, this.getUnfilteredIndex(index), this.props.who)
            if (isPrimary(val)) {
                this.props.changeBorrowerLoanSatisfiedUpon({ target: { value: "withproceeds" } }, this.getUnfilteredIndex(index), this.props.who)
            }
            this.getBalancePayment()
        }
        let changeBorrowerLoanForPrimary = e => {
            com.touch()
            let val = e.target.value === "true"

            this.props.changeBorrowerLoan(val, this.getUnfilteredIndex(index), "forprimaryresidence")

        }
        let displayOwnership = () => {

            switch (loans[index].attribution) {
                case 1:
                    return `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`
                case 2:
                    return `${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}`
                case 3:
                    return "Joint"
                default:
                    return "Unknown " + loans[index].attribution
            }
        }
        let onMonthlyChange = (e) => {
            com.touch()
            this.props.changeBorrowerLoanMonthly(e, this.getUnfilteredIndex(index), this.props.who)
        }
        let displayMonthly = () => {
            let loans = this.getFilteredLoans()

            let liability = loans[index]
            let userinput = liability.monthlydefinedbycustomer

            if (userinput) {
                return (
                    <Form.Group className="text-left pr-3">
                        <NumericalInput
                            isValid={false}
                            size="sm"
                            id={"loan" + index}
                            required
                            type="number"
                            name={"loan" + index}
                            defaultValue={liability.monthlypayment}
                            onChange={onMonthlyChange}

                            min={1}
                        />
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Enter the monthly payment
                        </Form.Control.Feedback>
                    </Form.Group>
                )
            }
            return "$" + com.commaize(loans[index].monthlypayment)
        }
        let askHowToPay = () => {


            if (parseInt(loans[index].remainingbalance) > 0) {
                if (loans[index].name === "AMEX") {
                    if (loans[index].satisfiedupon) {

                        changeBorrowerLoanSatisfiedUpon({ target: { value: 'withownfunds' } })
                    }
                    return (
                        <div>
                            <div className="sectionquestion"><i className="fas fa-angle-right"></i>Will you pay the balance before this transaction?
                            </div>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mb-0">

                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={loans[index].satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + index} name={"satisfied" + index}></input>
                                            <label id={"satisfieddownlabel" + index} className="zeitro-radio divlink" htmlFor={"satisfiedown" + index}>Yes, I will</label>
                                        </div>
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={loans[index].satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + index} name={"satisfied" + index}></input>
                                            <label id={"satisfiedlabel" + index} className="zeitro-radio divlink" htmlFor={"satisfied" + index}>No, I won't</label>
                                        </div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please make your choice.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    )
                }
                let canPayoffWithLoanProceeds = () => {
                    let loans = this.getFilteredLoans()

                    if (this.props.application.property.purpose === st.POL_Cashoutrefinance)
                        return true
                    if (this.props.application.property.purpose === st.POL_Purchase)
                        return false

                    if (isHELOC()) {
                        if (this.props.application.property.purpose === st.POL_Refinance && false === loans[index].partoforiginalpurchase)
                            return false
                    }
                    // refinance /cashout left
                    if (loans[index].forsubjectproperty === true)
                        return true
                    if (loans[index].partoforiginalpurchase)
                        return true
                    return false
                }
                let paidOff = () => {

                    return tooltip(<i className="text-nowrap" style={{ fontWeight: 'bold', fontStyle: 'normal' }}><i className="fas fa-angle-right"></i>Will this balance be paid off at the time of (or before) this transaction?</i>,
                        <div>
                            This question should be answered having several things in mind:
                            <ul>
                                <li>
                                    Are you refinancing this loan? Answer "Yes, I'm refinancing this loan"
                                </li>
                                <li>
                                    Are you paying the loan back? For example, you're selling your previous residence. Answer: "Yes, I'm it paying it off myself
                                    ".
                                </li><li>
                                    Closing some credit card balances can also be helpful in lowering your debt payments, and improving your odds of getting qualified for a new loan.
                                </li>
                                <li>
                                    Answer No, if you're keeping the loan as is
                                </li>
                            </ul>
                        </div>, "auto", "", "")
                }
                if (!showBalancePaidOff())
                    return ""
                return (
                    <div>
                        <div className="sectionquestion d-inline-block">{paidOff()}</div>
                        <Form.Group>
                            <fieldset>
                                <div className="d-flex mb-0">
                                    {canPayoffWithLoanProceeds() ?
                                        <div className="ml-2 mr-3 ">
                                            <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withproceeds" checked={loans[index].satisfiedupon === "withproceeds"} type="radio" id={"satisfied" + index} name={"satisfied" + index}></input>
                                            <label id={"satisfiedlabel" + index} className="zeitro-radio divlink" htmlFor={"satisfied" + index}>{isPrimary() ? "Yes, I'm refinancing this loan" : "Yes, I'm paying it off with the loan proceeds"}</label>
                                        </div> : ""
                                    }
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="withownfunds" checked={loans[index].satisfiedupon === "withownfunds"} type="radio" id={"satisfiedown" + index} name={"satisfied" + index}></input>
                                        <label id={"satisfiedownlabel" + index} className="zeitro-radio zeitro-radio divlink" htmlFor={"satisfiedown" + index}>Yes, I'm paying it off myself</label>
                                    </div>
                                    <div>
                                        <input required onChange={changeBorrowerLoanSatisfiedUpon} value="not" checked={loans[index].satisfiedupon === "not"} type="radio" id={"not" + index} name={"satisfied" + index}></input>
                                        <label id={"notlabel" + index} className="zeitro-radio ivlink" htmlFor={"not" + index}>No, I won't</label>
                                    </div>
                                </div>
                            </fieldset>
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Please make your choice.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                )
            }
            return ""
        }

        let changePropertyAddress = event => {
            com.touch()
            this.props.changeBorrowerLoan(event.target.value, this.getUnfilteredIndex(index), "propertyaddress")
        }


        let isMortgage = () => {

            return loans[index].creditloantype.toLowerCase().indexOf("mortgage") >= 0 ||
                loans[index].creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                loans[index].creditloantype.toLowerCase().indexOf("realestate") >= 0
        }

        let correction = loans[index].correction
        let crossed = ""

        if (["wrongperson", "alreadyclosed", "other", "duplicate"].includes(correction))
            crossed = " crisscrossed"
        if (correction === "paidbybusiness" || correction === "alreadyclosed")
            crossed = " greencrisscrossed"
        let mt5 = " mt-4"
        if (index === 0)
            mt5 = ""
        let onRateChange = (e) => {
            com.touch()
            this.props.changeBorrowerLoanRate(e, this.getUnfilteredIndex(index), this.props.who)
        }

        let changePartOfOriginal = e => {
            let r = e.target.value === "true"
            this.props.changeBorrowerLoan(r, this.getUnfilteredIndex(index), "partoforiginalpurchase")
        }
        let showAddress = () => {

            if (!isMortgage())
                return false

            if (this.props.application.property.purpose !== st.POL_Purchase && false === loans[index].forsubjectproperty && 
                this.props.application.financingsameproperty)
                return true

            if (this.props.application.property.purpose === st.POL_Purchase && false === loans[index].forprimaryresidence)
                return true

            if (this.props.application.property.purpose !== st.POL_Purchase &&  
                false === this.props.application.financingsameproperty && false === loans[index].forprimaryresidence )
                    return true                
            return false
        }

        let showBalancePaidOff = () => {
            if (isMortgage()) {

                if (loans[index].forsubjectproperty == null &&
                    loans[index].forprimaryresidence == null)
                    return false
                if (this.props.application.property.purpose !== st.POL_Purchase && loans[index].forsubjectproperty)
                    return false
                /*
                if(isPrimary()) {  
                    return false
                }
                */
                return true
            }
            return true
        }
        let style = {}
        if (loans[index].forsubjectproperty === true && crossed === "") {
            style = {
                backgroundImage: 'url("/housemoney.png")',
                position: 'relative',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom 0px right 50px',
                backgroundSize: 'auto 100%',

            }

        }
        let theRest = () => {
            let ital = "ml-3 font-italic font-weight-light"
            switch (loans[index].correction) {
                case "duplicate":
                    return <div className={ital}>
                        This entry is a duplicate, and won't be counted against your debt
                    </div>
                case "wrongperson":
                    return <div className={ital}>
                        This entry does not belong to you, and won't be counted against your debt
                    </div>
                case "alreadyclosed":
                    return <div className={ital}>
                        This loan is already closed, and won't be counted against your debt
                    </div>
                case "paidbybusiness":
                    return <div className={ital}>
                        This loan is paid by business, and won't be counted against your debt
                    </div>
                case "other":
                    return <div className={ital}>
                        This loan won't be counted against your debt
                    </div>
                default:
                    break
            }


            if (crossed !== "")
                return ""

            let showQuestionForPrimary = () => {

                if (this.props.application.property.purpose === st.POL_Purchase)
                    return ""
                // only refinance
                if (false !== loans[index].forsubjectproperty)
                    return ""
                if (false !== this.props.application.financingsameproperty)
                    return ""
                return askPrimary()
            }

            let askPrimary = () => {
                return <div>
                    <Row className="">
                        <Col>
                            <div className="sectionquestion"><i className="fas fa-angle-right"></i>Is this a loan for {com.getPrimaryAddress("borrower", this.props.application)}?</div>
                            <div className="d-flex mb-0">

                                <div className="ml-2 mr-3 ">
                                    <input required onChange={changeBorrowerLoanForPrimary} value="true" checked={true === loans[index].forprimaryresidence} type="radio" id={"forprimary" + index} name={"forsforprimaryubject" + index}></input>
                                    <label id={"forprimarylabel" + index} className="zeitro-radio divlink" htmlFor={"forprimary" + index}>Yes, it is</label>
                                </div>
                                <div className="ml-2 mr-3 ">
                                    <input required onChange={changeBorrowerLoanForPrimary} value="false" checked={false === loans[index].forprimaryresidence} type="radio" id={"forprimarynot" + index} name={"forsforprimaryubject" + index}></input>
                                    <label id={"forprimarynotlabel" + index} className="zeitro-radio divlink" htmlFor={"forprimarynot" + index}>No, it isn't</label>
                                </div>

                            </div>

                        </Col>
                    </Row>

                </div>
            }
            
            return (
                <div>
                    {(this.props.application.property.purpose !== st.POL_Purchase && isMortgage())
                        ?
                        <Row className="">
                            <Col>
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Is this a loan for {this.getSubjAddress()}?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanForSubject} value="true" checked={true === loans[index].forsubjectproperty} type="radio" id={"forsubject" + index} name={"forsubject" + index}></input>
                                        <label id={"forsubjectlabel" + index} className="zeitro-radio divlink" htmlFor={"forsubject" + index}>Yes, it is</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changeBorrowerLoanForSubject} value="false" checked={false === loans[index].forsubjectproperty} type="radio" id={"forsubjectnot" + index} name={"forsubject" + index}></input>
                                        <label id={"forsubjectnotlabel" + index} className="zeitro-radio divlink" htmlFor={"forsubjectnot" + index}>No, it isn't</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        : ""}
                    {showQuestionForPrimary()
                    }

                    {loans[index].forsubjectproperty === true && this.props.application.property.purpose !== st.POL_Purchase &&
                        !isHELOC() && isPrimary()
                        ?

                        <Form.Group controlId={"liability" + index} className="text-left d-flex text-nowrap  m-0  p-0 pb-2 " >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '1.1em' }} className="text-left pt-1 pl-3" > &gt;Your current rate? </Form.Label>
                            <fieldset>
                                <InputGroup style={{ width: '8em' }} >
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        className="ml-1"
                                        required
                                        name={"liability" + index}
                                        type="text"
                                        defaultValue={loans[index].rate}
                                        onChange={onRateChange}
                                        style={{ width: '4em' }}
                                        pattern="^[0-9]+\.[0-9]+$"
                                        placeholder="XX.XX"
                                    />
                                    <InputGroup.Append  >
                                        <InputGroup.Text style={{ fontSize: '1.1em' }} >%
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <div>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide description.
                                    </Form.Control.Feedback>
                                </div>
                            </fieldset>
                        </Form.Group>

                        : ""

                    }

                    <div className="mx-3">{showAddress() ?

                        <AddressAutocomplete isValid={false} size="sm"
                            required
                            label="Provide Property Address:"
                            id={"propertyaddress" + index}
                            type="text"
                            defaultValue={loans[index].propertyaddress}
                            onChange={changePropertyAddress}
                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                            goodfeedback="Looks good!"
                            badfeedback="Please provide property raddress."
                        />

                        : " "}</div>


                    {loans[index].forsubjectproperty === true && isHELOC() &&
                        this.props.application.property.purpose === st.POL_Refinance ?
                        <Row className="">
                            <Col >
                                <div className="sectionquestion"><i className="fas fa-angle-right"></i>Was this HELOC a part of the original purchase?</div>
                                <div className="d-flex mb-0">

                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="true" checked={true === loans[index].partoforiginalpurchase} type="radio" id={"partoforiginalpurchase" + index} name={"partoforiginalpurchase" + index}></input>
                                        <label id={"partoforiginalpurchaselabel" + index} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchase" + index}>Yes, it was</label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input required onChange={changePartOfOriginal} value="false" checked={false === loans[index].partoforiginalpurchase} type="radio" id={"partoforiginalpurchasenot" + index} name={"partoforiginalpurchase" + index}></input>
                                        <label id={"partoforiginalpurchasenotlabel" + index} className="zeitro-radio divlink" htmlFor={"partoforiginalpurchasenot" + index}>No, it wasn't</label>
                                    </div>

                                </div>

                            </Col>

                        </Row>
                        : ""}


                    <Row className="">
                        <Col >

                            {askHowToPay()}
                        </Col>

                    </Row>
                </div>
            )
        }

        let m3 = "m-3 "
        if (!margin) {
            mt5 = ""
            m3 = "mb-3 "
        }
        let toggleShow = () => {
            let expand = this.state.expand
            expand[index] = !expand[index]
            this.setState({ expand })

            var content = document.getElementById("card" + index);
            if (!expand[index]) {
                content.style.maxHeight = "0px";
            } else {
                content.style.maxHeight = 2 * content.scrollHeight + "px";
            }
        }
        let askAboutDuplicate = !margin &&
            loans[index].correction === "duplicate" &&
            this.state.stepsandsubsteps[index].dupresolved == null

        let askDupe = () => {
            if (index === 0)
                return "should not see this"
            let onDupe = e => {
                let val = e.target.value === "true"
                let stepsandsubsteps = this.state.stepsandsubsteps
                stepsandsubsteps[index].dupresolved = val
                this.setState({ stepsandsubsteps })

                if (!val) {
                    com.touch()
                    this.props.changeBorrowerLoanCorrection({ target: { value: "none" } }, this.getUnfilteredIndex(index), this.props.who)
                    setTimeout(() => {
                        let stepsandsubsteps = this.recalcStepper();
                        this.setState({ stepsandsubsteps })
                    }, 1000)
                }
            }
            return <div className="ml-2">
                <div className="_sectionquestion">This entry looks like a duplicate of the previous one: {loans[index - 1].name}, acc. {loans[index - 1].accountnumber},
                    balance: ${com.commaizeFloat(loans[index - 1].remainingbalance)}
                    <br />Could you please confirm?</div>
                <Form.Group>
                    <fieldset>
                        <div className="d-flex  mt-2">
                            <div className="mr-3">
                                <input required onChange={onDupe} value={true} checked={true === this.state.stepsandsubsteps[index].dupresolved} type="radio" id="dupe" name="dupe"></input>
                                <label className="zeitro-radio divlink" htmlFor="dupe">Yes, this is a duplicate</label></div>
                            <div><input required onChange={onDupe} value={false} checked={false === this.state.stepsandsubsteps[index].dupresolved} type="radio" id="dupenot" name="dupe"></input>
                                <label className="zeitro-radio divlink" htmlFor="dupenot">No, it is not</label></div>
                        </div>
                    </fieldset>
                </Form.Group>
            </div>
        }
        let switchCashout = () => {
            this.setState({ showWarning: false })
            this.props.changeBorrowerLoanSatisfiedUpon({ target: { value: "withproceeds" } }, this.state.observedIndex, this.props.who)
            this.props.changeMainPropertyPurpose({ target: { value: st.POL_Cashoutrefinance } })
        }
        let originalPurchase = () => {
            this.setState({ showWarning: false })

            this.props.changeBorrowerLoanSatisfiedUpon({ target: { value: "withproceeds" } }, this.state.observedIndex, this.props.who)
            this.props.changeBorrowerLoan(true, this.getUnfilteredIndex(index), "partoforiginalpurchase")
        }
        return (
            <div key={key} className={loans[index].mannerofpaymentcode !== "C" ?
                m3 + " red-shadow  text-wrap" + crossed + mt5
                :
                m3 + "  drop-shadow  text-wrap" + crossed + mt5
            } style={style}>
                <Modal show={this.state.showWarning} onHide={() => { this.setState({ showWarning: false }) }}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4>Tell us more about these loans</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-3">
                        <div style={{ minWidth: '400px' }}>
                            You indicated that you'd like to refinance more than one loan.
                        </div>
                        <div className="mt-4">
                            This is possible within the limits of rate/term refinance only if these loans were obtained together as a part of a purchase transaction.

                        </div>
                        <div className="mt-4">
                            Otherwise, we can switch to a cash out refinance. Your rates might be higher. Note, that you can always go back and change your choice.
                        </div>
                        <form name={"doo" + index}>
                        </form>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <Button onClick={switchCashout}>Switch to cash out refinance</Button>
                            </Col>
                            <Col className="text-center">
                                <Button onClick={originalPurchase}>The loans were part of original purchase</Button>
                            </Col>
                        </Row>

                    </Modal.Body>

                </Modal>

                <Modal show={this.state.show[index]} onHide={handleClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4>Does this liability need a correction?</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-3">
                        <form name={"corr" + index}>
                            <div className="mt-2 ">
                                <Form.Control name="purpose" as="select"
                                    value={correction}
                                    onChange={correctionChecked} >

                                    <option value="none">No correction needed</option>
                                    <option value="wrongperson">This is not my account!</option>
                                    <option value="alreadyclosed">This account has been paid in full and closed</option>
                                    <option value="paidbybusiness">Debt paid by business</option>
                                    <option value="duplicate">Duplicate entry</option>

                                    <option value="other">Other</option>
                                </Form.Control>

                            </div>
                            {correction !== "none" && correction !== "duplicate" ?
                                <div className="mt-3">
                                    <div>Please enter an explanation:</div>
                                    <textarea rows="5" onChange={changeExplanation} className="w-100" value={loans[index].explanation}>
                                    </textarea>
                                    <div style={{ fontStyle: 'italic', fontSize: '0.8em' }}>Documentation is required to support this explanation</div>

                                </div>
                                : ""
                            }
                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Button variant="success" onClick={handleClose}>Close</Button>
                                </Col>
                            </Row>
                        </form>

                    </Modal.Body>
                </Modal>
                <Row className="mt-0 pt-2 bluishbackground">
                    <Col xs>

                        <div style={{ fontSize: "1.2em" }} className="sectionquestion">
                            {this.getIcon(index)}

                            {com.unCamelCase(loans[index].creditloantype)}{this.getFlag(correction)}</div>
                    </Col>
                    {margin ?
                        <Col xs="auto">
                            <i className={this.state.expand[index] ? "fa fa-arrow-circle-up downarrow" : "fa fa-arrow-circle-down downarrow"} onClick={toggleShow} aria-hidden="true" ></i>
                        </Col> : ""}
                </Row>
                <div className="">
                    <Row className="bluishbackground" >
                        <Col xs="auto">
                            <div>Lender:</div>
                            <div >{loans[index].name}</div>
                        </Col>
                        <Col xs="auto">
                            <div>Account number:</div>
                            <div>{loans[index].accountnumber}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Balance:</div>
                            <div>${com.commaize(loans[index].remainingbalance)}</div>
                        </Col>
                        <Col xs="auto" >
                            <div>Mo. Payment:</div>
                            <div>{displayMonthly()}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Mo. left:</div>
                            <div>{
                                Number.isInteger(parseInt(loans[index].monthslefttopay)) ?
                                    loans[index].monthslefttopay : "N/A"
                            }</div>
                        </Col>

                        <Col xs="auto" className="">
                            Ownership<br />
                            {displayOwnership()}
                        </Col>

                        {loans[index].currentratingtype !== "AsAgreed" ?
                            <Col xs="auto" className="text-wrap">
                                <div style={{ fontWeight: '700', color: '#660000' }} className="text-wrap">{com.unCamelCase(loans[index].currentratingtype)}</div>
                            </Col>
                            : ""}

                    </Row>
                </div>
                <div className={!margin ? "" : "cardbody"} id={"card" + index}>
                    {askAboutDuplicate ? askDupe() :
                        <div className="">
                            <Row className="">
                                <Col sm="auto" className="d-flex pl-2 py-0 text-nowrap" >
                                    <Button onClick={showCorrect} style={{ color: '#600' }} className="text-wrap mt-0 p-1" variant="link" ><i className="fas fa-angle-right"></i>Click if this entry needs correction</Button><span className="mt-1">{tooltip("",
                                        <div>Click on this link if
                                            <ul>
                                                <li>
                                                    This loan does not belong to you
                                                </li>
                                                <li>
                                                    It has been paid in full
                                                </li>
                                                <li>
                                                    The debt has been paid by business
                                                </li>
                                                <li>
                                                    This is a duplicate entry (happens quite often!)
                                                </li>                                </ul>
                                            In this case the liability won't be counted, but you will have to supply supporting documents.

                                        </div>
                                        , "auto", "", true)}</span>
                                </Col>

                            </Row>
                            {askAboutDuplicate ? askDupe() : theRest()}
                        </div>}
                </div>

            </div>
        )
    }
    checkForPrimary = (index) => {
        if (this.props.application.property.purpose !== st.POL_Purchase)
            return true
        let loans = this.getFilteredLoans()

        if (loans[index].forprimaryresidence === true)
            return true

        let adr = loans[index].propertyaddress

        let parsed = parser.parseLocation(adr)
        let subj = parser.parseLocation(this.getSubjAddress())
        if (adr === "")
            return true

        let ret = parsed.city.toLowerCase() === subj.city.toLowerCase() &&
            parsed.number.toLowerCase() === subj.number.toLowerCase() &&
            parsed.state.toLowerCase() === subj.state.toLowerCase() &&
            parsed.street.toLowerCase() === subj.street.toLowerCase()

        return !ret
    }

    checkForSubject = (index) => {
        if (this.props.application.property.purpose === st.POL_Purchase)
            return true
        let loans = this.getFilteredLoans()

        if (loans[index].forsubjectproperty === true)
            return true

        let adr = loans[index].propertyaddress

        let parsed = parser.parseLocation(adr)
        let subj = parser.parseLocation(this.getSubjAddress())
        if (adr === "")
            return true

        let ret = parsed.city.toLowerCase() === subj.city.toLowerCase() &&
            parsed.number.toLowerCase() === subj.number.toLowerCase() &&
            parsed.state.toLowerCase() === subj.state.toLowerCase() &&
            parsed.street.toLowerCase() === subj.street.toLowerCase()

        return !ret
    }
    renderLoanStep = (index, name, address, accountnumber,
        monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key) => {
        index = parseInt(index)
        let loans = this.getFilteredLoans()

        let click = () => {
            if (index >= this.props.application.assetsandliabilities.loansreviewed)
                return
            this.props.changeReviewedLoans(index)
        }
        let onBack = e => {
            this.props.changeReviewedLoans(this.props.application.assetsandliabilities.loansreviewed - 1)
            this.setState({ status: "process" })
            this.props.clearValidate()
            document.getElementById("card" + (index - 1)).scrollIntoView({ behavior: "smooth" });
        }
        let onDone = e => {

            if (this.props.checkValidate(e)) {
                if (!this.checkForSubject(index)) {
                    alert("The address provided is for the property that you're refinancing!")
                    this.setState({ status: "error" })
                    return false
                }
                if (!this.checkForPrimary(index)) {
                    alert("The address provided is for the property that you're purchasing!")
                    this.setState({ status: "error" })
                    return false
                }
                this.setState({ status: "process" })
                this.props.changeReviewedLoans(this.props.application.assetsandliabilities.loansreviewed + 1)
                //document.getElementById("card" + (index)).scrollIntoView({ behavior: "smooth" });
            } else {
                this.setState({ status: "error" })
                this.props.clearValidate()
            }
        }

        let grayifneeded = ""
        if (index > this.props.application.assetsandliabilities.loansreviewed)
            grayifneeded = "graytext"
        let correction = loans[index].correction
        let dd = <div className="mt-0  w-100">
            {(index === this.props.application.assetsandliabilities.loansreviewed) ?
                <div id={"card" + index}>

                    <Row >
                        <Col xs>
                            {this.renderLoan(index, name, address, accountnumber,
                                monthlypayment, remainingbalance, monthslefttopay, satisfiedupon, key, false)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">{this.props.application.assetsandliabilities.loansreviewed > 0 ?
                            <Button size="sm" variant="outline-primary" onClick={onBack}>Back</Button> : ""}</Col>
                        <Col xs><Button variant="outline-primary" size="sm" type="button" onClick={onDone} >Click when done with this record</Button></Col>
                    </Row>

                </div>
                :
                <Row id={"card" + index} className={grayifneeded}>
                    <Col xs="auto">

                        <div >
                            {this.getIcon(index)}

                            {com.unCamelCase(loans[index].creditloantype)}{this.getFlag(correction)}
                        </div>

                    </Col>
                    <Col xs>
                        {loans[index].name}
                    </Col>
                </Row>
            }
        </div>


        let tt = ""
        let dup = (loans[index].correction === "duplicate")

        return (
            <Steps.Step sm title={dd} onClick={click} description={tt} subStep={dup} secondaryCurrent={dup} />
        )

    }
    nbsp = () => {
        return (<div className="mt-4">&nbsp;<br />&nbsp;</div>)
    }
    renderStepper = () => {
        if (this.state.stepsandsubsteps.length === 0)
            return ""

        let current = this.props.application.assetsandliabilities.loansreviewed == null ? 0 : this.props.application.assetsandliabilities.loansreviewed

        let inx = this.state.stepsandsubsteps[current].steps
        let ss = this.state.stepsandsubsteps[current].substeps

        return (
            <div id="liabilitystepper">
                <Steps status={this.state.status} direction="vertical" secondaryCurrent={ss} current={inx} className="text-left ml-3">
                    {
                        this.props.application.assetsandliabilities.loans.filter(this.filterNonDuplicates).filter(this.filterMortgages).map(this.processSteps)
                    }

                </Steps>
            </div>
        )
    }
    renderCore = () => {
        let loans = this.getFilteredLoans()

        if (this.props.application.assetsandliabilities.loansreviewed == null || this.props.application.assetsandliabilities.loansreviewed < loans.length) {
            return this.renderStepper()
        } else {
            return (
                <div className="">
                    {this.props.application.assetsandliabilities.loans === null ? "" :
                        this.props.application.assetsandliabilities.loans.filter(this.filterNonDuplicates).filter(this.filterMortgages).map(this.process)}
                </div>
            )
        }
    }
    render = () => {
        let loans = this.getFilteredLoans()
        let showWarnings = x => {
            return <Alert className="mx-3" variant="info"> {x}</Alert>
        }
        return (
            <div
                className=" mb-4 text-left" >
                <h2 className=" heading">

                    {(this.props.application.hascoborrower === "withcoborrower" && this.props.application.married) ?
                        `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname} and 
        ${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}` :
                        `${this.props.application[this.props.who].firstname} ${this.props.application[this.props.who].lastname} `
                    }'s Liabilities From Credit Report
                </h2>

                {this.props.application.assetsandliabilities.warnings != null && this.props.borrowerid ?
                    this.props.application.assetsandliabilities.warnings.map(showWarnings)
                    : ""
                }
                {this.state.loadingcr ?
                    <div className="w-100 mt-4 text-center">
                        <div id="spinner" className="py-5 my-5">

                            <img className="loader" alt="spinner" src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img>
                        </div>
                    </div>
                    :
                    (loans != null && loans.length > 0 ?
                        <div>
                            <div className="mx-3 sectionquestion text-wrap">Please review your existing mortgages, answer all the questions and mark the ones that are already paid off or invalid by clicking on the Needs Correction link</div>

                            {this.renderCore()}

                        </div> :
                        <div>
                            {this.props.application.scre === 0 ? this.props.message :
                                <div className="mx-3 my-3 sectionquestion text-wrap">Looks like your credit report contains no existing mortgages. If you have some unlisted mortgage loans, please indicate them on the next page.</div>
                            }

                        </div>
                    )
                }

                {this.presentFreeAndClear()}
                {true || (this.props.borrowerid && this.props.application.assetsandliabilities.loansreviewed >= loans.length) ?
                    <Button size="sm" className="ml-3 mt-3" onClick={this.load}>Click to Redo Soft Check</Button>
                    : ""}
                {this.state.message}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Liabilities)

