import React, { useState, useEffect, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import { Form, Button, Container } from "react-bootstrap";
import { Col, Row, Tabs, Drawer, QRCode, Skeleton } from 'antd';
import ReProfile, { LoProfile } from "./Profile";
import Properties from "./Properties";
import Resources from "./Resources";
import BasicInfo from "./BasicInfo";
import AboutMe from "./AboutMe";
import CustomerReview from "./CustomerReview"
import Tools, { LandingAffordability, LandingRateQuoter } from "./Tools";
import CallBack from "./CallBack";
import Helmet from 'react-helmet'
import "./NewLandingPage.css";

const backgroundColor = "#F7F8FC";

const LandingPage = (props) => {
  // if (window.location.href.includes("/re/") || props.url.includes("/re/")) {
  //   return <ReLandingPage {...props} />;
  // } else {
  // } 
  return <LoLandingPage {...props} />;
};

const ReLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const refProperties = useRef(null);
  const refRecommendations = useRef(null);
  const refTools = useRef(null);

  const handleClickRefProperties = () => {
    if (refProperties.current)
      refProperties.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickRefRecommendations = () => {
    if (refRecommendations.current)
      refRecommendations.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickRefTools = () => {
    if (refTools.current)
      refTools.current.scrollIntoView({ behavior: "smooth" });
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    console.log(window.location.href);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div>
      <div>
        <div>
          {/* <Navbar expand="lg" className="p-0 m-0  landingNav">
            <Nav className="landingnav">
              <Nav.Item className="landingNavName">{name}</Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link
                  eventKey="affordability"
                  onClick={handleClickRefProperties}
                >
                  Properties
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link
                  eventKey="prequal"
                  onClick={handleClickRefRecommendations}
                >
                  Recommendations
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="landingnavitem">
                <Nav.Link eventKey="resources" onClick={handleClickRefTools}>
                  Mortgage Tools
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar> */}
          <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container>
              <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="#features">Features</Nav.Link>
                  <Nav.Link href="#pricing">Pricing</Nav.Link>
                </Nav>
                <Nav>
                  <Nav.Link href="#deets">More deets</Nav.Link>
                  <Nav.Link eventKey={2} href="#memes">
                    Dank memes
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="landingProfile">
            <ReProfile
              id={props.id}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
            />
          </div>
        </div>
      </div>

      <div ref={refProperties} style={{ backgroundColor: "white" }}>
        <Properties id={props.id} {...props}/>
      </div>
      <div
        ref={refRecommendations}
        style={{ backgroundColor: backgroundColor }}
        className="pb-5"
      >
        <Resources id={props.id} width={width} height={height} />
      </div>
      <div
        style={{ backgroundColor: "white" }}
        className="landingTools"
        id="landingTools"
        ref={refTools}
      >
        <Tools id={props.id} width={width} height={height} {...props}/>
      </div>
      <div>
        <CallBack id={props.id} name={name} email={email} />
      </div>
      <div style={{ backgroundColor: backgroundColor }}>
        <div className="landingFooterClaim">
          The Security of your personal information is our highest priority. We
          use bank-level encryption (256-bit AEE) to ensure it is never
          compromised.
        </div>
        <div className="landingFooter">
          © Zeitro, Inc. All Rights Reserved 2023.
        </div>
      </div>
    </div>
  );
};

const LoLandingPage = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [basicInfo, setBasicInfo] = useState({});
  const [aboutInfo, setAboutInfo] = useState({});
  const [lenderList, setLenderList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [loading, setLoading] = useState(true)
  const [showContactmeModal, setShowContactmeModal] = useState(false);

  const refAffordability = useRef(null);
  const refRateQuoter = useRef(null);
  const refCalculators = useRef(null);
  const handleClickCalculators = (tab) => {
    if(refCalculators.current){
      setActiveTab(tab)
      refCalculators.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  const [landingUrl, setLandingUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    getProfile()
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const getContactmeModal = () => {
    return (
      <Modal
        show={showContactmeModal}
        onHide={() => setShowContactmeModal(false)}
      >
        <Modal.Body>
          <CallBack id={props.id} name={name} email={email} forHeader={true} />
        </Modal.Body>
      </Modal>
    );
  };
  
  const tabsItems = [
    {
      key: '1',
      label: 'Check Current Rate',
    },
    {
      key: '2',
      label: 'Check Affordability',
    }
  ]
  const [activeTab, setActiveTab] = useState('1')
  const tabsOnChange = (key) => {
    setActiveTab(key)
  }
  const [navDrawer, setNavDrawer] = useState(false);
  const showDrawer = () => {
    setNavDrawer(!navDrawer)
  }
  const onClose = () => {
    setNavDrawer(false)
  }
  const dynamicExternalLink = (href) => {
    if (!/^https?:\/\//i.test(href)) {
        return `https://${href}`
    }
    return href
  }
  const getPreQualificationLink = () => {
    if (window.location.host.includes("zeitro.us")) {
      return "https://app.zeitro.us/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    } 
    if (window.location.host.includes("localhost")) {
      return "http://localhost:3000/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
    }
    return "https://app.zeitro.com/services/preapp?customerid=" + props.customerid + "&loid=" + props.loid
  }
  const getProfile = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-CustomerID": props.customerid,
          "X-LoID": props.loid,
      },
  }).then(response => {
      if (!response.ok) {
          console.log(response)
          setLoading(false);
      } else {
          response.json().then(js => {
          
              let lo = js
              let su = lo.LandingPageSetUp
              let name = lo.FirstName + " " + lo.LastName
              setBasicInfo({
                file: [
                  {
                    url: lo.Image
                  }
                ],
                firstname: lo.FirstName,
                lastname: lo.LastName,
                nmls: lo.NMLS,
                states: typeof su.States === "undefined" ? [] : su.States,
                tagsList: typeof su.TagsList === "undefined" ? [] : su.TagsList,
                popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"]
              })
              setName(name)
              setEmail(lo.Email)
              setAboutInfo({
                introduction: lo.Introduction,
                phone: lo.Phone,
                email: lo.Email,
                companyWebsite: su.CompanyWebsite === undefined ? '' : su.CompanyWebsite,
                google: su.Google === undefined ?'':su.Google ,
                yelp: su.Yelp === undefined ?'':su.Yelp ,
                linkedin: su.Linkedin === undefined ?'':su.Linkedin ,
                twitter: su.Twitter === undefined ?"":su.Twitter ,
              })
              setReviewList( su.ReviewsList===undefined ? []: su.ReviewsList)
              setLandingUrl( window.location.hostname.includes("localhost") ? "http://localhost:3001"+js.LandingUrl :"https://" + window.location.hostname + js.LandingUrl)
              setLoading(false);
              
          })
      }
  }).catch(error => {
      console.log("Revalidate catch error " + error)
  })
  };
  if (loading) return <div className="p-4">
    <Skeleton active/>
    <Skeleton active/>
    <Skeleton active/>
  </div>
  return (
    <div className="landing-page">
      <Helmet>
        <title>{name}'s Loan Services </title>
      </Helmet>
      <div className="landing-nav">
        <div className="menu-left">
          <img src='/images/menu.svg' onClick={showDrawer} />
          <span onClick={() => handleClickCalculators('1')}>Check Current Rate</span>
          <span onClick={() => handleClickCalculators('2')}>Check Affordability</span>
          <span onClick={()=>window.location.href=getPreQualificationLink()}>Get Prequalified</span>
        </div>
        <div className="landing-navList">
          <Button className='contact-btn apply-now-btn' onClick={() => window.location.href=getPreQualificationLink()}>Apply Now</Button>
          <Button className='contact-btn' onClick={() => setShowContactmeModal(true)}>Contact me today</Button>
        </div>
      </div>
      <div>
        <div>
          <div className="landingProfile">
            <Row gutter={20}>
              <Col className="gutter-row" span={24} lg={10}>
                <BasicInfo customerid={props.customerid} loid={props.loid} basicInfo={basicInfo} contact={() => setShowContactmeModal(true)} />
              </Col>
              <Col className="gutter-row" span={24} lg={14}>
                <AboutMe basicInfo={basicInfo} aboutInfo={aboutInfo} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div ref={refCalculators} className="container calculators">
        <div className="calculators-title">Mortgage Calculators</div>
        <Tabs className="calculators-tabs" activeKey={activeTab} items={tabsItems} onChange={tabsOnChange} />
      </div>
      {
        activeTab === '1' && (
          <div
            style={{ backgroundColor: backgroundColor }}
            className="landingRateQuoter"
            id="landingRateQuoter"
            ref={refRateQuoter}
          >
            <LandingRateQuoter customerid={props.customerid} id={props.loid} width={width} height={height} {...props}/>
          </div>
        )
      }
      {
        activeTab === '2' && (
          <div
            style={{ backgroundColor: "white" }}
            className="landingTools pb-2"
            id="landingTools"
            ref={refAffordability}
          >
            <LandingAffordability customerid={props.customerid} loid={props.loid} width={width} height={height} />
          </div>
        )
      }
      <CustomerReview reviewList={reviewList} />
      <div className="landingFooter">
        <Row>
          <Col span={12} lg={4} className="sitemap">
            <div className="rowTitle">Sitemap</div>
            <div className="rowContent">
              <div>Home</div>
              <div onClick={() => handleClickCalculators('1')}>Check Current Rate</div>
              <div onClick={() => handleClickCalculators('2')}>Affordability</div>
              <div onClick={()=>window.location.href=getPreQualificationLink()}>Get Prequalified</div>
            </div>
          </Col>
          <Col span={12} lg={4} className="contact">
            <div className="rowTitle">Contact</div>
            <div className="rowContent">
              <div>{ aboutInfo.phone }</div>
              <div>{ aboutInfo.email }</div>
            </div>
          </Col>
          <Col span={24} lg={6} className="socialMedia">
            <div className="rowTitle">Social media</div>
            <div className="rowContent">
              { aboutInfo.google && <img src='/images/google.svg' alt='google' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.google)} /> }
              { aboutInfo.linkedin && <img src='/images/linkedln.svg' alt='linkedln' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.linkedin)} /> }
              { aboutInfo.twitter && <img src='/images/twitter.svg' alt='twitter' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.twitter)} /> }
              { aboutInfo.yelp && <img src='/images/yelp.svg' alt='yelp' onClick={() => window.location.href = dynamicExternalLink(aboutInfo.yelp)} /> }
            </div>
          </Col>
          <Col span={24} lg={6} className="security">
            <div className="rowTitle">Security</div>
            <div className="rowContent">
              The Security of your personal information is our highest priority. We use bank-level encryption (256-bit AEE) to ensure it is never compromised.
            </div>
          </Col>
          <Col span={24} lg={4} className="QRCode">
            <div>
              <div className="rowTitle" style={{marginBottom: '4px'}}>Scan my QR code</div>
              <div className="rowContent">
                <QRCode
                  className="landing-qrcode"
                  errorLevel="H"
                  value={landingUrl}
                  size={110}
                  bordered={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="copyright">Powered by Zeitro, Inc. All Rights Reserved {new Date().getFullYear()}.</Col>
        </Row>
      </div>
      {showContactmeModal && getContactmeModal()}
      <Drawer className="drawer-nav" placement="top" onClose={onClose} open={navDrawer}>
        <div className="nav" onClick={() => handleClickCalculators('1')}>Check Current Rate</div>
        <div className="nav" onClick={() => handleClickCalculators('2')}>Check Affordability</div>
        <a className="nav" href={getPreQualificationLink()} target="_blank">Get Prequalified</a>
        <div style={{textAlign: 'center'}}><Button className='contact-btn' onClick={() => setShowContactmeModal(true)}>Contact me today</Button></div>
      </Drawer>
    </div>
  );
};

export default LandingPage;
