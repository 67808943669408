import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';

import useInput, { helper } from '../hooks/use-input';
import inputs from '../hooks/hook.module.css';
import classes from './General.module.css';
import { ESP_Gmail, ESP_Bluehost } from '../../State';

const validateEmail = email => String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const emailProviderValidator = value => value === ESP_Gmail || value === ESP_Bluehost;
const allwaysTrue = value => true;

const EmailSetup = () => {

  const [submitted, setSubmitted] = useState(false);
  const [header, setHeader] = useState(<p className='mt-3'>What email service provider do you use?</p>);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [bConfigs, setBConfigs] = useState('');

  const configRef = useRef();


  const {
    value: emailProvider,
    valueInputChangeHandler: emailProviderChangeHandler,
    valueInputBlurHandler: emailProviderBlurHandler,
    reset: resetEmailProvider,
    cssClasses: emailProviderClasses,
  } = useInput(emailProviderValidator, submitted);

  const {
    value: singleEmail,
    valueIsValid: singleEmailIsValid,
    valueInputChangeHandler: singleEmailChangeHandler,
    valueInputBlurHandler: singleEmailBlurHandler,
    reset: resetSingleEmail,
    validationCondition: validationConditionForSingleEmail,
    cssClasses: singleEmailClasses,
  } = useInput(validateEmail, submitted);

  const {
    value: password,
    valueIsValid: passwordIsValid,
    valueInputChangeHandler: passwordChangeHandler,
    valueInputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
    cssClasses: passwordClasses,
  } = useInput(allwaysTrue, submitted);

  const {
    value: password2,
    valueIsValid: password2IsValid,
    valueInputChangeHandler: password2ChangeHandler,
    valueInputBlurHandler: password2BlurHandler,
    reset: resetPassword2,
    cssClasses: password2Classes,
  } = useInput(allwaysTrue, submitted);

  useEffect(() => {
    setPasswordMatch(password === password2);
  }, [password, password2])

  useEffect(() => {
    const token = sessionStorage.getItem("ZeitroA");
    fetch('/los/getemailconfigs', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      }
    }).then(response => {
      if (!response.ok) {
        alert(`contact engineering team please, error: ${response.statusText}`);
        return;
      }

      response.json().then(json => {
        if (json.EmailProvider !== '') {
          let header = `Your email provider is: ${json.EmailProvider}.`
          if (ESP_Gmail === json.EmailProvider) {
            header = header + ` API keys are ${json.KeysPresent ? '' : 'not '}present`
          } else if (ESP_Bluehost === json.EmailProvider) {
            header = header + ` Email address for comunication is${json.SingleEmail === '' ? ' not present' : `: ${json.SingleEmail}`}. Password is ${json.SingleEmailPasswordPresent ? '' : 'not '}present`
            singleEmailChangeHandler(helper(json.SingleEmail));
          }
          setHeader(<Alert className='mt-3' variant='info'>{header}</Alert>)
          emailProviderChangeHandler(helper(json.EmailProvider));
        }
      })
    })
  }, [singleEmailChangeHandler, setHeader, emailProviderChangeHandler])



  const updateEmailConfigs = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let condition = emailProvider === ESP_Bluehost ? singleEmailIsValid && passwordMatch && passwordIsValid && password2IsValid : emailProvider === ESP_Gmail ? bConfigs !== '' : bConfigs !== ''
    if (condition) {

      const token = sessionStorage.getItem("ZeitroA");

      const body = JSON.stringify({
        SingleEmail: singleEmail,
        Password: password,
        EmailProvider: emailProvider,
        Keys: bConfigs,
      });

      fetch('/los/updateemailconfigs', {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body,
      }).then(response => {
        if (!response.ok) {
          alert(`contact engineering team please, error: ${response.statusText}`);
          return;
        }

        alert("Email information is updated successfully")

      }).finally(() => {
        resetSingleEmail();
        resetPassword();
        resetPassword2();
        setSubmitted(false);
        setBConfigs('');
        configRef.current.value = '';
      })
    } else {
      if(emailProvider === ESP_Gmail && bConfigs === ''){
        alert('File is not uploaded');
      } else {
        alert('Something is wrong');
      }
    }
  };

  const configUploadHandler = event => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      let base64String;

      reader.onload = function () {
        base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        setBConfigs(base64String);
      }
      reader.readAsDataURL(file);
    }
  }

  return <div>
    {header}
    <Row>
      <Form.Group as={Col} controlId="emailProvider" className={emailProviderClasses}>
        <Form.Label>Email Provider</Form.Label>
        <Form.Control as='select' onChange={emailProviderChangeHandler} onBlur={emailProviderBlurHandler} value={emailProvider}>
          <option hidden></option>
          <option value={ESP_Gmail}>{ESP_Gmail}</option>
          <option value={ESP_Bluehost}>{ESP_Bluehost}</option>
        </Form.Control>
        <Form.Text className="text-muted">
          Email Provider
        </Form.Text>
      </Form.Group>
    </Row>
    {emailProvider === ESP_Bluehost &&
      <>
        <Row>
          <Form.Group as={Col} className={singleEmailClasses} controlId='singleEmail'>
            <Alert variant='info'>This email will be used for all email communication.</Alert>
            <Form.Label>Single outbound email address</Form.Label>
            <Form.Control type="text" placeholder={validationConditionForSingleEmail ? 'Should be left balnk or follow email format' : 'Enter Address or leave blank'} value={singleEmail} onChange={singleEmailChangeHandler} onBlur={singleEmailBlurHandler} />
            <Form.Text className="text-muted mb-3">Should be empty or follow email format.</Form.Text>
          </Form.Group>
          <Form.Group as={Col} controlId="Password" className={passwordMatch ? passwordClasses : inputs['invalid-input']}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={passwordChangeHandler}
              onBlur={passwordBlurHandler}
              placeholder="Password"
            />
            <Form.Text className="text-muted mb-3">Password</Form.Text>
          </Form.Group>
          <Form.Group as={Col} controlId="Passwor2" className={passwordMatch ? password2Classes : inputs['invalid-input']}>
            <Form.Label>Verify Password</Form.Label>
            <Form.Control
              type="password"
              value={password2}
              onChange={password2ChangeHandler}
              onBlur={password2BlurHandler}
              placeholder="Verify Password"
            />
            <Form.Text className="text-muted mb-3">Verify Password</Form.Text>
          </Form.Group>
        </Row>
        {!passwordMatch && <Alert variant='danger'>Passwords don't match</Alert>}
        <Row className='m-3'>
          <Col>
            <Button variant="primary" onClick={updateEmailConfigs}>
              Submit
            </Button>
          </Col>
        </Row>
      </>
    }
    {emailProvider === ESP_Gmail &&
      <>
        <div className={`${classes.textAlignLeft} m-3`}>
          <h3>1. Create a project</h3>
          <ol>
            <li>Sign in to <a href="https://console.cloud.google.com/" target='_blank' rel="noopener noreferrer">Google Cloud Platform console</a> using your corporate email address.</li>
            <li>Create a new project, using your domain as a value for organization field.</li>
          </ol>
          <h3>2. Enable API for Gmail</h3>
          <ol>
            <li>Select the project from a drop-down list at the top of the page.</li>
            <li>Go to APIs, click on enable APIs and services.</li>
            <li>Search for Gmail and click on Gmail API from the search results.</li>
            <li>Enable Gmail API.</li>
          </ol>
          <h3>3. Create a service account and private keys</h3>
          <ol>
            <li>Go to the <a href="https://console.cloud.google.com/iam-admin/serviceaccounts" target='_blank' rel="noopener noreferrer">Service Accounts</a> page. Make sure you are still using same email address and the project is selected in the drop-down list at the top of the page.</li>
            <li>Click Create Service Account.</li>
            <li>Fill in the form, only "Service account name" and "Service account ID" fields are mandatory.</li>
            <li>Click on the email of a newly created service account in the list.</li>
            <li>Go to keys tab.</li>
            <li>Click on "Add key" &#8594; "Create new key" &#8594; select JSON and click create &#8594; download the file.</li>
            <p className={classes.warningText}>Warning: Anybody, who has access to this file or it's content will be able to send and recieve emails on behalf of people within your organization.</p>
            <li>Upload downloaded file below. We will syphered content and store it. This configurations will be used to send emails on behalf of people within your organization.</li>
            <Form.Group controlId="JSONconfig" className={bConfigs === '' && submitted ? `${inputs['invalid-input']} mb-3` : 'mb-3'}>
              <Form.Control ref={configRef} type="file" onChange={configUploadHandler} />
            </Form.Group>
            <li>Go to details tab.</li>
            <li>Copy Unique ID (Client ID).</li>
          </ol>
          <h3>4.Grant permissions to the service account in G-Suit</h3>
          <ol>
            <li>Sign in to <a href='http://admin.google.com/' target='_blank' rel="noopener noreferrer">G-Suite admin portal</a>  using your G-Suite admin account.</li>
            <li>Go to Security.</li>
            <li>Click API controls.</li>
            <li>Click Manage Domain Wide Deligation.</li>
            <li>Click Add new</li>
            <li>Use Unique ID (Client ID) from 3.9.</li>
            <li>Add following API Scopes (without quotes) : "https://www.googleapis.com/auth/gmail.metadata", "https://www.googleapis.com/auth/gmail.modify". For you reference, list of scopes and explanations to them: <a href='https://developers.google.com/gmail/api/auth/scopes' target='_blank' rel="noopener noreferrer">here</a></li>
          </ol>
        </div>
        <Row>
          <Col>
            <Button onClick={updateEmailConfigs}>Submit</Button>
          </Col>
        </Row>
      </>
    }

  </div>
}

export default EmailSetup;