import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

const Bankstatement = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    doc.map((item, i) =>
      <div key={i}>
        <Row>
          <Form.Group as={Col} controlId={`BankName${i}`}>
            <Form.Label>Bank name</Form.Label>
            <Form.Control defaultValue={item['BankName']['Value']} onFocus={() => onFocus(item['BankName']['BBox'], item['BankName']['Page'])} type="text" onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'BankName', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`ClientName${i}`}>
            <Form.Label>Client name</Form.Label>
            <Form.Control defaultValue={item['ClientName']['Value']} type="text" onFocus={() => onFocus(item['ClientName']['BBox'], item['ClientName']['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'ClientName', 'Value'])} />
          </Form.Group>
        </Row>
        <h2>Transactions</h2>
        {item["Transactions"].map((transaction, j) =>
          <div key={`Transactions${i}${j}`}>
            <Row>
              <Form.Group as={Col} controlId={`date${i}${j}`}>
                <Form.Label>Date</Form.Label>
                <Form.Control defaultValue={transaction['date']} type="text" onFocus={() => onFocus(transaction['BBox'], transaction['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'Transactions', j, 'date'])} />
              </Form.Group>
              <Form.Group as={Col} controlId={`type${i}${j}`}>
                <Form.Label>Type</Form.Label>
                <Form.Control defaultValue={transaction['type']} type="text" onFocus={() => onFocus(transaction['BBox'], transaction['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'Transactions', j, 'type'])} />
              </Form.Group>
              <Form.Group as={Col} controlId={`amount${i}${j}`}>
                <Form.Label>Amount</Form.Label>
                <Form.Control defaultValue={transaction['amount']} type="text" onFocus={() => onFocus(transaction['BBox'], transaction['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'Transactions', j, 'amount'])} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId={`description${i}${j}`}>
                <Form.Label>Description</Form.Label>
                <Form.Control defaultValue={transaction['description']} type="text" onFocus={() => onFocus(transaction['BBox'], transaction['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'Transactions', j, 'description'])} />
              </Form.Group>
            </Row>
            <hr style={{ borderWidth: '3px' }} />
          </div>
        )}
        <Row>
          <Form.Group as={Col} controlId={`AccountNumber${i}`}>
            <Form.Label>Account number</Form.Label>
            <Form.Control defaultValue={item['AccountNumber']['Value']} type="text" onFocus={() => onFocus(item['AccountNumber']['BBox'], item['AccountNumber']['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'AccountNumber', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`ClientAddress${i}`}>
            <Form.Label>Client address</Form.Label>
            <Form.Control defaultValue={item['ClientAddress']['Value']} type="text" onFocus={() => onFocus(item['ClientAddress']['BBox'], item['ClientAddress']['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'ClientAddress', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`EndingBalance${i}`}>
            <Form.Label>Ending balance</Form.Label>
            <Form.Control defaultValue={item['EndingBalance']['Value']} type="text" onFocus={() => onFocus(item['EndingBalance']['BBox'], item['EndingBalance']['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'EndingBalance', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`StartingBalance${i}`}>
            <Form.Label>Starting balance</Form.Label>
            <Form.Control defaultValue={item['StartingBalance']['Value']} type="text" onFocus={() => onFocus(item['StartingBalance']['BBox'], item['StartingBalance']['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'StartingBalance', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId={`StatementEndDate${i}`}>
            <Form.Label>Statement end date</Form.Label>
            <Form.Control defaultValue={item['StatementEndDate']['Value']} type="text" onFocus={() => onFocus(item['StatementEndDate']['BBox'], item['StatementEndDate']['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'StatementEndDate', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId={`StatementStartDate${i}`}>
            <Form.Label>Statement start date</Form.Label>
            <Form.Control defaultValue={item['StatementStartDate']['Value']} type="text" onFocus={() => onFocus(item['StatementStartDate']['BBox'], item['StatementStartDate']['Page'])} onChange={e => onChangeTextInput('bankstatement.pdf', e.target.value, [i, 'StatementStartDate', 'Value'])} />
          </Form.Group>
        </Row>
        <br />
        <br />
        <br />
        <br />
      </div>
    )
  );
}

export default Bankstatement;