import React from 'react';
import classes from './Deadline.module.css';
import commonClasses from './Common.module.css';
import { dateDiffInDays } from '../../Utils/Utils';

const Deadline = ({ deadline }) => {

  let deadlineStyle = `${classes.deadline} ${commonClasses.tag} `;
  const deadlineDate = new Date(deadline);
  const localDateString = deadlineDate.toLocaleDateString()
  let differenceInDays = dateDiffInDays(deadlineDate, new Date()) // deadline and now;

  if (differenceInDays > 7) {
    deadlineStyle += commonClasses.greenBg;
  } else if (differenceInDays > 4) {
    deadlineStyle += commonClasses.orangBg;
  } else {
    deadlineStyle += commonClasses.redBg;
  }

  return (
    <span className={deadlineStyle}>
      <i className="far fa-clock"></i> {localDateString}
    </span>
  );
}

export default Deadline;