import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

import * as com from "../Common.js"
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import DateOfBirth from '../DateOfBirth'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Autocomplete from '../Autocomplete'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { tooltip } from '../Common/Tooltip'
import { FutureExpenses } from '../State'
import { titles } from '../occupations.js'

export function calculateEmploymentIncome(app, who) {
    let income = 0
    let employment = app[who].occupation

    let getIncomeType = (employment, token) => {
        switch (employment.income[token + "monthly"]) {
            case "monthly": {
                let v = parseFloat(employment.income[token])
                if (!isNaN(v))
                    return v
                break
            }
            case "yearly": {
                let v = parseFloat(employment.income[token])
                if (!isNaN(v))
                    return v / 12
                break
            }
            case "hourly": {
                let v = parseFloat(employment.income[token])
                let h = parseFloat(employment.income[token + "hoursperweek"])
                if (!isNaN(v) && !isNaN(h))
                    return v * h * 52 / 12
                break
            }
            default:
                break
        }
        return 0
    }
    if (app[who].occupation.hasoccupation === "employed") {
        if (app[who].occupation.selfemployed === true) {
            switch (employment.incomeorlossperiod) {
                case "monthly": {
                    let v = parseFloat(employment.incomeorloss)
                    if (!isNaN(v))
                        income += v
                    break
                }
                case "yearly": {
                    let v = parseFloat(employment.incomeorloss)
                    if (!isNaN(v))
                        income += v / 12
                    break
                }
                default:
                    break
            }
        } else {

            income += getIncomeType(employment, "base")
            income += getIncomeType(employment, "commissions")
            income += getIncomeType(employment, "overtime")
            income += getIncomeType(employment, "bonuses")
        }
        // other current income:
        for (let i = 0; app[who].otheroccupations !== null && (i < app[who].otheroccupations.length); i++) {
            let employment = app[who].otheroccupations[i]
            income += getIncomeType(employment, "base")
            income += getIncomeType(employment, "commissions")
            income += getIncomeType(employment, "overtime")
            income += getIncomeType(employment, "bonuses")
        }

    }
    return income
}
export function calculateDividendIncome(app, who) {
    let income = 0
    if (app[who].income.hasdividends) {
        let dividends = parseFloat(app[who].income.dividends)
        if (!isNaN(dividends)) {
            
                switch (app[who].income.dividendsmonthly) {
                    case "monthly": {
                        income += dividends
                        break
                    }
                    case "yearly": {
                        income += dividends / 12
                        break
                    }
                    default:
                        break
                }
   
        }
    }
    return income
}

export function calculateOtherIncome(app, who) {
    let income = 0
    if (app[who].income.otherincome !== null && app[who].income.otherincome.length > 0) {
        app[who].income.otherincome.forEach(
            inc => {
                let v = parseFloat(inc.amount)
                if (isNaN(v))
                    return
                switch (inc.period) {
                    case "monthly": {
                        income += v
                        break
                    }
                    case "yearly": {
                        income += v / 12
                        break
                    }
                    default:
                        break
                }
            }
        )
    }
    return income
}

export function calculateIncomeWho(app, who) {
    let income = calculateEmploymentIncome(app, who)
    income += calculateDividendIncome(app, who)
    income += calculateOtherIncome(app, who)

    return income
}
const calculateIncome = (app) => {
    let income = 0
    income += calculateIncomeWho(app, "borrower")
    if (app.hascoborrower === "withcoborrower") {
        // really not correct for multiple borrowers, REDO
        income += calculateIncomeWho(app, "coborrower")
    }
    return income
}
const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        application: state.application,
    }
}
const twoyears = 2 * 365 * 24 * 60 * 60 * 1000

const mapDispatchToProps = (dispatch) => ({
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changePhoneInfo: (phone, who, verb) => {
        dispatch(act.ChangeOccupationInfo(phone, who, verb))
    },
    changeOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.checked, who, verb))
    },
    addAdditionaOccupation: (event, who) => {
        dispatch(act.AddAdditionaOccupation(event.target.value, who))
    },
    removeAdditionalOccupation: (event, who, i) => {
        dispatch(act.RemoveAdditionalOccupation(event.target.value, who, i))
    },
    clearAdditionalOccupations: (event, who) => {
        dispatch(act.ClearAdditionalOccupations(event.target.value, who))
    },
    changePastOccupationInfo: (event, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(event.target.value, who, verb, index))
    },
    changePastOccupationDate: (date, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(date, who, verb, index))
    },
    changePastOccupationPhone: (phone, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(phone, who, verb, index))
    },
    changePastOccupationCheck: (event, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(event.target.checked, who, verb, index))
    },
    changeCurrentOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(!event.target.checked, who, verb))
    },

    addBorrowerOtherOccupation: (who) => {
        dispatch(act.AddBorrowerOtherOccupation(who))
    },
    removeBorrowerOtherOccupation: (who, index) => {
        dispatch(act.RemoveBorrowerOtherOccupation(who, index))
    },
    clearBorrowerOtherOccupations: (who) => {
        dispatch(act.ClearBorrowerOtherOccupations(who))
    },
    changeBorrowerOtherOccupationValue: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationValue(t, i, who, verb))
    },
    updateIncomeMonthly: (event, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(event.target.value, who, verb))
    },
    setBorrowerHasAdditionalIncome: (value, who) => {
        dispatch(act.SetBorrowerHasAdditionalIncome(value, who))
    },
    addBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.AddBorrowerAdditionalIncome(t, who))
    },
    removeBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.RemoveBorrowerAdditionalIncome(t, who))
    },
    clearBorrowerAdditionalIncome: (who) => {
        dispatch(act.ClearBorrowerAdditionalIncome(who))
    },
    updateBorrowerAdditionalIncome: (event, who, verb, n) => {

        dispatch(act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n))
    },
    changeIncomeCheck: (checked, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(checked, who, verb))
    },
    updateIncomeAtttribute: (t, who, verb) => {
        dispatch(act.UpdateIncomeAtttribute(t, who, verb))
    },

});


class Income extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            validated: false,
            showSelf: false,
            currentJob: false,
            index: -1
        }

        this.refsfrom = []
        this.refsto = []
        if (this.props.application[this.props.who].previousoccupations !== null) {
            for (let i = 0; i < this.props.application[this.props.who].previousoccupations.length; i++) {
                this.refsfrom.push(React.createRef())
                this.refsto.push(React.createRef())
            }
        }

        this.crefsfrom = []
        if (this.props.application[this.props.who].otheroccupations !== null) {
            for (let i = 0; i < this.props.application[this.props.who].otheroccupations.length; i++) {
                this.crefsfrom.push(React.createRef())

            }
        }
        this.isValidated = this.isValidated.bind(this);
        this.yearsatlineofwork = React.createRef()
    }
    componentDidMount() {
    }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    validateDates = () => {
        if (this.enoughCurrentJob())
            return true
        let len = 0
        if (null != this.props.application[this.props.who].previousoccupations)
            len = this.props.application[this.props.who].previousoccupations.length

        let validate = false
        let oldenough = false

        for (let i = 0; i < len; i++) {
            let to = com.dateFromDate(this.props.application[this.props.who].previousoccupations[i].to)
            let from = com.dateFromDate(this.props.application[this.props.who].previousoccupations[i].from)

            let t = new Date(to)
            let f = new Date(from)

            this.refsfrom[i].current.setCustomValidity("")
            this.refsto[i].current.setCustomValidity("")

            let old = new Date("01/01/1900")
            let now = new Date()
            let howold = now - f
            if (howold >= twoyears)
                oldenough = true

            if (f > t) {
                this.refsfrom[i].current.setCustomValidity("Must be earlier than To")
                validate = true
            }
            if (t < old) {
                this.refsto[i].current.setCustomValidity("Too old to be true")
                validate = true
            }
            if (f < old) {
                this.refsfrom[i].current.setCustomValidity("Too old to be true")
                validate = true
            }
            if (t >= now) {
                this.refsto[i].current.setCustomValidity("Can't be in the future")
                validate = true
            }
            if (f >= now) {
                this.refsfrom[i].current.setCustomValidity("Can't be in the future")
                validate = true
            }

            if (from >= to) {
                this.refsfrom[i].current.setCustomValidity("The To date should be after From date for " + this.props.application[this.props.who].previousoccupations[i].employername)
                validate = true
            }
        }

        if (!oldenough) {
            this.props.addAdditionaOccupation({ target: { value: "" } }, this.props.who)
            return false
        }
        /*
                len = 0
                if (null != this.props.application[this.props.who].otheroccupations)
                    len = this.props.application[this.props.who].otheroccupations.length
                for (let i = 0; i < len; i++) {
                    let from = com.dateFromDate(this.props.application[this.props.who].otheroccupations[i].from)
        
                    let f = new Date(from)
                    let cref = this.crefsfrom[i].current
                    cref.setCustomValidity("")
        
                    let old = new Date("01/01/1900")
                    let now = new Date()
        
                    if (f < old) {
                        cref.setCustomValidity("Too old to be true")
                        validate = true
                    }
        
                    if (f >= now) {
                        cref.setCustomValidity("Can't be in the future")
                        validate = true
                    }
        
                }
        */
        return !validate
    }
    handleSubmit = (event) => {

        if (!this.enoughCurrentJob()) {
            if (null !== this.props.application[this.props.who].previousoccupations && this.props.application[this.props.who].previousoccupations.length > 0) {
                if (!this.validateDates()) {
                    event.preventDefault();
                    this.setState({ validated: true })

                    return false
                }
            }
        }
        /*
                let atjob = parseInt(this.props.application[this.props.who].occupation.yearsatjob)
                let atline = parseInt(this.props.application[this.props.who].occupation.yearsatlineofwork)
                let len = 0
                if (null != this.props.application[this.props.who].previousoccupations)
                    len = this.props.application[this.props.who].previousoccupations.length
                if (atjob > atline) {
                    this.yearsatlineofwork.current.setCustomValidity("Can't be less than current occupation")
                    return false
                } else {
                    this.yearsatlineofwork.current.setCustomValidity("")
                }
        
                if (!this.validateDates()) {
                    event.preventDefault();
                    this.setState({ validated: true })
        
                    return false
                }
                if (atjob >= 2 && len > 0) {
                    window.setTimeout(() => this.props.clearAdditionalOccupations({ target: { value: 0 } }, this.props.who), 100)
                }
        */
        return true
    }

    process = (val, index) => {
        if (null === val)
            return ""
        let key = val.key

        return this.renderprevious(index, key)
    }

    renderprevious = (index, key) => {
        index = parseInt(index);
        let changePastOccupationInfo = (who, verb) => {

            return (event) => {
                com.touch()
                this.props.changePastOccupationInfo(event, who, verb, index)
            }
        }
        let changePastOccupationDate = (who, verb, index) => {
            return (event) => {
                com.touch()
                let fixed = com.fixDateInput(event.target.value)
                let from = this.refsfrom[index].current.value
                let to = this.refsto[index].current.value
                let expr = /^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$/ig

                if (to && from && to.match(expr) && from.match(expr)) {
                    let t = new Date(to)
                    let f = new Date(from)
                    this.refsfrom[index].current.setCustomValidity("")
                    this.refsto[index].current.setCustomValidity("")
                    let old = new Date("01/01/1900")
                    let now = new Date()
                    if (f > t) {
                        this.refsfrom[index].current.focus()
                        this.refsfrom[index].current.setCustomValidity("Must be earlier than To")
                    }
                    if (t < old) {
                        this.refsfrom[index].current.focus()
                        this.refsto[index].current.setCustomValidity("Too old to be true")
                    }
                    if (f < old) {
                        this.refsfrom[index].current.focus()
                        this.refsfrom[index].current.setCustomValidity("Too old to be true")
                    }
                    if (t >= now) {
                        this.refsto[index].current.focus()
                        this.refsto[index].current.setCustomValidity("Can't be in the future")
                    }
                    if (f >= now) {
                        this.refsto[index].current.focus()
                        this.refsfrom[index].current.setCustomValidity("Can't be in the future")
                    }
                }
                return this.props.changePastOccupationDate(fixed, who, verb, index)
            }
        }

        let changePastOccupationCheck = (who, verb) => {
            return event => {
                com.touch()
                if (event.target.checked && verb === "selfemployed") {
                    this.setState({ showSelf: true, currentJob: false, index: index })
                    this.props.changePastOccupationCheck(event, who, verb, index)
                } else
                    this.props.changePastOccupationCheck(event, who, verb, index)
            }
        }

        let addAdditionaOccupation = (who) => {
            return (event) => {
                com.touch()
                let reffrom = React.createRef();
                let refto = React.createRef();
                this.refsfrom.push(reffrom)
                this.refsto.push(refto)
                return this.props.addAdditionaOccupation(event, who)
            }
        }
        let removeAdditionalOccupation = (who) => {
            return (event) => {
                com.touch()
                this.refsfrom.splice(index)
                this.refsto.splice(index)
                return this.props.removeAdditionalOccupation(event, who, index)
            }
        }

        let changePastOccupationPhone = (who, verb) => {
            return (event) => {
                com.touch()
                let phone = com.fixPhoneInput(event.target.value)
                return this.props.changePastOccupationInfo({ target: { value: phone } }, who, verb, index)
            }
        }
        return (

            <div key={"prevjob_" + key} className="incard m-0 mb-3 ">
                <Row>
                    <Col className="p-0 pl-2">

                        <Form.Row >
                            <Col >
                                <Form.Group controlId={"Employer1" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Previous employer</Form.Label>
                                    <Form.Control isValid={false} size="sm"
                                        required
                                        name={"employer" + index}
                                        type="text"
                                        defaultValue={this.props.application[this.props.who].previousoccupations[index].employername}
                                        onChange={changePastOccupationInfo(this.props.who, "employername")}


                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Provide employer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="auto"  >
                                <Form.Group controlId={"from" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Dates from</Form.Label>
                                    <DateOfBirth
                                        size="sm"
                                        isValid={false}
                                        //ref={this.refsfrom[index]}
                                        onRef={ref => { this.refsfrom[index] = ref }}
                                        //reff={this.refsfrom[index]}
                                        required
                                        placeholder="MM/DD/YYYY"
                                        name={"ocfrom" + index}
                                        type="text"
                                        value={this.props.application[this.props.who].previousoccupations[index].from}
                                        onChange={changePastOccupationDate(this.props.who, "from", index)}
                                        negativefeedback="Provide valid start"
                                        pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="auto" >
                                <Form.Group controlId={"to" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >To:</Form.Label>
                                    <DateOfBirth size="sm" isValid={false}
                                        required
                                        //ref={this.refsto[index]}
                                        onRef={ref => { this.refsto[index] = ref }}
                                        reff={this.refsto[index]}
                                        placeholder="MM/DD/YYYY"
                                        name={"octo" + index}
                                        type="text"
                                        value={this.props.application[this.props.who].previousoccupations[index].to}
                                        onChange={changePastOccupationDate(this.props.who, "to", index)}
                                        negativefeedback="Provide valid end"
                                        pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                    />
                                </Form.Group>
                            </Col>


                        </Form.Row >
                        <Form.Row>
                            <Col  >
                                <AddressAutocomplete id={"EmployerAddr1" + index} label="Address:"
                                    required={true} name={"employeraddr" + index}

                                    defaultValue={this.props.application[this.props.who].previousoccupations[index].employeraddress}
                                    onChange={changePastOccupationInfo(this.props.who, "employeraddress")}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    placeholder="Street Address, No P.O.Box accepted"

                                    goodfeedback="Looks good!"
                                    badfeedback="Please provide address."
                                />
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId={"pjobphone" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >{tooltip("HR phone number",
                                        <div>Specify the HR/Personnel or hiring manager phone number to verify employment.
                                        </div>, 'bottom', ''
                                    )}

                                    </Form.Label>
                                    <Form.Control size="sm" isValid={false}
                                        required
                                        name={"cjobphone" + index}
                                        type="text"
                                        placeholder="(###)-###-####"
                                        pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                                        value={this.props.application[this.props.who].previousoccupations[index].phone}
                                        onChange={changePastOccupationPhone(this.props.who, "phone")}

                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Provide phone number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row >
                            <Col >
                                <Autocomplete
                                    required
                                    id={"PrevTitle" + index}
                                    suggestions={titles}
                                    name="position"
                                    type="text"
                                    label="Position/title"
                                    valid="Looks good!"
                                    invalid="Please enter position"
                                    value={this.props.application[this.props.who].previousoccupations[index].positiontitle}
                                    onChange={changePastOccupationInfo(this.props.who, "positiontitle")}
                                />

                            </Col>


                            <Col xs="auto" style={{ width: '10.5em' }}>
                                <Form.Group controlId="income0" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Yearly income</Form.Label>

                                    <NumericalInput isValid={false}
                                        required
                                        name={"income" + index}
                                        defaultValue={this.props.application[this.props.who].previousoccupations[index].income}
                                        onChange={changePastOccupationInfo(this.props.who, "income")}
                                        size="sm"
                                        min={0}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide yearly income.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="text-left nowrap d-block " >
                                &nbsp;<br />
                                <Form.Group controlId={"income" + index} className="form-inline mt-2" >
                                    <Form.Check inline

                                        checked={this.props.application[this.props.who].previousoccupations[index].selfemployed}
                                        onChange={changePastOccupationCheck(this.props.who, "selfemployed")}

                                        key={"selfemployed" + index}
                                        ref={this.refs[index]}
                                        type='checkbox' id={'selfemployment' + index} label='Self&nbsp;Employed' />
                                </Form.Group>
                            </Col>


                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="px-0 text-right aligh-top">
                        <i hidden={index !== this.props.application[this.props.who].previousoccupations.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addAdditionaOccupation(this.props.who)} ></i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeAdditionalOccupation(this.props.who)} ></i>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-2">
                        <Form.Group controlId={"pastemployedbyagent" + index} className="text-left d-block text-nowrap " >
                            <Form.Check type="checkbox" className="mx-3 px-0 text-wrap"
                                checked={this.props.application[this.props.who].previousoccupations[index].employedbyagent}
                                key={Math.random()}
                                onChange={changePastOccupationCheck(this.props.who, "employedbyagent")}
                                label="Check if employed by a family member,
                    property seller, real estate agent, or other
                    party to the transaction"></Form.Check>
                        </Form.Group>

                    </Col>
                </Row>
            </div>
        )
    }

    hideOccupation = () => {
        this.props.updateBorrowerHasOccupation({ target: { checked: true } })
    }
    showOccupation = () => {
        this.props.updateBorrowerHasOccupation({ target: { checked: true } })
    }
    updateBorrowerYearsAtJob = (e) => {
        com.touch()
        if (parseInt(e.target.value) <= 2) {

        }
        this.props.updateBorrowerYearsAtJob(e)
    }
    processOccupationYears(years) {
        if (years === "") {
            return
        }
        if (years < 2) {
            if (null === this.props.application[this.props.who].previousoccupations) {
                this.props.addAdditionaOccupation({ target: { value: "" } }, this.props.who)

                let reffrom = React.createRef();
                let refto = React.createRef();
                this.refsfrom.push(reffrom)
                this.refsto.push(refto)

            }
        } else {
            if (this.props.application[this.props.who].previousoccupations !== null && this.props.application[this.props.who].previousoccupations.length !== 0)
                this.props.clearAdditionalOccupations({ target: { value: 0 } }, this.props.who)
        }
    }
    handleSelfClose = () => {
        this.setState({ showSelf: false })
    }
    handleSelfCloseOff = () => {
        if (this.state.index === -1) {
            this.props.changeCurrentOccupationCheck({ target: { checked: true } }, this.props.who, "selfemployed")
            this.forceUpdate()

        } else {
            if (!this.state.currentJob) {
                this.props.changePastOccupationCheck({ target: { checked: false } }, this.props.who, "selfemployed", this.state.index)
            } else {
                this.props.changeBorrowerOtherOccupationValue(false, this.state.index, this.props.who, "selfemployed")
            }
        }
        this.setState({ showSelf: false })
    }


    updateMonthly = (who, verb) => {
        return e => {
            com.touch()
            this.props.updateIncomeAtttribute(e.target.value, who, verb)
        }
    }
    enoughCurrentJob = () => {
        let from = this.props.application[this.props.who].occupation.from
        if (from.length < 10)
            return true

        let f = new Date(from)

        let now = new Date()
        let howold = now - f

        return (howold >= twoyears)
    }
    processOtherIncome = (val, index) => {
        if (null === val)
            return ""
        let desc = val.description;
        let amount = val.monthlyamount;
        let key = val.key
        return this.extraIncome(index, desc, amount, key)
    }
    handleSelfClose = () => {
        this.setState({ showSelf: false })
    }
    handleSelfCloseOff = () => {

        this.props.changeCurrentOccupationCheck({ target: { checked: true } }, this.props.who, "selfemployed")

        this.setState({ showSelf: false })
    }

    extraIncome = (index, desc, amount, key) => {
        index = parseInt(index)

        let removePreviousOccupation = (event) => {
            this.props.removeBorrowerAdditionalIncome(index, this.props.who)
        }
        let updateBorrowerAdditionalIncome = (who, verb) => {
            return (event) => {
                com.touch()

                this.props.updateBorrowerAdditionalIncome(event, who, verb, index)
            }
        }
        let onSelectAdditionalIncome = e => {
            com.touch()

            this.props.updateBorrowerAdditionalIncome({ target: { value: e } }, this.props.who, "incometype", index)
            let description = ""
            com.othersouresofincome.forEach(x => {
                if (x[0] === e) {
                    description = x[1]
                }
            })
            if (e === "Other")
                description = ""

            this.props.updateBorrowerAdditionalIncome({ target: { value: description } }, this.props.who, "description", index)
        }

        let updateMonthly = (who, verb) => {
            return (event) => {
                com.touch()
                return this.props.updateBorrowerAdditionalIncome(event, who, verb, index)
            }
        }
        let showDescription = () => {
            let tp = this.props.application[this.props.who].income.otherincome[index].incometype
            let description = ""
            com.othersouresofincome.forEach(x => {
                if (x[0] === tp) {
                    description = x[2]
                }
            })
            if (tp === "Other" || description === "")
                return "Description:"
            return tooltip("Description:", description, "auto", "", "")
        }

        return (
            <div className="incard p-0 mx-0 my-2" key={key}>
                <Row className="mx-0 px-0">
                    <Col >
                        <Form.Row >
                            <Col className="" xs="auto">

                                <Form.Group controlId="firsttime" className="text-left" >
                                    <Form.Label>Period:</Form.Label>
                                    <Form.Control as="select"
                                        //defaultValue={this.props.firsttime}
                                        type="text"
                                        size="sm"
                                        defaultValue={this.props.application[this.props.who].income.otherincome[index].period}
                                        onChange={updateMonthly(this.props.who, "period")}
                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="">
                                <Form.Group controlId={"description" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >{showDescription()}</Form.Label>
                                    <InputGroup>
                                        <Form.Control isValid={false} size="sm"
                                            readOnly={"Other" !== this.props.application[this.props.who].income.otherincome[index].incometype}
                                            required
                                            pattern="^.+$"
                                            name={"description" + index}
                                            type="text"
                                            value={this.props.application[this.props.who].income.otherincome[index].description}
                                            onChange={updateBorrowerAdditionalIncome(this.props.who, "description")}

                                        />
                                        <DropdownButton
                                            as={InputGroup.Append}
                                            variant="dropdown"
                                            className="incdropdown"
                                            title=""
                                            id="dropdown-income"
                                            size="sm"
                                            onSelect={onSelectAdditionalIncome}
                                        >   {com.othersouresofincome.map(st =>
                                        (
                                            <Dropdown.Item key={st[0]} eventKey={st[0]} href="#">{st[1]}</Dropdown.Item>
                                        )
                                        )}
                                        </DropdownButton>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please describe the source of income.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" style={{ width: '10.5em' }}>
                                <Form.Group controlId="overtime" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Amount:</Form.Label>

                                    <NumericalInput isValid={false}
                                        required
                                        size="sm"
                                        min={0}
                                        defaultValue={this.props.application[this.props.who].income.otherincome[index].amount}
                                        onChange={updateBorrowerAdditionalIncome(this.props.who, "amount")}
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide overtime income.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Form.Row >
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top px-1">
                        <i hidden={index !== this.props.application[this.props.who].income.otherincome.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={this.addMoreSources} ></i>
                        <i hidden={index === this.props.application[this.props.who].income.otherincome.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removePreviousOccupation} ></i>
                    </Col>
                </Row>
            </div>
        )
    }
    addOtherSources = (e) => {
        com.touch()
        if (e.target.value !== "true") {
            this.props.clearBorrowerAdditionalIncome(this.props.who)
        } else {
            this.props.addBorrowerAdditionalIncome(0, this.props.who)
        }
    }
    addMoreSources = () => {
        com.touch()
        this.props.addBorrowerAdditionalIncome(0, this.props.who)

    }
    render = () => {


        let addAdditionaOccupation = (who) => {
            return (event) => {
                com.touch()
                let reffrom = React.createRef();
                let refto = React.createRef();
                this.refsfrom.push(reffrom)
                this.refsto.push(refto)
                return this.props.addAdditionaOccupation(event, who)
            }
        }
        let clearAdditionalOccupations = (who) => {
            return (event) => {
                com.touch()
                return this.props.clearAdditionalOccupations(event, who)
            }
        }

        let changeHasDividends = (who, verb) => {
            return (event) => {
                com.touch()

                let val = event.target.value === "true"
                this.props.updateIncomeAtttribute(val, who, verb)
            }
        }
        let updateIncomeMonthly = (who, verb) => {
            return e => {
                com.touch()
                this.props.updateIncomeAtttribute(e.target.value, who, verb)
            }
        }
        let showWarning = () => {
            return <div style={{border: 'solid 1px #c99',borderRadius: '8px', fontWeight: 'bold', color: '#993333'}} class="p-2 text-wrap">Attention! You have an insufficient job history to qualify for an agency loan. While we might still be able to find a Non-QM loan for you, it will likely have a higher interest rate and/or closing costs.</div>
        }
        
        return (
            <div className=" mb-3  text-left">


                <Modal dialogClassName=" align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showSelf} onHide={this.handleSelfClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you self employed?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <div className="mb-4">You only qualify for self employed status if you own 25% of your company or more.</div>
                        <div> Otherwise you are a salaried employee for the purpose of obtaining a loan.</div>
                    </Modal.Body>
                    <Modal.Footer style={{ fontSize: '0.8em' }} className="text-center">
                        <Row className="modalfooter">
                            <Col>
                                <Button variant="primary" onClick={this.handleSelfCloseOff} className="text-nowrap">No, I own less than 25%</Button>
                            </Col>
                            <Col>
                                <Button variant="success" style={{ fontSize: '1.0em' }} onClick={this.handleSelfClose} className="text-nowrap">Yes, I own 25% or more</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>

                <h2 className=" heading">
                    <Row>
                        <Col>
                            Tell us more about {this.props.application[this.props.who].firstname} {this.props.application[this.props.who].lastname}'s income
                        </Col>
                        <Col className="hideonphone" xs="auto" style={{ marginTop: "4px", fontSize: "0.7em", fontWeight: "normal", position: "absolute", right: "4em" }}> Monthly income: ${(com.commaizeFloat(calculateIncome(this.props.application).toFixed(2)))}</Col>
                    </Row>
                </h2>

                {this.props.application[this.props.who].occupation.hasoccupation === "employed" && this.enoughCurrentJob() ? "" :
                    <div className="viewport" >
                        <Form.Row className="mb-1">
                            <Col as="div" className="align-middle  text-left"  >
                                <div className="w-100 align-middle  text-wrap sectionquestion">If you claim employment income, you need to demonstrate at least two years of employment (including tertiary education). Have you been employed in the previous two years?</div>

                                <Form.Group>
                                    <fieldset>
                                        <div className="d-flex mt-3">
                                            <div className="mr-3 ">
                                                <input required onChange={clearAdditionalOccupations(this.props.who)} value="false"
                                                    checked={null !== this.props.application[this.props.who].previousoccupations && this.props.application[this.props.who].previousoccupations.length === 0}
                                                    type="radio" id="haspreviousnot" name="hasprevious"></input>
                                                <label className="divlink" htmlFor="haspreviousnot">No, I have not</label></div>
                                            <div className="mr-3 ">
                                                <input required onChange={addAdditionaOccupation(this.props.who)} value="true"
                                                    checked={null !== this.props.application[this.props.who].previousoccupations && this.props.application[this.props.who].previousoccupations.length > 0}
                                                    type="radio" id="hasprevious" name="hasprevious"></input>
                                                <label className="zeitro-radio  divlink" htmlFor="hasprevious">Yes, I have</label></div>
                                        </div>
                                    </fieldset>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please specify if you had other occupations within the last 2 years
                                    </Form.Control.Feedback>
                                </Form.Group>


                            </Col>
                        </Form.Row >

                        {this.props.application[this.props.who].previousoccupations === null ? 
                        "" : ( this.props.application[this.props.who].previousoccupations.length === 0 ?
                            showWarning() :
                        this.props.application[this.props.who].previousoccupations.map(this.process)
                        )
                        }

                    </div>
                }
                {true || this.canShowDividends() ?
                    <div>
                        <div className="viewport mt-3 text-left">
                            <Form.Row >
                                <Col>
                                    <div className="mt-3 sectionquestion">Do you have income in the form of dividends/interest (tax form 1099-DIV or 1099-INT)?</div>
                                    <Form.Group>
                                        <fieldset>
                                            <div className="d-flex mt-1">

                                                <div className="mr-3 ">
                                                    <input required onChange={changeHasDividends(this.props.who, "hasdividends")} value="false" checked={false === this.props.application[this.props.who].income.hasdividends} type="radio" id="hasdividendsnot" name="hasdividends"></input>
                                                    <label className="zeitro-radio divlink" htmlFor="hasdividendsnot">No, I don't</label></div>
                                                <div className="mr-3 ">
                                                    <input required onChange={changeHasDividends(this.props.who, "hasdividends")} value="true" checked={true === this.props.application[this.props.who].income.hasdividends} type="radio" id="hasdividends" name="hasdividends"></input>
                                                    <label className="zeitro-radio divlink" htmlFor="hasdividends">Yes, I do</label></div>
                                            </div>

                                        </fieldset>
                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please specify if you have dividend income.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            {this.props.application[this.props.who].income.hasdividends ?
                                <Form.Row>
                                    <Col className="mr-3" xs="auto">
                                        <Form.Group controlId="firsttime" className="text-left" >
                                            <Form.Label>Dividends/Interest</Form.Label>
                                            <Form.Control as="select"
                                                //defaultValue={this.props.firsttime}
                                                type="text"
                                                size="sm"
                                                defaultValue={this.props.application[this.props.who].income.dividendsmonthly}
                                                onChange={this.updateMonthly(this.props.who, "dividendsmonthly")}
                                            >
                                                <option value="yearly">Yearly</option>
                                                <option value="monthly">Monthly</option>

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col xs="auto" style={{ width: '10.5em' }}>
                                        <Form.Group controlId="dividend" className="text-left" >
                                            <Form.Label className="text-left text-nowrap" >Amount:</Form.Label>

                                            <NumericalInput isValid={false} size="sm"
                                                required
                                                min={0}
                                                defaultValue={this.props.application[this.props.who].income.dividends}
                                                onChange={updateIncomeMonthly(this.props.who, "dividends")}

                                            />

                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide dividends.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Form.Row>
                                : ""}
                        </div>
                    </div> : ""
                }

                {true || this.canShowOtherIncome() ?
                    <div className="viewport mt-3 text-left">
                        <Form.Row>
                            <Col><div className="sectionquestion">{tooltip("Do you have other sources of income (excluding rental income)",
                                <div>You don't need to provide information about alimony or child support unless you want it considered in determining your qualification for the loan.</div>,
                                "auto", "", "")}</div>
                                <Form.Group>
                                    <fieldset>
                                        <div className="d-flex mt-1">

                                            <div className="mr-3 ">
                                                <input required onChange={this.addOtherSources} value={false} defaultChecked={this.props.application[this.props.who].income.otherincome !== null && this.props.application[this.props.who].income.otherincome.length === 0} type="radio" id="otherincomenot" name="otherincome"></input>
                                                <label className="zeitro-radio divlink" htmlFor="otherincomenot">No, I don't</label></div>
                                            <div>
                                                <input required onChange={this.addOtherSources} value={true} defaultChecked={this.props.application[this.props.who].income.otherincome !== null && this.props.application[this.props.who].income.otherincome.length !== 0} type="radio" id="otherincome" name="otherincome"></input>
                                                <label className="zeitro-radio divlink" htmlFor="otherincome">Yes, I do</label></div>
                                        </div>
                                    </fieldset>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please specify if you have other income.
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>
                        </Form.Row>

                        {this.props.application[this.props.who].income.otherincome === null ? "" : this.props.application[this.props.who].income.otherincome.map(this.processOtherIncome)}
                    </div> : ""}

            </div >

        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Income)

const mapStateToPropsDetails = (state) => {
    return {
        application: state.application
    }
}

const mapDispatchToPropsDetails = (dispatch) => ({


    setBorrowerHasAdditionalIncome: (value, who) => {
        dispatch(act.SetBorrowerHasAdditionalIncome(value, who))
    },
    updateIncomeMonthly: (event, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(event.target.value, who, verb))
    },
    addBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.AddBorrowerAdditionalIncome(t, who))
    },
    removeBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.RemoveBorrowerAdditionalIncome(t, who))
    },
    clearBorrowerAdditionalIncome: (who) => {
        dispatch(act.ClearBorrowerAdditionalIncome(who))
    },

    updateBorrowerAdditionalIncome: (event, who, verb, n) => {

        dispatch(act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n))
    },
    changeIncomeCheck: (checked, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(checked, who, verb))
    },
    changeCurrentOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(!event.target.checked, who, verb))
    },
    changeCurrentOccupation: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changePhoneInfo: (phone, who, verb) => {
        dispatch(act.ChangeOccupationInfo(phone, who, verb))
    },
    changeOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.checked, who, verb))
    },
    addAdditionaOccupation: (event, who) => {
        dispatch(act.AddAdditionaOccupation(event.target.value, who))
    },
    removeAdditionalOccupation: (event, who, i) => {
        dispatch(act.RemoveAdditionalOccupation(event.target.value, who, i))
    },
    clearAdditionalOccupations: (event, who) => {
        dispatch(act.ClearAdditionalOccupations(event.target.value, who))
    },
    changePastOccupationInfo: (event, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(event.target.value, who, verb, index))
    },
    changePastOccupationDate: (date, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(date, who, verb, index))
    },
    changePastOccupationPhone: (phone, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(phone, who, verb, index))
    },
    changePastOccupationCheck: (event, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(event.target.checked, who, verb, index))
    },


    addBorrowerOtherOccupation: (who) => {
        dispatch(act.AddBorrowerOtherOccupation(who))
    },
    removeBorrowerOtherOccupation: (who, index) => {
        dispatch(act.RemoveBorrowerOtherOccupation(who, index))
    },
    clearBorrowerOtherOccupations: (who) => {
        dispatch(act.ClearBorrowerOtherOccupations(who))
    },
    changeBorrowerOtherOccupationValue: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationValue(t, i, who, verb))
    },
    changeBorrowerOtherOccupationIncome: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationIncome(t, i, who, verb))
    },
    changeMainProperty: (t, verb) => {
        dispatch(act.ChangeMainProperty(t, verb))
    },

});

export class IncomeDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validated: false,
            showSelf: false,
            isbasevalid: false,
            iscomissionvalid: false,
            isovertimevalid: false,
            isbonusesvalid: false,
            isdividendsvalid: false,
        }
        this.refsfrom = []
        this.refsto = []
        if (this.props.application[this.props.who].previousoccupations !== null) {
            for (let i = 0; i < this.props.application[this.props.who].previousoccupations.length; i++) {
                this.refsfrom.push(React.createRef())
                this.refsto.push(React.createRef())
            }
        }

        this.crefsfrom = []
        if (this.props.application[this.props.who].otheroccupations !== null) {
            for (let i = 0; i < this.props.application[this.props.who].otheroccupations.length; i++) {
                this.crefsfrom.push(React.createRef())

            }
        }

        this.isValidated = this.isValidated.bind(this);
    }
    componentDidMount() {
        if (this.props.application.property.expenses === null) {
            this.loadExpenses()
        }
    }
    loadExpenses = () => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/data/getexpences', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-Borrower": this.props.borrowerid,
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    alert("Bad response, should not happen")
                    return;
                }
                response.json().then(fees => {
                    let ex = new FutureExpenses();
                    ex.otherfinancing = fees.otherfinancing
                    ex.homeownersinsurance = fees.hazardinsurance
                    ex.realestatetaxes = fees.realestatetaxes
                    ex.hoa = fees.dues
                    ex.floodinsurance = fees.other
                    this.props.changeMainProperty(ex, "expenses")
                })
            }

        ).catch((err) => {

            this.setState({ loading: false })
            alert("Network error")
        });
    }
    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }

    updateMonthly = (who, verb) => {
        return e => {
            com.touch()
            this.props.updateIncomeMonthly(e, who, verb)
        }
    }

    onBaseAmountValid = (val) => {
        this.setState({ isbasevalid: val })
    }
    onComissionsAmountValid = (val) => {
        this.setState({ iscomissionvalid: val })
    }
    onOvertimeAmountValid = (val) => {
        this.setState({ isovertimevalid: val })
    }
    onBonusesAmountValid = (val) => {
        this.setState({ isbonusesvalid: val })
    }
    onDividendsValid = (val) => {
        this.setState({ isdividendsvalid: val })

    }
    canShowDividends = () => {
        if (this.props.application[this.props.who].occupation.hasoccupation === null)
            return false

        if (this.props.application[this.props.who].occupation.hasoccupation !== "employed")
            return true

        if (this.state.isbasevalid && this.props.application[this.props.who].income.hasbonuses === false)
            return true

        if (this.state.isbasevalid && this.props.application[this.props.who].income.hasbonuses === true &&
            this.state.iscomissionvalid && this.state.isovertimevalid && this.state.isbonusesvalid
        )
            return true

        return false
    }

    renderOccupation = (index, key, employment, dontShowIndex, changeOccupationInfo, onOwner,
        addCurrentOccupation, removeCurrentOccupation,
        updateMonthly, changeCurrentOccupationCheck, changeCurentOccupationPhone, changeCurrentOccupationDate) => {
        let changeEmployedByRelative = (who, verb) => {
            return e => {
                if (index == null) {
                    this.props.changeOccupationCheck(e, who, verb)
                } else {
                    this.props.changeBorrowerOtherOccupationValue(e.target.checked, index, who, verb)
                }
            }
        }
        let IncomeType = (commissions) => {
            return (

                <Col xs="auto" className="m-0 p-0">
                    <Row>
                        <Col style={{ width: '7em' }} className="mr-3 p-0 " xs="auto">

                            <Form.Group controlId={commissions + index} className="text-left" >
                                <Form.Label className="capitalize">{commissions}:</Form.Label>
                                <Form.Control as="select"
                                    type="text"
                                    size="sm"
                                    defaultValue={employment.income[commissions + "monthly"]}
                                    onChange={updateMonthly(this.props.who, commissions + "monthly")}
                                >
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                    {commissions === "base" ? <option value="hourly">Hourly</option> : ""}

                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs="auto" style={{ width: '12.5em' }} className="mr-2  px-0">
                            <Form.Group controlId={"amount" + commissions + index} className="text-left" >
                                <Form.Label className="text-left text-nowrap" >Amount:</Form.Label>

                                <NumericalInput isValid={false}
                                    required
                                    size="sm"
                                    min={0}

                                    defaultValue={employment.income[commissions]}
                                    onChange={updateMonthly(this.props.who, commissions)}
                                />

                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide {commissions}.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            {
                                (employment.income[commissions + "monthly"] === "hourly") ?
                                    <Form.Group controlId="basehoursperweek" className="text-left" >
                                        <Form.Label className="text-left text-nowrap" >Hours Per Week:</Form.Label>

                                        <Form.Control isValid={false} size="sm"
                                            required
                                            min={1}
                                            max={80}
                                            step={1}
                                            defaultValue={employment.income[commissions + "hoursperweek"]}
                                            onChange={updateMonthly(this.props.who, commissions + "hoursperweek")}
                                        />

                                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide overtime income.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    : ""
                            }
                        </Col>
                    </Row>
                </Col>)
        }
        let changeOwnership = e => {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                changeOccupationInfo(this.props.who, "percentownership")({ target: { value: e.target.value } })
            }, 200)
        }
        let ondocs = е => {

            changeOccupationInfo(this.props.who, "selfincomedoc")(е)
        }
        return (
            <div className="incard m-0 mb-3 px-0" key={"cjob_" + key}>
                <Row>
                    <Col>
                        <Form.Row >
                            <Col>
                                <Row>
                                    <Col className="ml-0 pl-0">
                                        <Form.Group controlId={"CEmployer1" + index} className="text-left" >
                                            <Form.Label className="text-left text-nowrap" >Employer:</Form.Label>
                                            <Form.Control isValid={false} size="sm"
                                                required
                                                name={"cemployer" + index}
                                                type="text"
                                                defaultValue={employment.employername}
                                                onChange={changeOccupationInfo(this.props.who, "employername")}


                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Provide employer.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    {/* <Col xs="auto"  >
                                        <Form.Group controlId={"cfrom" + index} className="text-left" >
                                            <Form.Label className="text-left text-nowrap" >Starting Date:</Form.Label>
                                            <DateOfBirth
                                                size="sm"
                                                isValid={false}
                                                //ref={this.crefsfrom[index]}
                                                onRef={ref => { this.crefsfrom[index] = ref }}
                                                //reff={this.crefsfrom[index]}                                
                                                required
                                                placeholder="MM/DD/YYYY"
                                                name={"ocfrom" + index}
                                                type="text"
                                                value={employment.from}
                                                onChange={changeCurrentOccupationDate(this.props.who, "from")}
                                                negativefeedback="Provide valid start"
                                                pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                            />
                                        </Form.Group>
                                    </Col> */}
                                </Row> </Col>

                        </Form.Row >
                        {/* <Form.Row>
                            <Col  >
                                <Row><Col className="ml-0 pl-0">
                                    <AddressAutocomplete id={"CEmployerAddr1" + index} label="Address:"
                                        required={true} name={"cemployeraddr" + index}

                                        defaultValue={employment.employeraddress}
                                        onChange={changeOccupationInfo(this.props.who, "employeraddress")}
                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                        placeholder="Street Address, No P.O.Box accepted"

                                        goodfeedback="Looks good!"
                                        badfeedback="Please provide address."
                                    />
                                </Col>
                                    <Col xs="auto">
                                        <Form.Group controlId={"cjobphone" + index} className="text-left" >
                                            <Form.Label className="text-left text-nowrap" >{tooltip("HR phone number",
                                                <div>Specify the HR/Personnel or hiring manager phone number to verify employment.
                                                </div>, 'bottom', ''
                                            )}

                                            </Form.Label>
                                            <Form.Control size="sm" isValid={false}
                                                required
                                                name={"cjobphone" + index}
                                                type="text"
                                                placeholder="(###)-###-####"
                                                pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                                                value={employment.phone}
                                                onChange={changeCurentOccupationPhone(this.props.who, "phone")}

                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Provide phone number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row></Col>

                            {index !== null ?
                                <Col xs="auto" style={{ width: '36px' }}></Col> : ""}
                        </Form.Row> */}
                        <Form.Row >
                            <Col>
                                <Row>
                                    <Col className="ml-0 pl-0">
                                        <Autocomplete
                                            required
                                            id={"cOtherTitle" + index}
                                            suggestions={titles}
                                            name="position"
                                            type="text"
                                            label="Position/title"
                                            valid="Looks good!"
                                            invalid="Please enter position"
                                            value={employment.positiontitle}
                                            onChange={changeOccupationInfo(this.props.who, "positiontitle")}
                                        />
                                    </Col>
                                    <Col xs="auto" >
                                        <Form.Group controlId="yearsatthiswork0" className="text-left" >
                                            <Form.Label className="text-left text-nowrap" >{tooltip("Years in this type of work",
                                                <div>Specify the number of years you have been in the profession.<br />
                                                    This is important for people who have been doing brief engagements with multiple companies while doing the same work professionally.
                                                </div>, 'bottom', ''
                                            )}

                                            </Form.Label>
                                            <Form.Control isValid={false} size="sm"
                                                required
                                                ref={this.yearsatlineofwork}
                                                name="yearsatthiswork0"
                                                type="number"
                                                min={0}
                                                defaultValue={employment.yearsatlineofwork}
                                                onChange={changeOccupationInfo(this.props.who, "yearsatlineofwork")}

                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please enter years at line of work.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs="auto" >
                                        <Form.Group controlId="moatthiswork0" className="text-left" >
                                            <Form.Label className="text-left text-nowrap" >Months:
                                            </Form.Label>
                                            <Form.Control isValid={false} size="sm"
                                                required
                                                ref={this.yearsatlineofwork}
                                                name="monthsatthiswork0"
                                                type="number"
                                                min={0}
                                                max={11}
                                                defaultValue={employment.monthsatlineofwork}
                                                onChange={changeOccupationInfo(this.props.who, "monthsatlineofwork")}
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please enter months at line of work.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            {index !== null ?
                                <Col xs="auto" style={{ width: '36px' }}></Col> : ""}
                        </Form.Row>

                        {false === employment.selfemployed ?
                            <div >
                                <Row >
                                    <Col className="m-0 p-0" style={{}}>
                                        <Row>
                                            {IncomeType("base")}
                                            {IncomeType("commissions")}
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col className="m-0 p-0">
                                        <Row>
                                            {IncomeType("overtime")}
                                            {IncomeType("bonuses")}
                                        </Row>
                                    </Col>
                                </Row>

                            </div> : ""}
                        <Form.Row className="">
                            <Col as="div" className="align-middle mx-1  text-left"  >
                                <div className="mt-2 w-100 align-middle  text-wrap sectionquestion">Are you self-employed or owner of this business?</div>

                                <Form.Group>
                                    <fieldset>
                                        <div className="d-flex mt-2">
                                            <div className="mr-3 ">
                                                <input required onChange={onOwner} value="false"
                                                    checked={false === employment.selfemployed}
                                                    type="radio" id={"selfemployednot" + index} name={"selfemployed" + index}></input>
                                                <label className="divlink" htmlFor={"selfemployednot" + index}>No, I am not</label></div>
                                            <div className="mr-3 ">
                                                <input required onChange={onOwner} value="true"
                                                    checked={true === employment.selfemployed}
                                                    type="radio" id={"selfemployed" + index} name={"selfemployed" + index}></input>
                                                <label className="zeitro-radio  divlink" htmlFor={"selfemployed" + index}>Yes, I am</label></div>
                                        </div>
                                    </fieldset>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please specify if you are self-employed
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row >
                        {true === employment.selfemployed ?
                            <>
                                <Form.Row>
                                    <Col xs="auto" className="mr-3 ml-2">
                                        <Form.Group controlId="share" className="text-left" >
                                            <Form.Label className="text-left text-nowrap" > Ownership share:
                                            </Form.Label>
                                            <InputGroup size="sm" >
                                                <Form.Control isValid={false} size="sm"
                                                    required
                                                    name="share"

                                                    type="text"
                                                    pattern="^[0-9.]+$"
                                                    defaultValue={employment.percentownership}
                                                    onChange={changeOwnership}

                                                />
                                                <InputGroup.Append>
                                                    <InputGroup.Text className="pl-3" style={{ fontSize: '0.9em', lineHeight: '1.3' }} size="sm">%</InputGroup.Text>

                                                </InputGroup.Append>
                                            </InputGroup>
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide phone number or N/A.1
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col className="mr-3 ml-2" xs="auto">
                                        <Form.Group controlId="firsttime" className="text-left" >
                                            <Form.Label>Income or Loss:</Form.Label>
                                            <Form.Control as="select"
                                                //defaultValue={this.props.firsttime}
                                                type="text"
                                                size="sm"
                                                defaultValue={employment.incomeorlossperiod}
                                                onChange={changeOccupationInfo(this.props.who, "incomeorlossperiod")}
                                            >
                                                <option value="monthly">Monthly</option>
                                                <option value="yearly">Yearly</option>

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col xs="auto" className="ml-2" style={{ width: '10.5em' }}>
                                        <Form.Group controlId="dividend" className="text-left" >
                                            <Form.Label className="text-left text-nowrap" >Amount:</Form.Label>

                                            <NumericalInput isValid={false} size="sm"
                                                required
                                                min={0}
                                                defaultValue={employment.incomeorloss}
                                                onChange={changeOccupationInfo(this.props.who, "incomeorloss")}

                                            />

                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide amount.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col>
                                    </Col>
                                </Form.Row >
                                {isNaN(parseInt(employment.percentownership)) ? "" :
                                    parseInt(employment.percentownership) >= 25 ?
                                        <fieldset>
                                            <Form.Row>
                                                <Col className="ml-2 mt-2 w-100 align-middle  text-wrap sectionquestion">
                                                    How do you file your taxes?
                                                </Col>
                                            </Form.Row>

                                            <Form.Row className="mt-2 ml-1 ">
                                                <Col className="mr-2" xs="auto">
                                                    <input type="radio" required checked={employment.selfincomedoc === "schedulec"} onChange={ondocs} value="schedulec"
                                                        name={"taxes" + key} id={"schedulec" + key} />
                                                    <label id="scheduleclabel" className="zeitro-radio divlink" htmlFor={"schedulec" + key}>Schedule C</label>
                                                </Col>
                                                <Col className="mr-2" xs="auto">
                                                    <input type="radio" required checked={employment.selfincomedoc === "schedulef"} onChange={ondocs} value="schedulef"
                                                        name={"taxes" + key} id={"schedulef" + key} />
                                                    <label id="scheduleflabel" className="zeitro-radio divlink" htmlFor={"schedulef" + key}>Schedule F</label>
                                                </Col>
                                                <Col className="mr-2" xs="auto">
                                                    <input type="radio" required checked={employment.selfincomedoc === "f1065"} onChange={ondocs} value="f1065"
                                                        name={"taxes" + key} id={"f1065" + key} />
                                                    <label id="f1065label" className="zeitro-radio divlink" htmlFor={"f1065" + key}>1065 Partnership Return</label>
                                                </Col>
                                                <Col className="mr-2" xs="auto">
                                                    <input type="radio" required checked={employment.selfincomedoc === "f1120S"} onChange={ondocs} value="f1120S"
                                                        name={"taxes" + key} id={"f1120S" + key} />
                                                    <label id="f1120Slabel" className="zeitro-radio divlink" htmlFor={"f1120S" + key}>1120S for S Corp</label>
                                                </Col>
                                                <Col className="mr-2" xs="auto">
                                                    <input type="radio" required checked={employment.selfincomedoc === "f1120"} onChange={ondocs} value="f1120"
                                                        name={"taxes" + key} id={"f1120" + key} />
                                                    <label id="f1120label" className="zeitro-radio divlink" htmlFor={"f1120" + key}>1120 for C Corp</label>
                                                </Col>
                                                <Col className=""></Col>
                                            </Form.Row>

                                        </fieldset>
                                        :
                                        <fieldset>
                                            <Form.Row>
                                                <Col className="ml-2 mt-2 w-100 align-middle  text-wrap sectionquestion">
                                                    How do you document your income?
                                                </Col>
                                            </Form.Row>

                                            <Form.Row className="mt-2 ml-1 ">
                                                <Col className="mr-2" xs="auto">
                                                    <input type="radio" required checked={employment.selfincomedoc === "w2paystub"} onChange={ondocs} value="w2paystub"
                                                        name={"taxes" + key} id={"w2paystub" + key} />
                                                    <label id="w2paystublabel" className="zeitro-radio divlink" htmlFor={"w2paystub" + key}>W2/Paystubs</label>
                                                </Col>
                                                <Col className="mr-2" xs="auto">
                                                    <input type="radio" required checked={employment.selfincomedoc === "schedulek1"} onChange={ondocs} value="schedulek1"
                                                        name={"taxes" + key} id={"schedulek1" + key} />
                                                    <label id="schedulek1label" className="zeitro-radio divlink" htmlFor={"schedulek1" + key}>Schedule K1</label>
                                                </Col>
                                                <Col className=""></Col>
                                            </Form.Row>
                                        </fieldset>
                                }
                                <Form.Row>
                                    <Col className="font-italic mt-2 text-wrap" style={{ fontWeight: '600' }}>
                                        Please be aware that you need 2 years of history of self employed income for it to be considered for conforming loans
                                    </Col>
                                </Form.Row>
                            </>
                            : ""}
                        <Form.Row>
                            <Col className="ml-2 px-1">
                                <Form.Group controlId={"employedbyagent" + index} className="text-left d-block text-nowrap " >
                                    <Form.Check type="checkbox" className="mx-3 px-0 text-wrap"
                                        checked={employment.employedbyagent}


                                        key={Math.random()}

                                        onChange={changeEmployedByRelative(this.props.who, "employedbyagent")}
                                        label="Check if employed by a family member,
                                    property seller, real estate agent, or other
                                    party to the transaction"></Form.Check>
                                </Form.Group>

                            </Col>
                        </Form.Row>

                    </Col>
                    {index !== null ?
                        <Col xs="auto" as="div" className="text-right aligh-top pl-0 pr-2">
                            <i hidden={dontShowIndex()} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addCurrentOccupation} ></i>
                            <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeCurrentOccupation} ></i>
                        </Col> : ""}
                </Row>
            </div>
        )
    }
    canShowOtherIncome = () => {
        if (!this.canShowDividends())
            return false
        if (null === this.props.application[this.props.who].income.hasdividends)
            return false
        if (false === this.props.application[this.props.who].income.hasdividends)
            return true

        if (false === this.props.application[this.props.who].income.hasdividends)
            return true

        if (true === this.props.application[this.props.who].income.hasdividends &&
            this.state.isdividendsvalid)
            return true

        return false
    }

    displayOtherOccupation = (o, index) => {
        let key = o.key
        index = parseInt(index);


        let changeCurrentOccupationDate = (who, verb) => {
            return (event) => {
                com.touch()
                let fixed = com.fixDateInput(event.target.value)
                let from = this.crefsfrom[index].current.value

                let expr = /^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$/ig

                if (from && from.match(expr)) {
                    let f = new Date(from)
                    this.crefsfrom[index].current.setCustomValidity("")

                    let old = new Date("01/01/1900")
                    let now = new Date()

                    if (f < old) {
                        this.refsfrom[index].current.focus()
                        this.refsfrom[index].current.setCustomValidity("Too old to be true")
                    }
                    if (f >= now) {
                        this.refsto[index].current.focus()
                        this.refsfrom[index].current.setCustomValidity("Can't be in the future")
                    }
                }
                return this.props.changeBorrowerOtherOccupationValue(fixed, index, who, verb)
            }
        }

        let changeCurentOccupationPhone = (who, verb) => {
            return (event) => {
                com.touch()
                let phone = com.fixPhoneInput(event.target.value)
                return this.props.changeBorrowerOtherOccupationValue(phone, index, who, verb)
            }
        }
        let changeCurrentOccupationCheck = (who, verb) => {
            return event => {
                com.touch()
                this.props.changeBorrowerOtherOccupationValue(event.target.checked, index, who, verb)
            }
        }

        let addCurrentOccupation = () => {
            let reffrom = React.createRef();
            this.crefsfrom.push(reffrom)
            return this.props.addBorrowerOtherOccupation(this.props.who)
        }
        let removeCurrentOccupation = (who) => {
            this.crefsfrom.splice(index)
            return this.props.removeBorrowerOtherOccupation(this.props.who, index)
        }
        let onOwner = e => {
            com.touch()
            let val = e.target.value === "true"
            this.props.changeBorrowerOtherOccupationValue(val, index, this.props.who, "selfemployed")

        }

        let updateMonthly = (who, verb) => {
            return e => {
                com.touch()
                this.props.changeBorrowerOtherOccupationIncome(e.target.value, index, who, verb)
            }
        }
        let changeOccupationInfo = (who, verb) => {
            return e => {
                com.touch()
                this.props.changeBorrowerOtherOccupationValue(e.target.value, index, who, verb)

            }
        }
        let dontShowIndex = () => {
            return index !== this.props.application[this.props.who].otheroccupations.length - 1
        }
        let employment = this.props.application[this.props.who].otheroccupations[index]

        return this.renderOccupation(index, key, employment, dontShowIndex, changeOccupationInfo, onOwner, addCurrentOccupation, removeCurrentOccupation,
            updateMonthly, changeCurrentOccupationCheck, changeCurentOccupationPhone, changeCurrentOccupationDate)
    }

    render = () => {
        let updateIncomeMonthly = (who, verb) => {
            return (event) => {
                com.touch()
                return this.props.updateIncomeMonthly(event, who, verb)
            }
        }
        let changeCurrentOccupationCheck = (who, verb) => {

            return (event) => {
                com.touch()
                switch (event.target.value) {
                    case "employed":
                        this.props.changeCurrentOccupation(event, who, verb)
                        this.props.changeCurrentOccupationCheck({ target: { checked: true } }, who, "selfemployed")
                        break
                    case "selfemployed":
                        this.props.changeCurrentOccupation(event, who, verb)
                        this.props.changeCurrentOccupationCheck({ target: { checked: false } }, who, "selfemployed")
                        this.setState({ showSelf: true })
                        break
                    case "unemployed":
                    case "retired":
                        this.props.changeCurrentOccupation(event, who, verb)
                        break
                    default:
                        break
                }

            }
        }

        let changeOccupationInfo = (who, verb) => {

            return (event) => {
                com.touch()
                this.props.changeOccupationInfo(event, who, verb)
            }
        }


        let changeOccupationDate = (who, verb) => {
            return (event) => {
                com.touch()
                let fixed = com.fixDateInput(event.target.value)
                this.props.changeOccupationInfo({ target: { value: fixed } }, who, verb)
            }
        }


        let changePhoneInfo = (who, verb) => {
            return (event) => {
                com.touch()
                let phone = com.fixPhoneInput(event.target.value)
                return this.props.changePhoneInfo(phone, who, verb)
            }
        }

        let clearAdditionalCurrentOccupations = e => {
            com.touch()
            this.props.clearBorrowerOtherOccupations(this.props.who)
        }
        let addAdditionaCurrentOccupation = e => {
            com.touch()
            this.props.addBorrowerOtherOccupation(this.props.who)
        }

        let onOwner = e => {
            let check = e.target.value === "true"
            com.touch()
            this.props.changeOccupationCheck({ target: { checked: check } }, this.props.who, "selfemployed")
        }
        let index = null
        let key = null
        let employment = this.props.application[this.props.who].occupation

        return (
            <div className=" text-left px-0 mx-0">
                <h2 className="heading d-flex">
                    <Row>
                        <Col>Tell us about {this.props.application[this.props.who].firstname} {this.props.application[this.props.who].lastname}'s income</Col>
                        <Col className="hideonphone" xs="auto" style={{ marginTop: "4px", fontSize: "0.7em", fontWeight: "normal", position: "absolute", right: "4em" }}> Monthly income: ${(com.commaizeFloat(calculateIncome(this.props.application).toFixed(2)))}</Col>
                    </Row>
                </h2>
                <div className="text-left mt-3 mb-3">


                    <div className="viewport">

                        <Form.Row>
                            <Col><div className="sectionquestion d-flex"> {tooltip("Are you currently employed or/and self-employed?", "If you have multiple jobs, enter your cumulative income. You'll be able to list those jobs later. Check Yes if you are self-employed")} </div>
                                <Form.Group>
                                    <fieldset>
                                        <Row className="mt-1 text-wrap">

                                            <Col md="auto" className="mr-3 ">
                                                <input required onChange={changeCurrentOccupationCheck(this.props.who, "hasoccupation")} value="unemployed" checked={this.props.application[this.props.who].occupation.hasoccupation === "unemployed"} type="radio" id="unemployed" name="employed"></input>
                                                <label className="zeitro-radio divlink" htmlFor="unemployed">No, I am unemployed</label></Col>
                                            <Col md="auto" className="mr-3 ">
                                                <input required onChange={changeCurrentOccupationCheck(this.props.who, "hasoccupation")} value="retired" checked={this.props.application[this.props.who].occupation.hasoccupation === "retired"} type="radio" id="retired" name="employed"></input>
                                                <label className="zeitro-radio divlink" htmlFor="retired">No, I am retired</label></Col>

                                            <Col md="auto" className="mr-3 ">
                                                <input required onChange={changeCurrentOccupationCheck(this.props.who, "hasoccupation")} value="employed" checked={this.props.application[this.props.who].occupation.hasoccupation === "employed"} type="radio" id="employed" name="employed"></input>
                                                <label className="zeitro-radio divlink" htmlFor="employed">Yes, I am employed or/and self-employed</label></Col>
                                            <Col></Col>
                                        </Row>

                                    </fieldset>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please specify if you are employed.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        {this.props.application[this.props.who].occupation.hasoccupation === "employed" ? this.renderOccupation(index, key, employment, () => true, changeOccupationInfo, onOwner,
                            () => true, () => true,
                            updateIncomeMonthly, changeCurrentOccupationCheck, changePhoneInfo, changeOccupationDate) : ""}

                    </div>
                    {this.props.application[this.props.who].occupation.hasoccupation === "employed" ?
                        <div className="viewport mt-3 text-left">
                            <div className="sectionquestion mt-3">
                                Do you currently have other jobs? </div>

                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mt-3 mb-0">
                                        <div className="mr-3 mt-0">
                                            <input required onChange={clearAdditionalCurrentOccupations} value="false"
                                                checked={this.props.application[this.props.who].otheroccupations !== null && this.props.application[this.props.who].otheroccupations.length === 0}
                                                type="radio" id="hasadditionalcurrentnot" name="hasadditionalcurrent"></input>
                                            <label className="zeitro-radio divlink" htmlFor="hasadditionalcurrentnot">No, I don't</label></div>
                                        <div className="mr-3  mt-0">
                                            <input required onChange={addAdditionaCurrentOccupation} value="true"
                                                checked={this.props.application[this.props.who].otheroccupations !== null && this.props.application[this.props.who].otheroccupations.length > 0}
                                                type="radio" id="hasadditionalcurrent" name="hasadditionalcurrent"></input>
                                            <label className="zeitro-radio divlink" htmlFor="hasadditionalcurrent">Yes, I do</label></div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify if you have other current occupations
                                </Form.Control.Feedback>
                            </Form.Group>


                            {this.props.application[this.props.who].otheroccupations === null ? "" :
                                this.props.application[this.props.who].otheroccupations.map(this.displayOtherOccupation)
                            }

                        </div> : ""}
                    <div>


                    </div>


                </div>


            </div >
        )
    }
};

IncomeDetails = connect(mapStateToPropsDetails, mapDispatchToPropsDetails, null, { forwardRef: true })(IncomeDetails)


