import React, { Component } from "react";
import "../../App.css";
import BootstrapTable from "react-bootstrap-table-next";
import SelectLoanOfficers from "./SelectLoanOfficers";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import Autocomplete from "../../Autocomplete";
import { Button } from "react-bootstrap";

const mapStateToProps = (state) => {
  let s = {
    application: state.application,
    borrower: state.borrower,
  };

  return s;
};

const mapDispatchToProps = (dispatch) => ({});

export class ReferralStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      application: this.props.application,
      referrals: [],
      allDeclined: false,
      hasDecline: false,
      referralLoanID: this.props.borrower.loan_id,
      showSentMultipleReferralsModal: false,
      showSpinner: false,
    };

    this.getReferralStatus();
  }

  getReferralStatus = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getreferralstatus", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Loan": this.props.borrower.loan_id,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Please contact Zeitro for help."
          );
          return;
        }
        response.json().then((js) => {
          if (js) {
            console.log(js);
            let declinedCount = 0;
            for (let r of js) {
              if (r.Status === "declined") {
                declinedCount++;
                this.setState({hasDecline: true})
              }
            }
            if (declinedCount > 0 && declinedCount === js.length) {
              this.setState({ allDeclined: true });
            }
            this.setState({
              referrals: js,
            });
          }
        });
      })
      .catch((err) => {
        console.log("Fetch Error :", err);
      });
  };

  getSentMultipleReferralsModal = () => {
    return (
      <SelectLoanOfficers
        loanid={this.props.borrower.loan_id}
        show={this.state.showSentMultipleReferralsModal}
        hide={() => {
          this.setState({ showSentMultipleReferralsModal: false });
        }}
      />
    );
  };

  getReferralsTable = () => {
    const columns = [
      {
        dataField: "Status",
        text: "Status",
        sort: true,
      },
      {
        dataField: "LoEmail",
        text: "Email",
      },
      {
        dataField: "Name",
        text: "Name",
        formatter: (_, row) => <div>{row.LoFirstName + " " + row.LoLastName}</div>,
      },
    ];
    if (this.state.hasDecline) {
      columns.push({
        dataField: "DecliningReason",
        text: "Declining Reason",
      })
    }

    return (
      <BootstrapTable
        bordered={false}
        striped
        hover
        condensed
        keyField="LoEmail"
        data={this.state.referrals}
        columns={columns}
        rowStyle={{backgroundColor: "transparent"}}
      />
    );
  };

  getHandOverButton = () => {
    return (
      <div>
        <p>
          <Button
            onClick={() =>
              this.setState({ showSentMultipleReferralsModal: true })
            }
          >
            {this.state.allDeclined ? "Re-select Loan Officers" : "Hand over to loan officers"}
          </Button>
        </p>
        {this.state.showSentMultipleReferralsModal &&
          this.getSentMultipleReferralsModal()}
      </div>
    );
  }

  render() {
    if (this.state.referrals.length === 0) {
      return this.getHandOverButton()
    }
    return (
      <div>
        <Row>
          {this.state.allDeclined ? <h6>This loan has been declined by all loan officers, please re-select loan officers:</h6> : <h5>This loan has been handed over to:</h5>}
          {this.getReferralsTable()}
          {this.state.allDeclined && this.getHandOverButton()}
        </Row>
      </div>
    );
  }
}

ReferralStatus = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ReferralStatus);
