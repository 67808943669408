import React from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';

class FilterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleFilter: '',
      deadlineBefore: false,
      deadlineAfter: false,
      month: '01',
      day: '01',
      year: new Date().getFullYear(),
      status: 'open'
    }
    this.userDocuments = {}
  }

  componentDidMount = () => {
    let token = sessionStorage.getItem("ZeitroA")
    let body = { ID: this.props.borrowerId, LoanID: this.props.loanId, WhoseDocuments: 'customer' }
    fetch('/agent/documents', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
          } else {
            for (let i = 0; i < js.Docs.length; i++) {
              this.userDocuments[js.Docs[i].FileName] = false
            }
          }
          this.forceUpdate()
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });

  }

  handleTitleChange = (e) => {
    this.setState({ titleFilter: e.target.value })
  }

  handleDeadlineBeforeAfter = (e) => {
    if (e.target.id === 'after') {
      if (this.state.deadlineAfter && !this.state.deadlineBefore) {
        this.setState({ deadlineAfter: false, deadlineBefore: false })
      } else {
        this.setState({ deadlineAfter: true, deadlineBefore: false })
      }
    } else if (e.target.id === 'before') {
      if (!this.state.deadlineAfter && this.state.deadlineBefore) {
        this.setState({ deadlineAfter: false, deadlineBefore: false })
      } else {
        this.setState({ deadlineAfter: false, deadlineBefore: true })
      }
    }
  }

  handleMonthChange = (e) => {
    this.setState({ month: e.target.value })
  }

  handleDayChange = (e) => {
    this.setState({ day: e.target.value })
  }

  handleYearChange = (e) => {
    this.setState({ year: e.target.value })
  }

  handleAttachmentsChange = (e) => {
    this.userDocuments[e.target.id] = e.target.checked
  }

  handleInternalSumbit = (e) => {
    let filters = this.props.filterConfiguration
    filters.title = this.state.titleFilter
    filters.deadlineBefore = this.state.deadlineBefore
    filters.deadlineAfter = this.state.deadlineAfter
    if (this.state.deadlineAfter || this.state.deadlineBefore) {
      filters.deadline = `${this.state.month}-${this.state.day}-${this.state.year}`
    } else {
      filters.deadline = ''
    }
    filters.listOfDocuments = []
    for (const [key, value] of Object.entries(this.userDocuments)) {
      if (value) {
        filters.listOfDocuments.push(key)
      }
    }
    this.props.onSubmit()

  }

  render() {
    return (
      <Modal
        onHide={this.props.onHide}
        show={this.props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Tasks filter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="titleFilter">
              <Form.Label>Task's title contains</Form.Label>
              <Form.Control type="text" placeholder="Check credit report" onChange={this.handleTitleChange} defaultValue={this.state.titleFilter} />
              <Form.Text className="text-muted">
                Enter something you remember from the title.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="deadlineFilter">
              <Form.Label>Task's deadline</Form.Label>
              <div>
                <Form.Check inline label="Before this date" type='checkbox' id='before' checked={this.state.deadlineBefore} onChange={this.handleDeadlineBeforeAfter} />
                <Form.Check inline label="After this date" type='checkbox' id='after' checked={this.state.deadlineAfter} onChange={this.handleDeadlineBeforeAfter} />
              </div>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="filterDeadlineMonth">
                <Form.Label>Choose month</Form.Label>
                <Form.Control disabled={!(this.state.deadlineBefore || this.state.deadlineAfter)} defaultValue={this.state.month} as="select" onChange={this.handleMonthChange}>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="filterDeadlineDay">
                <Form.Label>Choose day</Form.Label>
                <Form.Control disabled={!(this.state.deadlineBefore || this.state.deadlineAfter)} defaultValue={this.state.day} as="select" onChange={this.handleDayChange}>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                  <option>22</option>
                  <option>23</option>
                  <option>24</option>
                  <option>25</option>
                  <option>26</option>
                  <option>27</option>
                  <option>28</option>
                  <option>29</option>
                  <option>30</option>
                  <option>31</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="filterDeadlineYear">
                <Form.Label>Choose year</Form.Label>
                <Form.Control disabled={!(this.state.deadlineBefore || this.state.deadlineAfter)} defaultValue={this.state.year} as='select' onChange={this.handleYearChange}>
                  <option>{new Date().getFullYear() - 1}</option>
                  <option>{new Date().getFullYear()}</option>
                  <option>{new Date().getFullYear() + 1}</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
            {
              Object.entries(this.userDocuments).length !== 0 &&
              <Form.Group controlId="filterAttachments">
                <Form.Label>
                  Task is associated with all of chosen documents
                </Form.Label>
                {Object.entries(this.userDocuments).map((attachment) =>
                  <Form.Check
                    key={`${attachment[0]}`}
                    type={'checkbox'}
                    id={`${attachment[0]}`}
                    label={attachment[0]}
                    defaultChecked={attachment[1]}
                    onChange={this.handleAttachmentsChange}
                  />
                )}
              </Form.Group>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type='submit' onClick={this.handleInternalSumbit}>Submit</Button>
          <Button variant="danger" onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default FilterModal;