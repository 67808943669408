import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './EmailVerification.css';
import './ApplicationInterview.css';

import { Modal } from 'antd';
import Row from 'react-bootstrap/Col'
import { State } from '../State'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Button } from "react-bootstrap";
import CodeVerification from "../Common/components/CodeVerification";
import CheckGroup from "../Common/components/CheckGroup";
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as prgr from "../Store/progress"
import * as st from "../State"
import * as com from "../Common"
import { getCurrentState, loadState, updateState, mergeState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import Spinner from 'react-bootstrap/Spinner'

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
});

class EmailVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code : "",
            verified: false,
            showResult: false,
            verifingCode: false,
            sendingCode: false,
            loading: false,
        }
    }

    sendcode = () => {
        this.setState({sendingCode: true})
        fetch("/agent/generateonetimepasscode", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.email,
                customerID: "individualcustomers",
            }),            
            headers: {
                'Content-Type': 'application/json',
                Cache: "no-cache"                      
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                this.setState({sendingCode: false})
                return;
            }
            this.setState({sendingCode: false})
            
        }).catch(error => {
            console.log(error)
            this.setState({sendingCode: false})
        })
    }

    editEmail = (type) => {
        this.props.back(type)
    }

    verifycode = (code) => {
        this.setState({verifingCode: true})
        let url = "/agent/verifycodeandsignup"
        if (this.props.fromSignIn) {
            url = "/agent/verifycodeandsignin"
        }
        fetch(url, {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.props.email,
                firstName: this.props.firstname,
                lastName: this.props.lastname,
                code: code,
                customerID: "individualcustomers",
            }),            
            headers: {
                Cache: "no-cache"                      
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                this.setState({showResult: true, verified: false, verifingCode: false, code:""})
                return;
            }
            response.json().then(js => {

                if (js.Status !== "OK") {
                    console.log(js)
                    this.setState({showResult: true, verified: false, verifingCode: false, code:""})
                } else {
                    sessionStorage.setItem("ZeitroA", js.Token)
                    sessionStorage.removeItem("state")
                    sessionStorage.removeItem("originalstate")
                    let path = "/app/welcome"
                    this.setState({showResult: true, verified: true, verifingCode: false, code:""})
                    window.location.href = path
                }
            });
            
        }).catch(error => {
            console.log(error)
            this.setState({code: ""})
        })
    }

    componentDidMount() {
        this.sendcode()
    }


    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    handleSubmit = e => {
        if (!this.state.verified) {
            return false
        }
        return true

    }

    render() {
        const showErrorMeesage = () => {
            return (
                <div className="d-flex justify-content-center">

                    <div style={{backgroundColor:"#FFE6BF", borderRadius:"4px", padding:"10px",marginTop:"15px", maxWidth:"350px"}}>
                        <img src="/images/warningSignYellow.png" style={{width:"18px", height:"18px", marginRight:"10px"}}/>
                        <a>The code is incorrect or expired.</a>
                    </div>
                </div>
            )
        }

        if (this.state.showResult && this.state.verified ) {
            return (
                <div className="text-center mb-3">
                    <img src="/images/emailSuccess.png" style={{width:"80px", height:"78px"}}/>
                    <div className="prequalBigText">Succeed!</div>
                    <div className="prequalSmallText pt-2">
                        <div>Your verification was successful.</div>
                        <div>Continue to your agent portal.</div>
                        
                    </div>

                </div>
            )
        }

        return (
            <div className="text-center mb-3">
                

                <div><img src="/images/checkEmail2.png" style={{width: 66, height:"auto", marginBottom: '23px'}}/></div>
                <div className="prequalBigText prequalCheckText">Check your email</div>
                {/* <div className="prequalSmallText pt-2 text-wrap">Please enter the 4 digit code sent to {this.props.application.borrower.email}</div> */}
                {
                    this.props.email && (
                        <div style={{margin: '6px auto 18px', color: '#6E6E70', lineHeight: '20px'}}>
                            <div>We sent a code to <span style={{fontWeight: 600, color: '#222'}}>{this.props.email}</span><a className="prequalEditText" onClick={()=>{this.editEmail(1)}}>Edit</a></div>
                            <div>Enter the code to log in your account</div>
                        </div>
                    )
                }
                <div style={{display:"flex", justifyContent:"center", padding:"20px 0 0 10px"}}>
                {(this.state.verifingCode||this.state.sendingCode) ? <Spinner animation="border" size="lg"/> :<CodeVerification  value={this.state.code} onChange={(e)=>{
                        this.setState({code: e})
                        if (e.length === 4) {
                            this.verifycode(e)
                        }
                    }}/>}
                    
                </div>
                {(this.state.showResult && !this.state.verified ) && showErrorMeesage()}
                <div style={{fontSize: 16}} className="p-2">Didn't recieve the code? <a style={{color:"#325CEB", textDecorationLine: "underline"}} onClick={()=>{
                    this.sendcode()
                }}>Resend</a></div>
                {this.props.showBack && <div style={{fontSize: 16}}>or <a style={{color:"#325CEB", textDecorationLine: "underline"}} onClick={()=>{this.editEmail(2)}}>Sign in with password</a></div>}

            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EmailVerification)
