import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
import * as com from "./Common.js"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Interview from './Interview/Interview'
import Tasks from './Tasks/Tasks'
import EmployeeBoard from './EmployeeBoard/EmployeeBoard';
import { connect } from 'react-redux'
import * as act from "./Store/actions"
import * as brwr from "./Store/borrower"
import * as prgr from "./Store/progress"
import { mergeState } from "./Store"
import Documents from './Documents/Documents.js'
import FormPreview from './FormPreview'
import ContactInformation from './ContactInformation'
import CreditReport from './CreditReport'
import LOInput from './LOInput/LOInput'
import LoanSummary from "./LoanSummary/LoanSummary";
import DocumentsVerification from './DocumentVerification/DocumentsVerification'
import { application, State } from './State.js'
import { UpdateChangeLastTouch } from "./Store/progress"
import LoanComments from "./LoanComments";
import Interview1003View from "./Interview1003View/Interview1003View";
import Prequal from "./Interview/Prequal";
import ProductPicker from "./Interview/ProductPicker";
import "./Borrower.css"
import {HomeFilled, RightOutlined, DownloadOutlined} from '@ant-design/icons';
import { Button, Radio, Space, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const merge = require('deepmerge')
const deepcopy = require('deepcopy');
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    changeBorrowerId: (id) => {
        dispatch(brwr.ChangeBorrowerId(id))
    },
    changeBorrowerLoanId: (id) => {
        dispatch(brwr.ChangeBorrowerLoanId(id))
    },

    changeBorrowerName: (name) => {
        dispatch(brwr.ChangeBorrowerName(name))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
});

class Borrower extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            id: "",
            render: this.renderEmpty,
            remoteCoborrowers: [],
            selectedTabKey:"loanSummary",
            isPrequal: false,
            showRates: false,
            loadingPdf: false, 
            url:"",
            download:"",
        }
        if (this.props.progress.stage === "")
            this.props.updateChangeProgress(0, "interview")
        this.aref = React.createRef();
        // this.handleSelectedTabKeyChange = this.handleSelectedTabKeyChange.bind(this);
    }

    renderEmpty = () => (<div></div>)
    componentDidMount = () => {
        // eslint-disable-next-line react/no-direct-mutation-state
        const selectedKeys = sessionStorage.getItem('borrowermenu')
        if (selectedKeys) {
            this.setState({
                selectedTabKey: selectedKeys,
            })
        }
        this.state.id = this.props.match.params["id"]
        this.setState({ id: this.props.match.params["id"] })
        this.reload()
        this.getRemoteCoborrowers()
    }

    componentWillReceiveProps = (newProps) => {
        //alert( this.props.match.params["id"] )  
        let newid = newProps.match.params["id"]
        if (this.state.id === newid)
            return
        this.forceStateSynchronization()
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.id = newid
        this.setState({ id: newid, remoteCoborrowers: [] }, () => {
            this.reload()
            this.getRemoteCoborrowers()
        })
    }
    // handleSelectedTabKeyChange(val) {
    //     this.setState({ selectedTabKey: val });
    // }

    handleSubtabTabSelect = (customerID) => {
        this.forceStateSynchronization().then(() => {
            this.reload(customerID)
        })
    }

    reload = (customer_id) => {
        if (typeof customer_id === 'undefined') {
            customer_id = ""
            this.setState({ render: this.renderEmpty })
        }
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.state.id, CustomerID: customer_id }
        fetch('/agent/borrower', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        let state = JSON.parse(js.Borrower.State)

                        let serstate
                        if ("{}" === js.Borrower.State) {
                            state = JSON.parse((() => { let l = new State(); return l.toJson() })())
                        }
                        try {
                            let applicationInitialState = JSON.parse((() => { let l = new application(); return l.toJson() })())
                            let app = merge(applicationInitialState, state.application, { arrayMerge: overwriteMerge })
                            state.application = app
                        } catch (x) {
                            // just keep it
                        }
                        sessionStorage.removeItem("state")
                        sessionStorage.removeItem("originalstate")
                        sessionStorage.removeItem("edit")
                        serstate = JSON.stringify(state)
                        sessionStorage.setItem("state", serstate)
                        sessionStorage.setItem("originalstate", serstate)
                        this.props.updateChangeApplication(state.application)
                        this.setState({ 
                            firstname: js.Borrower.FirstName, 
                            lastname: js.Borrower.LastName, 
                            isPrequal:  state.application.property.purpose === "purchase" && state.application.property.hascontract===false,
                            showRates: state.application.scre !== 0 ? true: false,
                        })
                        this.props.changeBorrowerId(js.Borrower.ID)
                        this.props.changeBorrowerLoanId(js.Borrower.LoanID)
                        this.props.changeBorrowerName(js.Borrower.FirstName + " " + js.Borrower.LastName)
                        if (state.progress != null) {
                            this.props.updateChangeProgress(state.progress.interview.max, "max")
                            this.props.updateChangeProgress(state.progress.interview.step, "interview")
                            this.props.updateChangeLastTouch(state.progress.lasttouch)
                        } else {
                            //debugger
                            this.props.updateChangeProgress(0, "max")
                            this.props.updateChangeProgress(0, "interview")
                        }
                        
                        //} else {
                        //    alert("The borrower " + js.Borrower.FirstName + " " + js.Borrower.LastName + " did not input any information yet!")
                        //}
                        let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.Borrower.ID)
                        if (index === -1) {
                            let { ID, FirstName, LastName, ContactPhone, Email } = js.Borrower
                            this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: true }], render: this.renderTabs })
                        }
                    }
                });
            }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }

    getRemoteCoborrowers = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.state.id }
        fetch('/agent/remotecoborrowers', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        if (js.RemoteCoborrowers !== null) {
                            for (let i = 0; i < js.RemoteCoborrowers.length; i++) {
                                let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.RemoteCoborrowers[i])
                                if (index === -1) {
                                    let { ID, LastName, FirstName, ContactPhone, Email } = js.RemoteCoborrowers[i]
                                    this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: false }] })
                                }
                            }
                            this.setState({ render: this.renderTabs })
                        }
                    }
                });
            }
        )
    }

    forceStateSynchronization = () => {
        let state = deepcopy({ borrower: this.props.borrower, application: this.props.application })
        let previousState = JSON.parse(sessionStorage.getItem("originalstate"))
        let token = window.sessionStorage.getItem("ZeitroA");
        let { id } = state.borrower
        if (id === "")
            return
        return new Promise((resolve, reject) => {
            fetch("/agent/borrowerstate/" + id, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                },
            }).then(response => {
                if (!response.ok) {
                    console.log("Auth fetch error")
                    sessionStorage.removeItem("ZeitroA")
                    window.location.href = "/"
                } else {
                    response.json().then(js => {
                        if (js.Status !== "OK") {
                            console.log("State Update Error: " + js.Status)
                            reject('rejected')
                        } else {
                            //console.log("State Update Success: " + js.Status)
                            //debugger
                            mergeState(id, js.Text, state, previousState)
                            resolve('resolved')
                        }
                    })
                }
            }).catch(error => {
                console.log("error")
            })
        })
    }

    onselect = (key, e) => {
        let mainBorrower = this.state.remoteCoborrowers.find(person => person.isMain)
        if (mainBorrower.ID !== this.props.borrower.id) {
            this.forceStateSynchronization().then(() => {
                this.reload(mainBorrower.ID)
            })
        }
        this.props.updateChangeProgressType(key)
    }
    renderTabs = () => {
        let ID = ""
        try {
            ID = this.state.remoteCoborrowers[0].ID
        } catch (x) {

        }

        const getItem = (label, key, icon, children, type, disabled) => {
            return {
              key,
              icon,
              children,
              label,
              type,
              disabled,
            };
          }

        let firstItems = []
        if (this.state.isPrequal) {
            firstItems = [
                getItem('Loan summary', 'loanSummary'),
                getItem('Loan interview', 'loanInterview'),
                getItem('Pre-qual letter', 'prequalLetter', null, null, null, !this.state.showRates)
            ]
        } else {
            firstItems = [
                getItem('Loan summary', 'loanSummary'),
                getItem('Loan interview', 'loanInterview'),
            ]
        }


        firstItems.push(getItem('Rates', 'rates', null, null, null, !this.state.showRates))

        firstItems.push(getItem('Loan comments', 'loanComments'))

        let items = [
            getItem('Loan Overview', 'sub1', null, firstItems),
            getItem('Documents', 'documents'),
            getItem('Tasks', 'tasks'),
            getItem('Credit Report', 'creditReport'),
            getItem('MISMO', 'mismo'),
            getItem('Doc Verification', 'docVerification'),
        ];


        const renderTab = () => {
            switch (this.state.selectedTabKey) {
                case "loanSummary":
                    return  <LoanSummary key={ID} borrowerid={this.props.borrower.id} /> ;
                case "loanInterview":
                    // return <Interview key={this.state.id} borrowerid={this.props.borrower.id} />
                    return <Interview1003View key={this.state.id} borrowerid={this.props.borrower.id} />
                case "prequalLetter":
                    let ref = React.createRef()
                    return <div className="m-4"><Prequal borrowerid={this.props.borrower.id} loanid={this.props.borrower.loan_id} ref={ref}/></div>
                case "rates":
                    let ratesRef = React.createRef()
                    return <div className="m-4"><ProductPicker borrowerid={this.props.borrower.id} loanid={this.props.borrower.loan_id} ref={ratesRef}/></div>
                case "loanComments":
                    return <LoanComments />
                case "documents":
                    return <Documents key={this.state.id} />
                case "tasks":
                    return <Tasks key={this.state.id}/>
                case "creditReport":
                    return <CreditReport />
                case "mismo":
                    return <FormPreview form='mismo34' />
                case "docVerification":
                    return <div className="p-3"><DocumentsVerification /></div>
                default:
                    return <div>Please select</div>;
            }
        }

        const getLoanPurpose = () => {
            if (this.state.isPrequal) {
                return "Pre-qualification"
            }
            if (this.props.application.property.purpose === "purchase") {
                return "Purchase"
            } else if (this.props.application.property.purpose === "cashoutrefinance") {
                return "Cash-out refinance"
            } else {
                return "Refinance"
            }
        }

        const getLTV = () => {
            if (this.props.application.selection.loansize === "") {
                return "-"
            }
            return Math.floor(100 * com.safeParseInt(this.props.application.selection.loansize) / parseFloat(this.props.application.property.salesprice)) + "%"
        }

        const getLoanAmount = () => {
            if (this.props.application.selection.loansize === "") {
                return "-"
            }
            return "$" + com.commaize(this.props.application.selection.loansize)
        }

        const borrowerTopBar = () => {
            let st = {fontSize:18, color:"#6E6E70", marginRight: 10}
            return <div className="borrowerHeader">
            <div style={{gridColumn: 1, display:"flex", alignItems:"center"}}>
                <Link className="borrowerHeader-link" to={"/app/borrowers"}><div style={st}>Borrowers</div> </Link>
                <RightOutlined className="borrowerHeader-link" style={st} />
                <Link className="borrowerHeader-link" to={"/app/borrowers/" + this.props.borrower.id}><div className="" style={st}>{this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname}</div></Link>
                <RightOutlined className="borrowerHeader-link" style={st} />
                <div className="borrowerTopBarLoanInfo" >
                    <HomeFilled style={{fontSize: 23, marginRight: 20}}/>
                    {this.state.isPrequal ? <div className="d-flex"><div className="text-left mr-5" >
                        <div className="subHeader">Loan purpose</div>
                        <div className="title-5-bold">
                            {getLoanPurpose()}
                        </div>
                    </div>
                    <div className="text-left mr-5" >
                        <div className="subHeader">Loan Amount</div>
                        <div className="title-5-bold">
                            {getLoanAmount()}
                        </div>
                    </div>
                    <div className="text-left mr-5" >
                        <div className="subHeader">LTV</div>
                        <div className="title-5-bold">
                            {getLTV()}
                        </div>
                    </div></div> : <div className="text-left">
                        <div className="bold ">{this.props.application.property.address}</div>
                        <div className="flex-dots-between" style={{fontSize: 12}}>
                            <div className="d-flex">
                                <div>Loan Purpose</div>
                                <div className="bold ml-2">{getLoanPurpose()}</div>
                            </div>
                            <div className="d-flex">
                                <div>Loan Amount</div>
                                <div className="bold ml-2">{getLoanAmount()}</div>
                            </div>
                            <div className="d-flex">
                                <div>LTV</div>
                                <div className="bold ml-2">{getLTV()}</div>
                            </div>
                        </div>
                        </div>}
                </div>
            </div>
            <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>
            <div style={{gridColumn: 2, marginRight:20, display:"flex"}}>
                <Button className="mr-3 pdfButton" onClick={()=> {
                    this.getForm1003()
                }} >
                    {this.state.loadingPdf ? <Spin indicator={<LoadingOutlined
                            style={{
                            fontSize: 20,
                            }}
                            spin
                        />} /> : "PDF 1003"}
                </Button>
                <Button type="primary"  onClick={()=> {
                    this.setState({selectedTabKey: "mismo"})
                    sessionStorage.setItem("borrowermenu", "mismo")
                }} >
                    MISMO
                </Button>

            </div>
            </div>
        }

        return  (
            <div>
                <div className=""  style={{position: 'sticky', top: 0, backgroundColor: '#FFF', borderBottom: '1px solid #ECECEC', zIndex: 999}}>
                    {borrowerTopBar()}
                </div>
                <div className="d-flex borrow-content-wrap">
                    <Menu
                        onClick={(e)=> {
                            this.setState({selectedTabKey: e.key})
                            sessionStorage.setItem("borrowermenu", e.key)
                        }}
                        selectedKeys={[this.state.selectedTabKey]}
                        defaultOpenKeys={['sub1', 'sub2','sub3']}
                        mode="inline"
                        items={items}
                        className="text-left borrow-menu"
                    />
                    <div className="flex-grow-1">{renderTab()}</div>
                </div>
            </div>
        )
    }
    getForm1003 = () => {
        let disposition
        let token = com.getUserToken()
        this.setState({loadingPdf: true})
        fetch('/borrower/getform1003', {
          method: 'GET',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "X-Borrower": this.props.borrower.id,                
            "X-Loan": this.props.borrower.loan_id,                
          },
        }).then(response => {
            disposition = response.headers.get("Content-Disposition").split('=')

            return response.body
        })
            .then(body => {
                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }

                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(r => r.blob())
            .then(blob => {


                let download = disposition.slice(1).join().replace(/"/g, '')

                const url = window.URL.createObjectURL(blob);
                this.setState({ url: url, download: download })

                this.aref.current.click()
                this.forceUpdate();
                this.setState({loadingPdf: false})

            })
            .catch( (err) => {
                alert("Something went wrong with getting Form 1003, please contact zeitro for help.");
                console.log("Error in getForm1003:", err)
                this.setState({loadingPdf: false})

            });
    }
    render() {
        return (
            <div>
                {this.state.render()}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Borrower)

export class ReferredBorrower extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            id: "",
            render: this.renderEmpty,
            remoteCoborrowers: [],
        }

        if (this.props.progress.stage === "")
            this.props.updateChangeProgress(0, "interview")
        this.aref = React.createRef();
    }
    renderEmpty = () => (<div></div>)
    componentDidMount = () => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.id = this.props.match.params["id"]
        this.setState({ id: this.props.match.params["id"] })
        this.reload()
        this.getRemoteCoborrowers()
    }

    componentWillReceiveProps = (newProps) => {
        //alert( this.props.match.params["id"] )  
        let newid = newProps.match.params["id"]
        if (this.state.id === newid)
            return
        this.forceStateSynchronization()
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.id = newid
        this.setState({ id: newid, remoteCoborrowers: [] }, () => {
            this.reload()
            this.getRemoteCoborrowers()
        })
    }

    handleSubtabTabSelect = (customerID) => {
        this.forceStateSynchronization().then(() => {
            this.reload(customerID)
        })
    }

    reload = (customer_id) => {
        if (typeof customer_id === 'undefined') {
            customer_id = ""
            this.setState({ render: this.renderEmpty })
        }
        let token = sessionStorage.getItem("ZeitroA")
        let body = { LoanID: this.state.id}
        fetch('/re/referredborrower', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        let state = JSON.parse(js.Borrower.State)

                        let serstate
                        //if ("{}" !== js.Borrower.State) {
                        try {
                            let applicationInitialState = JSON.parse((() => { let l = new application(); return l.toJson() })())
                            let app = merge(applicationInitialState, state.application, { arrayMerge: overwriteMerge })
                            state.application = app
                        } catch (x) {
                            // just keep it
                        }
                        serstate = JSON.stringify(state)
                        sessionStorage.setItem("state", serstate)
                        sessionStorage.setItem("originalstate", serstate)
                        this.props.updateChangeApplication(state.application)
                        this.setState({ firstname: js.Borrower.FirstName, lastname: js.Borrower.LastName })
                        this.props.changeBorrowerId(js.Borrower.ID)
                        this.props.changeBorrowerLoanId(js.Borrower.LoanID)
                        this.props.changeBorrowerName(js.Borrower.FirstName + " " + js.Borrower.LastName)
                        if (state.progress != null) {
                            this.props.updateChangeProgress(state.progress.interview.max, "max")
                            this.props.updateChangeProgress(state.progress.interview.step, "interview")
                            this.props.updateChangeLastTouch(state.progress.lasttouch)
                        } else {
                            //debugger
                            this.props.updateChangeProgress(0, "max")
                            this.props.updateChangeProgress(0, "interview")
                        }
                        sessionStorage.removeItem("edit")
                        //} else {
                        //    alert("The borrower " + js.Borrower.FirstName + " " + js.Borrower.LastName + " did not input any information yet!")
                        //}
                        let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.Borrower.ID)
                        if (index === -1) {
                            let { ID, FirstName, LastName, ContactPhone, Email } = js.Borrower
                            this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: true }], render: this.renderTabs })
                        }
                    }
                });
            }
        ).catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
    }

    getRemoteCoborrowers = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.state.id }
        fetch('/agent/remotecoborrowers', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        if (js.RemoteCoborrowers !== null) {
                            for (let i = 0; i < js.RemoteCoborrowers.length; i++) {
                                let index = this.state.remoteCoborrowers.findIndex(person => person.ID === js.RemoteCoborrowers[i])
                                if (index === -1) {
                                    let { ID, LastName, FirstName, ContactPhone, Email } = js.RemoteCoborrowers[i]
                                    this.setState({ remoteCoborrowers: [...this.state.remoteCoborrowers, { ID, LastName, FirstName, ContactPhone, Email, isMain: false }] })
                                }
                            }
                            this.setState({ render: this.renderTabs })
                        }
                    }
                });
            }
        )
    }

    forceStateSynchronization = () => {
        let state = deepcopy({ borrower: this.props.borrower, application: this.props.application })
        let previousState = JSON.parse(sessionStorage.getItem("originalstate"))
        let token = window.sessionStorage.getItem("ZeitroA");
        let { id } = state.borrower
        if (id === "")
            return
        return new Promise((resolve, reject) => {
            fetch("/agent/borrowerstate/" + id, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                },
            }).then(response => {
                if (!response.ok) {
                    console.log("Auth fetch error")
                    sessionStorage.removeItem("ZeitroA")
                    window.location.href = "/"
                } else {
                    response.json().then(js => {
                        if (js.Status !== "OK") {
                            console.log("State Update Error: " + js.Status)
                            reject('rejected')
                        } else {
                            //console.log("State Update Success: " + js.Status)
                            //debugger
                            mergeState(id, js.Text, state, previousState)
                            resolve('resolved')
                        }
                    })
                }
            }).catch(error => {
                console.log("error")
            })
        })
    }

    onselect = (key, e) => {
        let mainBorrower = this.state.remoteCoborrowers.find(person => person.isMain)
        if (mainBorrower.ID !== this.props.borrower.id) {
            this.forceStateSynchronization().then(() => {
                this.reload(mainBorrower.ID)
            })
        }
        this.props.updateChangeProgressType(key)
    }
    renderTabs = () => {
        let ID = ""
        try {
            ID = this.state.remoteCoborrowers[0].ID
        } catch (x) {

        }

        return (
            <div key={this.state.id} className="p-0 m-0 mb-5">
                <Tabs unmountOnExit={true} onSelect={this.onselect} className="tabfont" variant="tabs" defaultActiveKey="interview" id="documenttabs">
                    <Tab eventKey="interview" title={this.state.remoteCoborrowers.length === 1 ? `${this.state.firstname} ${this.state.lastname}'s Interview` : 'Interviews'}>
                        {this.state.remoteCoborrowers.length > 1 ?
                            <Tabs unmountOnExit={true} onSelect={this.handleSubtabTabSelect} className="tabfont" variant="tabs">
                                {this.state.remoteCoborrowers.map((person, index) => (
                                    <Tab key={person.ID} eventKey={`${person.ID}`} title={`${person.FirstName} ${person.LastName}'s Interview`}>
                                        <div className="text-dark pt-5 border-left border-right">
                                            <Interview key={person.ID} borrowerid={person.ID} isDisabled={!person.isMain} />
                                        </div>
                                    </Tab>
                                ))}

                            </Tabs>
                            :
                            <div className="text-dark pt-1 border-left border-right">
                                <Interview key={this.state.id} borrowerid={this.props.borrower.id} viewonly={true}/>
                            </div>}
                    </Tab>
                    <Tab eventKey="LOBoard" title="Loan Summary">
                        <LoanSummary />
                    </Tab>
                </Tabs>
            </div>
        )
    }
    render() {
        return (
            <div>
                {this.state.render()}
            </div>
        )
    }
}

ReferredBorrower = connect(mapStateToProps, mapDispatchToProps)(ReferredBorrower)