import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import HorizontalSlider from '../Common/HorizontalSlider'
import ScrollBar from '../Common/components/ScrollBar';
import { isMobileOnly } from 'react-device-detect';
import { Redirect } from 'react-router-dom'
import { ChosenLoan, LoanProduct, service, fees } from '../State'
import * as com from "../Common.js"
import 'react-credit-cards/es/styles-compiled.css';
import { connect } from 'react-redux'
import { MonhtlyChangeBP } from '../Common/Tooltip'
import Details from './Details'
import Comparison from './Comparison'
import * as act from "../Store/actions"
import { getCurrentState } from '../Store'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap';
import { Select, Table, Button, Skeleton, Modal as Mod } from 'antd';
import * as st from "../State"
import "./ProductPicker.css"
import { ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    changeMainPropertyUnits: (input) => {
        dispatch(act.ChangeMainPropertyUnits(input))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
    updatePrepaidDefault: (name, period, prepaidperiods, amount) => {
        dispatch(act.UpdatePrepaidDefault(name, period, prepaidperiods, amount))
    },
});

var hash = require('object-hash');


const typeoptions = [
    { label: 'ARM 5', value: "arm56" },
    { label: 'ARM 7', value: "arm76" },
    { label: 'ARM 10', value: "arm106" },
    { label: 'Fixed', value: "fixed" }]

const termoptions = [
    { label: '30 year', value: 30 },
    { label: '25 year', value: 25 },
    { label: '20 year', value: 20 },
    { label: '15 year', value: 15 },
    { label: '10 year', value: 10 }]

export const pointsExplained = <div><b>Mortgage points</b> are percentage of the loan that you pay to the lender for a <b>lower monthly rate</b>.
    <div className='my-2'>Conversely, <b>negative points</b> are lender credits that can be used by you to <b>lower your closing costs</b>, or be put into an impound account to pay your insurance and taxes. </div>Lender credits are paid to you in exchange for a <b>higher interest rate</b></div>


export const getFees = function (loanid) {
    let token = sessionStorage.getItem("ZeitroA")
    this.setState({ loading: true })
    fetch('/data/getFees', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "Content-Type": "application/json",
            "X-Borrower": this.props.borrowerid,
        },
    }).then(
        response => {
            if (response.status !== 200) {
                alert("Bad response, should not happen")
                this.setState({
                    loading: false
                })
                return;
            }
            response.json().then(_fees => {

                let f = new fees(_fees)
                f.cannotshopservices = _fees.cannotshopservices
                f.canshopservices = _fees.canshopservices
                f.closingcostsfinanced = _fees.closingcostsfinanced
                f.deposit = _fees.deposit
                f.downpayment = _fees.downpayment
                f.fundsforborrower = _fees.fundsforborrower
                f.initialescrowpaymentsatclosing = _fees.initialescrowpaymentsatclosing
                f.othercosts = _fees.othercosts
                f.prepaids = _fees.prepaids
                f.recordingandgovernmentfees = _fees.recordingandgovernmentfees
                f.transfertax = _fees.transfertax
                f.sellerscredits = _fees.sellerscredits
                f.adjustmentsothercredits = _fees.adjustmentsothercredits

                this.props.updateSelectField(f, "estimatedclosingcosts")
                getCurrentState()
                setTimeout(() => this.getDTI(loanid), 3000)
            })
        }

    ).catch((err) => {

        this.setState({ loading: false })
        alert("Network error")
    });
}
export const getDTI = function (loanid) {
    let token = sessionStorage.getItem("ZeitroA")
    this.setState({ loading: true })
    fetch('/data/getPreDTI', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "Content-Type": "application/json",
            "X-Borrower": this.props.borrowerid,
            "X-Loan": typeof loanid === "undefined" ? "" : loanid,
        },

    }).then(
        response => {
            if (response.status !== 200) {
                alert("Bad response, should not happen")
                this.setState({
                    loading: false
                })
                return;
            }
            response.json().then(pr => {
                this.setState({ loading: false })

                if (typeof pr["Error"] !== "undefined") {
                    console.log(pr.Error)
                    alert("Sorry, but we are unable to provide rates right now. Please contact contact@zeitro.com for help.")
                } else {
                    this.loaded = true
                    this.fees = pr.dtiData[0].fees
                    this.fico = pr.fico
                    this.zeitro_fee = pr.zeitro_fee
                    this.prepaid_interest_days = pr.prepaid_interest_days
                    //this.props.updateSelectField(this.zeitro_fee.default, "ourfee")
                    this.props.updateSelectField(this.prepaid_interest_days, "prepaiddays")

                    this.productReload(pr)
                }
            })
        }

    ).catch((err) => {

        this.setState({ loading: false })
        alert("Network error")
    });
}
export const getCitizenship = function () {
    let status = this.props.application.borrower.citizenship
    if (status === "citizen") {
        return ["US_CITIZENS", ""]
    }
    if (status === "permanent") {
        return ["PERMANENT_RESIDENT_ALIEN", ""]
    }
    return ["NON_PERMANENT_RESIDENT_ALIEN", this.props.application.borrower.visa.replace('-', '_')]

}
export const getOccupancy = function () {
    switch (this.props.application.property.occupancy) {
        case "principal":
            return "PRIMARY"
        case "secondhome":
            return "SECOND_HOME"
        case "investment":
            return "INVESTMENT"
        default:
            alert("wrong occupancy")
            return "PRIMARY"
    }
}
export const getPropertyType = function () {
    let remap = {
        condominiumdetached: "CONDOMINIUM_DETACHED",
        condominium: "CONDOMINIUM_ATTACHED",
        pudattached: "PUD_ATTACHED",
        puddetached: "PUD_DETACHED",
        singlefamilyattached: "SINGLE_FAMILY_ATTACHED",
        singlefamilydetached: "SINGLE_FAMILY_DETACHED",
        twotofourfamily: "TWO_TO_FOUR_FAMILY",
        manufacturedhome: "MANUFACTURED_HOME"
    }
    let out = remap[this.props.application.property.propertytype]
    if (this.props.application.property.isManufacturedHome === true) {
        out = "MANUFACTURED_HOME"
    }
    return out
}

export const productReload = function (predti) {
    if (null == this.loaded)
        return
    // this one needs work
    let firstTimeHomeBuyer = (this.props.application.firsttimehomebuyer)
    let [citizenship, visa] = this.getCitizenship()

    let request = {
        state: this.props.application.property.state,
        county: this.props.application.property.county,
        purpose: com.Purpose2Calculator(this.props.property.purpose),
        loanAmount: com.safeParseInt(this.state.loansize),
        appraisal: this.props.application.property.appraisal,        
        estimatedClosingCosts: this.fees,
        firstTimeHomeBuyer: firstTimeHomeBuyer,
        units: this.props.application.property.propertytype === "twotofourfamily" ? com.safeParseInt(this.props.application.property.units) : 1,
        occupancy: this.getOccupancy(), // propagated
        property: this.getPropertyType(),
        selfemployed: this.props.application.borrower.occupation.selfemployed,
        property: this.props.application.property.propertytype,
        citizenship: citizenship,
        visaType: visa,
        preDTI: predti
    }

    let token = sessionStorage.getItem("ZeitroA")
    this.setState({ loading: true })
    fetch('/data/getproducts?isagent=true', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "Content-Type": "application/json",
            "X-Borrower": this.props.borrowerid,
        },
        body: JSON.stringify(request)
    }).then(
        response => {
            if (response.status !== 200) {

                this.setState({
                    loading: false, errorText: <h1 className='mt-5'>Server error</h1>,
                    products: [], topProducts: []
                })
                if (this.failedProduct != null)
                    this.failedProduct()
                return;
            }
            response.json().then(pr => {

                let products = pr.result
                let status = pr.status
                if ("Ok" !== status) {
                    /*
                    - "0" # InternalError
                    - "1" # LowFICOError
                    - "2" # HighDTIError
                    - "3" # HighLTVError
                    - "4" # LowIncomeError
                    - "5" # MatrixNotFoundError
                    - "6" # UnqualifiedPropertyError
                    */

                    let msg = ""
                    let showDetails = () => {
                        if (pr == null) return ""
                        if ("undefined" !== typeof this.props.borrowerid) {
                            let out = []
                            let reasons = pr["ineligible_reasons"]
                            if (reasons == null) return ""
                            for (const [key, value] of Object.entries(reasons)) {
                                let entry = <div className="mt-2" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{com.capitalize(key)}</div>
                                out.push(<div> {entry} </div>)
                                if (value.LenderLevelReason !== "")
                                    out.push(<div>{value.LenderLevelReason}</div>)
                                else {
                                    let preasons = value.ProductLevelReasons
                                    /* eslint-disable-next-line no-unused-vars */
                                    if (preasons !== null) {
                                        for (const [key, value] of Object.entries(preasons)) {
                                            out.push(<div>{value.ProductName}: {value.IneligibleReason} </div>)
                                        }
                                    }
                                }

                            }
                            return out
                        } else
                            return ""

                    }
                    msg = <h5 className='sectionquestion my-5 text-wrap text-center'><span style={{ color: '#993333' }}>Matching engine could not find a suitable loan. Reason: </span> <br />{pr["message"]}<br /> <div className="text-left" style={{ fontSize: '0.7em' }}>{showDetails()}</div> </h5>
                    this.setState({
                        loading: false, errorText: msg, errorCode: pr["status code"],
                        products: [], topProducts: []
                    })
                    if (this.failedProduct != null)
                        this.failedProduct()
                    return
                }
                if (this.askedForAssistance()) {
                    products = this.filterForAssistance(products)
                }
                this.processProducts(products)
                this.setState({ loading: false })
            })
        }

    ).catch((err) => {
        console.log(err)
        this.setState({ loading: false })
        this.setState({
            loading: false, errorText: <h1 className='mt-5'>{"Network Error"} </h1>,
            products: [], topProducts: []
        })
        if (this.failedProduct != null)
            this.failedProduct()
    });
}
export const getLTV = function () {
    let ltv = com.safeParseInt(this.state.loansize) * 100 / com.safeParseInt(this.props.application.property.appraisal)
    let cltv = (com.safeParseInt(this.state.loansize) + com.safeParseInt(this.cltv)) * 100 / com.safeParseInt(this.props.application.property.appraisal)
    let hcltv = (com.safeParseInt(this.state.loansize) + com.safeParseInt(this.hcltv)) * 100 / com.safeParseInt(this.props.application.property.appraisal)
    return [ltv, cltv, hcltv]
}

export class ProductPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeselected: [],
            termselected: [],
            yearstohold: 7,

            minclosingcost: 0,
            maxclosingcost: 100000,
            lowclosingcost: 0,
            highclosingcost: 0,

            loansize: 0,
            products: [],
            topProducts: [],
            loading: false,
            weightclosing: this.props.selection.weightclosing,
            weighttotalcost: this.props.selection.weighttotalcost,
            weightmonthly: this.props.selection.ƒTriangggweightmonthly,
            showSelf: false,
            ltv: 60.0,
            cltv: 0,
            fico: 720,
            income: 18000.0,
            occupancy: "PRIMARY",
            property: "SINGLE_FAMILY_DETACHED",
            errorText: "",
            downpayment: 30000,
            defdownpayment: 30000,
            viewkey: 0,
            paydown: 0,
            cashout: 30000,
            redirect: "",
            positionbyemail: {},
            statusloaded: false,
            showHelp: true,
            show: false,
            detailedCard: null,
            lenders: [],
            selectedlenders: [],
            selectedmaxreserves: -1,
            maxreserves: -1,
            showPriceChanged: false,
            selectedImage: null,
            needsAdjustment: false,
            comparisonSelections: {},
            showComparison: false,
            mincashout: 0,
            dtiSpreadsheetLoading: false,

            ratesToBeRecommended: [],
            showRecommendRatesModal: false,
        }
        this.hcltv = null
        this.cltv = null
        this.timer = 0
        this.lenders = []
        this.changeshown = false
        this.oldmaxupfrontcost = this.props.selection.maxupfrontcost
        if (isNaN(this.oldmaxupfrontcost))
            this.oldmaxupfrontcost = null
        this.howmany2display = 5
        if (this.props.borrowerid)
            this.howmany2display *= 3

        switch (this.props.property.purpose) {
            case "purchase":

                if (this.props.selection.selectedloan.hash !== "") {
                    this.state.loansize = this.props.selection.selectedloan.loansize
                    this.state.downpayment = this.state.defdownpayment = com.safeParseInt(this.props.property.salesprice) - this.state.loansize
                    this.props.updateSelectField(this.state.downpayment, "downpayment")
                } else {
                    this.state.downpayment = com.safeParseInt(this.props.selection.downpayment)
                    this.state.defdownpayment = com.safeParseInt(this.props.selection.downpayment)
                    this.state.loansize = com.safeParseInt(this.props.property.salesprice) - com.safeParseInt(this.state.downpayment)
                }
                break;
            case "refinance":

                if (this.props.selection.selectedloan.hash !== "") {
                    this.state.loansize = this.props.selection.selectedloan.loansize
                    this.state.downpayment = this.state.defdownpayment = com.safeParseInt(this.props.application.loanbalance) - this.state.loansize
                    this.props.updateSelectField(this.state.downpayment, "paydown")

                } else {
                    this.state.downpayment = com.safeParseInt(this.props.selection.paydown)
                    this.state.defdownpayment = com.safeParseInt(this.props.selection.paydown)
                    this.state.loansize = com.safeParseInt(this.props.application.loanbalance) - com.safeParseInt(this.props.selection.paydown)
                }
                break;
            case "cashoutrefinance":

                this.state.lmincashout = this.props.selection.selectedloan.min
                if (this.props.selection.selectedloan.hash !== "") {
                    this.state.loansize = this.props.selection.selectedloan.loansize
                    this.state.downpayment = this.state.defdownpayment = this.state.loansize - com.safeParseInt(this.props.application.loanbalance)
                    if (this.state.downpayment <= 0) {
                        this.state.downpayment = this.state.defdownpayment = 30000
                    }
                    this.props.updateSelectField(this.state.downpayment, "cashout")
                } else {
                    this.state.downpayment = com.safeParseInt(this.props.selection.cashout)
                    this.state.defdownpayment = com.safeParseInt(this.props.selection.cashout)
                    this.state.loansize = com.safeParseInt(this.props.application.loanbalance) + com.safeParseInt(this.props.selection.cashout)
                }
                break;
            default:
                alert("should not see this!")
                break;
        }

        this.props.updateSelectField(this.state.loansize.toString(), "loansize")
        this.downloadDTIspreadsheetRef = React.createRef();

        termoptions.forEach(term => {
            if (this.props.selection.loanterms.includes("year"+term.value))
                this.state.termselected.push(term.value)
        })
        if (this.state.termselected.length === 0)
            this.state.termselected = [termoptions[0].value]


        typeoptions.forEach(typ => {
            if (this.props.selection.loanamortizations.includes(typ.value))
                this.state.typeselected.push(typ.value)

        })
        if (this.state.typeselected.length === 0)
            this.state.typeselected = typeoptions.map(x => x.value)
        this.cull = (a) => a
        this.selectsort(this.props.selection.sortby)

        this.width = 250

        this.isPrequal = this.props.property.purpose==="purchase" && this.props.property.hascontract===false

        this.height = Math.floor((this.width / 2) * Math.tan(3.14 / 3.0))
        if (this.props.property.salesprice === "") {
            this.state.redirect = <Redirect to="/app/interview/main?section=start" />
        }
        this.rawproducts = []
        this.checkStatus()

        // backup
        this.loanbalance = this.state.loansize.toString()
        this.down = this.state.defdownpayment

        this.amortizations = [...this.props.selection.loanamortizations]
        this.terms = [...this.props.selection.loanterms]

        /* unit test!
                let l = ChosenLoan.fromJson(this.props.selection.selectedloan)
                l.hash = "ho ho ho" 
                this.props.updateSelectField(l, "selectedloan") 
        */
    }
    reset = () => {

        let termselected = []
        let typeselected = []
        this.props.updateSelectField(this.terms, "loanterms")
        this.props.updateSelectField(this.amortizations, "loanamortizations")

        termoptions.forEach(term => {
            if (this.terms.includes("year"+term.value))
                termselected.push(term.value)
        })
        if (termselected.length === 0)
            termselected = [termoptions[0].value]

        typeoptions.forEach(typ => {
            if (this.amortizations.includes(typ.value))
                typeselected.push(typ)

        })
        if (typeselected.length === 0)
            typeselected = typeoptions.map(x => x.value)

        this.setState({ termselected, typeselected }, () => {
            this.processProducts(this.rawproducts);
            this.forceUpdate()
        }
        )
    }
    getFees = getFees.bind(this)
    getDTI = getDTI.bind(this)
    getCitizenship = getCitizenship.bind(this)
    getOccupancy = getOccupancy.bind(this)
    getPropertyType = getPropertyType.bind(this)
    productReload = productReload.bind(this)
    getLTV = getLTV.bind(this)
    compareRate = (a, b) => {
        if (a.base_rate !== b.base_rate)
            return a.base_rate - b.base_rate

        return a.closing_cost - b.closing_cost // take into account remaining credits
    }

    cullRate = products => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let base_rate = products[0].base_rate
        let counter = 0
        for (let i = 1; i < products.length; i++) {
            if (products[i].base_rate !== base_rate) {
                newproducts.push(products[i])
                base_rate = products[i].base_rate
                counter = 1
            } else {


                counter += 1
                if (counter < 5) {
                    newproducts.push(products[i])
                    base_rate = products[i].base_rate
                }
            }
        }
        return newproducts
    }
    compareAPR = (a, b) => {
        if (a.APR !== b.APR)
            return a.APR - b.APR
        if (a.base_rate !== b.base_rate)
            return a.base_rate - b.base_rate
        return a.closing_cost - b.closing_cost
    }
    cullAPR = products => {
        if (products.length === 0)
            return products
        let newproducts = []
        newproducts.push(products[0])
        let APR = products[0].APR
        for (let i = 1; i < products.length; i++) {
            if (products[i].APR !== APR) {
                newproducts.push(products[i])
                APR = products[i].APR
            } else {
            }
        }
        return newproducts
    }
    compareMoneyUpfront = (a, b) => {
        let acc = this.getClosingCosts(a)
        let bcc = this.getClosingCosts(b)
        if (acc !== bcc)
            return acc - bcc
        return a.base_rate - b.base_rate
    }

    cullMoneyUpfront = products => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let closing_cost = this.getClosingCosts(products[0])
        for (let i = 1; i < products.length; i++) {
            if (this.getClosingCosts(products[i]) !== closing_cost) {
                newproducts.push(products[i])
                closing_cost = this.getClosingCosts(products[i])
            } else {
            }
        }
        return newproducts
    }

    compareMonthly = (a, b) => {
        if (a.monthly_payment !== b.monthly_payment)
            return a.monthly_payment - b.monthly_payment
        return a.closing_cost - b.closing_cost
    }

    cullMonthly = (products) => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let monthly_payment = products[0].monthly_payment
        for (let i = 1; i < products.length; i++) {
            if (products[i].monthly_payment !== monthly_payment) {
                newproducts.push(products[i])
                monthly_payment = products[i].monthly_payment
            } else {
            }
        }
        return newproducts
    }
    compareTotalOwnership = (a, b) => {
        let loansize = com.safeParseInt(this.state.loansize)
        let ca = com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, a.Term) //+ a.closing_cost
        let cb = com.getClosedInterestPaid(0.01 * b.base_rate, loansize, b.Term, b.Term) //+  b.closing_cost

        if (ca !== cb)
            return ca - cb

        return a.base_rate - b.base_rate
    }
    cullTotalOwnership = (products) => {
        if (products.length === 0)
            return products

        let getCost = a => {
            let loansize = com.safeParseInt(this.state.loansize)
            return com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, a.Term) //+ a.closing_cost            
        }
        let newproducts = []
        newproducts.push(products[0])
        let cost = getCost(products[0])

        for (let i = 1; i < products.length; i++) {
            if (cost !== getCost(products[i])) {
                newproducts.push(products[i])
                cost = getCost(products[i])
            } else {
            }
        }
        return newproducts
    }

    compareHoldingOwnership = (a, b) => {
        let loansize = com.safeParseInt(this.state.loansize)
        let ca = com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, this.state.yearstohold) // + a.closing_cost
        let cb = com.getClosedInterestPaid(0.01 * b.base_rate, loansize, b.Term, this.state.yearstohold) // +  b.closing_cost

        if (ca !== cb)
            return ca - cb

        return a.base_rate - b.base_rate
    }

    cullHoldingOwnership = (products) => {
        if (products === [])
            return products

        let getCost = a => {
            let loansize = com.safeParseInt(this.state.loansize)
            return com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, this.state.yearstohold) + a.closing_cost
        }
        let newproducts = []
        newproducts.push(products[0])
        let cost = getCost(products[0])

        for (let i = 1; i < products.length; i++) {
            if (cost !== getCost(products[i])) {
                newproducts.push(products[i])
                cost = getCost(products[i])
            } else {
            }
        }
        return newproducts
    }

    askedForAssistance = () => {
        return true === this.props.application.askedforassistance && this.props.application.property.purpose === "purchase"
    }
    componentDidMount() {

        this.getFees()
    }

    filterForAssistance = products => {
        let out = []
        products.forEach(x => {
            if (["RocketPro", "Plaza"].includes(x.Lender)) {
                out.push(x)
            }
        })
        return out
    }

    updateLender = lender => {

        let found = false
        this.lenders.forEach(x => {
            if (x === lender) {
                found = true
            }
        })
        if (found)
            return
        this.lenders.push(lender)
        this.setState({ selectedlenders: this.lenders, lenders: this.lenders })
    }
    isUnchecked = (lender) => {
        let found = false
        this.state.selectedlenders.forEach(x => {
            if (x === lender) {
                found = true
            }
        })
        return !found
    }
    processProducts = (products) => {
        if ("undefined" === typeof products || products.length === 0)
            return
        this.rawproducts = products
        let rearranged = []

        let minclosingcost = 10000000
        let maxclosingcost = 0
        let mintotalinterest = 100000000
        let maxtotalinterest = 0
        let maxreserves = 0
        let _types = [...this.state.typeselected]
        let _terms = [...this.state.termselected]
        let _totalterms = []
        products.forEach(product => {
            let lender = product.Lender
            this.updateLender(lender)
            if (this.isUnchecked(lender))
                return
            product.ProductRatesCosts.forEach(pr => {
                let commonproduct = pr.product
                let ausengine = pr.AUSEngine
                pr.rateCosts.forEach(rate => {
                    if (!_totalterms.includes(com.safeParseInt(commonproduct.Term))) {

                        _totalterms.push(com.safeParseInt(commonproduct.Term))
                    }

                    if (_terms.includes(com.safeParseInt(commonproduct.Term))) {
                        let typ = commonproduct.Arm
                        if (typ === null || typeof typ === "undefined")
                            typ = "fixed"
                        else
                            typ = "arm" + typ.fixedperiod + "6"
                        // filter out non-30 day locks
                        if (rate.lockDay !== 30)
                            return

                        if (_types.includes(typ)) {

                            let cc = rate.closing_cost // + rate.prepaid_interest
                            if (cc > maxclosingcost)
                                maxclosingcost = cc
                            if (cc < minclosingcost)
                                minclosingcost = cc
                            let interest = com.getClosedInterestPaid(0.01 * rate.base_rate, com.safeParseInt(this.state.loansize), commonproduct.Term,
                                this.state.yearstohold)

                            if (interest > maxtotalinterest)
                                maxtotalinterest = interest
                            if (interest < mintotalinterest)
                                mintotalinterest = interest
                            if (rate.min_months_reserves > maxreserves)
                                maxreserves = rate.min_months_reserves
                        } else {
                        }
                    } else {
                    }
                    let pdkt = {
                        Lender: lender,
                        ...commonproduct,
                        Aus: ausengine,
                        ...rate
                    }

                    rearranged.push(pdkt)
                })
            })
        });


        this.setState({ maxreserves })

        if (this.state.selectedmaxreserves < 0 || this.state.selectedmaxreserves > maxreserves) {
            this.setState({ selectedmaxreserves: maxreserves })
        // eslint-disable-next-line react/no-direct-mutation-state           
            this.state.selectedmaxreserves = maxreserves
        }


        if (minclosingcost < 0)
            minclosingcost = 0
        let lowclosingcost = minclosingcost
        let highclosingcost = maxclosingcost
        if (highclosingcost > 2000 && highclosingcost === maxclosingcost && lowclosingcost < 1000 && maxclosingcost < 20000)
            highclosingcost = 2000
        else {
            if (highclosingcost > 4000 && highclosingcost === maxclosingcost && lowclosingcost < 2000)
                highclosingcost = 4000
            else {
                if (highclosingcost > 8000 && highclosingcost === maxclosingcost && lowclosingcost < 4000)
                    highclosingcost = 8000
                else {
                    if (highclosingcost > 10000 && highclosingcost === maxclosingcost && lowclosingcost < 5000)
                        highclosingcost = 10000
                    else {
                        if (highclosingcost > 16000 && highclosingcost === maxclosingcost && lowclosingcost < 8000)
                            highclosingcost = 16000
                        else {
                            if (highclosingcost > 22000 && highclosingcost === maxclosingcost && lowclosingcost < 11000)
                                highclosingcost = 22000

                        }
                    }
                }

            }
        }

        let oldmaxupfrontcost = this.oldmaxupfrontcost

        if (null !== oldmaxupfrontcost && "" !== oldmaxupfrontcost) {
            oldmaxupfrontcost = parseInt(oldmaxupfrontcost)
            if (oldmaxupfrontcost <= minclosingcost)
                oldmaxupfrontcost = minclosingcost
            if (oldmaxupfrontcost >= maxclosingcost)
                oldmaxupfrontcost = maxclosingcost
            highclosingcost = oldmaxupfrontcost
        }

        this.oldmaxupfrontcost = highclosingcost
        if (oldmaxupfrontcost !== highclosingcost) {
            let st = highclosingcost.toString()

            this.props.updateSelectField(st, "maxupfrontcost")
        }



        this.setState({
            lowclosingcost: lowclosingcost, highclosingcost: highclosingcost,
            minclosingcost: minclosingcost, maxclosingcost: maxclosingcost,
            mintotalinterest: mintotalinterest, maxtotalinterest: maxtotalinterest
        })
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.lowclosingcost = lowclosingcost
        // eslint-disable-next-line react/no-direct-mutation-state        
        this.state.highclosingcost = highclosingcost

        let terms = []
        rearranged.forEach(pr => {
            let term = pr.Arm ? pr.Arm.fixedperiod : "fixed"
            if (!terms.includes(term))
                terms.push(term)
        })

        // Lender, Name
        let plainproducts = []
        this.referi = {}
        rearranged.forEach(pr => {
            if (pr.lockDay !== 30)
                return
            
            const { Lender, Name, Aus, lenderinfo, features, DTI, high_cost_loan, high_priced_loan, ...partialObject } = pr;

            let hsh = hash(partialObject)
            if (typeof this.referi[hsh] === "undefined") {
                plainproducts.push({ hash: hsh, ...partialObject })
                this.referi[hsh] = [{ Lender: Lender, Name: Name, Aus: Aus, LenderInfo: lenderinfo, Rate: partialObject }]
            } else {
                
                this.referi[hsh].push({ Lender: Lender, Name: Name, Aus: Aus, LenderInfo: lenderinfo, Rate: partialObject })
            }
        })

        this.products = plainproducts
        this.filter()

        this.getSelectedIcon(this.props.selection.selectedloan)
        if (!this.changeshown) {
            if (this.props.application.selection.selectedloan == null ||
                this.props.application.selection.selectedloan.hash === "" ) {

            } else {
                if (this.referi[this.props.application.selection.selectedloan.hash] == null) {
                    let suggestedLoan = this.findClosestLoan()
                    this.setState({showPriceChanged: true, suggestedLoan})
                }
            }
            this.changeshown = true
        }

    }
    getSelectedIcon = (selectedloan) => {
        // prep the logo for the selected loan
        if (selectedloan != null && selectedloan.hash !== "") {
            let lenders = this.referi[selectedloan.hash]

            if (lenders != null) {
                let lender = lenders[0]

                let src = "/loan.png"
                let image = <img alt="lender" height={24} style={{ height: '24', width: 'auto' }} className="mr-2" src={src}></img>
                this.setState({ selectedImage: image, needsAdjustment: false })
            } else {
                if (selectedloan.loansize !== this.state.loansize) {
                    // keep old
                } else {
                    let src = "/pricingchange.png"
                    let image = <Link onClick={this.onPriceChange}><img alt="ho ho ho" height={24} style={{ height: '24', width: 'auto' }} className="mr-2 oscillate" src={src}></img> </Link>
                    this.setState({ selectedImage: image, needsAdjustment: true })
                }
            }

        } else {
            let src = "/pricingchange.png"
            let image = <Link onClick={this.onPriceChange}><img alt="ho ho ho" height={24} style={{ height: '24', width: 'auto' }} className="mr-2 oscillate" src={src}></img> </Link>
            this.setState({ selectedImage: image, needsAdjustment: true })
        }
    }
    findClosestLoan = () => {
        let loan = this.props.selection.selectedloan
        let nextbest = null
        if(this.products.length === 0) {
            return
        }
        this.products.forEach(p => {
            if (p.base_rate !== loan.base_rate)
                return
            if (p.Term !== loan.Term)
                return
            if (loan.ARM != null) {
                if (p.ARM == null)
                    return
                if (loan.ARM.fixedperiod !== p.ARM.fixedperiod)
                    return
            }
            if (nextbest == null) {
                nextbest = p
                return
            }
            if (nextbest.closing_cost < 0 && p.closing_cost > 0)
                return
            if (nextbest.closing_cost < 0 && p.closing_cost < 0) {
                if (nextbest.closing_cost > p.closing_cost) {
                    nextbest = p
                    return
                }
            } else {
                if (nextbest.closing_cost > 0 && p.closing_cost > 0) {
                    if (nextbest.closing_cost > p.closing_cost) {
                        nextbest = p
                        return
                    }
                } else {
                    if (nextbest.closing_cost > 0 && p.closing_cost <= 0) {
                        nextbest = p
                        return
                    }
                }
            }
        })

        let suggested = new ChosenLoan()
        suggested.hash = nextbest.hash

        suggested.APR = nextbest.APR
        if (nextbest.Arm !== null)
            suggested.Arm = nextbest.Arm
        suggested.amortization = nextbest.MortgageAmortization
        suggested.Mtype = nextbest.Mtype
        suggested.Term = nextbest.Term
        suggested.base_rate = nextbest.base_rate
        suggested.closing_cost = nextbest.closing_cost
        suggested.features = nextbest.features
        suggested.high_cost_mortgage = nextbest.high_cost_mortgage
        suggested.lender_credit = nextbest.lender_credit
        suggested.lender_fee = nextbest.lender_fee
        suggested.lockDay = nextbest.lockDay
        suggested.min_months_reserves = nextbest.min_months_reserves
        suggested.monthly_payment = nextbest.monthly_payment
        suggested.prepaid_interest = nextbest.prepaid_interest
        suggested.points = nextbest.points
        suggested.pmi = nextbest.PMICost
        suggested.FHAMIP = nextbest.FHAMIP
        suggested.DTI = nextbest.DTI
        suggested.matchingproducts = []
        suggested.loansize = loan.loansize
        suggested.purpose = this.props.property.purpose
        suggested.ourfee = nextbest.zeitro_fee
        suggested.prepaiddays = this.prepaid_interest_days
        suggested.downpayment = loan.downpayment
        suggested.paydown = loan.paydown
        suggested.checkout = loan.checkout
        suggested.aus = loan.aus        
        suggested.matchingproducts = []
        if(nextbest.maxcachout != null)
            suggested.maxcashout = nextbest.maxcashout

        this.referi[nextbest.hash].forEach(
            pr => {
                let l = new LoanProduct()

                l.Lender = pr.Lender
                l.Name = pr.Name
                l.lenderinfo = pr.LenderInfo
                l.aus = pr.Aus
                suggested.matchingproducts.push(l)
            }
        )
        // suggested.lender = lender2lender[suggested.matchingproducts[0].Lender]
        // preserve edits if they were already made
        if (loan != null && loan.estimatedclosingcosts != null) {
            suggested.estimatedclosingcosts = loan.estimatedclosingcosts
        } else {
            suggested.estimatedclosingcosts = this.props.selection.estimatedclosingcosts
        }
        return suggested
    }
    onPriceChange = () => {
        let suggestedLoan = this.findClosestLoan()
        this.setState({ showPriceChanged: true, suggestedLoan })

    }
    filter = () => {
        if (!this.products)
            return
        let types = [...this.state.typeselected]
        let terms = [...this.state.termselected]


        let filteredbyterm = this.products.filter(p => terms.includes(p.Term));

        let filteredbytype = filteredbyterm.filter(p => {
            let typ = p.Arm
            if (typ === null || typeof typ === "undefined")
                typ = "fixed"
            else
                typ = "arm" +  typ.fixedperiod + "6"
            return types.includes(typ)
        })
        // recalculate min and max here!

        let minclosingcost = 10000000
        let maxclosingcost = 0

        filteredbytype.forEach(a => {


            if (a.closing_cost < minclosingcost) {
                minclosingcost = parseFloat(a.closing_cost)
            }
            if (a.closing_cost > maxclosingcost) {
                maxclosingcost = parseFloat(a.closing_cost)
            }
        })
        if (minclosingcost < 0)
            minclosingcost = 0

        this.setState({
            minclosingcost: minclosingcost, maxclosingcost: maxclosingcost,
            //lowclosingcost: minclosingcost, highclosingcost: maxclosingcost,
        })


        let filteredbyclosing = filteredbytype.filter(a => {
            return (this.getClosingCosts(a) >= this.state.lowclosingcost) &&
                (this.getClosingCosts(a) <= this.state.highclosingcost)
        })

        if (this.state.maxreserves > 0) {
            filteredbyclosing = filteredbyclosing.filter(a => {
                return a.min_months_reserves <= this.state.selectedmaxreserves
            })
        }


        filteredbyclosing.sort(this.compare)
        // now need to filter appropriately
        if (this.props.application.hascoborrower === "alone" && false === this.props.application.aloneMarriedWantsFHA) {
            // do not show FHA
            filteredbyclosing = filteredbyclosing.filter(x => {
                return x.FHAMIP == null
            })
        }

        let culled = this.cull(filteredbyclosing)
        //let top = filteredbyclosing.slice(0, 10)

        culled.splice(this.howmany2display)
        let top = culled
        
        if(this.props.property.purpose ===  "cashoutrefinance" && this.props.selection.mincashout > 0) {
            if(parseInt(this.props.selection.cashout) < this.props.selection.mincashout) {
                
                this.setState({
                    errorText: <div className="mb-4 text-center"><h4 className='mt-5'>Minimum cashout size is ${com.commaizeFloat(this.props.selection.mincashout)}.
                    </h4><br />
                        <div style={{ fontSize: '1.5em' }} className="mb-3">Please increase the cashout size</div>
                        </div>, topProducts: []
                })       
                return         
            }
        }
        this.setState({ products:culled,  topProducts: top })

        // if (this.products && this.products.length > 0 && this.state.topProducts.length === 0) {
        //     this.setState({
        //         errorText: <div className="mb-4 text-center"><h4 className='mt-5'>There are no qualifying loan products that fit your filters.
        //         </h4><br />
        //             <div style={{ fontSize: '1.5em' }} className="mb-3">Move the range sliders, change the term/amortization selections, or</div>
        //             <Button onClick={this.resetDefault} > <div style={{ fontSize: '1.4em' }}>Click to reset filters to default </div></Button> </div>
        //     })
        // }

    }

    resetDefault = () => {
        let termselected = [termoptions[0].value]

        let typeselected = []
        typeoptions.forEach(typ => {
            typeselected.push(typ.value)
        })
        this.setState({ typeselected, termselected })
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.processProducts(this.rawproducts)
            this.forceUpdate()
        }, 1000)
    }
    renderProducts = () => {
        return this.state.topProducts.map(this.displayCard)
    }
    onTriangleUpdate = (l1, l2) => {
        l1 = parseFloat(l1)
        l2 = parseFloat(l2)
        this.setState({ weightclosing: l2, weighttotalcost: l1, weightmonthly: Math.max(0.0, 1 - l2 - l1) })

        this.props.updateSelectField(l2, "weightclosing")
        this.props.updateSelectField(l1, "weighttotalcost")
        this.props.updateSelectField(Math.max(0.0, 1 - l2 - l1), "weightmonthly")

        this.filter()
    }

    updateTerms = e => {
        let out = []
        e.forEach(t => {
            out.push(t.id)
        })
        this.props.updateSelectField(out, "loanterms")
    }
    onSelect = (e) => {
        this.state.typeselected = e
        this.setState({typeselected: e})
        this.props.updateSelectField(e, "loanamortizations")
        this.processProducts(this.rawproducts)
        this.forceUpdate()
    }
    onSelectTerms = (e) => {
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.termselected = e
        this.setState({ termselected: e })
        this.props.updateSelectField(e.map(x=>"year"+x), "loanterms")
        this.processProducts(this.rawproducts)
        this.forceUpdate()
    }

    onSelectLenders = (e) => {

        this.setState({ selectedlenders: e }, () => {
            this.updateTerms(e)
            this.processProducts(this.rawproducts)
            this.forceUpdate()
        })
    }


    selectsort = e => {
        this.props.updateSelectField(e, "sortby")
        switch (e) {
            case "selectbyrate":
                this.compare = this.compareRate
                this.cull = this.cullRate
                break
            case "selectbyapr":
                this.compare = this.compareAPR
                this.cull = this.cullAPR
                break
            case "selectbyinterestterm":
                this.compare = this.compareHoldingOwnership
                this.cull = this.cullHoldingOwnership
                break
            case "selectbyinteresttotal":
                this.compare = this.compareTotalOwnership
                this.cull = this.cullTotalOwnership
                break
            case "selectbymonthlypayment":
                this.compare = this.compareMonthly
                this.cull = this.cullMonthly
                break
            case "selectbyclosingcosts":
                this.compare = this.compareMoneyUpfront
                this.cull = this.cullMoneyUpfront
                break

            default:
                break
        }
        this.filter()
        return
    }

    changeYears = e => {
        this.setState({ yearstohold: e })
        this.props.updateApplicationAttribute(com.safeParseInt(e), "keepinghousefor")
        return false
    }
    //--- begin callbacks that cause a reload
    updateDownpayment = e => {
        let dp = e
        if (isNaN(dp))
            return
        this.setState({ downpayment: com.safeParseInt(dp) })
        return false
    }
    changeDownpayment = e => {
        let dp = e
        if (isNaN(dp))
            return

        let ls = this.props.property.salesprice - parseFloat(dp)
        this.setState({ downpayment: dp, loansize: ls })
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.loansize = ls
        this.props.updateSelectField(com.safeParseInt(dp), "downpayment")
        this.props.updateSelectField(ls.toString(), "loansize")
        // no need to do setState here
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.ltv = 100 * (ls) / this.props.property.salesprice

        this.getDTI()
        return false
    }

    changePaydown = e => {
        let dp = e
        if (isNaN(dp))
            dp = 0

        let ls = com.safeParseInt(this.props.application.loanbalance) - parseFloat(dp)
        this.setState({ downpayment: com.safeParseInt(dp), loansize: ls })
        this.props.updateSelectField(com.safeParseInt(dp), "downpayment")
        this.props.updateSelectField(com.safeParseInt(dp), "paydown")
        this.props.updateSelectField(ls.toString(), "loansize")
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.loansize = ls
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.ltv = 100 * (com.safeParseInt(ls) / this.props.property.salesprice)

        this.getDTI()
        return false
    }

    changeCashout = e => {
        let dp = e

        let ls = com.safeParseInt(this.props.application.loanbalance) + parseFloat(dp)
        this.setState({ downpayment: dp, loansize: ls })
        this.props.updateSelectField(ls.toString(), "loansize")
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.ltv = 100 * (ls) / this.props.property.salesprice

        this.props.updateSelectField(com.safeParseInt(dp), "cashout")
        this.getDTI()
        return false
    }

    changeCost = e => {
        let high = e

        if (isNaN(high))
            high = this.state.maxclosingcost
        this.oldmaxupfrontcost = high
        if (high !== this.state.highclosingcost) {
            this.setState({ highclosingcost: high })
        // eslint-disable-next-line react/no-direct-mutation-state
            this.state.highclosingcost = high
        }
        this.props.updateSelectField(high.toString(), "maxupfrontcost")
        this.filter()
    }

    updateReserves = e => {
        let [reserves] = e
        if (this.state.selectedmaxreserves < 0)
            return
        if (isNaN(reserves))
            reserves = this.state.maxreserves
        if (reserves !== this.state.selectedmaxreserves) {
            this.setState({ selectedmaxreserves: reserves })
        // eslint-disable-next-line react/no-direct-mutation-state
            this.state.selectedmaxreserves = reserves
        }

        this.filter()
    }
    changeReserves = e => {
        let [reserves] = e
        if (this.state.selectedmaxreserves < 0)
            return
        if (isNaN(reserves))
            reserves = this.state.maxreserves
        if (reserves !== this.state.selectedmaxreserves) {
            this.setState({ selectedmaxreserves: reserves })
        // eslint-disable-next-line react/no-direct-mutation-state
            this.state.selectedmaxreserves = reserves
        }

        this.filter()
    }

    removeSelection(hash) {
        let comparisonSelections = this.state.comparisonSelections
        delete comparisonSelections[hash]

        this.setState({ comparisonSelections })
    }
    selectLoan(hash) {
        let ob = this.referi[hash][0]
        let loan = new ChosenLoan()
        loan.hash = hash

        loan.APR = ob.Rate.APR
        if (ob.Rate.Arm !== null)
            loan.Arm = ob.Rate.Arm
        loan.amortization = ob.Rate.MortgageAmortization
        loan.Mtype = ob.Rate.Mtype
        loan.Term = ob.Rate.Term
        loan.base_rate = ob.Rate.base_rate
        loan.closing_cost = ob.Rate.closing_cost
        loan.features = ob.Rate.features
        loan.high_cost_mortgage = ob.Rate.high_cost_mortgage
        loan.lender_credit = ob.Rate.lender_credit
        loan.lender_fee = ob.Rate.lender_fee
        loan.lockDay = ob.Rate.lockDay
        loan.min_months_reserves = ob.Rate.min_months_reserves
        loan.monthly_payment = ob.Rate.monthly_payment
        loan.prepaid_interest = ob.Rate.prepaid_interest
        loan.points = ob.Rate.points
        loan.pmi = ob.Rate.PMICost
        loan.FHAMIP = ob.Rate.FHAMIP
        loan.DTI = ob.Rate.DTI
        loan.matchingproducts = []
        loan.loansize = this.state.loansize
        loan.purpose = this.props.property.purpose
        loan.ourfee = this.zeitro_fee
        loan.prepaiddays = this.prepaid_interest_days
        
        if(ob.Rate.max_cashout != null) {
            loan.maxcashout = ob.Rate.max_cashout
        }
        // preserve edits if they were already made
        if (this.props.selection.selectedloan != null && this.props.selection.selectedloan.estimatedclosingcosts != null) {
            loan.estimatedclosingcosts = this.props.selection.selectedloan.estimatedclosingcosts
        } else {
            loan.estimatedclosingcosts = this.props.selection.estimatedclosingcosts
        }
        let cc = loan.estimatedclosingcosts
        if (loan.FHAMIP != null) {

            let addFha = () => {
                let found = false

                cc.cannotshopservices.forEach(key => {
                    if (key.servicename === com.upfrontMI) {
                        key.estimateamount = loan.FHAMIP.upfrontMIP
                        found = true
                    }
                })
                if (!found) {
                    let s = new service()
                    s.servicename = com.upfrontMI
                    s.estimateamount = loan.FHAMIP.upfrontMIP
                    cc.cannotshopservices.push(s)
                }
            }
            addFha()
        } else {

            for (let i = 0; i < cc.cannotshopservices.length; i++) {
                if (cc.cannotshopservices[i].servicename === com.upfrontMI) {
                    cc.cannotshopservices = cc.cannotshopservices.splice(i, 1)
                    break
                }
            }
        }

        switch (this.props.property.purpose) {
            case "purchase":
                loan.downpayment = this.state.downpayment
                break;
            case "refinance":
                loan.paydown = this.state.downpayment
                break;
            case "cashoutrefinance":
                loan.cashout = this.state.downpayment
                break;
            default:
                break;
        }

        loan.aus = ob.Aus

        this.referi[hash].forEach(
            pr => {
                let l = new LoanProduct()

                l.Lender = pr.Lender
                l.Name = pr.Name
                l.lenderinfo = pr.LenderInfo
                l.aus = pr.Aus
                loan.matchingproducts.push(l)
            }
        )
        
        if (!this.props.application.selection.loSelectedLoans.some(l => l.hash === loan.hash)) {
            this.props.updateSelectField([...this.props.application.selection.loSelectedLoans, loan], "loSelectedLoans");
        }
    }
    notifyBorrower() {
        let token = sessionStorage.getItem("ZeitroA")
        const pathSegments = window.location.pathname.split('/');
        const loanid = pathSegments[pathSegments.length - 1];

        fetch('/agent/notifyborroweraboutchosenrate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
                "X-Loan": loanid,
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return;
                } 
            }
        ).catch((err) => {
            console.log(err)
        });
    }
    recommendRates() {
        let finalSelected = []
        for (let rate of this.state.ratesToBeRecommended) {
            let hash = rate.hash
            let ob = this.referi[hash][0]
            let loan = new ChosenLoan()
            loan.hash = hash
    
            loan.APR = ob.Rate.APR
            if (ob.Rate.Arm !== null)
                loan.Arm = ob.Rate.Arm
            loan.amortization = ob.Rate.MortgageAmortization
            loan.Mtype = ob.Rate.Mtype
            loan.Term = ob.Rate.Term
            loan.base_rate = ob.Rate.base_rate
            loan.closing_cost = ob.Rate.closing_cost
            loan.features = ob.Rate.features
            loan.high_cost_mortgage = ob.Rate.high_cost_mortgage
            loan.lender_credit = ob.Rate.lender_credit
            loan.lender_fee = ob.Rate.lender_fee
            loan.lockDay = ob.Rate.lockDay
            loan.min_months_reserves = ob.Rate.min_months_reserves
            loan.monthly_payment = ob.Rate.monthly_payment
            loan.prepaid_interest = ob.Rate.prepaid_interest
            loan.points = ob.Rate.points
            loan.pmi = ob.Rate.PMICost
            loan.FHAMIP = ob.Rate.FHAMIP
            loan.DTI = ob.Rate.DTI
            loan.matchingproducts = []
            loan.loansize = this.state.loansize
            loan.purpose = this.props.property.purpose
            loan.ourfee = this.zeitro_fee
            loan.prepaiddays = this.prepaid_interest_days
            
            if(ob.Rate.max_cashout != null) {
                loan.maxcashout = ob.Rate.max_cashout
            }
            // preserve edits if they were already made
            if (this.props.selection.selectedloan != null && this.props.selection.selectedloan.estimatedclosingcosts != null) {
                loan.estimatedclosingcosts = this.props.selection.selectedloan.estimatedclosingcosts
            } else {
                loan.estimatedclosingcosts = this.props.selection.estimatedclosingcosts
            }
            let cc = loan.estimatedclosingcosts
            if (loan.FHAMIP != null) {
    
                let addFha = () => {
                    let found = false
    
                    cc.cannotshopservices.forEach(key => {
                        if (key.servicename === com.upfrontMI) {
                            key.estimateamount = loan.FHAMIP.upfrontMIP
                            found = true
                        }
                    })
                    if (!found) {
                        let s = new service()
                        s.servicename = com.upfrontMI
                        s.estimateamount = loan.FHAMIP.upfrontMIP
                        cc.cannotshopservices.push(s)
                    }
                }
                addFha()
            } else {
    
                for (let i = 0; i < cc.cannotshopservices.length; i++) {
                    if (cc.cannotshopservices[i].servicename === com.upfrontMI) {
                        cc.cannotshopservices = cc.cannotshopservices.splice(i, 1)
                        break
                    }
                }
            }
    
            switch (this.props.property.purpose) {
                case "purchase":
                    loan.downpayment = this.state.downpayment
                    break;
                case "refinance":
                    loan.paydown = this.state.downpayment
                    break;
                case "cashoutrefinance":
                    loan.cashout = this.state.downpayment
                    break;
                default:
                    break;
            }
    
            loan.aus = ob.Aus
    
            this.referi[hash].forEach(
                pr => {
                    let l = new LoanProduct()
    
                    l.Lender = pr.Lender
                    l.Name = pr.Name
                    l.lenderinfo = pr.LenderInfo
                    l.aus = pr.Aus
                    loan.matchingproducts.push(l)
                }
            )

            finalSelected.push(loan)
        }

        
        const combinedSelected = finalSelected.concat(this.props.application.selection.loSelectedLoans)
        // remove duplicates
        const uniqueArray = combinedSelected.filter((item, index, array) =>
            array.findIndex(obj => obj.hash === item.hash) === index
        );
        this.props.updateSelectField(uniqueArray, "loSelectedLoans");


        this.setState({ showRecommendRatesModal: true, ratesToBeRecommended: [] })
        this.notifyBorrower()
    }
    selectCard = card => {
        com.touch()
        this.selectLoan(card.hash)
    }

    getOldInterestRate = () => {

        if (this.props.application.property.purpose !== st.POL_Refinance)
            return
        // just scan all the loans for rate
        // first regular loans from credit report
        let rate = 0
        for (let i = 0; i < this.props.application.assetsandliabilities.loans.length; i++) {
            if (this.props.application.assetsandliabilities.loans[i].forsubjectproperty &&
                this.props.application.assetsandliabilities.loans[i].satisfiedupon === "withproceeds") {
                rate = this.props.application.assetsandliabilities.loans[i].rate
            }
        }

        if (0 === rate) {
            if (this.props.application.otherliens !== null) {
                for (let i = 0; i < this.props.application.otherliens.length; i++) {
                    if (this.props.application.otherliens[i].satisfiedupon === "withproceeds") {
                        rate = this.props.application.otherliens[i].rate
                    }
                }
            }
        }
        return rate
    }
    displayCard = (card, index) => {

        let currentMonthly = com.getMonthlyValue(this.props.application.borrower.expenses.currentfirstmortgage,
            this.props.application.borrower.expenses.currentfirstmortgageperiod)
        let mostyle = {}
        if (card.monthly_payment > parseFloat(currentMonthly)) {
            mostyle = { color: '#c33' }
        } else {
            mostyle = { color: '#393' }
        }
        let showSavings = () => {

            if (this.props.property.purpose !== "refinance")
                return ""
            if (card.monthly_payment > parseFloat(currentMonthly)) {
                return <i className="ml-1 fas fa-arrow-up"></i>
            }
            return <i className="ml-1 fas fa-arrow-down"></i>
        }

        if (this.props.selection.selectedloan !== null && (this.props.selection.selectedloan.hash === card.hash))
            return ""
        let getName = () => {
            if (this.props.borrowerid && this.referi && this.referi[card.hash] != null) {
                let r = this.referi[card.hash][0]
                return r["Lender"] + ", " + r["Name"]
            }
            return com.capitalize(card.Mtype.replace('_', ' ').toLowerCase().replace('fha', 'FHA'))
        }
        let getType = () => {
            let tp = ""
            if (card.Arm == null )
                tp = "Fixed rate"
            else
                tp = "ARM " + card.Arm.fixedperiod + "/6"
            return tp
        }

        let selectCard = e => { return this.selectCard(card) }
        let fingerclass = "fas fa-hand-point-left mr-1"
        if (index === 0)
            fingerclass += " fingerclass"
        let points = com.commaizeFloat((card.points * com.safeParseInt(this.state.loansize) / 100).toFixed(2))
        let moPayment = () => {
            let payment = card.monthly_payment
            if (card.PMICost !== 0) {
                payment = payment + card.PMICost
            } else if (card.FHAMIP != null) {
                payment = payment + card.FHAMIP.monthlyMIP
            }
            payment = com.commaizeFloat(payment.toFixed(2))
            return payment
        }

        let getBreakPoint = (loan) => {
            let interestRate = this.getOldInterestRate()

            function getClosedInterestPaid(rate, loansize, termmonths, spanmonths) {

                let monthlyrate = rate / 12.0
                let expn = Math.pow(1 + monthlyrate, termmonths)
                let payment = loansize * monthlyrate * expn / (expn - 1)
                payment = (Math.round(payment * 100) / 100)

                let accumulatedinterest = (loansize * monthlyrate - payment) *
                    (Math.pow(1 + monthlyrate, spanmonths) - 1) / monthlyrate +
                    spanmonths * payment
                return accumulatedinterest
            }

            let breakPoint = -1
            if (parseFloat(interestRate) <= loan.base_rate) {
                return breakPoint
            }

            for (let i = 1; i <= loan.Term * 12; i++) {
                let accumulatedOld = getClosedInterestPaid(parseFloat(interestRate) / 100, this.state.loansize, loan.Term * 12, i)
                let accumulatedNew = getClosedInterestPaid(loan.base_rate / 100, this.state.loansize, loan.Term * 12, i)

                accumulatedNew += loan.closing_cost
                if (accumulatedNew < accumulatedOld) {
                    breakPoint = i
                    break
                }
            }
            return breakPoint
        }

        return (
            <Container key={card.hash} className="drop-shadow w-100 px-0 text-left pb-3">
                <Row className="bluishbackground text-left mt-3 mb-0 mx-0 py-2">
                    {/* <Col style={{alignItems:"center", paddingRight: '0px' }} xs="auto" className="d-flex text-center vertical-middle mx-0 ">
                        <Form.Group controlId="emailmethod" className="text-center d-block text-nowrap m-0 p-0" >
                            <Form.Check type="checkbox"
                                checked={this.state.comparisonSelections[card.hash] != null}
                                key={card.hash}
                                disabled={this.state.comparisonSelections[card.hash] == null && Object.keys(this.state.comparisonSelections).length >= 4}
                                onChange={oncheckbox}
                                style={{ textAlign: 'center', paddingRight: '0px', paddingLeft: '22px' }}
                                //className="p-0 m-0"
                                label=""
                            ></Form.Check>
                        </Form.Group>
                    </Col> */}
                    <Col style={{ alignItems:"center", fontWeight: 500 }} className="d-flex text-left ">
                        {getName()}
                        {this.props.borrowerid ? "" : <div>, {getType()}, {card.Term} years </div>}
                    </Col>
                    <Col xs={"auto"} className="text-right align-top loancard" >
                        <div className='d-flex'>
                        <Button size="sm" onClick={selectCard} variant="outline"><b>Choose rate</b></Button>
                        <Button size="sm" variant="link" className='pr-0 ml-0' onClick={() => {
                                    // eslint-disable-next-line react/no-direct-mutation-state
                            this.state.detailedCard = card
                            this.setState({ detailedCard: card, show: true })
                        }
                        }>Cost Breakdown</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="text-left mt-0 mb-0 mx-0">

                    <Col xs={3} className=" loancard pt-2 ">
                        <div>Rate / APR</div>
                        <div>{card.base_rate}% / {card.APR}%</div>
                    </Col>
                    <Col xs={3} className=" loancard pt-2 ">
                        <div>Mo. Payment</div>
                        {this.props.property.purpose === "refinance" ? MonhtlyChangeBP(currentMonthly, card.monthly_payment, getBreakPoint(card),
                            <span style={mostyle} ><b>${moPayment()}</b>{showSavings()}</span>
                        ) : <b>${moPayment()}</b>}                    </Col>
                    <Col xs={3} className=" loancard pt-2 ">
                        <div>Estimated Closing Cost</div>
                        <b>${com.commaizeFloat((this.getClosingCosts(card)).toFixed(2))}</b>
                    </Col>
                    <Col xs={3} className=" loancard pt-2 ">
                        <div>Points</div>
                        {com.commaizeFloat(card.points.toFixed(3))}%{card.points <= 0 ? "" : <span>/${points}</span>}
                    </Col>
                </Row>
                <Row className="text-left mt-2 mb-0 mx-0 d-flow">

                    <Col className=" d-flow loancard" style={{ color: "#6E6E70" }}>
                        {card.PMICost !== 0 ? <span className="mr-3">Requires mortgage insurance ${com.commaizeFloat(card.PMICost)}/mo</span> : ""}

                        {com.safeParseInt(card.min_months_reserves) > 0 ?
                            <span className=" py-2  mr-3">
                                Reserves required: {card.min_months_reserves} months
                            </span> : ""}

                        {card.FHAMIP != null ?
                            <span className=" py-2  mr-3">
                                Upfront mortgage insurance premium: ${card.FHAMIP.upfrontMIP}, monthly insurance payment: ${com.commaizeFloat(card.FHAMIP.monthlyMIP)}
                            </span>
                            : ""}
                    </Col>

                </Row>

            </Container>
        )
    }

    printDownpayment = () => {
        let getPercent = () => {
            if (this.props.property.purpose === "purchase") {
                return Math.floor(100 * (parseFloat(this.props.property.salesprice)-parseFloat(this.state.downpayment)) / parseFloat(this.props.property.salesprice))
            } else if (this.props.property.purpose === "cashoutrefinance") {
                return Math.ceil(100 * (parseFloat(this.props.property.appraisal)+parseFloat(this.state.downpayment)-com.safeParseInt(this.props.application.loanbalance)) / parseFloat(this.props.property.appraisal))
            }  else {
                return Math.ceil(100 * (parseFloat(this.props.property.appraisal)-parseFloat(this.state.downpayment)-com.safeParseInt(this.props.application.loanbalance)) / parseFloat(this.props.property.appraisal))
            }
        }
        
        let purchase = () => {

            return <div>
            <div className='d-flex align-items-center '>
                <div  className='title-5'>Purchase Price:</div>
                <div className='bold ml-1 mr-3'>${com.commaize(this.props.property.salesprice)}</div>
                <div  className='title-5'>Loan amount:</div>
                <div className='bold ml-1 mr-3'>${com.commaize(this.props.property.salesprice - this.state.downpayment)}</div>
                <div  className='title-5'>LTV:</div>
                <div className='bold ml-1 mr-3'>{getPercent()}%</div>
            </div>
            <div className='productPickerFiltersWrap'>
                <div className="productPickerFiltersSelect">{filterAmortization()}</div>
                <div className="productPickerFiltersSelect">{filterLoanTerms()}</div>
                <div className="productPickerFiltersSelect">{filterLenders()}</div>
                <div className="productPickerFiltersScroll">
                    <div className='filterTitle'>Down Payment</div>
                    <ScrollBar scrollChangeValue={this.changeDownpayment} step={1000} min={0} max={com.safeParseInt(this.props.property.salesprice)}  initVal={this.state.defdownpayment} />
                </div>
                {maxClosingCosts()}
            </div>
            {/* <Button variant="primary" className="lpbutton" size="sm" onClick={this.reset}>Reset</Button> */}
        </div>
            

        }
        let refinance = () => {
            return <div>
            <div className='d-flex align-items-center '>
                <div  className='title-5'>Current balance:</div>
                <div className='bold ml-1 mr-3'>${com.commaize(this.props.application.loanbalance)}</div>
                <div  className='title-5'>Loan amount:</div>
                <div className='bold ml-1 mr-3'>${com.commaize(com.safeParseInt(this.props.application.loanbalance) - this.state.downpayment)}</div>
                <div  className='title-5'>LTV:</div>
                <div className='bold ml-1 mr-3'>{getPercent()}%</div>
            </div>
            <div className='productPickerFiltersWrap'>
                <div className="productPickerFiltersSelect">{filterAmortization()}</div>
                <div className="productPickerFiltersSelect">{filterLoanTerms()}</div>
                <div className="productPickerFiltersSelect">{filterLenders()}</div>
                <div className="productPickerFiltersScroll">
                    <div className='filterTitle'>Paydown principal</div>
                    <ScrollBar scrollChangeValue={this.changePaydown} step={1000} min={0} max={com.safeParseInt(this.props.application.loanbalance)}  initVal={this.state.defdownpayment} />
                </div>
                {maxClosingCosts()}
            </div>
        </div>
        }
        let cashout = () => {
            return <div>
            <div className='d-flex align-items-center '>
                <div  className='title-5'>Current balance:</div>
                <div className='bold ml-1 mr-3'>${com.commaize(this.props.application.loanbalance)}</div>
                <div  className='title-5'>Loan amount:</div>
                <div className='bold ml-1 mr-3'>${com.commaize(com.safeParseInt(this.props.application.loanbalance) + this.state.downpayment)}</div>
                <div  className='title-5'>LTV:</div>
                <div className='bold ml-1 mr-3'>{getPercent()}%</div>
            </div>
            <div className='productPickerFiltersWrap'>
                <div className="productPickerFiltersSelect">{filterAmortization()}</div>
                <div className="productPickerFiltersSelect">{filterLoanTerms()}</div>
                <div className="productPickerFiltersSelect">{filterLenders()}</div>
                <div className="productPickerFiltersScroll">
                    <div className='filterTitle'>Cash out</div>
                    <ScrollBar scrollChangeValue={this.changeCashout} step={1000} min={0} max={com.safeParseInt(this.props.property.appraisal - this.props.application.loanbalance)}  initVal={this.state.defdownpayment} />
                </div>
                {maxClosingCosts()}
                </div>
            </div>
        }

        let filterAmortization = () => {
            return <div>
                    <div className='filterTitle'>Amortization</div>
                    <div><Select 
                        mode='multiple'
                        style={{width:"100%", height:52}}
                        maxTagCount='responsive'
                        onChange={this.onSelect}
                        value={this.state.typeselected}
                        options={typeoptions}
                    /> </div>
                </div>
        }
        let filterLoanTerms = () => {
            return <div>
                    <div className='filterTitle'>Loan Terms</div>
                    <div><Select 
                        mode='multiple'
                        style={{width:"100%", height:52}}
                        maxTagCount='responsive'
                        onChange={this.onSelectTerms}
                        value={this.state.termselected}
                        options={termoptions}
                    /> </div>
                </div>
        }
        let filterLenders = () => {

            return <div>
                    <div className='filterTitle'>Lenders</div>
                    <div><Select 
                        mode='multiple'
                        style={{width:"100%", height:52}}
                        maxTagCount='responsive'
                        onChange={this.onSelectLenders}
                        value={this.state.selectedlenders}
                        options={this.state.lenders.map(x=>{return {value:x, label:com.capitalize(x)}})}
                    /> </div>
                </div>
        }

        let maxClosingCosts = () => {
            return <div className="productPickerFiltersScroll">
                <div className='filterTitle'>Max Closing Costs</div>
                <ScrollBar  scrollChangeValue={this.changeCost} step={1000} min={this.state.minclosingcost} max={this.state.maxclosingcost} initVal={this.state.highclosingcost} />
            </div>
        }

        switch (this.props.property.purpose) {
            case "purchase":
                return purchase()
            case "refinance":
                return refinance()
            case "cashoutrefinance":
                return cashout()
            default:
                alert("should not see this!")
                return "";
        }
    }
    getClosingCosts = (card) => {

        let cc = card.closing_cost

        if (cc < 0)
            cc = 0
        return cc
    }
    deleteSelection = () => {
        com.touch()
        this.props.updateSelectField(new ChosenLoan(), "selectedloan")
    }

    displaySelectedCard = (loan) => {
        let getName = (card) => {
            if (this.props.borrowerid && this.referi && this.referi[card.hash] != null) {
                let r = this.referi[card.hash]
                if (r !== undefined && r[0] !== undefined)
                    return com.capitalize(r[0]["Lender"]) + ", " + r[0]["Name"]
            }
            return com.capitalize(card.Mtype.replace('_', ' ').toLowerCase().replace('fha', 'FHA'))
        }
        let removeLOSelectedRate = (loan) => {
            let loSelectedLoans = this.props.application.selection.loSelectedLoans.filter(l => l.hash !== loan.hash)
            this.props.updateSelectField(loSelectedLoans, "loSelectedLoans")
        }
        let isSelectedByBorrower = (loan) => {
            return this.props.application.selection.selectedloan.hash === loan.hash
        }
        return <div className={isSelectedByBorrower(loan) ? "chosenRateWrap" : "loSelectedRateWrap"}>
            <div style={{width: 50, textAlign:"center"}}><img src='/images/selectedRateIcon.png' style={{width:32}}/></div>
            <div style={{flexGrow: 1}}>
                <div className='bottomBorder pb-2'>
                    <div className='d-flex  align-items-center justify-content-between'>
                        <div className='bold'>{getName(loan)}</div>
                        <div className='d-flex'>
                            {isSelectedByBorrower(loan) && <>
                                <a ref={this.downloadDTIspreadsheetRef} hidden href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${this.state.DTISpreadsheet}`} download={this.props.borrower.fullname +  " DTI Spreadsheet"} >&nbsp;</a>
                            <Button type='secondary' onClick={this.generateDTISpreadsheet} loading={this.state.dtiSpreadsheetLoading} >DTI Spreadsheet</Button>
                            </>}
                            <Button type='link-delete' onClick={()=>removeLOSelectedRate(loan)}>Remove choice</Button>
                        </div>
                    </div>
                    {isSelectedByBorrower(loan) && <div style={{color:"#1F3988", fontWeight:500}}><img src='/images/checkedCircle.png' style={{width:18, marginRight:3}}/>Borrower's Preferred Rate</div>}
                </div>
                <div className='d-flex justify-content-between pt-2'>
                    <div>
                        <div className='title-5'>Rate/APR</div>
                        <div className='bold'>{loan.base_rate}%/{loan.APR}%</div>
                    </div>
                    <div>
                        <div className='title-5'>Mo. payment</div>
                        <div className='bold'>${com.commaize(loan.monthly_payment)}</div>
                    </div>
                    <div>
                        <div className='title-5'>Points</div>
                        <div className='bold'>{loan.points}%/${com.commaizeFloat(loan.lender_credit)}</div>
                    </div>
                    <div>
                        <div className='title-5'>Est. closing costs</div>
                        <div className='bold'>${com.commaize(loan.closing_cost)}</div>
                    </div>
                </div>
            </div>
        </div>
    }

    displaySelectedCards = () => {
        if (this.props.application.selection.loSelectedLoans.length === 0) {
            return <div className='recommendRatesNoRatesTip'>
                You have not selected any rates to recommend to your borrower yet. You can select them below.
            </div>
        }

        return <div>
            {(this.props.application.selection.selectedloan.hash !== "") && <div className='recommendRatesNoRatesTip mb-2'>
                Your borrower has selected their preferred recommended rate.
            </div>}
            {this.props.application.selection.loSelectedLoans.map(loan => this.displaySelectedCard(loan))}
        </div>

        if (!isMobileOnly && (this.props.selection.selectedloan === null || this.props.selection.selectedloan.hash === "")) {
            return ""
        }

        let card = this.props.selection.selectedloan
        let currentMonthly = com.getMonthlyValue(this.props.application.borrower.expenses.currentfirstmortgage,
            this.props.application.borrower.expenses.currentfirstmortgageperiod)
        let mostyle = {}
        if (card.monthly_payment > parseFloat(currentMonthly)) {
            mostyle = { color: '#c33' }
        } else {
            mostyle = { color: '#393' }
        }
        let showSavings = () => {
            if (this.props.property.purpose !== "refinance")
                return ""
            if (card.monthly_payment > parseFloat(currentMonthly)) {
                return <i className="ml-1 fas fa-arrow-up"></i>
            }
            return <i className="ml-1 fas fa-arrow-down"></i>
        }
        let getName = () => {
            if (this.props.borrowerid && this.referi && this.referi[card.hash] != null) {
                let r = this.referi[card.hash]
                if (r !== undefined && r[0] !== undefined)
                    return com.capitalize(r[0]["Lender"]) + ", " + r[0]["Name"]
            }
            return com.capitalize(card.Mtype.replace('_', ' ').toLowerCase().replace('fha', 'FHA'))
        }
        let getType = (card) => {
            if (null == card)
                return ""
            if (card.Arm == null || card.amortization === "fixed")
                return "Fixed rate"
            return "ARM " + card.Arm.fixedperiod + "/6"
        }
        let loansize = com.safeParseInt(card.loansize)


        let points = com.commaizeFloat((card.points * com.safeParseInt(card.loansize) / 100).toFixed(2))
        let moPayment = () => {

            let payment = card.monthly_payment
            if (card.pmi !== 0) {
                payment = payment + card.pmi
            } else if (card.FHAMIP != null) {
                payment = payment + card.FHAMIP.monthlyMIP
            }
            payment = com.commaizeFloat(payment.toFixed(2))
            return payment
        }

        let getBreakPoint = (loan) => {
            let interestRate = this.getOldInterestRate()

            function getClosedInterestPaid(rate, loansize, termmonths, spanmonths) {

                let monthlyrate = rate / 12.0
                let expn = Math.pow(1 + monthlyrate, termmonths)
                let payment = loansize * monthlyrate * expn / (expn - 1)
                payment = (Math.round(payment * 100) / 100)

                let accumulatedinterest = (loansize * monthlyrate - payment) *
                    (Math.pow(1 + monthlyrate, spanmonths) - 1) / monthlyrate +
                    spanmonths * payment
                return accumulatedinterest
            }

            let breakPoint = -1
            if (parseFloat(interestRate) <= loan.base_rate) {
                return breakPoint
            }

            for (let i = 1; i <= loan.Term * 12; i++) {
                let accumulatedOld = getClosedInterestPaid(parseFloat(interestRate) / 100, loan.loansize, loan.Term * 12, i)
                let accumulatedNew = getClosedInterestPaid(loan.base_rate / 100, loan.loansize, loan.Term * 12, i)

                accumulatedNew += loan.closing_cost
                if (accumulatedNew < accumulatedOld) {
                    breakPoint = i
                    break
                }
            }
            return breakPoint
        }
        let image = this.state.selectedImage
        let onHidePriceChanged = () => {
            this.setState({ showPriceChanged: false })
        }
        let useSuggestion = () => {
            this.props.updateSelectField(this.state.suggestedLoan, "selectedloan")
            this.getSelectedIcon(this.state.suggestedLoan)
            this.setState({ showPriceChanged: false })
        }

        let oncheckbox = (e) => {
            let ch = e.target.checked
            let ar = this.state.comparisonSelections
            if (ar[card.hash] != null)
                delete ar[card.hash]

            if (ch) {
                ar[card.hash] = this.referi[card.hash]
                ar[card.hash].loansize = card.loansize
                ar[card.hash].paydown = card.paydown
                ar[card.hash].downpayment = card.downpayment
                ar[card.hash].cashout = card.cashout
            }
            this.setState({ comparisonSelections: ar })
        }
        let st = {}
        try {
            if (this.state.suggestedLoan.closing_cost > this.props.application.selection.selectedloan.closing_cost) {
                st = { color: '#633' }
            } else {
                if (this.state.suggestedLoan.closing_cost < this.props.application.selection.selectedloan.closing_cost) {
                    st = { color: '#363' }
                }
            }
        } catch (x) {

        }

        return <div className="mb-4" key={card.hash}>
            <div style={{ fontSize: '1.2em', fontWeight: 500 }} className="mb-1 pb-0">Chosen Rate: ${com.commaizeFloat(loansize)}</div>

            <Modal show={this.state.showPriceChanged} onHide={onHidePriceChanged} >
                <Modal.Header closeButton>
                    <Modal.Title className="capitalize">The closing cost of your selected loan has changed!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.suggestedLoan != null ?
                        <div>
                            <Row><Col className="mb-3 sectionquestion text-center">Here's a new suggested loan: </Col> </Row>
                            <Row><Col>Loan type:</Col><Col>{getType(this.state.suggestedLoan)},  {this.state.suggestedLoan.Term} years</Col> </Row>


                            <Row><Col>Loan amount:</Col><Col> ${com.commaizeFloat(this.state.suggestedLoan.loansize)}</Col> </Row>
                            <Row><Col>Base rate: </Col><Col>{this.state.suggestedLoan.base_rate}%</Col> </Row>
                            {this.state.suggestedLoan.base_rate !== this.props.application.selection.selectedloan.base_rate ?
                                <Row><Col>Old rate: </Col><Col>{this.props.application.selection.selectedloan.base_rate}% </Col> </Row>
                                : ""
                            }
                            <Row><Col>APR:</Col><Col> {this.state.suggestedLoan.APR}%</Col> </Row>
                            <Row><Col>Monthly payment: </Col><Col>  ${com.commaizeFloat(this.state.suggestedLoan.monthly_payment)}</Col> </Row>
                            {this.state.suggestedLoan.closing_cost >= 0 ?
                                <Row><Col>Closing costs:</Col><Col style={st}>  ${com.commaizeFloat(this.state.suggestedLoan.closing_cost.toFixed(2))}</Col> </Row>
                                :
                                <Row><Col>Closing costs: $0</Col><Col style={st} >Remaining credits: ${com.commaizeFloat(-this.state.suggestedLoan.closing_cost.toFixed(2))}
                                </Col> </Row>
                            }
                            {this.state.suggestedLoan.closing_cost !== this.props.application.selection.selectedloan.closing_cost ?
                                (this.props.application.selection.selectedloan.closing_cost >= 0 ?
                                    <Row><Col> Old closing costs:</Col><Col> ${com.commaizeFloat(this.props.application.selection.selectedloan.closing_cost.toFixed(2))} </Col> </Row> :
                                    <Row><Col>  Old remaining credits:</Col><Col> ${com.commaizeFloat(this.props.application.selection.selectedloan.closing_cost.toFixed(2))} </Col> </Row>
                                )
                                : ""
                            }

                        </div>
                        : ""
                    }
                </Modal.Body>
                <Modal.Footer className="text-center d-block" >
                    <Row className="text-center">
                        <Col>
                            <Button variant="zeitro-primary" onClick={useSuggestion}>Choose suggestion</Button>
                        </Col>
                        <Col>
                            <Button variant="zeitro-outline" onClick={e => { this.deleteSelection(); onHidePriceChanged() }}>Remove chosen</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Container key={card.hash} className="loanRates mt-2 w-100 px-0 pb-3 text-left">
                <Row className="bluishbackground text-left  mb-0 mx-0">
                    {/* <Col style={{ paddingRight: '0px', alignItems:"center" }} xs="auto" className="d-flex text-center vertical-middle mx-0 ">
                        {this.state.needsAdjustment ? "" :
                            <Form.Group controlId="emailmethod" className="text-center d-block text-nowrap m-0 p-0" >
                                <Form.Check type="checkbox"
                                    checked={this.state.comparisonSelections[card.hash] != null}
                                    key={card.hash}
                                    disabled={this.state.comparisonSelections[card.hash] == null && Object.keys(this.state.comparisonSelections).length >= 4}
                                    onChange={oncheckbox}
                                    style={{ textAlign: 'center', paddingRight: '0px', paddingLeft: '22px' }}
                                    //className="p-0 m-0"
                                    label=""
                                ></Form.Check>
                            </Form.Group>}
                    </Col> */}
                    <Col className="d-flex text-left  ">
                        <div className="d-flex text-left " style={{ alignItems:"center", fontWeight: 500 }}>
                            {getName()}
                            {this.props.borrowerid ? "" : <div>, &nbsp;{getType(card)}, {card.Term} years</div>}
                        </div>
                    </Col>
                    <Col xs="auto" className="text-right border-bottom loancard p-0 py-1" >
                        <a ref={this.downloadDTIspreadsheetRef} hidden href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${this.state.DTISpreadsheet}`} download={this.props.borrower.fullname +  " DTI Spreadsheet"} >&nbsp;</a>
                        <Button variant='outline' style={{fontSize: 14, minWidth: 136}} onClick={this.generateDTISpreadsheet}>{this.state.dtiSpreadsheetLoading ? <Spinner animation='border' size='sm'/> : "DTI Spreadsheet"}</Button>
                        {this.state.needsAdjustment ?
                            <Button onClick={this.onPriceChange} size="sm" variant="link" style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '0px', paddingBottom: '0px' }}>Click to update the loan</Button>
                            :
                            <Button onClick={this.deleteSelection} size="sm" variant="link">Remove choice</Button>
                        }
                    </Col>
                </Row>
                <Row className="text-left mt-0 mb-0 mx-0">

                    <Col xs={3} className="loancard pt-2 ">
                        <div>Rate / APR</div>
                        <b>{card.base_rate}% / {card.APR}%</b>
                    </Col>
                    <Col xs={3} className=" loancard pt-2 ">
                        <div>Mo. Payment</div>
                        {this.props.property.purpose === "refinance" ? MonhtlyChangeBP(currentMonthly, card.monthly_payment, getBreakPoint(card),
                            <span style={mostyle} ><b>${moPayment()}</b>{showSavings()}</span>
                        ) : <b>${moPayment()}</b>}
                    </Col>
                    <Col xs={3} className=" loancard pt-2 ">
                        <div>Closing Cost</div>
                        <b>${com.commaizeFloat((this.getClosingCosts(card)).toFixed(2))}</b>
                    </Col>

                    <Col xs={3} className=" loancard pt-2 ">
                        <div> Points</div>
                        {com.commaizeFloat(card.points.toFixed(3))}%{card.points <= 0 ? "" : <span>/${points}</span>}
                    </Col>
                </Row>
                <Row className="text-left text-wrap mt-2 mb-0 mx-0 ">
                    <Col className=" d-flow loancard" style={{ color: "#6E6E70" }}>
                        {card.pmi !== 0 ? <span className="mr-3">Requires mortgage insurance ${com.commaizeFloat(card.pmi)}/mo</span> : ""}

                        {com.safeParseInt(card.min_months_reserves) > 0 ?
                            <span className=" py-2  mr-3">
                                Reserves required: {card.min_months_reserves} months
                            </span> : ""}
                        {card.FHAMIP != null ?
                            <span className=" py-2  mr-3">
                                Upfront mortgage insurance premium: ${card.FHAMIP.upfrontMIP}, monthly insurance payment: ${com.commaizeFloat(card.FHAMIP.monthlyMIP)}
                            </span>
                            : ""}
                    </Col>

                </Row>
            </Container>

        </div>

    }

    displayFilters = () => {
        
    return <div>
        <div >
            {this.printDownpayment()}
        </div>

        {/* {this.state.maxreserves > 0 ?
            <div>
                <Row className="mt-2 mb-1"  ><Col style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Limit reserves:</Col><Col>

                    <div className="downpayment" style={{ fontSize: '0.9em', width: '110px !important' }} >

                        <div style={{ paddingTop: '3px', width: '110px !important' }} type="text" size="10"
                        >
                            {this.state.selectedmaxreserves} mo.</div>


                    </div>

                </Col></Row>
                <Row>
                    <Col><HorizontalSlider onUpdate={this.updateReserves} onChange={this.changeReserves} step={1} domain={[0, this.state.maxreserves]} values={[this.state.selectedmaxreserves]} width="240px" style={{ width: "240px" }} /></Col>
                </Row>
            </div> : ""} */}

    </div>

    }

    displayRates = () => {
        let getName = (card) => {
            if ( this.referi && this.referi[card.hash] != null) {
                let r = this.referi[card.hash][0]
                return <div>
                    <div className='bold'>{r["Lender"]}</div>
                    <div className='title-5'>{r["Name"]}</div>
                </div>
            }
            return com.capitalize(card.Mtype.replace('_', ' ').toLowerCase().replace('fha', 'FHA'))
        }
        let handleSelectRate = (card) => {
            if (this.state.ratesToBeRecommended.some(x => x.hash === card.hash)) {
                const updatedRates = this.state.ratesToBeRecommended.filter(x => x.hash !== card.hash);
                this.setState({ ratesToBeRecommended: updatedRates });
            } else {
                this.setState(prevState => ({
                    ratesToBeRecommended: [...prevState.ratesToBeRecommended, card]
                }));
            }
        };
        let displayErrors = () => {
            return <div className='p-3'>{this.state.errorText}</div>
        }
        
        const columns = [
            {
                title: 'Program',
                dataIndex: 'program',
                render: (text, record) => {
                    return getName(record)
                }
              },
              {
                title: 'Rate',
                dataIndex: 'rate',
                render: (text, record) => <div className='bold'>{record.base_rate}%</div>,
                sorter: (a, b) => a.base_rate - b.base_rate,
                defaultSortOrder: "ascend",
                sortDirections: ["descend", "ascend", "descend"],
              },
              {
                title: 'APR',
                dataIndex: 'apr',
                render: (text, record) => <div className='bold'>{record.APR}%</div>,
                sorter: (a, b) => a.APR - b.APR,
              },
              {
                title: 'Mo. Payment',
                dataIndex: 'monthlypayment',
                render: (text, record) => <div className='bold'>${com.commaize(record.monthly_payment)}</div>,
                sorter: (a, b) => a.monthly_payment - b.monthly_payment,
              },
              {
                title: 'Points',
                dataIndex: 'points',
                render: (text, record) => <div>
                    <div className='bold'>{record.points + "%/$" + record.lender_credit }</div>
                    <div className='title-5'>{"Est. closing costs: $"+com.commaize(record.closing_cost)}</div>
                </div>,
                sorter: (a, b) => a.points - b.points,
              },
              {
                title: '',
                dataIndex: 'add',
                render: (text, record) => <div className='d-flex align-items-center'>
                    <ExclamationCircleOutlined style={{fontSize: 25, color:"#6E6E70", marginRight:15}} onClick={() => {
                            this.state.detailedCard = record
                            this.setState({ detailedCard: record, show: true })
                        }
                        }/>
                    {!this.isPrequal && <Button type={this.state.ratesToBeRecommended.some(x => x.hash === record.hash) ? 'secondary-selected' : "secondary"} style={{height:36}} onClick={()=>handleSelectRate(record)}>{this.state.ratesToBeRecommended.some(x => x.hash === record.hash) ? <div><CheckOutlined style={{fontSize:14, marginRight:4}}/>Selected</div> : "Select"}</Button>}
                </div>,
                sorter: (a, b) => a.points - b.points,
              },
        ]
        return <div>
            {this.state.loading && <div className='p-2 mt-2'><Skeleton active/><Skeleton active/><Skeleton active/></div>}
            {this.state.products.length > 0 && <Table 
                className='productPickerTable'
                columns={columns} 
                dataSource={this.state.products} 
                showSorterTooltip={false}
                pagination={{
                hideOnSinglePage: true,
                }}
            />
            }
            {this.state.products.length === 0 && !this.state.loading && displayErrors()}
        </div>
    }

    checkStatus = () => {
        let token = sessionStorage.getItem("ZeitroA")
        if (this.props.application.remotecoborrowers.length > 0) {
            this.setState({ statusloaded: false })
            fetch('/data/coborrowerstatus', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Borrower": this.props.borrowerid,
                }
            }).then(
                response => {
                    if (response.status !== 200) {
                        //alert('Looks like there was a problem. Status Code: ' +
                        //    response.status);
                        //window.document.dispatchEvent(new Event('reauthenticate'), "");

                        return;
                    }
                    // Examine the text in the response
                    response.json().then(js => {
                        let positionbyemail = {}
                        let proc = (a, i) => {
                            let email = a.Email
                            let position = a.Position
                            positionbyemail[email] = position
                        }
                        js.map(proc)
                        this.setState({ positionbyemail: positionbyemail, statusloaded: true })
                    });
                }
            ).catch((err) => {
                alert('Fetch Error :', err);
            });

        }
    }

    renderRemoteCoborrowers = (rb, i) => {
        let getStatus = () => {
            let status = "Invited"
            if (rb.email in this.state.positionbyemail) {
                let pos = this.state.positionbyemail[rb.email]

                status = com.positionToStatus(pos)
            }
            return status
        }
        if (this.state.positionbyemail[rb.email] >= com.prefillLimit)
            return <div></div>
        else {
            clearTimeout(this.timer)
            this.timer = setTimeout(this.checkStatus, 30000)
            return (
                <div className="viewport mb-3 drop-shadow text-left" key={rb.key}>
                    <Form.Row>
                        <Col  >
                            <Form.Group controlId={"first_name" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >First Name:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{rb.firstname}</div>
                            </Form.Group>
                        </Col>
                        <Col  >
                            <Form.Group controlId={"last_name" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >Last Name:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{rb.lastname}</div>
                            </Form.Group>
                        </Col>
                        <Col  >
                            <Form.Group controlId={"email" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >Email:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{rb.email}</div>
                            </Form.Group>
                        </Col>
                        <Col  >
                            <Form.Group controlId={"email" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >Status:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{getStatus()}</div>
                            </Form.Group>
                        </Col>

                    </Form.Row>
                </div>
            )
        }
    }

    waiting = (num) => {

        return (
            <div className="mx-2 px-2 text-left  mb-4 bg-">
                <h2 className=" heading">
                    Product selector
                </h2>
                <div className="viewport text-center mt-4 ">
                    <div className="mb-3 sectionquestion">{
                        (num === 1) ? <div>Look like your coborrower has not filled enough of their application to allow product selection:</div>
                            : <div>Look like some of your coborrowers have not filled enough of their application to allow product selection:</div>
                    }</div>
                    {this.props.application.remotecoborrowers.map(this.renderRemoteCoborrowers)}

                </div>
            </div>
        )
    }
    generateDTISpreadsheet = () => {
        this.setState({ dtiSpreadsheetLoading: true }, () => {
            setTimeout(() => {
                // wait for 5 seconds for updating selected loan state
                let token = sessionStorage.getItem("ZeitroA")
                fetch("/agent/generatedtispreadsheet", {
                    cache: 'no-cache',
                    method: 'POST',
                    body: JSON.stringify({ CustomerID: this.props.borrower.id, LoanID: this.props.borrower.loan_id }),
                    headers: {
                        Authorization: "Bearer " + token,
                        Cache: "no-cache"
                    },
                }).then(response => {
                    if (response.status !== 200) {
                        this.setState({ dtiSpreadsheetLoading: false })
                        alert("Something went wrong, please try later.")
                        try {
        
                            response.text().then(js => {
                                console.log(js)
                            })
                            return;
                        } catch {
                            console.log('Something went wrong, please contact engineers')
                            return;
                        }
                    }
                    response.json().then((js) => {
                        if (js.RedirectURL !== "Something is wrong") {
                            this.setState({ DTISpreadsheet: js.SpreadsheetB64 }, () => this.downloadDTIspreadsheetRef.current.click());
                        } else {
                            alert("Something went wrong, please try later.")
                            console.log("Something is wrong, please reach engineers for help")
                        }
                        this.setState({ dtiSpreadsheetLoading: false })
                    })
                })
              }, 5 * 1000)


        })
    }
    render = () => {


        if (this.props.application.hascoborrower === "multiple") {
            if (!this.state.statusloaded) {
                return <div className="mb-5 pb-3">
                    <Row><Col className="text-center mt-5 pt-5"><img alt="" className="rotate" src="/logo.svg"></img></Col></Row>

                </div>
            }

            let notready = 0
            let remotes = this.props.application.remotecoborrowers

            for (let i = 0; i < remotes.length; i++) {
                let pos = this.state.positionbyemail[remotes[i].email]
                if (pos < com.prefillLimit)
                    notready += 1
            }
            if (notready > 0) {
                return <div>
                    {this.waiting(notready)}
                </div>
            }
        }
        return (
            <Container id="picker" className="text-center  mt-0 mb-2 pt-0 w-100 px-0 mx-0" fluid>
               

                <Modal show={this.state.show} dialogClassName="loandetails mt-5"
                    onHide={() => this.setState({ show: false })}>
                    <Modal.Header closeButton >
                        <Modal.Title><img alt="" src="/jackhammer.png" style={{ width: '32px' }}></img>Loan Drill Down</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Details seletion={this.props.selection.selectedloan} card={this.state.detailedCard} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({ show: false })}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showComparison} dialogClassName="loandetails mt-5"
                    onHide={() => this.setState({ showComparison: false })}>
                    <Modal.Header closeButton >
                        <Modal.Title><img alt="" src="/jackhammer.png" style={{ width: '32px' }}></img>Loan Comparison</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Comparison
                            hide={
                                () => {
                                    this.setState({ showComparison: false })
                                }}
                            calldelete={this.removeSelection.bind(this)}
                            callselect={this.selectLoan.bind(this)}
                            selection={this.state.comparisonSelections} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({ showComparison: false })}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                <Mod title="The rates you suggested have been forwarded to the borrower." open={this.state.showRecommendRatesModal} onOk={()=>this.setState({showRecommendRatesModal: false})} onCancel={()=>this.setState({showRecommendRatesModal: false})}>
                    <p>Your borrower will receive a notification about the recommended rates. Once a rate has been selected by your borrower, we will promptly inform you.</p>
                </Mod>

                {!this.isPrequal && <Row className="text-left pb-3" >
                    <Col>
                        <div className='title-3 mb-2'>
                            Recommend rates
                        </div>
                        <div>
                            {this.displaySelectedCards()}
                        </div>
                        <hr/>
                    </Col>
                </Row>}

                <Row className="text-left " >
                    <Col>
                        <div className='d-flex justify-content-between'>
                            <div className='title-3 mb-3'>
                                All rates
                            </div>
                            {this.state.ratesToBeRecommended.length > 0 && <div className='d-flex align-items-center'>
                                <div className='title-5 mr-2'>{this.state.ratesToBeRecommended.length} rates selected</div>
                                <Button type='primary' onClick={()=>this.recommendRates()}>Recommend selected rates </Button>
                                </div>}
                        </div>
                        <div>
                            {this.displayFilters()}
                        </div>
                        <div>
                            {this.displayRates()}
                        </div>
                    </Col>


                </Row>

            </Container>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ProductPicker)
