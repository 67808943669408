import React, { useState, useEffect, useRef } from "react";
import { QRCode } from "react-qrcode-logo";
import { Spinner } from "react-bootstrap";
import { Carousel, Button, Modal, Progress, message, Row, Col, Spin, Tabs, Skeleton, Popconfirm  } from 'antd';
import { InfoCircleTwoTone } from "@ant-design/icons";
import Lenders from "../Management/Lenders";
import CalculatorsSetup from "./CalculatorsSetup";
import BasicInfoSetup from "./BasicInfoSetup"
import AboutmeSetup from "./AboutmeSetup"
import CustomerReviewSetup from "./CustomerReviewSetup";
import BasicInfo from "../LandingPage/BasicInfo";
import AboutMe from "../LandingPage/AboutMe";
import CustomerReview from "../LandingPage/CustomerReview"
import { LandingAffordability, LandingRateQuoter } from "../LandingPage/Tools";
import {CheckGroupWithImage} from "../Common/components/CheckGroup";
import Profile from "../Profile";
import LandingPage from "../LandingPage/LandingPage";
import { getLoId, getCustomerId } from "../Common";
import "./InitialSetup.css"
import CallBack from "../LandingPage/CallBack";
import eventBus from '../Bus';
const title = {
  '1': 'Module 1: Basic info',
  '2': 'Module 2: About me',
  '3': 'Module 3: Mortgage Calculators',
  '4': 'Step 4: Voice from customers'
}
const describe = {
  '1': 'In this step, please provide your essential details, including your profile photo, NMLS number, and license. This information is crucial for verifying your identity as an authorized loan officer.',
  '2': 'In this step, please provide your essential details, including your profile photo, NMLS number, and license. This information is crucial for verifying your identity as an authorized loan officer.',
  '3': 'In this step, you have the option to include wholesale lenders, allowing your potential customers to access real-time rates based on demand.',
  '4': 'In this step, you have the option to include wholesale lenders, allowing your potential customers to access real-time rates based on demand.',
}

const LandingPageSetup = () => {
  const [landingUrl, setLandingUrl] = useState("");
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingProfileStatus, setLoadingProfileStatus] = useState(false);
  const [showSetupProfile, setShowSetupProfile] = useState(false);
  const [showSetupLenders, setShowSetupLenders] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [percent, setPercent] = useState(0);
  const [publishModule, setPublishModule] = useState([]);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [openEditModule, setOpenEditModule] = useState(false);
  const [moduleType, setModuleType] = useState('1');
  const [basicInfo, setBasicInfo] = useState({});
  const [aboutInfo, setAboutInfo] = useState({});
  const [lenderList, setLenderList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [hasLenders, setHasLenders] = useState(false);
  const [siteVersion, setSiteVersion] = useState(1)
  const [showChooseVesion, setChooseVersion] = useState(false)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const [published, setPublished] = useState(false)

  const reviewRef = useRef(null);
  const aboutMeRef = useRef(null);
  const basicInfoRef = useRef(null);
  const calculatorsRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [LendersStatus, setLendersStatus] = useState(false);
  const refAffordability = useRef(null);
  const refRateQuoter = useRef(null);
  

  const handleClickRefAffordability = (e) => {
    if (refAffordability.current)
      refAffordability.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickRefRateQuoter = () => {
    if (refRateQuoter.current)
      refRateQuoter.current.scrollIntoView({ behavior: "smooth" });
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    // console.log(width, height)
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const modulePercentage = {
    1: 18,
    2: 38,
    3: 24,
    4: 20
  }
  const calculateAdditionalPercent = (publish) => {
    let additionalPercent = 0
    console.log(publish)
    publish.forEach((module) => {
      if (modulePercentage[module]) {
        additionalPercent += modulePercentage[module]
      }
    })
  
    return additionalPercent
  }
  useEffect(() => {
    getProfile()
    getCustomerLenders()
  }, [])
  useEffect(() => {
    if (Object.keys(basicInfo).length !== 0 && basicInfo.file.length > 0 && basicInfo.firstname !=='' && basicInfo.lastname !=='' && !publishModule.includes(1)) {
      setPublishModule(prev => [...prev, 1])
    }
    if (Object.keys(aboutInfo).length !== 0 && aboutInfo.introduction !== '' && aboutInfo.phone !=='' && aboutInfo.email !=='' && !publishModule.includes(2)) {
      setPublishModule(prev => [...prev, 2])
    }
    if (hasLenders && !publishModule.includes(3)) {
      setPublishModule(prev => [...prev, 3])
    }
    if (reviewList.length > 0 && reviewList.filter(item => item.firstTime !== '' && item.review !== '').length > 0 && !publishModule.includes(4)) {
      setPublishModule(prev => [...prev, 4])
    }
  }, [basicInfo, aboutInfo, reviewList, hasLenders])
  useEffect(() => {
    const additionalPercent = calculateAdditionalPercent(publishModule);
    setPercent(additionalPercent)
    console.log('change publishModule')
  }, [publishModule]);
  const getCustomerLenders = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/getcustomerlenders", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          )
          return
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log(js)
          } else {
            if (js.Lenders !== null) {
              const hasLenders = js.Lenders.some(item => item.Active)
              setHasLenders(hasLenders)
            }
          }
        })
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err)
      })
  }
  const getProfile = () => {
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/getprofilesetup", {
      cache: 'no-cache',
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache"
      },
  }).then(response => {
      if (!response.ok) {
          console.log(response)
          setLoading(false);
      } else {
          response.json().then(js => {
          
              let lo = js
              let su = lo.LandingPageSetUp
              let name = lo.FirstName + " " + lo.LastName
              setBasicInfo({
                file: [
                  {
                    url: lo.Image
                  }
                ],
                firstname: lo.FirstName,
                lastname: lo.LastName,
                nmls: lo.NMLS,
                states: typeof su.States === "undefined" ? [] : su.States,
                tagsList: typeof su.TagsList === "undefined" ? [] : su.TagsList,
                popularTags: ['I speak Mandarin', 'Top 10 Loan offier', "Bay Area for 10+ years"]
              })
              setAboutInfo({
                introduction: lo.Introduction,
                phone: lo.Phone,
                email: lo.Email,
                companyWebsite: su.CompanyWebsite === undefined ? '' : su.CompanyWebsite,
                google: su.Google === undefined ?'':su.Google ,
                yelp: su.Yelp === undefined ?'':su.Yelp ,
                linkedin: su.Linkedin === undefined ?'':su.Linkedin ,
                twitter: su.Twitter === undefined ?"":su.Twitter ,
              })
              setReviewList( su.ReviewsList===undefined ? [
                {
                  firstname: 'Sally',
                  lastname: 'Example',
                  review: name + " is the greatest mortgage broker I've ever worked with.",
                },
                {
                  firstname: 'Beverly',
                  lastname: 'Example',
                  star: 4,
                  review: name + " is the best! Can't thank you enough and will continue to refer you whenever I can!",
                },
                {
                  firstname: 'Steve',
                  lastname: 'Example',
                  star: 2,
                  review: "Thanks again for all your help with my condo, I love this thing! Can't wait to talk to you about some investment property financing.",
                },
                {
                  firstname: 'Steve',
                  lastname: 'Example',
                  star: 2,
                  review: "",
                }
              ]: su.ReviewsList)
              if (typeof su.SiteVersion !== "undefined") setSiteVersion(su.SiteVersion)
              setLandingUrl( window.location.hostname.includes("localhost") ? "http://localhost:9262"+js.LandingUrl :"https://" + window.location.hostname + js.LandingUrl)
              setLoading(false);
              
          })
      }
  }).catch(error => {
      console.log("Revalidate catch error " + error)
  })
  };


  const getSetupProfile = ()=> {
    return (
      <Modal
        show={showSetupProfile}
        onHide={()=>setShowSetupProfile(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body><Profile /></Modal.Body>
      </Modal>
    );
  }
  
  const getSetupLenders = ()=> {
    return (
      <Modal
        open={showSetupLenders}
        onCancel={()=>setShowSetupLenders(false)}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Lenders />
      </Modal>
    );
  }
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showContactmeModal, setShowContactmeModal] = useState(false);
  const getContactmeModal = () => {
    return (
      <Modal
        open={showContactmeModal}
        onCancel={() => setShowContactmeModal(false)}
        footer={null}
        closeIcon={null}
      >
        <CallBack id={getCustomerId()} name={name} email={email} forHeader={true} />
      </Modal>
    );
  };
  const firstTimePage = () => {
    return (
      <div>this first time</div>
    )
  }
  const carouselChange = (currentSlide) => {
    console.log(currentSlide);
  }
  const toUpdate = () => {
    setFirstTime(false)
  }
  const isPublish = () => {
    return publishModule.includes(1) && publishModule.includes(2) && publishModule.includes(3);
  }

  const toPublish = () => {
    setChooseVersion(true)
  }

  const publish = () => {
    setPublished(false)
    setLoadingPublish(true)
    setPublishSuccess(true)
    let tosend = {
      "Email": aboutInfo.email,
      "FirstName": basicInfo.firstname,
      "LastName": basicInfo.lastname,
      "Phone": aboutInfo.phone,
      "NMLS": basicInfo.nmls,
      "Image": basicInfo.file[0].url,
      "Introduction": aboutInfo.introduction,
      "LandingPageSetUp": {
        ReviewsList: reviewList,
        CompanyWebsite: aboutInfo.companyWebsite,
        Google: aboutInfo.google,
        Yelp: aboutInfo.yelp,
        Linkedin: aboutInfo.linkedin,
        Twitter: aboutInfo.twitter,
        TagsList: basicInfo.tagsList,
        SiteVersion: siteVersion,
        States: basicInfo.states,
      }
  }
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/agent/updateprofilesetup', {
        method: 'POST',
        body: JSON.stringify(tosend),
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
    }).then(
        response => {
            if (response.status !== 200) {
                console('Looks like there was a problem. Status Code: ' +
                    response.status);
                loadingPublish(false)
                setPublished(true)
                return;
            }
            eventBus.emit('updateProfile')
            setLoadingPublish(false)
            setPublished(true)
        }
    ).catch(function (err) {
        setLoadingPublish(false)
        setPublished(true)
        console.log('Fetch Error :-S', err);
    });
  }
  const copyUrl = () => {
    navigator.clipboard.writeText(landingUrl)
    .then(() => {
      messageApi.success('copy success!')
    })
    .catch((error) => {
      messageApi.error('copy error!')
    })
  }
  const contentStyle = {
    margin: 0,
    height: '430px',
    width: '100%',
  }
  const opneModal = (type) => {
    setModuleType(type)
    setOpenEditModule(true)
  }
  const handleOk = () => {
    // setOpenEditModule(false)
    if (moduleType === '1' && basicInfoRef.current) {
      basicInfoRef.current.validateForm()
    } else if (moduleType === '2' && aboutMeRef.current) {
      aboutMeRef.current.validateForm()
    } else if (moduleType === '4' && reviewRef.current) {
      reviewRef.current.validateForm()
    }
  }
  const handleCancel = () => {
    if (moduleType === '1' && basicInfoRef.current) {
      basicInfoRef.current.resetForm()
    } else if (moduleType === '2' && aboutMeRef.current) {
      aboutMeRef.current.resetForm()
    } else if (moduleType === '4' && reviewRef.current) {
      reviewRef.current.resetForm()
    }
    setOpenEditModule(false)
  }
  const basicInfoFinish = (val) => {
    if (!publishModule.includes(1)) {
      setPublishModule(prev => [...prev, 1])
    }
    console.log(val)
    setBasicInfo(val)
    handleCancel()
  }
  const reviewFinish = (val) => {
    if (!publishModule.includes(4)) {
      setPublishModule(prev => [...prev, 4])
    } else {
      const arr = val.customerReview.filter(item => item.firstTime !== '' && item.review !== '')
      if (arr.length === 0) {
        setPublishModule(prev => prev.filter(item => item !== 4))
      }
    }
    setReviewList(val.customerReview)
    handleCancel()
  }
  const aboutMeFinish = (val) => {
    if (!publishModule.includes(2)) {
      setPublishModule(prev => [...prev, 2])
    }
    console.log('about', val)
    setAboutInfo(val)
    handleCancel()
  }
  const calculatorsFinish = (val) => {
    console.log('calculators Finish')
    if (!publishModule.includes(3)) {
      setPublishModule(prev => [...prev, 3])
    }
    setLenderList(val)
    console.log('calculatorsFinish', val)
    handleCancel()
    window.location.reload()
  }
  
  const showModule = ()  => {
    switch (moduleType) {
      case "1":
        return <BasicInfoSetup info={basicInfo} ref={basicInfoRef} finish={basicInfoFinish} />;
      case "2":
        return <AboutmeSetup info={aboutInfo} ref={aboutMeRef} finish={aboutMeFinish} />;
      case "3":
        return <CalculatorsSetup ref={calculatorsRef} finish={calculatorsFinish} cancel={handleCancel} />;
      case "4":
        return <CustomerReviewSetup reviewList={reviewList} ref={reviewRef} finish={reviewFinish} />;
    }
  }
  const tabsItems = [
    {
      key: '1',
      label: 'Check Current Rate',
      children: 
      <div
        style={{ backgroundColor: "#F7F8FC" }}
        className="landingRateQuoter"
        id="landingRateQuoter"
        ref={refRateQuoter}
      >
        <LandingRateQuoter customerid={getCustomerId()} loid={getLoId()} width={width} height={height} />
      </div>,
    },
    {
      key: '2',
      label: 'Check Affordability',
      children: 
      <div
        style={{ backgroundColor: "white" }}
        className="landingTools"
        id="landingTools"
        ref={refAffordability}
      >
        <LandingAffordability customerid={getCustomerId()} loid={getLoId()} width={width} height={height} />
      </div>,
    }
  ]
  const tabsOnChange = (key) => {
    console.log(key)
  }
  const preview = () => {
    const moduleList = {
      module1: basicInfo,
      module2: aboutInfo,
      module3: lenderList,
      module4: reviewList
    }
    sessionStorage.setItem('landing-info', JSON.stringify(moduleList))
    const url = window.location.origin + "/services/landing-review"
    window.open(url, '_blank')
  }
  const sendMsgToSlack = () => {

    let token = sessionStorage.getItem("ZeitroA");

    fetch("/agent/changedomainrequest", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        console.log(
          "/agent/addlenderrequest: Looks like there was a problem. Status Code: " +
            response.status
        );
        return;
      }
    });
  };
  const finish = () => {
    setChooseVersion(false)
    setPublished(false)
  }
  const getChooseVersionModal = () => {
    return <Modal
    title="Which site style would you like to use?"
    centered
    open={showChooseVesion}
    onOk={() => {published ? finish() : publish()}}
    okText={loadingPublish ? <Spinner animation="border" size="sm"/> : published? "OK" : "Publish"}
    onCancel={() => setChooseVersion(false)}
    width={"80%"}
    >
      <div className="d-flex justify-content-center">
      {published ? <div className="text-center">
        <img src="/images/congratulation.png"/>
        <div style={{marginTop: 20, fontWeight: 500}}>You can view your personal site now at <a href={landingUrl} target="_blank">{landingUrl}</a></div>
      </div> :       <CheckGroupWithImage 
          style={{width: "100%"}}
          imageWidth="350px"
          imageHeight="250px"
          data={[ {
              value: 1,
              label: 'Style 1',
              image: "/images/landingPageSample1.png"
            },
            {
              value: 2,
              label: 'Style 2',
              image: '/images/landingPageSample2.png'
            }
            ]} 
            onChangeSelect={v => setSiteVersion(v)} 
            value={siteVersion} 
            type='radio'
            direction="horizontal" />}

      </div>
  </Modal>
  }
  return (
    <>
      {
        firstTime ? (
          <div className="firstTimeWrap">
            <div className="firstTimeTitle">
              "Ready to create your personal website in just a few minutes?"
            </div>
            <div style={{width: '60%', marginBottom: '82px'}}>
              <Carousel className="firstTimeCarousel" afterChange={carouselChange}>
                <div>
                  <img style={contentStyle} src="https://img2.baidu.com/it/u=2479569717,2708545456&fm=253&fmt=auto&app=138&f=JPEG" />
                </div>
                <div>
                  <img style={contentStyle} src="https://hbimg.b0.upaiyun.com/c2dc04f7f4fcf8a4026e8eaec5ee6b2963a99d492aaa-WZq2hv_fw658" />
                </div>
                <div>
                  <img style={contentStyle} src="https://img2.baidu.com/it/u=2479569717,2708545456&fm=253&fmt=auto&app=138&f=JPEG" />
                </div>
              </Carousel>
            </div>
            <Button className="firstTimeBtn" type="primary" shape="circle" size="large" onClick={toUpdate}>Update my plan</Button>
            <div className="firstTimeTips">Update to premium program to access this feature</div>
          </div>
        ) : (
          <div className="mt-4 text-body landing-setup">
            {(loading || loadingProfileStatus ) ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <div>
                {contextHolder}
                <div className="configure-box">
                  <div className="title-1 mb-2">Configure your personal website</div>
                  {isPublish() && <div className="title-3 mb-1">Your website url: {landingUrl}<img className="ml-2" src="/images/copy.svg" onClick={copyUrl} />
                  
                  <Popconfirm
                  title="Would you like to submit a request to customize the domain for your personal website?"
                  description=""
                  onConfirm={()=>{
                    sendMsgToSlack()
                    message.success("Your request has been sent!")
                  }}
                  onCancel={()=>{}}
                  okText="Submit request"
                  placement="bottom"
                  overlayStyle={{width:400}}
                  cancelText="Cancel"
                  icon={<InfoCircleTwoTone />}
                ><Button type="link">Contact us for modifying the URL</Button></Popconfirm>
                  </div>}

                  <div className="configure-progress">
                    <Progress style={{width: '56%', marginBottom: '0'}} percent={percent} showInfo={false} strokeColor="#FBBC04" trailColor="#D9D9D9" />
                    <span className="num">{percent}%</span>
                    <Button className="publish-btn" type="primary" disabled={!isPublish()} size="large" onClick={toPublish}>Publish</Button>
                    {/* {
                      !publishSuccess && (
                        <Button className="preview-btn" size="large" onClick={preview}>Preview</Button>
                      )
                    } */}
                  </div>
                  {
                    !publishSuccess && (
                      <div className="configure-subtitle">
                        {!isPublish() && <div>Complete the required fields in the first 3 modules to publish your website.</div>}
                        {(isPublish() && !publishModule.includes(4)) && <div>Good job, you can publish your website now, but we encourage you to complete the rest to provide the best experience.</div>}
                        {(isPublish() && publishModule.includes(4)) && (
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img style={{marginRight: '16px'}}src="/images/check-circle.svg" />
                            <span>Congratulations! You have successfully built your personal website. Please publish it, and then you'll be able to view and share it with your customers.</span>
                          </div>
                        )}
                      </div>
                    )
                  }
                </div>
                {loading ? <Skeleton active/> : <div className="module-box">
                  <div>
                    <Row gutter={20}>
                      <Col span={24} lg={10} style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="module-title">Module 1: Basic info</div>
                        <div className="module-btn"><Button size="large" onClick={() => opneModal('1')}>Edit info</Button></div>
                        <BasicInfo style={{flex: '1'}} basicInfo={basicInfo} contact={() => setShowContactmeModal(true)} />
                      </Col>
                      <Col span={24} lg={14} style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="module-title">Module 2: About me</div>
                        <div className="module-btn"><Button variant="landingPageConfig" size="large" onClick={() => opneModal('2')}>Edit info</Button></div>
                        <AboutMe style={{flex: '1'}} basicInfo={basicInfo} aboutInfo={aboutInfo} />
                      </Col>
                    </Row>
                  </div>
                  <div className="container calculators">
                    <div className="module-title">Module 3: Mortgage Calculators</div>
                    <div className="module-btn">
                      <Button variant="landingPageConfig" size="large" onClick={() => opneModal('3')}>Add lenders</Button>
                      <Button variant="landingPageConfig" size="large" onClick={() => window.location.href = landingUrl}>Check Current Rate</Button>
                      <Button variant="landingPageConfig" size="large" onClick={() => window.location.href = landingUrl}>Check Affordability</Button>
                    </div>
                    <div className="calculators-box">
                      <Tabs className="calculators-tabs" defaultActiveKey="1" items={tabsItems} onChange={tabsOnChange} />
                      {
                        LendersStatus && (
                          <div className="calculators-mask"><div>Add wholesale lenders, allowing your potential customers to access real-time rates based on demand!</div></div>
                        )
                      }
                    </div>
                  </div>
                  <div className="container customerReview">
                    <div className="module-title">Module 4: Voice from customers <span style={{fontWeight: '300'}}>(Optional)</span></div>
                    <div className="module-btn"><Button variant="landingPageConfig" size="large" onClick={() => opneModal('4')}>Edit info</Button></div>
                  </div>
                  <CustomerReview mode="setup" reviewList={reviewList} />
                </div>}
                
              </div>
            )}
            {showSetupProfile && getSetupProfile()}
            {/* {showSetupProperties && getSetupProperties()}
            {showSetupResources && getSetupResources()} */}
            {showSetupLenders && getSetupLenders()}
            {showContactmeModal && getContactmeModal()}
            <Modal
              className="EditModuleModal"
              destroyOnClose={true}
              open={openEditModule}
              title=""
              onOk={handleOk}
              onCancel={handleCancel}
              width={885}
              footer={moduleType !== '3' ? [
                <Button className="btn moduleSave" key="submit" size="large" onClick={handleOk}>
                  Save
                </Button>,
                <Button className="btn moduleCancel" key="back" size="large" onClick={handleCancel}>
                  Cancel
                </Button>
              ] : null}
            >
              <div className="moduleTitle">{title[moduleType]}</div>
              <div className="moduleDescribe">{describe[moduleType]}</div>
              {showModule()}
            </Modal>
            {showChooseVesion && getChooseVersionModal()}
          </div>
        )
      }
    </>
  );
};

export default LandingPageSetup;
