import "./public.css"
import React, { useState, useEffect} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { commaize } from "../../Common";

const wipeoffComma = (e) => {
    return Number(e.replace(/,/g, ""))
}

export default function ScrollBar(props) {
    const { max, min, step, initVal, scrollChangeValue, hideSlider} = props;
 
    useEffect(() => {
        setMaxValue(max);
        setMinValue(min);
        setStepValue(step);
        setInitValue(initVal);
        setInputValue(initVal);
    }, [initVal, max, min, step])

    const [initValue, setInitValue] = useState(1)
    const [inputValue, setInputValue] = useState(1)
    const [maxValue, setMaxValue] = useState(3000000)
    const [minValue, setMinValue] = useState(0)
    const [stepValue, setStepValue] = useState(null)

    const initValueChange = (e) => {
        setInitValue(e)
        setInputValue(commaize(e))
        scrollChangeValue(e)
    }

    const inputChange = (e) => {
        if (wipeoffComma(e.target.value) > maxValue) {
            setInitValue(maxValue)
            scrollChangeValue(maxValue)
            setInputValue(maxValue)
        } else {
            setInitValue(wipeoffComma(e.target.value))
            scrollChangeValue(wipeoffComma(e.target.value))
            setInputValue(wipeoffComma(e.target.value))
        }
    }


    return (
        <div className="scroll-bar" style={props.style}>
            <div className="bar-sign">$</div>
            <input type='text' style={{width:hideSlider?"100%":""}} onChange={inputChange} className="input-text" placeholder="Paragraph Main" value={commaize(inputValue)}></input>
            {!hideSlider && <Slider style={{ marginLeft: '1rem' }} className="custom-range" min={minValue} max={maxValue} step={stepValue} onChange={initValueChange} value={initValue} />}
        </div>
    )
}
