import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Form from "react-bootstrap/Form";

import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Select from "react-select";

export const states = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export default class StateSelector extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }
  componentDidMount() {}

  componentWillUnmount() {}

  onSelect = (e) => {
    this.ref.current.value = e;
    this.props.onChange({ target: { value: e } });
  };
  render() {
    return (
      <InputGroup className="">
        <Form.Control
          isValid={false}
          size="sm"
          ref={this.ref}
          required={this.props.required}
          name={this.props.name}
          autoComplete="state"
          type="text"
          placeholder={this.props.placeholder}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          onChange={this.props.onChange}
          //value={this.props.application[this.props.who].presentaddressstate}
          //onChange={changeBorrowerState(this.props.who, "presentaddressstate")}
          pattern="[A-Z]{2,2}"
        />
        <DropdownButton
          as={InputGroup.Append}
          className="state-dropdown-button"
          variant="dropdown"
          title=""
          size="sm"
          autoComplete="state"
          onSelect={this.onSelect}
        >
          {Object.keys(states).map((st) => (
            <Dropdown.Item key={st} eventKey={st} href="#">
              {states[st]}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </InputGroup>
    );
  }
}

export const MultiStateSelector = (props) => {
  const getOptions = () => {
    let options = [];
    for (let s in states) {
      options.push({ value: s, label: states[s] });
    }
    return options;
  };

  return (
    <Select
      isMulti
      options={getOptions()}
      onChange={props.onChange}
      className={props.className}
      classNamePrefix={props.classNamePrefix}
      styles={props.styles}
      defaultValue={props.defaultValue}
      value={props.value}
      closeMenuOnSelect={props.closeMenuOnSelect}
    />
  );
};
