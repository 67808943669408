import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import "./RateQuote.css";
import { Col, Row, Card } from "react-bootstrap";

export default function IneligibleProductsModal(props) {
  console.log(props.message);

  console.log("ineligibleProducts", props.ineligibleProducts);
  const getAccordion = () => {
    let out = [];
    if (Object.keys(props.ineligibleProducts).length == 0) return out;
    for (let prd in props.ineligibleProducts) {
      let lenderReason = props.ineligibleProducts[prd].LenderLevelReason;
      let prdReasons = props.ineligibleProducts[prd].ProductLevelReasons;
      let cardBody = [];
      if (prdReasons != null) {
        prdReasons.forEach((pr) => {
          cardBody.push(
            <li>
              <b>{pr.ProductName}: </b>
              {pr.IneligibleReason}
            </li>
          );
        });
      } else {
        cardBody.push(
          <p>
            <b>Lender level reason: </b>
            {lenderReason}
          </p>
        );
      }
      out.push(
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={prd}>
            {prd}
            {lenderReason !== "" ? ": " + lenderReason : ""}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={prd}>
            <Card.Body>
              <ul>{cardBody}</ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    }
    return out;
  };
  let message = props.message;
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ineligible Products and Corresponding Reasons
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.keys(props.ineligibleProducts).length > 0 ? (
          <Accordion>{getAccordion()}</Accordion>
        ) : (
          <h3>{message !== "" ? message : "No unqualified product."}</h3>
        )}
      </Modal.Body>
    </Modal>
  );
}
