import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Row, Col, Nav,Button } from "react-bootstrap";
import { Modal} from 'antd';
import "../../App.css";
import "./RateQuote.css";
import { withRouter } from "react-router-dom";
import RateQuoteLONewVersion from "./RateQuoteLONewVersion";
import RateQuoteBorrowerVersion from "./RateQuoteBorrowerVersion";
import RateQuoteRealtorVersion from "./RateQuoteRealtorVersion";
import LimitedAccessModal from "../../Sidebar/LimitedAccessModal";
import { checkAccess } from "../../Auth";
import { UR_RealEstateAgent } from "../../State";
import Lenders from "../../Management/Lenders";

const RateQuoter = () => {
  // for checking realtor's accessability
  const [photoUnuploaded, setPhotoUnuploaded] = useState(false);
  const [referralUnfinished, setReferralUnfinished] = useState(false);
  const [referredLoanOfficerEmails, setReferredLoanOfficerEmails] = useState(
    []
  );
  const [showLimitedAccessModal, setShowLimitedAccessModal] = useState(false)
  const [switchkey, setSwitchkey] = useState('loVersion');
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);
  const isMobile = window.innerWidth < 576

  useEffect(() => {
    checkProfileStatus();
  }, []);

  const checkProfileStatus = () => {
    if (checkAccess([UR_RealEstateAgent])) {
      let token = sessionStorage.getItem("ZeitroA");
      fetch("/re/checkprofilestatus", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
      }).then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Please contact Zeitro for help."
          );
          return;
        }
        response.json().then((js) => {
          setPhotoUnuploaded(js.PhotoUnuploaded);
          setReferralUnfinished(js.ReferralUnfinished);
          setReferredLoanOfficerEmails(js.ReferredLoanOfficerEmails);
          setShowLimitedAccessModal((js.PhotoUnuploaded||js.ReferralUnfinished))
        });
      });
    }
  };

  let content = (
    <div>
      {(photoUnuploaded || referralUnfinished) && (
        <LimitedAccessModal
          photoUnuploaded={photoUnuploaded}
          referralUnfinished={referralUnfinished}
          referredLoanOfficerEmails={referredLoanOfficerEmails}
          show={showLimitedAccessModal}
          hide = {()=>setShowLimitedAccessModal(false)}
        />
      )}
      {sessionStorage.getItem("roles") === "RealEstateAgent" ? (
        <RateQuoteRealtorVersion isFromPreview={true}/>
      ) : (
        <Tab.Container defaultActiveKey="loVersion" onSelect = {(activekey)=>setSwitchkey(activekey)}>
        <Row style={{borderBottom:"1px solid #ECECEC",height:"83px", alignItems:"center"}}>
          <Col xs={6} style={{paddingLeft:"30px"}}>
            {!isMobile && <div style={{fontWeight:"600",textAlign:"left",fontSize:"14px"}}>Rates & Pricing</div>}
            {!isMobile && <div style={{textAlign:"left",fontSize:"16px",fontWeight:"400"}}>Explore rates and pricing.</div>}
          </Col>
          <Col xs={6}>
            <Nav style={{justifyContent:"right",paddingRight: isMobile?"0":"40px"}} >
              <Nav.Item className="d-flex">
                {switchkey === "borrowerVersion" && 
                  <Nav.Link style={{backgroundColor:"#F6F6F6",display:"flex",paddingLeft:"15px",alignItems:"center", border:"1px solid #F6F6F6",borderRadius:"4px",width:"240px",height:"40px"}} eventKey="loVersion">
                    <div style={{ fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap",marginLeft:"5px"}}>LO version</div>
                    <div style={{backgroundColor:"#FFFFFF",padding:"5px 5px 5px 5px",borderRadius:"4px",marginLeft:"15px",fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap"}}>Borrower version</div>
                  </Nav.Link>
                }
                {switchkey === "loVersion" && 
                  <Nav.Link style={{backgroundColor:"#F6F6F6",display:"flex",paddingLeft:"5px",alignItems:"center", border:"1px solid #F6F6F6",borderRadius:"4px",width:"240px",height:"40px"}} eventKey="borrowerVersion">
                    <div style={{backgroundColor:"#FFFFFF",padding:"5px 15px 5px 15px",borderRadius:"4px",fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap"}}>LO version</div>
                    <div style={{marginLeft:"5px",fontSize:"14px",fontWeight:"500",color:"#222222",whiteSpace:"nowrap"}}>Borrower version</div>
                  </Nav.Link>
                }
                <Button variant="addLenders"  onClick={()=>setShowAddLendersModal(true)}>Add lenders</Button>
              </Nav.Item>
            </Nav>  
          </Col>
        </Row>
          <Tab.Content>
                <Tab.Pane eventKey="loVersion"><RateQuoteLONewVersion isMobile={isMobile} /></Tab.Pane>
                <Tab.Pane eventKey="borrowerVersion"><RateQuoteBorrowerVersion /></Tab.Pane>
          </Tab.Content>

          <Modal
            open={showAddLenderModal}
            onCancel={()=>setShowAddLendersModal(false)}
            centered
            footer={[
              <Button key="back" onClick={()=>setShowAddLendersModal(false)}>
                Cancel
              </Button>,
            ]}
            width={1000}
          >
            <Lenders />
          </Modal>
        </Tab.Container>
      )}
    </div>
  );
  return content;
};

export default withRouter(RateQuoter);
