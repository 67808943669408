import React,  { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { 
    Progress,
    Tabs,
    Tag,
    DatePicker,
    Button,
    Checkbox,
    Tooltip,
    Select,
    Switch,
    Modal,
    Divider,
    Form, 
    Input,
    InputNumber,
    Slider,
    Col, 
    Row,
    Upload
  } from 'antd'
import "./AboutmeSetup.css"
import { isEmpty } from 'lodash'
import * as com from '../Common'
const { TextArea } = Input
const AboutmeSetup = forwardRef(({ info, finish }, ref) => {
    const [form] = Form.useForm();
    const [expand, setExpand] = useState(true);
    const [percent, setPercent] = useState(0);
    const [required, setRequired] = useState(38);
    useEffect(() => {
        const formValues = form.getFieldValue()
        setProgressValue(formValues)
    }, [percent])
    const progressStatus = () => {
        if (percent < required) {
            return 'undone'
        } else if (percent >= required && percent < 100) {
            return 'normal'
        }
    }
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const onFinish = (val) => {

    }
    const formInitial = {...info}
    useImperativeHandle(ref, () => ({
        validateForm() {
            form.validateFields().then((values) => {
                finish(values)
            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo);
            });
        },
        resetForm() {
            form.resetFields()
        }
    }))
    const handleValuesChange = (changedValues, allValues) => {
        console.log(changedValues, allValues)
        const form = {...allValues}
        setProgressValue(form)
    }
    const onChangePhone = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, ""); // Remove all non-numeric characters
        value = value.replace(/^(\d{3})(\d{3})(\d{4}).*/, "($1) $2-$3"); // Format the number
        form.setFieldsValue({
            phone: value,
        })
    }
    
    const setProgressValue = (form) => {
        const fillNum = Object.keys(form).filter((key) => {
            const value = form[key];
            if (percent < required || (percent >= required && (isEmpty(form.introduction) || isEmpty(form.phone) || isEmpty(form.email)))) {
                return !isEmpty(value) && ['introduction', 'phone', 'email'].includes(key)
            } else {
                return !isEmpty(value)
            }
        }).length
        const progressValue = Math.round((fillNum / 8) * 100)
        setPercent(progressValue)
    }
    const getRandomInt = (min, max) => {
        const randomNum = Math.floor(Math.random() * (max - min + 1))
        return randomNum + min
    }
    const IntroductionList = [
        `I am a dedicated Loan Officer with over 10 years of experience. I specialize in helping first-time homebuyers navigate the mortgage landscape. My mission is to provide clear, straightforward advice so you can make informed decisions. Let's turn your dream home into a reality!`,
        `With a passion for financial education and a knack for numbers, I've helped countless clients secure loans that fit their needs and lifestyle. I believe in a personalized, no-stress approach to lending. Reach out, and let's find the perfect loan for you!`,
        `Whether you are considering refinancing your current mortgage or are in the process of purchasing a new property, I am committed to providing unparalleled service and guidance from start to finish. Let us commence this financial journey together.`,
        `A certified Loan Officer with a keen focus on client financial education. I have successfully facilitated loans for a diverse clientele and take a bespoke approach to meet individual needs with minimal stress. I invite you to reach out for a customized loan solution tailored to your financial circumstances`,
    ]
    const autoGenerate = () => {
        const random = getRandomInt(0, 4)
        form.setFieldsValue({
            introduction: IntroductionList[random],
        })
        const formValues = form.getFieldValue()
        setProgressValue(formValues)
    }
    return (
        <div className="about-me-setup">
            <div className='progress-box'>
                <div className='left-progress'>
                    <Progress className={`module-progress ${progressStatus()}`} percent={percent} showInfo={false} trailColor="#d9d9d9" />
                    <div style={{width: `${required}%`}} className='require-tag'><span>Required</span></div>
                </div>
                <div className='percent'>
                    {percent}%
                </div>
            </div>
            <div className='form-wrap'>
                <Form
                    className='about-me-form'
                    layout='vertical'
                    form={form}
                    initialValues={formInitial}
                    onFinish={onFinish}
                    onValuesChange={handleValuesChange}
                    validateMessages={validateMessages}
                    size="large"
                >
                    <Row>
                        <Col style={{position: 'relative'}} span={24}>
                            <Form.Item
                                name="introduction" 
                                label="Introduction"
                                rules={[
                                    {
                                    required: true,
                                    },
                                ]}
                            >
                                <TextArea
                                    style={{padding: '9px 11px 56px'}}
                                    autoSize={{
                                        minRows: 4,
                                    }}
                                />
                            </Form.Item>
                            <Button className='auto-generate' icon={<img src='/images/tip.svg' />} size='default' onClick={autoGenerate}>Auto generate</Button>
                        </Col>
                    </Row>
                    <Row gutter={60}>
                        <Col span={24} sm={12}>
                            <Form.Item 
                                name="phone" 
                                label="Cell phone number"
                                rules={[
                                    {
                                    required: true,
                                    },
                                ]}
                            >
                                <Input placeholder=""  onChange={onChangePhone} />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12}>
                            <Form.Item
                                name="email" 
                                label="Email"
                                rules={[
                                    {
                                    required: true,
                                    type: 'email',
                                    },
                                ]}
                            >
                                <Input placeholder="" disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='optional'>
                        <span>Optional field</span> <img className={expand ? 'down' : 'up'} src='/images/chevron-down-large.svg' onClick={() => {setExpand(!expand)}} />
                    </Row>
                    {
                        expand && (
                            <>
                               <Form.Item 
                                    name="companyWebsite" 
                                    label="Company"
                                >
                                    <Input placeholder=""  />
                                </Form.Item>
                                <Form.Item 
                                    name="google" 
                                    label="Google review"
                                >
                                    <Input placeholder=""  />
                                </Form.Item>
                                <Form.Item 
                                    name="yelp" 
                                    label="Yelp review"
                                >
                                    <Input placeholder=""  />
                                </Form.Item>
                                <Form.Item 
                                    name="linkedin" 
                                    label="Linkedin"
                                >
                                    <Input placeholder=""  />
                                </Form.Item>
                                <Form.Item 
                                    name="twitter" 
                                    label="Twitter"
                                >
                                    <Input placeholder=""  />
                                </Form.Item>
                            </>
                        )
                    }
                </Form>
            </div>
        </div>
    );
})
export default AboutmeSetup;