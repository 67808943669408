import React, { Component, useState } from "react";
import "./Sidebar.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const LimitedAccessModal = (props) => {
  const [loading, setLoading] = useState(false)
  let photoUnuploaded =  props.photoUnuploaded
  let referralUnfinished = props.referralUnfinished
  let referredLoanOfficerEmails = []
  if (props.referredLoanOfficerEmails) {referredLoanOfficerEmails = props.referredLoanOfficerEmails}

  const resendInvitation = ()=> {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/reinvitereferredloanofficers", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          alert("Looks like there was a problem, please contact Zeitro for help.");
          return;
        }
        response.json().then((js) => {
          setLoading(false)
          if (js.Status !== "OK") {
            alert("Looks like there was a problem, please contact Zeitro for help.");
          } else {
            alert("The invitation emails have been re-sent successfully!");
          }
        });
      })
      .catch(function (err) {
        setLoading(false)
        console.log("Fetch Error:/re/reinvitereferredloanofficers :-S", err);
      });
  
  }

  return (
    <Modal
      show={props.show}
      onHide={props.hide}
      // backdrop="static"
      // keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Limited Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>To unlock full access:</p>
        {photoUnuploaded && <p>- Go to "Profile" to upload your photo. </p>}
        {(referralUnfinished && referredLoanOfficerEmails.length===0) && <p>- Go to "Resources"-"Add Loan Officers" to add at least one loan officer.</p>}
        {(referralUnfinished && referredLoanOfficerEmails.length>0) && <p>- None of your referred loan officers ({referredLoanOfficerEmails.join(", ")}) has signed up yet. Please wait for them to signup or add other loan officers.</p>}
      </Modal.Body>
      <Modal.Footer>
        {photoUnuploaded && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/app/profile";
            }}
          >
            Upload photo
          </Button>
        )}
        {referralUnfinished && (
          <>
          <Button size="sm" onClick={()=>resendInvitation()}>{loading? <Spinner size="sm" animation="border"/>: "Resend Invitation"}</Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/app/resources";
            }}
          >
            Add resources
          </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default LimitedAccessModal;
