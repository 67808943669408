import React, { useState, useEffect, useRef } from "react";
import "./ReferBorrowers.css";
import ListGroup from "react-bootstrap/ListGroup";
import { Row, Col, Form, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AddNewBorrowers from "./AddNewBorrowers";
import CurrentBorrowers from "./CurrentBorrowers";

const { SearchBar, ClearSearchButton } = Search;

const ReferBorrowers = (props) => {
  return (
    <Tabs defaultActiveKey="current" unmountOnExit="true" transition={false} id="resourcestabs">
      <Tab style={{ height: '100%' }} eventKey="current" title="Referred Borrowers">
        <CurrentBorrowers />
      </Tab>
      <Tab style={{ height: '100%' }} eventKey="createnew" title="Refer New Borrowers">
        <AddNewBorrowers />
      </Tab>
    </Tabs>
  )
};
export default ReferBorrowers;
