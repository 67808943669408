import React, { useState } from 'react';
import { Form, Input } from 'antd';
import './FloatingLabelInput.css';

export default function FloatingLabelInput({ type = 'text',value, label, onChange, name, className, style }) {
  return (
    <div className={"floating-label-input-container " + className} style={style} >
      <input type={type} value={value} onChange={onChange} name={name} required autoFocus/>
      <label className={value && 'filled'} >
        {label}
      </label>
    </div>
  );
}


