import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function LogoutPage()  {
    window.location.href = "/"
    window.sessionStorage.removeItem("ZeitroA")
    return (
        <div>
        </div>
    )
}

export default LogoutPage;


