import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Calendar from "react-calendar";
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button as But, Popconfirm, Skeleton } from 'antd';
const { SearchBar, ClearSearchButton } = Search;


const mapStateToProps = (state) => {
    return {
        application: state.application,
        borrower: state.borrower,
    }
}




function DeleteDocument(props) {

    const nukeTheDoc = a => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: props.borrowerid }
        fetch('/agent/deletedoc/' + props.id, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    props.handleClose()
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        props.handleClose()
                    } else {
                        props.handleClose(props.id)
                    }
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    let { handleClose, ...newprops } = props
    return (
        <Modal      {...newprops}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Do you really want to delete {props.name}?</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-5">

                <p className="text-center">
                    This operation is irreversible. Are you sure you want to delete {props.name}?
                </p>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Button variant="zeitro-outline" onClick={props.handleClose}>No, I'll keep the document</Button>
                    </Col>
                    <Col className="text-center">
                        <Button variant="zeitro-primary" onClick={nukeTheDoc}>Yes, delete the document</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
class DocumentManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            url: "",
            download: "",
            delete: false,
            expiryDate: false,
            documentsCheckbox: {},
            zipGeneration: false,
            deletingdoc: false,
            loadingdocs: false,
        }
        this.aref = React.createRef();

        this.maxDate = new Date()
        this.maxDate.setFullYear(this.maxDate.getFullYear() + 10)

        this.columns = [
            {
                dataField: 'progress',
                text: ' ',
            }, {
                dataField: 'filename',
                text: 'File Name',
                sort: true,
                classes: 'divlink',
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.download(row)
                    },
                },
                formatter: (cell, row) => {
                    if (cell.length > 64) {
                        return cell.substring(0, 64) + "..."
                    }
                    return cell
                }
            }, {
                dataField: 'comment',
                text: 'Comment',
                sort: true
            }, {
                dataField: 'created',
                text: 'Created',
                sort: true
            }, {
                dataField: 'delete',
                text: 'Delete',
                classes: 'divlink',
                formatter: (cellContent, row) => (
                    <div style={{ 'textAlign': 'center' }}>
                        <Popconfirm
                            title={"Delete " + row.filename}
                            description={"Are you sure to delete " + row.filename+"? This operation is irreversible."}
                            onConfirm={()=>this.deletedoc(row.id)}
                            icon={
                            <QuestionCircleOutlined
                                style={{
                                color: 'red',
                                }}
                            />
                            }
                        >
                            <a >{this.state.deletingdoc? <Spinner animation="border" size="sm"/> : "Delete"}</a>
                        </Popconfirm>
                    </div>
                ),
            }, {
                dataField: "id",
                text: "id",
                hidden: true,
            }, {
                dataField: "index",
                text: "index",
                hidden: true,
            }
        ];
        if (this.props.whosedocuments === 'customer') {
            this.columns.push({
                dataField: 'expiryDate',
                text: 'Expiry Date',
                classes: 'divlink',
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.expiryDate(row.id, row.filename)
                    },
                }
            }, {
                dataField: 'forlosonly',
                text: 'Shown to borrower',
                formatter: (cellContent, row) => (
                    <div style={{ 'textAlign': 'center' }}>
                        <label>
                            <input type="checkbox" onChange={() => /* change is handeledd by events -> onClick below */ { }} checked={!this.state.documentsCheckbox[row.filename]} />
                        </label>
                    </div>
                ),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        e.stopPropagation()
                        this.toogleForLosOnly(row.id, !this.state.documentsCheckbox[row.filename], row.filename)
                    }
                }
            })
        }
    }
    documentsJSON = (document, index) => {
        let result = {
            progress: <Spinner className="position-relative" size="sm" variant="success"
                animation={((document.progress > 0) && (document.progress < 100)) ? "border" : ""} />,
            filename: document.name,
            comment: document.comment,
            created: document.created,
            delete: "Delete",
            id: document.id,
            index: index
        }
        if (this.props.whosedocuments === 'customer') {
            result.expiryDate = document.expiryDate === '' ? 'Set expiry date' : document.expiryDate
            result.forlosonly = document.forlosonly
        }
        return result
    }

    toogleForLosOnly = (id, forlosonly, filename) => {
        let token = sessionStorage.getItem("ZeitroA")
        const body = {
            ForLosOnly: forlosonly,
            DocumentID: id,
            DocumentName: filename,
            BorrowerID: this.props.borrower.id
        }
        fetch('/agent/setdocumentforlosonly', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(() => this.setState({ documentsCheckbox: { ...this.state.documentsCheckbox, [filename]: forlosonly } }))

    }

    componentDidMount() {
        this.reload()
    }
    reload = () => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.documents = []
        this.setState({ documents: [], loadingdocs: true })
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.props.borrower.id, LoanID: this.props.borrower.loan_id, WhoseDocuments: this.props.whosedocuments === 'customer' ? this.props.whosedocuments : "lender" }
        fetch('/agent/documents', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    this.setState({loadingdocs: false})
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        this.setState({loadingdocs: false})
                    } else {
                        let docs = js.Docs.map(s => {
                            let dt = new Date(s.Uploaded)
                            let result = { rand: Math.random(), id: s.ID, name: s.FileName, comment: s.Comment, mime: s.Mime, created: dt.toLocaleString("en-US"), progress: 0 }
                            if (this.props.whosedocuments === 'customer') {
                                let tmp = s.ExpiryDate.split(' ')[0]
                                tmp = tmp.split('-')
                                let expDate = s.ExpiryDate === '' ? '' : `${tmp[1]}/${tmp[2]}/${tmp[0]}`
                                result = { rand: Math.random(), id: s.ID, name: s.FileName, comment: s.Comment, mime: s.Mime, created: dt.toLocaleString("en-US"), progress: 0, expiryDate: expDate, forlosonly: !s.ForLosOnly }
                            }
                            return result
                        })
                        let docsCheck = {}
                        if (this.props.whosedocuments === 'customer') {
                            for (let i = 0; i < js.Docs.length; i++) {
                                docsCheck[js.Docs[i].FileName] = js.Docs[i].ForLosOnly
                            }
                        }
                        this.setState({ documents: docs, documentsCheckbox: docsCheck, loadingdocs:false })
                    }

                });
            }
        )
            .catch(function (err) {
                this.setState({loadingdocs: false})
                console.log('Fetch Error :-S', err);
            });
    }
    delete = (id, name) => {
        this.setState({ delete: true, documentid: id, documentname: name })
    }
    deletedoc = (id) => {
        this.setState({deletingdoc: true})
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.props.borrower.id }
        fetch('/agent/deletedoc/' + id, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    this.setState({deletingdoc: false})
                    return;
                }
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } 
                    this.setState({deletingdoc: false})
                    this.reload()
                });
            }
        )
            .catch(function (err) {
                this.setState({deletingdoc: false})
                console.log('Fetch Error :-S', err);
            });
    }
    expiryDate = (id, name) => {
        this.setState({ expiryDate: true, documentid: id, documentname: name })
    }
    download = ({ id, filename, index }) => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { ID: this.props.borrower.id }

        let disposition
        let docs = this.state.documents
        docs[index] = { ...docs[index], rand: Math.random(), progress: 1 }
        this.setState({ documents: Array.from(docs) })

        let ifNotPdf = (body) => {
            new Promise((resolve, reject) => {
                const reader = body.getReader();

                resolve(new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }

                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                }))
            }).then(stream => new Response(stream))
                .then(r => r.blob())
                .then(blob => {
                    console.log("blob done")

                    let download = disposition.slice(1).join().replace(/"/g, '')

                    const url = window.URL.createObjectURL(blob);
                    this.setState({ url: url, download: download })

                    this.aref.current.click()
                    this.forceUpdate();
                })
        }

        fetch('/agent/downloaddoc/' + id, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            if (response.headers.get("Content-Type") === 'application/pdf') {
                response.json().then(js => {
                    let byteCharacters = atob(js.Base64);
                    let byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);

                    let a = window.document.createElement('a');
                    a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf;base64' }));
                    a.download = filename;

                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
            } else {
                disposition = response.headers.get("Content-Disposition").split('=')
                return response.body
            }
        }).then(body => {
            if (typeof body !== 'undefined') {
                ifNotPdf(body)
            }
        })
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            })
            .finally(() => {
                let docs = this.state.documents
                docs[index] = { ...docs[index], rand: Math.random(), progress: 0 }
                this.setState({ documents: docs })
            });
    }

    downloadAll = () => {

        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ zipGeneration: true })

        fetch('/agent/downloadalldocs', {
            method: 'POST',
            body: JSON.stringify({ BorrowerID: this.props.borrower.id, DocumentIDs: this.state.documents.map(d => d.id) }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(async response => {

            if (response.status !== 200) {
                return;
            }

            await response.json().then(js => {
                let files = js.Documents;
                let zip = new JSZip();
                let filesFolder = zip.folder(`${this.props.borrower.fullname} documents`)
                for (let i = 0; i < files.length; i++) {
                    let { Name, Content } = files[i];
                    filesFolder.file(Name, Content, { base64: true });
                }
                zip.generateAsync({ type: 'blob' }).then(blob => saveAs(blob, `${this.props.borrower.fullname} documents.zip`))
            })
        }).finally(() => {
            this.setState({ zipGeneration: false })
        })
    }

    setExpiryDate = (date) => {

        let result = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

        let token = sessionStorage.getItem("ZeitroA")
        const body = {
            ExpiryDate: result,
            DocumentID: this.state.documentid,
            DocumentName: this.state.documentname,
            BorrowerID: this.props.borrower.id
        }

        fetch('/agent/setdocumentexpirydate', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(() => {
            let tmp = this.state.documents.map(d => {
                if (d.id === this.state.documentid) {
                    d.expiryDate = result
                }
                return d
            })
            this.setState({ expiryDate: false, documents: cloneDeep(tmp) })
        })

    }


    render() {
        let hideDelete = (docID) => {
            if (typeof docID !== 'undefined') {
                let tmp = this.state.documents.filter(d => d.id !== docID)
                this.setState({ delete: false, documents: cloneDeep(tmp) })
            } else {
                this.setState({ delete: false })
            }
        }
        let hideExpiryDate = (docID, expDate) => {
            this.setState({ expiryDate: false })
        }
        return (
            <div className="text-left mt-3 mx-2 ">
                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}> </a>
                {this.state.loadingdocs ? <Skeleton active/> :<ToolkitProvider
                    bootstrap4
                    keyField='email'
                    data={this.state.documents.map(this.documentsJSON)}
                    columns={this.columns}
                    search >
                    {
                        props => (
                            <div className="p-3">
                                <div style={{padding: '0 20px', display: 'flex',justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div style={{width: '50%', maxWidth: '290px', display: 'flex', alignItems: 'center'}}>
                                        <div><SearchBar {...props.searchProps} /></div>
                                        <div><ClearSearchButton {...props.searchProps} /></div>
                                    </div>
                                    <Button onClick={this.downloadAll}>{this.state.zipGeneration && <Spinner animation="border" size='sm' />} Download all documents</Button>
                                </div>
                                <BootstrapTable id="scaledtable"
                                    striped bootstrap4 bordered={false}
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                    wrapperClasses="table-responsive"
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>}
                

                <DeleteDocument
                    show={this.state.delete}
                    onHide={hideDelete}
                    id={this.state.documentid}
                    name={this.state.documentname}
                    borrowerid={this.props.borrower.id}
                    handleClose={hideDelete}

                />

                {this.state.expiryDate && <div
                    id="myModal"
                    style={{ display: "block" }}
                    className="mymodal"
                    onClick={hideExpiryDate}
                >
                    <div
                        style={{ borderWidth: "0px", backgroundColor: "rgb(0,0,0,0.0)" }}
                        className="mymodal-content"
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <div>
                            <Calendar
                                maxDate={this.maxDate}
                                minDate={new Date(1960, 0, 1)}
                                calendarType={"Hebrew"}
                                onChange={this.setExpiryDate}
                            />
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}
export default connect(mapStateToProps, null)(DocumentManager)
