import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ListGroup from "react-bootstrap/ListGroup";
import { termOptions } from "./common";
import HorizontalSliderV2 from "../../Common/HorizontalSliderV2";
import Select from 'react-select';
import "../../App.css";
import "./RateQuote.css";
import * as com from "../../Common.js";
import NumericalInput, {CommaizedInput} from "../../NumericalInput";
import { withRouter } from "react-router-dom";
import IneligibleProductsModal from "./IneligibleProducts";
import AllRatesModal, {AllRates} from "./AllRates";
import ReportIssuesModal from "./ReportIssues";
import RateReport from "./RateReport";
import Spinner from 'react-bootstrap/Spinner'
import { checkAccess } from "../../Auth";
import { UR_LoanOfficer } from "../../State";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Tips } from "../../Common/Tooltip";
import { Dropdown, Space, Input } from 'antd';
// import {InfoCircleOutlined} from '@ant-design/icons';

const DEFAULT_LOAN_TERM = 30; //year

let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};

const RateQuoteBorrowerVersion = (props) => {
  let customerid = com.getCustomerId();

  const [loanLimits, setLoanLimits] = useState({});
  const [onUpdateRates, setOnUpdateRates] = useState(false)
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");

  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("SANTA CLARA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [monthlyIncome, setMonthlyIncome] = useState(50000);
  const [monthlyDebt, setMonthlyDebt] = useState(1000);
  const [estOtherCosts, setEstOtherCosts] = useState(3195);
  const [escrowWaiver, setEscrowWaiver] = useState(true);

  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(6);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [ineligibleProducts, setIneligibleProducts] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [showAllRates, setShowAllRates] = useState(false);
  const [showReportIssues, setShowReportIssues] = useState(false);
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [showSendReportSpinner, setShowSendReportSpinner] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [previewEmail, setPreviewEmail] = useState("");
  const [previewResult, setPreviewResult] = useState("");
  const [loProfile, setLOProfile] = useState({})
  const [currentMtype, setCurrentMtype] = useState("fixed30")

  const [message, setMessage] = useState("");
  const [showMoreOptions,setShowMoreOptions] = useState(false)
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showSubscribeResult, setShowSubscribeResult] = useState(false);
  const [subscribeResultMessage, setSubscribeResultMessage] = useState("");
  const [validatedSubscribe, setValidatedSubscribe] = useState(false);
  const subscribeFormRef = useRef()
  const [alreadysubscribe, setAlreadysubscribe] = useState(false);
  const [pastSetting, setPastSetting] = useState({});
  const [newSetting, setNewSetting] = useState({});
  const [lenderNotSet, setLendersNotSet] = useState(false)
  const [showDetails, setShowDetails] = useState(false);

  const [showFileModal, setShowFileModel] = useState(false);
  const handleCloseFileModal = () => setShowFileModel(false);
  const handleShowFileModal = () => setShowFileModel(true);

  const [showEditCostDetails, setShowEditCostDetails] = useState(false);
  const handleEditCostDetailsModal = () => setShowEditCostDetails(false);
  const [cannotshop, setCannotshop] = useState([
    { name: "Appraisal Fee", value: 750 },
    { name: "Credit Report", value: 65 },
    { name: "Flood Certification", value: 10 },
    { name: "HOA Certification Fee", value: 350 },
    { name: "Lender Doc Prep", value: 75 },
    { name: "Tax Service Fee", value: 80 },
  ]);
  const [canshop, setCanshop] = useState([
    { name: "Pest Inspection", value: 175 },
    { name: "Title-Closing/Escrow Fee", value: 475 },
    { name: "Title-Courier Fee", value: 35 },
    { name: "Title-Endorsement Fee", value: 25 },
    { name: "Title-Lender's Title Insurance", value: 645 },
    { name: "Title-Loan Tie in Fee", value: 280 },
    { name: "Title-Notary Fee", value: 200 },
    { name: "Title-Processing Fees to Escrow", value: 15 },
    { name: "Title-Wire Fee to Title", value: 15 },
  ]);

  const preselectedTermOptions = [
    { label: "30 Year",value: "fixed30" },
    { label: "15 Year",value: "fixed15" },
    { label: "ARM 5/6",value: "arm56" },
  ];

  let fileSuffixMap = {
    Fairway: "pdf",
    HomePoint: "xlsx",
    PRMG: "xlsx",
    AmeriHome: "xlsx",
    HomePointDelegated: "xlsx",
    FifthThirdBank: "xls",
    RocketPro: "xlsx",
    USBank: "pdf",
    PRMG: "xls",
    Caliber: "pdf",
    LoanDepot: "pdf",
    Amwest: "pdf",
    DivineMortgage: "pdf",
    LoanStore: "pdf",
    NewWaveLending: "pdf",
    WholesaleMortgageLender: "pdf",
    ResiCentral: "pdf",
    Provident: "csv",
    FCB:"xlsx",
    PlanetHome:"xlsx",
    PennyMac:"xlsx",
    FlagStar : "xlsx",
  };

  // useEffect(() => {
  //   console.log(showIneligible, message, ineligibleProducts);
  // });

  const [selectedTerms, setSelectedTerms] = useState(preselectedTermOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.ceil((getLoanAmount() / parseInt(propertyValue)) * 100);
    // let cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
    // let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
    return [ltv, ltv, ltv];
  };

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };

  let downloadRatesheet = (loan) => {
    let s2ab = (s) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    let blob = new Blob([s2ab(atob(loan.FileB))], { type: "" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    if (fileSuffixMap[loan.Lender] == undefined) {
      handleShowFileModal();
      return;
    }

    link.download = loan.Lender + "_Ratesheet." + fileSuffixMap[loan.Lender];
    link.click();
  };


  let handleShowLoansDetails = () => {
    if (showDetails) {
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  };

  let getEstimatedThirdPartyFee = () => {
    let lm = getLoanAmount()
    return com.commaize(3000 + 1000 * lm/1000000)
  }

  let getProducts = (controller) => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;

    setMessage("");

    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();

    if (state != "TEXAS") _county = county;

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: parseFloat(estOtherCosts),
      pmi: pmi,
      getFiles: props.isFromLanding? false : true,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
      escrowWaiver: escrowWaiver,
    };
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");
    let url = "/data/getrefinance/" + customerid
    if (checkAccess([UR_LoanOfficer]) || props.isFromLanding)  {
      url = "/agent/borrowerpriceengine"
    }
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        // 'Cache-Control': 'no-cache, no-store, must-revalidate',
        "Content-Type": "application/json",
        "X-AGENT": props.agentID !== undefined ? props.agentID : com.getLoId(),
      },
      body: JSON.stringify(body),
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          setOnUpdateRates(false)
          setMessage("An internal error has occured");

          return;
        }
        response.json().then((pr) => {
          if (pr.status_code === "12") {
            setLendersNotSet(true)
            setLoading(false);
            setOnUpdateRates(false)
            return
          }

          setOnUpdateRates(false)
          let products = pr.result;
          if (pr.ineligible_reasons != undefined) {
            setIneligibleProducts(pr.ineligible_reasons);
          } else {
            setIneligibleProducts({});
          }
          if (pr.message != undefined) {
            setMessage(pr.message);
          } else {
            setMessage("");
          }
          if (products === undefined) {
            setShowIneligible(true)
            setLoading(false);
            return;
          }
          products.forEach((product) => {
            // console.log("prd", product);
            let lender = product.Lender;
            let fb = product.FileB;
            product.ProductRatesCosts.forEach((pr) => {
              let commonproduct = pr.product;
              let adjustments = pr.appliedAdjustments;
              // if (commonproduct.Term !== loanTerm) return;
              let ausengine = pr.AUSEngine;
              pr.rateCosts.forEach((rate) => {
                let pdkt = {
                  Lender: lender,
                  ...commonproduct,
                  Aus: ausengine,
                  ...rate,
                  Adjustments: adjustments,
                  FileB: fb,
                };

                if (rate.lockDay !== 30) {
                  return;
                }
                /// SOMRTHING ELSE HERE
                pdkt.loanAmount = getLoanAmount();
                // if (!pdkt.Mtype.includes("FHA")) {
                  if (pdkt.Arm != null) {
                    if (pdkt.Arm.fixedperiod == 5) {
                      arm56.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 7) {
                      arm76.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 10) {
                      arm106.push(pdkt);
                    }
                  } else {
                    if (pdkt.Term == 30) {
                      fixed30.push(pdkt);
                    } else if (pdkt.Term == 25) {
                      fixed25.push(pdkt);
                    } else if (pdkt.Term == 20) {
                      fixed20.push(pdkt);
                    } else if (pdkt.Term == 15) {
                      fixed15.push(pdkt);
                    } else if (pdkt.Term == 10) {
                      fixed10.push(pdkt);
                    }
                  }
                // }
              });
            });
          });

          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            // let filteredPrds = products.filter((p)=>{
            //   return p.lender_credit>0
            // })
            // let otherPrds = products.filter((p)=>{
            //   return p.lender_credit<=0
            // })
            // otherPrds.sort(compareRate)
            // let anotherPrds = []
            // if (otherPrds.length > 0) {
            //   anotherPrds = [otherPrds[0]]
            //   anotherPrds[0].lender_credit = 0
            // }
            // filteredPrds = [...filteredPrds, ...anotherPrds]
            let filteredPrds = products
            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              // console.log(filteredPrds[i]);
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0
            let bestPoints = 1000000
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 3000) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 3000)
                bestIndex = i
              }
            }
            return bestIndex
          };


          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);

          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          allRates = {}
          allRates.fixed30 = fixed30
          // allRates.fixed25 = fixed25
          allRates.fixed20 = fixed20
          allRates.fixed15 = fixed15
          allRates.fixed10 = fixed10
          allRates.arm56 = arm56
          allRates.arm76 = arm76
          allRates.arm106 = arm106

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        setOnUpdateRates(false)
        setLoading(false);
      });
  };

  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    };

  const ref = useRef();
  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });

  useEffect(() => {
    const controller = new AbortController()
    getProducts(controller);
    getLoanLimits(controller);

    let token = sessionStorage.getItem("ZeitroA")
    fetch('/agent/getprofile', {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
    }).then(
        response => {
            if (response.status !== 200) {
                alert('Looks like there was a problem. Please contact Zeitro for help.');
                return;
            }
            response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log("Error in /agent/getprofile: " + js.Error)
                } else {
                    let lo = js.Profile
                    // console.log("profile info returned: %s ", JSON.stringify(js))
                    setLOProfile({
                        firstName: lo.FirstName, lastName: lo.LastName,
                        email: lo.Email, phone: lo.Phone, avatarsrc: lo.Image, nmls: lo.Nmls
                    })


                }
            });
        }
    ).catch(function (err) {
        alert('Fetch Error :-S', err);
    });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!showSubscribe) setShowRatePreview(false)
  }, [showSubscribe, showRatePreview]); 

  let showCounties = () => {
    let options = [<option value=""></option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);
      
      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x)}</option>);
      });
    }
    return options;
  };

  let showStates = () => {
    let options = [<option value=""></option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  }

  useEffect(()=>{
    const controller = new AbortController()
    if (onUpdateRates) {
      getProducts(controller);
    }
    return () => {
      controller.abort();
    };
  }, [onUpdateRates])

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
    if (parseInt(downpayment) === 0) {
      setValidated(true);
      alert("Down payment can't be zero");
      return false;
    }
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      alert("Down payment can't be bigger than purchase price");
      return false;
    }
    if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
      setValidated(true);
      alert("FICO score should be in the range of 300 - 1000");
      return false;
    }
    setOnUpdateRates(true)
    return false;
  };

  let changeBalancefixed30 = (v) => {
    setIndexFixed30(v);
  };
  let changeBalancefixed25 = (v) => {
    setIndexFixed25(v);
  };
  let changeBalancefixed20 = (v) => {
    setIndexFixed20(v);
  };
  let changeBalancefixed15 = (v) => {
    setIndexFixed15(v);
  };
  let changeBalancefixed10 = (v) => {
    setIndexFixed10(v);
  };
  let changeBalancearm56 = (v) => {
    setIndexArm56(v);
  };
  let changeBalancearm76 = (v) => {
    setIndexArm76(v);
  };
  let changeBalancearm106 = (v) => {
    setIndexArm106(v);
  };

  let cardStyle = {
    backgroundColor: "#f7f7f7",
    borderRadius: 5,
    margin: "auto",
  };

  let showCard = (type) => {
    if (loans.length === 0) return "";
    let cbfunc = changeBalancefixed30;
    let allloans = fixed30;
    let curindex = indexfixed30;

    if (type == "fixed25") {
      allloans = fixed25;
      cbfunc = changeBalancefixed25;
      curindex = indexfixed25;
    } else if (type == "fixed20") {
      allloans = fixed20;
      cbfunc = changeBalancefixed20;
      curindex = indexfixed20;
    } else if (type == "fixed15") {
      allloans = fixed15;
      cbfunc = changeBalancefixed15;
      curindex = indexfixed15;
    } else if (type == "fixed10") {
      allloans = fixed10;
      cbfunc = changeBalancefixed10;
      curindex = indexfixed10;
    } else if (type == "arm56") {
      allloans = arm56;
      cbfunc = changeBalancearm56;
      curindex = indexarm56;
    } else if (type == "arm76") {
      allloans = arm76;
      cbfunc = changeBalancearm76;
      curindex = indexarm76;
    } else if (type == "arm106") {
      allloans = arm106;
      cbfunc = changeBalancearm106;
      curindex = indexarm106;
    }

    let loan = allloans[curindex];

    if (loan == undefined) {
      return (
        <Container className="text-center p-3" style={cardStyle}>
          <p>No qualified product for loan type {type}</p>
        </Container>
      );
    }

    const getDetails = (loan) => {
      let priceData = []
      let priceColumns = []
      if (loan.prices.CloseOverPoint == null) {
        priceData = [
          {
            lock: loan.prices.LockDayPoint.toFixed(3),
            adj: loan.prices.AdjustmentPoint.toFixed(3),
            comp: loan.prices.CompensationPoint.toFixed(3),
            total:
              loan.points.toFixed(3) + "/$" + com.commaize(loan.lender_credit),
          },
        ];
        priceColumns = [
          {
            dataField: "lock",
            text: "LockDay Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "adj",
            text: "Adjustment Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "comp",
            text: "Compensation %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "total",
            text: "Total",
            headerStyle: { backgroundColor: "white" },
          },
        ];
      } else {
        priceData = [
          {
            lock: loan.prices.LockDayPoint.toFixed(3),
            adj: loan.prices.AdjustmentPoint.toFixed(3),
            comp: loan.prices.CompensationPoint.toFixed(3),
            closeover: loan.prices.CloseOverPoint.toFixed(3),
            total:
              loan.points.toFixed(3) + "/$" + com.commaize(loan.lender_credit),
          },
        ];
        priceColumns = [
          {
            dataField: "lock",
            text: "LockDay Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "adj",
            text: "Adjustment Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "comp",
            text: "Compensation %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "closeover",
            text: "Close Over %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "total",
            text: "Total",
            headerStyle: { backgroundColor: "white" },
          },
        ];
      }



      let ccData = [
        {
          estimated: com.commaize(estOtherCosts),
          lenderfee: com.commaize(loan.lender_fee),
          pi: com.commaize(loan.prepaid_interest),
          total: com.commaize(loan.closing_cost),
        },
      ];
      let ccColumns = [
        {
          dataField: "estimated",
          text: "Est. Costs(LE part B+C)",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "lenderfee",
          text: "Lender Fee",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "pi",
          text: "Est. Prepaid Interest(15 Day)",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "total",
          text: "Total Closing Costs",
          headerStyle: { backgroundColor: "white" },
        },
      ];
      return (
        <div>
          <ListGroup variant="pricedetail">
            <ListGroup.Item>
              <b>
                {loan.Lender} : {loan.Name}
              </b>
            </ListGroup.Item>
            <ListGroup.Item className="align-items-start">
              <div>
                <b>Applied adjustments:</b>
              </div>
              {Object.keys(loan.Adjustments).map((adj) => (
                <div>
                  {adj}: {loan.Adjustments[adj]}
                </div>
              ))}
            </ListGroup.Item>
            <div style={{ backgroundColor: "white" }}>
              <BootstrapTable
                bootstrap4
                condensed={true}
                bordered={false}
                striped={false}
                keyField="lock"
                data={priceData}
                columns={priceColumns}
              />
            </div>
            <div style={{ backgroundColor: "white" }}>
              <BootstrapTable
                bootstrap4
                condensed={true}
                bordered={false}
                striped={false}
                keyField="total"
                data={ccData}
                columns={ccColumns}
              />
            </div>
            <ListGroup.Item>
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() => downloadRatesheet(loan)}
              >
                Download Ratesheet
              </Button>{" "}
            </ListGroup.Item>
            <Modal show={showFileModal} onHide={handleCloseFileModal}>
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Something was wrong with downloading function. Please contact
                Zeitro team.
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </ListGroup>
        </div>
      );
    };

    const onClickMinusClosingCosts = ()=> {
      if (type==="fixed30") {
        setIndexFixed30(Math.max(0, curindex-1));
      } else if (type==="fixed25") {
        setIndexFixed25(Math.max(0, curindex-1));
      } else if (type==="fixed20") {
        setIndexFixed20(Math.max(0, curindex-1));
      } else if (type==="fixed15") {
        setIndexFixed15(Math.max(0, curindex-1));
      } else if (type==="fixed10") {
        setIndexFixed10(Math.max(0, curindex-1));
      } else if (type==="arm56") {
        setIndexArm56(Math.max(0, curindex-1));
      } else if (type==="arm76") {
        setIndexArm76(Math.max(0, curindex-1));
      } else if (type==="arm106") {
        setIndexArm106(Math.max(0, curindex-1));
      }
    }
    const onClickAddClosingCosts = ()=> {
      if (type==="fixed30") {
        setIndexFixed30(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed25") {
        setIndexFixed25(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed20") {
        setIndexFixed20(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed15") {
        setIndexFixed15(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed10") {
        setIndexFixed10(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm56") {
        setIndexArm56(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm76") {
        setIndexArm76(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm106") {
        setIndexArm106(Math.min(allloans.length-1, curindex+1));
      }
    }

    return (
      <Container className="text-center p-3 ratesCard" style={props.isFromLanding ? {backgroundColor:"white"}:{}}>
        <Row>
          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Loan Type</div>
            {type.includes("fixed") ? (
              <div className="ratesCardText">{loan.Term} Year Fixed</div>
            ) : (
              <div className="ratesCardText">
                {loan.Arm.fixedperiod}/{loan.Arm.adjustmentperiod} ARM
              </div>
            )}
          </Col>

          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Rate</div>
            <div className="ratesCardText">
              {loan.base_rate.toFixed(3)}%
            </div>
          </Col>

          <Col  className="ratesCardCol" md>
            <div className="ratesCardTitle">APR</div>
            <div className="ratesCardText">
              {loan.APR.toFixed(3)}%
            </div>
          </Col>

          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Monthly Pay</div>
            <div className="ratesCardText">
              ${com.commaizeFloat(loan.monthly_payment.toFixed(2))}
            </div>
          </Col>

          <Col md={5} className="ratesCardClosingCostCol">
          <div className="ratesCardClosingCostRow">
            <div className="ratesCardClosingCostLabel">Points
              <Tips
                placement="top"
                title=""
                content={
                  <div>
                  Mortgage points are essentially a form of prepaid interest you can choose to pay up front in exchange for a lower interest rate and monthly payments (a practice known as “buying down” your interest rate)
                  </div>
                }
              />
            </div>
            <div className="ratesCardClosingCostAdjusterCol">
              <InputGroup className="ratesCardClosingCostAdjuster mt-1">
                <InputGroup.Append>
                  <InputGroup.Text className="ratesCardClosingCostForm" onClick={()=>onClickAddClosingCosts()}>—</InputGroup.Text>
                </InputGroup.Append>
                <InputGroup.Append>
                  <InputGroup.Text style={{backgroundColor:"white"}}>${com.commaizeFloat(loan.lender_credit.toFixed(0))}</InputGroup.Text>
                </InputGroup.Append>
                <InputGroup.Append>
                  <InputGroup.Text className="ratesCardClosingCostForm" onClick={()=>onClickMinusClosingCosts()}>+</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </div>
          </Col>
        </Row>
        {showDetails ? (
          <div>
            <br /> {getDetails(loan)}
          </div>
        ) : (
          ""
        )}
      </Container>
    );
  };

  let showCards = () => {
    let buttons = []
    let termMap = {
      fixed30:"30 Year",
      fixed25:"25 Year",
      fixed20:"20 Year",
      fixed15:"15 Year",
      fixed10:"10 Year",
      arm56:"ARM 5/6",
      arm76:"ARM 7/6",
      arm106:"ARM 10/6",
    }
    for (let mtype in allRates) {
      const buttonStyle = mtype === currentMtype ? "rate-quoter-mtype-selected" : "rate-quoter-mtype-unselected";
      buttons.push(<Button variant={buttonStyle} id={mtype} onClick={()=>setCurrentMtype(mtype)}>{termMap[mtype]}</Button>)
    }

    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date();

    return (<div className="rate-quoter-term-selector pt-2 pb-2">
      <div className="rate-quoter-term-rates" >Rate as of {today.toLocaleDateString("en-US", options)}</div>
      <div className="rate-quoter-term-buttons">
        {buttons}
      </div>
    </div>)
  };

  let generateTableData = () => {

    if (typeof allRates[currentMtype] === "undefined") {
      return []
    }

    let point1Diff = 999
    let point1I = 0
    let point0Diff = 999
    let point0I = 0
    let indexes = new Set();


    for (var i = 0; i < allRates[currentMtype].length; i++){
      let diff0 = Math.abs(allRates[currentMtype][i].points )
      let diff1 = Math.abs(allRates[currentMtype][i].points -1 )
      if (diff0 < point0Diff) {
        point0I = i
        point0Diff = diff0
      }
      if (diff1 < point1Diff) {
        point1I = i
        point1Diff = diff1
      }
    } 

    indexes.add(point0I)
    indexes.add(point1I)
  
    let start = point1I+1
    while (start <= allRates[currentMtype].length-1 &&  start - point1I <=2 ) {
      indexes.add(start)
      start++
    } 

    if (point0I+1 <= allRates[currentMtype].length-1) indexes.add(point0I+1)

    let data = []
    let indexesSorted = Array.from(indexes).sort((a, b) => a - b)
    for (let i of indexesSorted) {
      let d = allRates[currentMtype][i]
      if (typeof d !== "undefined") {
        data.push({
          rate: d.base_rate.toFixed(3) +"%",
          apr: d.APR.toFixed(3) + "%",
          monthlyPayment: "$"+com.commaizeFloat(d.monthly_payment),
          points: "$"+com.commaizeFloat(d.lender_credit),
        })
      }
    }

    return data

  }

  let showRatesForMtype = () => {
    if (typeof allRates[currentMtype] === "undefined") {
      return ""
    }
    let headerStyle = {
      borderBottom: "#D9D9D9 solid 1px",
      borderTop: "none",
    };
    const rowStyle = { border: "none", fontSize:"28px", padding: '25px 0' };
    let columns = [
      {
        dataField: "rate",
        text: "Rate",
        formatter: (cell, row) => {return <span><strong>{cell}</strong></span>}
      },
      {
        dataField: "apr",
        text: "APR",
      },
      {
        dataField: "monthlyPayment",
        text: "Mo.Pay",
      },
      {
        dataField: "points",
        text: "Pts/Credit",
        headerFormatter: (column, index) => {
          return <div className="d-flex justify-content-center">
          <div >{column.text}</div>
          <Tips
          placement="top"
          title=""
          content={
            <div style={{color:"black"}}>
              A positive number means you have to pay lender to get the lower rates. A negative number means lender will give you a credit to help you with the closing costs.
            </div>
          }
        /></div>
        }
      },
    ]
    if (typeof props.customerid !== "undefined") {
      let url = "https://app.zeitro.com/services/preapp?customerid=" + props.customerid
      if (typeof props.agentID !== "undefined") {
        url += "&loid="+props.agentID
      }
      columns.push(
        {
          dataField: "action",
          text: "",
          formatter: (cell, row) => {return <div><Button style={{paddingLeft:"15px",paddingRight:"15px"}} variant="rate-quoter-get-started" onClick={()=>window.open(url, "_blank")}>Apply</Button></div>}
        },
      )
    }
    let data = generateTableData()
    return (
      <div className="rate-quoter-mtype-table">
        {data.length === 0 ? <div className="ratesWarning">
          <Row className="justify-content-md-center">
            <Col xs={1}>
              <img
                src="/images/warningSign.png"
                style={{ maxWidth: "20px" }}
              />
            </Col>
            <Col xs={11}>No qualified products for this mortgage type.</Col>
          </Row>
        </div> : <BootstrapTable
          bootstrap4
          keyField="rate"
          columns={columns}
          data={data}
          bordered={false}
          striped={false}
          // rowStyle={rowStyle}
          headerClasses={props.isFromLanding?"landing-rate-quoter-header":"rate-quoter-header"}
          rowClasses='rate-quoter-row'
          hover
          condensed
        />}
        
      </div>
    )
  }


  let onMoreOptions = (e) => {
    if (showMoreOptions) {
      setShowMoreOptions(false);
    } else {
      setShowMoreOptions(true);
    }
  };

  let getEditCostsModal = () => {
    let updateTotal = (f1, f2) => {
      let t = 0;
      let all = [...f1, ...f2];
      for (let fe of all) {
        t += parseFloat(fe.value);
      }
      setEstOtherCosts(t);
    };

    let handleCannotshop = (i, e) => {
      let newFormValues = [...cannotshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };

    let addCannotshop = () => {
      setCannotshop([...cannotshop, { name: "", value: "" }]);
    };

    let removeCannotshop = (i) => {
      let newFormValues = [...cannotshop];
      newFormValues.splice(i, 1);
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };
    let handleCanshop = (i, e) => {
      let newFormValues = [...canshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    let addCanshop = () => {
      setCanshop([...canshop, { name: "", value: "" }]);
    };

    let removeCanshop = (i) => {
      let newFormValues = [...canshop];
      newFormValues.splice(i, 1);
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    return (
      <Modal
        show={showEditCostDetails}
        onHide={handleEditCostDetailsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h3>
              <b>Services you cannot shop for</b>
            </h3>
            {cannotshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCannotshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCannotshop()}>
              Add More Fees
            </Button>
            <h3>
              <b>Services you can shop for</b>
            </h3>
            {canshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCanshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCanshop()}>
              Add More Fees
            </Button>
          </Form>
          <h3>
            <b>Total estimated fees: ${com.commaize(estOtherCosts)}</b>
          </h3>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={handleEditCostDetailsModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditCostDetailsModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  let multiSelectStyle = {
    chips: {
      background: "#505050",
    },
    multiselectContainer: {
      color: "black",
    },
    searchBox: {
      border: "none",
      "border-bottom": "2px solid black",
      "border-radius": "0px",
    },
    option: {
      background: "white",
      color: "black",
    },
  };

  let onSelectTerms = (selectedList, selectedItem) => {
    setSelectedTerms(selectedList);
  };

  let onRemoveTerms = (selectedList, removedItem) => {
    setSelectedTerms(selectedList);
  };


  let onSubscribe = (e) => {
    setShowSubscribe(true);
    setShowSubscribeResult(false);
    setSubscribeResultMessage("");
  };

  let getSubscribeModal = () => {
    const handleCloseModal = () => setShowSubscribe(false);
    const handleReset = () => {
      subscribeFormRef.current.reset();
      setValidatedSubscribe(false);
    };
    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        return
      }
      setValidatedSubscribe(true);

      let stname = [];
      selectedTerms.forEach((t) => {
        stname.push(t.label);
      });
      let [ltv, cltv, hcltv] = getLtv();
      let body = {
        Email: event.target.elements.email.value,
        Fname: event.target.elements.fname.value,
        Lname: event.target.elements.lname.value,
        Frequency: event.target.elements.frequency.value,
        SelectedTerms: stname,
        Fixed30lc:
          fixed30[indexfixed30] !== undefined
            ? fixed30[indexfixed30].lender_credit
            : 3000,
        Fixed25lc:
          fixed25[indexfixed25] !== undefined
            ? fixed25[indexfixed25].lender_credit
            : 3000,
        Fixed20lc:
          fixed20[indexfixed20] !== undefined
            ? fixed20[indexfixed20].lender_credit
            : 3000,
        Fixed15lc:
          fixed15[indexfixed15] !== undefined
            ? fixed15[indexfixed15].lender_credit
            : 3000,
        Fixed10lc:
          fixed10[indexfixed10] !== undefined
            ? fixed10[indexfixed10].lender_credit
            : 3000,
        Arm56lc:
          arm56[indexarm56] !== undefined ? arm56[indexarm56].lender_credit : 3000,
        Arm76lc:
          arm76[indexarm76] !== undefined ? arm76[indexarm76].lender_credit : 3000,
        Arm106lc:
          arm106[indexarm106] !== undefined
            ? arm106[indexarm106].lender_credit
            : 3000,
        PropertyType: com.getPropertyType(propertyType),
        LTV: ltv,
        LoanAmount: getLoanAmount(),
        Occupancy: com.getOccupancy(occupancy),
        Purpose: purpose,
        State: state,
        County: county,
        MonthlyIncome: monthlyIncome,
        MonthlyDebt: monthlyDebt,
        Fico: fico,
      };
      setNewSetting(body);
      let token = sessionStorage.getItem("ZeitroA");
      fetch("/agent/subscriberates", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "Content-Type": "application/json",
          "X-AGENT": props.agentID,
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("Looks like there was a problem: " + response);
          return;
        }
        response.json().then((r) => {
          if (r.Status == "OK") {
            setAlreadysubscribe(false);
            setNewSetting(body);
            setShowSubscribeResult(true);
          } else {
            console.log(r);
            setPastSetting(r.Settings);
            setAlreadysubscribe(true);
            setShowSubscribeResult(true);
          }
        });
      });
      handleReset()
      setShowSubscribe(false);
    };

    let [ltv, cltv, hcltv] = getLtv();
    let ratesPreviewData = {
      PropertyType: com.getPropertyType(propertyType),
      LTV: ltv,
      LoanAmount: getLoanAmount(),
      Occupancy: occupancy,
      Purpose: purpose,
      State: state,
      County: county,
      MonthlyIncome: monthlyIncome,
      MonthlyDebt: monthlyDebt,
      Fico: fico,
      selected: selectedTerms,
      fixed30: fixed30[indexfixed30],
      fixed25: fixed25[indexfixed25],
      fixed20: fixed20[indexfixed20],
      fixed15: fixed15[indexfixed15],
      fixed10: fixed10[indexfixed10],
      arm56: arm56[indexarm56],
      arm76: arm76[indexarm76],
      arm106: arm106[indexarm106],
    }
  

    return (
      <Modal
        show={showSubscribe}
        onHide={handleCloseModal}
        size="lg"
        className="ratesReportModal"
        centered
      >
          <Modal.Body className="ratesReportModalBody">
            <div className="ratesReportModalDiv">
                <Form ref={subscribeFormRef} validated={validatedSubscribe} onSubmit={handleSubmit}>
              <div className="ratesReportForm">
                <div>
                  <div className="ratesReportFormCol">
                    <Form.Group controlId="fname">
                      <Form.Label className="RateQuoterformLabel">First Name</Form.Label>
                      <Form.Control placeholder="Your first name" size="sm"/>
                    </Form.Group>
                  </div>
                  <div className="ratesReportFormCol">
                    <Form.Group controlId="lname">
                      <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text"  placeholder="Your last name" size="sm"/>
                  </Form.Group>
                  </div>
                </div>
                <div>
                <div className="ratesReportFormCol">
                  <Form.Group controlId="email">
                    <Form.Label className="RateQuoterformLabel">Email</Form.Label>
                    <Form.Control placeholder="example@email.com" type="email" required size="sm"/>
                  </Form.Group>
                </div>
                <div className="ratesReportFormCol">
                  <Form.Group controlId="frequency">
                    <Form.Label>Report Frequency</Form.Label>
                  <Form.Control as="select" size="sm">
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </Form.Control>
                </Form.Group>
                </div>
                </div>
              </div>
                <div className="ratesReportFormButton">
                  <Button variant="saveSubscribe" type="submit">
                    Save
                  </Button>
                </div>
                </Form>
              {!props.isMobile && <div className="ratesReportPreview">
                <RateReport data={ratesPreviewData} isMobile={props.isMobile}/>
              </div>}
            </div>
        {/* <br/>
            <h3>Or, send the rate report one time and right away:</h3>
            <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" required onChange={(e)=>setPreviewEmail(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
                <Button variant="primary" size="md" style={{marginTop: "32px", width: "80%"}} onClick={handleShowRatePreview}>
                 Preview
                </Button>
              </Col>

            </Form.Row>
         </Form> */}
          </Modal.Body>
          {!props.isMobile && <Modal.Footer>
            {showRatePreview && <RateReport data={previewData}/>}
            {previewResult !== "" && <Container><Row><b style={{size:"120%"}}>{previewResult}</b></Row></Container>}
            {showRatePreview && <Button variant="light" size="md" onClick={()=>setShowRatePreview(false)}>
              Hide Preview
            </Button>}
            <Button variant="light" size="md" onClick={handleCloseModal}>
              {showRatePreview? "Re-config" : "Close"}
            </Button>
            {showRatePreview && <Button variant="primary" size="md" onClick={()=>sendRatesReport()}>
              {showSendReportSpinner ? <Spinner animation="border" variant="light" size="sm" /> : "Send"}
            </Button>}
          </Modal.Footer>}
      </Modal>
    );
  };

  let getSubscribeResultModal = () => {
    const handleCloseModal = () => setShowSubscribeResult(false);
    let s = (string) => {
      return string.toString().replaceAll("_", " ").toLowerCase();
    };
    let showSubscribeMessageExisted = () => {
      let data = [
        {
          field: "Loan Purpose",
          past: s(pastSetting.Purpose),
          new: s(newSetting.Purpose),
        },
        {
          field: "Loan Amount",
          past: pastSetting.LoanAmount,
          new: newSetting.LoanAmount,
        },
        {
          field: "FICO Score",
          past: pastSetting.Fico,
          new: newSetting.Fico,
        },
        {
          field: "Occupancy Type",
          past: s(pastSetting.Occupancy),
          new: s(newSetting.Occupancy),
        },
        {
          field: "Property Type",
          past: s(pastSetting.PropertyType),
          new: s(newSetting.PropertyType),
        },
        {
          field: "Monthly Income",
          past: s(pastSetting.MonthlyIncome),
          new: s(newSetting.MonthlyIncome),
        },
        {
          field: "Monthly Debt",
          past: s(pastSetting.MonthlyDebt),
          new: s(newSetting.MonthlyDebt),
        },
        {
          field: "Email Frequency",
          past: s(pastSetting.Frequency),
          new: s(newSetting.Frequency),
        },
        {
          field: "Preferred Products",
          past: s(pastSetting.SelectedTerms),
          new: s(newSetting.SelectedTerms),
        },
      ];
      let columns = [
        {
          dataField: "field",
          text: "",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "past",
          text: "Past Settings",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "new",
          text: "New Settings",
          headerStyle: { backgroundColor: "white" },
        },
      ];

      return (
        <BootstrapTable
          bootstrap4
          condensed={true}
          bordered={false}
          striped={false}
          keyField="lock"
          data={data}
          columns={columns}
        />
      );
    };

    let handleUpdateSubscribe = () => {
      fetch("/agent/updatesubscribe", {
        method: "POST",
        headers: {
          Cache: "no-cache",
          "Content-Type": "application/json",
          "X-AGENT": props.agentID,
        },
        body: JSON.stringify(newSetting),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("Error in /agent/updatesubscribe:", response)
        }
      });

      setShowSubscribe(false);
      setShowSubscribeResult(false);
    };

    return (
      <Modal
        show={showSubscribeResult}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="ratesReportModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {alreadysubscribe
              ? "This email account has subscribed before"
              : "Done!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="alreadySubscribedModalBody">
          {!alreadysubscribe ? (
            <div>
              <p></p>
              <p style={{ fontSize: "130%" }}>
                {newSetting.Email} will receive rates report on a{" "}
                {newSetting.Frequency} basis.
              </p>
              <p></p>
            </div>
          ) : (
            showSubscribeMessageExisted()
          )}
        </Modal.Body>

        {alreadysubscribe ? (
          <Modal.Footer>
            {!props.isMobile && <Button variant="light" size="md" onClick={handleCloseModal}>
              Close
            </Button>}
            <Button variant="update" onClick={handleCloseModal}>
              Keep Past Setting
            </Button>
            <Button variant="update" onClick={handleUpdateSubscribe}>
              Use New Setting
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button variant="light" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };

  let handleShowRatePreview = () => {
    let [ltv, cltv, hcltv] = getLtv();
    let data = {
      PropertyType: com.getPropertyType(propertyType),
      LTV: ltv,
      LoanAmount: getLoanAmount(),
      Occupancy: occupancy,
      Purpose: purpose,
      State: state,
      County: county,
      MonthlyIncome: monthlyIncome,
      MonthlyDebt: monthlyDebt,
      Fico: fico,
      selected: selectedTerms,
      fixed30: fixed30[Math.max(indexfixed30-1, 0)],
      fixed25: fixed25[Math.max(indexfixed25-1,0)],
      fixed20: fixed20[Math.max(indexfixed20-1,0)],
      fixed15: fixed15[Math.max(indexfixed15-1,0)],
      fixed10: fixed10[Math.max(indexfixed10-1,0)],
      arm56: arm56[Math.max(indexarm56-1,0)],
      arm76: arm76[Math.max(indexarm76-1,0)],
      arm106: arm106[Math.max(indexarm106-1,0)],
    }
  
    setPreviewData(data)
    setShowRatePreview(true)
  }

  let sendRatesReport = () => {
    let html = document.getElementById("ratesreport").outerHTML

    if (previewEmail=="") {
      setPreviewResult("Please provide a valid email.")
      setShowRatePreview(false)
      return
    }

    let token = sessionStorage.getItem("ZeitroA")

    if (loProfile.firstName!==undefined) {
      html +=  `
      <div class="col"><p><b>${loProfile.firstName} ${loProfile.lastName}</b> | Loan Officer<br>
      ${loProfile.email} | ${loProfile.phone}<br>
      NMLS: ${loProfile.nmls}</p></div>
      `
    }
    setShowSendReportSpinner(true)
    fetch('/los/sendratesreport', {
      method: 'POST',
      body: JSON.stringify({ 
        Email: previewEmail,   
        Subject: "Your Personalized Mortgage Rate",
        Body: "<html><body>" + html + "</body></html>" 
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(
      response => {
        if (response.status !== 200) {
          alert("Something went srong when sending the email, please report this issue to Zeitro.")
          setShowRatePreview(false)
          setShowSubscribe(false)
        } else {
          alert("Rates report has been sent successfully.")
          setShowSendReportSpinner(false)
          setShowRatePreview(false)
          setPreviewEmail("")
          setPreviewResult("")
          setShowSubscribe(false)
        }
        response.json().then(js => {
          console.log(js)
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :', err);
      });    
  }

  const handleChangeSelectedTerms = (e)=>{
    setSelectedTerms(e)
  }

  const getLenderNotSetMessage = () => {

    return  <div className="ratesWarning">
    <Row className="justify-content-md-center">
      <Col xs={1}>
        <img
          src="/images/warningSign.png"
          style={{ maxWidth: "20px" }}
        />
      </Col>
      <Col xs={11}>
      {(props.isFromLanding && !props.isFromPreview) ? "This functionality is not ready yet. Please check later." : <div><p>You've not added any lenders yet.</p><p> Please go to "Lenders" on sidebar to config your lenders.</p></div>}
      </Col>
    </Row>
  </div>
  }

  const updateProperValue = (e) => {
    let val = e.target.value
    if (val==="") {
      setPropertyValue(100000)
    } else {
      setPropertyValue(parseInt(val))
    }
  }

  const updateDownpayment = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(0)
    } else {
      setDownpayment(parseInt(val))
    }
  }

  const updateLTV = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(0)
    } else {
      let p = parseInt(val)
      setDownpayment(Math.round(propertyValue * 0.01 * p))
    }
  }

  const items = []

  const getDownpaymentDomain = () => {
    if (purpose === "Purchase") {
      return [propertyValue*0.03, propertyValue*0.6]
    } else {
      return [0, propertyValue*0.97]
    }
  }

  return (
    <div
      id="contain"
      ref={ref}
      style={{ color: "black" }}
      className="w-100 text-center rateQuoteBorrowerVersion"
    >
      <Container style={props.isFromLanding?{background:"rgb(247, 248, 252)"}:{background:"#F7F7F7"}} className="py-4">
        <Col className="text-center" md="auto">
          <Form
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Col md xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0", fontWeight:"600" }}>
                    Loan Purpose
                  </Form.Label>
                  <Form.Control
                    style={{height:"40px"}}
                    size="sm"
                    as="select"
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                  >
                    <option value="Purchase">Purchase</option>
                    <option value="RateTermRefi">Refinance</option>
                    <option value="CashOutRefi">Cash Out Refinance</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0", fontWeight:"600" }}>State</Form.Label>
                  <Form.Control
                    style={{height:"40px"}}
                    size="sm"
                    as="select"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                    required
                  >
                    {showStates()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {state !== "TEXAS" ? (
                <Col md xs={6}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0", fontWeight:"600" }}>
                      County
                    </Form.Label>
                    <Form.Control
                      style={{height:"40px"}}
                      size="sm"
                      as="select"
                      id="country"
                      name="country"
                      onChange={(e) => setCounty(e.target.value)}
                      required
                    >
                      {showCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0", fontWeight:"600" }}>
                    Credit Score
                  </Form.Label>
                  <Form.Control
                    style={{height:"40px"}}
                    size="sm"
                    as="input"
                    onChange={(e) =>
                      setFico(
                        parseInt(e.target.value === "" ? 0 : e.target.value)
                      )
                    }
                    value={fico}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>  

            <Form.Row>
              <Col xs={12} md={4} lg={4}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0", fontWeight:"600" }}>
                    {purpose === "Purchase"
                      ? "Est. Purchase Price"
                      : "Home Value"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign" style={{fontSize:"16px", marginTop:"8px"}}>$</div>
                    <CommaizedInput style={{ border:"none", maxWidth:"100px", fontSize:"16px", maxHeight: "40px"}} value={propertyValue} onChange={updateProperValue} />
                    <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div>
                  </div>
                </Form.Group>
              </Col>

              <Col xs={11} md={4} style={{display:"-webkit-inline-box"}}>
                <Form.Group className="text-left rate-quoter-downpay">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0", fontWeight:"600" }}
                  >
                    {purpose === "Purchase"
                      ? "Down Payment"
                      : "Mortgage Balance"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign" style={{fontSize:"16px",marginTop:"8px"}}>$</div>
                    <CommaizedInput style={{border:"none", maxWidth:"100px", fontSize:"16px", maxHeight: "40px"}} value={downpayment} onChange={updateDownpayment} />
                    <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={getDownpaymentDomain()} defaultValues={[downpayment]} onChange={setDownpayment} onUpdate={setDownpayment} step={1000}/></div>
                    </div>
                </Form.Group>
                {purpose === "Purchase" ?  <Input className="rate-quoter-ltv" suffix="%" defaultValue={100-getLtv()[0]} value={100-getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{width:90, borderRadius: "4px", paddingTop: "7px"}}/> :
                    <Input className="rate-quoter-ltv" suffix="%" defaultValue={getLtv()[0]} value={getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{width:90, borderRadius: "4px", paddingTop: "7px"}}/>
                }
              </Col>


              <Col xs={12} md={4} className="rate-quoter-filters-wrap">
              
              <Dropdown
              menu={{
                items,
              }}
                dropdownRender={(menu) => (
                        <div >
                          {React.cloneElement(menu, {
                  style: {
                    boxShadow: 'none',
                  },
                })}
                    <Space
                      style={{
                        padding: 30,
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
                        backgroundColor:"white",
                      }}
                      className="d-block"
                    >
                      <div >
                        <Form.Group className="text-left mr-4">
                        <Form.Label style={{ marginBottom: "0" }}>
                          Monthly Income
                        </Form.Label>
                        <NumericalInput
                          size="sm"
                          value={monthlyIncome}
                          required
                          onChange={(e) => setMonthlyIncome(e.target.value)}
                          min={0}
                        />
                      </Form.Group>
                      </div>
                      <div>
                      <Form.Group className="text-left mr-4">
                        <Form.Label style={{ marginBottom: "0" }}>
                        Monthly Liability
                        </Form.Label>
                        <NumericalInput
                          size="sm"
                          value={monthlyDebt}
                          required
                          onChange={(e) => setMonthlyDebt(e.target.value)}
                          min={0}
                        />
                      </Form.Group>
                      </div>
                      <div>


                  <Form.Group controlId="propertytype" className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Property Type
                    </Form.Label>
                    <Form.Control
                      name="propertytype"
                      as="select"
                      size="sm"
                      type="text"
                      required
                      value={propertyType}
                      onChange={(e) => setPropertyType(e.target.value)}
                    >
                      <option value="singlefamilydetached">
                        Single Family Home, Detached
                      </option>
                      <option value="singlefamilyattached">
                        Single Family Home, Attached
                      </option>
                      <option value="condominiumdetached">
                        Condominium, Detached
                      </option>
                      <option value="condominium">Condominium, Attached</option>
                      <option value="pudattached">
                        Planned Unit Development, attached
                      </option>
                      <option value="puddetached">
                        Planned Unit Development, detached
                      </option>
                      <option value="twotofourfamily">
                        2 to 4 Unit Multifamily
                      </option>
                      <option value="manufacturedhome">
                        Manufactured Home
                      </option>
                    </Form.Control>
                  </Form.Group>
                </div>
                {propertyType === "twotofourfamily" ? (
                  <div>
                    <Form.Group controlId="propertytype" className="text-left ">
                      Number of Units
                      <Form.Control
                        name="propertytype"
                        as="select"
                        size="sm"
                        type="text"
                        required
                        value={units}
                        onChange={(e) => setUnits(e.target.value)}
                      >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <Form.Group controlId="occupancy" className="text-left ">
                    <Form.Label
                      className="text-left "
                      style={{ marginBottom: "0" }}
                    >
                      Occupancy
                    </Form.Label>
                    <Form.Control
                      name="occupancy"
                      as="select"
                      size="sm"
                      defaultValue={occupancy}
                      onChange={(e) => {
                        onOccupancy(e.target.value);
                      }}
                    >
                      <option value="principal">Primary Residence</option>
                      <option value="secondhome">Second Home</option>
                      <option value="investment">Investment</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div >
                    <Form.Group controlId="impound" className="text-left ">
                      Impound/Escrow
                      <Form.Control
                      name="propertytype"
                      as="select"
                      size="sm"
                      type="text"
                      required
                      value={escrowWaiver}
                      onChange={(e) => setEscrowWaiver(e.target.value === "true" ? true : false)}
                    >
                      <option value={true}>No</option>
                      <option value={false}>Yes</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                  </Space>
                </div>
              )}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space className="dropdownFilter">
                <img src="/images/moreFilters.png" className="dropdownFilterImage" />
                  More filters
                </Space>
              </a>
            </Dropdown>

            <Button type="submit" variant="rate-quoter-update">
                Check rates
            </Button>
            </Col>
                {/* <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Monthly Liability
                    </Form.Label>
                    <NumericalInput
                      size="sm"
                      value={monthlyDebt}
                      required
                      onChange={(e) => setMonthlyDebt(e.target.value)}
                      min={0}
                    />
                  </Form.Group>
                </Col> */}
                {/* <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Estimated Fees (LE part B+C){" "}
                    </Form.Label>
                    <NumericalInput
                      size="sm"
                      value={estOtherCosts}
                      required
                      onChange={(e) => setEstOtherCosts(e.target.value)}
                      min={0}
                    />
                  </Form.Group>
                </Col> */}
              </Form.Row>
            {/* ) : (
              ""
            )}
            <Form.Row>
                <Button
                  variant="rateQuoterAdvancedOptions"
                  onClick={onMoreOptions}
                  size="sm"
                >
                  {showMoreOptions ? "Less Options" : <>Advanced Options<img src="/images/bluePlusInCircleMark.png" style={{maxWidth:"16px", paddingLeft:"5px"}}/></>}
                  
                </Button>{" "}
            </Form.Row>
            <hr className="advancedOptionsDivider" />
            <Form.Row className="text-center rateQuoterUpdateButton">
              <Col>
              <Button type="submit" variant="rateQuoterUpdateButton" size="md">
                  Update Results
              </Button>
              </Col>
            </Form.Row> */}

            {/* {!props.isFromLanding &&             <Form.Row>
              <Col xs className="text-center w-100">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Show Loan Details"
                  style={{ paddingTop: "10px" }}
                  onChange={handleShowLoansDetails}
                />
                {showDetails && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    onClick={() => setShowAllRates(true)}
                    style={{ marginTop: "10px" }}
                  >
                    See All Rates
                  </Button>
                )}
                {showDetails && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    onClick={() => setShowIneligible(true)}
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  >
                    Ineligible Product
                  </Button>
                )}
                {showDetails && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    onClick={() => setShowReportIssues(true)}
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  >
                    Report Issues
                  </Button>
                )}
              </Col>
            </Form.Row>} */}
          </Form>
        </Col>
      </Container>

      {loading ? (
        <Row>
          <div className="text-center w100 justify-content-center  align-content-center">
            <div id="spinner" className="pt-5 mt-4 ml-5 text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        </Row>
      ) : (
        <Container className="pl-0 pr-0">
          {lenderNotSet && getLenderNotSetMessage()}
          {(!lenderNotSet && message === "") ? (
            <div>
              <div>{showCards()}</div>
              <div>{showRatesForMtype()}</div>
              {props.isFromLanding &&(<div className="getRatesReportDiv">
                <Container className="getRatesReport">
                <div className="getRatesReportCol1"><img className="getRatesReportImg" src="/images/getRatesReport.png" style={{maxWidth:"60px"}} /></div>
                <div className="getRatesReportCol2">
                  <div className="getRatesReportTitle">Get your personalized rates report</div>
                  <div className="getRatesReportText">Enter your email address and delivery frequency, you will receive a personalized rates report based on your inputs. </div>
                </div>
                <div className="getRatesReportCol3">
                  <Button
                    variant="getRatesReportImgButton"
                    onClick={onSubscribe}
                    >
                    Get Rates Report
                  </Button>
                  </div>
                </Container>
                  <div className="getRatesReportButton2">
                    <Button
                      variant="getRatesReportImgButton"
                      onClick={onSubscribe}
                      >
                      Get Rates Report
                    </Button>
                  </div>
              </div>)}
            </div>
            

          ) : (
            <div className="text-wrap w-100">{message}</div>
          )}
        </Container>
      )}

      {showReportIssues && (
        <ReportIssuesModal
          show={true}
          onHide={() => setShowReportIssues(false)}
        />
      )}
      {(showIneligible && !props.isFromLanding) && (
        <IneligibleProductsModal
          show={true}
          onHide={() => setShowIneligible(false)}
          ineligibleProducts={ineligibleProducts}
          message={message}
        />
      )}
      {showAllRates && (
        <AllRatesModal
          show={true}
          onHide={() => setShowAllRates(false)}
          allRates={allRates}
        />
      )}
      {showEditCostDetails ? getEditCostsModal() : ""}
      {showSubscribeResult ? getSubscribeResultModal() : ""}
      {showSubscribe && !showSubscribeResult ? getSubscribeModal() : ""}
    </div>
  );
};
export default withRouter(RateQuoteBorrowerVersion);
