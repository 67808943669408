import React, { Component } from "react";
import "./Recaptcha.css"

export default class Recaptcha extends Component {
    constructor(props) {
        console.log(props)
        super(props)
    }


    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.onload = () => this.props.onload();
      
        document.body.appendChild(script);
      }
      scriptLoaded = () => {

      }
    callback = (a) => {
        //alert(a)
    }
    render() {

        return (
            <div id="g-recaptcha" className="g-recaptcha text-left" data-sitekey="6Lfss-UUAAAAAAZJ2MPSLAbpCoVoUuLWBCWtfLun" ></div>
        )   
    }
}