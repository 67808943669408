import React, { useState, useEffect, useRef } from "react";
import "./ReferredRealtors";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as com from "../../Common";
import StateSelector from "../../StateSelector";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const { SearchBar, ClearSearchButton } = Search;

const CurrentReferredRealtors = (props) => {
  const [referredRealtors, setReferredRealtors] = useState([]);
  const [toRemoveReferredRealtor, setToRemoveReferredRealtor] =
    useState({});
  const [showRemoveREModal, setShowRemoveREModal] = useState(false);
  const handleCloseRemoveREModal = () => setShowRemoveREModal(false);
  const [loading, setLoading] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const [toEditResource, setToEditResource] = useState({});

  const onRemoveRE = (row) => {
    setToRemoveReferredRealtor(row);
    setShowRemoveREModal(true);
  };

  const removeReferredRealtor = () => {
    console.log("removing", toRemoveReferredRealtor);

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/removereferredrealtor", { 
      method: "POST",
      body: JSON.stringify(toRemoveReferredRealtor),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      getReferredRealtors();
    });
    setShowRemoveREModal(false);
  };
  const getRemoveREModal = () => {
    return (
      <Modal show={showRemoveREModal} onHide={handleCloseRemoveREModal}>
        <Modal.Header closeButton>
          <Modal.Title>Removing Loan Officers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {toRemoveReferredRealtor.Name},{" "}
          {toRemoveReferredRealtor.Email}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseRemoveREModal}>
            Close
          </Button>
          <Button onClick={removeReferredRealtor}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const resendInvitation = (row)=> {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/reinvitereferredrealtors", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(row),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          alert("Looks like there was a problem, please contact Zeitro for help.");
          return;
        }
        response.json().then((js) => {
          setLoading(false)
          if (js.Status !== "OK") {
            alert("Looks like there was a problem, please contact Zeitro for help.");
          } else {
            alert("The invitation email has been re-sent successfully!");
          }
        });
      })
      .catch(function (err) {
        setLoading(false)
        console.log("Fetch Error:/re/reinvitereferredrealtors :-S", err);
      });
  
  }

  const loStatusFormatter = (_, row) => (
    <div>{row.ReID === "" ? <div>Unregistered<Button variant="resend" size="sm" onClick={()=>resendInvitation(row)}>{loading? <Spinner size="sm" animation="border"/>: "Resend Invitation"}</Button></div> : "Registerred"}</div>
  );

  let headerStyle = {
    backgroundColor: "white",
    borderBottom: "solid 2px",
    borderTop: "solid 2px",
    fontSize: "120%",
    wordBreak: "break-word",
  };
  let columnStyle = { wordBreak: "break-word" };

  let loColumns = [
    {
      dataField: "FirstName",
      text: "First Name",
      headerStyle: headerStyle,
    },
    {
      dataField: "LastName",
      text: "Last Name",
      headerStyle: headerStyle,
    },
    {
      dataField: "Email",
      text: "Email",
      headerStyle: headerStyle,
      style: columnStyle,
    },
    {
      dataField: "ReID",
      text: "Status",
      isDummyField: true,
      formatter: loStatusFormatter,
      headerStyle: headerStyle,
      sort: true,
    },
    // {
    //   dataField: "Remove",
    //   text: "",
    //   isDummyField: true,
    //   formatter: (cell, row) => (
    //     <div>
    //       <Button
    //         variant="editresource"
    //         onClick={() => {
    //           setToEditResource(row);
    //           setShowEditModal(true);
    //         }}
    //       >
    //         Edit
    //       </Button>{" "}
    //       <Button variant="remove" onClick={() => onRemoveRE(row)}>
    //         Remove
    //       </Button>
    //     </div>
    //   ),
    //   headerStyle: headerStyle,
    // },
  ];

  const getReferredRealtors = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getreferredrealtors", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((r) => {
        console.log(r);
        setReferredRealtors(r);
      });
    });
  };

  useEffect(() => {
    getReferredRealtors();
  }, []);

 return (
    <div>
      <div className="realtors">
        <h5 className="mt-4">
          <b>Real Estate Agent Contacts</b>
        </h5>
        {!referredRealtors ? (
          <h5 className="mt-5">You haven't added any real estate contact yet.</h5>
        ) : (
          <ToolkitProvider
            bootstrap4
            keyField="email"
            data={referredRealtors}
            columns={loColumns}
            search
          >
            {(props) => (
              <div className="mt-4">
                <Row className="ml-4">
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton {...props.searchProps} />
                </Row>

                <BootstrapTable
                  id="resources"
                  bootstrap4
                  bordered={false}
                  // pagination={paginationFactory({
                  //   hidePageListOnlyOnePage: true,
                  // })}
                  {...props.baseProps}
                  filter={filterFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
      {showRemoveREModal ? getRemoveREModal() : ""}
    </div>
  );
};
export default CurrentReferredRealtors;
