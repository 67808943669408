import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Row from 'react-bootstrap/Col'

import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import * as com from "../Common"
import { getCurrentState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { tooltip } from '../Common/Tooltip'

const mapStateToProps = (state) => {
    let getOccupation = (employed, selfemployed) => {
        if (employed === "employed" && !selfemployed) return "employed"
        if (employed === "employed" && selfemployed) return "selfemployed"
        return "unemployed"

    }
    return {
        application: state.application,
        hascoborrower: state.application.hascoborrower,
        property: state.application.property,
        cohabiting: state.application.cohabiting,
        financingsameproperty: state.application.financingsameproperty,
        borroweroccupation: getOccupation(state.application.borrower.occupation.hasoccupation,
            state.application.borrower.occupation.selfemployed),
        coborroweroccupation: "co" + getOccupation(state.application.coborrower.occupation.hasoccupation,
            state.application.coborrower.occupation.selfemployed),
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    updateCurrentResidence: (event) => {
        dispatch(act.UpdateCurrentResidence(event.target.value))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    updateCohabiting: (event) => {
        dispatch(act.UpdateCohabiting(event.target.checked))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event.target.value))
    },

    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },

    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event.target.value))
    },

    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationCheck: (check, who, verb) => {
        dispatch(act.ChangeOccupationInfo(check, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },

    updateApplicationCheck: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.checked, verb))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    addRemoteCoborrower: (i) => {
        dispatch(act.AddRemoteCoborrower(i))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },      
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },    
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },     
});

class Coborrower extends Component {

    constructor(props) {
        super(props);
        let has = props.hascoborrower ? "" : "selected"
        let doenothave = props.hascoborrower ? "selected" : ""
        this.state = {
            selectedalone: has,
            withcoborrower: doenothave,
            show: false,
            showSelf: false,
            showCashout: false,
            qualify: "",
            additional: ""
        }
        this.radiocoborrower = React.createRef()

        let needsReset = false
        if(this.props.progress.lasttouch == null  ) {
          needsReset = true
        } else {
            let now = new Date()
            let then = new Date(this.props.progress.lasttouch)
            let days = (now - then)/(3600*24*1000)
            if(days > 7)
              needsReset = true
        }
        let resettableStates = ['interviewstarted', 'productselected', 'interviewfinished']
    
        if(needsReset && resettableStates.includes(this.props.application.status) ) {
          this.props.updateChangeProgress(0, "max")
          this.props.updateChangeProgress(0 , "interview")
          let d = new Date()        
          this.props.updateChangeLastTouch( d.toLocaleString() ) 
          this.props.changeReviewedLoans(0)
          getCurrentState()   
          this.forceUpdate()  
        }
    }
    componentDidMount() { }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    handleSubmit = e => {
        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo({ target: { value: st.O_Own } }, com.borrower, "presentaddressownership")
        return true
    }

    chooseBorrower = (event) => {
        com.touch()
        this.radioself.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Alone } }) // this is a hack
    }
    chooseCoborrower = (event) => {
        com.touch()
        this.radiocoborrower.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })  // this is a hack
    }
    updateCoborrower = (event) => {
        com.touch()
        if (event.target.id === "multiple") {
            if (this.props.application.remotecoborrowers.length === 0)
                this.props.addRemoteCoborrower()
        }
        
        if (event.target.id === "withcoborrower") {            
            this.updateMarried({ target: { value: "true" } })
        }
        if (event.target.id === "alone") {
            this.updateMarried({ target: { value: "false" } })
        }
        this.props.updateCoborrower(event)
    }
    updateCohabiting = (e) => {
        com.touch()
        let val = e.target.value === "true"
        this.props.updateCohabiting({ target: { checked: val } })
    }
    updateMarried = (e) => {
        com.touch()
        let val = e.target.value === "true"
        this.props.updateApplicationAttribute(val, st.MS_Married)
    }
    changeEmployed = e => {
        switch (e.target.value) {
            case "employed":
                this.props.changeOccupationCheck(true, com.borrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.borrower, "selfemployed")
                break;
            case "selfemployed":
                this.props.changeOccupationCheck(true, com.borrower, "hasoccupation")
                this.props.changeOccupationCheck(true, com.borrower, "selfemployed")
                break;
            case "unemployed":
            default:
                this.props.changeOccupationCheck(false, com.borrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.borrower, "selfemployed")
                break;
        }
    }
    changeCoEmployed = e => {
        com.touch()
        switch (e.target.value) {
            case "coemployed":
                this.props.changeOccupationCheck(true, com.coborrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.coborrower, "selfemployed")
                break;
            case "coselfemployed":
                this.props.changeOccupationCheck(true, com.coborrower, "hasoccupation")
                this.props.changeOccupationCheck(true, com.coborrower, "selfemployed")
                break;
            case "counemployed":
            default:
                this.props.changeOccupationCheck(false, com.coborrower, "hasoccupation")
                this.props.changeOccupationCheck(false, com.coborrower, "selfemployed")
                break;
        }
    }
    selfEmployedAsessment = () => {

    }

    changeMainPropertyAppraisal = e => {
        com.touch()
        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo(e, com.borrower, "marketvalue")
        this.props.changeMainPropertyAppraisal(e)
    }
    renderCoborrowerEmployment() {
        if (this.props.hascoborrower !== st.HC_Alone) {
            return (
                <Form.Row className="pt-0  my-0 py-0">

                    <Col className="text-left text-nowrap" >
                        <Form.Label className="text-left" >Additional borrower employment status:</Form.Label>
                        <Form.Group controlId="employment" >

                            <Form.Control name="employment" as="select" size="sm"
                                defaultValue={this.props.coborroweroccupation}
                                onChange={this.changeCoEmployed}
                            >
                                <option value="coemployed">Additional borrower currently employed</option>
                                <option value="coselfemployed">Additional borrower currently self-employed</option>
                                <option value="counemployed">Additional borrower not employed</option>

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="text-left ">
                        <div hidden={this.props.coborroweroccupation !== "coselfemployed"} className="pt-2"> <br /><span style={{ fontSize: '0.8em' }} className="divlink mt-4 bluish" onClick={this.selfEmployedAsessment}> <b>&lt;-</b><i className="fas fa-question-circle text-info mr-1"></i> See if you qualify</span>
                        </div>
                    </Col>
                    <Col></Col>
                </Form.Row>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
    showRentOrOWn = () => {
        let changeBorrowerInfo = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)
            }
        }
        if (this.props.property.purpose === st.POL_Refinance || this.props.property.purpose === st.POL_Cashoutrefinance)
            return ""
        else
            return (

                <Form.Group controlId="currentdwelling" >
                    <Form.Label className="text-left" >Your current residence:</Form.Label>
                    <Form.Control required name="currentdwelling" as="select" size="sm"
                        defaultValue={this.props.application.borrower.presentaddressownership}
                        onChange={changeBorrowerInfo(com.borrower, "presentaddressownership")}
                    >
                        <option value="">...</option>
                        <option value={st.O_Rent}>I am renting</option>
                        <option value={st.O_Own}>I own it</option>
                        <option value={st.O_Rentfree}>I live rent free</option>
                    </Form.Control>
                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid" >
                        Please make a selection.
                        </Form.Control.Feedback>
                </Form.Group>

            )
    }
    onFirst = e => {
        com.touch()
        let out = "true" === e.target.value
        this.props.updateApplicationAttribute(out, "firsttimebuyer")
    }

    firstTime = () => {
        if (this.props.property.purpose !== st.POL_Purchase || this.props.application.borrower.presentaddressownership === st.O_Own)
            return ""
        return (
            <div className="mx-1 mt-3 text-wrap text-left">

                <div className="d-flex sectionquestion text-left"><span>{tooltip("Are you a first time home buyer?",
                    <div>
                        A first time home buyer is someone who meets any of the following conditions:
<ul>
                            <li>
                                An individual who has not owned a principal residence for three years. If you’ve owned a home but your spouse has not, then you can purchase a place together as first-time homebuyers.
</li><li>
                                A single parent who has only owned a home with a former spouse while married.
</li><li>
                                A displaced homemaker who has only owned with a spouse.
</li><li>
                                An individual who has only owned a principal residence not permanently affixed to a permanent foundation in accordance with applicable regulations.
</li><li>
                                An individual who has only owned a property that was not in compliance with state, local, or model building codes—and that cannot be brought into compliance for less than the cost of constructing a permanent structure.
</li>
                        </ul>
                    </div>, "auto"

                )}</span></div>

                <Form.Group>
                    <fieldset>
                        <div className="d-flex  ">
                            <div className="mr-3">
                                <input required onChange={this.onFirst} value={true} checked={true === this.props.application.firsttimebuyer} type="radio" id="firsttimebuyer" name="freeandclear"></input>
                                <label id="firsttimebuyerlabel" className="zeitro-radio divlink" htmlFor="firsttimebuyer">Yes, I am</label></div>
                            <div><input required onChange={this.onFirst} value={false} checked={false === this.props.application.firsttimebuyer} type="radio" id="firsttimebuyernot" name="freeandclear"></input>
                                <label id="firsttimebuyernotlabel" className="zeitro-radio divlink" htmlFor="firsttimebuyernot">No, I'm not</label></div>
                        </div>
                    </fieldset>
                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid" >
                        Please specify if you are are a first time buyer.
                        </Form.Control.Feedback>
                </Form.Group>

            </div>

        )
    }

    sameProperty = () => {
        /*
        let updateProgram = e => {
            this.props.changeMainPropertyRefinanceAttribute(e.target.value, "currentprogram")
        }
        let updateCurrentRate = e => {
            this.props.changeMainPropertyRefinanceAttribute(e.target.value, "currentrate")

        }
        */
        let onSame = e => {
            com.touch()
            let out = e.target.value === "true"
            this.props.updateSameProperty({ target: { checked: out } })
            if(out)
                this.props.changeMainPropertyOccupancy({ target: { value: "principal" } })
        }
        if (this.props.property.purpose !== st.POL_Refinance && this.props.property.purpose !== st.POL_Cashoutrefinance)
            return ""
        else
            return (
                <div>
                    <div className="d-flex mt-3 sectionquestion text-left">Are you refinancing your primary residence? </div>

                    <Form.Group controlId="financesamesame" className="text-left" >
                        <fieldset>
                            <div className="d-flex  ">
                                <div className="mr-3">
                                    <input required onChange={onSame} value={true} checked={true === this.props.financingsameproperty} type="radio" id="financingsameproperty" name="financingsameproperty"></input>
                                    <label id="financingsamepropertylabel" className="zeitro-radio divlink" htmlFor="financingsameproperty">Yes, I am</label>
                                </div>
                                <div>
                                    <input required onChange={onSame} value={false} checked={false === this.props.financingsameproperty} type="radio" id="financingsamepropertynot" name="financingsameproperty"></input>
                                    <label id="financingsamepropertynotlabel" className="zeitro-radio divlink" htmlFor="financingsamepropertynot">No, I'm not</label>
                                </div>
                            </div>
                        </fieldset>
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please specify if you are refiancing primary residence.
                        </Form.Control.Feedback>
                    </Form.Group>



                </div>
            )
    }

    updateCoborrowerAssetsUsed = (e) => {
        com.touch()
        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: "withcoborrower" } })  // this is a hack
        }
        this.props.updateApplicationCheck(e, "assetsofotherpersonused")
    }
    updateCoborrowerLiabilitiesUsed = (e) => {
        com.touch()
        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })  // this is a hack
        }

        this.props.updateApplicationCheck(e, "liabilitiesofotherpersonrequired")
    }

    figureOut = () => {
        this.setState({ show: true })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    handleSelfClose = () => {
        this.setState({ showSelf: false })
    }
    selfEmployedAsessment = () => {
        this.setState({ showSelf: true })

    }
    qualifyForCashout = () => {
        this.setState({ showCashout: true })
    }
    handleCloseCashout = () => {
        this.setState({ showCashout: false })
    }
    changeMainPropertyPurpose = e => {
        com.touch()
        this.props.updateSelectField(new st.ChosenLoan(), "selectedloan")
        this.props.changeReviewedLoans(0)
 
        if (e.target.value === "cashoutrefinance") {
            this.setState({ showCashout: true })
        }
        if (this.props.property.purpose !== "purchase") { // if change from purpose, clear gifts/insurance
            this.props.clearBorrowerGift()
            this.props.clearBorrowerInsurance()          
        }
        this.props.changeMainPropertyPurpose(e)
    }
    render() {


        let appraisalVerbage = () => {
            switch (this.props.property.purpose) {
                case st.POL_Purchase:
                case st.POL_Refinance:
                case st.POL_Cashoutrefinance:
                    return "Estimated home value"
                default:
                    return "You should not see this line!"
            }
        }

        let onQualify = e => {
            this.setState({ qualify: e.target.value })
        }
        let onAdditional = e => {
            this.setState({ additional: e.target.value })

        }
        let onContract = e => {
            com.touch()
            let val = e.target.value === "true"
            this.props.changeMainPropertyAttribute(val, "hascontract")
        }
        let onBudget = e => {
            com.touch()
            this.props.changeMainPropertySalesPrice(e)
            this.props.changeMainPropertyAppraisal(e)
        }
        return (
            <div className=" text-left mb-3">

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showCashout} onHide={this.handleCloseCashout} >
                    <Modal.Header closeButton>
                        <Modal.Title>Do you qualify for cash out refinance?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">

                        <div className="my-4 ">The borrower must be on the property title for at least 6 months in order to qualify for cash out refinance.
                            </div>
                        <div>Have you been on the title for more than 6 months?</div>
                        <div className="mt-3 mb-3">
                            <input type="radio" checked={this.state.qualify === "yes6month"} onChange={onQualify} value="yes6month" name="forcashout" id="yes6month" />
                            <label id="yes6monthlabel" className="divlink" htmlFor="yes6month">Yes, I have</label>
                            <input className="ml-3" type="radio" checked={this.state.qualify === "no6month"} onChange={onQualify} value="no6month" name="forcashout" id="no6month" />
                            <label id="no6monthlabel" className="divlink" htmlFor="no6month">No, I have not</label>
                        </div>
                        {
                            this.state.qualify === "yes6month" ? <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }} > Yes! Please continue filling out the application </div> : ""
                        }
                        {
                            this.state.qualify === "no6month" ?
                                <div  >
                                    Does the following apply to you?
                            <ul>
                                        <li>You acquired the property through inheritance, divorce, separation or dissolution of domestic partnership.</li>
                                        <li>Purchased the property using all cash.</li>
                                        <li>Property was under an LLC or under a Trust for a minimum of six months.</li>
                                    </ul>
                                    <div className="mt-3 mb-3">
                                        <input type="radio" checked={this.state.additional === "yesapplies"} onChange={onAdditional} value="yesapplies"
                                            name="yesapplies" id="yesapplies" />
                                        <label className="divlink" id="yesapplieslabel"
                                            htmlFor="yesapplies">Yes, it does</label>
                                        <input className="ml-3" checked={this.state.additional === "doesnotapply"} type="radio" onChange={onAdditional} value="doesnotapply"
                                            name="yesapplies" id="doesnotapply" />
                                        <label id="noapplieslabel" className="divlink"
                                            htmlFor="doesnotapply">No, it doesn't</label>
                                    </div>
                                    {
                                        this.state.additional === "yesapplies" ? <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }} >Yes! Please continue filling out the application </div>
                                            :
                                            (this.state.additional === "doesnotapply" ? <div style={{ fontSize: '1.2em', color: '#770000', fontWeight: 'bold' }} >Sorry, you can't apply for a cash out loan at this point in time.</div> : "")
                                    }
                                </div> : ""

                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseCashout}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>When you need to apply with a coborrower:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <Form id="modal" noValidate validated={false} onSubmit={this.handleSubmitModal}>

                            <div className="my-4 ">Applicants should complete this form as “Borrower” or “Additional Borrower,” as applicable.
                    </div>
                            <div className="mb-4">Additional Borrower information must also be provided (and the appropriate box checked) when </div>
                            <Row>
                                <Col>
                                    <input type="checkbox" id="assetsofotherpersonused" name="assetsofotherpersonused" defaultChecked={this.props.application.assetsofotherpersonused} onChange={this.updateCoborrowerAssetsUsed} ></input>
                                    <label id="assetsofotherpersonusedlabel" style={{ textDecoration: "underline" }} className="divlink" htmlFor="assetsofotherpersonused">The INCOME or ASSETS of a person other than the Borrower (including the Borrower’s spouse) will be used as a basis for loan qualification </label>
                                </Col>
                            </Row>
                        or
                        <Row>
                                <Col>
                                    <Form.Group controlId="liabilitiesofotherpersonrequired" className="text-left form-inline " >
                                        <Form.Check className="divlink" type="checkbox" id="liabilitiesofotherpersonrequired" name="liabilitiesofotherpersonrequired" defaultChecked={this.props.application.liabilitiesofotherpersonrequired} onChange={this.updateCoborrowerLiabilitiesUsed} label="The income or assets of the Borrower’s spouse or other person who has community property rights pursuant to state law will NOT be used as a basis for loan qualification, but his or her LIABILITIES must be considered because the spouse or other person has community property rights pursuant to applicable law and Borrower resides in a community property state, the security property is located in a community property state, or the Borrower is relying on other property located in a community property state as a basis for repayment of the loan."></Form.Check></Form.Group>
                                </Col> </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showSelf} onHide={this.handleSelfClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you self employed?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <div className="mb-4">You only qualify for self employed status if you own 25% of your company or more.</div>
                        <div> Otherwise you are a salaried employee for the purpose of obtaining a loan.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleSelfClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <h2 className="pl-3 heading text-wrap">Tell us about how you are applying for a loan
                </h2>
                <div className="text-center pt-2  ">
                    <div className="pb-2 ">
                        <div className="viewport">
                            <Form.Group>
                                <fieldset>
                                    <Form.Row className="my-0 py-0 ">
                                        <Col xs="auto" style={{ flexWrap: false }} className="text-left text-nowrap" >

                                            <input  type="radio" name="borrower"
                                                disabled={this.props.hascoborrower !== "alone" &&
                                                    (this.props.application.liabilitiesofotherpersonrequired ||
                                                        this.props.application.assetsofotherpersonused)}
                                                id="alone"
                                                required
                                                checked={this.props.hascoborrower === "alone"}
                                                onChange={this.updateCoborrower} value="alone" />

                                            {(this.props.hascoborrower !== "alone" &&
                                                (this.props.application.liabilitiesofotherpersonrequired ||
                                                    this.props.application.assetsofotherpersonused)) ?
                                                (<label id="alonelabel" htmlFor="alone" className="zeitro-radio text-nowrap" ><span className="mt-4 text-left" id="alone" style={{ color: "#cccccc" }}
                                                >I am applying alone</span> </label>)
                                                :
                                                (<label id="alonelabel" htmlFor="alone" className="zeitro-radio text-nowrap" ><span className="mt-5 divlink text-left" id="alone"
                                                >I am applying alone</span> </label>)

                                            }

                                        </Col>
                                        <Col className="text-left ml-5 hidesmall">
                                            {(this.props.hascoborrower !== "alone" &&
                                                (this.props.application.liabilitiesofotherpersonrequired ||
                                                    this.props.application.assetsofotherpersonused)) ?
                                                (<label id="ifyoucanlabel" className=" text-left text-wrap " > <span style={{ fontSize: '0.8em' }} className="divlink ml-4 bluish" onClick={this.figureOut}> <b>&lt;-</b><i className="fas fa-question-circle text-newinfo mr-1"></i>Check if you can apply alone</span></label>)
                                                :
                                                (<label id="ifyoumustabel" className="text-left text-wrap" > <span style={{ fontSize: '0.8em' }} className="divlink ml-4 bluish" onClick={this.figureOut}> <b>&lt;-</b><i className="fas fa-question-circle text-newinfo mr-1"></i>Click to see if you must include co-applicant</span></label>)

                                            }
                                        </Col>
                                        

                                    </Form.Row>
                                    <Form.Row className=" my-0 py-0">

                                        <Col md="auto" style={{ flexWrap: false }} className="text-left text-nowrap" >
                                            <input required  ref={this.radiocoborrower} type="radio" id="withcoborrower" name="borrower" checked={this.props.hascoborrower === "withcoborrower"}
                                                onChange={this.updateCoborrower} value="withcoborrower" />
                                            <label id="withcoborrowerlabel" className="zeitro-radio divlink text-nowrap text-left" htmlFor="withcoborrower" >I am applying with a co-borrower </label>
                                        </Col>
                                        <Col className="text-left">

                                        </Col>
                                        <Col></Col>
                                    </Form.Row >
                                    {/* <Form.Row className=" my-0 py-0">

                                        <Col style={{ flexWrap: false }} className="d-flex text-left text-nowrap" >
                                            <input required style={{ marginTop: '4px' }} className="mr-2" type="radio" id="multiple" name="borrower" checked={this.props.hascoborrower === "multiple"}
                                                onChange={this.updateCoborrower} value="multiple" />
                                            <label  className="zeitro-radio divlink text-nowrap text-left" htmlFor="multiple"  >With multiple applicants</label>
                                        </Col>
                                        <Col className="text-left">

                                        </Col>
                                        <Col></Col>
                                    </Form.Row > */}
                                </fieldset>
                                <Form.Control.Feedback className="text-left" type="valid">Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback className="text-left" type="invalid" >
                                    Please make a choice of how you are applying for the loan!
                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="viewport mt-2" hidden={this.props.hascoborrower === null || this.props.hascoborrower === "alone"}>

                            {(this.props.hascoborrower === "multiple") ?

                                <Form.Row className="mb-1">

                                    <Col as="div" className="align-middle ml-3 mt-2 text-left"  >
                                        <div className="w-100 align-middle  text-wrap sectionquestion">Are you married to {this.props.hascoborrower === "withcoborrower" ? "your coborrower" : "one of them"}?</div>
                                        <Form.Group>
                                            <fieldset>
                                                <div className="d-flex mt-1">
                                                    <div className="mr-3 ">
                                                        <input required onChange={this.updateMarried} value="false"
                                                            checked={false === this.props.application.married}
                                                            type="radio" id="marriednot" name="married"></input>
                                                        <label id="marriednolabelt" className="zeitro-radio divlink" htmlFor="marriednot">No, I am not</label></div>
                                                    <div className="mr-3 ">
                                                        <input required onChange={this.updateMarried} value="true"
                                                            checked={true === this.props.application.married}
                                                            type="radio" id="married" name="married"></input>
                                                        <label id="marriedlabelt" className="zeitro-radio divlink" htmlFor="married">Yes, I am</label></div>
                                                </div>
                                            </fieldset>
                                            <Form.Control.Feedback className="text-left" type="valid">Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback className="text-left" type="invalid" >
                                                Please indicate if you are married to the additional borrower
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Form.Row >
                                : ""}
                            {this.props.hascoborrower === "withcoborrower"
                                || (this.props.hascoborrower === "multiple" && this.props.application.married) ?

                                <Form.Row className="mb-1">
                                    <Col as="div" className="align-middle ml-3 mt-2 text-left"  >
                                        <Form.Group>
                                            <fieldset>
                                                <div className="w-100 align-middle  text-wrap sectionquestion">If you're applying with your spouse or life partner, did you live together for the last 2 years?</div>
                                                <div className="d-flex mt-1">

                                                    <div className="mr-3 ">
                                                        <input required onChange={this.updateCohabiting} value="false"
                                                            checked={false === this.props.application.cohabiting}
                                                            type="radio" id="cohabitednot" name="cohabited"></input>
                                                        <label id="cohabitednotlabel" className="zeitro-radio divlink" htmlFor="cohabitednot">No, we did not</label></div>
                                                    <div className="mr-3 ">
                                                        <input required onChange={this.updateCohabiting} value="true"
                                                            checked={true === this.props.application.cohabiting}
                                                            type="radio" id="cohabited" name="cohabited"></input>
                                                        <label id="cohabitedlabel" className="zeitro-radio divlink" htmlFor="cohabited">Yes, we did</label></div>
                                                </div>
                                            </fieldset>
                                            <Form.Control.Feedback className="text-left" type="valid">Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback className="text-left" type="invalid" >
                                                Please answer if you lived with your coborrower for the last 2 years
                </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Form.Row> : ""
                            }
                        </div>
                    </div>

                    <div className="viewport mt-2" hidden={this.props.hascoborrower === null}>
                        <Form.Row className=" pt-2">
                            <Col xs="auto" className="text-left text-nowrap" >
                                <Form.Group controlId="loanpurpose">
                                    <Form.Label className="text-left" >Select the purpose of your loan:</Form.Label>
                                    <Form.Control required name="purpose" as="select" size="sm"
                                        defaultValue={this.props.property.purpose}
                                        onChange={this.changeMainPropertyPurpose} >

                                        <option value="">...</option>
                                        <option value="purchase">Home Purchase</option>
                                        <option value="refinance">Refinance to improve rate and term</option>
                                        <option value="cashoutrefinance">Cash-out Refinance</option>
                                    </Form.Control>
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please select the purpose of the loan.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <div hidden={this.props.property.purpose !== "cashoutrefinance"} className="mt-2 text-left" > <br /><span style={{ fontSize: '0.8em' }} className="divlink mt-4 bluish" onClick={this.qualifyForCashout}> <b>&lt;-</b><i className="fas fa-question-circle text-info mr-1"></i> See if you qualify</span>
                                </div>
                            </Col>
                        </Form.Row>
                        {this.props.property.purpose !== "purchase" ? "" : <div className="mt-3 text-left">
                            <div className="w-100 align-middle  text-wrap sectionquestion">Do you already have a purchase contract or know the property you're buying?</div>

                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mt-2 ">
                                        <div className="mr-3">
                                            <input required onChange={onContract} value={true} checked={true === this.props.application.property.hascontract} type="radio" id="hascontract" name="hascontract"></input>
                                            <label id="hascontractlabel" className="zeitro-radio divlink" htmlFor="hascontract">Yes, I do</label></div>
                                        <div><input required onChange={onContract} value={false} checked={false === this.props.application.property.hascontract} type="radio" id="hascontractnot" name="hascontract"></input>
                                            <label id="hascontractnotlabel" className="zeitro-radio divlink text-wrap" htmlFor="hascontractnot">No, just looking for preapproval</label></div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" >
                                    Please specify if you are are a first time buyer.
                        </Form.Control.Feedback>
                            </Form.Group>

                        </div>}
                        {this.props.property.purpose === null ? "" :


                            <div>
                                <Form.Row className="">
                                {  this.props.property.purpose !== st.POL_Purchase  || 
                                (this.props.property.purpose === st.POL_Purchase && true === this.props.application.property.hascontract) ?
                                    <Col xs="auto" className="text-left text-nowrap inline-block" >
                                        <Form.Group controlId="appraisal" className="text-nowrap d-">
                                            {tooltip(appraisalVerbage(),
                                                <div>Estimated home value ultimately comes from a qualified appraiser, and will be refined in the later stages of getting a loan.
                                    <br /><br />In the mean time you can use the property price estimate from websites
                                    such as <a target="about:blank" href="https://zillow.com">Zillow</a> or <a target="about:blank" href="https://redfin.com">Redfin</a></div>)}
                                            <NumericalInput name="appraisal" size="sm"
                                                defaultValue={this.props.property.appraisal}
                                                onChange={this.changeMainPropertyAppraisal}
                                                required
                                                step={1000}
                                                min={1000}
                                            />


                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide size of the loan.
                                </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> : ""}
                                    {this.props.property.purpose === st.POL_Purchase && false === this.props.application.property.hascontract ?
                                        <Col xs="auto" className="text-left text-nowrap">
                                        <Form.Group controlId="sales" >
                                            <Form.Label className="text-wrap">What is your maximum purchase price?</Form.Label>
                                            <NumericalInput name="sales" size="sm"
                                                defaultValue={this.props.property.salesprice}
                                                onChange={onBudget}
                                                required
                                                step={1000}
                                                min={1000}
                                            />
                                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid" >
                                                Please provide your budget.
                                    </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                : ""}
                                    {this.props.property.purpose === st.POL_Purchase && true === this.props.application.property.hascontract ?
                                        <Col xs="auto" className="text-left text-nowrap">
                                            <Form.Group controlId="purchaseprice" >
                                                <Form.Label >Purchase Price:</Form.Label>
                                                <NumericalInput name="purchaseprice" size="sm"
                                                    defaultValue={this.props.property.salesprice}
                                                    onChange={this.props.changeMainPropertySalesPrice}
                                                    required
                                                    step={1000}
                                                    min={1000}
                                                />
                                                <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" >
                                                    Please provide the purchase price.
                                        </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        : ""
                                    }

                                    <Col></Col>
                                </Form.Row>

                                {(this.props.property.purpose !== "purchase") ?
                                    ""
                                    : <Form.Row><Col xs="auto" className="text-left">
                                        {this.showRentOrOWn()}
                                    </Col></Form.Row>
                                }
                                {this.sameProperty()}
                                {this.firstTime()}
                            </div>
                        }
                    </div>

                </div>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Coborrower)
