import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Autocomplete from "../../Autocomplete";

const SelectLoanOfficers = (props) => {
  const [success, setSuccess] = useState(false);
  const [allLoanOfficers, setAllLoanOfficers] = useState([]);
  const [loOptions, setLoOptions] = useState([]);
  const [systemLO, setSystemLO] = useState("");
  const [newLO, setNewLO] = useState("");
  const [selectedLoanOfficers, setSelectedLoanOfficers] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    getReferredLoanOfficers();
    getLoOptions()
  }, []);

  const getLoOptions = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getallindividualloanofficers", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((r) => {
        if (r) {
          let tmp = [];
          for (let lo of r) {
            console.log(lo);
            tmp.push(lo.FirstName+" "+lo.LastName+", "+lo.Email+", NMLS "+lo.NMLS);
          }
          setLoOptions(tmp);
        }
      });
    });
  };

  const getReferredLoanOfficers = () => {
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/getreferredloanofficers", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
    }).then((response) => {
      if (response.status !== 200) {
        alert(
          "Looks like there was a problem. Please contact Zeitro for help."
        );
        return;
      }
      response.json().then((r) => {
        if (r) {
          let tmp = [];
          let tmpSelected = [];
          for (let lo of r) {
            console.log(lo);
            tmp.push(lo);
            tmpSelected.push(lo.Email);
          }
          setAllLoanOfficers(tmp);
          setSelectedLoanOfficers(tmpSelected);
        }
      });
    });
  };

  const sendReferrals = () => {
    if (selectedLoanOfficers.length === 0) {
        return
    }
    setShowSpinner(true);
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/sendreferrals", {
      body: JSON.stringify(selectedLoanOfficers),
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Loan": props.loanid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Please contact zeitro for help."
          );
          setShowSpinner(false);
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          setShowSpinner(false);
          setSuccess(true);
          window.location.reload()
        });
      })
      .catch((err) => {
        setShowSpinner(false);
        console.log("Fetch Error :", err);
      });
  };

  const columns = [
    {
      dataField: "Name",
      text: "Name",
    },
    {
      dataField: "Email",
      text: "Email",
    },
    {
      dataField: "LoID",
      text: "Is Registerred",
      isDummyField: true,
      formatter: (_, row) => <div>{row.LoID === "" ? "No" : "Yes"}</div>,
      sort: true,
    },
  ];

  const handleOnSelect = (row, isSelect) => {
    let tmp = [...selectedLoanOfficers];
    if (isSelect) {
      tmp.push(row.Email);
    } else {
      tmp = tmp.filter((x) => x !== row.Email);
    }
    setSelectedLoanOfficers(tmp);
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const emails = rows.map((r) => r.Email);
    if (isSelect) {
      setSelectedLoanOfficers(emails);
    } else {
      setSelectedLoanOfficers([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedLoanOfficers,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const addALo = () => {
    let tmp = [...allLoanOfficers];
    let tmpSelected = [...selectedLoanOfficers];
    if (newLO !== "") {
      tmp.push({ Email: newLO, LoID: "" });
      tmpSelected.push(newLO);
      setAllLoanOfficers(tmp);
      setSelectedLoanOfficers(tmpSelected);
      setNewLO("");
    }
    if (systemLO !== "") {
      let name = systemLO.split(",")[0].trim();
      let email = systemLO.split(",")[1].trim();
      tmp.push({ Name: name, Email: email });
      tmpSelected.push(email);
      setAllLoanOfficers(tmp);
      setSelectedLoanOfficers(tmpSelected);
      setSystemLO("");
      console.log(tmpSelected);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.hide}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Loan to Loan Officers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {success ? "OKOK" :(
          <div>
          <BootstrapTable
            bordered={false}
            striped
            hover
            condensed
            keyField="Email"
            data={allLoanOfficers}
            columns={columns}
            selectRow={selectRow}
            noDataIndication={"no results found"}
          />

          <Row style={{ paddingRight: "30px" }}>
            <Col className="text-left">
              <Autocomplete
                suggestions={loOptions}
                label="Add a in-the-system LO:"
                name="state"
                id="state"
                type="text"
                valid="Looks good!"
                invalid="Please enter the state"
                placeholder="Search a LO by name, email or NMLS"
                value={systemLO}
                onChange={(e) => {
                  setNewLO("");
                  setSystemLO(e.target.value);
                }}
              />
            </Col>
            <Col className="text-center" xs={1}>
              {" "}
              or
            </Col>
            <Col className="text-left">
              <Form.Group controlId="newLOToRefer">
                <Form.Label className="text-left">
                  Invite a out-of-the-system LO
                </Form.Label>
                <Form.Control
                  size="sm"
                  name="newLOToRefer"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                  value={newLO}
                  onChange={(e) => {
                    setSystemLO("");
                    setNewLO(e.target.value);
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter your last name!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={1} style={{ marginTop: "28px" }}>
              <Button size="sm" onClick={() => addALo()}>
                Add
              </Button>
            </Col>
          </Row>
        </div>
            )}

        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props.hide}>Back</Button>
          {!success && <Button onClick={() => sendReferrals()}>
            {showSpinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              "Send Referrals"
            )}
          </Button>}
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectLoanOfficers;
