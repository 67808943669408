import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Col, Row, Divider, Carousel, Drawer, Spin, Modal } from "antd";
import jwt_decode from "jwt-decode";
import smartlookClient from 'smartlook-client'
import EmailVerification from './Application/EmailVerification';
import AppleLogin from 'react-apple-login'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './msalConfig';
import throttle from 'lodash.throttle';
import "./SignUpPage.css";
const contentStyle = {
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
export default function SignUpPage(props) {
  const [form] = Form.useForm()
  const { instance } = useMsal()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [signingup, setSigningup] = useState(false)
  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const [role, setRole] = useState("loanOfficer")
  const [googleReady, setGoogleReady] = useState(false);
  const formInitial = {}
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };
  const onFinish = (val) => {
    checkIfAlreadySignedUp(val)
  };

  const handleClose = () => {
    setShow(false)
  };
  const back = () => {
    setShowEmailVerification(false)
  }
  useEffect(() => {
    if (!showEmailVerification) {
      renderGoogleBtn()
    }
  }, [showEmailVerification])
  const appleLogin = (res) => {
    if (res && !res.error) {
      setLoading(true)
      fetch("/agent/applesignin", {
        method: "POST",
        body: JSON.stringify({
          code: res.authorization.code, 
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            alert(
              "Looks like there was a problem. Please contact contact@zeitro.com for help."
            );
            setLoading(false)
            return;
          }
          response.json().then((js) => {
            if (js.Status !== "OK") {
              console.log("Error: " + js.Text)
              if (js.Text.includes("invalid email address")) {
                setMsg('Sorry, but we were unable to find a valid email address associated with this Apple ID. Please try using a different Apple ID or alternative methods.')
              } else {
                setMsg('Sorry, but we are unable to sign you in using your Apple account at this time. Please consider using alternative sign-in methods.')
              }
              setShow(true)
              sessionStorage.removeItem("ZeitroA")
              sessionStorage.removeItem("state")
              setLoading(false)
            } else {
              sessionStorage.setItem("ZeitroA", js.Token)
              sessionStorage.setItem("state", js.State)
              sessionStorage.setItem("originalstate", js.State)
              sessionStorage.setItem(
                "roles",
                JSON.parse(atob(js.Token.split(".")[1])).roles
              );
  
              window.location.href = "/app/welcome"
              setLoading(false)
            }
          })
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err)
        })
    }
  }
  const msLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
  
      // Check if the login response is valid and contains the account
      if (loginResponse && loginResponse.account) {
        // Proceed with any necessary actions after successful sign-in
        console.log('Successfully signed in:', loginResponse.account, loginResponse.account.name, loginResponse.account.username);

        setLoading(true)
        fetch("/agent/microsoftsignin", {
          method: "POST",
          body: JSON.stringify({
            name: loginResponse.account.name,
            email:loginResponse.account.username,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              alert(
                "Looks like there was a problem. Please contact contact@zeitro.com for help."
              );
              setLoading(false)
              return;
            }
            response.json().then((js) => {
              if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
                setMsg('Sorry, but we are unable to sign you in using your Microsoft account at this time. Please consider using alternative sign-in methods.')
                setShow(true)
                sessionStorage.removeItem("ZeitroA")
                sessionStorage.removeItem("state")
                setLoading(false)
              } else {
                sessionStorage.setItem("ZeitroA", js.Token)
                sessionStorage.setItem("state", js.State)
                sessionStorage.setItem("originalstate", js.State)
                sessionStorage.setItem(
                  "roles",
                  JSON.parse(atob(js.Token.split(".")[1])).roles
                );
    
                window.location.href = "/app/welcome"
                setLoading(false)
              }
            })
          })
          .catch(function (err) {
            console.log("Fetch Error :-S", err)
          })
        

      }
    } catch (error) {
      console.error('Sign-in error:', error);
    }
  };

  const checkIfAlreadySignedUp = (val) => {
    let tosend = JSON.stringify({
      email: val.email.toLowerCase().trim(),
      firstName: val.firstname.trim(),
      lastName: val.lastname.trim(),
    });
    setSigningup(true)
    fetch("/agent/checkalreadysignedup", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status !== 200) {
            setMsg("Looks like there was a problem. Status Code: " + response.status)
            setShow(true)
            alert("Sorry, we are currently unable to register this email account. Please try again later.")

            return;
        }
        setSigningup(false)

        response.json().then((js) => {
          if (js.Status !== "OK") {
            setMsg(js.Text)
            setShow(true)
            sessionStorage.removeItem("ZeitroA");
            sessionStorage.removeItem("state");
            
          } else {
            setShowEmailVerification(true)
          }
        });
      })
      .catch((err) => {
        setSigningup(false)
        console.log("Fetch Error :", err);
        setMsg("Our servers are experiencing problems. Please check later.")
        setShow(true)
      });

  }

  const signup = (val) => {
    let tosend = JSON.stringify({
      email: val.email.toLowerCase().trim(),
      // phone: this.state.phone.trim(),
    //   password: this.state.password,
      firstName: val.firstname.trim(),
      lastName: val.lastname.trim(),
      // licenseNumber: this.state.licenseNumber.trim(),
      // licenseState: this.state.licenseState,
      role: role,
      captcha: "",
      isFromRE: props.loemail === undefined ? false : true,
      isFromLO: props.reemail === undefined ? false : true,
      isFromAdmin: props.fromAdmin === true ? true : false,
    });
    setSigningup(true)
    fetch("/agent/signup", {
      method: "POST",
      body: tosend,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status !== 200) {
            setMsg("Looks like there was a problem. Status Code: " + response.status)
            setShow(true)
            alert("Sorry, we are currently unable to register this email account. Please try again later.")

            return;
        }
        // Examine the text in the response
        setSigningup(false)

        response.json().then((js) => {
          if (js.Status !== "OK") {
            setMsg(js.Text)
            setShow(true)
            sessionStorage.removeItem("ZeitroA");
            sessionStorage.removeItem("state");
            
          } else {
            if (props.view === "baresignup") {
              let evt = { source: "plainredirect", payload: "/account-thanks" };
              console.log(evt);
              window.parent.postMessage(evt, "*");
            } else {
              this.setState({showSignupSuccessModal: true})
            }
          }
        });
      })
      .catch((err) => {
        setSigningup(false)
        console.log("Fetch Error :", err);
        setMsg("Our servers are experiencing problems. Please check later.")
        setShow(true)
      });
  };
  const renderGoogleBtn = () => {
    try {
      if (google && google.accounts && google.accounts.id) {
        google.accounts.id.renderButton(
          document.getElementById("googleSignin"),
          {
            type: "icon",
            theme: "outline", 
            size:"large",
            shape: "circle",
            width: document.getElementById("googleSigninWrap")?.offsetWidth,
            text: "signup_with",
          }
        )
        setGoogleReady(true)
      }      
    } catch (error) {
      
    }
  }
  useEffect(() => {
    /* global google */
    window.onload =  () => {
        google.accounts.id.initialize({
          client_id:"265683149831-g5e9goi4rtabm4p6rv11igkvnan4rfns.apps.googleusercontent.com",
          callback: handleGoogleCallback,
        })
        renderGoogleBtn()
    }
  
    if (props.view === "bare" || props.view === "baresignup") {
        if (window.drift) {
          window.drift.hide();
          window.drift = null;
        }
    }
    smartlookClient.init('df9b963e61e60937d8fac4f50e40a31e2a8cdbb0')
    smartlookClient.record({ 
        emails: true,
        forms: true,
        numbers: true,
        ips: true,
    })
    const handleResize = throttle((event) => {
      renderGoogleBtn()
    }, 200);
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleGoogleCallback = (response) => {
    let userObject = jwt_decode(response.credential);

    setLoading(true)
    fetch("/agent/googlesignin", {
      method: "POST",
      body: JSON.stringify(userObject),
      headers: {
        "Content-Type": "application/json",
        "X-GOOGLETOKEN": response.credential,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Sorry, but we are unable to sign you in using your Google account at this time. Please consider using alternative sign-up methods or contact contact@zeitro.com for help."
          );
          setLoading(false)
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Text)
            setMsg('Sorry, but we are unable to sign you in using your Google account at this time. Please consider using alternative sign-in methods.')
            setShow(true)
            sessionStorage.removeItem("ZeitroA")
            sessionStorage.removeItem("state")
            setLoading(false)
          } else {
            sessionStorage.setItem("ZeitroA", js.Token)
            sessionStorage.setItem("state", js.State)
            sessionStorage.setItem("originalstate", js.State)
            sessionStorage.setItem(
              "roles",
              JSON.parse(atob(js.Token.split(".")[1])).roles
            );

            window.location.href = "/app/welcome";
            setLoading(false)
          }
        })
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err)
      })
  }
  return (
    <>
      <div className="sign-up-box">
        <div className="create-account">
          <div className="sign-up-logo">
            <img src="/images/zeitrologo.png" style={{maxWidth:60}}/>
          </div>
          <div className="mobile-nav">
            <div className="mobile-logo">
              <img src="/images/zeitrologo.png" style={{maxWidth:60}}/>
            </div>

          </div>
          <div className="create-centerBox">
            {
              !showEmailVerification ? (
                <>
                  {
                    signingup ? (
                        <Spin />
                    ) : (
                        <>
                            <div className="create-title">Create a Free Account</div>
                            <Divider plain>Sign up with</Divider>
                            {!googleReady && <Row className="waitingGoogleSignin"  style={{justifyContent: 'center', gap: 34}}><Spin tip="Loading" size="small">
                              <div />
                            </Spin></Row>}
                            <Spin spinning={loading}>
                                  <Row id="googleSigninWrap" style={{justifyContent: 'center', gap: '34px'}}>
                                      
                                        <div id="googleSignin"></div>
                                        {googleReady && <>
                                          <Button
                                        size="large"
                                        shape="circle"
                                        className="other-login sign-outlook"
                                        onClick={msLogin}
                                        icon={
                                          <img
                                            src="/images/outlook.svg"
                                            style={{ width: "18px", height: "18px" }}
                                          />
                                        }
                                      >
                                      </Button>
                                      <AppleLogin
                                        clientId={"com.zeitro.signinservice"} 
                                        redirectURI={"https://agent.zeitro.com"}   
                                        usePopup={true}
                                        callback={(res) => appleLogin(res)}
                                        scope="email name"
                                        responseMode="query"
                                        render={(props) => (
                                            <Button
                                              size="large"
                                              shape="circle"
                                              className="other-login sign-apple"
                                              onClick={props.onClick}
                                              icon={
                                                <img
                                                  src="/images/apple.svg"
                                                  style={{ width: "18px", height: "18px" }}
                                                />
                                              }
                                            >
                                            </Button>
                                        )}
                                      />
                                        </>}
                                  </Row>
                                </Spin>
                                <Divider plain>or</Divider>
                                <Form
                                className="create-account-form"
                                layout="vertical"
                                form={form}
                                initialValues={formInitial}
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                size="large"
                                >
                                <Row>
                                    <Col span={24}>
                                    <Form.Item
                                        name="firstname"
                                        label="First name"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input placeholder="First name" />
                                    </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                    <Form.Item
                                        name="lastname"
                                        label="Last name"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input placeholder="Last name" />
                                    </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                        {
                                            required: true,
                                            type: "email",
                                        },
                                        ]}
                                    >
                                        <Input placeholder="Email address" />
                                    </Form.Item>
                                    </Col>
                                </Row>
                                <Button
                                    htmlType="submit"
                                    className="create-btn sign-zeitro"
                                    block
                                >
                                    Sign up with Zeitro
                                </Button>
                            </Form>
                        </>
                    )
                  }
            
                <div className="signIn-text">
                  Already have an account?{" "}
                  <a className="to-signIn" variant="link" href="/">
                    Sign in
                  </a>
                </div>
                </>
              ) : (
                <EmailVerification email={form.getFieldValue('email')} firstname={form.getFieldValue('firstname')}
                lastname={form.getFieldValue('lastname')}  showBack back={back} />
              )
            }
          </div>
          <div className="create-copyright">
            © {new Date().getFullYear()} Zeitro. All rights reserved.
          </div>
        </div>
      </div>
      <Modal
        open={show}
        title="Sign Up Failed"
        cancelText="Close"
        onCancel={handleClose}
        footer={(_, { CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
      >
        <div>{msg.includes("already been signed up") ? "This email has already been registered. Please proceed with signing in." : "Sorry, we're unable to sign up this account at this time, please try later or contact contact@zeitro.com for help."}</div>
        
      </Modal>

    </>
  );
}
