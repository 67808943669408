import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import "./Chat.css";
import PDFViewer from "./PDFViewer";
import { ChatPromptExamples, DocPromptExamples, FindPromptAnswer, convertPointsToNewlines } from "./utils";

function Chat() {
  const [messages, setMessages] = useState([]);
  const [showWelcome, setShowWelcome] = useState(true);
  const [showDoc, setShowDoc] = useState(false);
  const [isBotThinking, setIsBotThinking] = useState(false);
  const [pageNumber, setPageNumber] = useState(1); 
  const [messageToSend, setMessageToSend] = useState("")

  const handleBackClick = () => {
    setShowWelcome(true);
    setShowDoc(false);
  };

  const changePage = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const handleSendDocMessage = async (newMessage) => {
    setMessages([...messages, { text: newMessage, sender: "user" }]);
    setIsBotThinking(true); 

    const getPageLabels = (data) => {
      try {
          const pageLabels = data.reference
          .match(/'page_label': '(\d+)'/g)
          .map(label => label.match(/\d+/)[0]);
          return [...new Set(pageLabels)];
      } catch (error) {
          return []
      }

  };

    let ans = FindPromptAnswer(newMessage);
    if (ans !== null) {
        setIsBotThinking(true);
        setTimeout(() => {
          
          let uniquePageLabels = getPageLabels(ans);
          let answer = ans.answer

          
          if (uniquePageLabels.length >0) {
              answer = <div>
                            <div>{ans.answer}</div>
                            <div className="mt-3">Check reference pages:</div>
                            {uniquePageLabels.map(n => <li className="aAsLink" onClick={()=>changePage(parseInt(n))}>Page {n}</li>)}
                        </div>
          }
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: answer, sender: "bot" },
          ]);          

          setIsBotThinking(false); 
        }, 3000);

        return
    }

    try {
      let token = sessionStorage.getItem("ZeitroA");
      const response = await fetch("/agent/mortgagegpt/askfanniemae", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body: JSON.stringify({
          Message: newMessage,
        }),
      });

      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);

    const data = await response.json();

    let uniquePageLabels = getPageLabels(data);

    let answer = data.answer
      if (uniquePageLabels.length >0) {
          answer = <div>
                        <div>{data.answer}</div>
                        <div className="mt-3">Check reference pages:</div>
                        {uniquePageLabels.map(n => <li className="aAsLink" onClick={()=>changePage(parseInt(n))}>Page {n}</li>)}
                    </div>
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: answer, sender: "bot" },
      ]);
    } catch (error) {
      console.error("Fetching error:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Sorry something went wrong, please try later", sender: "bot" },
      ]);
    } finally {
      setIsBotThinking(false); // Bot finishes thinking, regardless of success or failure
    }
  };
  const handleSendMessage = async (newMessage) => {
    setMessages([...messages, { text: newMessage, sender: "user" }]);
    setIsBotThinking(true); 

    let ans = FindPromptAnswer(newMessage);
    if (ans!==null) {
        setIsBotThinking(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: ans, sender: "bot" },
          ]);
          setIsBotThinking(false); 
        }, 3000);
        return
    }

    try {
      let token = sessionStorage.getItem("ZeitroA");
      const response = await fetch("/agent/mortgagegpt/askwithoutdoc", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
        },
        body: JSON.stringify({
          Message: newMessage,
        }),
      });

      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.json();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data.answer, sender: "bot" },
      ]);
    } catch (error) {
      console.error("Fetching error:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Sorry something went wrong, please try later", sender: "bot" },
      ]);
    } finally {
      setIsBotThinking(false); // Bot finishes thinking, regardless of success or failure
    }
  };

  const handlePromptClick = (prompt) => {
    handleSendMessage(prompt);
    setShowWelcome(false);
  };
  const handleDocPromptClick = (prompt) => {
    handleSendDocMessage(prompt);
    setShowWelcome(false);
    setShowDoc(true);
  };

  const showChatHeader = () => {
    return <div className="chat-header">
    <button className="back-button" onClick={handleBackClick}>
      <LeftOutlined style={{ fontSize: 18, color: "black" }} />
    </button>
    <div className="chat-title">Mortgage AI</div>
  </div>
  }

  const onChangeMessageToSend = (val) => {
    setMessageToSend(val)
  }

  const onSendMessageOnWelcomePage = () => {
    handleSendMessage(messageToSend);
    setShowWelcome(false);    
  }

  return (
    <div className="chat">
      {showWelcome ? (
        <WelcomePage
          onPromptClick={handlePromptClick}
          onDocPromptClick={handleDocPromptClick}
          onChangeMessageToSend={onChangeMessageToSend}
          onSendMessage={onSendMessageOnWelcomePage}
        />
      ) : (
        <div style={{ width: "100%" }}>
            {!showDoc && showChatHeader()}
          {showDoc ? (
            <Row className="">
                <Col md={6}>
                    <ChatApp >
                    {showChatHeader()}
                    <MessageList
                    messages={messages}
                    isBotThinking={isBotThinking}
                    />
                    <ChatInput onSendMessage={showDoc ? handleSendDocMessage : handleSendMessage} />
                </ChatApp>
                </Col>
                <Col md={6}>
                    <PDFViewer file="/fannie_mae_guideline.pdf" pageNumber={pageNumber} setPageNumber={changePage}/>
                </Col>
            </Row>
          ) : (
            <div className="d-flex justify-content-center">
              <ChatApp className="width70">
                <MessageList
                  messages={messages}
                  isBotThinking={isBotThinking}
                />
                <ChatInput onSendMessage={handleSendMessage} style={{marginBottom:10}}/>
              </ChatApp>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function WelcomePage({ onPromptClick, onDocPromptClick, onChangeMessageToSend, onSendMessage }) {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSendMessage()
    }
  }


  return (
    <div className="welcome-page px-4">
      <div className="title-1 mt-5">GuidelineGPT</div>
      <div className="title-5">
        Get quick answers for all your mortgage questions
      </div>
      <div className="d-flex justify-content-center">
        <div className="chatprompts">
          <img src="/images/chatsign.png" style={{ width: 52, height: 52 }} />
          <div style={{ fontWeight: 500 }}>You can start the chat by asking:</div>
          <Row className="px-3 text-left mt-3">
            {ChatPromptExamples.map((prompt, index) => (
              <Col
                md={6}
                className="p-2 "
                onClick={() => onPromptClick(prompt.question)}
              >
                <div className="prompt d-flex justify-space-between">
                  <div className="mr-3" key={index}>
                    {`"` + prompt.question + `"`}
                  </div>
                  <RightOutlined />
                </div>
              </Col>
            ))}
          </Row>
          <Row className="px-3 text-left mt-3">
            {DocPromptExamples.map((prompt, index) => (
              <Col
                md={6}
                className="p-2 "
                onClick={() => onDocPromptClick(prompt.question)}
              >
                <div className="prompt d-flex justify-space-between">
                  <div>
                    <div className="mr-3" key={index}>
                      {`"` + prompt.question + `"`}
                    </div>
                    <div className="promptdoc">
                      <img
                        src="/images/pdf2.png"
                        style={{ height: 22, marginRight: 6 }}
                      />
                      Fannie Mae Conventional Guideline.pdf
                    </div>
                  </div>
                  <RightOutlined />
                </div>
              </Col>
            ))}
          </Row>
          <Row className="px-3 mt-3">
            <Col className="p-2">
            <div className="chat-input" >
              <input
                type="text"
                // ref={inputRef}
                // value={inputValue}
                onChange={(e) => onChangeMessageToSend(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Ask any mortgage-related questions"
              />
              <Button variant="sendmessage" onClick={()=>onSendMessage()} >Send</Button>
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

function ChatApp({ children, className }) {
  return <div className={"chat-app " + className}>{children}</div>;
}

function MessageList({ messages, isBotThinking }) {
    const messageContainerRef = useRef(null);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="message-list" ref={messageContainerRef}>
      {messages.map((message, index) => (
        <Message key={index} text={message.text} sender={message.sender} />
      ))}
      {isBotThinking && <Message text="..." sender="bot" />}
    </div>
  );
}

function Message({ text, sender }) {
  const avatar =
    sender === "bot" ? (
      <img src="/images/chatbot.png" alt="Chatbot" className="avatar" />
    ) : null;

  return (
    <div className={`${sender}-message message`}>
      {avatar}
      <div
        className={`text-bubble ${text === "..." ? "typing-indicator" : ""}`}
      >
        {text === "..." ? (
          <>
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </>
        ) : (
          text
        )}
      </div>
    </div>
  );
}

function ChatInput({ onSendMessage, style }) {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null); 

  useEffect(() => {
    inputRef.current.focus();
  }, []); 

  const handleSend = () => {
    if (inputValue.trim()) {
      onSendMessage(inputValue.trim());
      setInputValue("");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Call the button click handler when Enter key is pressed
      handleSend();
    }
  }
  return (
    <div className="chat-input" style={style}>
      <input
        type="text"
        ref={inputRef}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type a message"
      />
      <Button variant="zeitro-primary"  onClick={handleSend}>Send</Button>
    </div>
  );
}

export default Chat;
