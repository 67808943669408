import React from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import "./CustomerReview.css";
export default function CustomerReview({ reviewList, mode }) {
    return (
        <>
            {
                !reviewList.every(val => val.review ==='' || val.firstname ==='') && (
                    <div className="customer-review">
                        <div className='container'>
                                { mode !== 'setup' && <div className='customer-review-title'>Voice from customers</div> }
                                <div className='scroll-view'>
                                    <div className='review-list'>
                                        {
                                            reviewList.map((item, reviewIndex) =>
                                                {
                                                    if (item.review !=='' && item.firstname !=='') {
                                                        return (
                                                            <div className='review-item' key={reviewIndex}>
                                                                <div className='review-content'>
                                                                    <div className='star'>
                                                                        {
                                                                            [...Array(item.star ? item.star : 5)].map((star, index) =>
                                                                                <img src='/images/star.svg' key={index} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <Tooltip placement="bottom" title={item.review}>
                                                                        <div className='content'>{ item.review }</div>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className='customer'>{`${item.firstname} ${item.lastname}`}</div>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                                {/* <Row className='more-review'>
                                    <Col className='lebal-box'>
                                        <span className='more-review-lebal'>Learn more from the customer review:</span>
                                    </Col>
                                    <Col style={{display: 'flex'}}>
                                        <div className='google-review'><img src='/images/google.svg' alt='google' /><span>Google Review</span></div>
                                        <div className='yelp-review'><img src='/images/yelp.svg' alt='yelp' /><span>Yelp Review</span></div>
                                    </Col>
                                </Row> */}
                        </div>
                    </div>
                )
            }
        </>
    );
}