import React, { Component } from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { PennyInput } from "../NumericalInput";
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import '../Utils.css'
import * as com from "../Common"
import { lender } from "../State"

const deepcopy = require('deepcopy');

class CreditReportSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allenders: [],
            customerlenders: [],
            selectedid: null,
            validated: false,
            minimumClosingCostPercentage: "",
            maximumClosingCostPercentage: "",
        }
        this.lendersid = {}
        this.form = React.createRef();
    }



    getValues = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/data/getucsparams', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {


                    if (js.Status !== "Ok") {
                        alert("Error!")
                    } else {
                        this.setState({
                            username: js.Config.username,
                            password: js.Config.password, 
                            url: js.Config.url,
                            interface: js.Config.iface
                        })

                    }
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }
    componentDidMount() {
        this.getValues()
    }
    sendValues() {
        let token = sessionStorage.getItem("ZeitroA")
        let b = deepcopy(this.state.customerlenders)
        // reset config to string 
        b.forEach(x => {
            x.Config = JSON.stringify(x.Config)
        })
        let body = {

            Status: "OK", 
            Config: {
                username: this.state.username,
                password: this.state.password,
                url: this.state.url,
                iface: this.state.interface,
            }
        }
        fetch('/data/postucsparams', {

            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(body)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        alert("Error!")
                    } else {
                        alert("Lenders updated successfully!")
                    }
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    handleSubmit = event => {

        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true })

            return false
        }
        this.sendValues()
        event.preventDefault();
        event.stopPropagation();
        return false
    }

    render() {
        return (
            <div className="text-center pl-4">
                <h5 className="ml-4 my-5">UCS Settings:</h5>
                <Form className="text-center" ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                    <Row >
                        <Col></Col>
                        <Col className="px-2">

                            <Form.Group controlId="username" className="text-left">
                                <Form.Label className="text-left" >Username:</Form.Label>

                                <InputGroup size="sm" >
                                    <Form.Control
                                        size="sm"
                                        name={"username"}
                                        //required
                                        value={this.state.username}
                                        onChange={e => { this.setState({ username: e.target.value }) }}
                                        type="text"
                                        style={{ width: '8em' }}
                                        pattern="^.+$"
                                        placeholder="username"
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter username
                                    </Form.Control.Feedback>
                                </InputGroup >

                            </Form.Group>
                        </Col>
                        <Col className="px-2" >

                            <Form.Group controlId="password" className="text-left">
                                <Form.Label className="text-left" >Password:</Form.Label>

                                <InputGroup size="sm" >
                                    <Form.Control
                                        size="sm"
                                        name={"password"}
                                        //required
                                        value={this.state.password}
                                        onChange={e => { this.setState({ password: e.target.value }) }}
                                        type="text"
                                        style={{ width: '8em' }}
                                        pattern="^.+$"
                                        placeholder="password"
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter password
                                    </Form.Control.Feedback>
                                </InputGroup >

                            </Form.Group>
                        </Col>

                        <Col className="px-2">
                            <Form.Group controlId="url" className="text-left" >
                                <Form.Label className="text-left" >Url:</Form.Label>

                                <InputGroup size="sm" >
                                    <Form.Control
                                        size="sm"
                                        name={"url"}
                                        //required
                                        value={this.state.url}
                                        onChange={e => { this.setState({ url: e.target.value }) }}
                                        type="text"
                                        style={{ width: '8em' }}
                                        pattern="^.+$"
                                        placeholder="url"
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter Url
                                    </Form.Control.Feedback>
                                </InputGroup >

                            </Form.Group>
                        </Col>
                        <Col className="px-2">

                            <Form.Group controlId="interface"  className="text-left">
                                <Form.Label className="text-left" >Interface:</Form.Label>

                                <InputGroup size="sm" >
                                    <Form.Control
                                        size="sm"
                                        name={"interface"}
                                        //required
                                        value={this.state.interface}
                                        onChange={e => { this.setState({ interface: e.target.value }) }}
                                        type="text"
                                        style={{ width: '8em' }}
                                        pattern="^.+$"
                                        placeholder="interface"
                                    />

                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter interface
                                    </Form.Control.Feedback>
                                </InputGroup >

                            </Form.Group>

                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className="my-5">
                        <Col className="text-center">
                            <Button type="submit" >Update</Button>
                        </Col>
                    </Row>
                </Form>

            </div>


        )
    }
}

export default CreditReportSettings