import React from 'react';

import { Form, Row, Col } from 'react-bootstrap';

const Id = ({ doc, onFocus, onChangeTextInput }) => {
  return (
    Array.isArray(doc) ? // suport of old parseddata format
      doc.map((item, i) =>
        <div key={i}>
          <Row>
            <Form.Group as={Col} controlId="IDNumber">
              <Form.Label>ID number</Form.Label>
              <Form.Control defaultValue={item['IDNumber']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'IDNumber'])} />
            </Form.Group>

            <Form.Group as={Col} controlId="ExpirationDate">
              <Form.Label>Expiration date</Form.Label>
              <Form.Control defaultValue={item['ExpirationDate']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'ExpirationDate'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="GivenName">
              <Form.Label>Given name</Form.Label>
              <Form.Control defaultValue={item['GivenName']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'GivenName'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="MiddleName">
              <Form.Label>Middle name</Form.Label>
              <Form.Control defaultValue={item['MiddleName']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'MiddleName'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="FamilyName">
              <Form.Label>Family name</Form.Label>
              <Form.Control defaultValue={item['FamilyName']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'FamilyName'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="AddressLine">
              <Form.Label>Address line</Form.Label>
              <Form.Control defaultValue={item['AddressLine']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'AddressLine'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="City">
              <Form.Label>City</Form.Label>
              <Form.Control defaultValue={item['City']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'City'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="State">
              <Form.Label>State</Form.Label>
              <Form.Control defaultValue={item['State']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'State'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="PostalCode">
              <Form.Label>Postal code</Form.Label>
              <Form.Control defaultValue={item['PostalCode']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'PostalCode'])} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="DateOfBirth">
              <Form.Label>Date of birth</Form.Label>
              <Form.Control defaultValue={item['DateOfBirth']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'DateOfBirth'])} />
            </Form.Group>
            <Form.Group as={Col} controlId="Sex">
              <Form.Label>Sex</Form.Label>
              <Form.Control defaultValue={item['Sex']} type="text" onChange={e => onChangeTextInput('id.pdf', e.target.value, [i, 'Sex'])} />
            </Form.Group>
          </Row>
        </div>) :
      <div>
        <Row>
          <Form.Group as={Col} controlId="IDNumber">
            <Form.Label>ID number</Form.Label>
            <Form.Control defaultValue={doc['IDNumber']['Value']} type="text" onFocus={() => onFocus(doc['IDNumber']['BBox'], doc['IDNumber']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['IDNumber', 'Value'])} />
          </Form.Group>

          <Form.Group as={Col} controlId="ExpirationDate">
            <Form.Label>Expiration date</Form.Label>
            <Form.Control defaultValue={doc['ExpirationDate']['Value']} type="text" onFocus={() => onFocus(doc['ExpirationDate']['BBox'], doc['ExpirationDate']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['ExpirationDate', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="GivenName">
            <Form.Label>Given name</Form.Label>
            <Form.Control defaultValue={doc['GivenName']['Value']} type="text" onFocus={() => onFocus(doc['GivenName']['BBox'], doc['GivenName']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['GivenName', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="MiddleName">
            <Form.Label>Middle name</Form.Label>
            <Form.Control defaultValue={doc['MiddleName']['Value']} type="text" onFocus={() => onFocus(doc['MiddleName']['BBox'], doc['MiddleName']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['MiddleName', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="FamilyName">
            <Form.Label>Family name</Form.Label>
            <Form.Control defaultValue={doc['FamilyName']['Value']} type="text" onFocus={() => onFocus(doc['FamilyName']['BBox'], doc['FamilyName']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['FamilyName', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="AddressLine">
            <Form.Label>Address line</Form.Label>
            <Form.Control defaultValue={doc['AddressLine']['Value']} type="text" onFocus={() => onFocus(doc['AddressLine']['BBox'], doc['AddressLine']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['AddressLine', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="City">
            <Form.Label>City</Form.Label>
            <Form.Control defaultValue={doc['City']['Value']} type="text" onFocus={() => onFocus(doc['City']['BBox'], doc['City']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['City', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="State">
            <Form.Label>State</Form.Label>
            <Form.Control defaultValue={doc['State']['Value']} type="text" onFocus={() => onFocus(doc['State']['BBox'], doc['State']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['State', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="PostalCode">
            <Form.Label>Postal code</Form.Label>
            <Form.Control defaultValue={doc['PostalCode']['Value']} type="text" onFocus={() => onFocus(doc['PostalCode']['BBox'], doc['PostalCode']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['PostalCode', 'Value'])} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="DateOfBirth">
            <Form.Label>Date of birth</Form.Label>
            <Form.Control defaultValue={doc['DateOfBirth']['Value']} type="text" onFocus={() => onFocus(doc['DateOfBirth']['BBox'], doc['DateOfBirth']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['DateOfBirth', 'Value'])} />
          </Form.Group>
          <Form.Group as={Col} controlId="Sex">
            <Form.Label>Sex</Form.Label>
            <Form.Control defaultValue={doc['Sex']['Value']} type="text" onFocus={() => onFocus(doc['Sex']['BBox'], doc['Sex']['Page'])} onChange={e => onChangeTextInput('id.pdf', e.target.value, ['Sex', 'Value'])} />
          </Form.Group>
        </Row>
      </div>
  );
}

export default Id;