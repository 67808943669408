import React, { useState, useEffect, useRef } from "react";
import "./ThirdPartyResources.css";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as com from "../../Common";
import StateSelector from "../../StateSelector";

const AddLoanOfficers = (props) => {
  const [validated, setValidated] = useState(false);
  const [resourceName, setResourceName] = useState("");
  const [resourceEmail, setResourceEmail] = useState("");
  const [resourceCompany, setResourceCompany] = useState("");
  const [resourcePhone, setResourcePhone] = useState("");
  const [resourceLicenseState, setResourceLicenseState] = useState("");
  const [resourceLicenseNumber, setResourceLicenseNumber] = useState("");
  const [resourceWebsite, setResourceWebsite] = useState("");
  const [toBeAdded, setToBeAdded] = useState([]);
  const [toRemoveIndex, setToRemoveIndex] = useState(-1);
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);
  const handleReset = () => {
    formRef.current.reset();
    setResourceName("");
    setResourceEmail("");
    setResourceCompany("");
    setResourcePhone("");
    setResourceLicenseNumber("");
    setResourceLicenseState("");
    setResourceWebsite("");
    setValidated(false);
  };
  useEffect(() => {
    if (toRemoveIndex >= 0) {
      let tmp = [...toBeAdded];
      tmp.splice(toRemoveIndex, 1);
      setToBeAdded(tmp);
      setToRemoveIndex(-1);
    }
  }, [toRemoveIndex]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    let lst = [];
    lst.push({
      Company: resourceCompany,
      Name: resourceName,
      Email: resourceEmail.toLowerCase(),
      Phone: resourcePhone,
      NMLS: resourceLicenseNumber,
      Website: resourceWebsite,
      LicenseState: resourceLicenseState,
    });
    setToBeAdded(lst);

    let token = sessionStorage.getItem("ZeitroA");

    setLoading(true)
    fetch("/re/savereferredloanofficers", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(lst),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          alert("Looks like there was a problem, please contact Zeitro for help.");
          return;
        }
        // Examine the text in the response
        response.json().then((js) => {
          if (js.Status !== "OK") {
            setLoading(false)
            alert("Looks like there was a problem, please contact Zeitro for help.");
          } else {
            setToBeAdded([]);
            setLoading(false)
            alert("Your connected loan officer has been saved! If the loan officer is not in the system, she/he will get an invitation email.");
            window.location.reload();
          }
        });
      })
      .catch(function (err) {
        setLoading(false)
        console.log("Fetch Error:/re/savereferredloanofficers :-S", err);
      });
    handleReset();
  };

  return (
    <div>
      <h5 className="mt-4 pl-5"><b>Connect with a Loan Officer</b></h5>
      <div className="pl-5 pr-5">
        Connecting with loan officers to provide your customers with streamlined loan application experience.
      </div>
      <div className="addNewResource">
        {/* <Row>
          Search resource's name, company, email: ## fuzzy search here
          <input className="ml-3"></input>
        </Row> */}
        <Form ref={formRef} validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col className="text-left">
              <Form.Group controlId="name">
                <Form.Label className="text-left">LO Name:</Form.Label>
                <Form.Control
                  required
                  name="name"
                  size="sm"
                  autoFocus
                  autoComplete="name"
                  type="name"
                  defaultValue={resourceName}
                  onChange={(e) => {
                    setResourceName(e.target.value);
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="resourceCompany">
                <Form.Label className="text-left">Company/Broker:</Form.Label>
                <Form.Control
                  required
                  name="resourceCompany"
                  size="sm"
                  autoFocus
                  autoComplete="resourceCompany"
                  type="resourceCompany"
                  defaultValue={resourceCompany}
                  onChange={(e) => {
                    setResourceCompany(e.target.value);
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid company/broker.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="email">
                <Form.Label className="text-left">Email:</Form.Label>
                <Form.Control
                  required
                  name="resourceEmail"
                  size="sm"
                  autoFocus
                  autoComplete="resourceEmail"
                  type="resourceEmail"
                  defaultValue={resourceEmail}
                  onChange={(e) => {
                    setResourceEmail(e.target.value);
                  }}
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="text-left">
              <Form.Group controlId="phone" className="text-left">
                <Form.Label className="text-left">Contact Phone:</Form.Label>
                <Form.Control
                  isValid={false}
                  size="sm"
                  required
                  name="phone"
                  type="text"
                  value={resourcePhone}
                  onChange={(e)=>setResourcePhone(e.target.value)}
                  pattern={com.phoneRegex}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid contact phone.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col className="text-left" xs={6}>
              <Form.Group controlId="licenseState" className="text-left">
                <Form.Label className="text-left">License State(optional):</Form.Label>
                <StateSelector
                  isValid={false}
                  size="sm"
                  name="resourceLicenseState"
                  autoComplete="resourceLicenseState"
                  type="text"
                  placeholder="##"
                  value={resourceLicenseState}
                  onChange={(e) => setResourceLicenseState(e.target.value)}
                  pattern="^[A-Z]{2,2}$"
                />
              </Form.Group>
            </Col>
            <Col className="text-left" >
              <Form.Group controlId="resouceLicenseNumber">
                <Form.Label className="text-left">NMLS(optional):</Form.Label>
                <Form.Control
                  name="resouceLicenseNumber"
                  size="sm"
                  autoFocus
                  autoComplete="resouceLicenseNumber"
                  type="resouceLicenseNumber"
                  defaultValue={resourceLicenseNumber}
                  onChange={(e) => setResourceLicenseNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col className="text-left" >
              <Form.Group controlId="resourceWebsite">
                <Form.Label className="text-left">URL (optional):</Form.Label>
                <Form.Control
                  name="resourceWebsite"
                  size="sm"
                  autoFocus
                  autoComplete="resourceWebsite"
                  type="resourceWebsite"
                  defaultValue={resourceWebsite}
                  onChange={(e) => setResourceWebsite(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Button
                type="submit"
                style={{ marginTop: "26px", height: "32px" }}
              >
                {loading ? <Spinner animation="border" variant="primary" size="sm" /> : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AddLoanOfficers;
