import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ListGroup from "react-bootstrap/ListGroup";
import HorizontalSliderV2 from "../../Common/HorizontalSliderV2";
import Select from 'react-select';
import { CommaizedInput } from "../../NumericalInput";
import "./RateQuote.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import * as com from "../../Common.js";
import NumericalInput from "../../NumericalInput";
import { withRouter } from "react-router-dom";
import IneligibleProductsModal from "./IneligibleProducts";
import AllRatesModal, { AllRates } from "./AllRates";
import ReportIssuesModal from "./ReportIssues";
import RateReport from "./RateReport";
import Spinner from "react-bootstrap/Spinner";
import { termOptions } from "./common";
import Tooltip from 'react-bootstrap/Tooltip'

const DEFAULT_LOAN_TERM = 30; //year

let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};

const RateQuoteRealtorVersion = (props) => {
  let customerid = com.getCustomerId();

  const [onUpdateRates, setOnUpdateRates] = useState(false);
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");

  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("SANTA CLARA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [monthlyIncome, setMonthlyIncome] = useState(50000);
  const [monthlyDebt, setMonthlyDebt] = useState(1000);
  const [estOtherCosts, setEstOtherCosts] = useState(3195);

  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(6);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [ineligibleProducts, setIneligibleProducts] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [showAllRates, setShowAllRates] = useState(false);
  const [showReportIssues, setShowReportIssues] = useState(false);
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [showSendReportSpinner, setShowSendReportSpinner] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [previewEmail, setPreviewEmail] = useState("");
  const [previewResult, setPreviewResult] = useState("");
  const [loProfile, setLOProfile] = useState({});

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessageModal] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showSubscribeResult, setShowSubscribeResult] = useState(false);
  const [subscribeResultMessage, setSubscribeResultMessage] = useState("");
  const [validatedSubscribe, setValidatedSubscribe] = useState(false);
  const subscribeFormRef = useRef();
  const [alreadysubscribe, setAlreadysubscribe] = useState(false);
  const [pastSetting, setPastSetting] = useState({});
  const [newSetting, setNewSetting] = useState({});
  const [lendersNotSet, setLendersNotSet] = useState(false)

  const [showDetails, setShowDetails] = useState(false);

  const [showFileModal, setShowFileModel] = useState(false);
  const handleCloseFileModal = () => setShowFileModel(false);
  const handleShowFileModal = () => setShowFileModel(true);

  const [loMap, setLoMap] = useState({});

  const preselectedTermOptions = [
    { label: "30 Year",value: "fixed30" },
    { label: "15 Year",value: "fixed15" },
    { label: "ARM 5/6",value: "arm56" },
  ];

  const [selectedTerms, setSelectedTerms] = useState(preselectedTermOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.ceil((getLoanAmount() / parseInt(propertyValue)) * 100);
    // let cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
    // let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
    return [ltv, ltv, ltv];
  };

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };

  const showErrorMessage = () => {
    return (
      <Modal show={showMessage} onHide={() => setShowMessageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowMessageModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  let getEstimatedThirdPartyFee = () => {
    let lm = getLoanAmount()
    return com.commaize(3000 + 1000 * lm/1000000)
  }

  let getProducts = (controller) => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;

    setMessage("");

    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();

    if (state != "TEXAS") _county = county;

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: parseFloat(estOtherCosts),
      pmi: pmi,
      getFiles: false,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
    };
    setLoading(true);
    let token = sessionStorage.getItem("ZeitroA");

    fetch("/re/getrates", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "Content-Type": "application/json",
        "X-RE": props.id,
      },
      body: JSON.stringify(body),
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          setOnUpdateRates(false);
          console.log(response)
          // setMessage(
          //   "An internal error has occured. Please contact Zeitro for help."
          // );
          // setShowMessageModal(true);
          return;
        }
        response.json().then((pr) => {
          if (pr.status_code === "12") {
            setLendersNotSet(true)
            // setShowMessageModal(true);
            setOnUpdateRates(false);
            setLoading(false);
            return;
          }

          if (pr.status !== "Ok") {
            setMessage("No qualified products.");
            setShowMessageModal(true);
            setOnUpdateRates(false);
            setLoading(false);
            return;
          }

          setLoMap(pr.loan_officer_map);
          let products = pr.result;
          console.log(pr);

          products.forEach((product) => {
            let lender = product.Lender;
            let commonproduct = product.product;
            let ausengine = product.AUSEngine;

            product.rateCosts.forEach((rate) => {
              let pdkt = {
                Lender: lender,
                ...commonproduct,
                Aus: ausengine,
                ...rate,
              };

              if (rate.lockDay !== 30) {
                return;
              }
              /// SOMRTHING ELSE HERE
              pdkt.loanAmount = getLoanAmount();
              if (!pdkt.Mtype.includes("FHA")) {
                if (pdkt.Arm != null) {
                  if (pdkt.Arm.fixedperiod == 5) {
                    arm56.push(pdkt);
                  } else if (pdkt.Arm.fixedperiod == 7) {
                    arm76.push(pdkt);
                  } else if (pdkt.Arm.fixedperiod == 10) {
                    arm106.push(pdkt);
                  }
                } else {
                  if (pdkt.Term == 30) {
                    fixed30.push(pdkt);
                  } else if (pdkt.Term == 25) {
                    fixed25.push(pdkt);
                  } else if (pdkt.Term == 20) {
                    fixed20.push(pdkt);
                  } else if (pdkt.Term == 15) {
                    fixed15.push(pdkt);
                  } else if (pdkt.Term == 10) {
                    fixed10.push(pdkt);
                  }
                }
              }
            });
          });

          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            let filteredPrds = products.filter((p) => {
              return p.lender_credit > 0;
            });
            let otherPrds = products.filter((p)=>{
              return p.lender_credit<=0
            })
            otherPrds.sort(compareRate)
            let anotherPrds = []
            if (otherPrds.length > 0) {
              anotherPrds = [otherPrds[0]]
              anotherPrds[0].lender_credit = 0
            }
            filteredPrds = [...filteredPrds, ...anotherPrds]

            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              // console.log(filteredPrds[i]);
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0;
            let bestPoints = 1000000;
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 3000) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 3000);
                bestIndex = i;
              }
            }
            return bestIndex;
          };

          allRates = {};
          allRates.fixed30 = fixed30;
          allRates.fixed25 = fixed25;
          allRates.fixed20 = fixed20;
          allRates.fixed15 = fixed15;
          allRates.fixed10 = fixed10;
          allRates.arm56 = arm56;
          allRates.arm76 = arm76;
          allRates.arm106 = arm106;

          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);

          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setOnUpdateRates(false);
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setOnUpdateRates(false);
        setLoading(false);
      });
  };
  const ref = useRef();

  useEffect(() => {
    const controller = new AbortController();
    getProducts(controller);

    let token = sessionStorage.getItem("ZeitroA");
    fetch("/agent/getprofile", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-RE": props.id,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then((js) => {
          if (js.Status !== "OK") {
            alert("Error: " + js.Error);
          } else {
            let lo = js.Profile;
            console.log("profile info returned: %s ", JSON.stringify(js));
            setLOProfile({
              firstName: lo.FirstName,
              lastName: lo.LastName,
              email: lo.Email,
              phone: lo.Phone,
              avatarsrc: lo.Image,
              nmls: lo.Nmls,
            });
          }
        });
      })
      .catch(function (err) {
        alert("Fetch Error :-S", err);
      });
    console.log("lo", loProfile);
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!showSubscribe) setShowRatePreview(false);
  }, [showSubscribe, showRatePreview]);

  let showCounties = () => {
    let options = [];
    let keys = Object.keys(com.counties);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  useEffect(() => {
    const controller = new AbortController();
    if (onUpdateRates) {
      getProducts(controller);
    }
    return () => {
      controller.abort();
    };
  }, [onUpdateRates]);

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
    if (parseInt(downpayment) === 0) {
      setValidated(true);
      alert("Downpayment can't be zero");
      return false;
    }
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      alert("Downpayment can't be bigger than purchase price");
      return false;
    }
    if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
      setValidated(true);
      alert("FICO score should be in the range of 300 - 1000");
      return false;
    }
    setOnUpdateRates(true);
    return false;
  };

  let showCard = (type) => {
    if (loans.length === 0) return "";
    let allloans = fixed30;
    let curindex = indexfixed30;

    if (type == "fixed25") {
      allloans = fixed25;
      curindex = indexfixed25;
    } else if (type == "fixed20") {
      allloans = fixed20;
      curindex = indexfixed20;
    } else if (type == "fixed15") {
      allloans = fixed15;
      curindex = indexfixed15;
    } else if (type == "fixed10") {
      allloans = fixed10;
      curindex = indexfixed10;
    } else if (type == "arm56") {
      allloans = arm56;
      curindex = indexarm56;
    } else if (type == "arm76") {
      allloans = arm76;
      curindex = indexarm76;
    } else if (type == "arm106") {
      allloans = arm106;
      curindex = indexarm106;
    }

    let loan = allloans[curindex];

    if (loan == undefined) {
      return (
        <Container className="text-center p-3 ratesCard">
          <p>No qualified product for loan type {type}</p>
        </Container>
      );
    }

    const getDetails = (loan) => {
      let priceData = [];
      let priceColumns = [];
      if (loan.prices.CloseOverPoint == null) {
        priceData = [
          {
            lock: loan.prices.LockDayPoint.toFixed(3),
            adj: loan.prices.AdjustmentPoint.toFixed(3),
            comp: loan.prices.CompensationPoint.toFixed(3),
            total:
              loan.points.toFixed(3) + "/$" + com.commaize(loan.lender_credit),
          },
        ];
        priceColumns = [
          {
            dataField: "lock",
            text: "LockDay Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "adj",
            text: "Adjustment Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "comp",
            text: "Compensation %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "total",
            text: "Total",
            headerStyle: { backgroundColor: "white" },
          },
        ];
      } else {
        priceData = [
          {
            lock: loan.prices.LockDayPoint.toFixed(3),
            adj: loan.prices.AdjustmentPoint.toFixed(3),
            comp: loan.prices.CompensationPoint.toFixed(3),
            closeover: loan.prices.CloseOverPoint.toFixed(3),
            total:
              loan.points.toFixed(3) + "/$" + com.commaize(loan.lender_credit),
          },
        ];
        priceColumns = [
          {
            dataField: "lock",
            text: "LockDay Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "adj",
            text: "Adjustment Point %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "comp",
            text: "Compensation %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "closeover",
            text: "Close Over %",
            headerStyle: { backgroundColor: "white" },
          },
          {
            dataField: "total",
            text: "Total",
            headerStyle: { backgroundColor: "white" },
          },
        ];
      }

      let ccData = [
        {
          estimated: com.commaize(estOtherCosts),
          lenderfee: com.commaize(loan.lender_fee),
          pi: com.commaize(loan.prepaid_interest),
          total: com.commaize(loan.closing_cost),
        },
      ];
      let ccColumns = [
        {
          dataField: "estimated",
          text: "Est. Costs(LE part B+C)",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "lenderfee",
          text: "Lender Fee",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "pi",
          text: "Est. Prepaid Interest(15 Day)",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "total",
          text: "Total Closing Costs",
          headerStyle: { backgroundColor: "white" },
        },
      ];
      return (
        <div>
          <ListGroup variant="pricedetail">
            <ListGroup.Item>
              <b>
                {loan.Lender} : {loan.Name}
              </b>
            </ListGroup.Item>
            <ListGroup.Item className="align-items-start">
              <div>
                <b>Applied adjustments:</b>
              </div>
              {Object.keys(loan.Adjustments).map((adj) => (
                <div>
                  {adj}: {loan.Adjustments[adj]}
                </div>
              ))}
            </ListGroup.Item>
            <div style={{ backgroundColor: "white" }}>
              <BootstrapTable
                bootstrap4
                condensed={true}
                bordered={false}
                striped={false}
                keyField="lock"
                data={priceData}
                columns={priceColumns}
              />
            </div>
            <div style={{ backgroundColor: "white" }}>
              <BootstrapTable
                bootstrap4
                condensed={true}
                bordered={false}
                striped={false}
                keyField="total"
                data={ccData}
                columns={ccColumns}
              />
            </div>
            <ListGroup.Item>
              <Button variant="outline-dark" size="sm">
                Download Ratesheet
              </Button>{" "}
            </ListGroup.Item>
            <Modal show={showFileModal} onHide={handleCloseFileModal}>
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Something was wrong with downloading function. Please contact
                Zeitro team.
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </ListGroup>
        </div>
      );
    };


    const showLoanOfficers = (loids) => {
      let out = []
      for (let id of loids) {
        if (id in loMap) {
          out.push(<div className="ratesCardLO">
            <div className="ratesCardLO1">
              <img className="ratesCardLOPicture" src={loMap[id].Picture}/>
              <a className="ratesCardLOName">{loMap[id].FirstName}{" "}{loMap[id].LastName}</a>
              <a className="ratesCardLONMLS">#{loMap[id].NMLS}</a>
            </div>
            <div className="ratesCardLO2">
              <Button variant="ratesCardLOButton" size="sm" onClick={()=>window.open("https://" + window.location.hostname + loMap[id].URL, "_blank")}>Get Pre-Qualified With LO</Button>
            </div>
          </div>)
        }
      }
      return out
    };

    const onClickMinusClosingCosts = ()=> {
      if (type==="fixed30") {
        setIndexFixed30(Math.max(0, curindex-1));
      } else if (type==="fixed25") {
        setIndexFixed25(Math.max(0, curindex-1));
      } else if (type==="fixed20") {
        setIndexFixed20(Math.max(0, curindex-1));
      } else if (type==="fixed15") {
        setIndexFixed15(Math.max(0, curindex-1));
      } else if (type==="fixed10") {
        setIndexFixed10(Math.max(0, curindex-1));
      } else if (type==="arm56") {
        setIndexArm56(Math.max(0, curindex-1));
      } else if (type==="arm76") {
        setIndexArm76(Math.max(0, curindex-1));
      } else if (type==="arm106") {
        setIndexArm106(Math.max(0, curindex-1));
      }
    }
    const onClickAddClosingCosts = ()=> {
      if (type==="fixed30") {
        setIndexFixed30(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed25") {
        setIndexFixed25(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed20") {
        setIndexFixed20(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed15") {
        setIndexFixed15(Math.min(allloans.length-1, curindex+1));
      } else if (type==="fixed10") {
        setIndexFixed10(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm56") {
        setIndexArm56(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm76") {
        setIndexArm76(Math.min(allloans.length-1, curindex+1));
      } else if (type==="arm106") {
        setIndexArm106(Math.min(allloans.length-1, curindex+1));
      }
    }

    return (
      <Container className="text-center p-3 ratesCard">
        <Row className="ratesCardRow1">
          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Loan Type</div>
            {type.includes("fixed") ? (
              <div className="ratesCardText">{loan.Term} Year Fixed</div>
            ) : (
              <div className="ratesCardText">
                {loan.Arm.fixedperiod}/{loan.Arm.adjustmentperiod} ARM
              </div>
            )}
          </Col>

          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Rate</div>
            <div className="ratesCardText">
              {loan.base_rate.toFixed(3)}%
            </div>
          </Col>

          <Col  className="ratesCardCol" md>
            <div className="ratesCardTitle">APR</div>
            <div className="ratesCardText">
              {loan.APR.toFixed(3)}%
            </div>
          </Col>

          <Col className="ratesCardCol" md>
            <div className="ratesCardTitle">Monthly Pay</div>
            <div className="ratesCardText">
              ${com.commaizeFloat(loan.monthly_payment.toFixed(2))}
            </div>
          </Col>

          <Col md={5} className="ratesCardClosingCostCol">
          <div className="ratesCardClosingCostRow">
            <div className="ratesCardClosingCostLabel">Points
            <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip" className="text-left">
                      Mortgage points are essentially a form of prepaid interest you can choose to pay up front in exchange for a lower interest rate and monthly payments (a practice known as “buying down” your interest rate)
                    </Tooltip>
                  }
                >
                  <img src="/images/questionMark.png" style={{maxWidth:"15px", marginLeft:"5px"}}/>
                </OverlayTrigger>
            </div>
            <div className="ratesCardClosingCostAdjusterCol">
              <InputGroup className="ratesCardClosingCostAdjuster mt-1">
                <InputGroup.Append>
                  <InputGroup.Text className="ratesCardClosingCostForm" onClick={()=>onClickAddClosingCosts()}>—</InputGroup.Text>
                </InputGroup.Append>
                <InputGroup.Append>
                  <InputGroup.Text style={{backgroundColor:"white"}}>${com.commaizeFloat(loan.lender_credit.toFixed(0))}</InputGroup.Text>
                </InputGroup.Append>
                <InputGroup.Append>
                  <InputGroup.Text className="ratesCardClosingCostForm" onClick={()=>onClickMinusClosingCosts()}>+</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </div>
          </Col>
        </Row>

        <div className="mt-3">
        {showLoanOfficers(loan.lo_ids)}
        </div>
        {showDetails ? (
          <div>
            <br /> {getDetails(loan)}
          </div>
        ) : (
          ""
        )}
      </Container>
    );
  };

  let showCards = () => {
    let s = [];
    selectedTerms.forEach((t) => {
      s.push(<br></br>);
      s.push(showCard(t.value));
    });
    return s;
  };

  let onMoreOptions = (e) => {
    if (showMoreOptions) {
      setShowMoreOptions(false);
    } else {
      setShowMoreOptions(true);
    }
  };

  let onSubscribe = (e) => {
    setShowSubscribe(true);
    setShowSubscribeResult(false);
    setSubscribeResultMessage("");
  };

  let getSubscribeModal = () => {
    const handleCloseModal = () => setShowSubscribe(false);
    const handleReset = () => {
      subscribeFormRef.current.reset();
      setValidatedSubscribe(false);
    };
    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        return;
      }
      setValidatedSubscribe(true);

      let stname = [];
      selectedTerms.forEach((t) => {
        stname.push(t.label);
      });
      let [ltv, cltv, hcltv] = getLtv();
      let body = {
        Email: event.target.elements.email.value,
        Fname: event.target.elements.fname.value,
        Lname: event.target.elements.lname.value,
        Frequency: event.target.elements.frequency.value,
        SelectedTerms: stname,
        Fixed30lc:
        fixed30[indexfixed30] !== undefined
          ? fixed30[indexfixed30].lender_credit
          : 3000,
        Fixed25lc:
          fixed25[indexfixed25] !== undefined
            ? fixed25[indexfixed25].lender_credit
            : 3000,
        Fixed20lc:
          fixed20[indexfixed20] !== undefined
            ? fixed20[indexfixed20].lender_credit
            : 3000,
        Fixed15lc:
          fixed15[indexfixed15] !== undefined
            ? fixed15[indexfixed15].lender_credit
            : 3000,
        Fixed10lc:
          fixed10[indexfixed10] !== undefined
            ? fixed10[indexfixed10].lender_credit
            : 3000,
        Arm56lc:
          arm56[indexarm56] !== undefined ? arm56[indexarm56].lender_credit : 3000,
        Arm76lc:
          arm76[indexarm76] !== undefined ? arm76[indexarm76].lender_credit : 3000,
        Arm106lc:
          arm106[indexarm106] !== undefined
            ? arm106[indexarm106].lender_credit
            : 3000,
        PropertyType: com.getPropertyType(propertyType),
        LTV: ltv,
        LoanAmount: getLoanAmount(),
        Occupancy: com.getOccupancy(occupancy),
        Purpose: purpose,
        State: state,
        County: county,
        MonthlyIncome: monthlyIncome,
        MonthlyDebt: monthlyDebt,
        Fico: fico,
      };
      console.log(body);
      setNewSetting(body);
      let token = sessionStorage.getItem("ZeitroA");
      fetch("/agent/subscriberates/" + customerid, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("Looks like there was a problem: " + response);
          return;
        }
        response.json().then((r) => {
          if (r.Status == "OK") {
            setAlreadysubscribe(false);
            setNewSetting(body);
            setShowSubscribeResult(true);
          } else {
            console.log(r);
            setPastSetting(r.Settings);
            setAlreadysubscribe(true);
            setShowSubscribeResult(true);
          }
        });
      });
      handleReset();
      setShowSubscribe(false);
    };

    return (
      <Modal
        show={showSubscribe}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Send the Personalized Rates Report</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h3>Send the rate report on a regular basis:</h3>
          <Form
            ref={subscribeFormRef}
            validated={validatedSubscribe}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" required />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="fname">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" placeholder="XX or empty" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="lname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" placeholder="XX or empty" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="frequency">
                  <Form.Label>Email Frequency</Form.Label>
                  <Form.Control as="select">
                    <option value="daily">Send it Daily</option>
                    <option value="weekly">Send it Weekly</option>
                    <option value="monthly">Send it Monthly</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  type="submit"
                  size="md"
                  style={{ marginTop: "32px" }}
                >
                  Subscribe
                </Button>
              </Col>
            </Form.Row>
          </Form>
          <br />
          <h3>Or, send the rate report one time and right away:</h3>
          <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    onChange={(e) => setPreviewEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
              <Col>
                <Button
                  variant="primary"
                  size="md"
                  style={{ marginTop: "32px", width: "80%" }}
                  onClick={handleShowRatePreview}
                >
                  Preview
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {showRatePreview && <RateReport data={previewData} />}
          {previewResult !== "" && (
            <Container>
              <Row>
                <b style={{ size: "120%" }}>{previewResult}</b>
              </Row>
            </Container>
          )}
          {showRatePreview && (
            <Button
              variant="light"
              size="md"
              onClick={() => setShowRatePreview(false)}
            >
              Hide Preview
            </Button>
          )}
          <Button variant="light" size="md" onClick={handleCloseModal}>
            {showRatePreview ? "Re-config" : "Close"}
          </Button>
          {showRatePreview && (
            <Button
              variant="primary"
              size="md"
              onClick={() => sendRatesReport()}
            >
              {showSendReportSpinner ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Send"
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  let getSubscribeResultModal = () => {
    const handleCloseModal = () => setShowSubscribeResult(false);
    let s = (string) => {
      return string.toString().replaceAll("_", " ").toLowerCase();
    };
    let showSubscribeMessageExisted = () => {
      let data = [
        {
          field: "Loan Purpose",
          past: s(pastSetting.Purpose),
          new: s(newSetting.Purpose),
        },
        {
          field: "Loan Amount",
          past: pastSetting.LoanAmount,
          new: newSetting.LoanAmount,
        },
        {
          field: "FICO Score",
          past: pastSetting.Fico,
          new: newSetting.Fico,
        },
        {
          field: "Occupancy Type",
          past: s(pastSetting.Occupancy),
          new: s(newSetting.Occupancy),
        },
        {
          field: "Property Type",
          past: s(pastSetting.PropertyType),
          new: s(newSetting.PropertyType),
        },
        {
          field: "Monthly Income",
          past: s(pastSetting.MonthlyIncome),
          new: s(newSetting.MonthlyIncome),
        },
        {
          field: "Monthly Debt",
          past: s(pastSetting.MonthlyDebt),
          new: s(newSetting.MonthlyDebt),
        },
        {
          field: "Email Frequency",
          past: s(pastSetting.Frequency),
          new: s(newSetting.Frequency),
        },
        {
          field: "Preferred Products",
          past: s(pastSetting.SelectedTerms),
          new: s(newSetting.SelectedTerms),
        },
      ];
      let columns = [
        {
          dataField: "field",
          text: "",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "past",
          text: "Past Settings",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "new",
          text: "New Settings",
          headerStyle: { backgroundColor: "white" },
        },
      ];

      return (
        <BootstrapTable
          bootstrap4
          condensed={true}
          bordered={false}
          striped={false}
          keyField="lock"
          data={data}
          columns={columns}
        />
      );
    };

    let handleUpdateSubscribe = () => {
      fetch("/data/updatesubscribe/" + customerid, {
        method: "POST",
        headers: {
          Cache: "no-cache",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSetting),
      }).then((response) => {
        console.log("updatesubscribe", response);
      });

      setShowSubscribe(false);
      setShowSubscribeResult(false);
    };

    return (
      <Modal
        show={showSubscribeResult}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {alreadysubscribe
              ? "The email account has subscribed before"
              : "Done!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!alreadysubscribe ? (
            <div>
              <p></p>
              <p style={{ fontSize: "130%" }}>
                {newSetting.Email} will receive rates report on a{" "}
                {newSetting.Frequency} basis.
              </p>
              <p></p>
            </div>
          ) : (
            showSubscribeMessageExisted()
          )}
        </Modal.Body>

        {alreadysubscribe ? (
          <Modal.Footer>
            <Button variant="light" size="md" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="update" onClick={handleCloseModal}>
              Keep Past Setting
            </Button>
            <Button variant="update" onClick={handleUpdateSubscribe}>
              Use New Setting
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button variant="light" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };

  let handleShowRatePreview = () => {
    let [ltv, cltv, hcltv] = getLtv();
    let data = {
      PropertyType: com.getPropertyType(propertyType),
      LTV: ltv,
      LoanAmount: getLoanAmount(),
      Occupancy: occupancy,
      Purpose: purpose,
      State: state,
      County: county,
      MonthlyIncome: monthlyIncome,
      MonthlyDebt: monthlyDebt,
      Fico: fico,
      selected: selectedTerms,
      fixed30: fixed30[indexfixed30],
      fixed25: fixed25[indexfixed25],
      fixed20: fixed20[indexfixed20],
      fixed15: fixed15[indexfixed15],
      fixed10: fixed10[indexfixed10],
      arm56: arm56[indexarm56],
      arm76: arm76[indexarm76],
      arm106: arm106[indexarm106],
    };

    setPreviewData(data);
    setShowRatePreview(true);
  };

  let sendRatesReport = () => {
    let html = document.getElementById("ratesreport").outerHTML;

    if (previewEmail == "") {
      setPreviewResult("Please provide a valid email.");
      setShowRatePreview(false);
      return;
    }

    let token = sessionStorage.getItem("ZeitroA");

    if (loProfile.firstName !== undefined) {
      html += `
      <div class="col"><p><b>${loProfile.firstName} ${loProfile.lastName}</b> | Loan Officer<br>
      ${loProfile.email} | ${loProfile.phone}<br>
      NMLS: ${loProfile.nmls}</p></div>
      `;
    }
    setShowSendReportSpinner(true);
    fetch("/agent/sendratesreport", {
      method: "POST",
      body: JSON.stringify({
        Email: previewEmail,
        Subject: "Your Personalized Mortgage Rate",
        Body: "<html><body>" + html + "</body></html>",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-AGENT": props.id,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Something went srong when sending the email, please report this issue to Zeitro."
          );
          setShowRatePreview(false);
          setShowSubscribe(false);
          setShowSendReportSpinner(false)
        } else {
          alert("Rates report has been sent successfully.");
          setShowSendReportSpinner(false);
          setShowRatePreview(false);
          setPreviewEmail("");
          setPreviewResult("");
          setShowSubscribe(false);
        }
      })
      .catch(function (err) {
        setShowSendReportSpinner(false)
        console.log("Fetch Error :", err);
      });
  };


  const handleChangeSelectedTerms = (e)=>{
    setSelectedTerms(e)
  }

  const getLendersNotSetMessage = () => {
    return (
      <div className="ratesWarning">
        <Row className="justify-content-md-center">
      <Col xs={1}>
        <img
          src="/images/warningSign.png"
          style={{ maxWidth: "20px" }}
        />
      </Col>
      <Col xs={11}>
      {(props.isFromPreview) ? <div><p>None of your connected loan officers has finished lender configuration.</p><p> Please wait for them to configure lenders or add more loan officers.</p></div> : 
      <div>This functionality is not ready yet. Please check later.</div>}
      </Col>
    </Row>
  </div>

    )
  }

  const updateProperValue = (e) => {
    let val = e.target.value
    if (val==="") {
      setPropertyValue(100000)
    } else {
      setPropertyValue(parseInt(val))
    }
  }

  const updateDownpayment = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(Math.round(propertyValue * 0.03))
    } else {
      setDownpayment(parseInt(val))
    }
  }

  const updateLTV = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(Math.round(propertyValue))
    } else {
      let ltv = parseInt(val)
      if (ltv <= 0) { // TBD
        setDownpayment(Math.round(propertyValue * 0.9))
      } else if (ltv > 97) {
        setDownpayment(Math.round(propertyValue * 0.03))
      } else {
        setDownpayment(Math.round(propertyValue * (1 - 0.01 * ltv)))
      }
    }
  }

  return (
    <div
      id="contain"
      ref={ref}
      style={{ color: "black" }}
      className="w-100 text-center rateQuoteRealtorVersion"
    >
      <Container>
        <Col className="text-center" md="auto">
          <Form
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Loan Purpose
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                  >
                    <option value="Purchase">Purchase</option>
                    <option value="RateTermRefi">Refinance</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>State</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="CALIFORNIA">California</option>
                    <option value="TEXAS">Texas</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {state !== "TEXAS" ? (
                <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      County
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      id="country"
                      name="country"
                      onChange={(e) => setCounty(e.target.value)}
                      // required
                    >
                      {showCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Credit Score
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="input"
                    onChange={(e) =>
                      setFico(
                        parseInt(e.target.value === "" ? 0 : e.target.value)
                      )
                    }
                    value={fico}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>  
            <Form.Row>
              <Col xs={12} md={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    {purpose === "Purchase"
                      ? "Est. Purchase Price"
                      : "Home Value"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign">$</div>
                    <CommaizedInput style={{marginTop:"4px", border:"none", maxWidth:"100px", fontSize:"15px"}} value={propertyValue} onChange={updateProperValue} />
                    <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div>
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} md={6}>
                <Form.Group className="text-left ">
                  <Form.Label
                    className="text-left "
                    style={{ marginBottom: "0" }}
                  >
                    {purpose === "Purchase"
                      ? "Down Payment"
                      : "Mortgage Balance"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign">$</div>
                    <CommaizedInput style={{marginTop:"4px", border:"none", maxWidth:"80px", fontSize:"15px"}} value={downpayment} onChange={updateDownpayment} />
                    <div className="slash">/</div>
                    <input className="ltv" type="number" value={getLtv()[0]} onChange={updateLTV}/>
                    <div className="ltvSuffix">% LTV</div>
                    <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[propertyValue*0.03, propertyValue*0.6]} defaultValues={[downpayment]} onChange={setDownpayment} onUpdate={setDownpayment} step={1000}/></div>
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} md={6}>
              <Form.Group className="text-left ">
                <Form.Label style={{ marginBottom: "0" }}>
                   Loan Types
                </Form.Label>
                <Select
                defaultValue={preselectedTermOptions}
                isMulti
                name="colors"
                options={termOptions}
                className="rateQuoterTermsForm"
                classNamePrefix="rateQuoterMultiSelect"
                closeMenuOnSelect={false}
                isClearable={false}
                onChange={handleChangeSelectedTerms}
              />
              </Form.Group>

              </Col>
              <Col xs={12} md={6}></Col>
            </Form.Row>


            {showMoreOptions ? (
              <Form.Row>
                <Col md>
                  <Form.Group controlId="propertytype" className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Property Type
                    </Form.Label>
                    <Form.Control
                      name="propertytype"
                      as="select"
                      size="sm"
                      type="text"
                      required
                      value={propertyType}
                      onChange={(e) => setPropertyType(e.target.value)}
                    >
                      <option value="singlefamilydetached">
                        Single Family Home, Detached
                      </option>
                      <option value="singlefamilyattached">
                        Single Family Home, Attached
                      </option>
                      <option value="condominiumdetached">
                        Condominium, Detached
                      </option>
                      <option value="condominium">Condominium, Attached</option>
                      <option value="pudattached">
                        Planned Unit Development, attached
                      </option>
                      <option value="puddetached">
                        Planned Unit Development, detached
                      </option>
                      <option value="twotofourfamily">
                        2 to 4 Unit Multifamily
                      </option>
                      <option value="manufacturedhome">
                        Manufactured Home
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {propertyType === "twotofourfamily" ? (
                  <Col md>
                    <Form.Group controlId="propertytype" className="text-left ">
                      Number of Units
                      <Form.Control
                        name="propertytype"
                        as="select"
                        size="sm"
                        type="text"
                        required
                        value={units}
                        onChange={(e) => setUnits(e.target.value)}
                      >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}
                <Col md>
                  <Form.Group controlId="occupancy" className="text-left ">
                    <Form.Label
                      className="text-left "
                      style={{ marginBottom: "0" }}
                    >
                      Occupancy
                    </Form.Label>
                    <Form.Control
                      name="occupancy"
                      as="select"
                      size="sm"
                      defaultValue={occupancy}
                      onChange={(e) => {
                        onOccupancy(e.target.value);
                      }}
                    >
                      <option value="principal">Primary Residence</option>
                      <option value="secondhome">Second Home</option>
                      <option value="investment">Investment</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            ) : (
              ""
            )}

            {showMoreOptions ? (
              <Form.Row>
                <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Monthly Income
                    </Form.Label>
                    <NumericalInput
                      size="sm"
                      value={monthlyIncome}
                      required
                      onChange={(e) => setMonthlyIncome(e.target.value)}
                      min={0}
                    />
                  </Form.Group>
                </Col>
                <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Monthly Liability
                    </Form.Label>
                    <NumericalInput
                      size="sm"
                      value={monthlyDebt}
                      required
                      onChange={(e) => setMonthlyDebt(e.target.value)}
                      min={0}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Estimated Fees (LE part B+C){" "}
                    </Form.Label>
                    <NumericalInput
                      size="sm"
                      value={estOtherCosts}
                      required
                      onChange={(e) => setEstOtherCosts(e.target.value)}
                      min={0}
                    />
                  </Form.Group>
                </Col> */}
              </Form.Row>
            ) : (
              ""
            )}

            <Form.Row>
                <Button
                  variant="rateQuoterAdvancedOptions"
                  onClick={onMoreOptions}
                  size="sm"
                >
                  {showMoreOptions ? "Less Options" : <>Advanced Options<img src="/images/bluePlusInCircleMark.png" style={{maxWidth:"16px", paddingLeft:"5px"}}/></>}
                  
                </Button>{" "}
            </Form.Row>
            <hr className="advancedOptionsDivider" />
            <Form.Row className="text-center rateQuoterUpdateButton">
              <Col>
              <Button type="submit" variant="rateQuoterUpdateButton" >
                  Update Results
              </Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Container>

      {loading ? (
        <Row>
          <div className="text-center w100 justify-content-center  align-content-center">
            <div id="spinner" className="pt-5 mt-4 ml-5 text-center">
              <img
                alt="spinner"
                className="loader mt-5"
                src={
                  com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"
                }
              ></img>
            </div>
          </div>
        </Row>
      ) : (
        <Container >
          {selectedTerms.length !== 0 ? (
            <>
            <div >{showCards()}</div>
            <div className="ratesDisclaimerDiv">
              <div>Estimated third party fee: {getEstimatedThirdPartyFee()}</div>
            </div>
            </>
          ) : (
            <div className="text-wrap w-100">{message}</div>
          )}
          {lendersNotSet && getLendersNotSetMessage()}
        </Container>
      )}

      {showReportIssues && (
        <ReportIssuesModal
          show={true}
          onHide={() => setShowReportIssues(false)}
        />
      )}
      {showSubscribeResult ? getSubscribeResultModal() : ""}
      {showSubscribe && !showSubscribeResult ? getSubscribeModal() : ""}
      {showMessage && showErrorMessage()}
    </div>
  );
};
export default withRouter(RateQuoteRealtorVersion);
