import React, { Component, useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Interview1003View.css";
import "../App.css";
import { SimpleTip } from "../Common/Tooltip";
import { Tabs } from 'antd-mobile'
import {
  Anchor,
  Dropdown,
  Space,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Button,
  DatePicker,
  Alert
} from "antd";
import dayjs from "dayjs";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import IncomeSourcesTable, {
  OtherAssetsTable,
  LiabilitiesTable,
  PropertyMortgagesTable,
  GiftsTable,
  OtherLiabilitiesTable,
} from "./table";
import { DownOutlined, LeftOutlined, RightOutlined, MinusSquareOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { withRouter } from "react-router-dom";
import CheckGroup from "../Common/components/CheckGroup";
import * as st from "../State";
import * as act from "../Store/actions";
import { connect } from "react-redux";
import {
  UpdateChangeProgress,
  UpdateChangeVisitedStage,
  UpdateChangeLastTouch,
} from "../Store/progress";
import { getCurrentState } from "../Store";
import * as com from "../Common.js";
import { UR_LoanProcessor } from "../State";
import throttle from "lodash.throttle";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)
const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeBorrowerInfo: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb));
  },
  changePhoneInfo: (input, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(input, who, verb));
  },
  changeBorrowerInfoByValue: (val, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(val, who, verb));
  },
  changeBorrowerInfoByNumberValue: (val, who, verb) => {
    val = val.toString().replace(/\D/g, "")
    dispatch(act.ChangeBorrowerInfo(val, who, verb));
  },
  updateCoborrower: (event) => {
    dispatch(act.UpdateHasCoborrower(event));
  },
  changeOccupationInfo: (event, who, verb) => {
    dispatch(act.ChangeOccupationInfo(event.target.value, who, verb));
  },
  changeOccupationInfoByValue: (event, who, verb) => {
    dispatch(act.ChangeOccupationInfo(event, who, verb));
  },
  updateIncomeMonthly: (event, who, verb) => {
    dispatch(act.UpdateIncomeMonthly(event.target.value, who, verb));
  },
  updateIncomeMonthlyByVal: (event, who, verb) => {
    dispatch(act.UpdateIncomeMonthly(event, who, verb));
  },
  updateIncomeAtttribute: (t, who, verb) => {
    dispatch(act.UpdateIncomeAtttribute(t, who, verb));
  },
  addBorrowerOtherOccupation: (who) => {
    dispatch(act.AddBorrowerOtherOccupation(who));
  },
  removeBorrowerOtherOccupation: (who, index) => {
    dispatch(act.RemoveBorrowerOtherOccupation(who, index));
  },
  clearBorrowerOtherOccupations: (who) => {
    dispatch(act.ClearBorrowerOtherOccupations(who));
  },
  changeBorrowerOtherOccupationValue: (t, i, who, verb) => {
    dispatch(act.ChangeBorrowerOtherOccupationValue(t, i, who, verb));
  },
  changeBorrowerPreviousOccupationIncome: (t, i, who, verb) => {
    dispatch(act.ChangeBorrowerPreviousOccupationIncome(t, i, who, verb));
  },
  addBorrowerPreviousOccupation: (who) => {
    dispatch(act.AddBorrowerPreviousOccupation(who));
  },
  removeBorrowerPreviousOccupation: (who, index) => {
    dispatch(act.RemoveBorrowerPreviousOccupation(who, index));
  },
  clearBorrowerPreviousOccupations: (who) => {
    dispatch(act.ClearBorrowerPreviousOccupations(who));
  },
  changeBorrowerPreviousOccupationValue: (t, i, who, verb) => {
    dispatch(act.ChangeBorrowerPreviousOccupationValue(t, i, who, verb));
  },
  changeOccupationCheck: (event, who, verb) => {
    dispatch(act.ChangeOccupationInfo(event.target.checked, who, verb));
  },
  addAdditionaOccupation: (event, who) => {
    dispatch(act.AddAdditionaOccupation(event, who));
  },
  removeAdditionalOccupation: (event, who, i) => {
    dispatch(act.RemoveAdditionalOccupation(event.target.value, who, i));
  },
  clearAdditionalOccupations: (event, who) => {
    dispatch(act.ClearAdditionalOccupations(event, who));
  },
  bulkAddBorrowerOwnedProperties: (t) => {
    dispatch(act.BulkAddBorrowerOwnedProperties(t));
  },
  addBorrowerOwnedProperty: (t) => {
    dispatch(act.AddBorrowerOwnedProperty(t));
  },
  changeOwnedProperty: (t, i, v) => {
    dispatch(act.ChangeOwnedProperty(t, i, v));
  },
  removeBorrowerOwnedProperty: (i) => {
    dispatch(act.RemoveBorrowerOwnedProperty(i));
  },
  clearBorrowerOwnedProperty: (t) => {
    dispatch(act.ClearBorrowerOwnedProperty(t));
  },
  updateExpenses: (val, who, verb) => {
    dispatch(act.UpdateExpenses(val, who, verb))
  },
  updateBorrowerAdditionalIncome: (event, who, verb, n) => {
    dispatch(
      act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n)
    );
  },
  changePastOccupationInfo: (event, who, verb, index) => {
    dispatch(
      act.ChangePastOccupationInfo(event.target.value, who, verb, index)
    );
  },
  changePastOccupationDate: (date, who, verb, index) => {
    dispatch(act.ChangePastOccupationInfo(date, who, verb, index));
  },
  changePastOccupationPhone: (phone, who, verb, index) => {
    dispatch(act.ChangePastOccupationInfo(phone, who, verb, index));
  },
  changePastOccupationCheck: (event, who, verb, index) => {
    dispatch(
      act.ChangePastOccupationInfo(event.target.checked, who, verb, index)
    );
  },
  addBorrowerAdditionalIncome: (t, who) => {
    dispatch(act.AddBorrowerAdditionalIncome(t, who));
  },
  removeBorrowerAdditionalIncome: (i, who) => {
    dispatch(act.RemoveBorrowerAdditionalIncome(i, who));
  },
  clearBorrowerAdditionalIncome: (who) => {
    dispatch(act.ClearBorrowerAdditionalIncome(who));
  },
  updateBorrowerAdditionalIncome: (event, who, verb, n) => {
    dispatch(
      act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n)
    );
  },
  changeMainProperty: (value, verb) => {
    dispatch(act.ChangeMainProperty(value, verb));
  },
  changeMainPropertyPurpose: (val) => {
    dispatch(act.ChangeMainPropertyPurpose(val));
  },
  changeMainPropertyAttribute: (t, verb) => {
    dispatch(act.ChangeMainPropertyAttribute(t, verb))
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
  clearBorrowerGift: () => {
    dispatch(act.ClearBorrowerGift());
  },
  changeDeclaration: (event, who, verb) => {
    dispatch(act.ChangeDeclaration(event, who, verb));
  },
  updateEqualOpportunity: (value, who, verb) => {
    dispatch(act.UpdateEqualOpportunity(value, who, verb));
  },
  updateEqualOpportunitySub: (value, who, section, verb) => {
    dispatch(act.UpdateEqualOpportunitySub(value, who, section, verb));
  },
  updateChangeProgress: (step, stage) => {
    dispatch(UpdateChangeProgress(step, stage));
  },
  updateChangeLastTouch: (t) => {
    dispatch(UpdateChangeLastTouch(t));
  },
  updateChangeVisitedStage: (step, stage) => {
    dispatch(UpdateChangeVisitedStage(step, stage));
  },
  updateApplicationAttribute: (input, verb) => {
    dispatch(act.UpdateApplicationAttribute(input, verb));
  },
});

class Interview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      loanLimits: [],
      expandedAnchor: false,
      showOwnPropertyWarning1: false,
      showOwnPropertyWarning2: false,
      activeKey: 's1'
    };
    this.throttleScroll = throttle(
      () => {
        let currentKey = this.tabItems[0].key
        for (const item of this.tabItems) {
          const element = document.getElementById(`${item.key}`)
          if (!element) continue
          const rect = element.getBoundingClientRect()
          if (rect.top <= 380) {
            currentKey = item.key
          } else {
            break
          }
        }
        this.setState({ activeKey: currentKey })
      },
      {
        leading: true,
        trailing: true,
        wait: 300,
      }
    )
  }

  getLoanLimits = () => {
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        this.setState({ loanLimits: res });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getCountyOptions = (st) => {
    let options = [];
    let s = st.toUpperCase();
    if (st.length === 2) {
      s = com.convertAbbStateToFull(st).toUpperCase();
    }

    if (s in this.state.loanLimits) {
      let keys = Object.keys(this.state.loanLimits[s]);

      keys.forEach((x) => {
        options.push({ value: com.capitalize(x), label: com.capitalize(x) });
      });
    }
    return options;
  };

  getStateOptions = () => {
    let options = [];
    let keys = Object.keys(this.state.loanLimits);

    keys.forEach((x) => {
      options.push({
        value: com.capitalize(x),
        label: com.capitalize(x),
      });
    });
    return options;
  };

  updateIncomeMonthly = (val, who, attr) => {
    this.props.updateIncomeMonthlyByVal("monthly", who, attr+"monthly")
    this.props.updateIncomeMonthly(val, who, attr)
  }

  dateField = (label, value, onChangeFunc, placeholder) => {
    let dateFormat = "MM/DD/YYYY";
    let earliest = "01/01/1900"

    return (
      <div>
        {label === null ? (
          ""
        ) : (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            {label}
          </div>
        )}
        {this.state.editMode ? (
            <DatePicker
              size="large"
              value={value===""?value: dayjs(value, dateFormat)}
              format="MM/DD/YYYY"
              onChange={onChangeFunc}
            />
        ) : (
          <div style={{ fontSize: 16 }}>{value}</div>
        )}
      </div>
    );
  };

  inputField = (label, value, onChangeFunc, placeholder) => {
    return (
      <div>
        {label === null ? (
          ""
        ) : (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            {label}
          </div>
        )}
        {this.state.editMode ? (
          <Form.Control
            value={value}
            defaultValue={value}
            onChange={onChangeFunc}
            placeholder={typeof placeholder !== "undefined" ? placeholder : ""}
          />
        ) : (
          <div style={{ fontSize: 16 }}>{value}</div>
        )}
      </div>
    );
  };
  inputFieldWithPrefix = (label, value, onChangeFunc, prefix, tipContent) => {
    return (
      <div>
        {label !== null ? (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            {label}
          </div>
        ) : (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            &nbsp;
          </div>
        )}

        {this.state.editMode ? (
          <Input
            value={value}
            onChange={onChangeFunc}
            addonBefore={typeof tipContent!=="undefined" ? <div>{prefix}<SimpleTip content={tipContent} /> </div> : prefix}
            size="large"
          />
        ) : (
          <div style={{ fontSize: 16 }}>{prefix + ": " + value}</div>
        )}
      </div>
    );
  };
  inputNumberFields = (label, values, onChangeFuncs, prefixes, suffixes) => {
    let inputFields = [];
    for (let i = 0; i < values.length; i++) {
      inputFields.push(
        this.state.editMode ? (
          <InputNumber
            value={values[i]}
            defaultValue={values[i]}
            onChange={onChangeFuncs[i]}
            addonBefore={prefixes !== null ? prefixes[i] : ""}
            addonAfter={suffixes !== null ? suffixes[i] : ""}
            size="large"
            className="pr-2"
          />
        ) : (
          <div style={{ fontSize: 16 }} className="pr-2">
            {values[i] + " " + suffixes[i]}
          </div>
        )
      );
    }

    return (
      <div>
        {label !== null ? (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            {label}
          </div>
        ) : (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            &nbsp;
          </div>
        )}
        <div className="d-flex">{inputFields}</div>
      </div>
    );
  };
  inputFields = (label, values, onChangeFuncs, prefixes, suffixes, valuePeriods) => {
    let inputFields = [];
    for (let i = 0; i < values.length; i++) {
      let val = values[i]
      if (typeof valuePeriods !== "undefined") {
        let period = valuePeriods[i]
        if (period === "yearly") {
          val = Math.round(com.safeParseInt(values[i])/12)
        }
      }
      inputFields.push(
        this.state.editMode ? (
          <Input
            value={val}
            onChange={onChangeFuncs[i]}
            addonBefore={prefixes !== null ? prefixes[i] : ""}
            suffix={suffixes !== null ? suffixes[i] : ""}
            className={i === values.length - 1 ? "" : "pr-4"}
          />
        ) : (
          <div
            style={{ fontSize: 16 }}
            className={i === values.length - 1 ? "" : "pr-4"}
          >
            {prefixes[i] + ": " + values[i] + " " + suffixes[i]}
          </div>
        )
      );
    }

    return (
      <div>
        {label !== null && (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            {label}
          </div>
        )}
        <div className="d-flex">{inputFields}</div>
      </div>
    );
  };
  dropdownField = (label, value, options, onChangeFunc) => {
    let selected = options.filter((o) => o.value === value);
    return (
      <div>
        {label === null ? (
          ""
        ) : (
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            {label}
          </div>
        )}
        {this.state.editMode ? (
          <Form.Control
            as="select"
            defaultValue={value}
            value={value}
            onChange={onChangeFunc}
          >
            {options.map((o) => (
              <option value={o.value}>{o.label}</option>
            ))}
          </Form.Control>
        ) : (
          <div style={{ fontSize: 16 }}>
            {selected.length > 0 ? selected[0].label : ""}
          </div>
        )}
      </div>
    );
  };
  horizontalDropdownField = (label, value, options, onChangeFunc) => {
    let selected = options.filter((o) => o.value === value);
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="mr-2 col-7">{label}</div>
        {this.state.editMode ? (
          <Form.Control
            as="select"
            defaultValue={value}
            onChange={onChangeFunc}
          >
            {options.map((o) => (
              <option value={o.value}>{o.label}</option>
            ))}
          </Form.Control>
        ) : (
          <div style={{ fontSize: 16 }}>
            {selected.length > 0 ? selected[0].label : ""}
          </div>
        )}
      </div>
    );
  };
  selectionField = (label, value, options, onChangeFunc, isHorizontal) => {
    let selected = options.filter((o) => o.value === value);
    if (isHorizontal) {
      return (
        <div className="d-flex justify-content-between">
          <div className="mr-2">{label}</div>
          {this.state.editMode ? (
            <CheckGroup
              data={options}
              onChangeSelect={onChangeFunc}
              value={value}
              type="radio"
              style={{ width: "100%" }}
              direction="horizontal"
            />
          ) : (
            <div style={{ fontSize: 16 }}>
              {selected.length > 0 ? selected[0].label : ""}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
            {label}
          </div>
          {this.state.editMode ? (
            <CheckGroup
              data={options}
              onChangeSelect={onChangeFunc}
              value={value}
              type="radio"
              style={{ width: "100%" }}
              direction="horizontal"
            />
          ) : (
            <div style={{ fontSize: 16 }}>
              {selected.length > 0 ? selected[0].label : ""}
            </div>
          )}
        </div>
      );
    }
  };
  yesNoField = (label, value, onChangeFunc) => {
    let options = [
      {
        value: "yes",
        label: "Yes",
      },
      {
        value: "no",
        label: "No",
      },
    ];
    let selected = options.filter((o) => o.value === value);
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="mr-2 fontWeight500 col-7 pl-0">{label}</div>
        {this.state.editMode ? (
          <CheckGroup
            data={options}
            onChangeSelect={onChangeFunc}
            value={value}
            type="radio"
            style={{ width: "100%" }}
            direction="horizontal"
          />
        ) : (
          <div style={{ fontSize: 16 }}>
            {selected.length > 0 ? selected[0].label : ""}
          </div>
        )}
      </div>
    );
  };
  onAddressChange = (e) => {
    let addr = e.target.value;
    if (addr === "") {
      this.props.changeMainProperty(false, "lookupsuccessful");
    }
    return this.props.changeBorrowerInfo(
      e,
      "borrower",
      "presentaddressstreet"
    );
  };
  onStreetAddressCompletion = (address, who) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    let zip = com.getSubaddress(address, "postal_code", "long_name");

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );

    this.props.changePhoneInfo(county, who, "presentaddresscity");
    this.props.changePhoneInfo(state, who, "presentaddressstate");
    this.props.changePhoneInfo(zip, who, "presentaddresszip");
    let sfx = "";
    let short = com.getSubaddress(address, "route", "short_name");

    let stradd = num + " " + short;
    this.props.changeBorrowerInfo(
      { target: { value: stradd } },
      who,
      "presentaddressstreet"
    );

    let shorts = short.split(" ");
    if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
      sfx = shorts.pop();

      if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
        sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase();

      short = shorts.join(" ");
    }
    let prefix = "";
    let s = shorts[0].toUpperCase();

    if (com.prefixes.includes(s)) {
      prefix = shorts[0];
      shorts = short.split(" ");
      short = shorts.slice(1, shorts.length).join(" ");
    }

    if (
      who === "borrower" &&
      this.props.application.property.purpose !== "purchase" &&
      this.props.application.financingsameproperty
    )
      this.lookupProperty(num, prefix, short, sfx, city, zip);
  };
  getTotalIncome = () => {
    let emp = this.props.application.borrower.occupation
    let i1 = parseFloat(emp.income.base === "" ? 0 : emp.income.base)
    let i2 = parseFloat(emp.income.bonuses === "" ? 0 : emp.income.bonuses)
    let i3 = parseFloat(emp.income.commissions === "" ? 0 : emp.income.commissions)
    let i4 = parseFloat(emp.income.overtime === "" ? 0 : emp.income.overtime)
    return i1+i2+i3+i4
  }
  renderOwnedProperty = (i) => {
    let property = this.props.application.assetsandliabilities.ownedproperties[i]
    return (
      <>
        <Row className="">
          <Col>
            {this.state.editMode ? <AddressAutocomplete
                    id={"OwnedPropertyAddress" + i}
                    label={<div className="fontWeight500">Address</div>}
                    required={true}
                    name="addessline"
                    defaultValue={property.address}
                    onChange={(e) => this.props.changeOwnedProperty(e.target.value, i, "address")}
                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                    placeholder="Please choose from the suggested addresses generated based on the input."
                    goodfeedback="Looks good!"
                    badfeedback="Please provide your street address."
                    onCompletion={e=>{
                      let address = e
                      let state = com.getSubaddress(
                        address,
                        "administrative_area_level_1",
                        "short_name"
                      );
                      let zip = com.getSubaddress(address, "postal_code", "long_name");
                      let city = com.getSubaddress(address, "locality", "long_name");
                      let county = com.getSubaddress(
                        address,
                        "administrative_area_level_2",
                        "long_name"
                      );
                      let num = com.getSubaddress(address, "street_number", "long_name");
                      let short = com.getSubaddress(address, "route", "short_name");
                      let stradd = num + " " + short+", "+city+", "+state+" "+zip;
                      this.props.changeOwnedProperty(state, i, "state")
                      this.props.changeOwnedProperty(county, i, "county")
                      this.props.changeOwnedProperty(city, i, "city")
                      this.props.changeOwnedProperty(zip, i, "zipcode")
                      this.props.changeOwnedProperty(stradd, i, "address")
                    }}
                    size="md"
                    /> : <div className="fontSize16">{property.address}</div>} 

          </Col>
        </Row>
        <Row>
          <Col sm={4} className="mb-4">
            {this.dropdownField(
              null,
              property.state,
              this.getStateOptions(),
              (e) => this.props.changeOwnedProperty(e.target.value, i, "state")
            )}
          </Col>
          <Col sm={4} className="mb-4">
            {this.dropdownField(
              null,
              property.county,
              this.getCountyOptions(property.state),
              (e) => this.props.changeOwnedProperty(e.target.value, i, "county")
            )}
          </Col>
          <Col sm={4} className="mb-4">
            {this.inputField(
              null,
              property.zipcode,
              (e) => this.props.changeOwnedProperty(e.target.value, i, "zipcode"),
              "Zip code"
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="mb-4">
            {this.inputFieldWithPrefix(
              "Property Value",
              property.marketvalue,
              (e) =>
                this.props.changeOwnedProperty(e.target.value, i, "marketvalue"),
              "$"
            )}
          </Col>
          <Col sm={6} className="mb-4">
            {this.dropdownField(
              "Status",
              property.status,
              [
                { value: "sold", label: "Sold" },
                { value: "pending", label: "Pending sale" },
                { value: "rental", label: "Rental" },
                { value: "second", label: "Second" },
                { value: "rental", label: "Rental" },
              ],
              (e) =>this.props.changeOwnedProperty(e.target.value, i, "status"),
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="mb-4">
            {this.dropdownField(
              "Intended Occupancy",
              property.occupancy,
              [
                { value: "principal", label: "Primary Residence" },
                { value: "secondhome", label: "Second Home" },
                { value: "investment", label: "Investment" },
              ],
              (e) =>
                this.props.changeOwnedProperty(e.target.value, i, "occupancy")
            )}
          </Col>
          <Col sm={6} className="mb-4">
            {this.inputFieldWithPrefix(
              "Monthly insurance, taxes, association dues, etc.",
              property.insurancemaintenancetaxes,
              (e) =>this.props.changeOwnedProperty(e.target.value, i, "insurancemaintenancetaxes"),
              "$"
            )}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Checkbox
              checked={
                property.propertytype ==="twotofourfamily"
              }
              className="fontWeight500"
              onChange={(e)=>{
                if (e.target.checked) {
                  this.props.changeOwnedProperty("twotofourfamily",i,"propertytype")
                } else {
                  this.props.changeOwnedProperty("singlefamilydetached",i,"propertytype")
                }
              }}
            >
              This is a 2-4 unit property
            </Checkbox>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Checkbox
              checked={property.occupancy === "investment"}
              className="fontWeight500"
              onChange={(e)=>{
                if (e.target.checked) {
                  this.props.changeOwnedProperty("investment",i,"occupancy")
                } else {
                  this.props.changeOwnedProperty("principal",i,"occupancy")
                }
              }}
            >
              This is a investment property
            </Checkbox>
          </Col>
        </Row>
        {(
          property.occupancy==="investment" ||
          property.propertytype==="twotofourfamily"
        ) && <Row className="mb-4">
        <Col md={6}>
          {this.inputFieldWithPrefix(
            "Expected Monthly Rental Income",
            property.netrentalincome,
            (e) =>
              this.props.changeOwnedProperty(
                e.target.value,
                i,
                "netrentalincome"
              ),
            "$"
          )}
        </Col>
      </Row>}
        {/* <Row className="mb-4">
          <PropertyMortgagesTable />
        </Row> */}
      </>
    )
  }
  sectionS1 = () => {
    return (
      <div id="s1" className="p-4">
        <div className="fontWeight500" style={{ fontSize: 20 }}>
          Section 1: Borrower Information
        </div>
        <div>
          This section asks about your personal information and your income from
          employment and other sources, such as retirement, that you want
          considered to qualify for this loan.
        </div>
      </div>
    );
  };
  section1a = () => {
    return (
      <div id="1a">
        <div className="form1003Title">1a. Personal Information</div>
        <div className="p-4">
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "First Name",
                this.props.application.borrower.firstname,
                (event) =>
                  this.props.changeBorrowerInfo(event, "borrower", "firstname")
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "Last Name",
                this.props.application.borrower.lastname,
                (event) =>
                  this.props.changeBorrowerInfo(event, "borrower", "lastname")
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              {this.inputField(
                "Social Security Number",
                this.props.application.borrower.socialsecurity.replaceAll(
                  "-",
                  ""
                ),
                (e) => {
                  const cleanedValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                  const firstPart = cleanedValue.slice(0, 3);
                  const secondPart = cleanedValue.slice(3, 5);
                  const thirdPart = cleanedValue.slice(5, 9);
                  let formattedValue = "";
                  if (firstPart) {
                    formattedValue += firstPart;
                  }
                  if (secondPart) {
                    formattedValue += `-${secondPart}`;
                  }
                  if (thirdPart) {
                    formattedValue += `-${thirdPart}`;
                  }
                  this.props.changeBorrowerInfo(
                    { target: { value: formattedValue } },
                    "borrower",
                    "socialsecurity"
                  );
                }
              )}
            </Col>
            <Col sm={6} className="mb-3">
              {this.dateField(
                "Date of Birth",
                this.props.application.borrower.dateofbirth,
                (date, dateString) =>
                  this.props.changeBorrowerInfo(
                    { target: { value: dateString } },
                    "borrower",
                    "dateofbirth"
                  )
              )}
            </Col>
          </Row>
          <Row className="">
            <Col sm={6}>
              {this.dropdownField(
                "Citizenship",
                this.props.application.borrower.citizenship===null?"":this.props.application.borrower.citizenship,
                [
                  { value: "", label: "..." },
                  { value: "citizen", label: "US Citizen" },
                  { value: "permanent", label: "US Permanent Resident" },
                  { value: "alien", label: "Non-US Citizen" },
                ],
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "citizenship"
                  )
              )}
            </Col>
            <Col className="pr-0"></Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col>
              {this.selectionField(
                "Type of Credit",
                this.props.application.hascoborrower,
                [
                  {
                    value: "alone",
                    label: "I am applying for individual credit",
                  },
                  {
                    value: "withcoborrower",
                    label: "I am applying for joint credit",
                  },
                ],
                (e) => this.props.updateCoborrower(e)
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col>
              {this.selectionField(
                "Marital Status",
                this.props.application.borrower.maritalstatus,
                [
                  {
                    value: "married",
                    label: "Married",
                  },
                  {
                    value: "separated",
                    label: "Separated",
                  },
                  {
                    value: "unmarried",
                    label: "Unmarried",
                  },
                ],
                (e) =>
                  this.props.changeBorrowerInfoByValue(
                    e,
                    "borrower",
                    "maritalstatus"
                  )
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={6}>
              {this.inputFieldWithPrefix(
                "Dependents (not listed by another borrower)",
                this.props.application.borrower.numberofdependents,
                (e) =>
                  this.props.changeBorrowerInfo(
                    e,
                    "borrower",
                    "numberofdependents"
                  ),
                "Number"
              )}
            </Col>
            <Col sm={6}>
              {this.inputFieldWithPrefix(
                null,
                this.props.application.borrower.dependentsages,
                (e) =>
                  this.props.changeBorrowerInfo(
                    e,
                    "borrower",
                    "dependentsages"
                  ),
                "Ages",
                `Divided ages by ",". For example, "12, 20, 39"`,
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col sm={6}>
              {this.inputFieldWithPrefix(
                "Contact",
                this.props.application.borrower.homephone,
                (e) =>{
                  let phone = com.fixPhoneInput(e.target.value);
                  this.props.changeBorrowerInfo({target:{value:phone}}, "borrower", "homephone")
                },
                "Phone"
              )}
            </Col>
            <Col sm={6}>
              {this.inputFieldWithPrefix(
                null,
                this.props.application.borrower.email,
                (e) => this.props.changeBorrowerInfo(e, "borrower", "email"),
                "Email"
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-1">
            <Col>
                {this.state.editMode ? <AddressAutocomplete
                      id="Address"
                      label={<div className="fontWeight500">Current Residential Address</div>}
                      required={true}
                      name="addessline"
                      defaultValue={
                        this.props.application["borrower"]
                          .presentaddressstreet
                      }
                      onChange={(e) => this.props.changeBorrowerInfo(e, "borrower", "presentaddressstreet")}
                      pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                      placeholder="Please choose from the suggested addresses generated based on the input."
                      goodfeedback="Looks good!"
                      badfeedback="Please provide your street address."
                      onCompletion={e=>this.onStreetAddressCompletion(e, "borrower")}
                      size="md"
                      /> : <div className="fontSize16">{this.props.application["borrower"]
                      .presentaddressstreet}</div>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={4} className="mb-4">
              {this.dropdownField(
                null,
                this.props.application.borrower.presentaddressstate,
                this.getStateOptions(),
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "presentaddressstate"
                  )
              )}
            </Col>
            <Col sm={4} className="mb-4">
              {this.dropdownField(
                null,
                this.props.application.borrower.presentaddresscounty,
                this.getCountyOptions(
                  this.props.application.borrower.presentaddressstate
                ),
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "presentaddresscounty"
                  )
              )}
            </Col>
            <Col sm={4} className="mb-4">
              {this.inputField(
                null,
                this.props.application.borrower.presentaddresszip,
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "presentaddresszip"
                  )
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputNumberFields(
                "How Long at Current Address?",
                [
                  this.props.application.borrower.presentaddressyears,
                  this.props.application.borrower.presentaddressmonths,
                ],
                [
                  (event) =>
                    this.props.changeBorrowerInfoByNumberValue(
                      event,
                      "borrower",
                      "presentaddressyears"
                    ),
                  (event) =>
                    this.props.changeBorrowerInfoByNumberValue(
                      event,
                      "borrower",
                      "presentaddressmonths"
                    ),
                ],
                null,
                ["Years", "Months"]
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.selectionField(
                "Housing",
                this.props.application.borrower.presentaddressownership,
                [
                  {
                    value: st.O_Rentfree,
                    label: "No primary expense",
                  },
                  {
                    value: st.O_Own,
                    label: "Own",
                  },
                  {
                    value: st.O_Rent,
                    label: "Rent",
                  },
                ],
                (e) =>
                  this.props.changeBorrowerInfoByValue(
                    e,
                    "borrower",
                    "presentaddressownership"
                  )
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Checkbox
                checked={!this.props.application.borrower.samemailingaddress}
                onChange={(e) => {
                  this.props.changeBorrowerInfoByValue(
                    !e.target.checked,
                    "borrower",
                    "samemailingaddress"
                  )
                  if (!e.target.checked) {
                    let b = this.props.application.borrower
                    this.props.changeBorrowerInfoByValue(b.presentaddressstreet,"borrower","mailingaddressstreet")
                    this.props.changeBorrowerInfoByValue(b.presentaddresscity,"borrower","mailingaddresscity")
                    this.props.changeBorrowerInfoByValue(b.presentaddressstate,"borrower","mailingaddressstate")
                    this.props.changeBorrowerInfoByValue(b.presentaddresszip,"borrower","mailingaddresszip")
                  }
                }
                }
                className="fontWeight500"
              >
                Mailing Address is different from Current Address
              </Checkbox>
            </Col>
          </Row>
          {!this.props.application.borrower.samemailingaddress && <Row className="mb-4">
            <Col>
              {this.inputField(
                "Mailing Address",
                this.props.application.borrower.mailingaddressstreet,
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "mailingaddressstreet"
                  )
              )}
            </Col>
          </Row>}
          {!this.props.application.borrower.samemailingaddress &&          <Row className="mb-4">
            <Col>
              {this.dropdownField(
                null,
                this.props.application.borrower.mailingaddressstate,
                this.getStateOptions(),
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "mailingaddressstate"
                  )
              )}
            </Col>
            <Col className="">
              {this.dropdownField(
                null,
                this.props.application.borrower.mailingaddresscity,
                this.getCountyOptions(
                  this.props.application.borrower.mailingaddressstate
                ),
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "mailingaddresscity"
                  )
              )}
            </Col>
            <Col>
              {this.inputField(
                null,
                this.props.application.borrower.mailingaddresszip,
                (event) =>
                  this.props.changeBorrowerInfo(
                    event,
                    "borrower",
                    "mailingaddresszip"
                  )
              )}
            </Col>
          </Row>}

        </div>
      </div>
    );
  };

  section1b = () => {
    let emp = this.props.application.borrower.occupation;
    return (
      <div id="1b">
        <div className="form1003Title">1b. Employment and Income</div>
        <div className="p-4">
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "Employer or Business Name",
                emp.employername,
                (e) =>
                  this.props.changeOccupationInfo(e, "borrower", "employername")
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.inputField("Phone", emp.phone, (e) => {
                let phone = com.fixPhoneInput(e.target.value);
                this.props.changeOccupationInfo({target:{value:phone}}, "borrower", "phone")
              }
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputField("Address", emp.employeraddress, (e) =>
                this.props.changeOccupationInfo(
                  e,
                  "borrower",
                  "employeraddress"
                )
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={6} className="mb-4">
              {this.inputField("Position or Title", emp.positiontitle, (e) =>
                this.props.changeOccupationInfo(
                  e,
                  "borrower",
                  "positiontitle"
                )
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.dateField(
                "Start Date",
                emp.from,
                (date, dateString) =>
                  this.props.changeOccupationInfoByValue(
                    dateString,
                    "borrower",
                    "from"
                  )
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputNumberFields(
                "How long in this line of work?",
                [emp.yearsatlineofwork, emp.monthsatlineofwork],
                [
                  (event) =>
                    this.props.changeOccupationInfoByValue(
                      event,
                      "borrower",
                      "yearsatlineofwork"
                    ),
                  (event) =>
                    this.props.changeOccupationInfoByValue(
                      event,
                      "borrower",
                      "monthsatlineofwork"
                    ),
                ],
                null,
                ["Years", "Months"]
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Checkbox
                checked={emp.employedbyagent}
                onChange={(e) =>
                  this.props.changeOccupationInfoByValue(
                    e.target.checked,
                    "borrower",
                    "employedbyagent"
                  )
                }
                className="fontWeight500"
              >
                I am employed by a family member, property seller, real estate
                agent, or other party to the transaction.
              </Checkbox>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Checkbox
                checked={emp.selfemployed}
                onChange={(e) =>
                  this.props.changeOccupationInfoByValue(
                    e.target.checked,
                    "borrower",
                    "selfemployed"
                  )
                }
                className="fontWeight500"
              >
                Check if you are the Business Owner or Self-Employed
              </Checkbox>
            </Col>
          </Row>
          {emp.selfemployed && (
            <Row>
              <Col sm={6} className="mb-4">
                {this.inputField(
                  "I have an ownership share of",
                  emp.percentownership,
                  (e) =>
                    this.props.changeOccupationInfo(
                      e,
                      "borrower",
                      "percentownership"
                    )
                )}
              </Col>
              <Col sm={6} className="mb-4">
                {this.inputField(
                  "Monthly Income (or Loss)",
                  emp.incomeorloss,
                  (event) =>
                    this.props.changeOccupationInfo(
                      event,
                      "borrower",
                      "incomeorloss"
                    )
                )}
              </Col>
            </Row>
          )}
          <hr className="form1003Divider"></hr>
          <Row>
            <Col className="mb-4">
              {this.inputFields(
                "Gross Monthly Income",
                [emp.income.base, emp.income.overtime],
                [
                  (e) => this.updateIncomeMonthly(e, "borrower", "base"),
                  (e) =>
                    this.updateIncomeMonthly(e, "borrower", "overtime"),
                ],
                ["Base   ", "Overtime  "],
                ["mo", "mo"]
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              {this.inputFields(
                null,
                [emp.income.bonuses, emp.income.commissions],
                [
                  (e) => this.updateIncomeMonthly(e, "borrower", "bonuses"),
                  (e) =>
                    this.updateIncomeMonthly(
                      e,
                      "borrower",
                      "commissions"
                    ),
                ],
                ["Bonus", "Commissions"],
                ["mo", "mo"]
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputFields(
                "Total Income",
                [this.getTotalIncome()],
                [],
                ["$"],
                ["mo"]
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  section1c = () => {
    const changeVal = (i, t, useValue) => {
      return (e) =>
        this.props.changeBorrowerOtherOccupationValue(
          useValue ? e : e.target.value,
          i,
          "borrower",
          t
        );
    };
    const addIncome = () => {
      if (this.props.application.borrower.otheroccupations === null) {
        this.props.clearBorrowerOtherOccupations("borrower");
      }
      this.props.addBorrowerOtherOccupation("borrower");
    };
    const displayAdditionalOccupation = () => {
      if (
        this.props.application.borrower.otheroccupations === null ||
        this.props.application.borrower.otheroccupations.length === 0
      ) {
        return "";
      }
      let out = [];
      for (
        let i = 0;
        i < this.props.application.borrower.otheroccupations.length;
        i++
      ) {
        out.push(additonalIncome(i));
      }
      return out;
    };
    const additonalIncome = (i) => {
      let emp = this.props.application.borrower.otheroccupations[i];
      let getTotalIncome = () => {
        let i1 = parseFloat(emp.income.base === "" ? 0 : emp.income.base)
        let i2 = parseFloat(emp.income.bonuses === "" ? 0 : emp.income.bonuses)
        let i3 = parseFloat(emp.income.commissions === "" ? 0 : emp.income.commissions)
        let i4 = parseFloat(emp.income.overtime === "" ? 0 : emp.income.overtime)
        return i1+i2+i3+i4
      }
      return (
        <div className="p-4 removable">
          <div className="text-right"><MinusSquareOutlined onClick={()=>this.props.removeBorrowerOtherOccupation("borrower", i)} style={{color:"#325CEB", fontSize: 16, cursor:"pointer"}}/></div>
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "Employer or Business Name",
                emp.employername,
                changeVal(i, "employername")
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.inputField("Phone", emp.phone, changeVal(i, "phone"))}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputField(
                "Address",
                emp.employeraddress,
                changeVal(i, "employeraddress")
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "Position or Title",
                emp.positiontitle,
                changeVal(i, "positiontitle")
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.dateField(
                "Start Date",
                emp.from,
                (date, dateString) =>
                  this.props.changeBorrowerOtherOccupationValue(
                    dateString,
                    i,
                    "borrower",
                    "from"
                  )
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputNumberFields(
                "How long in this line of work?",
                [emp.yearsatlineofwork, emp.monthsatlineofwork],
                [
                  changeVal(i, "yearsatlineofwork", true),
                  changeVal(i, "monthsatlineofwork", true),
                ],
                null,
                ["Years", "Months"]
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Checkbox
                checked={emp.employedbyagent}
                onChange={changeVal(i, "employedbyagent")}
                className="fontWeight500"
              >
                I am employed by a family member, property seller, real estate
                agent, or other party to the transaction.
              </Checkbox>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="px-0">
              <Checkbox
                checked={emp.selfemployed}
                onChange={changeVal(i, "selfemployed")}
                className="fontWeight500"
              >
                Check if you are the Business Owner or Self-Employed
              </Checkbox>
            </Col>
          </Row>
          {emp.selfemployed && (
            <Row>
              <Col sm={6} className="mb-4">
                {this.inputField(
                  "I have an ownership share of",
                  emp.percentownership,
                  changeVal(i, "percentownership")
                )}
              </Col>
              <Col sm={6} className="mb-4">
                {this.inputField(
                  "Monthly Income (or Loss)",
                  emp.incomeorloss,
                  changeVal(i, "incomeorloss")
                )}
              </Col>
            </Row>
          )}
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col>
              {this.inputFields(
                "Gross Monthly Income",
                [emp.income.base, emp.income.overtime],
                [
                  (e) =>
                    this.props.changeBorrowerOtherOccupationIncome(
                      e.target.value,
                      i,
                      "borrower",
                      "base"
                    ),
                  (e) =>
                    this.props.changeBorrowerOtherOccupationIncome(
                      e.target.value,
                      i,
                      "borrower",
                      "overtime"
                    ),
                ],
                ["Base   ", "Overtime  "],
                ["mo", "mo"]
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputFields(
                null,
                [emp.income.bonuses, emp.income.commissions],
                [
                  (e) =>
                    this.props.changeBorrowerOtherOccupationIncome(
                      e.target.value,
                      i,
                      "borrower",
                      "bonuses"
                    ),
                  (e) =>
                    this.props.changeBorrowerOtherOccupationIncome(
                      e.target.value,
                      i,
                      "borrower",
                      "commissions"
                    ),
                ],
                ["Bonus", "Commissions"],
                ["mo", "mo"]
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              {this.inputFields(
                "Total Income",
                [getTotalIncome(i)],
                [],
                ["$"],
                ["mo"]
              )}
            </Col>
          </Row>
        </div>
      );
    };

    return (
      <div id="1c">
        <div className="form1003Title">
          1c. Additional Employment/Self-Employment and Income
        </div>
        <div>{displayAdditionalOccupation()}</div>
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#325CEB",
            cursor: "pointer",
          }}
          onClick={() => addIncome()}
        >
          <img
            src="/images/bluePlusInCircleMark.png"
            style={{ width: 20, height: 20 }}
            className="ml-4 mr-2 my-3"
          />
          Add addtional income
        </div>
        
      </div>
    );
  };

  section1d = () => {
    const changeVal = (i, t, useValue) => {
      return (e) =>
        this.props.changeBorrowerPreviousOccupationValue(
          useValue ? e : e.target.value,
          i,
          "borrower",
          t
        );
    };
    const displayPreviousOccupation = () => {
      if (
        this.props.application.borrower.previousoccupations === null ||
        this.props.application.borrower.previousoccupations.length === 0
      ) {
        return "";
      }
      let out = [];
      for (
        let i = 0;
        i < this.props.application.borrower.previousoccupations.length;
        i++
      ) {
        out.push(previousIncome(i));
      }
      return out;
    };
    const previousIncome = (i) => {
      let emp = this.props.application.borrower.previousoccupations[i];
      return (
        <div className="p-4 removable">
          <div className="text-right"><MinusSquareOutlined onClick={()=>this.props.removeBorrowerPreviousOccupation("borrower", i)} style={{color:"#325CEB", fontSize: 16, cursor:"pointer"}}/></div>
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "Employer or Business Name",
                emp.employername,
                changeVal(i, "employername")
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.inputField("Phone", emp.phone, changeVal(i, "phone"))}
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "Address",
                emp.employeraddress,
                changeVal(i, "employeraddress")
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.inputField(
                "Position or Title",
                emp.positiontitle,
                changeVal(i, "positiontitle")
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-4">
              {this.dateField(
                "From",
                emp.from,
                (date, dateString) =>
                  this.props.changeBorrowerPreviousOccupationValue(
                    dateString,
                    i,
                    "borrower",
                    "from"
                  )
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.dateField(
                "To",
                emp.to,
                (date, dateString) =>
                  this.props.changeBorrowerPreviousOccupationValue(
                    dateString,
                    i,
                    "borrower",
                    "to"
                  )
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Checkbox
                checked={emp.employedbyagent}
                onChange={changeVal(i, "employedbyagent")}
                className="fontWeight500"
              >
                I am employed by a family member, property seller, real estate
                agent, or other party to the transaction.
              </Checkbox>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Checkbox
                checked={emp.selfemployed}
                onChange={changeVal(i, "selfemployed")}
                className="fontWeight500"
              >
                Check if you are the Business Owner or Self-Employed
              </Checkbox>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6}>
            {this.inputFields(
                "Income",
                [emp.income],
                [changeVal(i, "income")],
                ["$"],
                ["mo"],
              )}
            </Col>
          </Row>
        </div>
      );
    };
    const addPreviousIncome = () => {
      if (this.props.application.borrower.previousoccupations === null) {
        this.props.clearBorrowerPreviousOccupations("borrower");
      }
      this.props.addBorrowerPreviousOccupation("borrower");
    };
    return (
      <div id="1d">
        <div className="form1003Title">
          1d. Previous Employment/Self-Employment and Income
        </div>
        <div>{displayPreviousOccupation()}</div>
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#325CEB",
            cursor: "pointer",
          }}
          onClick={() => addPreviousIncome()}
        >
          <img
            src="/images/bluePlusInCircleMark.png"
            style={{ width: 20, height: 20 }}
            className="ml-4 mr-2 my-3"
          />
          Add previous income
        </div>
      </div>
    );
  };

  section1e = () => {
    const displayOtherIncomes = () => {
      let inc = this.props.application.borrower.income.otherincome;
      if (inc === null || inc.length === 0) {
        return "";
      }
      let out = [];
      const selectBefore = (i) => {
        return (
          <Select
            style={{ minWidth: 200 }}
            onSelect={(e) =>
              this.props.updateBorrowerAdditionalIncome(
                { target: { value: e } },
                "borrower",
                "incometype",
                i
              )
            }
            value={inc[i].incometype}
          >
            {com.othersouresofincome.map((st) => (
              <Option value={st[0]}>{st[1]}</Option>
            ))}
          </Select>
        );
      };

      for (let i = 0; i < inc.length; i++) {
        out.push(
          <Row>
            <Col className="mx-2 my-2">
              <Input
                addonBefore={selectBefore(i)}
                suffix="mo"
                prefix="$"
                value={inc[i].amount}
                size="large"
                onChange={(e) =>
                  this.props.updateBorrowerAdditionalIncome(
                    e,
                    "borrower",
                    "amount",
                    i
                  )
                }
              />
            </Col>
            <Col className="text-left d-flex align-items-center">
              <MinusCircleOutlined  onClick={()=>this.props.removeBorrowerAdditionalIncome(i, "borrower")} style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
            </Col>
          </Row>
        );
      }
      return out;
    };
    const addIncome = (e) => {
      this.props.addBorrowerAdditionalIncome(0, "borrower");
    };

    return (
      <div id="1e">
        <div className="form1003Title">1e. Income from Other Sources</div>
        <div>{displayOtherIncomes()}</div>
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#325CEB",
            cursor: "pointer",
          }}
          onClick={addIncome}
        >
          <img
            src="/images/bluePlusInCircleMark.png"
            style={{ width: 20, height: 20 }}
            className="ml-4 mr-2 my-3"
          />
          Add income from other resources
        </div>
      </div>
    );
  };
  sectionS2 = () => {
    return (
      <div id="s2" className="p-4">
        <div className="fontWeight500" style={{ fontSize: 20 }}>
          Section 2: Financial Information — Assets and Liabilities
        </div>
        <div>
          This section asks about things you own that are worth money and that
          you want considered to qualify for this loan. It then asks about your
          liabilities (or debts) that you pay each month, such as credit cards,
          alimony, or other expenses.
        </div>
      </div>
    );
  };
  section2a = () => {
    return (
      <div id="2a">
        <div className="form1003Title">
          2a. Assets – Bank Accounts, Retirement, and Other Accounts You Have
        </div>
        <div className="p-4">
          <Row>
            <Col className="px-0">
              <IncomeSourcesTable />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  section2b = () => {
    return (
      <div id="2b">
        <div className="form1003Title">
          2b. Other Assets and Credits You Have
        </div>
        <div className="p-4">
          <Row>
            <Col className="px-0">
              <OtherAssetsTable />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  section2c = () => {
    return (
      <div id="2c">
        <div className="form1003Title">
          2c. Liabilities – Credit Cards, Other Debts, and Leases that You Owe
        </div>
        <div className="p-4">
          <Row>
            <Col className="px-0">
              <LiabilitiesTable />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  section2d = () => {
    return (
      <div id="2d">
        <div className="form1003Title">2d. Other Liabilities and Expenses</div>
          <div className="p-4">
          <Row>
            <Col className="px-0">
              <OtherLiabilitiesTable />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  sectionS3 = () => {
    return (
      <div id="s3" className="p-4">
        <div className="fontWeight500" style={{ fontSize: 20 }}>
          Section 3: Financial Information — Real Estate
        </div>
        <div>
          This section asks you to list all properties you currently own and
          what you owe on them.
        </div>
      </div>
    );
  };
  section3a = () => {
    const renderProperties = () => {
      if (this.props.application.assetsandliabilities.ownedproperties.length===0) {
        return ""
      }
      return this.renderOwnedProperty(0)
    }
    return (
      <div id="3a">
        <div className="form1003Title">3a. Property You Own</div>
        <div className="p-4">
            <Checkbox
                checked={
                  this.props.application.assetsandliabilities.ownedproperties.length === 0
                }
                className="fontWeight500 mb-4"
                onChange={(e)=>{
                  if (e.target.checked) {
                    this.props.clearBorrowerOwnedProperty(0)
                  } else {
                    if (this.props.application.assetsandliabilities.ownedproperties.length === 0) {
                      this.props.addBorrowerOwnedProperty()
                    }
                  }
                }}
              >
                I do not own any real estate
              </Checkbox>
              {renderProperties()}
        </div>
      </div>
    );
  };
  section3b = () => {
    const renderProperties = () => {
      if (this.props.application.assetsandliabilities.ownedproperties.length <= 1) {
        return ""
      }
      return this.renderOwnedProperty(1)
    }
    return (
      <div id="3b">
        <div className="form1003Title">3b. Additional Property</div>
        <div className="p-4">
        <Row className="mb-4">
          <Col>
            <Checkbox
              checked={
                this.props.application.assetsandliabilities.ownedproperties.length <2
              }
              className="fontWeight500"
              onChange={(e)=>{
                let count = this.props.application.assetsandliabilities.ownedproperties.length
                if (!e.target.checked) {
                  if ( count === 0) {
                    this.props.addBorrowerOwnedProperty()
                    this.props.addBorrowerOwnedProperty()
                    this.setState({showOwnPropertyWarning1: true})
                  } else if ( count === 1) {
                    this.props.addBorrowerOwnedProperty()
                    this.setState({showOwnPropertyWarning1: false})
                  }
                } else {
                  if ( count > 1) {
                    for (let i=1; i<count; i++) {
                      this.props.removeBorrowerOwnedProperty(i)
                    }
                  } 
                }
              }}
            >
              Does not apply
            </Checkbox>
          </Col>
        </Row>
        {this.state.showOwnPropertyWarning1 && <Row>
          <Col>
          <Alert
              message="Warning"
              description="Please fill the property information in section 3a first"
              type="warning"
              showIcon
              closable
            />
          </Col>
        </Row>}
        {renderProperties()}
        </div>
      </div>
    );
  };
  section3c = () => {
    const renderProperties = () => {
      if (this.props.application.assetsandliabilities.ownedproperties.length <= 2) {
        return ""
      }
      return this.renderOwnedProperty(2)
    }
    return (
      <div id="3c">
        <div className="form1003Title">3c. Additional Property</div>
        <div className="p-4">
        <Row className="mb-4">
          <Col>
            <Checkbox
              checked={
                this.props.application.assetsandliabilities.ownedproperties.length < 3
              }
              className="fontWeight500"
              onChange={(e)=>{
                let count = this.props.application.assetsandliabilities.ownedproperties.length
                if (!e.target.checked) {
                  if ( count === 0) {
                    this.props.addBorrowerOwnedProperty()
                    this.props.addBorrowerOwnedProperty()
                    this.props.addBorrowerOwnedProperty()
                    this.setState({showOwnPropertyWarning1: true, showOwnPropertyWarning2: true})
                  } else if ( count === 1) {
                    this.props.addBorrowerOwnedProperty()
                    this.props.addBorrowerOwnedProperty()
                    this.setState({showOwnPropertyWarning2: true})
                  } else if ( count === 2) {
                    this.props.addBorrowerOwnedProperty()
                    this.setState({showOwnPropertyWarning1: false, showOwnPropertyWarning2: false})
                  }
                } else {
                  if ( count > 2) {
                    for (let i=2; i<count; i++) {
                      this.props.removeBorrowerOwnedProperty(i)
                    }
                  } 
                }
              }}
            >
              Does not apply
            </Checkbox>
          </Col>
        </Row>
        {this.state.showOwnPropertyWarning2 && <Row>
          <Col>
          <Alert
              message="Warning"
              description="Please fill the property information in section 3a and 3b first"
              type="warning"
              showIcon
              closable
            />
          </Col>
        </Row>}
        {renderProperties()}
        </div>
      </div>
    );
  };
  sectionS4 = () => {
    return (
      <div id="s4" className="p-4">
        <div className="fontWeight500" style={{ fontSize: 20 }}>
          Section 4: Loan and Property Information
        </div>
        <div>
          This section asks about the loan’s purpose and the property you want
          to purchase or refinance.
        </div>
      </div>
    );
  };
  section4a = () => {
    return (
      <div id="4a">
        <div className="form1003Title">4a. Loan and Property Information</div>
        <div className="p-4">
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputFieldWithPrefix(
                "Loan Amount",
                this.props.application.selection.loansize,
                (e) => this.props.updateSelectField(e.target.value, "loansize"),
                "$"
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.dropdownField(
                "Loan Purpose",
                this.props.application.property.purpose,
                [
                  { value: "purchase", label: "Purchase" },
                  { value: "refinance", label: "Rate/Term refinance" },
                  { value: "cashoutrefinance", label: "Cash-out refinance" },
                ],
                (e) => {
                  this.props.changeMainPropertyPurpose(e.target.value)
                  if (e.target.value==="purchase") {
                    this.props.changeMainPropertyAttribute(true, "hascontract")
                  }
                }
              )}
            </Col>
          </Row>
          <Row className="">
            <Col>
              {this.state.editMode ? <AddressAutocomplete
                      id="PropertyAddress"
                      label={<div className="fontWeight500">Address</div>}
                      required={true}
                      name="addessline"
                      defaultValue={this.props.application.property.address}
                      onChange={(e) => this.props.changeMainProperty(e.target.value, "address")}
                      pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                      placeholder="Please choose from the suggested addresses generated based on the input."
                      goodfeedback="Looks good!"
                      badfeedback="Please provide your street address."
                      onCompletion={e=>{
                        let address = e
                        let state = com.getSubaddress(
                          address,
                          "administrative_area_level_1",
                          "short_name"
                        );
                        let zip = com.getSubaddress(address, "postal_code", "long_name");

                        let county = com.getSubaddress(
                          address,
                          "administrative_area_level_2",
                          "long_name"
                        );
                        let num = com.getSubaddress(address, "street_number", "long_name");
                        let short = com.getSubaddress(address, "route", "short_name");
                        let city = com.getSubaddress(address, "locality", "long_name");
                        let stradd = num + " " + short+", "+city+", "+state+" "+zip;
                        this.props.changeMainProperty(state, "state")
                        this.props.changeMainProperty(county, "county")
                        this.props.changeMainProperty(zip, "zipcode")
                        this.props.changeMainProperty(stradd, "address")
                      }}
                      size="md"
                      /> : <div className="fontSize16">{this.props.application["borrower"]
                      .presentaddressstreet}</div>} 

            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-4">
              {this.dropdownField(
                null,
                this.props.application.property.state,
                this.getStateOptions(),
                (e) => this.props.changeMainProperty(e.target.value, "state")
              )}
            </Col>
            <Col sm={4} className="mb-4">
              {this.dropdownField(
                null,
                this.props.application.property.county,
                this.getCountyOptions(this.props.application.property.state),
                (e) => this.props.changeMainProperty(e.target.value, "county")
              )}
            </Col>
            <Col sm={4} className="mb-4">
              {this.inputField(
                null,
                this.props.application.property.zipcode,
                (e) => this.props.changeMainProperty(e.target.value, "zipcode"),
                "Zip code"
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-4">
              {this.inputFieldWithPrefix(
                "Property Value",
                this.props.application.property.appraisal,
                (e) =>
                  this.props.changeMainProperty(e.target.value, "appraisal"),
                "$"
              )}
            </Col>
            <Col sm={6} className="mb-4">
              {this.dropdownField(
                "Intended Occupancy",
                this.props.application.property.occupancy,
                [
                  { value: "principal", label: "Primary Residence" },
                  { value: "secondhome", label: "Second Home" },
                  { value: "investment", label: "Investment" },
                ],
                (e) =>
                  this.props.changeMainProperty(e.target.value, "occupancy")
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col>
              <div>
                <div
                  style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}
                >
                  1. Mixed-Use Property
                </div>
                <div>
                  {this.selectionField(
                    "If you will occupy the property, will you set aside space within the property to operate your own business?",
                    this.props.application.property.isMixedUse === true
                      ? true
                      : false,
                    [
                      {
                        value: true,
                        label: "Yes",
                      },
                      {
                        value: false,
                        label: "No",
                      },
                    ],
                    (e) => this.props.changeMainProperty(e, "isMixedUse"),
                    true
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <div>
                <div
                  style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}
                >
                  2. Manufactured Home
                </div>
                <div>
                  {this.selectionField(
                    "Is the property a manufactured home? (e.g., a factory built dwelling built on a permanent chassis)",
                    this.props.application.property.isManufacturedHome === true
                      ? true
                      : false,
                    [
                      {
                        value: true,
                        label: "Yes",
                      },
                      {
                        value: false,
                        label: "No",
                      },
                    ],
                    (e) =>
                      this.props.changeMainProperty(e, "isManufacturedHome"),
                    true
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  section4b = () => {
    return (
      <div id="4b">
        <div className="form1003Title">
          4b. Other New Mortgage Loans on the Property You are Buying or
          Refinancing
        </div>
        <div className="p-4">
          <Row className="mb-4">
            <PropertyMortgagesTable />
          </Row>
        </div>
      </div>
    );
  };
  section4c = () => {
    return (
      <div id="4c">
        <div className="form1003Title">
          4c. Rental Income on the Property You Want to Purchase
        </div>
        <div className="p-4">
        <Row className="mb-4">
            <Col>
              <Checkbox
                checked={
                  this.props.application.property.propertytype ==="twotofourfamily"
                }
                className="fontWeight500"
                onChange={(e)=>{
                  if (e.target.checked) {
                    this.props.changeMainProperty("twotofourfamily","propertytype")
                  } else {
                    this.props.changeMainProperty("singlefamilydetached","propertytype")
                  }
                }}
              >
                This is a 2-4 unit property
              </Checkbox>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Checkbox
                checked={this.props.application.property.occupancy === "investment"}
                className="fontWeight500"
                onChange={(e)=>{
                  if (e.target.checked) {
                    this.props.changeMainProperty("investment","occupancy")
                  } else {
                    this.props.changeMainProperty("principal","occupancy")
                  }
                }}
              >
                This is a investment property
              </Checkbox>
            </Col>
          </Row>
          {this.props.application.property.purpose==="purchase" && (
            this.props.application.property.occupancy==="investment" ||
            this.props.application.property.propertytype==="twotofourfamily"
          ) && <Row className="mb-4">
          <Col className="pl-0">
            {this.inputFieldWithPrefix(
              "Expected Monthly Rental Income",
              this.props.application.property.purchase
                .expectedgrossmonthlyrentalincome,
              (e) =>
                this.props.changeMainProperty(
                  e.target.value,
                  "purchase|expectedgrossmonthlyrentalincome"
                ),
              "$"
            )}
          </Col>
          <Col className="pl-0">
            {this.inputFieldWithPrefix(
              "Expected Monthly Net Income",
              this.props.application.property.purchase.expectednetmonthlyrentalincome,
              (e) =>
                this.props.changeMainProperty(
                  e.target.value,
                  "purchase|expectednetmonthlyrentalincome"
                ),
              "$"
            )}
          </Col>
        </Row>}
        </div>
      </div>
    );
  };
  section4d = () => {
    return (
      <div id="4d">
        <div className="form1003Title">
          4d. Gifts or Grants You Have Been Given or Will Receive for this Loan
        </div>
        <div className="p-4">
          <Row className="mb-4">
            <Col className="px-0">
              {/* <Checkbox
                checked={
                  this.props.application.assetsandliabilities.gifts === null ||
                  this.props.application.assetsandliabilities.gifts.length === 0
                }
                className="fontWeight500 mb-3"
                onChange={(e) => {
                  if (e.target.checked) {
                    this.props.clearBorrowerGift()
                  }
                }}
              >
                Does not apply
              </Checkbox> */}
              <GiftsTable />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  sectionS5 = () => {
    return (
      <div id="s5" className="p-4">
        <div className="fontWeight500" style={{ fontSize: 20 }}>
          Section 5: Declarations
        </div>
        <div>
          This section asks you specific questions about the property, your
          funding, and your past financial history
        </div>
      </div>
    );
  };
  section5a = () => {
    return (
      <div id="5a">
        <div className="form1003Title">
          5a. About this Property and Your Money for this Loan
        </div>
        <div className="p-4">
          <Row className="mb-4">
            <Col className="px-0">
              {this.yesNoField(
                "A. Will you occupy the property as your primary residence?",
                this.props.application.borrower.declarations.occupyasprimary ===
                  "yes"
                  ? "yes"
                  : "no",
                (e) =>
                  this.props.changeDeclaration(e, "borrower", "occupyasprimary")
              )}
            </Col>
          </Row>
          {this.props.application.borrower.declarations.occupyasprimary ===
            "yes" && (
            <Row className="mb-4">
              <Col className="px-0">
                {this.yesNoField(
                  "Have you had an ownership interest in another property in the last three years?",
                  this.props.application.borrower.declarations
                    .ownershipinterest === "yes"
                    ? "yes"
                    : "no",
                  (e) =>
                    this.props.changeDeclaration(
                      e,
                      "borrower",
                      "ownershipinterest"
                    )
                )}
              </Col>
            </Row>
          )}
          {this.props.application.borrower.declarations.ownershipinterest ===
            "yes" && (
            <Row className="mb-4">
              <Col className="px-0">
                {this.horizontalDropdownField(
                  "(1) What type of property did you own: primary residence (PR), FHA secondary residence (SR), second home (SH), or investment property (IP)?",
                  this.props.application.borrower.declarations.propertyowned ===
                    null
                    ? ""
                    : this.props.application.borrower.declarations
                        .propertyowned,
                  [
                    { value: "", label: "..." },
                    { value: "principal", label: "Primary residence" },
                    { value: "secondhome", label: "Second Home" },
                    { value: "fhasecond", label: "FHA Second Home" },
                    { value: "investment", label: "Investment" },
                  ],
                  (e) =>
                    this.props.changeDeclaration(e, "borrower", "propertyowned")
                )}
              </Col>
            </Row>
          )}
          {this.props.application.borrower.declarations.ownershipinterest ===
            "yes" && (
            <Row className="mb-4">
              <Col className="px-0">
                {this.horizontalDropdownField(
                  "(1) What type of property did you own: primary residence (PR), FHA secondary residence (SR), second home (SH), or investment property (IP)?",
                  this.props.application.borrower.declarations.titleheld ===
                    null
                    ? ""
                    : this.props.application.borrower.declarations.titleheld,
                  [
                    { value: "", label: "..." },
                    { value: "yourself", label: "By yourself" },
                    {
                      value: "jointwithspouse",
                      label: "Jointly with the spouse or legal life partner",
                    },
                    {
                      value: "jointwithother",
                      label: "Jointly with another person",
                    },
                  ],
                  (e) =>
                    this.props.changeDeclaration(e, "borrower", "titleheld")
                )}
              </Col>
            </Row>
          )}
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col className="px-0">
              {this.yesNoField(
                "B. If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?",
                this.props.application.borrower.declarations
                  .familyrelationship === "yes"
                  ? "yes"
                  : "no",
                (e) =>
                  this.props.changeDeclaration(
                    e,
                    "borrower",
                    "familyrelationship"
                  )
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col className="px-0">
              {this.yesNoField(
                "C. Are you borrowing any money for this real estate transaction or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?",
                this.props.application.borrower.declarations
                  .downpaymentborrowed === "yes"
                  ? "yes"
                  : "no",
                (e) =>
                  this.props.changeDeclaration(
                    e,
                    "borrower",
                    "downpaymentborrowed"
                  )
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col className="px-0">
              {this.yesNoField(
                "D. 1. Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?",
                this.props.application.borrower.declarations
                  .applyingonanother === "yes"
                  ? "yes"
                  : "no",
                (e) =>
                  this.props.changeDeclaration(
                    e,
                    "borrower",
                    "applyingonanother"
                  )
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col className="px-0">
              {this.yesNoField(
                "D. 2. Have you or will you be applying for any new credit on or before closing thisloan that is not disclosed on this application?",
                this.props.application.borrower.declarations
                  .applyingnewcredit === "yes"
                  ? "yes"
                  : "no",
                (e) =>
                  this.props.changeDeclaration(
                    e,
                    "borrower",
                    "applyingnewcredit"
                  )
              )}
            </Col>
          </Row>
          <hr className="form1003Divider"></hr>
          <Row className="mb-4">
            <Col className="px-0">
              {this.yesNoField(
                "E. Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?",
                this.props.application.borrower.declarations.cleanenergylien ===
                  "yes"
                  ? "yes"
                  : "no",
                (e) =>
                  this.props.changeDeclaration(e, "borrower", "cleanenergylien")
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  section5b = () => {
    let questions = [
      [
        "F. Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?",
        "comakeronnote",
      ],
      ["G. Are there any outstanding judgments against you?", "judgements"],
      [
        "H. Are you currently delinquent or in default on a Federal debt?",
        "delinquentondebt",
      ],
      [
        "I. Are you a party to a lawsuit in which you potentially have any personal financial liability?",
        "lawsuit",
      ],
      [
        "J. Have you conveyed title to any property in lieu of foreclosure in the past 7 years?",
        "conveyedtitle",
      ],
      [
        "K. Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?",
        "loanforeclosure",
      ],
      [
        "L. Have you had property foreclosed upon in the last 7 years?",
        "propertyforeclosure",
      ],
      ["M. Have you declared bankruptcy within the past 7 years?", "bancrupt"],
    ];
    let fields = [];
    for (let i = 0; i < questions.length; i++) {
      fields.push(
        <Row className="mb-4">
          <Col className="px-0">
            {this.yesNoField(
              questions[i][0],
              this.props.application.borrower.declarations[questions[i][1]] ===
                "yes"
                ? "yes"
                : "no",
              (e) =>
                this.props.changeDeclaration(e, "borrower", questions[i][1])
            )}
          </Col>
        </Row>
      );
      if (i < questions.length - 1) {
        fields.push(<hr className="form1003Divider"></hr>);
      }
    }

    return (
      <div id="5b">
        <div className="form1003Title">5b. About Your Finances</div>
        <div className="p-4">{fields}</div>
      </div>
    );
  };
  sectionS6 = () => {
    return (
      <div id="s6">
        <div className="p-4">
          <div className="fontWeight500" style={{ fontSize: 20 }}>
            Section 6: Acknowledgments and Agreements.
          </div>
          <div>
            This section tells you about your legal obligations when you sign
            this application.
          </div>
        </div>
        <div className="form1003Title">Acknowledgments and Agreements</div>
        <div className="p-4">
          <Row className="mb-4">
            <Col className="px-0">
              <div className="fontWeight500 mb-3">Definitions:</div>
              <div>
                <li>
                  "Lender" includes the Lender’s agents, service providers, and
                  any of their successors and assigns.{" "}
                </li>
                <li>
                  "Other Loan Participants" includes (i) any actual or potential
                  owners of a loan resulting from this application (the “Loan”),
                  (ii) acquirers of any beneficial or other interest in the
                  Loan, (iii) any mortgage insurer, (iv) any guarantor, (v) any
                  servicer of the Loan, and (vi) any of these parties' service
                  providers, successors or assigns.
                </li>
              </div>
              <div className="fontWeight500 my-3">
                I agree to, acknowledge, and represent the following:
              </div>
              <div className="fontWeight500 mb-3">
                (1) The Complete Information for this Application{" "}
              </div>
              <li>
                The information I have provided in this application is true,
                accurate, and complete as of the date I signed this application.
              </li>
              <li>
                If the information I submitted changes or I have new information
                before closing of the Loan, I must change and supplement this
                application, including providing any updated/supplemented real
                estate sales contract.{" "}
              </li>
              <li>
                {" "}
                For purchase transactions: The terms and conditions of any real
                estate sales contract signed by me in connection with this
                application are true, accurate, and complete to the best of my
                knowledge and belief. I have not entered into any other
                agreement, written or oral, in connection with this real estate
                transaction
              </li>
              <li>
                The Lender and Other Loan Participants may rely on the
                information contained in the application before and after
                closing of the Loan
              </li>
              <li>
                {" "}
                Any intentional or negligent misrepresentation of information
                may result in the imposition of:{" "}
              </li>
              <div>
                (a) civil liability on me, including monetary damages, if a
                person suffers any loss because the person relied on any
                misrepresentation that I have made on this application, and/or
              </div>
              <div>
                (b) criminal penalties on me including, but not limited to, fine
                or imprisonment or both under the provisions of Federal law (18
                U.S.C. §§ 1001 et seq.).
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  sectionS7 = () => {
    return (
      <div id="s7">
        <div className="p-4">
          <div className="fontWeight500" style={{ fontSize: 20 }}>
            Section 7: Military Service
          </div>
          <div>
            This section asks questions about your (or your deceased spouse's)
            military service.
          </div>
        </div>
        <div className="form1003Title">Military Service of Borrower</div>
        <div className="p-4">
          <Row className="mb-4">
            <Col className="px-0">
              {this.yesNoField(
                "Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?",
                this.props.application.borrower.declarations
                  .servearmedforces === "yes"
                  ? "yes"
                  : "no",
                (e) =>
                  this.props.changeDeclaration(
                    e,
                    "borrower",
                    "servearmedforces"
                  )
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  sectionS8 = () => {
    const getRace = () => {
      let r = this.props.application.borrower.equalopportunity.race
      if (r.asian) {
        return "asian"
      } else if (r.black) {
        return "black"
      } else if (r.pacific) {
        return "pacific"
      } else if (r.white) {
        return "white"
      } else if (r.americanindian) {
        return "americanindian"
      }
      return ""
    }
    return (
      <div id="s8">
        <div className="p-4">
          <div className="fontWeight500" style={{ fontSize: 20 }}>
            Section 8: Demographic Information
          </div>
          <div>This section asks about your ethnicity, sex, and race.</div>
        </div>
        <div className="form1003Title">Demographic Information of Borrower</div>
        <div className="p-4">
          <Row className="mb-4">
            <Col className="px-0">
              {this.selectionField(
                "Ethnicity",
                this.props.application.borrower.equalopportunity.ethnicity,
                [
                  { value: "hispanic", label: "Hispanic or Latino" },
                  { value: "nonhispanic", label: "Not Hispanic or Latino" },
                  { value: "donttell", label: "I do not wish to answer" },
                ],
                (e) =>
                  this.props.updateEqualOpportunity(e, "borrower", "ethnicity")
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="px-0">
              {this.selectionField(
                "Sex",
                this.props.application.borrower.equalopportunity.sex,
                [
                  { value: "female", label: "Female" },
                  { value: "male", label: "Male" },
                  { value: "other", label: "Other" },
                  { value: "donttell", label: "I do not wish to answer" },
                ],
                (e) => this.props.updateEqualOpportunity(e, "borrower", "sex")
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="px-0">
              {this.selectionField(
                "Race",
                getRace(),
                [
                  { value: "asian", label: "Asian" },
                  { value: "pacific", label: "Pacific" },
                  { value: "americanindian", label: "Ametican indian" },
                  { value: "black", label: "Black" },
                  { value: "white", label: "White" },
                ],
                (e) => {
                    ["asian", "pacific", "americanindian", "black", "white"].map(r => this.props.updateEqualOpportunitySub(
                      r===e,
                      "borrower",
                      "race",
                      r,
                    ))
                }
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  handleScroll = () => {
    this.throttleScroll()
  };
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidMount() {
    this.getLoanLimits();
    if (sessionStorage.getItem("edit") === null) {
      sessionStorage.setItem("edit", "true");
    }
    if (this.props.application.borrower.income.hasdividends === null) {
      this.props.updateIncomeAtttribute(false, "borrower", "hasdividends");
    }
    window.addEventListener('scroll', this.handleScroll)
  }
  tabItems = [
    { key: 's1', title: 'S1. Borrower Information'},
    { key: '1a', title: '1a. Personal Information' },
    { key: '1b', title: '1b. Current Employment/Self-Employment and Income'},
    { key: '1c', title: '1c. Additional Employment/Self-Employment and Income'},
    { key: '1d', title: '1d. Previous Employment/Self-Employment and Income'},
    { key: '1e', title: '1e. Income from Other Sources'},
    { key: 's2', title: 'S2. Financial Information'},
    { key: '2a', title: '2a. Assets'},
    { key: '2b', title: '2b. Other Assets and Credits You Have'},
    { key: '2c', title: '2c. Liabilities – Credit Cards, Other Debts, and Leases that You Owe'},
    { key: '2d', title: '2d. Other Liabilities and Expenses'},
    { key: 's3', title: 'S3. Financial Information — Real Estate'},
    { key: '3a', title: '3a. Property You Own'},
    { key: '3b', title: '3b. Additional Property'},
    { key: '3c', title: '3c. Additional Property'},
    { key: 's4', title: 'S4. Loan and Property Information'},
    { key: '4a', title: '4a. Loan and Property Information'},
    { key: '4b', title: '4b. Other New Mortgage Loans on the Property'},
    { key: '4c', title: '4c. Rental Income on the Property'},
    { key: '4d', title: '4d. Gifts or Grants'},
    { key: 's5', title: 'S5. Declarations.'},
    { key: '5a', title: '5a. About this Property and Your Money for this Loan'},
    { key: '5b', title: '5b. About Your Finances'},
    { key: 's6', title: 'S6. Acknowledgments and Agreements'},
    { key: 's7', title: 'S7. Military Service'},
    { key: 's8', title: 'S8. Demographic Information'},
  ]
  render = () => {
    const items = [
      {
        label: (
          <a
            rel="noopener noreferrer"
            onClick={() => {
              this.setState({ editMode: true });
              sessionStorage.setItem("edit", "false");
            }}
          >
            editing
          </a>
        ),
        key: "0",
      },
      {
        label: (
          <a
            rel="noopener noreferrer"
            onClick={() => this.setState({ editMode: false })}
          >
            viewing
          </a>
        ),
        key: "1",
      },
    ];
    const tabsChange = (key) => {
      console.log(key)
      document.getElementById(`${key}`).scrollIntoView()
      window.scrollTo({
        top: window.scrollY - 370,
      })
    }
    return (
      <div className="text-left">
        <div className="title1003 title1003-mobile d-flex justify-content-between">
          <div style={{ fontSize: 16 }}>
            Uniform Residential Loan Application
          </div>
          <div className="editing-btn">
            You are{" "}
            <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space style={{ color: "#325CEB" }}>
                  {this.state.editMode ? "editing" : "viewing"}
                  <DownOutlined style={{ color: "#325CEB" }} />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
        <div className="loanInterview-tabs-wrap">
          <Tabs activeKey={this.state.activeKey} onChange={tabsChange}>
            {
              this.tabItems.map(item => (
                <Tabs.Tab title={item.key} key={item.key}></Tabs.Tab>
              ))
            }
          </Tabs>
        </div>
        <Row>
          <Col
            className={
              !this.state.expandedAnchor ? "expanded loans-left-anchor pl-0 col-9" : "expanded loans-left-anchor pl-0 col-7"
            }
          >
            {this.sectionS1()}
            {this.section1a()}
            {this.section1b()}
            {this.section1c()}
            {this.section1d()}
            {this.section1e()}
            {this.sectionS2()}
            {this.section2a()}
            {this.section2b()}
            {this.section2c()}
            {this.section2d()}
            {this.sectionS3()}
            {this.section3a()}
            {this.section3b()}
            {this.section3c()}
            {this.sectionS4()}
            {this.section4a()}
            {this.section4b()}
            {this.section4c()}
            {this.section4d()}
            {this.sectionS5()}
            {this.section5a()}
            {this.section5b()}
            {this.sectionS6()}
            {this.sectionS7()}
            {this.sectionS8()}
          </Col>
          <Col
            className={
              !this.state.expandedAnchor ? "expanded loans-rigth-anchor col-3" : "expanded loans-rigth-anchor col-5"
            }
          >
            <div className="d-flex">
              <Button
                shape="circle"
                onClick={() =>
                  this.setState({ expandedAnchor: !this.state.expandedAnchor })
                }
                size="large"
                className="mt-4"
                icon={
                  !this.state.expandedAnchor ? (
                    <LeftOutlined />
                  ) : (
                    <RightOutlined />
                  )
                }
                style={{ marginLeft: "-20px" }}
              ></Button>
              <div style={{ color: "#6E6E70", marginLeft: 10, marginTop: 36 }}>
                QUICK GUIDE
              </div>
            </div>
            <Anchor
              offsetTop={67}
              items={[
                ["s1", "S1. Borrower Information"],
                ["1a", "1a. Personal Information"],
                ["1b", "1b. Current Employment/Self-Employment and Income"],
                ["1c", "1c. Additional Employment/Self-Employment and Income"],
                ["1d", "1d. Previous Employment/Self-Employment and Income"],
                ["1e", "1e. Income from Other Sources"],
                ["s2", "S2. Financial Information"],
                ["2a", "2a. Assets"],
                ["2b", "2b. Other Assets and Credits You Have"],
                ["2c", "2c. Liabilities – Credit Cards, Other Debts, and Leases that You Owe"],
                ["2d", "2d. Other Liabilities and Expenses"],
                ["s3", "S3. Financial Information — Real Estate"],
                ["3a", "3a. Property You Own "],
                ["3b", "3b. Additional Property"],
                ["3c", "3c. Additional Property"],
                ["s4", "S4. Loan and Property Information"],
                ["4a", "4a. Loan and Property Information "],
                ["4b", "4b. Other New Mortgage Loans on the Property"],
                ["4c", "4c. Rental Income on the Property"],
                ["4d", "4d. Gifts or Grants"],
                ["s5", "S5. Declarations."],
                ["5a", "5a. About this Property and Your Money for this Loan"],
                ["5b", "5b. About Your Finances"],
                ["s6", "S6. Acknowledgments and Agreements"],
                ["s7", "S7. Military Service"],
                ["s8", "S8. Demographic Information"],
              ].map((s) => {
                return {
                  key: s[0],
                  href: "#" + s[0],
                  title: this.state.expandedAnchor ? s[1] : s[0],
                };
              })}
            />
          </Col>
        </Row>
      </div>
    );
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Interview)
);
