import React, { useState, useEffect, useRef } from "react";
import "./ReferBorrowers.js";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import NumericalInput, { CommaizedInput } from "../NumericalInput";
import AddressAutocomplete from "../Common/AddressAutocomplete";

import * as com from "../Common.js";

const AddNewBorrowers = (props) => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleReset = () => {
    formRef.current.reset();
    setEmail("");
    setName("");
    setValidated(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA");
    fetch("/re/referborrower", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify(
        {
          email: email,
          name: name,
        }
      ),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false)
          alert("Looks like there was a problem. Please contact Zeitro for help.");
          return;
        }
        response.json().then((js) => {
          setLoading(false)
          if (js.Status !== "OK") {
            alert("Looks like there was a problem. Please contact Zeitro for help.");
          } else {
            alert("Your referred borrower has been saved! We'll send an email to notify the borrower to check the loan officers information on your landing page.");
            window.location.reload();
          }
        });
      })
      .catch(function (err) {
        setLoading(false)
        console.log("Fetch Error /re/referborrower:-S", err);
      });
      handleReset();
  };

  return (
    <div>
      <h5 className="mt-4 mb-4 pl-5">Refer a Borrower to Your Loan Officers</h5>
      <Container>
        <Form ref={formRef} validated={validated} onSubmit={onSubmit}>
          <Row>
            <Col className="text-left">
              <Form.Label className="text-left">Name:</Form.Label>
              <Form.Control
                isValid={false}
                size="sm"
                required
                type="text"
                onChange={(e)=>setName(e.target.value)}
              />
            </Col>
            <Col className="text-left">
              <Form.Group controlId="minPrice">
                <Form.Label className="text-left">Email:</Form.Label>
                <Form.Control
                isValid={false}
                size="sm"
                required
                type="text"
                onChange={(e)=>setEmail(e.target.value)}
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
              />
              </Form.Group>
            </Col>
          </Row>
          <Row className="text-center">
            <Col className="text-center">
              <Button className="addPropertyButton" type="submit">
                {loading ? <Spinner animation="border" size="sm" /> : "Confirm"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddNewBorrowers;
