import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import UICustomization from './UI/UICustomization';
import Emails from './Communication/Emails';
import SMS from './Communication/SMS';
import Other from './Other/Other';

const Customization = () => {
  return (
    <Tabs mountOnEnter={true}>
      <Tab eventKey="ui" title="User Interface" className='applybgcolorfix'>
        <UICustomization />
      </Tab>
      <Tab eventKey="email" title="Email Templates">
        <Emails />
      </Tab>
      <Tab eventKey="sms" title="SMS Templates">
        <SMS />
      </Tab>
      <Tab eventKey="other" title="Other">
        <Other />
      </Tab>
    </Tabs>
  );
}

export default Customization;